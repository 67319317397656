import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UiDiv } from '@webfox-sc/core';

import { useAppDispatch } from '../../app/appHooks';
import { createContact, fetchContactsByEmail, updateContact } from '../../app/slices/contactsSlice';
import { createJob } from '../../app/slices/jobsSlice';
import { createRecipient } from '../../app/slices/recipientsSlice';
import IconCheck from '../../assets/icons-v2/IconCheck';
import ButtonPrimary from '../../atoms/ButtonPrimary';
import JobUtil from '../../utils/JobUtil';
import { useAuth } from '../auth/useAuth';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';

import JobRecipientEditor from './JobRecipientEditor';
import JobServices from './JobServices';
import PageContentSubHeading from '../page/PageContentSubHeading';

const JobCreate: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [services, setServices] = useState<JobServiceData[]>([]);
  const [jobRecipientEditorData, setJobRecipientEditorData] = useState<any>(null);

  const handleCreateJob = async () => {
    const {
      clientId,
      debitorId,
      costcenter,
      carrierNumber,
      carrierDescription,
      clientNumber,
      clientDescription,
      noTax,
      contacts,
      address,
    } = jobRecipientEditorData;

    const totalPrice = JobUtil.getServicesTotalNetPrice(services);

    /**
     * create job
     */
    const firstContact = contacts[0];
    const jobData: JobCreateData = {
      creator_id: currentUser?.id,
      status: 'Erstellt',
      costcenter,
      client_id: clientId,
      client_name: clientDescription,
      carrier_number: carrierNumber,
      contact_person_name: firstContact ? firstContact.name : '',
      contact_person_email: firstContact ? firstContact.email : '',
      no_tax: noTax,
      services,
      totalPrice,
    };
    const resJobCreated = await dispatch(createJob(jobData));
    const jobCreatedData = resJobCreated.payload?.data;
    if (!jobCreatedData) {
      return false;
    }

    /**
     * update contacts
     */
    const contactIds: any = [];
    for (let i = 0; i < contacts.length; i += 1) {
      const contact = contacts[i];
      const resExistingContacts = await dispatch(fetchContactsByEmail(contact.email));
      const existingContactsData = resExistingContacts.payload?.data;
      const firstExistingContactData = existingContactsData ? existingContactsData[0] : undefined;

      if (firstExistingContactData) {
        if (firstExistingContactData.name !== contact.name) {
          await dispatch(
            updateContact(firstExistingContactData.id, {
              name: contact.name,
            })
          );
        }
        contactIds.push(firstExistingContactData.id);
      } else {
        const resContactCreated = await dispatch(
          createContact({
            name: contact.name,
            email: contact.email,
          })
        );
        const contactCreatedData = resContactCreated.payload?.data;
        if (contactCreatedData) {
          contactIds.push(contactCreatedData.id);
        }
      }
    }

    /**
     * create recipient
     */
    const recipientData = {
      client_id: clientId,
      debitor_id: debitorId,
      costcenter,
      carrier_number: carrierNumber,
      carrier_description: carrierDescription,
      client_number: clientNumber,
      client_description: clientDescription,
      contacts: contactIds,
      address,
      job_id: jobCreatedData.id,
      job: jobCreatedData.id,
    };
    await dispatch(createRecipient(recipientData));

    /**
     * navigate to job
     */
    navigate(`/job/${jobCreatedData.id}`);
    return true;
  };

  return (
    <>
      <PageHeading heading={`${t('job.headline.create')}`} backlinkLabel="Zurück" backlinkPath="/jobs" preferHistoryBack />
      <PageContent paddingTop="l">
        <PageContentSubHeading leftSideText={`* ${t('common.form.requiredFields')}`}>{t('job.recipient')}</PageContentSubHeading>

        <UiDiv marginTop="s">
          <JobRecipientEditor
            isJobEditMode
            isEditMode
            showButtonNext
            onUpdate={(data) => setJobRecipientEditorData(data)}
          />
        </UiDiv>

        {jobRecipientEditorData && (
          <>
            <PageContentSubHeading leftSideText={`* ${t('common.form.requiredFields')}`} marginTop="l">
              {t('job.services')}
            </PageContentSubHeading>

            <UiDiv paddingTop="s">
              <JobServices
                isJobCreateMode
                clientId={jobRecipientEditorData ? jobRecipientEditorData.clientId : null}
                onServicesUpdated={(jobServicesUpdated) => setServices(jobServicesUpdated)}
              />
            </UiDiv>
            <UiDiv paddingTop="m" justifyContent="center">
              <ButtonPrimary
                label={t('job.actions.create')}
                icon={IconCheck}
                disabled={!services.length}
                onClick={() => handleCreateJob()}
              />
            </UiDiv>
          </>
        )}
      </PageContent>
    </>
  );
};

export default JobCreate;
