import { UiDiv, UiFlex, UiText } from '@webfox-sc/core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../app/entityHooks/useCompany';
import { entitySelectors } from '../../../app/entitySelectors';
import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconClose from '../../../assets/icons-v2/IconClose';
import Button from '../../atoms-v2/Button';
import TextInput from '../../atoms-v2/TextInput';
import QMAdminCompanyClientsItem from './QMAdminCompanyClientsItem';

const limitInterval = 20;

interface QMAdminCompanyClientsProps {
  companyId: number | undefined;
}

const QMAdminCompanyClients: React.FC<QMAdminCompanyClientsProps> = ({ companyId }) => {
  const { t } = useTranslation();
  const locationEntities = entitySelectors.selectEntities<LocationEntity>('locations');

  const { company, clients } = useCompany(companyId);
  const [searchValue, setSearchValue] = useState('');
  const [limit, setLimit] = useState(limitInterval);

  const clientsForSearch = useMemo(() => {
    return clients.map((client) => {
      const location = locationEntities[client.locationId || 0];
      return {
        ...client,
        locationDescription: location?.description || '',
      };
    });
  }, [clients, locationEntities]);

  const clientsFiltered = useMemo(() => {
    return clientsForSearch.filter((client) =>
      client.locationDescription?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [clientsForSearch, searchValue]);

  if (!company) {
    return null;
  }

  return (
    <>
      <UiText variant="h2" color="white" textAlign="center">
        {t('job.recipient')}
      </UiText>

      {clients.length === 0 ? (
        <UiText variant="copy" color="white" textAlign="center" marginTop="s">
          {t('company.info.noClientsFound')}
        </UiText>
      ) : (
        <>
          {clients.length > limitInterval && (
            <UiFlex columnGap="xxs" maxWidth="640px" margin="0 auto" marginTop="s">
              <UiDiv flexGrow={1}>
                <TextInput
                  variant="outline"
                  placeholder={`${t('common.actions.search')} ...`}
                  value={searchValue}
                  onChange={(e) => {
                    setLimit(limitInterval);
                    setSearchValue(e.target.value);
                  }}
                />
              </UiDiv>
              {searchValue && (
                <UiDiv>
                  <Button
                    variant="secondaryRed"
                    aria-label={t('common.actions.resetSearch') || undefined}
                    icon={IconClose}
                    onClick={() => {
                      setLimit(limitInterval);
                      setSearchValue('');
                    }}
                  />
                </UiDiv>
              )}
            </UiFlex>
          )}

          <UiText variant="copy" color="white" textAlign="center" marginTop="s">
            {limit > clientsFiltered.length ? clientsFiltered.length : limit} / {clientsFiltered.length}
          </UiText>

          <UiDiv display="flex" flexDirection="column" gap="xs" marginTop="s">
            {clientsFiltered.slice(0, limit).map((client) => {
              return <QMAdminCompanyClientsItem key={client.id} clientId={client.id} />;
            })}
          </UiDiv>

          {clientsFiltered.length > limit && (
            <UiDiv display="flex" justifyContent="center" marginTop="s">
              <Button icon={IconArrowDown} onClick={() => setLimit(limit + limitInterval)}>
                {t('common.actions.showMoreEntries')}
              </Button>
            </UiDiv>
          )}
        </>
      )}
    </>
  );
};

export default QMAdminCompanyClients;
