/* eslint-disable react-hooks/exhaustive-deps */
import { difference, pick, uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ROWS_STEP } from '../../../global/constants';

export function usePaginatedList({
  getAPIFilter,
  fetchObjects,
  objectPath,
  defaultSortingKey = 'createdAt',
  defaultSortingOrder = 'desc',
}) {
  const defaultLimit = ROWS_STEP;

  const [objectIds, setObjectIds] = useState([]);
  const [totalCount, setTotalCount] = useState(undefined);
  const [sortingKey, setSortingKey] = useState(defaultSortingKey);
  const [sortingOrder, setSortingOrder] = useState(defaultSortingOrder);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (options = {}) => {
    const { start = 0, limit = defaultLimit } = options;

    const filters = getAPIFilter(options) || {};
    const params = {
      // populate: '*',
      filters,
      _limit: limit,
      _start: start,
      _sort: `${sortingKey}:${String(sortingOrder).toUpperCase()}`, //  'entry_id:DESC',
    };

    const { objects: objectsData, count } = await fetchObjects(params);
    if (!objectsData) {
      return { ids: null };
    }
    return { ids: objectsData.map((objectData) => objectData.id), count };
  };

  const fetchInitial = useCallback(async () => {
    setIsLoading(true);
    const { ids, count } = await fetchData();
    setIsLoading(false);
    setObjectIds(ids);

    setTotalCount(count);
  }, [sortingKey, sortingOrder]);

  const fetchMore = async () => {
    const { ids } = await fetchData({ start: objectIds.length });
    setObjectIds([...objectIds, ...ids]);
  };

  const fetchFiltered = async (filter, showMore) => {
    // fetch all if limit is not explicitly set
    // set a limit to avoid freezing gui
    setIsLoading(true);
    const { ids, count } = await fetchData({ ...filter, ...(showMore ? { start: objectIds.length } : {}) });
    setIsLoading(false);
    if (ids !== objectIds) {
      setObjectIds(showMore ? [...objectIds, ...ids] : ids);
      setTotalCount(count);
    }
  };

  const addObjects = (objects) => {
    if (objects && objects.length > 0) {
      setObjectIds(uniq([...objects.map(({ id }) => id), ...objectIds]));
    }
  };

  const removeObjects = (objects) => {
    if (objects && objects.length > 0) {
      const idsToRemove = objects.map(({ id }) => id);
      setObjectIds(difference(objectIds, idsToRemove));
    }
  };

  return {
    objectsData: useSelector(({ dataState }) => pick(dataState[objectPath], objectIds)),
    objectIds,
    totalCount,
    fetchMore,
    fetchFiltered,
    resetFilter: fetchInitial,
    addObjects,
    removeObjects,
    sortingKey,
    setSortingKey,
    sortingOrder,
    setSortingOrder,
    isLoading
  };
}
