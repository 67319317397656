/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import ButtonPrimary from '../../atoms/ButtonPrimary';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import Section from '../../atoms/Section';
import { useUserOptions } from '../../hooks/useUserOptions';
import { createCostcenter, deleteCostcenter, updateCostcenter } from '../../redux-actions/actionsCostcenters';
import ClientsUtil from '../../utils/ClientsUtil';
import CostcenterUtil from '../../utils/CostcenterUtil';
import { getEntryLabel, stripObject } from '../../utils/FilterUtil';
import FormPanel from '../atoms-v2/FormPanel';
import TextInput from '../atoms-v2/TextInput';
import CustomReactSelect from '../CustomReactSelect';
import Advice from '../elements/Advice';
import BasicButtonGroup from '../elements/BasicButtonGroup';
import FormCell from '../elements/FormCell';
import FormLabel from '../elements/FormLabel';
import FormRow from '../elements/FormRow';
import ToggleBox from '../elements/ToggleBox';

import './costcenter-editor.scss';
import { useToastContext } from '../toast/ToastProvider';
import { UiDiv } from '@webfox-sc/core';
import { usePermissions } from '../auth/usePermissions';

const CostcenterEditor = ({
  model = {},
  isError,
  adviceText: initialAdviceText,
  showAdvice: hasAdvice,
  isUpdateMode,
  initModel,
  checkCanUpdate,
  onCancel,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = useToastContext();
  const { userOptions } = useUserOptions();
  const { canViewGeneralBaseData } = usePermissions();

  const [rawModel, setRawModel] = useState(model);
  const [modelState, setModelState] = useState(model);

  let initialAdviceStatus = null;
  let adviceStatusText = '';
  if (model.isNew) {
    initialAdviceStatus = 'success';
    adviceStatusText = t('costcenter.success.new');
  } else if (isError) {
    initialAdviceStatus = 'error';
  } else if (model.isModified) {
    initialAdviceStatus = 'info';
    adviceStatusText = t('costcenter.success.changed');
  } else if (hasAdvice) {
    initialAdviceStatus = 'custom';
  }

  const [adviceStatus, setAdviceStatus] = useState(initialAdviceStatus);
  const [adviceText, setAdviceText] = useState(initialAdviceText);

  useEffect(() => {
    if (model !== rawModel) {
      if (model) {
        setModelState(model);
        if (model.isModified) {
          setAdviceStatus('info');
          setAdviceText(t('costcenter.success.changed'));
        } else if (model.isNew) {
          setAdviceStatus('new');
          setAdviceText(t('costcenter.success.new'));
        }
        setRawModel(model);
      } else {
        const emptyModel = CostcenterUtil.getEmptyObject();
        setModelState(emptyModel);
        setRawModel(emptyModel);
      }
    }
  }, [model, rawModel]);

  useEffect(() => {
    if (adviceText !== initialAdviceText) {
      setAdviceText(initialAdviceText);
    }
  }, [initialAdviceText]);

  useEffect(() => {
    // new error
    if (isError && adviceStatus !== 'error') {
      setAdviceStatus('error');
    }
  }, [isError]);

  const handleChangeNumber = (value) => {
    setModelState({
      ...modelState,
      number: value,
    });
  };

  const handleOnClickCreate = async () => {
    const canUpdate = checkCanUpdate(modelState);
    if (canUpdate) {
      const strapiData = CostcenterUtil.getAPIData(modelState);
      const newModels = await dispatch(createCostcenter(strapiData));
      onCreate(newModels);

      const emptyModel = initModel();
      setModelState(emptyModel);
      setRawModel(emptyModel);
    } else {
      setAdviceStatus('error');
      setAdviceText(t('costcenter.errors.exists'));
    }
  };

  const handleOnClickUpdate = async () => {
    const canUpdate = checkCanUpdate(modelState);
    if (canUpdate) {
      const strapiData = CostcenterUtil.getAPIData(modelState);
      await dispatch(updateCostcenter(model.id, strapiData));
      onUpdate();
    } else {
      setAdviceStatus('error');
      setAdviceText(t('costcenter.errors.exists'));
    }
  };

  const handleOnClickDelete = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('costcenter.prompts.delete', {number: modelState.number}))) {
      const deletedModels = await dispatch(deleteCostcenter(modelState.id));
      onDelete(deletedModels);
      showToast(t('costcenter.success.deleted', {number: modelState.number}), 'success');
    }
  };

  const handleChangeUsers = async (value) => {
    setModelState({
      ...modelState,
      userIds: [...value],
    });
  };

  const handleChangeClients = async (values) => {
    setModelState({
      ...modelState,
      clients: [...values],
      clientIds: values.map(({ value: id }) => id),
    });
  };

  const handleOnClickAdvice = () => {
    setAdviceStatus(false);
  };

  const { clients } = modelState;

  const currentOptions = {
    clients: null,
  };

  if (clients) {
    currentOptions.clients = clients.map((client) => ({
      value: client.id,
      label: getEntryLabel('costcenterClient', client),
      company: stripObject(client.company),
      location: stripObject(client.location),
    }));
  }

  /**
   * users multi select
   */
  const userSelectOptions = (userOptions || []).map((userOption) => {
    return {
      value: userOption.value,
      label: userOption.text,
    };
  });

  const userSelectedOptions = userSelectOptions.filter((userSelectOption) =>
    (modelState.userIds || []).includes(userSelectOption.value)
  );

  return (
    <FormPanel>
      <ToggleBox show={!!adviceStatus}>
        <UiDiv paddingBottom="s">
          <Advice
            text={adviceText || adviceStatusText}
            isSuccess={adviceStatus === 'success'}
            isError={adviceStatus === 'error'}
            isInfo={adviceStatus === 'info'}
            onClick={handleOnClickAdvice}
          />
        </UiDiv>
      </ToggleBox>
      <FormRow>
        <FormCell cols="4">
          <FormLabel text={`${t('costcenter.number')} *`} />
          <TextInput
            value={modelState.number}
            maxLength={6}
            onChange={(e) => handleChangeNumber(e.target.value)}
            disabled={!canViewGeneralBaseData}
          />
        </FormCell>
        <FormCell cols="8">
          <FormLabel text={t('costcenter.users')} />
          <div className="smg-costcenter-editor__dropdown">
            <CustomReactSelect
              selectedOption={userSelectedOptions}
              options={userSelectOptions}
              isMulti
              placeholder={t('common.actions.select')}
              isClearable={false}
              onChange={(options) => {
                const selectedUserIds = options.map((option) => option.value);
                handleChangeUsers(selectedUserIds);
              }}
            />
          </div>
        </FormCell>
        <FormCell cols="12">
          <FormLabel text={t('costcenter.clients')} />
          <div className="smg-costcenter-editor__dropdown">
            <CustomReactSelect
              isMulti
              asyncDefaultOptions={currentOptions.clients}
              selectedOption={currentOptions.clients}
              asyncApiPath="/clients"
              asyncLimit={500}
              asyncSort="number:asc"
              asyncSetParams={(inputValue) =>
                ClientsUtil.getClientGroupSearchParams(inputValue, {
                  populate: ['location', 'company'],
                })
              }
              asyncFormatOption={(rawClient) => {
                const cl = ClientsUtil.parseData(rawClient);
                const entry = {
                  company: stripObject(cl.company),
                  location: stripObject(cl.location),
                };
                return {
                  value: cl.id,
                  label: getEntryLabel('costcenterClient', entry),
                  ...entry,
                };
              }}
              asyncInputMinLength={3}
              isClearable
              isSearchable
              placeholder={t('common.form.search')}
              isDisabled={!canViewGeneralBaseData}
              onChange={handleChangeClients}
            />
          </div>
        </FormCell>
      </FormRow>
      <Section paddingTop="M">
        <FormRow>
          <FormCell cols="8">
            <BasicButtonGroup>
              {!isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.create')}
                  icon={IconCheck}
                  disabled={!modelState.number}
                  onClick={handleOnClickCreate}
                />
              )}
              {isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.apply')}
                  icon={IconCheck}
                  disabled={!modelState.number}
                  onClick={handleOnClickUpdate}
                />
              )}
              <ButtonSecondary label={t('common.actions.close')} icon={IconClose} onClick={onCancel} />
            </BasicButtonGroup>
          </FormCell>
          {isUpdateMode && canViewGeneralBaseData && (
            <FormCell cols="4" align="right">
              <ButtonSecondary label={t('costcenter.actions.delete')} icon={IconClose} onClick={handleOnClickDelete} />
            </FormCell>
          )}
        </FormRow>
      </Section>
    </FormPanel>
  );
};

CostcenterEditor.propTypes = {
  model: PropTypes.object,
  isUpdateMode: PropTypes.bool,
  showAdvice: PropTypes.bool,
  adviceText: PropTypes.string,
  isError: PropTypes.bool,
  initModel: PropTypes.func,
  checkCanUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

CostcenterEditor.defaultProps = {
  model: null,
  isUpdateMode: false,
  showAdvice: false,
  adviceText: '',
  isError: false,
  initModel: () => {},
  checkCanUpdate: () => true,
  onCancel: () => false,
  onCreate: () => false,
  onUpdate: () => false,
  onDelete: () => false,
};
export default CostcenterEditor;
