import { UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconClose from '../../../assets/icons-v2/IconClose';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import IconUser from '../../../assets/icons-v2/IconUser';
import CustomReactSelect from '../../CustomReactSelect';
import ContentBox from '../../atoms-v2/ContentBox';
import IconButton from '../../atoms-v2/IconButton';
import Text from '../../atoms-v2/Text';
import TextInput from '../../atoms-v2/TextInput';
import TextLink from '../../atoms-v2/TextLink';
import { useQMAdminClient } from '../useQMAdminClient';
import { useQMAdminCostcenter } from '../useQMAdminCostcenter';
import { useQMAdminSchedule } from '../useQMAdminSchedule';

interface QMAdminClientScheduleUsersFormProps {
  clientId?: number;
  scheduleId?: number;
  onUpdate?: (selectedUsers: UserEntity[]) => void;
}

const QMAdminClientScheduleUsersForm: React.FC<QMAdminClientScheduleUsersFormProps> = ({ clientId, scheduleId, onUpdate }) => {
  const { t } = useTranslation();
  const { client } = useQMAdminClient(clientId);
  const { users: scheduleUsers } = useQMAdminSchedule(scheduleId);
  const { costcenter, qmUsers, qmUsersSelectOption } = useQMAdminCostcenter(client?.costcenterId);

  const [selectedUsers, setSelectedUsers] = useState<UserEntity[]>(scheduleUsers);
  const selectedUserIds = selectedUsers.map((user) => user.id);

  const [showNewUserSelect, setShowNewUserSelect] = useState(true);

  useEffect(() => {
    if (client) {
      setSelectedUsers(scheduleUsers);
      setShowNewUserSelect(!scheduleUsers.length);
    }
  }, [client, scheduleUsers]);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(selectedUsers);
    }
  }, [selectedUsers, onUpdate]);

  const remainingUserSelectOptions = useMemo(() => {
    return qmUsersSelectOption.filter((option) => !selectedUserIds.includes(option.value));
  }, [selectedUserIds, qmUsersSelectOption]);

  const handleOnSelectUser = (selectedUserId: number) => {
    const selectedUser = (qmUsers || []).find((user) => user.id === selectedUserId);
    if (!!selectedUser && !selectedUsers.includes(selectedUser)) {
      setSelectedUsers([...selectedUsers, selectedUser]);
      setShowNewUserSelect(false);
    }
  };

  const handleOnUnselectUser = (selectedUserId: number) => {
    const selectedUsersNew = selectedUsers.filter((user) => user.id !== selectedUserId);
    setSelectedUsers(selectedUsersNew);
    setShowNewUserSelect(!selectedUsersNew.length);
  };

  return (
    <ContentBox>
      <Text icon={IconUser}>
        <strong>{t('common.fields.reporter')}</strong>
      </Text>
      {qmUsers.length ? (
        <UiDiv display="flex" flexDirection="column" gap="xxs" marginTop="s" padding="s" background="white">
          {selectedUsers.map((selectedUser) => {
            return (
              <UiDiv key={selectedUser.id} display="flex" gap="xs" alignItems="center">
                <UiDiv flexGrow={1}>
                  <TextInput value={`${selectedUser.username} (${selectedUser.roleName})`} disabled />
                </UiDiv>
                <IconButton icon={IconClose} onClick={() => handleOnUnselectUser(selectedUser.id)} />
              </UiDiv>
            );
          })}
          {showNewUserSelect && remainingUserSelectOptions.length > 0 && (
            <CustomReactSelect
              options={remainingUserSelectOptions}
              onChangeValue={(selectedUserId) => handleOnSelectUser(selectedUserId)}
              placeholder={t('common.actions.select')}
            />
          )}
          {!showNewUserSelect && remainingUserSelectOptions.length > 0 && (
            <UiDiv marginTop="xxs">
              <TextLink
                icon={IconPlus}
                onClick={(e) => {
                  e.preventDefault();
                  setShowNewUserSelect(true);
                }}
              >
                {t('schedule.actions.addReporter')}
              </TextLink>
            </UiDiv>
          )}
        </UiDiv>
      ) : (
        <UiText variant="copy" marginTop="s">
          <em>{t('costcenter.info.noUsersFound', { number: costcenter?.number })}</em>
        </UiText>
      )}
    </ContentBox>
  );
};

export default QMAdminClientScheduleUsersForm;
