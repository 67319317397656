import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useAppDispatch } from '../../app/appHooks';
import { useCertificate } from '../../app/entityHooks/useCertificate';
import { useJob } from '../../app/entityHooks/useJob';
import { useUser } from '../../app/entityHooks/useUser';
import { acceptPublicCertificates } from '../../app/slices/certificatesSlice';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconArrowUp from '../../assets/icons-v2/IconArrowUp';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconDownload from '../../assets/icons-v2/IconDownload';
import IconPlus from '../../assets/icons-v2/IconPlus';
import IconAttachment from '../../assets/icons/IconAttachment';
import DateUtil from '../../utils/DateUtil';
import Button from '../atoms-v2/Button';
import { useAuth } from '../auth/useAuth';

import RestUtil from '../../utils/RestUtil';
import LoadingWrapper from '../atoms-v2/LoadingWrapper';
import { usePermissions } from '../auth/usePermissions';
import PageContentSubHeading from '../page/PageContentSubHeading';
import JobCertificateEditor from './JobCertificateEditor';
import JobFiles from './JobFiles';
import JobHistoryList from './JobHistoryList';
import { useJobCertificate } from './useJobCertificate';
import { useJobHistories } from './useJobHistories';

interface JobCertificateProps {
  jobId?: number | undefined;
  onCertificateCreated?: () => void;
  onCertificateInstantConfirmed?: () => void;
  onClickSend?: (setCertificatePendingStatus?: boolean) => void;
}

const JobCertificate: React.FC<JobCertificateProps> = ({
  jobId,
  onCertificateCreated,
  onCertificateInstantConfirmed,
  onClickSend,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { colors } = useTheme();

  const { currentUser } = useAuth();
  const { user } = useUser(currentUser?.id);
  const { canCreateJob } = usePermissions();
  const userEmail = user?.email;

  const { job, recipient: jobRecipient } = useJob(jobId);
  const { certificate, recipient: certificateRecipient, files, pdfFile } = useCertificate(job?.certificateId);
  const { certificateHistoriesSorted } = useJobHistories(jobId);

  const {
    createCertificateStatus,
    handleCreateCertificate,
    handleCreateCertificatePDF,
    handleUploadCertificateFiles,
    handleDeleteCertificateFile,
  } = useJobCertificate(job?.id);

  const [showDetails, setShowDetails] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);

  const [orderNumber, setOrderNumber] = useState<string | undefined>('');
  const [serviceStartDate, setServiceStartDate] = useState<Date | null | undefined>(null);
  const [serviceEndDate, setServiceEndDate] = useState<Date | null | undefined>(null);
  const [location, setLocation] = useState<string | undefined>('');
  const [comments, setComments] = useState<string | undefined>('');
  const [showPrices, setShowPrices] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (certificate) {
      setOrderNumber(certificate.orderNumber);
      setLocation(certificate.location);
      setServiceStartDate(certificate.serviceStartDate);
      setServiceEndDate(certificate.serviceEndDate);
      setComments(certificate.comments);
      setShowPrices(certificate.showPrices);
    } else if (jobRecipient) {
      setLocation(jobRecipient.carrierDescription);
    }
  }, [certificate, jobRecipient]);

  const handleOnChangeInput = (key: string, value: any) => {
    if (key === 'orderNumber') {
      setOrderNumber(value);
    } else if (key === 'location') {
      setLocation(value);
    } else if (key === 'comments') {
      setComments(value);
    } else if (key === 'showPrices') {
      setShowPrices(value);
    }
  };

  const handleChangeStartDate = (startDate) => {
    setServiceStartDate(startDate);
    if (!serviceEndDate || startDate > serviceEndDate) {
      setServiceEndDate(startDate || null);
    }
  };

  const handleChangeEndDate = (endDate) => {
    if (!!serviceStartDate && endDate < serviceStartDate) {
      setServiceEndDate(serviceStartDate);
    } else {
      setServiceEndDate(endDate);
    }

    if (!serviceStartDate) {
      setServiceStartDate(endDate);
    }
  };

  const handleOnClickCreate = async () => {
    setIsCreateMode(false);
    setShowDetails(true);
    await handleCreateCertificate(orderNumber, location, serviceStartDate, serviceEndDate, comments, showPrices);
    if (onCertificateCreated) {
      onCertificateCreated();
    }
  };

  const handleClickInstantConfirm = async () => {
    const formData = new FormData();
    const certificates = [
      {
        id: certificate?.id,
      },
    ];
    formData.append('data', JSON.stringify({ certificates, email: userEmail }));

    try {
      await dispatch(acceptPublicCertificates(certificate?.id, null, formData));
      if (onCertificateInstantConfirmed) {
        onCertificateInstantConfirmed();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  let statusTitle = '';
  let statusTitleColor = colors.ciBlue3;
  let statusText = '';
  if (isCreateMode) {
    statusTitle = t('job.documentForm.headline.create');
  } else if (certificate) {
    switch (certificate.confirmed) {
      case 'INIT':
        statusTitle = t('job.documentForm.headline.editing');
        statusTitleColor = colors.grey80;
        statusText =
          (certificate &&
            t('common.messages.createdAt', {
              date: DateUtil.getDateTimeFormattedExtended(certificate.createdAt),
            })) ||
          '';
        break;
      case 'DEFAULT':
      case 'CONFIRMATION_PENDING':
      case 'REJECTION_PENDING':
        statusTitle = t('job.documentForm.headline.pending');
        statusTitleColor = colors.trafficLightYellow;
        statusText =
          (certificate &&
            t('common.messages.sentAt', {
              date: DateUtil.getDateTimeFormattedExtended(certificate.createdAt),
            })) ||
          '';
        break;
      case 'BESTAETIGT':
        statusTitle = t('job.documentForm.headline.confirmed');
        statusTitleColor = colors.trafficLightGreenText;
        statusText =
          (certificate &&
            t('common.messages.confirmedAt', {
              date: DateUtil.getDateTimeFormattedExtended(certificate.createdAt),
            })) ||
          '';
        break;
      case 'ABGELEHNT':
        statusTitle = t('job.documentForm.headline.rejected');
        statusTitleColor = colors.trafficLightRed;
        statusText =
          (certificate &&
            t('common.messages.rejectedAt', {
              date: DateUtil.getDateTimeFormattedExtended(certificate.createdAt),
            })) ||
          '';
        break;
      default:
        statusTitle = '';
    }
  }

  const showSendButton = certificate?.confirmed === 'INIT';

  const showResendButton = certificate?.confirmed === 'DEFAULT';

  const showConfirmButton = !!certificate && certificate.confirmed !== 'BESTAETIGT' && canCreateJob;

  const showPDF = !!certificate && !!certificateRecipient && job?.status !== 'LN-erstellt';

  const areFilesEditable =
    canCreateJob && certificate?.confirmed !== 'BESTAETIGT' && certificate?.confirmed !== 'ABGELEHNT';

  const showFiles = !!certificate && (areFilesEditable || files.length > 0);

  return (
    <>
      <PageContentSubHeading>{String(`${t('certificate.headline.singular')} ${certificate?.entryId || ''}`).trim()}</PageContentSubHeading>

      <Collapse show={!certificate && !isCreateMode && createCertificateStatus === 'idle'} fadeInOut>
        <UiDiv display="flex" justifyContent="center" paddingTop="s">
          <Button variant="highlight" icon={IconPlus} onClick={() => setIsCreateMode(true)}>
            {t('common.actions.create')}
          </Button>
        </UiDiv>
      </Collapse>

      <Collapse show={!!certificate || isCreateMode} fadeInOut>
        <UiDiv paddingTop="s">
          <LoadingWrapper isLoading={createCertificateStatus === 'loading'}>
            <UiDiv background="white" padding="m">
              <UiDiv background="grey20" padding="s">
                <UiText variant="h3" color={statusTitleColor} textAlign="center">
                  {statusTitle}
                </UiText>
                {!!certificate && (
                  <>
                    {statusText && (
                      <UiText variant="copy" textAlign="center" marginTop="xs">
                        {statusText}
                      </UiText>
                    )}
                    <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="s">
                      <Button
                        icon={showDetails ? IconArrowUp : IconArrowDown}
                        endIcon={certificate.fileIds && certificate.fileIds.length > 0 ? IconAttachment : undefined}
                        active={showDetails}
                        onClick={() => {
                          setShowDetails(!showDetails);
                          setShowHistory(false);
                        }}
                      >
                        {t('common.actions.show')}
                      </Button>
                      {certificateHistoriesSorted.length > 0 && (
                        <Button
                          icon={showHistory ? IconArrowUp : IconArrowDown}
                          active={showHistory}
                          onClick={() => {
                            setShowDetails(false);
                            setShowHistory(!showHistory);
                          }}
                        >
                          {t('job.history')}
                        </Button>
                      )}
                      {showPDF && (
                        <>
                          {pdfFile ? (
                            <Button
                              icon={IconDownload}
                              onClick={() => {
                                window.open(RestUtil.getDownloadUrl(pdfFile?.url), '_blank', '');
                              }}
                            >
                              {t('common.actions.downloadPdf')}
                            </Button>
                          ) : (
                            <Button icon={IconDownload} onClick={handleCreateCertificatePDF}>
                              {t('common.actions.downloadPdf')}
                            </Button>
                          )}
                        </>
                      )}
                    </UiDiv>
                  </>
                )}
              </UiDiv>

              <Collapse show={!certificate || showDetails} fadeInOut>
                <UiDiv paddingTop="m">
                  <JobCertificateEditor
                    jobId={jobId}
                    isCreateMode={isCreateMode}
                    orderNumber={orderNumber}
                    location={location}
                    startDate={serviceStartDate}
                    endDate={serviceEndDate}
                    comments={comments}
                    showPrices={showPrices}
                    onChangeInput={(key, value) => handleOnChangeInput(key, value)}
                    onChangeStartDate={(startDate) => handleChangeStartDate(startDate)}
                    onChangeEndDate={(endDate) => handleChangeEndDate(endDate)}
                  />
                  {showFiles && (
                    <UiDiv marginTop="m">
                      <JobFiles
                        files={files}
                        isEditable={areFilesEditable}
                        onAddFiles={(fileList) => handleUploadCertificateFiles(fileList)}
                        onDeleteFile={(fileId) => handleDeleteCertificateFile(fileId)}
                      />
                    </UiDiv>
                  )}
                </UiDiv>
              </Collapse>

              <Collapse show={showHistory} fadeInOut>
                <UiDiv paddingTop="m">
                  <JobHistoryList jobId={jobId} type="certificate" />
                </UiDiv>
              </Collapse>

              {isCreateMode && (
                <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="m">
                  <Button
                    variant="highlight"
                    icon={IconCheck}
                    disabled={!serviceStartDate || !serviceEndDate}
                    onClick={handleOnClickCreate}
                  >
                    {t('certificate.actions.create')}
                  </Button>
                  <Button variant="secondary" icon={IconClose} onClick={() => setIsCreateMode(false)}>
                    {t('common.actions.cancel')}
                  </Button>
                </UiDiv>
              )}

              {(showSendButton || showResendButton || showConfirmButton) && (
                <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="m">
                  {showSendButton && (
                    <Button
                      variant="highlight"
                      icon={IconArrowRight}
                      onClick={() => {
                        if (onClickSend) {
                          onClickSend(true);
                        }
                      }}
                    >
                      {t('certificate.actions.send')}
                    </Button>
                  )}
                  {showResendButton && (
                    <Button
                      variant="highlight"
                      icon={IconArrowRight}
                      onClick={() => {
                        if (onClickSend) {
                          onClickSend(false);
                        }
                      }}
                    >
                      {t('certificate.actions.resend')}
                    </Button>
                  )}
                  {showConfirmButton && (
                    <Button onClick={handleClickInstantConfirm}>{t('certificate.actions.accept')}</Button>
                  )}
                </UiDiv>
              )}
            </UiDiv>
          </LoadingWrapper>
        </UiDiv>
      </Collapse>
    </>
  );
};

export default JobCertificate;
