import React from 'react';
import { UiDiv, UiText } from '@webfox-sc/core';
import { useTheme } from 'styled-components';

export type BubbleSize = 's' | 'm' | 'xl';
interface BubbleProps {
  children: React.ReactNode;
  variant: string | 'red' | 'yellow' | 'green';
  size?: BubbleSize;
}

const Bubble: React.FC<BubbleProps> = ({ variant, size, children }) => {
  const theme = useTheme();

  const backgroundColorMap: Record<BubbleProps['variant'], string> = {
    red: theme.colors.trafficLightRed,
    yellow: theme.colors.trafficLightYellow,
    green: theme.colors.trafficLightGreen,
  };

  return (
    <UiDiv
      borderRadius="50px"
      background={backgroundColorMap[variant]}
      width={size === 'm' ? '40px' : size === 'xl' ? '100px' : '30px'}
      height={size === 'm' ? '40px' : size === 'xl' ? '100px' : '30px'}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <UiText variant={size === 'xl' ? 'bubbleXL' : 'copySmall'} fontWeight={700} color="white" textAlign="center">
        {children}
      </UiText>
    </UiDiv>
  );
};

export default Bubble;
