import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { clientSchema, jobSchema } from '../schemas';
import { getCompleteEntities } from '../schemaUtils';

export const fetchClients = (params?: any, withCount?: boolean): RequestEntitiesAction<ClientApiData[]> =>
  requestEntities({
    method: 'GET',
    path: '/clients',
    schema: [clientSchema],
    params,
    withCount,
  });

export const fetchClient = (clientId: number, params?: any): RequestEntitiesAction<ClientApiData> =>
  requestEntities({
    method: 'GET',
    path: `/clients/${clientId}`,
    schema: clientSchema,
    params,
  });

export const updateClient = (
  clientId: number,
  data: ClientUpdateData,
  params?: any
): RequestEntitiesAction<ClientApiData> =>
  requestEntities({
    method: 'PUT',
    path: `/clients/${clientId}`,
    data,
    schema: clientSchema,
    params,
  });

export const updateClientContacts = (
  clientId: number,
  data: ClientUpdateContactsData,
  params?: any
): RequestEntitiesAction<ClientApiData> =>
  requestEntities({
    method: 'PUT',
    path: `/clients/${clientId}/contacts`,
    data,
    schema: clientSchema,
    params,
  });

export const moveJobs = (
  clientId: number,
  data: ClientMoveJobsData,
  params?: any
): RequestEntitiesAction<ClientApiData> =>
  requestEntities({
    method: 'PUT',
    path: `/clients/${clientId}/move`,
    data,
    schema: [jobSchema],
    params,
  });
const clientsAdapter = createEntityAdapter<ClientEntity>();

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: clientsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.clients) {
        const completeEntities = getCompleteEntities(action.payload.entities.clients);
        if (Object.keys(completeEntities).length > 0) {
          clientsAdapter.upsertMany(state, completeEntities);
        }
      }
    });
  },
});

export default clientsSlice.reducer;
