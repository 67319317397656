import styled from 'styled-components';
import { useContentContext } from './ContentContext';
import SectionView from './SectionView';
import PageContent from '../page/PageContent';
import { SPACINGS } from '../../theme/theme';

interface ContentViewProps {
  selector: ContentSectionKey;
}

const StyledWrapper = styled.div({
  color: 'white',
});

const ContentView: React.FC<ContentViewProps> = ({ selector }) => {
  const contents = useContentContext();
  const content = contents[selector];

  return (
    <StyledWrapper>
      <PageContent paddingTop={SPACINGS.XXL}>
        <SectionView content={content} />
      </PageContent>
    </StyledWrapper>
  );
};

export default ContentView;
