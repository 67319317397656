/**
 * Path schemas
 */

export const pathSchemas = {
  qm: '/qm',
  qmAdd: '/qm/add',
  qmReport: '/qm/report/:reportId',
  qmReportroom: '/qm/reportroom/:reportroomId',
  qmReportroomEdit: '/qm/reportroom/:reportroomId/edit',

  qmAdmin: '/qm-admin',

  qmAdminCompany: '/qm-admin/company/:companyId',
  qmAdminCompanyConfigAdd: '/qm-admin/company/:companyId/config/add',
  qmAdminCompanyConfig: '/qm-admin/company/:companyId/config/:craftId',

  qmAdminClient: '/qm-admin/client/:clientId',
  qmAdminClientConfig: '/qm-admin/client/:clientId/config/:craftId',
  qmAdminClientReports: '/qm-admin/client/:clientId/reports',

  baseDataUsers: '/data/users',
  baseDataCompanies: '/data/companies',
  baseDataLocations: '/data/locations',
  baseDataClientGroups: '/data/clientgroups',
  baseDataClients: '/data/objects',
  baseDataServices: '/data/services',
  baseDataCostcenters: '/data/costcenter',
  baseDataCheckitems: '/data/checkitems',
  baseDataCheckitemgroups: '/data/checkitemgroups',
  baseDataCrafts: '/data/crafts',
  baseDataQm: '/data/qm',
  baseDataImport: '/data/import',
};

/**
 * QM
 */

export const pathToQM = (): string => {
  return pathSchemas.qm;
};

export const pathToQMAddAdHoc = (): string => {
  return pathSchemas.qmAdd;
};

export const pathToQMReport = (reportId: number, referrer?: string): string => {
  const basePath = pathSchemas.qmReport.replace(':reportId', `${reportId}`);
  if (referrer) {
    return `${basePath}?ref=${encodeURIComponent(referrer)}`;
  }
  return basePath;
};

export const pathToQMReportroom = (reportroomId: number, referrer?: string): string => {
  const basePath = pathSchemas.qmReportroom.replace(':reportroomId', `${reportroomId}`);
  if (referrer) {
    return `${basePath}?ref=${encodeURIComponent(referrer)}`;
  }
  return basePath;
};

export const pathToQMReportroomEdit = (reportroomId: number, referrer?: string, keepQueryString?: boolean): string => {
  const basePath = pathSchemas.qmReportroomEdit.replace(':reportroomId', `${reportroomId}`);
  if (referrer) {
    if (keepQueryString) {
      return `${basePath}${referrer}`;
    }
    return `${basePath}?ref=${encodeURIComponent(referrer)}`;
  }
  return basePath;
};

/**
 * QM Admin
 */

export const pathToQMAdmin = (): string => {
  return pathSchemas.qmAdmin;
};

export const pathToQMAdminCompany = (companyId: number): string => {
  return pathSchemas.qmAdminCompany.replace(':companyId', `${companyId}`);
};

export const pathToQMAdminCompanyConfigAdd = (companyId: number): string => {
  return pathSchemas.qmAdminCompanyConfigAdd.replace(':companyId', `${companyId}`);
};

export const pathToQMAdminCompanyConfig = (companyId: number, craftId?: number | string): string => {
  return pathSchemas.qmAdminCompanyConfig.replace(':companyId', `${companyId}`).replace(':craftId', `${craftId || 0}`);
};

export const pathToQMAdminClient = (clientId: number): string => {
  return pathSchemas.qmAdminClient.replace(':clientId', `${clientId}`);
};

export const pathToQMAdminClientConfig = (clientId: number, craftId?: number): string => {
  return pathSchemas.qmAdminClientConfig.replace(':clientId', `${clientId}`).replace(':craftId', `${craftId || 0}`);
};

export const pathToQMAdminClientReports = (clientId: number): string => {
  return pathSchemas.qmAdminClientReports.replace(':clientId', `${clientId}`);
};

/**
 * Base data
 */

export const pathToDefaultBaseData = (permissions: {
  canViewGeneralBaseData: boolean;
  canViewQMAdmin: boolean;
  canViewAllUsers: boolean;
}): string => {
  const { canViewGeneralBaseData, canViewQMAdmin, canViewAllUsers } = permissions;
  if (canViewGeneralBaseData) {
    if (canViewAllUsers) {
      return pathSchemas.baseDataUsers;
    }
    return pathSchemas.baseDataCompanies;
  }
  if (canViewQMAdmin) {
    return pathSchemas.baseDataQm;
  }
  return '/';
};
