import { InlineSVG, UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import IconInfo from '../../assets/icons-v2/IconInfo';
import { useAddress } from '../../app/entityHooks/useAddress';

const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

interface JobRecipientCompanyIconProps {
  isDisabled?: boolean;
  addressId: number | undefined;
}

const JobRecipientAddressIcon: React.FC<JobRecipientCompanyIconProps> = ({ isDisabled, addressId }) => {
  const { colors } = useTheme();
  const { address } = useAddress(addressId);

  const addressLines: string[] = useMemo(() => {
    const res: string[] = [];
    if (address?.line1) {
      res.push(address.line1);
    }
    if (address?.line2) {
      res.push(address.line2);
    }
    if (address?.line3) {
      res.push(address.line3);
    }
    if (address?.line4) {
      res.push(address.line4);
    }

    if (address?.po) {
      res.push(`Postfach ${address.po}`);
      res.push(`${address?.zip_po || ''} ${address?.city || ''}`);
    } else {
      res.push(address?.street || '');
      res.push(`${address?.zip || ''} ${address?.city || ''}`);
    }
    return res;
  }, [address]);

  const ref = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useOnClickOutside(ref, () => setIsPopupOpen(false));

  return (
    <UiDiv position="relative" boxSizing="border-box" width="20px" height="20px" padding="3px" marginLeft="10px">
      <UiDiv
        cursor={isDisabled ? 'inherit' : 'pointer'}
        onClick={() => setIsPopupOpen(true)}
        onMouseOver={() => setIsPopupOpen(true)}
        onMouseOut={() => setIsPopupOpen(false)}
      >
        <InlineSVG width="14px" height="14px" color={isDisabled ? colors.grey20 : colors.ciBlue3}>
          {IconInfo}
        </InlineSVG>
      </UiDiv>
      {isPopupOpen && !isDisabled && (
        <UiDiv position="absolute" top={0} left={0}>
          <UiText
            innerRef={ref}
            variant="copy"
            position="absolute"
            bottom={0}
            left={0}
            padding="xs"
            background="white"
            border={`2px solid ${colors.ciBlue3}`}
            whiteSpace="nowrap"
          >
            {addressLines.map((line, index) => (
              <UiDiv key={`l_${index}`}>{line}</UiDiv>
            ))}
          </UiText>
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default JobRecipientAddressIcon;
