import { pick, pull } from 'lodash';
import { CollectionFilterState, DropdownOption, DropdownOptionGroup, FilterEntity } from '../types/clientFilter';


export const DROPDOWN_GROUP_LABELS = {
  companies: 'Debitor Nr.',
  locations: 'Technischer Platz Nr.',
  costcenters: 'Arbeitsplatz',
};

export const GROUP_KEYS = ['all', 'companies', 'locations', 'costcenters'];

const DROPDOWN_PARAMS = [
  { identifier: 'costcenter', dataKey: 'costcenters' },
  { identifier: 'location', dataKey: 'locations' },
  { identifier: 'company', dataKey: 'companies' },
];

export const stripObject: (o: any) => FilterEntity = (obj) => pick(obj, ['name', 'number', 'description', 'id']);

const getLocationLabel = ({ number, description }) => {
  const matches = description && description.match(/^([\d]+)?\s*(.*)/);
  let descriptionParsed;
  if (matches && matches.length >= 3) {
    // eslint-disable-next-line prefer-destructuring
    descriptionParsed = matches[2];
  } else {
    descriptionParsed = description;
  }
  return `${number} – ${descriptionParsed}`;
};

export const getGroupLabel = (gk, dataObject: { number?: any } = {}): string => {
  if (gk === 'all') {
    return 'Passend:';
  }
  if (gk === 'none') {
    return 'Suchergebnis:';
  }
  if (gk === 'own') {
    return 'Zugewiesen:';
  }
  return `${DROPDOWN_GROUP_LABELS[gk]} ${dataObject?.number}:`;
};

export const getEntryLabel = (type, entry) => {
  switch (type) {
    case 'location':
      return getLocationLabel(entry);
    case 'company':
      return `${entry?.number} – ${entry?.name?.split(' (')[0]}`;
    case 'carrierDescription':
      return `${entry?.description}`;
    case 'clientDescription':
      return `${entry?.name}`;
    case 'client':
      if (entry && entry.isGroup) {
        return `TP-Gruppe ${entry?.debitorId}`;
      }
      return `${entry?.company?.name || ''} ${entry?.company?.number || ''} (${entry?.location?.number || ''}), AP. ${
        entry?.costcenter?.number || ''
      }`;
    case 'costcenterClient':
      return `${entry?.company?.name || ''} (${entry?.location?.number || ''})`;
    default:
      return `${entry?.number}`;
  }
};

export const getEntries = (type, entries): DropdownOption =>
  entries.map((entry) => {
    const res = stripObject(entry) as DropdownOption;
    res.value = entry?.id;
    res.label = getEntryLabel(type, entry);
    return res;
  });

export const createClientDropdowns = (filterMap): Record<string, DropdownOptionGroup[]> => {
  const dropdownParams = Object.values(DROPDOWN_PARAMS);
  const dropdowns = {};
  dropdownParams.forEach(({ identifier, dataKey }) => {
    dropdowns[identifier] = [];
    if (filterMap && filterMap[dataKey] && filterMap[dataKey].group) {
      // check other filter objects for grouped options
      const otherKeys = [...GROUP_KEYS];
      pull(otherKeys, dataKey);
      otherKeys.forEach((key) => {
        if (filterMap[dataKey].group[key]) {
          dropdowns[identifier].push({
            label: getGroupLabel(key, filterMap[key].selected),
            options: getEntries(identifier, filterMap[dataKey].group[key]),
          });
        }
      });
    }
  });
  return dropdowns;
};

export const getClientOptions = (clients: ClientApiData[]): CollectionFilterState => {
  const locationsMap: Record<number, FilterEntity> = {};
  const companiesMap: Record<number, FilterEntity> = {};
  const costcentersMap: Record<number, FilterEntity> = {};
  const res: {
    clientIds: number[];
    locations: FilterEntity[];
    companies: FilterEntity[];
    costcenters: FilterEntity[];
  } = {
    clientIds: [],
    locations: [],
    companies: [],
    costcenters: [],
  };

  clients.forEach(({ location, company, costcenter_rel, id }) => {
    res.clientIds.push(id);
    if (location && typeof location === 'object' && !locationsMap[location.id]) {
      locationsMap[location.id] = stripObject(location);
    }
    if (company && typeof company === 'object' && !companiesMap[company.id]) {
      companiesMap[company.id] = stripObject(company);
    }
    if (costcenter_rel && typeof costcenter_rel === 'object' && !costcentersMap[costcenter_rel.id]) {
      costcentersMap[costcenter_rel.id] = stripObject(costcenter_rel);
    }
  });
  const locations = Object.values(locationsMap);
  if (locations.length > 0) {
    res.locations = locations;
  }
  const companies = Object.values(companiesMap);
  if (companies.length > 0) {
    res.companies = companies;
  }
  const costcenters = Object.values(costcentersMap);
  if (costcenters.length > 0) {
    res.costcenters = costcenters;
  }
  return res;
};
