import React, { useEffect, useState } from 'react';
import { UiDiv } from '@webfox-sc/core';

import { useAppDispatch } from '../../../app/appHooks';
import { useRoomgroup } from '../../../app/entityHooks/useRoomgroup';
import { createRoomgroup, deleteRoomgroup, updateRoomgroup } from '../../../app/slices/roomgroupsSlice';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import Button from '../../atoms-v2/Button';
import Checkbox from '../../atoms-v2/Checkbox';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import TextInput from '../../atoms-v2/TextInput';
import { useTranslation } from 'react-i18next';

interface BaseDataQMRoomgroupFormProps {
  roomgroupId?: number | undefined;
  craftId?: number | undefined;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

const BaseDataQMRoomgroupForm: React.FC<BaseDataQMRoomgroupFormProps> = ({ roomgroupId, craftId, onSubmit, onClickCancel }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formMode = roomgroupId ? 'update' : 'create';

  const { roomgroup } = useRoomgroup(roomgroupId);
  const [roomgroupData, setRoomgroupData] = useState<RoomgroupUpdateData>({});

  useEffect(() => {
    setRoomgroupData({
      name: roomgroup?.name,
      disabled: roomgroup ? roomgroup?.disabled : true,
      craft: roomgroup ? roomgroup.craftId : craftId
    });
  }, [roomgroup, craftId]);

  const isFormDataValid = () => {
    const { name } = roomgroupData;
    return !!name;
  };

  const handleChangeValue = (key: string, value: any) => {
    const roomgroupDataUpdated: RoomgroupUpdateData = { ...roomgroupData };
    roomgroupDataUpdated[key] = value;
    setRoomgroupData(roomgroupDataUpdated);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (formMode === 'create') {
      await dispatch(createRoomgroup(roomgroupData));
    } else if (roomgroupId) {
      await dispatch(updateRoomgroup(roomgroupId, roomgroupData));
    }
    if (onSubmit) {
      onSubmit(e);
    }
  };

  const handleOnClickDelete: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (roomgroup) {
      if (window.confirm(t('roomgroup.prompts.delete', { name: roomgroup.name }) || undefined)) {
        dispatch(deleteRoomgroup(roomgroup.id));
      }
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" rowGap="s" columnGap="xs">
        <UiDiv gridColumn="1 / 10">
          <FormElementLabel>{t('common.fields.name')} *</FormElementLabel>
          <TextInput value={roomgroupData.name} onChange={(e) => handleChangeValue('name', e.target.value)} />
        </UiDiv>
        <UiDiv gridColumn="10 / 13">
          <FormElementLabel>{t('common.qm.preSelected')}</FormElementLabel>
          <Checkbox
            checked={!roomgroupData.disabled}
            onChange={(e) => handleChangeValue('disabled', !e.target.checked)}
          />
        </UiDiv>
      </UiDiv>
      <UiDiv display="flex" columnGap="xs" justifyContent="space-between" paddingTop="s">
        <UiDiv display="flex" columnGap="xs">
          <Button type="submit" icon={IconCheck} disabled={!isFormDataValid()}>
            {formMode === 'create' ? 'Erstellen' : 'Übernehmen'}
          </Button>
          <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
            {t('common.actions.cancel')}
          </Button>
        </UiDiv>
        {formMode === 'update' && (
          <Button icon={IconDelete} onClick={handleOnClickDelete}>
            {t('common.actions.delete')}
          </Button>
        )}
      </UiDiv>
    </form>
  );
};

export default BaseDataQMRoomgroupForm;
