import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@webfox-sc/core';
import { COLORS, STYLE_TEXT_M } from '../../theme/theme';

const theme = {
    root: {
        border: 'none',
        background: 'transparent',
        padding: 0,
        outline: 0,
        span: {
            ...STYLE_TEXT_M,
            textDecoration: 'underline',
            color: COLORS.WHITE,
        },
    },
};

const TextButton = (props) => {
    return <Button {...props} theme={theme} />;
};

TextButton.propTypes = {
    ...Button.propTypes,
    label: PropTypes.string,
};

TextButton.defaultProps = {
    ...Button.defaultProps,
    label: '',
};

export default TextButton;
