import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';
import { entitySelectors } from '../../../app/entitySelectors';
import { useUser } from '../../../app/entityHooks/useUser';

export const useBaseDataUser = (
  userId: number | undefined
): {
  user: UserEntity | undefined;
  costcentersSorted: CostcenterEntity[];
} => {
  const { user } = useUser(userId);

  const costcenters = entitySelectors.selectByIds<CostcenterEntity>('costcenters', user?.costcenterIds);
  const costcentersSorted: CostcenterEntity[] = useMemo(() => {
    return _orderBy(costcenters, [(costcenter: CostcenterEntity) => costcenter.number], ['asc']);
  }, [costcenters]);

  return {
    user,
    costcentersSorted,
  };
};
