import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { checkitemSchema } from '../schemas';

const populateOptions = {
  checkitemgroup: {
    populate: {
      checkitems: {
        populate: '*',
      },
    },
  },
};

export const createCheckitem = (data: CheckitemCreateData): RequestEntitiesAction<CheckitemData> =>
  requestEntities({
    method: 'POST',
    path: '/checkitems',
    params: {
      populate: populateOptions,
    },
    data,
    schema: checkitemSchema,
  });

export const updateCheckitem = (checkitemId: number, data: CheckitemUpdateData): RequestEntitiesAction<CheckitemData> =>
  requestEntities({
    method: 'PUT',
    path: `/checkitems/${checkitemId}`,
    params: {
      populate: populateOptions,
    },
    data,
    schema: checkitemSchema,
  });

export const deleteCheckitem = (checkitemId: number): RequestEntitiesAction<CheckitemData> =>
  requestEntities({
    method: 'DELETE',
    path: `/checkitems/${checkitemId}`,
    schema: checkitemSchema,
    type: 'deleteCheckitem',
  });

const checkitemsAdapter = createEntityAdapter<CheckitemEntity>();

export const checkitemsSlice = createSlice({
  name: 'checkitems',
  initialState: checkitemsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.checkitems) {
        checkitemsAdapter.upsertMany(state, action.payload.entities.checkitems);
      }
      if (action.meta.arg.type === 'deleteCheckitem') {
        checkitemsAdapter.removeOne(state, action.payload.data.id);
      }
    });
  },
});

export default checkitemsSlice.reducer;
