const IconArrowUp = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M45.65,19.34a5.62,5.62,0,0,1,8.7,0l44.37,54.2a5.62,5.62,0,0,1-4.35,9.18H5.63a5.62,5.62,0,0,1-4.35-9.18Z"
    />
  </svg>
);

export default IconArrowUp;
