import { USE_SCHEDULE_INTERVAL } from '../app.config';
import DateUtil from './DateUtil';

class ScheduleUtil {
  static getFrequencyIntervalFormatted(
    frequency: number | undefined,
    interval: ScheduleInterval | undefined,
    t: (key: string, params?: Record<string, any>) => string
  ): string | undefined {
    if (!frequency || !interval) {
      return undefined;
    }
    return `${frequency} x ${t(`schedule.form.frequency.${interval}`)}`;
  }

  static getIntervalData(date: Date | null | undefined, interval: ScheduleInterval | undefined): Record<string, any> {
    if (!date) {
      return {};
    }
    if (interval === 'annually') {
      return { year: date.getFullYear() };
    }
    if (interval === 'semiAnnually') {
      return { semester: Math.ceil((date.getMonth() + 1) / 6), year: date.getFullYear() };
    }
    if (interval === 'quarterly') {
      return { quarter: Math.ceil((date.getMonth() + 1) / 3), year: date.getFullYear() };
    }
    if (interval === 'monthly') {
      return { month: DateUtil.getMonthName(date), year: date.getFullYear() };
    }

    return { date: DateUtil.getDateFormatted(date) };
  }

  static getScheduleDateFormatted(
    date: Date | null | undefined,
    interval: ScheduleInterval | undefined,
    t: (key: string, params: Record<string, any>) => string,
    prefix = 'schedule.form.date'
  ): string | undefined {
    if (USE_SCHEDULE_INTERVAL) {
      return t(`${prefix}.${interval || 'exact'}`, this.getIntervalData(date, interval));
    }
    return t(`${prefix}.exact`, { date: DateUtil.getDateFormatted(date) });
  }
}

export default ScheduleUtil;
