import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getErrorMessageFromResponse } from '../../utils/ErrorUtil';
import { requestEntities } from '../requestEntities';
import { RootState } from '../store';

export const limitInterval = 10;

export interface ErrorState {
  errorHeading?: string | null;
  errorText?: string | null;
  showError?: boolean;
}

const initialState: ErrorState = {
  errorHeading: null,
  errorText: null,
  showError: false,
};

export const errorSlice = createSlice({
  name: 'errors',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.rejected, (state, action) => {
        if (action?.payload?.error?.statusCode) {
          const { statusCode } = action?.payload?.error;
          const { message } = action?.error;
          const { path, method, type } = action.meta.arg;

          const errorMessage = getErrorMessageFromResponse({
            statusCode,
            message,
            path,
            method,
            type
          });

          if (errorMessage) {
            const { errorHeading, errorText } = errorMessage;
            state.showError = true;
            state.errorHeading = errorHeading;
            state.errorText = errorText;
          }
        }
      });
  },
  reducers: {
    setErrorState: (state: ErrorState, action: PayloadAction<ErrorState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setErrorState } = errorSlice.actions;
export const selectErrorState = (state: RootState): ErrorState => state.errorState;
export default errorSlice.reducer;
