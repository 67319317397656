import { UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../../app/appHooks';
import { pathToQM, pathToQMReportroomEdit } from '../../app/paths';
import { createReportroom } from '../../app/slices/reportroomsSlice';
import { createReportPDF, deleteReport, fetchReport, updateReport } from '../../app/slices/reportsSlice';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import iconPlus from '../../assets/icons-v2/IconPlus';
import DateUtil from '../../utils/DateUtil';
import Button from '../atoms-v2/Button';
import ResultBubble from '../atoms-v2/ResultBubble';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';

import IconDelete from '../../assets/icons-v2/IconDelete';
import IconDownload from '../../assets/icons-v2/IconDownload';
import AppUtil from '../../utils/AppUtil';
import { usePermissions } from '../auth/usePermissions';
import QMReportComment from './QMReportComment';
import QMReportMetaInfo from './QMReportMetaInfo';
import QMReportReportroomTeaser from './QMReportReportroomTeaser';
import { useQMReport } from './useQMReport';

const QMReport: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { canDeleteAllQMReports } = usePermissions();
  const { reportId: reportIdString } = useParams();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('ref');

  // const [isCommentExtended, setIsCommentExtended] = useState(false);

  const reportId = reportIdString ? parseInt(reportIdString) : -1;
  const {
    contacts,
    reporter,
    report,
    validRoomCount,
    reportroomsFinished,
    reportroomsPending,
    countReportroomsFinished,
    countReportroomsUnfinished,
    countReportroomsPending,
    craft,
  } = useQMReport(reportId);
  useEffect(() => {
    if (reportId) {
      dispatch(
        fetchReport(reportId, {
          populate: {
            client: {
              populate: {
                company: {
                  fields: ['id'],
                },
                location: {
                  fields: ['id'],
                },
                costcenter_rel: {
                  fields: ['id'],
                },
              },
            },
            craft: {
              fields: ['id', 'name'],
            },
            contacts: '*',
            reporter: '*',
            reportrooms: {
              populate: {
                roomgroup: '*',
                report: { fields: ['id'] },
                checkresults: { populate: { checkitem: '*', pictures: '*' } },
              },
            },
          },
        })
      );
    }
  }, [dispatch, reportId]);

  const backPath = useMemo(() => {
    if (referrer) {
      return referrer;
    }
    return pathToQM();
  }, [referrer]);

  if (!report) {
    return null;
  }

  const handleOnClickDelete = async () => {
    if (window.confirm(t('report.prompts.delete') || undefined)) {
      await dispatch(deleteReport(report.id));
      navigate(backPath);
    }
  };

  const handleCreatePDF = async () => {
    const res = await dispatch(createReportPDF(reportId));
    AppUtil.openDownload(res.payload?.data);
  };

  const handleCreateReportroom = async () => {
    const response = await dispatch(
      createReportroom({
        report: report.id,
      })
    );
    const reportroomId = response?.payload?.data?.id;
    if (reportroomId) {
      navigate(pathToQMReportroomEdit(reportroomId, `${pathname}${search}`));
    }
  };

  const handleOnSaveComment = (comment?: string) => {
    if (comment !== report?.comment) {
      dispatch(
        updateReport(report.id, {
          comment,
        })
      );
    }
  };

  const handleFinishReport = async () => {
    await dispatch(
      updateReport(report.id, {
        status: 'finished',
      })
    );
    navigate(backPath);
  };

  const reportTypeFormatted = report.isAdHoc ? t('report.form.adHoc.true') : t('report.form.adHoc.false');

  return (
    <>
      <PageHeading
        heading={
          report.status === 'finished'
            ? reportTypeFormatted
            : t('report.headline.execute', { type: reportTypeFormatted }) || ''
        }
        backlinkLabel={t('common.actions.back') || undefined}
        backlinkPath={backPath}
      />

      <PageContent marginTop="l">
        <QMReportMetaInfo
          companyName={report.companyName}
          locationDescription={report.locationDescription}
          costcenterNumber={report.costcenterNumber}
          contacts={contacts}
          reporter={reporter}
          dateFormatted={report.status === 'finished' ? DateUtil.getDateFormatted(report.date) : undefined}
          dueDateFormatted={report.status !== 'finished' ? DateUtil.getDateFormatted(report.dueDate) : undefined}
          craftName={craft?.name || t('craft.form.options.default') || undefined}
        >
          {/* Comment */}
          {report.status !== 'finished' || !!report.comment ? (
            <UiDiv display="flex" marginTop="s">
              <QMReportComment
                editMode={report.status !== 'finished'}
                comment={report?.comment}
                onSave={(comment) => handleOnSaveComment(comment)}
              />
            </UiDiv>
          ) : null}
        </QMReportMetaInfo>

        <UiText variant="h2" color="white" textAlign="center" marginTop="l">
          {t('report.minRoomCount')}: {validRoomCount}{' '}
          {validRoomCount === 1 ? t('report.textFragments.room.singular') : t('report.textFragments.room.plural')}
        </UiText>

        {/*
        Overall result
        */}
        {countReportroomsUnfinished === 0 && (
          <UiDiv background="white" color="ciBlue1" padding="m" marginTop="m">
            <UiDiv>
              <UiText variant="h3" textAlign="center">
                {t('report.sorePercentage')}
              </UiText>
              <UiDiv display="flex" justifyContent="center" marginTop="s">
                <ResultBubble result={report.scorePercentage || 0} size="xl" />
              </UiDiv>
              {countReportroomsPending === 0 && report.status !== 'finished' && (
                <UiDiv display="flex" justifyContent="center" marginTop="s">
                  <Button variant="highlight" icon={IconArrowRight} onClick={handleFinishReport}>
                    {t('report.actions.finishAndSend')}
                  </Button>
                </UiDiv>
              )}
            </UiDiv>
          </UiDiv>
        )}

        {/*
        Finished reportrooms
        */}
        {countReportroomsFinished > 0 && (
          <UiDiv marginTop="m">
            <UiText variant="h3" color="white" textAlign="center">
              {countReportroomsFinished} {t('report.textFragments.finished')}{' '}
              {countReportroomsFinished === 1 ? t('report.reportRoom.singular') : t('report.reportRoom.plural')}
            </UiText>
            <UiDiv display="flex" flexDirection="column" rowGap={{ mobile: 's', tablet: 'xs' }} marginTop="m">
              {reportroomsFinished.map((reportroom) => {
                return <QMReportReportroomTeaser key={reportroom.id} reportroomId={reportroom.id} />;
              })}
            </UiDiv>
          </UiDiv>
        )}

        {/*
        Pending reportrooms
        */}
        {countReportroomsPending > 0 && (
          <UiDiv marginTop="m">
            <UiText variant="h3" color="white" textAlign="center">
              {countReportroomsPending} {t('report.textFragments.started')}{' '}
              {countReportroomsPending === 1 ? t('report.reportRoom.singular') : t('report.reportRoom.plural')}
            </UiText>
            <UiDiv display="flex" flexDirection="column" rowGap={{ mobile: 's', tablet: 'xs' }} marginTop="m">
              {reportroomsPending.map((reportroom) => {
                return <QMReportReportroomTeaser key={reportroom.id} reportroomId={reportroom.id} />;
              })}
            </UiDiv>
          </UiDiv>
        )}

        {/*
        Add reportroom
        */}
        {countReportroomsPending === 0 && report.status !== 'finished' && (
          <UiDiv background="white" padding="m" marginTop="m">
            {countReportroomsUnfinished > 0 && (
              <UiText variant="h3" textAlign="center">
                {t('report.info.reportRoomsToCheck', {
                  count: countReportroomsUnfinished,
                  item:
                    countReportroomsUnfinished === 1
                      ? t('report.textFragments.room.singular')
                      : t('report.textFragments.room.plural'),
                })}
              </UiText>
            )}
            <UiDiv display="flex" alignItems="center" justifyContent="center" marginTop="s">
              <Button variant="highlight" icon={iconPlus} onClick={handleCreateReportroom}>
                {t('report.actions.addReportRoom')}
              </Button>
            </UiDiv>
          </UiDiv>
        )}

        {/* Download report */}
        {report.status === 'finished' && (
          <UiDiv display="flex" alignItems="center" justifyContent="center" marginTop="m">
            <Button variant="secondaryRed" icon={IconDownload} onClick={handleCreatePDF}>
              {t('report.actions.download')}
            </Button>
          </UiDiv>
        )}
        {/*
        Delete Report
        */}
        {(canDeleteAllQMReports || report.status !== 'finished') && (
          <UiDiv display="flex" alignItems="center" justifyContent="center" marginTop="m">
            <Button variant="secondaryRed" icon={IconDelete} onClick={handleOnClickDelete}>
              {t('report.actions.delete')}
            </Button>
          </UiDiv>
        )}
      </PageContent>
    </>
  );
};

export default QMReport;
