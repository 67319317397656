import React from 'react';
import { useDrop } from 'react-dnd';
import { UiDiv } from '@webfox-sc/core';
import { useTheme } from 'styled-components';

interface BaseDataQMDropTargetProps {
  parentId: number;
  dropTargetIndex: number;
  accept: string | string[];
  onDrop?: (item: DragItem, dropTargetIndex: number) => void;
}

const BaseDataQMDropTarget: React.FC<BaseDataQMDropTargetProps> = ({ parentId, dropTargetIndex, accept, onDrop }) => {
  const theme = useTheme();

  const [{ isDragOver, canDrop }, dropTarget] = useDrop(
    () => ({
      accept,
      collect: (monitor) => ({
        isDragOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      canDrop: (item) => {
        return item.parentId === parentId && item.index !== dropTargetIndex && item.index + 1 !== dropTargetIndex;
      },
      drop: (item: DragItem) => {
        if (onDrop) {
          onDrop(item, dropTargetIndex);
        }
      },
    }),
    [parentId, dropTargetIndex, onDrop]
  );

  return (
    <div ref={dropTarget}>
      <UiDiv
        display="flex"
        alignItems="center"
        padding="4px 0"
        opacity={0}
        style={{
          opacity: canDrop ? 1 : 0,
        }}
      >
        <UiDiv
          width="100%"
          height={0}
          background="white"
          borderTop={`2px dashed ${theme.colors.ciRed}`}
          transition="height 0.1s ease"
          style={{
            height: canDrop && isDragOver ? '52px' : undefined,
            border: canDrop && isDragOver ? `2px dashed ${theme.colors.acGreen}` : undefined,
          }}
        />
      </UiDiv>
    </div>
  );
};

export default BaseDataQMDropTarget;
