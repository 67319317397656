import { Block, Group } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { pathSchemas } from '../../app/paths';
import IconPlus from '../../assets/icons-v2/IconPlus';
import Button from '../atoms-v2/Button';
import { usePermissions } from '../auth/usePermissions';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';

const BaseData: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { canViewGeneralBaseData, canViewCostcenterBaseData, canViewQMAdmin, canViewAllUsers } = usePermissions();

  const handleClickNavItem = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <PageHeading heading="Stammdaten" />
      <PageContent marginTop="l">
        <Group gap="xs" justifyContent="center">
          {canViewAllUsers && (
            <Button onClick={() => handleClickNavItem(pathSchemas.baseDataUsers)} active={pathname === pathSchemas.baseDataUsers}>
              {t('user.navItem')}
            </Button>
          )}
          {canViewGeneralBaseData && (
            <>
              <Button onClick={() => handleClickNavItem(pathSchemas.baseDataCompanies)} active={pathname === pathSchemas.baseDataCompanies}>
                {t('company.navItem')}
              </Button>
              <Button onClick={() => handleClickNavItem(pathSchemas.baseDataLocations)} active={pathname === pathSchemas.baseDataLocations}>
                {t('location.navItem')}
              </Button>
              <Button
                onClick={() => handleClickNavItem(pathSchemas.baseDataClientGroups)}
                active={pathname === pathSchemas.baseDataClientGroups}
              >
                {t('clientGroup.navItem')}
              </Button>
              <Button onClick={() => handleClickNavItem(pathSchemas.baseDataClients)} active={pathname === pathSchemas.baseDataClients}>
                {t('client.navItem')}
              </Button>
              <Button onClick={() => handleClickNavItem(pathSchemas.baseDataServices)} active={pathname === pathSchemas.baseDataServices}>
                {t('service.navItem')}
              </Button>
            </>
          )}
          {(canViewCostcenterBaseData &&
            <Button onClick={() => handleClickNavItem(pathSchemas.baseDataCostcenters)} active={pathname === pathSchemas.baseDataCostcenters}>
              {t('costcenter.navItem')}
            </Button>
          )}
          {canViewQMAdmin && (
            <>
              <Button onClick={() => handleClickNavItem(pathSchemas.baseDataCrafts)} active={pathname === pathSchemas.baseDataCrafts}>
                {t('craft.navItem')}
              </Button>
              <Button onClick={() => handleClickNavItem(pathSchemas.baseDataCheckitemgroups)} active={pathname === pathSchemas.baseDataCheckitemgroups}>
                {t('checkitemGroup.navItem')}
              </Button>
              <Button onClick={() => handleClickNavItem(pathSchemas.baseDataCheckitems)} active={pathname === pathSchemas.baseDataCheckitems}>
                {t('checkitem.navItem')}
              </Button>
              <Button onClick={() => handleClickNavItem(pathSchemas.baseDataQm)} active={pathname === pathSchemas.baseDataQm}>
                {t('check.navItem')}
              </Button>
            </>
          )}
          {canViewGeneralBaseData && (
            <Button
              icon={IconPlus}
              onClick={() => handleClickNavItem(pathSchemas.baseDataImport)}
              active={pathname === pathSchemas.baseDataImport}
            >
              {t('import.navItem')}
            </Button>
          )}
        </Group>

        <Block paddingTop="l">
          <Outlet />
        </Block>
      </PageContent>
    </>
  );
};

export default BaseData;
