import { omit, pickBy } from 'lodash';

type AnyData = {
  [props: string]: any;
};

export const getEntityId = (dataOrId: AnyData | number | null | undefined): number | undefined => {
  if (!dataOrId) {
    return undefined;
  }

  if (typeof dataOrId === 'object') {
    return dataOrId.id || dataOrId._id;
  }

  if (typeof dataOrId === 'number') {
    return dataOrId;
  }

  return undefined;
};

export const isCompleteEntity = (data) => Object.keys(omit(data, ['id'])).length > 0;

export const getCompleteEntities = (data) => pickBy(data, ({ id, ...other }) => Object.keys(other).length > 0);
