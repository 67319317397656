import { useMemo } from 'react';
import _filter from 'lodash/filter';

import { entitySelectors } from '../../../app/entitySelectors';

export const useQMAdminConfigCounts = (
  filter: SpecificityEntry
): {
  roomgroups: RoomgroupEntity[] | undefined;
  roomgroupsCount: number;
  checkitemCount: number;
  craft: CraftEntity | undefined;
} => {
  const roomgroups = entitySelectors.selectAll<RoomgroupEntity>('roomgroups');
  const craft = filter.craftId ? entitySelectors.selectById<CraftEntity>('crafts', filter.craftId) : undefined;

  const entities = useMemo(() => {
    const filteredRoomgroups: RoomgroupEntity[] = _filter(roomgroups, ({ clientId, companyId, craftId }) => {        // only roomgroups for craft, if id in filter is 0, return roomgroups without craft, if filter is otherwise falsy, return all
      // only roomgroups for craft, if id in filter is 0, return roomgroups without craft, if filter is otherwise falsy, return all
      if (!!filter.craftId && filter.craftId === craftId || !filter.craftId && (filter.craftId !== 0 || !craftId)) {
        // only global roomgroups
        if (!filter.companyId && !filter.clientId && !companyId && !clientId) {
            return true;
        }
  
        // only company related roomgroups
        if (!!filter.companyId && !filter.clientId && filter.companyId === companyId && !clientId) {
            return true;
        }
  
        // only client related roomgroups
        if (!!filter.clientId && filter.clientId === clientId && !filter.companyId) {
            return true;
        }
      }
      return false;
    });

    const enabledRoomgroups: RoomgroupEntity[] = _filter(filteredRoomgroups, ({ disabled }) => !disabled);

    return {
      roomgroups: filteredRoomgroups,
      roomgroupsCount: enabledRoomgroups.length,
      checkitemCount: enabledRoomgroups.reduce((cnt, roomgroup) => cnt + (roomgroup?.checkitemCount || 0), 0),
    };
  }, [roomgroups, filter]);

  return {
    ...entities,
    craft
  };
};
