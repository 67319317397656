/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useUserActions } from './useUserActions';

export const UserOptionsContext = createContext();

export const UserOptionsProvider = ({ children }) => {
  const users = useSelector(({ usersState }) => usersState.userModels);
  const isLoadingUsers = useSelector(({ usersState }) => usersState.isLoadingUsers);
  const { fetchUsers } = useUserActions();

  useEffect(() => {
    if ((!users || Object.keys(users).length === 0) && !isLoadingUsers) {
      fetchUsers();
    }
  }, []);

  const userOptions = useMemo(() => (users && Object.values(users)) || []).map(
    (user) => ({ value: user.id, text: user.username }),
    [users]
  );

  return (
    <UserOptionsContext.Provider
      value={{
        userOptions,
      }}
    >
      {children}
    </UserOptionsContext.Provider>
  );
};

UserOptionsProvider.propTypes = {
  children: PropTypes.node,
};

UserOptionsProvider.defaultProps = {
  children: null,
};

export const useUserOptions = () => {
  return useContext(UserOptionsContext);
};
