import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities } from '../requestEntities';

const picturesAdapter = createEntityAdapter<PictureEntity>();

export const picturesSlice = createSlice({
  name: 'pictures',
  initialState: picturesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.pictures) {
        picturesAdapter.upsertMany(state, action.payload.entities.pictures);
      }
    });
  },
});

export default picturesSlice.reducer;
