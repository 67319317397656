import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import BaseDataFilteredList from '../../components/base-data/shared/BaseDataFilteredList';
import { usePaginatedList } from '../../components/base-data/shared/usePaginatedList';
import ClientGroupEditor from '../../components/ClientGroupEditor';
import TableHeaderCell from '../../components/elements/TableHeaderCell';
import TableHeaderRow from '../../components/elements/TableHeaderRow';
import SearchFilter from '../../components/SearchFilter';
import { BaseDataFilterProvider } from '../../hooks/baseDataFilter';
import { BaseDataViewProvider } from '../../hooks/baseDataView';
import { useClientGroupsFilter } from '../../hooks/useClientGroupsFilter';
import { useClientsActions } from '../../hooks/useClientsActions';
import { deleteAllClientGroups } from '../../redux-actions/actionsClients';
import ClientsUtil from '../../utils/ClientsUtil';
import ClientGroupListItem from './ClientGroupListItem';

const ViewBaseDataClientGroups = () => {
  const { t } = useTranslation();
  const { isDataFilter, initialTableFilter } = useClientGroupsFilter();
  const { fetchClientGroups } = useClientsActions();

  const dataLoaderProps = {
    objectPath: 'clients',
    fetchObjects: (params) => fetchClientGroups({ ...params, populate: ClientsUtil.baseViewClientGroupsPopulate }),
  };

  const dispatch = useDispatch();

  return (
    <BaseDataFilterProvider isDataFilter={isDataFilter} initialTableFilter={initialTableFilter}>
      <BaseDataViewProvider
        objectPath="clients"
        getNewObject={ClientsUtil.getEmptyClientGroup}
        useFilter={useClientGroupsFilter}
        deleteObjects={(ids) => dispatch(deleteAllClientGroups(ids))}
        dataLoader={usePaginatedList}
        dataLoaderProps={dataLoaderProps}
      >
        <BaseDataFilteredList
          labelObjects={t('common.entities.clientGroup.plural')}
          labelNew={t('clientGroup.actions.new')}
          toolbar={
            <>
              <SearchFilter />
            </>
          }
          Editor={ClientGroupEditor}
          TableHeader={({ handleSort, sortingKey, sortingOrder }) => (
            <TableHeaderRow>
              <TableHeaderCell
                text={t('clientGroup.debitorId')}
                width="15%"
                isSorting
                onClick={() => handleSort('debitorId')}
                sortingOrder={sortingKey === 'debitorId' ? sortingOrder : ''}
              />
              <TableHeaderCell text="Objekte" width="61%" />
              <TableHeaderCell
                text={t('common.fields.updatedAt')}
                width="24%"
                isSorting
                onClick={() => handleSort('updatedAt')}
                sortingOrder={sortingKey === 'updatedAt' ? sortingOrder : ''}
              />
            </TableHeaderRow>
          )}
          TableRow={ClientGroupListItem}
          newEditorPresets={{
            showAdvice: true,
            isError: true,
            adviceText: t('common.info.invalidFromImport'),
          }}
        />
      </BaseDataViewProvider>
    </BaseDataFilterProvider>
  );
};

export default ViewBaseDataClientGroups;
