import { Collapse, UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { useRoomgroups } from '../../../app/entityHooks/useRoomgroups';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import Button from '../../atoms-v2/Button';
import BaseDataQMRoomgroup from './BaseDataQMRoomgroup';
import BaseDataQMRoomgroupForm from './BaseDataQMRoomgroupForm';

interface BaseDataQMRoomgroupListProps {
  craftId: number | undefined;
  onSubmit: () => void;
}

const BaseDataQMRoomgroupList: React.FC<BaseDataQMRoomgroupListProps> = ({ craftId, onSubmit }) => {
  const { t } = useTranslation();
  const { roomgroupIds } = useRoomgroups(craftId);
  const [showCreateForm, setShowCreateForm] = useState(false);

  return (
    <DndProvider backend={HTML5Backend}>
      {roomgroupIds.length > 0 && (
        <UiDiv display="flex" flexDirection="column" rowGap="xs" marginBottom="xs">
          {roomgroupIds.map((roomgroupId) => {
            return <BaseDataQMRoomgroup key={roomgroupId} roomgroupId={roomgroupId} />;
          })}
        </UiDiv>
      )}
      <Button icon={IconPlus} onClick={() => setShowCreateForm(!showCreateForm)}>
        {t('roomgroup.baseData.actions.new')}
      </Button>
      <Collapse show={showCreateForm} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="xs">
          <UiDiv padding="s" background="grey20">
            <BaseDataQMRoomgroupForm
              onSubmit={() => {
                onSubmit();
                setShowCreateForm(false);
              }}
              craftId={craftId}
              onClickCancel={() => setShowCreateForm(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
    </DndProvider>
  );
};

export default BaseDataQMRoomgroupList;
