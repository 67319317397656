/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './copy-text-m.scss';

class CopyTextM extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, children, bold } = this.props;

        if (text) {
            return (
                <span
                    className={`smg-copy-text-m ${bold && 'smg-copy-text-m-bold'}`}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            );
        }

        return <span className={`smg-copy-text-m ${bold && 'smg-copy-text-m-bold'}`}>{children}</span>;
    }
}

CopyTextM.propTypes = {
    text: PropTypes.string,
    children: PropTypes.any
};

CopyTextM.defaultProps = {
    text: '',
    children: null
};

export default CopyTextM;
