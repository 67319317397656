
const messages = {
  t1: 'Der Server ist gerade nicht erreichbar, bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
  h2c: 'Der Leistungsnachweis wurde nicht versendet.',
  t2c: 'Der Leistungsnachweis wurde inzwischen betätigt.<br />Bitte laden Sie die Seite neu.',
  h2o: 'Das Angebot wurde nicht versendet.',
  t2o: 'Das Angebot wurde inzwischen betätigt.<br />Bitte laden Sie die Seite neu.',
  t3: 'Die Daten für diese Seite konnten nicht geladen werden.<br>Sehr wahrscheinlich ist dieser Link bereits ungültig.<br>Falls Sie ein technisches Problem vermuten, so prüfen Sie bitte zunächst Ihre Internetverbindung. Anderenfalls kontaktieren Sie uns gern, so dass wir das Problem schnellstmöglich beheben können.',
};

export interface ErrorResponse {
  statusCode?: number;
  message?: string | null;
  path?: string | null;
  method?: string | null;
  type?: string | null;
}

export interface ErrorMessage {
  errorHeading?: string | null;
  errorText?: string | null;
}

export const getErrorMessageFromResponse = (errorResponse: ErrorResponse): ErrorMessage | null => {
  const { statusCode,path } = errorResponse
  if (!statusCode || statusCode === 502 || statusCode === 500 && path?.match(/\/jobs/)) {
    return {
      errorText: messages.t1,
    };
  } else if (path?.match(/\/jobs/) && (statusCode === 404 || statusCode === 403)) {
    return {
      errorText: messages.t3,
    };
  } else if (path?.match(/\/offers/) && statusCode === 428) {
    return {
      errorHeading: messages.h2o,
      errorText: messages.t2o,
    };
  } else if (path?.match(/\/certificates/)&& statusCode === 428) {
    return {
      errorHeading: messages.h2c,
      errorText: messages.t2c,
    };
  }
  return null;
}