import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import styled, { CSSObject } from 'styled-components';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../assets/icons-v2/IconArrowUp';

const StyledButton = styled.button(({ theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    border: 0,
    borderRadius: 0,
    padding: 0,
    margin: 0,
    background: 'transparent',
    ...theme.type.copySmall,
    fontWeight: 700,
    color: theme.colors.ciBlue5,
    transition: 'color 0.2s ease',
    svg: {
      width: '8px',
      height: '8px',
      verticalAlign: 'top',
    },
    '&[data-sortable="false"]': {
      pointerEvents: 'none',
    },
    '&[data-sortable="true"]': {
      color: 'white',
      cursor: 'pointer',
    },
  };

  return baseStyle;
});

interface DataTableColumnHeaderProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  sortable?: boolean;
  sortingOrder?: SortingOrder;
  icon?: React.ReactNode;
}

const DataTableColumnHeader: React.FC<DataTableColumnHeaderProps> = ({
  sortable,
  sortingOrder,
  icon,
  type,
  children,
  ...props
}) => {
  const dataAttributes = {
    'data-sortable': sortable,
  };

  return (
    <UiDiv>
      <StyledButton type={type || 'button'} {...props} {...dataAttributes}>
        {children}
        {sortingOrder === 'asc' ? IconArrowUp : sortingOrder === 'desc' ? IconArrowDown : null}
      </StyledButton>
    </UiDiv>
  );
};

export default DataTableColumnHeader;
