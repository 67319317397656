import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@webfox-sc/core';
import TableBodyRow from '../../components/elements/TableBodyRow';
import TableBodyCell from '../../components/elements/TableBodyCell';
import { useLocationListItem } from './LocationListItem.hooks';
import { themeCheckbox } from '../../theme/theme';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';

const LocationListItem = ({ objectId, onClick, isSelected, onSelect }) => {
  const { location, createdAtFormatted, updatedAtFormatted } = useLocationListItem(objectId);

  if (!location) {
    return null;
  }

  return (
    <TableBodyRow isClickable isModified={location.isModified} isNew={location.isNew}>
      <TableBodyCell layout="wide" text={location.number} onClick={onClick} width="13%" />
      <TableBodyCell layout="wide" text={location.description} onClick={onClick} width="auto" />
      <TableBodyCell layout="wide" text={createdAtFormatted} onClick={onClick} width="13%" />
      <TableBodyCell layout="wide" text={updatedAtFormatted} onClick={onClick} width="13%" />
      <TableBodyCell layout="wide" width="7%">
        <Checkbox checked={isSelected} onChange={(e) => onSelect(e.target.checked, objectId)} theme={themeCheckbox} />
      </TableBodyCell>
      <TableBodyCell layout="wide" iconSrc={IconArrowDown} onClick={onClick} width="7%" />
    </TableBodyRow>
  );
};

LocationListItem.propTypes = {
  objectId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

LocationListItem.defaultProps = {
  onClick: () => false,
  isSelected: PropTypes.bool,
  onSelect: () => false,
};

export default LocationListItem;
