import { useTranslation } from 'react-i18next';

import { useEffect } from 'react';
import { useAppDispatch } from '../../app/appHooks';
import { fetchUsers } from '../../app/slices/usersSlice';
import CustomReactSelect from '../CustomReactSelect';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import { useUsers } from '../qm-shared/useUsers';

interface QMUserFilterProps {
  selectedValue: number | null;
  onChange: (value: number | undefined) => void;
}

const QMUserFilter: React.FC<QMUserFilterProps> = ({ selectedValue, onChange }) => {
  const { t } = useTranslation();
  const { usersSelectOptions } = useUsers();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchUsers({ _limit: -1 }));
    };
    fetchData();
  }, [dispatch]);

  return (
    <>
      <FormElementLabel color="white">{t('common.fields.reporter')}</FormElementLabel>
      <CustomReactSelect
        layout="outline"
        options={[
          {
            value: null,
            label: t('common.form.showAllUsers'),
          },
          ...usersSelectOptions,
        ]}
        selectedValue={selectedValue}
        onChangeValue={onChange}
        isClearable
      />
    </>
  );
};

export default QMUserFilter;
