import { entitySelectors } from '../entitySelectors';

export const useCheckitemgroup = (
  id: number | undefined
): {
  checkitemgroup: CheckitemgroupEntity | undefined;
  checkitemgrouptype: CheckitemgrouptypeEntity | undefined;
  checkitems: CheckitemEntity[];
} => {
  const checkitemgroup = entitySelectors.selectById<CheckitemgroupEntity>('checkitemgroups', id);
  const checkitemgrouptype = entitySelectors.selectById<CheckitemgrouptypeEntity>('checkitemgrouptypes', checkitemgroup?.checkitemgrouptypeId);
  const checkitems = entitySelectors.selectByIds<CheckitemEntity>('checkitems', checkitemgroup?.checkitemIds);

  return {
    checkitemgroup,
    checkitemgrouptype,
    checkitems,
  };
};
