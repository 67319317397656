const IconMenuClose = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M85.36,14.64c2.44,2.44,2.44,6.4,0,8.84l-26.52,26.52,26.52,26.52c2.44,2.44,2.44,6.4,0,8.84s-6.4,2.44-8.84,0l-26.52-26.52-26.52,26.52c-2.44,2.44-6.4,2.44-8.84,0s-2.44-6.4,0-8.84l26.52-26.52L14.64,23.48c-2.44-2.44-2.44-6.4,0-8.84s6.4-2.44,8.84,0l26.52,26.52,26.52-26.52c2.44-2.44,6.4-2.44,8.84,0Z"
    />
  </svg>
);

export default IconMenuClose;
