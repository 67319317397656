const IconCalendar = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M95.45,9.09H81.82V4.55a4.55,4.55,0,1,0-9.09,0V9.09H54.55V4.55a4.55,4.55,0,0,0-9.1,0V9.09H27.27V4.55a4.55,4.55,0,1,0-9.09,0V9.09H4.55A4.54,4.54,0,0,0,0,13.64V36.36H100V13.64a4.54,4.54,0,0,0-4.55-4.55ZM0,95.45A4.54,4.54,0,0,0,4.55,100h90.9A4.54,4.54,0,0,0,100,95.45v-50H0Z"
    />
  </svg>
);

export default IconCalendar;
