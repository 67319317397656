/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function offerReducer(
    state = {
        offer: {},
        isRequestingOffer: false
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_OFFER:
            return Object.assign({}, state, {
                isRequestingOffer: true
            });

        case REDUX_ACTIONS.REQUEST_OFFER_ERROR:
            return Object.assign({}, state, {
                offer: {},
                isRequestingOffer: false
            });

        case REDUX_ACTIONS.RECIEVE_OFFER:
            return Object.assign({}, state, {
                offer: action.offer,
                isRequestingOffer: false
            });

        case REDUX_ACTIONS.RECIEVE_UPDATE_OFFER:
            return Object.assign({}, state, {
                offer: action.offer
            });

        default:
            return state;
    }
}

export default offerReducer;
