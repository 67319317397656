/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './advice.scss';

class Advice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { text, isSuccess, isInfo, isError, onClick } = this.props;

    let className = 'smg-advice';
    if (isSuccess) {
      className += ' smg-advice--success';
    } else if (isError) {
      className += ' smg-advice--error';
    } else if (isInfo) {
      className += ' smg-advice--info';
    }

    return (
      <div className={className} onClick={onClick}>
        <div className="smg-advice__text">
          <b dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    );
  }
}

Advice.propTypes = {
  text: PropTypes.string,
  isSuccess: PropTypes.bool,
  isInfo: PropTypes.bool,
  isError: PropTypes.bool,
  onClick: PropTypes.func,
};

Advice.defaultProps = {
  text: '',
  isSuccess: false,
  isInfo: false,
  isError: false,
  onClick: () => false,
};

export default Advice;
