import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import QMUtil from '../../utils/QMUtil';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { reportroomSchema } from '../schemas';
import { getCompleteEntities } from '../schemaUtils';

export const createReportroom = (data: ReportroomCreateData): RequestEntitiesAction<ReportroomData> =>
  requestEntities({
    method: 'POST',
    path: '/reportrooms',
    params: {
      populate: QMUtil.reportroomPopulateOptions,
    },
    data,
    schema: reportroomSchema,
  });

export const fetchReportroom = (reportroomId: number, params?: any): RequestEntitiesAction<ReportroomData> =>
  requestEntities({
    method: 'GET',
    path: `/reportrooms/${reportroomId}`,
    schema: reportroomSchema,
    params: {
      populate: QMUtil.reportroomPopulateOptions,
      ...params,
    },
  });

export const updateReportroom = (
  reportroomId: number,
  data: ReportroomUpdateData
): RequestEntitiesAction<ReportroomData> =>
  requestEntities({
    method: 'PUT',
    path: `/reportrooms/${reportroomId}`,
    data,
    params: {
      populate: QMUtil.reportroomPopulateOptions,
    },
    schema: reportroomSchema,
  });

export const updateCheckresults = (
    reportroomId: number,
    data: CheckresultUpdateData
  ): RequestEntitiesAction<ReportroomData> =>
    requestEntities({
      method: 'PUT',
      path: `/reportrooms/${reportroomId}/checkresults`,
      data,
      params: {
        populate: QMUtil.reportroomPopulateOptions,
      },
      schema: reportroomSchema,
    });

export const deleteReportroom = (reportroomId: number): RequestEntitiesAction<ReportroomData> =>
  requestEntities({
    method: 'DELETE',
    path: `/reportrooms/${reportroomId}`,
    params: {
      populate: QMUtil.reportroomPopulateOptions,
    },
    schema: reportroomSchema,
    type: 'deleteReportroom',
  });

const reportroomsAdapter = createEntityAdapter<ReportroomEntity>();

export const reportroomsSlice = createSlice({
  name: 'reportrooms',
  initialState: reportroomsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.payload.entities?.reportrooms) {
          const completeEntities = getCompleteEntities(action.payload.entities.reportrooms);
          if (Object.keys(completeEntities).length > 0) {
            reportroomsAdapter.upsertMany(state, completeEntities);
          }
        }
        if (action.meta.arg.type === 'deleteReportroom') {
          reportroomsAdapter.removeOne(state, action.payload.data.id);
        }
      })
  },
});

export default reportroomsSlice.reducer;
