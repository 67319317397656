import { UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { pathToQMReport } from '../../app/paths';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconInfo from '../../assets/icons-v2/IconInfo';
import DateUtil from '../../utils/DateUtil';
import Button from '../atoms-v2/Button';
import IconLink from '../atoms-v2/IconLink';
import ResultBubble from '../atoms-v2/ResultBubble';
import { useQMReport } from '../qm/useQMReport';

interface QMFinishedReportTeaserProps {
  reportId: number | undefined;
}

const QMFinishedReportTeaser: React.FC<QMFinishedReportTeaserProps> = ({ reportId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { report } = useQMReport(reportId);

  if (!report) {
    return null;
  }

  return (
    <UiDiv background="white" padding="15px">
      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr 40px', tablet: '1fr 40px 22px' }}
        rowGap="xs"
        columnGap="m"
        alignItems="center"
      >
        <UiDiv
          display="grid"
          gridTemplateColumns={{ mobile: '1fr', tablet: '200px 1fr' }}
          rowGap="xs"
          columnGap="s"
          alignItems="center"
        >
          <UiDiv display="flex" columnGap="xs">
            {report.isAdHoc ? (
              <UiText variant="copySmall">
                <strong>{t('report.form.adHoc.true')}</strong>
              </UiText>
            ) : (
              <>
                <UiText variant="copySmall">
                  <strong>{t('report.dueDate')}</strong>
                </UiText>
                <UiText variant="copySmall">{DateUtil.getDateFormatted(report.dueDate)}</UiText>
              </>
            )}
          </UiDiv>
          <UiDiv display="flex" columnGap="xs">
            <UiText variant="copySmall">
              <strong>{t('report.date')}</strong>
            </UiText>
            <UiText variant="copySmall">{DateUtil.getDateFormatted(report.date)}</UiText>
          </UiDiv>
        </UiDiv>
        <UiDiv alignSelf="flex-start">
          {report.scorePercentage !== undefined && <ResultBubble result={report.scorePercentage} />}
        </UiDiv>
        <UiDiv display={{ mobile: 'none', tablet: 'block' }}>
          <IconLink
            icon={IconArrowRight}
            href={pathToQMReport(report.id, `${pathname}${search}`)}
            onClick={(e) => {
              e.preventDefault();
              navigate(pathToQMReport(report.id, `${pathname}${search}`));
            }}
          />
        </UiDiv>
      </UiDiv>
      <UiDiv display={{ mobile: 'block', tablet: 'none' }} paddingTop="s">
        <Button variant="secondary" icon={IconInfo} onClick={() => navigate(pathToQMReport(report.id, `${pathname}${search}`))}>
          {t('common.actions.showDetails')}
        </Button>
      </UiDiv>
    </UiDiv>
  );
};

export default QMFinishedReportTeaser;
