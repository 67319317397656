import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQMAdminConfigCounts } from './useQMAdminConfigCounts';

const QMAdminConfigCounts: React.FC<SpecificityEntry> = (filter) => {
  const { t } = useTranslation();
  const { roomgroupsCount, checkitemCount, craft } = useQMAdminConfigCounts(filter);
  return (
    <>
      {checkitemCount || 0} {checkitemCount === 1 ? t('common.entities.checkitem.singular') : t('common.entities.checkitem.plural')} in {roomgroupsCount || 0}{' '}
      {roomgroupsCount === 1 ? t('common.entities.roomgroup.singular') : t('common.entities.roomgroup.plural')}
      {craft ? `, ${t('common.fields.craft')}: ${craft.name}`: ''}
    </>
  );
};

export default QMAdminConfigCounts;
