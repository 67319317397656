import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { dashboardSchema, jobSchema } from '../schemas';
import { RootState } from '../store';

export const createJob = (data: JobCreateData): RequestEntitiesAction<JobData> =>
  requestEntities({
    method: 'POST',
    path: '/jobs',
    data,
    schema: jobSchema,
  });

export const fetchJobs = (params?: any, withCount?: boolean): RequestEntitiesAction<JobData[]> =>
  requestEntities({
    method: 'GET',
    path: '/jobs',
    schema: [jobSchema],
    params,
    withCount,
    type: 'fetchJobs',
  });

export const fetchJob = (jobId: number, params?: any): RequestEntitiesAction<JobData> =>
  requestEntities({
    method: 'GET',
    path: `/jobs/${jobId}`,
    schema: jobSchema,
    params,
    type: 'fetchJob',
  });

export const updateJob = (jobId: number, data: JobUpdateData): RequestEntitiesAction<JobData> =>
  requestEntities({
    method: 'PUT',
    path: `/jobs/${jobId}`,
    data,
    schema: jobSchema,
  });

export const updateJobIsInvoiced = (jobId: number, jobIsInvoiced: boolean): RequestEntitiesAction<JobData> =>
  requestEntities({
    method: 'PUT',
    path: `/jobs/${jobId}/invoiced`,
    data: { is_invoiced: jobIsInvoiced },
    schema: jobSchema,
  });

export const deleteJob = (jobId: number): RequestEntitiesAction<JobData> =>
  requestEntities({
    method: 'DELETE',
    path: `/jobs/${jobId}`,
    schema: jobSchema,
    type: 'deleteJob',
  });

  
export const fetchJobMetrics = (params?: any): RequestEntitiesAction<DashboardData> =>
  requestEntities({
    method: 'GET',
    path: '/jobs/metrics',
    schema: dashboardSchema,
    params,
    type: 'fetchJobMetrics',
  });

export interface JobsState extends EntityState<JobEntity> {
  fetchJobsStatus: RequestStatus;
  fetchJobStatus: RequestStatus;
}

const jobsAdapter = createEntityAdapter<JobEntity>();

const initialState: JobsState = jobsAdapter.getInitialState({
  fetchJobsStatus: 'idle',
  fetchJobStatus: 'idle',
});

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchJobs') {
          state.fetchJobsStatus = 'loading';
        }
        if (action.meta.arg.type === 'fetchJob') {
          state.fetchJobStatus = 'loading';
        }
        if (action.meta.arg.type === 'fetchJobMetrics') {
          state.fetchJobsStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.meta.arg.type === 'fetchJobs') {
          state.fetchJobsStatus = 'idle';
        }
        if (action.meta.arg.type === 'fetchJob') {
          state.fetchJobStatus = 'idle';
        }
        if (action.meta.arg.type === 'fetchJobMetrics') {
          state.fetchJobsStatus = 'idle';
        }
        if (action.payload.entities?.jobs) {
          jobsAdapter.upsertMany(state, action.payload.entities.jobs);
        }
        if (action.meta.arg.type === 'deleteJob') {
          jobsAdapter.removeOne(state, action.payload.data.id);
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchJobs') {
          state.fetchJobsStatus = 'failed';
        }
        if (action.meta.arg.type === 'fetchJob') {
          state.fetchJobStatus = 'failed';
        }
        if (action.meta.arg.type === 'fetchJobMetrics') {
          state.fetchJobsStatus = 'failed';
        }
      });
  },
});

export const selectJobsState = (state: RootState): JobsState => state.entitiesState.jobs;
export default jobsSlice.reducer;
