import { Block, Group, GroupItem } from '@webfox-sc/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconSearch from '../../../assets/icons-v2/IconSearch';
import ButtonOutline from '../../../atoms/ButtonOutline';
import FormElementLabel from '../../../components/atoms-v2/FormElementLabel';
import TextInput from '../../../components/atoms-v2/TextInput';

const JobListSearch = ({ value, onChange, onSubmit }) => {
  const { t } = useTranslation();
  const [valueDraft, setValueDraft] = useState('');

  useEffect(() => {
    setValueDraft(value);
  }, [value]);

  const handleOnChange = (e) => {
    setValueDraft(e.target.value);
    onChange(e.target.value);
  };

  const handleOnReset = () => {
    setValueDraft('');
    onSubmit('');
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit(valueDraft);
  };

  return (
    <Block width="721px">
      <FormElementLabel color="ciBlue4">{t('common.form.textSearch')}</FormElementLabel>
      <form onSubmit={handleOnSubmit}>
        <Group nowrap spacing="5px">
          <GroupItem grow>
            <TextInput
              variant="outline"
              aria-label={t('common.form.input')} 
              value={valueDraft}
              onChange={handleOnChange}
            />
          </GroupItem>
          {valueDraft && (
            <GroupItem>
              <ButtonOutline type="button" ariaLabel={t('common.form.reset')} icon={IconClose} onClick={handleOnReset} />
            </GroupItem>
          )}
          <GroupItem>
            <ButtonOutline type="submit" label={t('common.actions.search')} icon={IconSearch} />
          </GroupItem>
        </Group>
      </form>
    </Block>
  );
};

JobListSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

JobListSearch.defaultProps = {
  value: '',
  onChange: () => {},
  onSubmit: () => {},
};

export default JobListSearch;
