import { Block, UiDiv, UiText } from '@webfox-sc/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { useUser } from '../../../app/entityHooks/useUser';
import { createUser, deleteUser, updateUser } from '../../../app/slices/usersSlice';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import Button from '../../atoms-v2/Button';
import Checkbox from '../../atoms-v2/Checkbox';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import TextInput from '../../atoms-v2/TextInput';
import CustomReactSelect from '../../CustomReactSelect';

import CopyText from '../../../atoms/CopyText';
import { COLORS } from '../../../theme/theme';
import { usePermissions } from '../../auth/usePermissions';
import { useToastContext } from '../../toast/ToastProvider';
import { useBaseDataUserForm } from './useBaseDataUserForm';
import { USE_PHONE_NUMBER } from '../../../app.config';

const isPasswordValid = (password) => {
  if (password && password.length >= 8) {
    let criteriaMatchCount = 0;

    if (/[a-z]+/.test(password)) {
      criteriaMatchCount++;
    }
    if (/[A-Z]+/.test(password)) {
      criteriaMatchCount++;
    }
    if (/[0-9]+/.test(password)) {
      criteriaMatchCount++;
    }
    if (/[#?!@$%^&*\\_-]+/.test(password)) {
      criteriaMatchCount++;
    }

    return criteriaMatchCount >= 3;
  }
  return false;
};
interface BaseDataUserFormProps {
  userId?: number | undefined;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

const BaseDataUserForm: React.FC<BaseDataUserFormProps> = ({ userId, onSubmit, onClickCancel }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showToast } = useToastContext();
  const formMode = userId ? 'update' : 'create';

  const { user } = useUser(userId);
  const { assignableRoleIds, canEditUser } = usePermissions();
  const { roleOptions } = useBaseDataUserForm();
  const [userData, setUserData] = useState<UserUpdateData>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setUserData({
      username: user?.username,
      email: user?.email,
      password: undefined,
      tel: user?.tel,
      blocked: user?.blocked,
      role: user?.roleId,
    });
  }, [user]);

  const isUserDataValid = useCallback(() => {
    const { username, email, password } = userData;

    const reEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailValid = reEmail.test(String(email).toLowerCase());

    if (formMode === 'update') {
      return username && isEmailValid;
    }

    return username && isEmailValid && isPasswordValid(password);
  }, [formMode, userData]);

  const handleChangeValue = (key: string, value: any) => {
    const userDataUpdated: UserUpdateData = { ...userData };
    userDataUpdated[key] = value;
    setUserData(userDataUpdated);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    let res;
    if (formMode === 'create') {
      setErrorMessage(null);
      res = await dispatch(createUser(userData));
    } else if (userId) {
      res = await dispatch(updateUser(userId, userData));
    }
    if (res?.error) {
      if (res?.payload?.error?.message) {
        setErrorMessage(t('common.errors.general', { message: res.payload.error.message }));
      } else {
        setErrorMessage(t('common.errors.unknown'));
      }
    } else {
      if (onSubmit) {
        onSubmit(e);
      }
    }
  };

  const handleOnClickDelete: React.MouseEventHandler<HTMLButtonElement> = async () => {
    if (userId) {
      if (window.confirm(t('common.prompts.delete', { entry: user?.username }) || undefined)) {
        const res = await dispatch(deleteUser(userId));
        if (res?.meta?.requestStatus === 'fulfilled') {
          showToast(t('common.success.deleted', 'success'));
        } else {
          showToast(t('common.errors.deleted', { entry: user?.username }), 'error');
        }
      }
    }
  };

  const canEdit = formMode === 'create' || canEditUser(user);

  return (
    <form onSubmit={handleOnSubmit}>
      <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" rowGap="15px" columnGap="xs">
        <UiDiv gridColumn="1 / 5">
          <FormElementLabel>{t('user.username')} *</FormElementLabel>
          <TextInput
            value={userData.username}
            onChange={(e) => handleChangeValue('username', e.target.value)}
            disabled={!canEdit}
          />
        </UiDiv>
        <UiDiv gridColumn="5 / 9">
          <FormElementLabel>{t('user.email')} *</FormElementLabel>
          <TextInput
            value={userData.email}
            onChange={(e) => handleChangeValue('email', e.target.value)}
            disabled={!canEdit}
          />
        </UiDiv>
        {USE_PHONE_NUMBER && (
          <UiDiv gridColumn="9 / 13">
            <FormElementLabel>{t('user.phoneNumber')}</FormElementLabel>
            <TextInput
              value={userData.tel}
              onChange={(e) => handleChangeValue('tel', e.target.value)}
              disabled={!canEdit}
            />
          </UiDiv>
        )}
        <UiDiv gridColumn="1 / 5">
          <FormElementLabel>{t('user.role')} *</FormElementLabel>
          <CustomReactSelect
            options={roleOptions}
            selectedValue={userData.role}
            onChangeValue={(value) => handleChangeValue('role', value)}
            placeholder={t('common.actions.select')}
            isDisabled={!canEdit}
            isOptionDisabled={(option) => !assignableRoleIds.includes(option.value)}
          />
        </UiDiv>
        <UiDiv gridColumn="5 / 13">
          <FormElementLabel>{t('user.active')}</FormElementLabel>
          <Checkbox
            checked={!userData.blocked}
            onChange={(e) => handleChangeValue('blocked', !e.target.checked)}
            disabled={!canEdit}
          />
        </UiDiv>
        {formMode === 'create' && (
          <>
            <UiDiv gridColumn="1 / 5">
              <FormElementLabel>{t('user.password')} *</FormElementLabel>
              <UiDiv background="white">
                <TextInput value={userData.password} onChange={(e) => handleChangeValue('password', e.target.value)} />
              </UiDiv>
            </UiDiv>
            <UiDiv gridColumn="1 / 13">
              <UiText variant="copySmall">
                {t('account.changePassword.criteria.headline')}
                <br />- {t('account.changePassword.criteria.lowerCase')}
                <br />- {t('account.changePassword.criteria.upperCase')}
                <br />- {t('account.changePassword.criteria.digit')}
                <br />- {t('account.changePassword.criteria.special')}
              </UiText>
            </UiDiv>
          </>
        )}
      </UiDiv>
      {errorMessage && (
        <UiDiv paddingTop="s">
          <Block padding="10px 12px" background={COLORS.TRAFFIC_LIGHT_RED}>
            <CopyText color="white" textAlign="center">
              {errorMessage}
            </CopyText>
          </Block>
        </UiDiv>
      )}
      {canEdit && (
        <UiDiv display="flex" columnGap="xs" justifyContent="space-between" paddingTop="s">
          <UiDiv display="flex" columnGap="xs">
            <Button type="submit" icon={IconCheck} disabled={!isUserDataValid()}>
              {formMode === 'create' ? 'Erstellen' : 'Übernehmen'}
            </Button>
            <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
              {t('common.actions.cancel')}
            </Button>
          </UiDiv>
          {formMode === 'update' && (
            <Button icon={IconDelete} onClick={handleOnClickDelete}>
              {t('common.actions.delete')}
            </Button>
          )}
        </UiDiv>
      )}
    </form>
  );
};

export default BaseDataUserForm;
