import { intersection, merge, pull } from 'lodash';
import { useAppDispatch } from '../app/appHooks';
import { initialState } from '../app/slices/clientFilterSlice';
import { fetchClient, fetchClients } from '../app/slices/clientsSlice';
import {
  ClientFilterState,
  CollectionFilterState,
  DynamicClientFilter,
  FilterEntity,
  FilterEntityKey,
} from '../types/clientFilter';
import { getClientOptions, stripObject } from '../utils/FilterUtil';

const fetchClientParams = {
  populate: {
    costcenter_rel: {
      fields: ['id', 'number'],
      populate: {
        clients: {
          populate: ['location', 'company'],
        },
      },
    },
    location: {
      fields: ['id', 'description', 'number'],
      populate: {
        clients: {
          populate: ['costcenter_rel', 'company'],
        },
      },
    },
    company: {
      fields: ['id', 'name', 'number'],
      populate: {
        clients: {
          populate: ['costcenter_rel', 'location'],
        },
        address: '*',
      },
    },
  },
};

export const useDynamicClientFilter: (
  currentFilterState: any,
  handleSetFilterState: (filterState: ClientFilterState) => void
) => DynamicClientFilter = (currentFilterState, handleSetFilterState) => {
  const dispatch = useAppDispatch();

  const selectClient = async (newId?: number, oldId?: number) => {
    if (!newId) {
      handleSetFilterState({
        clientId: undefined,
      });
    }
    if (!!newId && newId !== oldId) {
      const resClientRaw = await dispatch(fetchClient(newId, fetchClientParams));
      const clientRaw = resClientRaw?.payload?.data;
      if (!clientRaw?.id) {
        handleSetFilterState({
          clientId: undefined,
        });
      } else {
        handleSetFilterState({
          clientId: clientRaw.id,
          companies: {
            ...stripObject(clientRaw.company),
            ...getClientOptions(
              (typeof clientRaw.company !== 'number' && (clientRaw.company?.clients as ClientApiData[])) || []
            ),
          },
          locations: {
            ...stripObject(clientRaw.location),
            ...getClientOptions(
              (typeof clientRaw.location !== 'number' && (clientRaw.location?.clients as ClientApiData[])) || []
            ),
          },
          costcenters: {
            ...stripObject(clientRaw.costcenter_rel),
            ...getClientOptions(
              (typeof clientRaw.costcenter_rel !== 'number' &&
                (clientRaw.costcenter_rel?.clients as ClientApiData[])) ||
                []
            ),
          },
        });
      }
    }
  };

  const computeFilterMap = (filterState: ClientFilterState, lastUpdate: FilterEntityKey) => {
    const keys = ['companies', 'locations', 'costcenters'];

    // compute filter groups
    const filterMap = {
      companies: {},
      locations: {},
      costcenters: {},
    };

    const filters: Record<string, number[]> = {};
    // create ids object
    keys.forEach((key) => {
      if (filterState[key]) {
        const obj = filterState[key];
        filters[key] = obj.clientIds;
        filterMap[key].selected = obj;
      }
    });

    let newFilterState = { ...filterState };

    const filteredClientIds = intersection(...(Object.values(filters) as number[][]));
    if (filteredClientIds.length === 0) {
      // no match, reset
      if (lastUpdate) {
        // check if other selections are in options of the last selected entry
        const otherKeys = [...keys];
        pull(otherKeys, lastUpdate);

        const lastFilterObject = filterState[lastUpdate];
        otherKeys.forEach((key) => {
          const selection = filterState[key];
          if (selection) {
            const options = lastFilterObject ? lastFilterObject[key] : null;
            if (options && !options.find(({ id }) => id === selection.id)) {
              // selection not found in options, delete
              delete newFilterState[key];
            }
          }
        });
      } else {
        // no last update, reset
        newFilterState = { ...initialState };
      }
      // if (!isEqual(filterState, newFilterState)) {
      //   setFilterState(newFilterState);
      // }
      if (currentFilterState.clientId) {
        newFilterState.clientId = null;
        // setClientId(null);
      }
    } else if (filteredClientIds.length === 1) {
      // exactly one matching client, select
      selectClient(filteredClientIds[0]);
    } else {
      // more than one match
      selectClient();
    }

    // set options
    keys.forEach((key, i) => {
      const otherKeys = [...keys];
      otherKeys.splice(i, 1);

      filterMap[key].group = {};
      otherKeys.forEach((k) => {
        if (newFilterState[k] && newFilterState[k][key] && newFilterState[k][key].length > 0) {
          filterMap[key].group[k] = newFilterState[k][key];
        }
      });
    });
    newFilterState.filterMap = filterMap;
    return newFilterState;
  };

  return {
    clientId: currentFilterState.clientId,
    filterMap: currentFilterState.filterMap,
    setClientId: selectClient,
    company: currentFilterState.companies || undefined,
    setCompany: async (input: FilterEntity): Promise<CollectionFilterState> => {
      const company: CollectionFilterState = input ? { ...input } : {};
      if (input.id) {
        const resClients = await dispatch(
          fetchClients({
            filters: {
              company: {
                id: input.id,
              },
            },
            populate: {
              costcenter_rel: {
                fields: ['number', 'id'],
              },
              location: {
                fields: ['description', 'number', 'id'],
              },
            },
            _limit: -1,
          })
        );
        const clients = resClients?.payload?.data || [];

        if (clients) {
          const options = getClientOptions(clients);
          if (options) {
            merge(company, options);
          }
        }
      }
      const newFilterState = computeFilterMap({ ...currentFilterState, companies: company }, 'companies');
      handleSetFilterState(newFilterState);
      return company;
    },
    location: currentFilterState.locations || undefined,
    setLocation: async (input: FilterEntity): Promise<CollectionFilterState> => {
      const location: CollectionFilterState = input ? { ...input } : {};
      if (input.id) {
        const resClients = await dispatch(
          fetchClients({
            filters: {
              location: {
                id: input.id,
              },
            },
            populate: {
              costcenter_rel: {
                fields: ['number', 'id'],
              },
              company: {
                fields: ['name', 'number', 'id'],
              },
            },
            _limit: -1,
          })
        );
        const clients = resClients?.payload?.data || [];

        if (clients) {
          const options = getClientOptions(clients);
          if (options) {
            merge(location, options);
          }
        }
      }
      const newFilterState = computeFilterMap({ ...currentFilterState, locations: location }, 'locations');
      handleSetFilterState(newFilterState);
      return location;
    },
    costcenter: currentFilterState.costcenters || undefined,
    setCostcenter: async (input: FilterEntity): Promise<CollectionFilterState> => {
      const costcenter: CollectionFilterState = input ? { ...input } : {};
      if (input.id) {
        const resClients = await dispatch(
          fetchClients({
            filters: {
              costcenter_rel: {
                id: input.id,
              },
            },
            populate: {
              location: {
                fields: ['description', 'number', 'id'],
              },
              company: {
                fields: ['name', 'number', 'id'],
              },
            },
            _limit: -1,
          })
        );
        const clients = resClients?.payload?.data || [];

        if (clients) {
          const options = getClientOptions(clients);
          if (options) {
            merge(costcenter, options);
          }
        }
      }
      const newFilterState = computeFilterMap({ ...currentFilterState, costcenters: costcenter }, 'costcenters');
      handleSetFilterState(newFilterState);
      return costcenter;
    },
  };
};
