import { entitySelectors } from '../entitySelectors';

export const useReportroom = (
  reportroomId: number | undefined
): {
  reportroom: ReportroomEntity | undefined;
  report: ReportEntity | undefined;
  roomgroup: RoomgroupEntity | undefined;
} => {
  const reportroom = entitySelectors.selectById<ReportroomEntity>('reportrooms', reportroomId);
  const report = entitySelectors.selectById<ReportEntity>('reports', reportroom?.reportId);
  const roomgroup = entitySelectors.selectById<RoomgroupEntity>('roomgroups', reportroom?.roomgroupId);

  return {
    reportroom,
    report,
    roomgroup,
  };
};
