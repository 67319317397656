import AppUtil from './AppUtil';
import ClientsUtil from './ClientsUtil';

class CostcenterUtil {
  /**
   * Returns an empty costcenter, initialized with data provided
   * @param {Object} initialData data to initialize
   * @reutrn {Object} costcenter
   */
  static getEmptyObject(initialData = {}) {
    return {
      number: '',
      clients: [],
      users: [],
      ...initialData,
    };
  }

  static parseData(costcenterData, options = {}) {
    const { additionalData, oldCostcenter } = options;

    const { id, createdAt, updatedAt, number, clients, client_ids, user_ids, users, isNew, isModified } =
      costcenterData;

    const baseCostcenter = {
      id: id || null,
      createdAt: createdAt || null,
      updatedAt: updatedAt || null,
      number: number || null,
      clientIds: (clients && clients.map((c) => c?.id)) || client_ids || [],
      userIds: (users && users.map((u) => u?.id)) || user_ids || [],
      // userNamesString:
      //     users && users.length > 0
      //         ? users.map(({ username }) => username).join(', ')
      //         : '** Kein Nutzer zugeordnet **',
      isNew,
      isModified,
    };

    if (clients) {
      const clientsArr = [];
      clients.forEach((client) => {
        if (client && typeof client !== 'string') {
          clientsArr.push(ClientsUtil.parseData(client));
        }
      });
      if (clientsArr.length) {
        baseCostcenter.clients = clientsArr;
      }
    }

    if (users) {
      const usersArr = [];
      users.forEach((user) => {
        if (user && typeof user !== 'string') {
          usersArr.push(user.userName);
        }
      });
      if (usersArr.length) {
        baseCostcenter.users = usersArr;
      }
    }

    let oldProps = {};
    if (oldCostcenter) {
      oldProps = AppUtil.getOldProps(baseCostcenter, oldCostcenter);
    }

    return {
      ...baseCostcenter,
      ...oldProps,
      ...additionalData,
    };
  }

  /**
   * Returns data object for a costcenter to be used in API requests
   * @param {Object} costcenter the location Object
   * @returns {Object} the data
   */
  static getAPIData(costcenter) {
    const { number, clientIds, userIds } = costcenter;

    const data = {
      number,
      clients: clientIds || [],
      users: userIds || [],
    };

    return data;
  }

  static baseViewPopulate = {
    clients: { populate: ['company', 'location'] },
    users: {
      fields: ['username'],
      populate: {
        role: {
          fields: ['name'],
        },
      },
    },
  };
}

export default CostcenterUtil;
