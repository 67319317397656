import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities } from '../requestEntities';

// obsolete
// export const createHistory = (data: HistoryCreateData): RequestEntitiesAction<HistoryData> =>
//   requestEntities({
//     method: 'POST',
//     path: '/histories',
//     data,
//     schema: historySchema,
//   });

const historiesAdapter = createEntityAdapter<HistoryEntity>();

export const historiesSlice = createSlice({
  name: 'histories',
  initialState: historiesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.histories) {
        historiesAdapter.upsertMany(state, action.payload.entities.histories);
      }
    });
  },
});

export default historiesSlice.reducer;
