import React from 'react';
import { UiDiv, UiDivProps, UiText } from '@webfox-sc/core';

interface PageContentSubHeadingProps extends UiDivProps {
  leftSideText?: string;
}

const PageContentSubHeading: React.FC<PageContentSubHeadingProps> = ({ leftSideText, children, ...props }) => {
  return (
    <UiDiv
      display="grid"
      gridTemplateColumns={{ mobile: '1fr', tablet: '1fr auto 1fr' }}
      gap="s"
      alignItems="center"
      {...props}
    >
      <UiText variant="copySmall" color="white" order={{ mobile: 2, tablet: 1 }}>
        {leftSideText}
      </UiText>
      <UiText variant="h2" color="white" textAlign="center" order={{ mobile: 1, tablet: 2 }}>
        {children}
      </UiText>
    </UiDiv>
  );
};

export default PageContentSubHeading;
