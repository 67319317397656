import { entitySelectors } from '../entitySelectors';

export const useRoomgroups = (
  craftId: number | undefined
): {
  roomgroups: RoomgroupEntity[];
  roomgroupIds: number[];
} => {
  const roomgroups = entitySelectors.selectByFilter<RoomgroupEntity>(
    'roomgroups',
    (roomgroup) => roomgroup.craftId === craftId
  );
  const roomgroupIds = (roomgroups || []).map(({ id }) => id);

  return {
    roomgroups,
    roomgroupIds,
  };
};
