import { Action, combineReducers, configureStore, Reducer, ThunkAction } from '@reduxjs/toolkit';

import certificateReducer from '../redux-reducers/certificateReducer';
import clientsReducer from '../redux-reducers/clientsReducer';
import companiesReducer from '../redux-reducers/companiesReducer';
import costcentersReducer from '../redux-reducers/costcentersReducer';
import dataReducer from '../redux-reducers/dataReducer';
import importsReducer from '../redux-reducers/importsReducer';
import jobDetailsReducer from '../redux-reducers/jobDetailsReducer';
import jobsReducer from '../redux-reducers/jobsReducer';
import locationsReducer from '../redux-reducers/locationsReducer';
import offerReducer from '../redux-reducers/offerReducer';
import servicesReducer from '../redux-reducers/servicesReducer';
import userReducer from '../redux-reducers/userReducer';
import usersReducer from '../redux-reducers/usersReducer';

import addressesSliceReducer from './slices/addressesSlice';
import authSliceReducer from './slices/authSlice';
import certificatesSliceReducer from './slices/certificatesSlice';
import checkitemgrouptypesSliceReducer from './slices/checkitemgrouptypesSlice';
import checkitemgroupsSliceReducer from './slices/checkitemgroupsSlice';
import checkitemtypesSliceReducer from './slices/checkitemtypesSlice';
import checkitemsSliceReducer from './slices/checkitemsSlice';
import checkresultsSliceReducer from './slices/checkresultsSlice';
import clientFilterSliceReducer from './slices/clientFilterSlice';
import clientsSliceReducer from './slices/clientsSlice';
import craftsSliceReducer from './slices/craftsSlice';
import dashbaordSliceReducer from './slices/dashboardSlice';
import qmOverviewSliceReducer from './slices/qmOverviewSlice';
import servicesSliceReducer from './slices/servicesSlice';
import companiesSliceReducer from './slices/companiesSlice';
import contactsSliceReducer from './slices/contactsSlice';
import costcentersSliceReducer from './slices/costcentersSlice';
import filesSliceReducer from './slices/filesSlice';
import jobListSliceReducer from './slices/jobListSlice';
import jobsSliceReducer from './slices/jobsSlice';
import locationsSliceReducer from './slices/locationsSlice';
import offersSliceReducer from './slices/offersSlice';
import picturesSliceReducer from './slices/picturesSlice';
import qmAdminSliceReducer from './slices/qmAdminSlice';
import qmSliceReducer from './slices/qmSlice';
import recipientsSliceReducer from './slices/recipientsSlice';
import reportroomsSliceReducer from './slices/reportroomsSlice';
import reportsSliceReducer from './slices/reportsSlice';
import permissionsSliceReducer from './slices/permissionsSlice';
import rolesSliceReducer from './slices/rolesSlice';
import roomgroupsSliceReducer from './slices/roomgroupsSlice';
import schedulesSliceReducer from './slices/schedulesSlice';
import usersSliceReducer from './slices/usersSlice';
import historiesSliceReducer from './slices/historiesSlice';
import errorSliceReducer from './slices/errorSlice';

const entitiesReducer = combineReducers({
  users: usersSliceReducer,
  permissions: permissionsSliceReducer,
  roles: rolesSliceReducer,
  companies: companiesSliceReducer,
  clients: clientsSliceReducer,
  services: servicesSliceReducer,
  costcenters: costcentersSliceReducer,
  checkitemtypes: checkitemtypesSliceReducer,
  checkitems: checkitemsSliceReducer,
  checkitemgroups: checkitemgroupsSliceReducer,
  checkitemgrouptypes: checkitemgrouptypesSliceReducer,
  crafts: craftsSliceReducer,
  roomgroups: roomgroupsSliceReducer,
  schedules: schedulesSliceReducer,
  locations: locationsSliceReducer,
  contacts: contactsSliceReducer,
  reports: reportsSliceReducer,
  reportrooms: reportroomsSliceReducer,
  checkresults: checkresultsSliceReducer,
  pictures: picturesSliceReducer,
  jobs: jobsSliceReducer,
  recipients: recipientsSliceReducer,
  addresses: addressesSliceReducer,
  offers: offersSliceReducer,
  certificates: certificatesSliceReducer,
  files: filesSliceReducer,
  histories: historiesSliceReducer,
});

export const store = configureStore({
  devTools: true,
  reducer: {
    companiesState: companiesReducer,
    locationsState: locationsReducer,
    clientFilterState: clientFilterSliceReducer,
    clientsState: clientsReducer,
    dataState: dataReducer as Reducer,
    dashboardState: dashbaordSliceReducer,
    qmOverviewState: qmOverviewSliceReducer,
    jobDetailsState: jobDetailsReducer,
    jobsStateLegacy: jobsReducer,
    servicesState: servicesReducer,
    userState: userReducer,
    usersState: usersReducer,
    costcentersState: costcentersReducer,
    offerState: offerReducer,
    certificateState: certificateReducer,
    importState: importsReducer,
    authState: authSliceReducer,
    entitiesState: entitiesReducer,
    qmAdmimState: qmAdminSliceReducer,
    qmState: qmSliceReducer,
    jobListState: jobListSliceReducer,
    errorState: errorSliceReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type EntitiesState = ReturnType<typeof entitiesReducer>;
