import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { selectDashboardState, setDashboardState } from '../../app/slices/dashboardSlice';
import { selectJobsState } from '../../app/slices/jobsSlice';
import IconDownload from '../../assets/icons-v2/IconDownload';
import Button from '../atoms-v2/Button';

const DashboardDownloadButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { csvData, jobsCount } = useAppSelector(selectDashboardState);

  const { fetchJobsStatus } = useAppSelector(selectJobsState);

  return (
    <>
      {csvData ? (
        <CSVLink
          style={{ textDecoration: 'none' }}
          separator={';'}
          data={csvData}
          filename={'Gegenbauer_Jobuebersicht.csv'}
        >
          <Button icon={IconDownload} minWidth="100%" variant="highlight">
            {t('common.actions.save')}
          </Button>
        </CSVLink>
      ) : (
        <>
          {fetchJobsStatus === 'loading' ? (
            <Button icon={IconDownload} minWidth="100%" variant="highlight" disabled>
              {t('common.form.loading')}
            </Button>
          ) : (
            <Button
              icon={IconDownload}
              minWidth="100%"
              variant="highlight"
              onClick={() => {
                dispatch(
                  setDashboardState({
                    limit: jobsCount,
                  })
                );
              }}
            >
              {t('common.actions.createCsv')}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default DashboardDownloadButton;
