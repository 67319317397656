export const useCostcentersFilter = () => {
    const isDataFilter = () => {
        return false;
    };

    const filterObjects = (objects) => ({ objectsFiltered: objects });

    const getAPIFilter = () => null;

    const initialDataFilter = null;

    const initialTableFilter = null;

    const uniqueKeys = ['number'];

    return {
        uniqueKeys,
        getAPIFilter,
        isDataFilter,
        filterObjects,
        initialDataFilter,
        initialTableFilter,
    };
};
