import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePermissions } from '../../components/auth/usePermissions';
import BaseDataFilteredList from '../../components/base-data/shared/BaseDataFilteredList';
import { usePaginatedList } from '../../components/base-data/shared/usePaginatedList';
import CostcenterEditor from '../../components/CostcenterEditor';
import TableHeaderCell from '../../components/elements/TableHeaderCell';
import TableHeaderRow from '../../components/elements/TableHeaderRow';
import { BaseDataFilterProvider } from '../../hooks/baseDataFilter';
import { BaseDataViewProvider } from '../../hooks/baseDataView';
import { useCostcenterActions } from '../../hooks/useCostcenterActions';
import { useCostcentersFilter } from '../../hooks/useCostcentersFilter';
import { UserOptionsProvider } from '../../hooks/useUserOptions';
import { deleteAllCostcenters } from '../../redux-actions/actionsCostcenters';
import CostcenterUtil from '../../utils/CostcenterUtil';
import CostcenterListItem from './CostcenterListItem';

const ViewBaseDataCostcenters = () => {
  const { t } = useTranslation();
  const { isDataFilter, initialTableFilter } = useCostcentersFilter();
  const { fetchCostcentersLegacy } = useCostcenterActions();
  const { canViewGeneralBaseData } = usePermissions();

  const dataLoaderProps = {
    objectPath: 'costcenters',
    fetchObjects: (params) => fetchCostcentersLegacy({ ...params, populate: CostcenterUtil.baseViewPopulate }),
  };

  const dispatch = useDispatch();

  return (
    <UserOptionsProvider>
      <BaseDataFilterProvider isDataFilter={isDataFilter} initialTableFilter={initialTableFilter}>
        <BaseDataViewProvider
          objectPath="costcenters"
          getNewObject={CostcenterUtil.getEmptyObject}
          useFilter={useCostcentersFilter}
          deleteObjects={(ids) => dispatch(deleteAllCostcenters(ids))}
          dataLoader={usePaginatedList}
          dataLoaderProps={dataLoaderProps}
        >
          <BaseDataFilteredList
            labelObjects={t('costcenter.headline.plural')}
            labelNew={t('costcenter.actions.new')}
            hideCreate={!canViewGeneralBaseData}
            hideDeleteAll={!canViewGeneralBaseData}
            Editor={CostcenterEditor}
            TableHeader={({ handleSort, sortingKey, sortingOrder }) => (
              <TableHeaderRow>
                <TableHeaderCell
                  text={t('common.entities.costcenter.singular')}
                  width="20%"
                  isSorting
                  onClick={() => handleSort('number')}
                  sortingOrder={sortingKey === 'number' ? sortingOrder : ''}
                />
                <TableHeaderCell text={t('common.entities.user.plural')} width="56%" />
                <TableHeaderCell
                  text={t('common.fields.updatedAt')}
                  width="24%"
                  isSorting
                  onClick={() => handleSort('updatedAt')}
                  sortingOrder={sortingKey === 'updatedAt' ? sortingOrder : ''}
                />
              </TableHeaderRow>
            )}
            TableRow={CostcenterListItem}
            newEditorPresets={{
              showAdvice: true,
              isError: true,
            }}
          />
        </BaseDataViewProvider>
      </BaseDataFilterProvider>
    </UserOptionsProvider>
  );
};

export default ViewBaseDataCostcenters;
