import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _set from 'lodash/set';
import qs from 'qs';

type ParamsResponse = {
  params: ApiParams;
  paramString: string;
}

const apiParamKeys = ['sort', 'pagination', 'count', 'populate', 'fields'];
const appParamKeys = ['_start', '_limit', '_populate', '_sort', 'filters'];
const operators = [
  'eq',
  'eqi',
  'ne',
  'lt',
  'lte',
  'gt',
  'gte',
  'in',
  'notIn',
  'contains',
  'notContains',
  'containsi',
  'notContainsi',
  'null',
  'notNull',
  'between',
  'startsWith',
  'endsWith',
  'or',
  'and',
];

const parseAppParamKey = (appParamKey: string) => {
  const match = appParamKey.match(/(.*)_([a-z]+)$/i);
  if (match) {
    const suffix = match[2];
    if (operators.includes(suffix)) {
      return { key: match[1], operator: `$${suffix}` };
    }
  }
  return { key: appParamKey, operator: null };
};

export const getAPIParams = (appParams: AppParams | null | undefined, withCount?: boolean ): ParamsResponse => {
  // keep keys that are valid in API params
  const params: ApiParams = _pick(appParams || {}, apiParamKeys);
  const filters: FilterPredicate | FilterPredicate[] = appParams?.filters || [];

  if (withCount) {
    params.count = true;
  }

  // custom mapping for meta params
  if (appParams?._start) {
    _set(params, ['pagination', 'start'], appParams._start);
  }
  if (appParams?._limit) {
    _set(params, ['pagination', 'limit'], appParams._limit);
  }
  if (appParams?._sort) {
    _set(params, ['sort'], appParams._sort);
  }
  // if filters is already an object, don't add further filters,
  // since this might lead to an unexpected behavior,
  // e.g. filters = { $and: [...] } vs. filters = { $or: [...] }
  // if filters is an array, combine all filters with $and
  if (Array.isArray(filters)) {
    // all other appParams are treated as filters
    const appFilters = _omit(appParams, [...apiParamKeys, ...appParamKeys]);
    _forEach(appFilters, (filterValue, filterKey) => {
      if (filterValue !== undefined) {
        const { key, operator } = parseAppParamKey(filterKey);
        if (operator) {
          filters.push({
            [key]: {
              [operator]: filterValue,
            },
          });
        } else {
          filters.push({
            [key]: filterValue,
          });
        }
      }
    });
  }

  if (Array.isArray(filters)) {
    if (filters.length > 1) {
      _set(params, ['filters', '$and'], filters);
    } else if(filters.length > 0) {
      _set(params, ['filters'], filters[0]);
    }
  } else if (typeof filters === 'object') {
    _set(params, ['filters'], filters);
  }

  
  const hasParams = Object.keys(params).length > 0;
  const paramString = hasParams ? `?${_map(params, (args, key) => qs.stringify({ [key]: args })).join('&')}` : '';

  return {
    params,
    paramString
  };
};
