/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox } from '@webfox-sc/core';
import TableBodyRow from '../../components/elements/TableBodyRow';
import TableBodyCell from '../../components/elements/TableBodyCell';
import { themeCheckbox } from '../../theme/theme';
import DateUtil from '../../utils/DateUtil';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';

const ClientListItem = ({ objectId, onClick, isSelected, onSelect }) => {
  const object = useSelector(({ dataState }) => dataState.clients[objectId]);
  if (!object) {
    return null;
  }

  const {
    company,
    // companyId,
    companyIdOld,
    location,
    // locationId,
    locationIdOld,
    costcenter,
    // costcenterId,
    costcenterIdOld,
    updatedAt,
    isModified,
    isNew,
  } = object;

  // const company = companyId ? useSelector(({ dataState }) => dataState.companies[companyId]) : {};
  const oldCompany = companyIdOld ? useSelector(({ dataState }) => dataState.companies[companyIdOld]) : {};

  // const location = locationId ? useSelector(({ dataState }) => dataState.locations[locationId]) : {};
  const oldLocation = locationIdOld ? useSelector(({ dataState }) => dataState.locations[locationIdOld]) : {};

  // const costcenter = costcenterId ? useSelector(({ dataState }) => dataState.costcenters[costcenterId]) : {};
  const costcenterOld = costcenterIdOld ? useSelector(({ dataState }) => dataState.costcenters[costcenterIdOld]) : {};

  const updatedAtFormatted = updatedAt ? DateUtil.getDateFormatted(updatedAt) : '';

  return (
    <TableBodyRow isClickable isModified={isModified} isNew={isNew}>
      <TableBodyCell
        text={company && company.number}
        textOld={oldCompany && oldCompany.number}
        onClick={onClick}
        width="15%"
      />
      <TableBodyCell
        text={company && company.name}
        textOld={oldCompany && oldCompany.name}
        onClick={onClick}
        width="41%"
      />
      <TableBodyCell
        text={location && location.number}
        textOld={oldLocation && oldLocation.number}
        onClick={onClick}
        width="10%"
      />
      <TableBodyCell
        text={costcenter && costcenter.number}
        textOld={costcenterOld && costcenterOld.number}
        width="10%"
      />
      <TableBodyCell text={updatedAtFormatted} onClick={onClick} width="10%" />
      <TableBodyCell width="7%">
        <Checkbox checked={isSelected} onChange={(e) => onSelect(e.target.checked, objectId)} theme={themeCheckbox} />
      </TableBodyCell>
      <TableBodyCell iconSrc={IconArrowDown} onClick={onClick} width="7%" />
    </TableBodyRow>
  );
};

ClientListItem.propTypes = {
  objectId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

ClientListItem.defaultProps = {
  onClick: () => {},
  isSelected: PropTypes.bool,
  onSelect: () => false,
};

export default ClientListItem;
