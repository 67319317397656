import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const ns = process.env.REACT_APP_I18N_NAMESPACE;
export const fallbackLanguage = 'de';

const i18nextConfig = {
  lng: fallbackLanguage,
  ns: ['common', `${ns}`],
  defaultNS: ns,
  fallbackNS: 'common',
  debug: process.env.NODE_ENV === 'development',
  fallbackLng: fallbackLanguage
};

i18n.use(Backend).use(initReactI18next).init(i18nextConfig);

export default i18n;
