import { useMemo } from 'react';
import { isEmpty, orderBy } from 'lodash';

import { useJob } from '../../app/entityHooks/useJob';
import { entitySelectors } from '../../app/entitySelectors';
import { JOB_STATUS } from '../../global/constants';

export const useJobHistories = (
  jobId: number | undefined
): {
  job: JobEntity | undefined;
  offerHistoriesSorted: HistoryEntity[];
  certificateHistoriesSorted: HistoryEntity[];
  jobHistoriesSorted: HistoryEntity[];
} => {
  const { job } = useJob(jobId);
  const histories = entitySelectors.selectByIds<HistoryEntity>('histories', job?.historyIds);

  const offerHistoriesSorted: HistoryEntity[] = useMemo(() => {
    return orderBy(
      histories.filter(
        (history) =>
          (history.targetStatus || '').startsWith('A-') ||
          !isEmpty(history.diffOld?.offer) ||
          !isEmpty(history.diffNew?.offer)
      ),
      ['createdAt'],
      ['asc']
    );
  }, [histories]);

  const certificateHistoriesSorted: HistoryEntity[] = useMemo(() => {
    return orderBy(
      histories.filter(
        (history) =>
          (history.targetStatus || '').startsWith('LN-') ||
          !isEmpty(history.diffOld?.certificate) ||
          !isEmpty(history.diffNew?.certificate)
      ),
      ['createdAt'],
      ['asc']
    );
  }, [histories]);

  const jobHistoriesSorted: HistoryEntity[] = useMemo(() => {
    return orderBy(
      histories.filter((history) => !history.targetStatus || history.targetStatus === JOB_STATUS.CREATED),
      ['createdAt'],
      ['asc']
    );
  }, [histories]);

  return {
    job,
    offerHistoriesSorted,
    certificateHistoriesSorted,
    jobHistoriesSorted,
  };
};
