/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './headline-two.scss';

class HeadlineTwo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, children } = this.props;

        return <h2 className="smg-headline-two">{text || children}</h2>;
    }
}

HeadlineTwo.propTypes = {
    text: PropTypes.string,
    children: PropTypes.any
};

HeadlineTwo.defaultProps = {
    text: '',
    children: null
};

export default HeadlineTwo;
