import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../app/entityHooks/useUser';
import { useAuth } from '../auth/useAuth';

type StatusSelectOption = { value: '' | JobStatus | JobOfferStatus; label: string };

export const useJobsFilters = (): {
  showCostcenterFilter: boolean;
  costcenterOptions: SelectOption[];
  statusOptions: StatusSelectOption[];
} => {
  const { t } = useTranslation();
  const statusOptions: StatusSelectOption[] = useMemo(
    () => [
      {
        value: '',
        label: t('common.form.all'),
      },
      {
        value: 'Erstellt',
        label: t('job.form.status.new'),
      },
      {
        value: 'A-erstellt',
        label: t('job.form.status.offerCreated'),
      },
      {
        value: 'A-ausstehend',
        label: t('job.form.status.offerPending'),
      },
      {
        value: 'A-bestaetigt',
        label: t('job.form.status.offerConfirmed'),
      },
      {
        value: 'A-abgelehnt',
        label: t('job.form.status.offerRejected'),
      },
      {
        value: 'LN-erstellt',
        label: t('job.form.status.certificateCreated'),
      },
      {
        value: 'LN-ausstehend',
        label: t('job.form.status.certificatePending'),
      },
      {
        value: 'LN-bestaetigt',
        label: t('job.form.status.certificateAccepted'),
      },
      {
        value: 'LN-abgelehnt',
        label: t('job.form.status.certificateRejected'),
      },
    ],
    [t]
  );

  const { currentUser } = useAuth();
  const { costcenters } = useUser(currentUser?.id);

  const showCostcenterFilter = costcenters.length > 0;

  const costcenterOptions: SelectOption[] = useMemo(() => {
    return [
      {
        value: '',
        label: t('common.form.all'),
      },
      ...orderBy(costcenters, [(costcenter: CostcenterEntity) => costcenter.number], ['asc']).map(
        (costcenter: CostcenterEntity) => {
          return {
            value: costcenter.number || '',
            label: costcenter.number,
          };
        }
      ),
    ];
  }, [costcenters, t]);

  return {
    showCostcenterFilter,
    costcenterOptions,
    statusOptions,
  };
};
