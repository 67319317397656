import axios from 'axios';
import _forEach from 'lodash/forEach';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';

export function fetchImportFiles() {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_IMPORTS,
    });

    return axios({
      method: 'get',
      url: `${RestUtil.getStrapiUrl()}/imports`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const data = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_IMPORTS,
          data,
        });
        return data;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_IMPORTS_ERROR,
        });
        throw error;
      });
  };
}

export function uploadImportFiles({ files, ...data }) {
  return async (dispatch, getState) => {
    const { authState } = getState();
    dispatch({
      type: REDUX_ACTIONS.REQUEST_UPLOAD_IMPORTS,
    });

    const formData = new FormData();
    _forEach(files, (file, index) => {
      formData.append(`files.${index}`, file, file.name.replace(' ', ''));
    });
    formData.append('data', JSON.stringify(data));

    return axios({
      method: 'post',
      url: `${RestUtil.getStrapiUrl()}/imports`,
      data: formData,
      headers: {
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_UPLOAD_IMPORTS,
          data: res.data || {},
        });
        return res.data || {};
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_UPLOAD_IMPORTS_ERROR,
        });
        throw error;
      });
  };
}

export function importImportFiles(data) {
  return async (dispatch, getState) => {
    const { authState } = getState();
    dispatch({
      type: REDUX_ACTIONS.REQUEST_IMPORT_IMPORTS,
    });

    return axios({
      method: 'put',
      url: `${RestUtil.getStrapiUrl()}/imports`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_IMPORT_IMPORTS,
          data: res.data || {},
        });
        return res.data || {};
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_IMPORT_IMPORTS_ERROR,
        });
        throw error;
      });
  };
}

export function deleteImportFile(id) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_IMPORT,
    });

    return axios({
      method: 'delete',
      url: `${RestUtil.getStrapiUrl()}/imports/${id}`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const data = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_IMPORT,
          data,
        });
        return data;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_IMPORT_ERROR,
        });
        throw error;
      });
  };
}
