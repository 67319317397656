import React from 'react';
import styled, { CSSObject } from 'styled-components';

type IconButtonVariant = 'white' | 'red';

interface StyledIconButtonProps {
  variant?: IconButtonVariant;
}

const StyledIconButton = styled.button<StyledIconButtonProps>(({ variant, theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    width: '22px',
    height: '22px',
    border: 0,
    margin: 0,
    padding: 0,
    background: 'transparent',
    color: theme.colors.ciBlue3,
    cursor: 'pointer',
    transition: 'color 0.1s ease',
    svg: {
      width: '18px',
      maxWidth: 'none',
      height: '18px',
      verticalAlign: 'top',
    },
    '&:hover': {
      color: theme.colors.ciRed,
    },
    '&:active': {
      color: theme.colors.ciRed,
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
      '&:hover': {
        opacity: 0.5,
      },
    },
  };

  switch (variant) {
    case 'white':
      return {
        ...baseStyle,
        color: 'white',
        '&:hover': {
          color: theme.colors.ciBlue5,
        },
        '&:active': {
          color: 'white',
        },
      };
    case 'red':
      return {
        ...baseStyle,
        color: theme.colors.ciRed,
        '&:hover': {
          color: theme.colors.ciBlue2,
        },
        '&:active': {
          color: theme.colors.ciBlue2,
        },
      };

    default:
      return {
        ...baseStyle,
      };
  }
});

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: IconButtonVariant;
  icon?: React.ReactNode;
}

const IconButton: React.FC<IconButtonProps> = ({ variant, icon, type, ...props }) => {
  return (
    <StyledIconButton variant={variant} type={type || 'button'} {...props}>
      {icon}
    </StyledIconButton>
  );
};

export default IconButton;
