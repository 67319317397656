import { useMemo } from 'react';
import { useRoomgroup } from '../../../app/entityHooks/useRoomgroup';

export const useBaseDataQMRoomgroup = (
  roomgroupId: number | undefined
): {
  roomgroup: RoomgroupEntity | undefined;
  checkitemgroups: CheckitemgroupEntity[];
  countCheckitems: number;
} => {
  const { roomgroup, checkitemgroups } = useRoomgroup(roomgroupId);

  const countCheckitems = useMemo(() => {
    let result = 0;
    checkitemgroups.forEach((checkitemgroup) => {
      result += checkitemgroup.checkitemIds.length;
    });
    return result;
  }, [checkitemgroups]);

  return {
    roomgroup,
    checkitemgroups,
    countCheckitems,
  };
};
