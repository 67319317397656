import React from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv } from '@webfox-sc/core';

import TextBox from '../atoms-v2/TextBox';

interface JobServicesSummaryProps {
  netPriceFormatted?: string;
  vatLabel?: string;
  vatFormatted?: string;
  grossPriceFormatted?: string;
}

const JobServicesSummary: React.FC<JobServicesSummaryProps> = ({
  netPriceFormatted,
  vatLabel,
  vatFormatted,
  grossPriceFormatted,
}) => {
  const { t } = useTranslation();
  return (
    <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" rowGap="xxs" columnGap="xxs">
      {netPriceFormatted && (
        <>
          <TextBox gridColumn={{ mobile: '1 / 9', tablet: '1 / 11' }} textAlign="right">
            <b>{t('job.serviceForm.totalNet')}</b>
          </TextBox>
          <TextBox gridColumn={{ mobile: '9 / 13', tablet: '11 / 13' }} background="grey20" textAlign="right">
            <b>{netPriceFormatted}</b>
          </TextBox>
        </>
      )}

      {!!vatLabel && !!vatFormatted && (
        <>
          <TextBox gridColumn={{ mobile: '1 / 9', tablet: '1 / 11' }} textAlign="right">
            <b>{vatLabel}</b>
          </TextBox>
          <TextBox gridColumn={{ mobile: '9 / 13', tablet: '11 / 13' }} background="grey20" textAlign="right">
            <b>{vatFormatted}</b>
          </TextBox>
        </>
      )}

      {grossPriceFormatted && (
        <>
          <TextBox gridColumn={{ mobile: '1 / 9', tablet: '1 / 11' }} textAlign="right">
            <b>{t('job.serviceForm.totalGross')}</b>
          </TextBox>
          <TextBox gridColumn={{ mobile: '9 / 13', tablet: '11 / 13' }} background="grey20" textAlign="right">
            <b>{grossPriceFormatted}</b>
          </TextBox>
        </>
      )}
    </UiDiv>
  );
};

export default JobServicesSummary;
