import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const DataEditor = ({ objectId, objectPath, component: Editor, ...editorProps }) => {
  const model = useSelector(({ dataState }) => dataState[objectPath][objectId]);
  if (!model) {
    return null;
  }
  return <Editor model={model} {...editorProps} />;
};

DataEditor.propTypes = {
  objectId: PropTypes.number.isRequired,
  objectPath: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

DataEditor.defaultProps = {};

export default DataEditor;
