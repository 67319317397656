import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const limitInterval = 20;

export interface JobListState {
  selectedCostcenterNumber?: string;
  selectedCompanyName?: string;
  selectedStatus?: '' | JobStatus | JobOfferStatus;
  searchValue?: string;
  sortingValue?: string;
  limit?: number;
  jobIds?: number[];
  jobsCount?: number;
}

const initialState: JobListState = {
  selectedCostcenterNumber: '',
  selectedCompanyName: '',
  selectedStatus: '',
  searchValue: '',
  sortingValue: 'createdAt-desc',
  limit: limitInterval,
  jobIds: [],
  jobsCount: 0,
};

export const jobListSlice = createSlice({
  name: 'jobList',
  initialState,
  reducers: {
    setJobListState: (state: JobListState, action: PayloadAction<JobListState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setJobListState } = jobListSlice.actions;
export const selectJobListState = (state: RootState): JobListState => state.jobListState;
export default jobListSlice.reducer;
