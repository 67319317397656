/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './table-body-row.scss';

class TableBodyRow extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { isClickable, onClick, isActive, isError, isModified, isNew, children } = this.props;

        let className = 'smg-table-body-row';
        if (isClickable) {
            className += ' smg-table-body-row--clickable';
        }
        if (isActive) {
            className += ' smg-table-body-row--active';
        }
        if (isError) {
            className += ' smg-table-body-row--error';
        }
        if (isModified) {
            className += ' smg-table-body-row--modified';
        }
        if (isNew) {
            className += ' smg-table-body-row--new';
        }

        return (
            <div className={className} onClick={onClick}>
                {children}
            </div>
        );
    }
}

TableBodyRow.propTypes = {
    isClickable: PropTypes.bool,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    isError: PropTypes.bool,
    isModified: PropTypes.bool,
    isNew: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

TableBodyRow.defaultProps = {
    isClickable: false,
    onClick: () => false,
    isActive: false,
    isError: false,
    isModified: false,
    isNew: false,
    children: null
};

export default TableBodyRow;
