import { InlineSVG, UiDiv, UiElement, UiFlex, UiSpan, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import InvoicedCheckbox from './InvoicedCheckbox';

interface DashboardJobCardProps {
  children: React.ReactNode;
  createdAt?: string;
  clientName?: string;
  entryId?: string;
  dlasFormatted?: string;
  carrierNumber?: string;
  href?: string;
  totalPrice?: string;
  onNavigate?: React.MouseEventHandler<HTMLAnchorElement>;
  onSelect?: () => void;
  isSelected: boolean;
}

const DashboardJobCard: React.FC<DashboardJobCardProps> = ({
  createdAt,
  clientName,
  entryId,
  dlasFormatted,
  carrierNumber,
  totalPrice,
  href,
  onNavigate,
  onSelect,
  isSelected,
  children,
}) => {
  const { t } = useTranslation();

  const handleOnNavigate: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    if (onNavigate) {
      onNavigate(e);
    }
  };

  const handleOnSelect = onSelect
    ? () => {
        onSelect();
      }
    : undefined;

  return (
    <UiElement
      as={onSelect ? 'div' : 'a'}
      textDecoration="none"
      color="ciBlue1"
      display="grid"
      gridTemplateColumns={{
        mobile: '1fr 32px 22px',
        tablet: `5fr 74px 1fr ${onSelect ? 'auto' : ''} 22px`,
      }}
      padding="15px"
      background={{ _: 'white', ':hover': 'grey20' }}
      transition="background-color 0.3s ease"
      alignItems="center"
      columnGap="s"
      href={onSelect ? undefined : href}
      onClick={onSelect ? undefined : handleOnNavigate}
    >
      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 6fr 3fr 2fr 2fr' }}
        rowGap="xxs"
        columnGap="s"
        alignItems="center"
      >
        <UiText variant="copySmall">{entryId}</UiText>
        <UiText variant="copySmall">{clientName}</UiText>
        <UiText variant="copySmall">
          <UiSpan display={{ mobile: 'inline', tablet: 'none' }}>
            {t('common.fields.carrierNumber.medium')}: {carrierNumber}
          </UiSpan>
          <UiSpan display={{ mobile: 'none', tablet: 'inline' }}>
            {t('common.fields.carrierNumber.short')}:
            <br />
            {carrierNumber}
          </UiSpan>
        </UiText>
        <UiText variant="copySmall">
          {dlasFormatted && (
            <>
              <UiSpan display={{ mobile: 'inline', tablet: 'none' }}>
                {t('common.fields.dla')}: {dlasFormatted}
              </UiSpan>
              <UiSpan display={{ mobile: 'none', tablet: 'inline' }}>
                {t('common.fields.dla')}:
                <br />
                {dlasFormatted}
              </UiSpan>
            </>
          )}
        </UiText>
        <UiText variant="copySmall">
          <UiSpan display={{ mobile: 'inline', tablet: 'none' }}>
            {t('common.fields.createdAt')}: {createdAt}
          </UiSpan>
          <UiSpan display={{ mobile: 'none', tablet: 'inline' }}>
            {t('common.fields.createdAt')}:
            <br />
            {createdAt}
          </UiSpan>
        </UiText>
        <UiText variant="copySmall" display={{ mobile: 'block', tablet: 'none' }}>
          {t('job.total')}: {totalPrice}
        </UiText>

        <UiText variant="copySmall" display={{ mobile: 'flex', tablet: 'none' }} gap="xs">
          <UiSpan>{t('job.isInvoiced')}: </UiSpan>
          <InvoicedCheckbox checked={isSelected} onChange={handleOnSelect} />
        </UiText>
      </UiDiv>

      <UiDiv display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} rowGap="10px" columnGap="5px">
        {children}
      </UiDiv>

      <UiText variant="copySmall" display={{ mobile: 'none', tablet: 'block' }}>
        {t('job.total')}: {totalPrice}
      </UiText>
      {handleOnSelect ? (
        <>
          <UiDiv
            justifyContent="center"
            alignItems="center"
            display={{ mobile: 'none', tablet: 'flex' }}
            gap="xxs"
            flexDirection="column"
          >
            <UiText variant="copySmall">
              <UiSpan>
                {t('job.isInvoiced')}:<br />
              </UiSpan>
            </UiText>
            <InvoicedCheckbox checked={isSelected} onChange={handleOnSelect} />
          </UiDiv>

          <a href={href}>
            <UiFlex width="22px" height="22px" justifyContent="center" alignItems="center" color="ciBlue3">
              <InlineSVG width="18px" height="18px" onClick={handleOnNavigate}>
                {IconArrowRight}
              </InlineSVG>
            </UiFlex>
          </a>
        </>
      ) : (
        <UiFlex width="22px" height="22px" justifyContent="center" alignItems="center" color="ciBlue3">
          <InlineSVG width="18px" height="18px" onClick={handleOnNavigate}>
            {IconArrowRight}
          </InlineSVG>
        </UiFlex>
      )}
    </UiElement>
  );
};

export default DashboardJobCard;
