import React from 'react';
import styled from 'styled-components';

type TextButtonVariant = 'white';

interface StyledTextButtonProps {
  variant?: TextButtonVariant;
}

const StyledTextButton = styled.button<StyledTextButtonProps>(({ variant, theme }) => {
  return {
    appearance: 'none',
    padding: 0,
    margin: 0,
    background: 'transparent',
    border: 'none',
    display: 'inline-flex',
    gap: '10px',
    alignItems: 'center',
    ...theme.type.copy,
    color: variant === 'white' ? 'white' : theme.colors.ciRed,
    textDecoration: variant === 'white' ? 'underline' : 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      color: variant === 'white' ? 'white' : theme.colors.ciRed,
      textDecoration: variant === 'white' ? 'none !important' : 'underline !important',
    },
    '&:active': {
      color: theme.colors.ciBlue4,
      textDecoration: variant === 'white' ? 'underline !important' : 'none !important',
    },
    svg: {
      width: '12px',
      height: '12px',
      verticalAlign: 'top',
      textDecoration: 'none',
    },
  };
});

interface TextButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: TextButtonVariant;
  icon?: React.ReactNode;
}

const TextButton: React.FC<TextButtonProps> = ({ variant, icon, children, ...props }) => {
  return (
    <StyledTextButton variant={variant} {...props}>
      {icon}
      {children}
    </StyledTextButton>
  );
};

export default TextButton;
