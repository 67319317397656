import React from 'react';
import styled, { CSSObject } from 'styled-components';

type ButtonVariant = 'primary' | 'secondary' | 'secondaryRed' | 'secondaryWhite' | 'highlight' | 'yellow' | 'red' | 'grey';

interface StyledButtonProps {
  variant?: ButtonVariant;
  isIconOnly?: boolean;
  minWidth?: 0 | string;
}

const StyledButton = styled.button<StyledButtonProps>(({ variant, isIconOnly, minWidth, theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    width: '100%', // only on mobile
    minWidth: minWidth || '50px',
    minHeight: '50px',
    border: 0,
    borderRadius: '5px',
    padding: isIconOnly ? 0 : '0 20px',
    margin: 0,
    background: theme.colors.ciBlue3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    ...(theme.type.button as CSSObject),
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.1s ease',
    svg: {
      flex: '0 0 14px',
      width: '14px',
      maxWidth: 'none',
      height: '14px',
      verticalAlign: 'top',
    },
    '&:hover': {
      background: theme.colors.ciRed,
    },
    '&:active': {
      background: theme.colors.ciRed,
    },
    '&[data-active="true"]': {
      background: theme.colors.ciRed,
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
      '&:hover': {
        opacity: 0.5,
      },
    },
    [theme.mediaQueries.tablet]: {
      width: 'auto',
    },
  };

  switch (variant) {
    case 'secondary':
      return {
        ...baseStyle,
        background: 'transparent',
        border: `1px solid ${theme.colors.ciBlue3}`,
        padding: isIconOnly ? 0 : '0 19px',
        color: theme.colors.ciBlue3,
        '&:hover': {
          borderColor: theme.colors.ciRed,
          color: theme.colors.ciRed,
        },
        '&:active': {
          background: theme.colors.ciRed,
          color: theme.colors.white,
        },
        '&[data-active="true"]': {
          background: theme.colors.ciRed,
          borderColor: theme.colors.ciRed,
          color: theme.colors.white,
        },
      };

    case 'secondaryRed':
      return {
        ...baseStyle,
        background: 'transparent',
        border: `1px solid ${theme.colors.ciRed}`,
        padding: isIconOnly ? 0 : '0 19px',
        color: theme.colors.ciRed,
        '&:hover': {
          background: theme.colors.ciRed,
          color: theme.colors.white,
        },
        '&:active': {
          background: theme.colors.ciRed,
          color: theme.colors.white,
        },
        '&[data-active="true"]': {
          background: theme.colors.ciRed,
          color: theme.colors.white,
        },
      };

    case 'secondaryWhite':
      return {
        ...baseStyle,
        background: 'transparent',
        border: '1px solid white',
        padding: isIconOnly ? 0 : '0 19px',
        color: 'white',
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.1)',
        },
        '&:active': {
          background: 'rgba(255, 255, 255, 0.2)',
        },
        '&[data-active="true"]': {
          background: 'white',
          color: theme.colors.black,
        },
      };

    case 'highlight':
      return {
        ...baseStyle,
        background: theme.colors.acGreen,
        color: theme.colors.black,
        '&:hover': {
          background: theme.colors.acGreenDark,
        },
        '&:active': {
          background: theme.colors.acGreenDark,
        },
        '&[data-active="true"]': {
          background: theme.colors.acGreenDark,
          color: 'white',
        },
      };

    case 'yellow':
      return {
        ...baseStyle,
        background: theme.colors.acOrange,
        color: theme.colors.black,
        '&:hover': {
          filter: 'brightness(90%)'
        },
        '&:active': {
          filter: 'brightness(80%)'
        },
        '&[data-active="true"]': {
          filter: 'brightness(75%)'
        },
      };

    case 'red':
      return {
        ...baseStyle,
        background: theme.colors.ciRed,
        color: theme.colors.white,
        '&:hover': {
          filter: 'brightness(90%)',
          color: theme.colors.white,
        },
        '&:active': {
          filter: 'brightness(80%)',
          color: theme.colors.white,
        },
        '&[data-active="true"]': {
          filter: 'brightness(75%)',
          color: theme.colors.white,
        },
      };

    case 'grey':
      return {
        ...baseStyle,
        background: theme.colors.grey20,
        color: theme.colors.ciBlue1,
        '&:hover': {
          background: theme.colors.grey40,
          // color: 'black',
        },
        '&:active': {
          background: theme.colors.grey80,
          color: theme.colors.white,
        },
        '&[data-active="true"]': {
          background: theme.colors.grey80,
          color: theme.colors.white,
        },
      };
  
    /**
     * return primary as default
     */
    default:
      return {
        ...baseStyle,
      };
  }
});

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  minWidth?: 0 | string;
  active?: boolean;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ variant, icon, endIcon, minWidth, active, type, children, disabled, ...props }) => {
  const isIconOnly = (!!icon || !!endIcon) && !children;

  const dataAttributes = {
    'data-active': active,
  };

  return (
    <StyledButton
      variant={variant}
      isIconOnly={isIconOnly}
      minWidth={minWidth}
      type={type || 'button'}
      disabled={disabled}
      {...props}
      {...dataAttributes}
    >
      {icon}
      {children}
      {endIcon}
    </StyledButton>
  );
};

export default Button;
