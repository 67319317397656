import { Block, UiDiv } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateUtil from '../../utils/DateUtil';
import CustomDatePicker from '../atoms-v2/CustomDatePicker';
import Text from '../atoms-v2/Text';
import QMHistoryVisualizationGraph from './QMHistoryVisualizationGraph';

type QMHistoryVisualizationProps = {
  reports: ReportEntity[];
};

const QMHistoryVisualization: React.FC<QMHistoryVisualizationProps> = ({ reports }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [timeFilterIsSet, setTimeFilterIsSet] = useState(false);
  const filteredReports = reports
    .filter((report) => {
      if (startDate === undefined || report.date === undefined) {
        return true;
      }

      const roundedDate = report.date.split('T')[0];
      const reportDate = new Date(roundedDate);

      return reportDate.getTime() >= startDate.getTime();
    })
    .filter((report) => {
      if (endDate === undefined || report.date === undefined) {
        return true;
      }

      const roundedDate = report.date.split('T')[0];
      const reportDate = new Date(roundedDate);

      // otherwise daylight saving time could interfere
      reportDate.setHours(0);
      reportDate.setMinutes(0);
      reportDate.setSeconds(0);

      return reportDate.getTime() <= endDate.getTime();
    });

  useEffect(() => {
    if (startDate !== undefined || endDate !== undefined) {
      setTimeFilterIsSet(true);
    }
  }, [startDate, endDate]);

  return (
    <Block>
      <UiDiv display={{ tablet: 'flex' }} alignItems="center" gap="m" justifyContent="center">
        <UiDiv marginBottom={{ mobile: 's', tablet: 0 }} textAlign="center">
          <Text color="white">{t('report.form.from')}</Text>
        </UiDiv>
        <Block display="flex" alignItems="center" gap="m" justify="center">
          <Block>
            <CustomDatePicker
              variant="outline"
              selected={startDate}
              maxDate={endDate}
              value={DateUtil.getDateFormatted(startDate)}
              onChange={(date) => {
                if (date === null) {
                  return;
                }

                setStartDate(date as Date);
              }}
            />
          </Block>
          <Block>
            <Text color="white">{t('report.form.until')}</Text>
          </Block>
          <Block>
            <CustomDatePicker
              variant="outline"
              selected={endDate}
              minDate={startDate}
              value={DateUtil.getDateFormatted(endDate)}
              onChange={(date) => {
                if (date === null) {
                  return;
                }

                setEndDate(date as Date);
              }}
            />
          </Block>
        </Block>
      </UiDiv>
      <QMHistoryVisualizationGraph reports={filteredReports} showOldest={timeFilterIsSet} />
    </Block>
  );
};

export default QMHistoryVisualization;
