import { entitySelectors } from '../entitySelectors';

export const useCertificate = (
  certificateId: number | undefined
): {
  certificate: CertificateEntity | undefined;
  job: JobEntity | undefined;
  recipient: RecipientEntity | undefined;
  files: FileEntity[];
  pdfFile: FileEntity | undefined;
} => {
  const certificate = entitySelectors.selectById<CertificateEntity>('certificates', certificateId);
  const job = entitySelectors.selectById<JobEntity>('jobs', certificate?.jobId);
  const recipient = entitySelectors.selectById<RecipientEntity>('recipients', certificate?.recipientId);
  const files = entitySelectors.selectByIds<FileEntity>('files', certificate?.fileIds);
  const pdfFile = entitySelectors.selectById<FileEntity>('files', certificate?.pdfFileId);

  return {
    certificate,
    job,
    recipient,
    files,
    pdfFile,
  };
};
