import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from '../../app/entityHooks/useHistory';
import DateUtil from '../../utils/DateUtil';
import TextBox from '../atoms-v2/TextBox';
import JobHistoryDiff from './JobHistoryDiff';

interface JobHistoryListItemProps {
  historyId: number | undefined;
}

const JobHistoryListItem: React.FC<JobHistoryListItemProps> = ({ historyId }) => {
  const { t } = useTranslation();
  const { history } = useHistory(historyId);

  if (!history) {
    return null;
  }

  const historyTitles: {
    [key in JobStatus | JobOfferStatus]?: string;
  } = {
    Erstellt: t('history.status.created') || '',
    'A-erstellt': t('history.status.offerCreated') || '',
    'A-ausstehend': t('history.status.offerPending') || '',
    'A-bestaetigt': t('history.status.offerConfirmed') || '',
    'A-abgelehnt': t('history.status.offerRejected') || '',
    'LN-erstellt': t('history.status.certificateCreated') || '',
    'LN-ausstehend': t('history.status.certificatePending') || '',
    'LN-bestaetigt': t('history.status.certificateAccepted') || '',
    'LN-abgelehnt': t('history.status.certificateRejected') || '',
  };

  // -- recipients formatted
  let recipientPersonsFormatted: string[] = [];

  // -- create recipients formatted by recipientPersonList
  recipientPersonsFormatted = (history.recipientPersonList || []).map((recipientPerson) => {
    return `${recipientPerson.name} (${recipientPerson.email})`;
  });

  // -- fallback recipient formatted for legacy data
  if (!recipientPersonsFormatted.length && history.recipientName && history.recipientEmail) {
    recipientPersonsFormatted.push(`${history.recipientName} (${history.recipientEmail})`);
  }

  return (
    <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 2fr' }} columnGap="xxs">
      <TextBox variant="copy" order={{ tablet: 3 }} background="grey20">
        {DateUtil.getDateTimeFormattedExtended(history.createdAt)}
      </TextBox>
      <TextBox variant="copy" order={{ tablet: 4 }} background="grey20">
        {history.targetStatus && (
          <UiDiv>
            <strong>{historyTitles[history.targetStatus]}</strong>
          </UiDiv>
        )}

        {(history.diffNew || history.diffOld) && (
          <UiDiv>
            <JobHistoryDiff newDiff={history.diffNew} oldDiff={history.diffOld} />
          </UiDiv>
        )}

        {history.actionDoneBy && (
          <UiDiv marginTop="xs">
            {t('common.fields.by')}: {history.actionDoneBy}
          </UiDiv>
        )}

        {recipientPersonsFormatted.length > 0 && (
          <UiDiv marginTop="xs">
            {recipientPersonsFormatted.map((personFormatted, index) => {
              return (
                <UiDiv key={String(index)} wordBreak="break-all">
                  {index === 0 ? t('message.to') : t('message.cc')}: {personFormatted}
                </UiDiv>
              );
            })}
          </UiDiv>
        )}

        {history.comment && (
          <UiDiv marginTop="xs">
            {t('history.comment')}:
            <br />
            {history.comment}
          </UiDiv>
        )}
      </TextBox>
    </UiDiv>
  );
};

export default JobHistoryListItem;
