import { entitySelectors } from '../entitySelectors';

export const useCheckitemtype = (
  checkitemtypeId: number | undefined
): {
  checkitemtype: CheckitemtypeEntity | undefined;
} => {
  const checkitemtype = entitySelectors.selectById<CheckitemtypeEntity>('checkitemtypes', checkitemtypeId);

  return {
    checkitemtype,
  };
};
