import { UiDiv, UiText } from '@webfox-sc/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { useJob } from '../../app/entityHooks/useJob';
import { useRecipient } from '../../app/entityHooks/useRecipient';
import { updateCertificate } from '../../app/slices/certificatesSlice';
import {
  createJob,
  deleteJob,
  fetchJob,
  selectJobsState,
  updateJob,
  updateJobIsInvoiced,
} from '../../app/slices/jobsSlice';
import { updateOffer } from '../../app/slices/offersSlice';
import { createRecipient } from '../../app/slices/recipientsSlice';
import IconClose from '../../assets/icons-v2/IconClose';
import IconCopy from '../../assets/icons-v2/IconCopy';
import IconEdit from '../../assets/icons-v2/IconEdit';
import IconFolder from '../../assets/icons-v2/IconFolder';
import IconNotFound from '../../assets/icons-v2/IconNotFound';
import IconRefresh from '../../assets/icons-v2/IconRefresh';
import AppUtil from '../../utils/AppUtil';
import LoadingWrapper from '../atoms-v2/LoadingWrapper';
import NoResultsNotice from '../atoms-v2/NoResultsNotice';
import { useAuth } from '../auth/useAuth';
import PageContent from '../page/PageContent';
import PageContentSubHeading from '../page/PageContentSubHeading';
import PageHeading from '../page/PageHeading';

import { ALLOW_EDIT_JOB_CLIENT } from '../../app.config';
import ButtonJobDetails from './ButtonJobDetails';
import JobAddressEditor from './JobAddressEditor';
import JobCertificate from './JobCertificate';
import JobHistory from './JobHistory';
import JobOffer from './JobOffer';
import JobRecipientEditor from './JobRecipientEditor';
import JobServices from './JobServices';
import { useJobRecipient } from './useJobRecipient';
import { usePermissions } from '../auth/usePermissions';
import FormPanel from '../atoms-v2/FormPanel';
import FormRowGroup from '../atoms-v2/FormRowGroup';
import Checkbox from '../atoms-v2/Checkbox';

const Job: React.FC = () => {
  const { t } = useTranslation();
  const { canEditJobIsInvoiced } = usePermissions();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('ref');
  const { currentUser } = useAuth();
  const { jobId: jobIdString } = useParams();
  const jobId = jobIdString ? parseInt(jobIdString) : 0;

  const { job, offer, certificate } = useJob(jobId);
  const { recipient: jobRecipient, contacts: jobRecipientContacts } = useRecipient(job?.recipientId);
  const { recipient: certificateRecipient, contacts: certificateRecipientContacts } = useRecipient(
    certificate?.recipientId
  );

  const isJobEditable = !job?.isArchive && job?.status !== 'LN-ausstehend' && job?.status !== 'LN-bestaetigt';

  const { handleUpdateJobRecipient, handleUpdateJobAddress } = useJobRecipient(jobId);

  const { fetchJobStatus } = useAppSelector(selectJobsState);

  /**
   * local state
   */
  const [isJobEditMode, setIsJobEditMode] = useState(false);
  const [isButtonCopyJobEnabled, setIsButtonCopyJobEnabled] = useState(false);

  /**
   * fetch job data
   */
  const fetchJobData = useCallback(() => {
    if (jobId) {
      dispatch(fetchJob(jobId));
    }
  }, [dispatch, jobId]);

  /**
   * fetch initial job data
   */
  useEffect(() => {
    if (jobId) {
      fetchJobData();
    }
  }, [jobId, fetchJobData]);

  /**
   * enable copying if client exists
   */
  useEffect(() => {
    if (job?.clientId) {
      setIsButtonCopyJobEnabled(true);
    }
  }, [job?.clientId]);

  const backPath = useMemo(() => {
    if (referrer) {
      return referrer;
    }
    return '/jobs';
  }, [referrer]);

  /**
   * duplicate job
   */
  const handleClickDuplicateJob = async () => {
    if (!!currentUser && !!job && !!jobRecipient) {
      setIsButtonCopyJobEnabled(false);

      const jobCreateData: JobCreateData = {
        creator_id: currentUser.id,
        status: 'Erstellt',
        costcenter: job.costcenterNumber,
        client_id: job.clientId,
        client_name: job.clientName,
        carrier_number: job.carrierNumber,
        contact_person_name: job.contactPersonName,
        contact_person_email: job.contactPersonEmail,
        no_tax: job.noTax,
        services: job.services,
        totalPrice: job.totalPrice,
      };

      const resCreateJob = await dispatch(createJob(jobCreateData));
      const newJobId = resCreateJob?.payload?.data?.id;

      if (newJobId) {
        const recipientCreateData: RecipientCreateData = {
          costcenter: jobRecipient.costcenterNumber,
          carrier_number: jobRecipient.carrierNumber,
          carrier_description: jobRecipient.carrierDescription,
          client_id: jobRecipient.clientId,
          debitor_id: jobRecipient.debitorId,
          client_number: jobRecipient.clientNumber,
          client_description: jobRecipient.clientDescription,
          contacts: jobRecipient.contactIds,
          address: jobRecipient.addressData,
          job: newJobId,
        };

        await dispatch(createRecipient(recipientCreateData));
      }

      setIsButtonCopyJobEnabled(true);
      setIsJobEditMode(true);
      navigate(`/job/${newJobId}`);
    }
  };

  /**
   * archive job
   */
  const handleOnClickArchiveJob = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('job.prompts.archive', { jobId: job?.entryId }) || undefined)) {
      if (job) {
        dispatch(
          updateJob(job.id, {
            is_archive: true,
          })
        );
      }
    }
  };

  /**
   * unarchive job
   */
  const handleOnClickUnArchiveJob = async () => {
    if (job) {
      dispatch(
        updateJob(job.id, {
          is_archive: false,
        })
      );
    }
  };

  /**
   * delete job
   */
  const handleOnClickDeleteJob = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('job.prompts.delete', { jobId: job?.entryId }) || undefined)) {
      if (job) {
        await dispatch(deleteJob(job?.id));
        navigate(backPath);
      }
    }
  };

  /**
   * update job recipient data
   */
  const handleUpdateJobRecipientData = async (jobRecipientEditorData: any) => {
    await handleUpdateJobRecipient(jobRecipientEditorData);
    fetchJobData();
  };

  /**
   * update job address data
   */
  const handleUpdateJobAddressData = async (jobAddressEditorData: any) => {
    await handleUpdateJobAddress(jobAddressEditorData);
    fetchJobData();
  };

  /**
   * send offer
   */
  const handleSendOffer = async (setOfferPendingStatus?: boolean) => {
    if (!job || !offer) {
      return false;
    }

    try {
      const offerUpdateData: OfferUpdateData = {
        sendMail: true,
        additionalRecipients: jobRecipientContacts.map((contact) => contact.email),
        entry_id: offer.entryId,
        linkURL: `${AppUtil.getBaseUrl()}/offer/${offer.id}/`,
        ccLinkURL: `${AppUtil.getBaseUrl()}/job/${job.id}/`,
        costCenterNumber: jobRecipient?.costcenterNumber,
        creator_name: offer.creatorName,
        creator_email: offer.creatorEmail,
        creator_tel: offer.creatorTel,
      };
      // -- send status only if necessary
      if (setOfferPendingStatus) {
        offerUpdateData.confirmed = 'DEFAULT';
      }
      await dispatch(updateOffer(offer.id, offerUpdateData));
    } catch (error: any) {
      return false;
    }

    fetchJobData();
    return true;
  };

  /**
   * send certificate
   */
  const handleSendCertificate = async (setCertficatePendingStatus?: boolean) => {
    if (!job || !certificate) {
      return false;
    }

    try {
      const certificateUpdateData: CertificateUpdateData = {
        sendMail: true,
        entry_id: certificate.entryId,
        additionalRecipients: certificateRecipientContacts.map((contact) => contact.email),
        linkURL: `${AppUtil.getBaseUrl()}/confirm/${certificate.id}/`,
        costCenterNumber: certificateRecipient?.costcenterNumber,
        creator_name: certificate.creatorName,
        creator_email: certificate.creatorEmail,
        creator_tel: certificate.creatorTel,
      };
      /**
       * send status only if necessary
       */
      if (setCertficatePendingStatus) {
        certificateUpdateData.confirmed = 'DEFAULT';
      }
      await dispatch(updateCertificate(certificate.id, certificateUpdateData));
    } catch (error: any) {
      return false;
    }

    fetchJobData();

    return true;
  };

  /**
   * render
   */
  return (
    <LoadingWrapper isLoading={!job && fetchJobStatus === 'loading'} paddingTop={!job ? 'xl' : undefined}>
      {!job ? (
        <PageContent>
          <NoResultsNotice icon={IconNotFound}>{t('job.messages.notFound')}</NoResultsNotice>
        </PageContent>
      ) : (
        <>
          <PageHeading
            heading={`Job ${job.entryId}`}
            backlinkLabel={t('common.actions.overview') || undefined}
            backlinkPath={backPath}
          />
          <PageContent marginTop="m">
            <UiDiv display="flex" gap="xs" justifyContent="center">
              {isJobEditable && (
                <ButtonJobDetails
                  icon={isJobEditMode ? IconClose : IconEdit}
                  onClick={() => setIsJobEditMode(!isJobEditMode)}
                >
                  {isJobEditMode ? t('common.actions.cancelEdit') : t('common.actions.edit')}
                </ButtonJobDetails>
              )}
              {!job.isArchive && (
                <ButtonJobDetails icon={IconCopy} disabled={!isButtonCopyJobEnabled} onClick={handleClickDuplicateJob}>
                  {t('common.actions.copy')}
                </ButtonJobDetails>
              )}
              {!job.isArchive && (
                <ButtonJobDetails icon={IconFolder} onClick={handleOnClickArchiveJob}>
                  {t('common.actions.archive')}
                </ButtonJobDetails>
              )}
              {job.isArchive && (
                <ButtonJobDetails icon={IconRefresh} onClick={handleOnClickUnArchiveJob}>
                  {t('common.actions.restore')}
                </ButtonJobDetails>
              )}
              <ButtonJobDetails icon={IconClose} onClick={handleOnClickDeleteJob}>
                {t('common.actions.delete')}
              </ButtonJobDetails>
            </UiDiv>

            <PageContentSubHeading leftSideText={`* ${t('common.form.requiredFields')}`} marginTop="l">
              {t('job.recipient')}
            </PageContentSubHeading>

            <UiDiv marginTop="s">
              <JobRecipientEditor
                jobId={job.id}
                isJobEditMode={isJobEditMode}
                onUpdate={(jobRecipientEditorData) => handleUpdateJobRecipientData(jobRecipientEditorData)}
              />
            </UiDiv>

            {ALLOW_EDIT_JOB_CLIENT && (
              <>
                <PageContentSubHeading marginTop="l">{t('common.entities.address.singular')}</PageContentSubHeading>

                <UiDiv marginTop="s">
                  <JobAddressEditor
                    jobId={job.id}
                    isJobEditMode={isJobEditMode}
                    onUpdate={(jobAddressEditorData) => handleUpdateJobAddressData(jobAddressEditorData)}
                  />
                </UiDiv>
              </>
            )}

            <PageContentSubHeading leftSideText={`* ${t('common.form.requiredFields')}`} marginTop="l">
              {t('job.services')}
            </PageContentSubHeading>

            <UiDiv marginTop="s">
              <JobServices jobId={job.id} isJobEditMode={isJobEditMode} onServicesUpdated={() => fetchJobData()} />
            </UiDiv>

            <UiDiv marginTop="l">
              <JobOffer
                jobId={job.id}
                onOfferCreated={() => fetchJobData()}
                onOfferInstantRejected={() => fetchJobData()}
                onClickSend={handleSendOffer}
              />
            </UiDiv>

            <UiDiv marginTop="l">
              <JobCertificate
                jobId={job.id}
                onCertificateCreated={() => fetchJobData()}
                onCertificateInstantConfirmed={() => fetchJobData()}
                onClickSend={handleSendCertificate}
              />
            </UiDiv>

            <UiDiv marginTop="l">
              <JobHistory jobId={job.id} />
            </UiDiv>

            {canEditJobIsInvoiced && (
              <>
                <PageContentSubHeading marginTop="l">{t('job.headline.invoicing')}</PageContentSubHeading>
                <UiDiv marginTop="s">
                  <FormPanel>
                    <FormRowGroup>
                      <UiDiv display="flex" gap="xs" alignItems="center">
                        <Checkbox
                          checked={job.isInvoiced}
                          onChange={(e) => {
                            dispatch(updateJobIsInvoiced(job.id, e.target.checked));
                          }}
                        />
                        <UiText flexGrow={1} variant="copy">
                          {t('job.isInvoiced')}
                        </UiText>
                      </UiDiv>
                    </FormRowGroup>
                  </FormPanel>
                </UiDiv>
              </>
            )}
          </PageContent>
        </>
      )}
    </LoadingWrapper>
  );
};

export default Job;
