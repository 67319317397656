import { entitySelectors } from '../entitySelectors';

export const useService = (
  serviceId: number | undefined
): {
  service: ServiceEntity | undefined;
} => {
  const service = entitySelectors.selectById<ServiceEntity>('services', serviceId);

  return {
    service,
  };
};
