/* xeslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './form-row.scss';

class FormRow extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { children } = this.props;

        return <div className="smg-form-row">{children}</div>;
    }
}

FormRow.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

FormRow.defaultProps = {
    children: null,
};

export default FormRow;
