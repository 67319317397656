import { pick } from 'lodash';
import hash from 'object-hash';
import AddressUtil from './AddressUtil';
import AppUtil from './AppUtil';
import CompaniesUtil from './CompaniesUtil';
import LocationsUtil from './LocationsUtil';

const parseCostcenterData = (costcenterData) => {
  const { id, createdAt, updatedAt, number, clients, client_ids, user_ids, users, isNew, isModified } = costcenterData;

  const baseCostcenter = {
    id: id || null,
    createdAt: createdAt || null,
    updatedAt: updatedAt || null,
    number: number || null,
    clientIds: (clients && clients.map(({ id: clientId }) => clientId)) || client_ids || [],
    userIds: (users && users.map(({ id: userId }) => userId)) || user_ids || [],
    isNew,
    isModified,
  };

  return baseCostcenter;
};
class ClientsUtil {
  static filterKeys = ['id', 'createdAt', 'updatedAt', 'companyId', 'locationId', 'costcenterId', 'noTax'];

  static apiFieldKeys = {
    companyId: 'company.id',
    costcenterId: 'costcenter_rel.id',
    locationId: 'location.id',
    noTax: 'no_tax',
  };

  /**
   * Creates a hash over properties that have to be unique for a client
   * @param {Object} client the client
   * @returns {String} the hash
   */
  static getClientHash(client) {
    return hash({
      clientNumber: client.clientNumber,
      carrierNumber: client.carrierNumber,
      costCenterNumber: client.costCenterNumber,
    });
  }

  /**
   * Returns an empty client, initialized with data provided
   * @param {Object} initialData data to initialize
   * @reutrn {Object} client
   */
  static getEmptyClient(initialData = {}) {
    const strippedInitData = pick(initialData, ClientsUtil.filterKeys);
    return {
      costcenterId: '',
      locationId: '',
      companyId: '',
      noTax: false,
      debitorId: null,
      address: {},
      ...strippedInitData,
    };
  }

  /**
   * Returns an empty clientGroup, initialized with data provided
   * @param {Object} initialData data to initialize
   * @reutrn {Object} clientGroup
   */
  static getEmptyClientGroup(initialData = {}) {
    const strippedInitData = pick(initialData, ClientsUtil.filterKeys);
    return {
      debitorId: null,
      isGroup: true,
      ...strippedInitData,
    };
  }

  /**
   * only used in dashboard
   * @param {} clientModels clients
   * @returns options
   */
  static getClientSelectOptions(clientModels) {
    const clientNumbers = [];
    const clientOptions = [];
    const carrierNumbers = [];
    const carrierOptions = [];
    const clientIdOptions = [];

    clientModels.forEach((clientModel) => {
      const { clientNumber, carrierNumber, clientDescription, carrierDescription, costCenterNumber, id } = clientModel;
      if (!clientNumbers.includes(clientNumber)) {
        clientNumbers.push(clientNumber);
        const clientDescriptionParsed = clientDescription.split(' (')[0];
        clientOptions.push({
          value: clientNumber,
          label: `${clientNumber} – ${clientDescriptionParsed}`,
        });
      }
      if (!carrierNumbers.includes(carrierNumber)) {
        carrierNumbers.push(carrierNumber);
        // remove number in description
        const matches = carrierDescription.match(/^([\d]+)?\s*(.*)/);
        let carrierDescriptionParsed;
        if (matches && matches.length >= 3) {
          // eslint-disable-next-line prefer-destructuring
          carrierDescriptionParsed = matches[2];
        } else {
          carrierDescriptionParsed = carrierDescription;
        }
        carrierOptions.push({
          value: carrierNumber,
          label: `${carrierNumber} – ${carrierDescriptionParsed}`,
        });
      }
      clientIdOptions.push({
        value: id,
        label: `${clientDescription} (${carrierNumber}), Kst. ${costCenterNumber}`,
      });
    });

    return {
      clientNumbersSelectOptions: AppUtil.sortDataObjects(clientOptions, 'value', 'asc'),
      carrierNumbersSelectOptions: AppUtil.sortDataObjects(carrierOptions, 'value', 'asc'),
      clientSelectOptions: AppUtil.sortDataObjects(clientIdOptions, 'text', 'asc'),
    };
  }

  static parseData(clientData, options = {}) {
    const { additionalData, oldClient } = options;

    const {
      id,
      createdAt,
      updatedAt,
      company,
      location,
      costcenter_rel,
      no_tax,
      debitor_id,
      is_group,
      client_group,
      address,
      clients,
      client_ids,
    } = clientData || {};

    const baseClient = {
      id: id || null,
      createdAt: createdAt || null,
      updatedAt: updatedAt || null,
      companyId: (company && (typeof company !== 'object' || typeof client_group === 'string' ? company : company.id)) || null,
      costcenterId: AppUtil.getEntryId(costcenter_rel),
      locationId: AppUtil.getEntryId(location),
      noTax: no_tax !== undefined ? no_tax : null,
      debitorId: debitor_id !== undefined ? debitor_id : null,
      addressId: AppUtil.getEntryId(address),
      isGroup: is_group || false,
      clientIds: (clients && clients.map((c) => c?.id)) || client_ids || [],
      clientGroupId: AppUtil.getEntryId(client_group),
    };

    if (company && typeof company === 'object') {
      baseClient.company = CompaniesUtil.parseData(company);
    }
    if (location && typeof location === 'object') {
      baseClient.location = LocationsUtil.parseData(location);
    }
    if (costcenter_rel && typeof costcenter_rel === 'object') {
      baseClient.costcenter = parseCostcenterData(costcenter_rel);
    }
    if (client_group && typeof client_group === 'object') {
      baseClient.clientGroup = this.parseData(client_group);
    }

    if (clients) {
      const clientsArr = [];
      clients.forEach((client) => {
        if (client && typeof client === 'object') {
          clientsArr.push(this.parseData(client));
        }
      });
      if (clientsArr.length) {
        baseClient.clients = clientsArr;
      }
    }

    let oldProps = {};
    if (oldClient) {
      oldProps = AppUtil.getOldProps(baseClient, oldClient);
    }

    return {
      ...baseClient,
      ...oldProps,
      ...additionalData,
    };
  }

  /**
   * Returns data object for a client to be used in API requests
   * @param {Object} client the client Object
   * @returns {Object} the data
   */
  static getAPIData(client) {
    const {
      companyId,
      locationId,
      costcenterId,
      noTax,
      debitorId,
      address,
      addressId,
      isGroup,
      clientGroupId,
      clientIds,
    } = client;

    const data = {
      company: companyId,
      location: locationId,
      no_tax: noTax,
      costcenter_rel: costcenterId || null,
      debitor_id: debitorId || null,
    };

    if (isGroup) {
      data.is_group = true;
      data.clients = clientIds || [];
    } else {
      data.is_group = false;
      data.client_group = clientGroupId;

      if (address) {
        // keep address.number in sync with debitorId
        data.address = AddressUtil.getAPIData({ ...address, number: debitorId });
      } else if (addressId) {
        data.address = addressId;
      }
    }
    return data;
  }

  // TODO handle in backend
  // static baseViewClientsPopulate = ['company', 'location', 'costcenter_rel'];

  static baseViewClientsPopulate = {
    company: {
      fields: ['id', 'number', 'name'],
    },
    location: {
      fields: ['id', 'number', 'description'],
    },
    costcenter_rel: {
      fields: ['id', 'number'],
    },
    client_group: {
      fields: ['id', 'debitor_id'],
    },
    address: '*',
  };

  static baseViewClientGroupsPopulate = {
    clients: {
      populate: {
        company: {
          fields: ['id', 'number', 'name'],
        },
        location: {
          fields: ['id', 'number', 'description'],
        },
        costcenter_rel: {
          fields: ['id', 'number'],
        },
      },
    },
  };

  static getClientGroupSearchParams = (inputValue, options) => {
    let filters;
    if (inputValue) {
      const queryFilter = {
        $or: [
          {
            debitor_id: {
              $containsi: inputValue,
            },
          },
          {
            location: {
              description: {
                $containsi: inputValue,
              },
            },
          },
          {
            location: {
              number: {
                $containsi: inputValue,
              },
            },
          },
          {
            company: {
              number: {
                $containsi: inputValue,
              },
            },
          },
          {
            company: {
              name: {
                $containsi: inputValue,
              },
            },
          },
          {
            costcenter_rel: {
              number: {
                $containsi: inputValue,
              },
            },
          },
        ],
      };
      if (options?.isGroup !== undefined) {
        const groupFilter = options.isGroup
          ? { is_group: true }
          : {
              $or: [
                {
                  is_group: { $ne: true },
                },
                {
                  is_group: { $null: true },
                },
              ],
            };
        filters = {
          $and: [groupFilter, queryFilter],
        };
      } else {
        filters = queryFilter;
      }
    } else {
      filters = {};
    }
    return {
      filters,
      populate: options?.populate || ['costcenter_rel', 'location', 'company'],
    };
  };
}

export default ClientsUtil;
