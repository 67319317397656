import { createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { companySchema } from '../schemas';
import { getCompleteEntities } from '../schemaUtils';
import { RootState } from '../store';

export const fetchCompanies = (params?: any, withCount?: boolean): RequestEntitiesAction<CompanyData[]> =>
  requestEntities({
    method: 'GET',
    path: '/companies',
    schema: [companySchema],
    params,
    type: 'fetchCompanies',
    withCount,
  });

export const fetchCompany = (companyId: number, params?: any): RequestEntitiesAction<CompanyData> =>
  requestEntities({
    method: 'GET',
    path: `/companies/${companyId}`,
    schema: companySchema,
    params,
  });

export const updateCompany = (
  companyId: number,
  data: CompanyUpdateData,
  params?: any
): RequestEntitiesAction<CompanyData> =>
  requestEntities({
    method: 'PUT',
    path: `/companies/${companyId}`,
    data,
    schema: companySchema,
    params,
  });

export const updateCompanyContacts = (
  companyId: number,
  data: CompanyUpdateContactsData,
  params?: any
): RequestEntitiesAction<CompanyData> =>
  requestEntities({
    method: 'PUT',
    path: `/companies/${companyId}/contacts`,
    data,
    schema: companySchema,
    params,
  });
export interface CompaniesState extends EntityState<CompanyEntity> {
  fetchCompaniesStatus: RequestStatus;
}

const companiesAdapter = createEntityAdapter<CompanyEntity>();

const initialState: CompaniesState = companiesAdapter.getInitialState({
  fetchCompaniesStatus: 'idle',
});

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchCompanies') {
          state.fetchCompaniesStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.meta.arg.type === 'fetchCompanies') {
          state.fetchCompaniesStatus = 'idle';
        }
        if (action.payload.entities?.companies) {
          const completeEntities = getCompleteEntities(action.payload.entities.companies);
          if (Object.keys(completeEntities).length > 0) {
            companiesAdapter.upsertMany(state, completeEntities);
          }
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchCompanies') {
          state.fetchCompaniesStatus = 'failed';
        }
      });
  },
});

export const selectCompaniesState = (state: RootState): CompaniesState => state.entitiesState.companies;
export default companiesSlice.reducer;
