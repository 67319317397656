import React from 'react';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import styled, { CSSObject } from 'styled-components';
import { Block, InlineSVG } from '@webfox-sc/core';
import IconCalendar from '../../assets/icons-v2/IconCalendar';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('de', de);

type CustomDatePickerVariant = 'outline';

interface StyledDatePickerProps {
  variant?: CustomDatePickerVariant;
}

const StyledDatePicker = styled.div<StyledDatePickerProps>(({ variant, theme }) => {
  let inputStyle: CSSObject = {
    border: `1px solid ${theme.colors.ciBlue1}`,
    background: 'transparent',
    padding: '14px 42px 14px 10px',
    width: '100%',
    height: '50px',
    boxSizing: 'border-box',
    transition: 'background-color 0.1s ease',
    ...theme.type.copy,
    color: theme.colors.ciBlue1,
    '&::placeholder': {
      fontStyle: 'italic',
      color: theme.colors.grey40,
    },
    '&:hover': {
      background: theme.colors.ciBlue2,
    },
    '&:focus': {
      outline: 0,
    },
  };

  if (variant === 'outline') {
    inputStyle = {
      ...inputStyle,
      borderColor: 'white',
      background: 'transparent',
      color: 'white',
      '&::placeholder': {
        fontStyle: 'italic',
        color: 'rgba(255,255,255,0.5)',
      },
      '&:hover': {
        background: theme.colors.grey20,
      },
    };
  }

  return {
    position: 'relative',
    zIndex: 0,
    background: variant === 'outline' ? 'transparent' : 'white',
    '& input': {
      ...inputStyle,
    },
    // fix for popper warning caused by popper margin style
    '& .react-datepicker-popper': {
      margin: 0,
    },
    '& .react-datepicker-wrapper': {
      width: '100%'
    }
  };
});

interface CustomDatePickerProps extends ReactDatePickerProps<never, boolean> {
  variant?: CustomDatePickerVariant;
  interval?: ScheduleInterval;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ variant, locale, interval, ...props }) => {
  const computedProps: any = {};

  if (interval === 'weekly') {
    computedProps.filterDate = (date) => date && date.getDay() === 0;
    // computedProps.dateFormat = 'QQQ yyyy';
  } else if (interval === 'monthly') {
    computedProps.showMonthYearPicker = true;
    computedProps.dateFormat = 'MM/yyyy';
  } else if (interval === 'quarterly') {
    computedProps.showQuarterYearPicker = true;
    computedProps.dateFormat = 'QQQ yyyy';
  } else if (interval === 'semiAnnually') {
    computedProps.filterDate = (date) => date && [3, 9].includes(date.getMonth());
    computedProps.showQuarterYearPicker = true;
    computedProps.dateFormat = 'QQQ yyyy';
  } else if (interval === 'annually') {
    computedProps.showYearPicker = true;
    computedProps.dateFormat = 'yyyy';
  }

  return (
    <StyledDatePicker variant={variant}>
      <Block position="relative" zIndex={2}>
        <DatePicker locale={locale || 'de'} {...props} {...computedProps} />
      </Block>
      <Block position="absolute" zIndex={1} top="18px" right="17px">
        <InlineSVG width="14px" color={variant === 'outline' ? 'white' : undefined}>
          {IconCalendar}
        </InlineSVG>
      </Block>
    </StyledDatePicker>
  );
};

export default CustomDatePicker;
