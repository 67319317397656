import { REDUX_ACTIONS } from '../global/constants';

function companiesReducer(
    state = {
        isCompaniesRequest: false,
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_COMPANIES:
            return { ...state, isCompaniesRequest: true };

        case REDUX_ACTIONS.REQUEST_COMPANIES_ERROR:
            return { ...state, isCompaniesRequest: false };

        case REDUX_ACTIONS.RECEIVE_COMPANIES:
            return { ...state, isCompaniesRequest: false };

        default:
            return state;
    }
}

export default companiesReducer;
