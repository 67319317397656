import React from 'react';
import { UiDiv, UiDivProps } from '@webfox-sc/core';

const FormRowGroup: React.FC<UiDivProps> = ({ children, ...props }) => {
  return (
    <UiDiv display="flex" flexDirection="column" rowGap="15px" {...props}>
      {children}
    </UiDiv>
  );
};

export default FormRowGroup;
