const IconPicture = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M8.12.85H91.88a8.06,8.06,0,0,1,8.12,8V60.92L69.4,30.84a3.62,3.62,0,0,0-5.05,0l-23.7,23.3L26,42a3.61,3.61,0,0,0-4.83.2L0,62.92V8.83A8.06,8.06,0,0,1,8.12.85Zm83.76,98.3a8.06,8.06,0,0,0,8.12-8V70.85L66.87,38.29,46.13,58.68l9.36,7.75a3.47,3.47,0,0,1,.43,4.95,3.62,3.62,0,0,1-5,.42L38.61,61.63h0L23.85,49.4,0,72.84V91.17a8.06,8.06,0,0,0,8.12,8ZM36.43,29.45A8.42,8.42,0,1,0,27.87,21,8.49,8.49,0,0,0,36.43,29.45Z"
    />
  </svg>
);

export default IconPicture;
