/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './form-label.scss';

class FormLabel extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { align, text, large } = this.props;

        let className = 'smg-form-label';

        if (align === 'right') {
            className += ' smg-form-label--align-right ';
        }
        if (large) {
            className += ' smg-form-label--large';
        }

        return (
            <div className={className}>
                <span dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        );
    }
}

FormLabel.propTypes = {
    /**
     * @prop align
     * @type string
     * @values 'right'
     */
    align: PropTypes.string,
    text: PropTypes.string,
    large: PropTypes.bool,
};

FormLabel.defaultProps = {
    align: '',
    text: '',
};

export default FormLabel;
