/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function clientsReducer(
    state = {
        isLoadingClients: false,
        clientNumbersSelectOptions: [],
        carrierNumbersSelectOptions: [],
        clientSelectOptions: [],
    },
    action
) {
    switch (action.type) {

        case REDUX_ACTIONS.REQUEST_CLIENTS:
            return Object.assign({}, state, {
                isLoadingClients: true
            });

        case REDUX_ACTIONS.REQUEST_CLIENTS_ERROR:
            return Object.assign({}, state, {
                isLoadingClients: false
            });

        case REDUX_ACTIONS.RECEIVE_CLIENTS:
            return Object.assign({}, state, {
                isLoadingClients: false
            });

        default:
            return state;
    }
}

export default clientsReducer;
