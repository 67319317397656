/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { pick, orderBy, pickBy, isEqual } from 'lodash';
import { useBaseDataFilterContext } from './baseDataFilter';
import { useToastContext } from '../components/toast/ToastProvider';

export const BaseDataViewContext = createContext();

export const BaseDataViewProvider = ({
  objectPath,
  getNewObject,
  dataLoaderProps,
  useFilter,
  deleteObjects,
  dataLoader,
  children,
}) => {
  const { showToast } = useToastContext();
  const { dataFilter, tableFilter } = useBaseDataFilterContext();

  const [showAdd, setShowAdd] = useState(false);
  const [newObject, setNewObject] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [selection, setSelection] = useState({});

  // TODO remove
  const [newObjects, onUpdateNew] = useState({});

  const { getAPIFilter, filterObjects, uniqueKeys } = useFilter();

  const {
    objectsData,
    totalCount,
    fetchMore,
    fetchFiltered,
    resetFilter,
    addObjects,
    removeObjects,
    sortingKey,
    setSortingKey,
    sortingOrder,
    setSortingOrder,
    isLoading,
  } = dataLoader({ getAPIFilter, ...dataLoaderProps });

  const handleCheckCanUpdate = (model) => {
    if (uniqueKeys && uniqueKeys.length > 0) {
      const modelUniqe = pick(model, uniqueKeys);
      const duplicates = pickBy(objectsData, (object) => isEqual(pick(object, uniqueKeys), modelUniqe));
      const duplicateKeys = Object.keys(duplicates);
      if (duplicateKeys.length > 1) {
        // more than one object with same value(s)
        return false;
      }
      if (duplicateKeys.length > 0) {
        // more than one object with same, check if it is not current object
        if (duplicateKeys[0] !== `${model.id}`) {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnAdd = () => {
    const obj = getNewObject(dataFilter);
    setNewObject(obj);
    setShowAdd(true);
    setActiveId(false);
    return obj;
  };

  const handleOnCreate = async (args) => {
    await addObjects(args);
    setNewObject(null);
  };

  const handleOnCancel = () => {
    setShowAdd(false);
    setActiveId(false);
  };

  const handleOnSelect = (value, id) => {
    const newSelection = { ...selection };
    if (value) {
      newSelection[id] = true;
    } else if (newSelection[id]) {
      delete newSelection[id];
    }
    setSelection(newSelection);
  };

  // fetch data whenever the filter changes
  useEffect(() => {
    if (dataFilter) {
      fetchFiltered(dataFilter);
    } else {
      resetFilter();
    }
  }, [dataFilter]);

  const handleOnClickSorting = (key) => {
    let order = 'asc';
    if (key === sortingKey && sortingOrder === 'asc') {
      order = 'desc';
    }
    setSortingOrder(order);
    setSortingKey(key);
    setActiveId(null);
  };

  const handleOnClickTableBodyRow = (id) => {
    setActiveId(activeId === id ? null : id);
    setShowAdd(false);
  };

  const newObjectsKeys = useMemo(() => Object.keys(newObjects || {}), [newObjects]);

  const handleBulkItemCancel = useCallback(
    (uuid) => {
      if (!newObjectsKeys.length) {
        onUpdateNew(null);
      } else {
        const updatedNewObjects = { ...newObjects };
        delete updatedNewObjects[uuid];
        onUpdateNew({ ...updatedNewObjects });
      }
    },
    [newObjects]
  );

  const { objectsFiltered, entryCounts } = filterObjects(
    Object.values(objectsData),
    { ...dataFilter, ...tableFilter } || {}
  );

  const objectsSorted = orderBy(objectsFiltered, ['isNew', 'isModified', sortingKey], ['asc', 'asc', sortingOrder]);
  const displayedObjectIds = objectsSorted.map(({ id }) => id);

  const handleOnDeleteAll = async () => {
    if (window.confirm('Wollen Sie die ausgewählten Einträge wirklich löschen?')) {
      const ids = Object.keys(selection);
      if (ids.length > 0) {
        const removedObjects = await deleteObjects(ids);
        removeObjects(removedObjects);
        setSelection({});
        showToast('Die ausgewählten Einträge wurden gelöscht.', 'success');
      }
    }
  };

  if (entryCounts) {
    entryCounts.invalid = newObjectsKeys.length;
  }

  // only show status filter if there are entries in a status different from "original"
  const showStatusFilter = entryCounts && !!(entryCounts.new || entryCounts.editedNotNew || entryCounts.invalid);

  // display all newObjects depending on filter
  const showNew = tableFilter && tableFilter.status && tableFilter.status.findIndex((v) => v === 'invalid') >= 0;

  const countDisplayed = displayedObjectIds ? displayedObjectIds.length : 0;
  const displayShowMore = countDisplayed < totalCount;

  return (
    <BaseDataViewContext.Provider
      value={{
        objectIds: displayedObjectIds,
        countTotal: totalCount,
        countDisplayed,
        displayShowMore,
        handleShowMore: dataFilter ? () => fetchFiltered(dataFilter, true) : fetchMore,
        handleOnAdd,
        showAdd,
        setShowAdd,
        newObject,
        handleOnCancel,
        showStatusFilter,
        entryCounts,
        showNew,
        newObjectsKeys,
        handleOnClickSorting,
        sortingKey,
        sortingOrder,
        objectPath,
        activeId,
        setActiveId,
        handleBulkItemCancel,
        handleOnClickTableBodyRow,
        handleCheckCanUpdate,
        handleOnCreate,
        handleOnUpdate: () => {},
        handleOnDelete: removeObjects,
        handleOnDeleteAll,
        selection,
        handleOnSelect,
        isLoading,
      }}
    >
      {children}
    </BaseDataViewContext.Provider>
  );
};

BaseDataViewProvider.propTypes = {
  objectPath: PropTypes.string.isRequired,
  getNewObject: PropTypes.func,
  dataLoaderProps: PropTypes.object,
  dataLoader: PropTypes.func,
  // defaultSortingKey: PropTypes.string,
  // defaultSortingOrder: PropTypes.string,
  useFilter: PropTypes.func,
  // fetchObjects: PropTypes.func,
  // countObjects: PropTypes.func,
  deleteObjects: PropTypes.func,
  children: PropTypes.node,
};

BaseDataViewProvider.defaultProps = {
  getNewObject: () => {},
  dataLoaderProps: {},
  dataLoader: () => {},
  useFilter: () => {},
  fetchObjects: () => {},
  countObjects: () => {},
  deleteObjects: () => {},
  children: null,
};

export const useBaseDataViewContext = () => {
  return useContext(BaseDataViewContext);
};
