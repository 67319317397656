import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface QMReportMetaInfoProps {
  children?: React.ReactNode;
  companyName?: string;
  craftName?: string;
  locationDescription?: string;
  costcenterNumber?: string;
  contacts?: ContactEntity[];
  reporter?: UserEntity;
  dateFormatted?: string;
  dueDateFormatted?: string;
}

const QMReportMetaInfo: React.FC<QMReportMetaInfoProps> = ({
  children,
  companyName,
  craftName,
  locationDescription,
  costcenterNumber,
  contacts,
  reporter,
  dateFormatted,
  dueDateFormatted,
}) => {
  const { t } = useTranslation();
  return (
    <UiDiv display={{ tablet: 'flex' }} gap="1px">
      <UiDiv background="grey20" color="ciBlue1" flexGrow={1} padding="s">
        <UiDiv display={{ tablet: 'flex' }} gap="m">
          <UiDiv flexBasis="20%" marginBottom={{ mobile: 's', tablet: 0 }}>
            <b>{t('common.entities.company.singular')}</b>
            <UiDiv>{companyName}</UiDiv>
          </UiDiv>
          <UiDiv flexGrow={1} marginBottom={{ mobile: 's', tablet: 0 }}>
            <b>{t('common.entities.location.singular')}</b>
            <UiDiv>{locationDescription}</UiDiv>
          </UiDiv>
          <UiDiv>
            <b>{t('common.entities.costcenter.singular')}</b>
            <UiDiv>{costcenterNumber}</UiDiv>
          </UiDiv>
        </UiDiv>
        <UiDiv display={{ tablet: 'flex' }} gap="m">
          {contacts && contacts?.length > 0 && (
            <UiDiv rowGap="s" marginTop="s">
              <b>{t('common.fields.contacts')}</b>
              <UiDiv>
                {contacts.map(({ name, email, id }) => (
                  <UiDiv key={`contact_${id}`}>
                    {name} ({email})
                  </UiDiv>
                ))}
              </UiDiv>
            </UiDiv>
          )}
          <UiDiv rowGap="s" marginTop="s">
            <b>{t('common.fields.reporter')} </b>
            <UiDiv>
              {reporter?.username} ({reporter?.email})
            </UiDiv>
          </UiDiv>
          <UiDiv rowGap="s" marginTop="s">
            <b>{t('common.fields.craft')}</b>
            <UiDiv>
              {craftName}
            </UiDiv>
          </UiDiv>
        </UiDiv>
        {children}
      </UiDiv>
      {dateFormatted && (
        <UiDiv background="ciBlue5" color="ciBlue1" padding="s">
          <b>{t('common.fields.date')}</b>
          <UiDiv>{dateFormatted}</UiDiv>
        </UiDiv>
      )}
      {dueDateFormatted && (
        <UiDiv background="ciBlue5" color="ciBlue1" padding="s">
          <b>{t('report.dueDate')}</b>
          <UiDiv>{dueDateFormatted}</UiDiv>
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default QMReportMetaInfo;
