/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './auth-overlay.scss';

class AuthOverlay extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { children } = this.props;

        return (
            <div className="auth-overlay">
                <div className="auth-overlay__dialog">{children}</div>
            </div>
        );
    }
}

AuthOverlay.propTypes = {
    /**
     * @prop children
     * @type object || array || boolean
     */
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool])
};

AuthOverlay.defaultProps = {
    children: null
};

export default AuthOverlay;
