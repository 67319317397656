import React, { useCallback } from 'react';
import { useAppDispatch } from '../../../app/appHooks';
import { useClient } from '../../../app/entityHooks/useClient';
import { updateClientContacts } from '../../../app/slices/clientsSlice';
import QMUtil from '../../../utils/QMUtil';
import QMAdminContactsForm from '../qm-admin-company/QMAdminContactsForm';

interface QMAdminClientContactsProps {
  clientId: number | undefined;
}

const QMAdminClientContacts: React.FC<QMAdminClientContactsProps> = ({ clientId }) => {
  const dispatch = useAppDispatch();
  const { client } = useClient(clientId);

  const handleOnAsyncSave = useCallback(
    async (contactIds: number[]) => {
      if (clientId) {
        await dispatch(
          updateClientContacts(
            clientId,
            contactIds,
            {
              populate: QMUtil.clientPopulateOptions,
            }
          )
        );
      }
    },
    [clientId, dispatch]
  );

  if (!client) {
    return null;
  }

  return <QMAdminContactsForm contactIds={client.contactIds} onAsyncSave={handleOnAsyncSave} />;
};

export default React.memo(QMAdminClientContacts);
