import { UiDiv, UiDivProps } from '@webfox-sc/core';
import React from 'react';

import { useTheme } from 'styled-components';

const PageContent: React.FC<UiDivProps> = ({ children, ...props }) => {
  const { page } = useTheme();
  return (
    <UiDiv maxWidth={page.contentMaxWidth} padding="0 3%" margin="0 auto" {...props}>
      {children}
    </UiDiv>
  );
};

export default PageContent;
