import React from 'react';
import styled from 'styled-components';

type TextLinkVariant = 'white';

interface StyledTextLinkProps {
  variant?: TextLinkVariant;
}

const StyledLink = styled.a<StyledTextLinkProps>(({ variant, theme }) => {
  return {
    display: 'inline-flex',
    gap: '10px',
    alignItems: 'center',
    ...theme.type.copy,
    color: variant === 'white' ? 'white' : theme.colors.ciRed,
    textDecoration: variant === 'white' ? 'underline' : 'none',
    cursor: 'pointer',
    '&:hover': {
      color: variant === 'white' ? 'white' : theme.colors.ciRed,
      textDecoration: variant === 'white' ? 'none !important' : 'underline !important',
    },
    '&:active': {
      color: theme.colors.ciBlue4,
      textDecoration: variant === 'white' ? 'underline !important' : 'none !important',
    },
    svg: {
      width: '12px',
      height: '12px',
      verticalAlign: 'top',
      textDecoration: 'none',
    },
  };
});

interface TextLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: TextLinkVariant;
  icon?: React.ReactNode;
}

const TextLink: React.FC<TextLinkProps> = ({ variant, icon, children, ...props }) => {
  return (
    <StyledLink variant={variant} {...props}>
      {icon}
      {children}
    </StyledLink>
  );
};

export default TextLink;
