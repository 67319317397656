import { REDUX_ACTIONS } from '../global/constants';

const initialState = {
  currentJobListView: '',
  jobListJobIds: [],
  costCenterFilter: '',
  clientFilter: '',
  statusFilter: '',
  searchFilter: '',
  sortingKey: 'createdAt',
  sortingOrder: 'desc',
  jobModels: [],
  isJobsRequest: false,
  isJobsCountRequest: false,
};

function jobsReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case REDUX_ACTIONS.UPDATE_JOB_MODELS:
      return { ...state, jobModels: action.jobModels };

    case REDUX_ACTIONS.UPDATE_IS_LOADING_JOBS:
      return { ...state, isJobsRequest: action.isJobsRequest };

    case REDUX_ACTIONS.REQUEST_JOBS:
      return { ...state, isJobsRequest: true };

    case REDUX_ACTIONS.REQUEST_JOBS_ERROR:
    case REDUX_ACTIONS.RECIEVE_JOBS:
      return { ...state, isJobsRequest: false };

    case REDUX_ACTIONS.REQUEST_JOBS_COUNT:
      return { ...state, isJobsCountRequest: true };

    case REDUX_ACTIONS.REQUEST_JOBS_COUNT_ERROR:
    case REDUX_ACTIONS.RECIEVE_JOBS_COUNT:
      return { ...state, isJobsCountRequest: false };

    case REDUX_ACTIONS.SET_JOBS_STATE:
      return { ...state, ...action.state };

    default:
      return state;
  }
}

export default jobsReducer;
