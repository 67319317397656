/* eslint-disable */
import { UiDiv } from '@webfox-sc/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import UploadButtonSecondary from '../../../atoms/UploadButtonSecondary';
import { defaultEncoding, encodingOptions, schemaOptions } from '../../../utils/importUtils';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import CustomReactSelect from '../../CustomReactSelect';
import CheckboxLegacy from '../../../atoms/CheckboxLegacy/CheckboxLegacy';

const CSVUploader = ({ onUpload }) => {
  const { t } = useTranslation();
  const [encoding, setSelectedEncoding] = useState(defaultEncoding);
  const [schema, setSelectedSchema] = useState(null);
  const [autoImport, setAutoImport] = useState(false);

  const handleChangeFileUpload = (e) => {
    const { files: inputFiles } = e.target;
    if (inputFiles.length > 0) {
      onUpload({ files: inputFiles, encoding, schema, auto_import: autoImport });
    }
    e.target.value = null;
  };

  return (
    <UiDiv display="grid" gridTemplateColumns="repeat(5, 1fr)" gap="xs" width="100%" alignItems="right" justifyContent="flex-end">
      {/* <UiDiv gridColumn={{ mobile: '1 / 5', tablet: '2 / 2'}}> */}
        {/* <FormElementLabel color="smgLightBlue">Automatischer Import</FormElementLabel>
        <CheckboxLegacy
          size="L"
          checked={!!autoImport}
          onChange={(value) => setAutoImport(value)}
        /> */}
      {/* </UiDiv> */}
      <UiDiv gridColumn={{ mobile: '1 / 5', tablet: '3 / 3'}}>
        <FormElementLabel color="ciBlue4">{t('common.import.encoding')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          options={encodingOptions}
          selectedValue={encoding}
          onChangeValue={setSelectedEncoding}
          
        />
      </UiDiv>
      <UiDiv gridColumn={{ mobile: '1 / 5', tablet: '4 / 4'}}>
        <FormElementLabel color="ciBlue4">{t('common.import.schema')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          options={schemaOptions}
          selectedValue={schema}
          onChangeValue={setSelectedSchema}
        />
      </UiDiv>
      <UiDiv gridColumn={{ mobile: '1 / 5', tablet: '5 / 5'}} alignSelf="end">
        <UploadButtonSecondary
          label="CSV-Datei hochladen"
          icon={IconPlus}
          accept=".csv, text/csv"
          disabled={!schema}
          // multiple
          onChange={handleChangeFileUpload}
        />
      </UiDiv>
    </UiDiv>
  );
};

CSVUploader.propTypes = {
  /**
   * Class name of wrapping div
   * @prop className
   * @type string
   * @values ''
   */
  className: PropTypes.string,
  /**
   * @prop onUpload
   * @type function (callback)
   */
  onUpload: PropTypes.func,
};

CSVUploader.defaultProps = {
  className: '',
  onUpload: () => null,
};

export default CSVUploader;
