/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';
import { getAPIParams } from '../utils/APIParamsUtil';

export const useClientsActions = () => {
  const dispatch = useDispatch();
  const { jwt } = useSelector(({ authState }) => authState);

  const fetchClientsLegacy = useCallback(async (params = { _limit: -1 }, path = 'clients') => {
    dispatch({
      type: REDUX_ACTIONS.REQUEST_CLIENTS,
    });

    const { paramString } = getAPIParams(params, true);
    let data;
    try {
      ({ data } = await axios({
        method: 'get',
        url: `${RestUtil.getStrapiUrl()}/${path}${paramString}`,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Cache-Control': 'no-store',
          'If-Modified-Since': '0',
        },
      }));

      const objects = data?.data;
      const count = data?.pagination?.total || 0;
      await dispatch({
        type: REDUX_ACTIONS.RECEIVE_CLIENTS,
        clients: objects,
      });
      if (params && params._limit === -1 && objects) {
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_COUNT_CLIENTS,
          count,
        });
      }

      return { objects, count };
    } catch (error) {
      dispatch({
        type: REDUX_ACTIONS.REQUEST_CLIENTS_ERROR,
      });
      return { objects: [], count: 0};
    }
  }, []);

  return {
    fetchClientsLegacy,
    fetchClientGroups: (params = { _limit: -1 }) => fetchClientsLegacy(params, 'clientgroups'),
  };
};
