import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useJob } from '../../app/entityHooks/useJob';
import { useOffer } from '../../app/entityHooks/useOffer';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconArrowUp from '../../assets/icons-v2/IconArrowUp';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconDownload from '../../assets/icons-v2/IconDownload';
import IconPlus from '../../assets/icons-v2/IconPlus';
import IconAttachment from '../../assets/icons/IconAttachment';
import DateUtil from '../../utils/DateUtil';
import Button from '../atoms-v2/Button';

import { REJECT_OFFERS } from '../../app.config';
import { useAppDispatch } from '../../app/appHooks';
import { useUser } from '../../app/entityHooks/useUser';
import { rejectPublicOffers } from '../../app/slices/offersSlice';
import RestUtil from '../../utils/RestUtil';
import LoadingWrapper from '../atoms-v2/LoadingWrapper';
import { useAuth } from '../auth/useAuth';
import { usePermissions } from '../auth/usePermissions';
import PageContentSubHeading from '../page/PageContentSubHeading';
import JobFiles from './JobFiles';
import JobHistoryList from './JobHistoryList';
import JobOfferEditor from './JobOfferEditor';
import LandingOfferReject from './JobOfferReject';
import { useJobHistories } from './useJobHistories';
import { useJobOffer } from './useJobOffer';

interface JobOfferProps {
  jobId: number | undefined;
  onOfferCreated?: () => void;
  onOfferInstantRejected?: () => void;
  onClickSend?: (setOfferPendingStatus: boolean) => void;
}

const JobOffer: React.FC<JobOfferProps> = ({ jobId, onOfferCreated, onOfferInstantRejected, onClickSend }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { currentUser } = useAuth();
  const { user } = useUser(currentUser?.id);
  const { canCreateJob } = usePermissions();
  const dispatch = useAppDispatch();
  const userEmail = user?.email;

  const { job, recipient: jobRecipient } = useJob(jobId);
  const { offer, recipient: offerRecipient, files, pdfFile } = useOffer(job?.offerId);
  const { offerHistoriesSorted } = useJobHistories(jobId);

  const { createOfferStatus, handleCreateOffer, handleCreateOfferPDF, handleUploadOfferFiles, handleDeleteOfferFile } =
    useJobOffer(job?.id);

  const [showDetails, setShowDetails] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [showRejection, setShowRejection] = useState(false);

  const [orderNumber, setOrderNumber] = useState<string | undefined>('');
  const [location, setLocation] = useState<string | undefined>('');
  const [comments, setComments] = useState<string | undefined>('');

  useEffect(() => {
    if (offer) {
      setOrderNumber(offer.orderNumber || '');
      setLocation(offer.location || '');
      setComments(offer.comments || '');
    } else if (jobRecipient) {
      setLocation(jobRecipient.carrierDescription || '');
    }
  }, [offer, jobRecipient]);

  const handleOnChangeInput = (key: string, value: any) => {
    if (key === 'orderNumber') {
      setOrderNumber(value);
    } else if (key === 'location') {
      setLocation(value);
    } else if (key === 'comments') {
      setComments(value);
    }
  };

  const handleOnClickCreate = async () => {
    setIsCreateMode(false);
    setShowDetails(true);
    setShowHistory(false);
    await handleCreateOffer(orderNumber, location, comments);
    if (onOfferCreated) {
      onOfferCreated();
    }
  };

  let statusTitle = '';
  let statusTitleColor = colors.ciBlue3;
  let statusText = '';
  if (isCreateMode) {
    statusTitle = t('job.documentForm.headline.create');
  } else if (offer) {
    switch (offer.confirmed) {
      case 'INIT':
        statusTitle = t('job.documentForm.headline.editing');
        statusTitleColor = colors.grey40;

        statusText =
          (offer &&
            t('common.messages.createdAt', {
              date: DateUtil.getDateTimeFormattedExtended(offer.createdAt),
            })) ||
          '';
        break;
      case 'DEFAULT':
      case 'CONFIRMATION_PENDING':
      case 'REJECTION_PENDING':
        statusTitle = t('job.documentForm.headline.pending');
        statusTitleColor = colors.trafficLightYellow;
        statusText =
          (offer &&
            t('common.messages.sentAt', {
              date: DateUtil.getDateTimeFormattedExtended(offer.createdAt),
            })) ||
          '';
        break;
      case 'BESTAETIGT':
        statusTitle = t('job.documentForm.headline.confirmed');
        statusTitleColor = colors.trafficLightGreenText;
        statusText =
          (offer &&
            t('common.messages.confirmedAt', {
              date: DateUtil.getDateTimeFormattedExtended(offer.createdAt),
            })) ||
          '';
        break;
      case 'ABGELEHNT':
        statusTitle = t('job.documentForm.headline.rejected');
        statusTitleColor = colors.trafficLightRed;
        statusText =
          (offer &&
            t('common.messages.rejectedAt', {
              date: DateUtil.getDateTimeFormattedExtended(offer.createdAt),
            })) ||
          '';
        break;
      default:
        statusTitle = '';
    }
  }

  const handleOnClickReject = async (reason: string) => {
    const formData = new FormData();
    const offers = [
      {
        id: offer?.id,
      },
    ];
    formData.append('data', JSON.stringify({ offers, email: userEmail, reason }));

    try {
      await dispatch(rejectPublicOffers(offer?.id, null, formData));
      setShowRejection(false);
      if (onOfferInstantRejected) {
        onOfferInstantRejected();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const showSendButton = offer?.confirmed === 'INIT';
  const showResendButton = offer?.confirmed === 'DEFAULT';
  const showRejectButton =
    REJECT_OFFERS &&
    !!offer &&
    offer.confirmed &&
    ['DEFAULT', 'CONFIRMATION_PENDING', 'REJECTION_PENDING'].includes(offer.confirmed) &&
    canCreateJob;
  const showPDF = !!offer && !!offerRecipient && job?.offerStatus !== 'A-erstellt';

  const areFilesEditable = canCreateJob && offer?.confirmed !== 'BESTAETIGT' && offer?.confirmed !== 'ABGELEHNT';

  const showFiles = !!offer && (areFilesEditable || files.length > 0);

  return (
    <>
      <PageContentSubHeading>
        {String(`${t('offer.headline.singular')} ${offer?.entryId || ''}`).trim()}
      </PageContentSubHeading>

      <Collapse show={!offer && !isCreateMode && createOfferStatus === 'idle'}>
        <UiDiv display="flex" justifyContent="center" paddingTop="s">
          <Button variant="highlight" icon={IconPlus} onClick={() => setIsCreateMode(true)}>
            {t('common.actions.create')}
          </Button>
        </UiDiv>
      </Collapse>

      <Collapse show={!!offer || isCreateMode} fadeInOut>
        <UiDiv paddingTop="s">
          <LoadingWrapper isLoading={createOfferStatus === 'loading'}>
            <UiDiv background="white" padding="m">
              <UiDiv background="grey20" padding="s">
                <UiText variant="h3" color={statusTitleColor} textAlign="center">
                  {statusTitle}
                </UiText>
                {!!offer && (
                  <>
                    {statusText && (
                      <UiText variant="copy" textAlign="center" marginTop="xs">
                        {statusText}
                      </UiText>
                    )}
                    <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="s">
                      <Button
                        icon={showDetails ? IconArrowUp : IconArrowDown}
                        endIcon={offer.fileIds && offer.fileIds.length > 0 ? IconAttachment : undefined}
                        active={showDetails}
                        onClick={() => {
                          setShowDetails(!showDetails);
                          setShowHistory(false);
                        }}
                      >
                        {t('common.actions.show')}
                      </Button>
                      {offerHistoriesSorted.length > 0 && (
                        <Button
                          icon={showHistory ? IconArrowUp : IconArrowDown}
                          active={showHistory}
                          onClick={() => {
                            setShowDetails(false);
                            setShowHistory(!showHistory);
                          }}
                        >
                          {t('job.history')}
                        </Button>
                      )}
                      {showPDF && (
                        <>
                          {pdfFile ? (
                            <Button
                              icon={IconDownload}
                              onClick={() => {
                                window.open(RestUtil.getDownloadUrl(pdfFile?.url), '_blank', '');
                              }}
                            >
                              {t('common.actions.downloadPdf')}
                            </Button>
                          ) : (
                            <Button icon={IconDownload} onClick={handleCreateOfferPDF}>
                              {t('common.actions.downloadPdf')}
                            </Button>
                          )}
                        </>
                      )}
                    </UiDiv>
                  </>
                )}
              </UiDiv>

              <Collapse show={!offer || showDetails}>
                <UiDiv paddingTop="m">
                  <JobOfferEditor
                    jobId={jobId}
                    isCreateMode={isCreateMode}
                    orderNumber={orderNumber}
                    location={location}
                    comments={comments}
                    onChangeInput={(key, value) => handleOnChangeInput(key, value)}
                  />
                  {showFiles && (
                    <UiDiv marginTop="m">
                      <JobFiles
                        files={files}
                        isEditable={areFilesEditable}
                        onAddFiles={(fileList) => handleUploadOfferFiles(fileList)}
                        onDeleteFile={(fileId) => handleDeleteOfferFile(fileId)}
                      />
                    </UiDiv>
                  )}
                </UiDiv>
              </Collapse>

              <Collapse show={showHistory}>
                <UiDiv paddingTop="m">
                  <JobHistoryList jobId={jobId} type="offer" />
                </UiDiv>
              </Collapse>

              {isCreateMode && (
                <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="m">
                  <Button variant="highlight" icon={IconCheck} onClick={handleOnClickCreate}>
                    {t('offer.actions.create')}
                  </Button>
                  <Button variant="secondary" icon={IconClose} onClick={() => setIsCreateMode(false)}>
                    {t('common.actions.cancel')}
                  </Button>
                </UiDiv>
              )}

              {(showSendButton || showResendButton || showRejectButton) && (
                <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="m">
                  {showSendButton && (
                    <Button
                      variant="highlight"
                      icon={IconArrowRight}
                      onClick={() => {
                        if (onClickSend) {
                          onClickSend(true);
                        }
                      }}
                    >
                      {t('offer.actions.send')}
                    </Button>
                  )}
                  {showResendButton && (
                    <Button
                      variant="highlight"
                      icon={IconArrowRight}
                      onClick={() => {
                        if (onClickSend) {
                          onClickSend(false);
                        }
                      }}
                    >
                      {t('offer.actions.resend')}
                    </Button>
                  )}
                  {showRejectButton && (
                    <Button
                      endIcon={showRejection ? IconArrowUp : IconArrowDown}
                      onClick={() => setShowRejection(!showRejection)}
                    >
                      {t('offer.actions.reject')}
                    </Button>
                  )}
                </UiDiv>
              )}
              <Collapse show={showRejectButton && showRejection} fadeInOut keepChildren={false}>
                <UiDiv paddingTop="s">
                  <LandingOfferReject
                    onClickCancel={() => setShowRejection(false)}
                    onClickReject={handleOnClickReject}
                  />
                </UiDiv>
              </Collapse>
            </UiDiv>
          </LoadingWrapper>
        </UiDiv>
      </Collapse>
    </>
  );
};

export default JobOffer;
