/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './table-body-cell.scss';

class TableBodyCell extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { layout, text, textOld, iconSrc, iconSmallSrc, width, children, onClick } = this.props;

    const cellStyle = {
      width: width,
      flexGrow: width === 'auto' ? 1 : 0,
      flexShrink: width === 'auto' ? 1 : 0,
    };

    let className = 'smg-table-body-cell';
    if (layout === 'wide') {
      className += ' smg-table-body-cell--wide';
    }

    return (
      <div className={className} style={cellStyle} onClick={onClick}>
        <div className="smg-table-body-cell__inner">
          {text && (
            <div className="smg-table-body-cell__text">
              <span dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          )}
          {textOld !== null && (
            <div className="smg-table-body-cell__text smg-table-body-cell__text--old">
              <span>{textOld}</span>
            </div>
          )}
          {iconSrc && <div className="smg-table-body-cell__icon">{iconSrc}</div>}
          {iconSmallSrc && <div className="smg-table-body-cell__icon-small">{iconSmallSrc}</div>}
          {children && <div>{children}</div>}
        </div>
      </div>
    );
  }
}

TableBodyCell.propTypes = {
  /**
   * @prop layout
   * @type string
   * @values '' | 'wide'
   */
  layout: PropTypes.string,

  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textOld: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconSrc: PropTypes.node,
  iconSmallSrc: PropTypes.node,

  /**
   * @prop width
   * @type string
   * @values 'auto' | 'fit' | '[n]%'
   * @default 'auto'
   */
  width: PropTypes.string,

  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
  onClick: PropTypes.func,
};

TableBodyCell.defaultProps = {
  layout: '',
  text: '',
  textOld: null,
  iconSrc: undefined,
  iconSmallSrc: undefined,
  width: 'auto',
  children: null,
  onClick: () => {},
};

export default TableBodyCell;
