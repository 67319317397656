import React from 'react';
import { Block } from '@webfox-sc/core';
import LoginForm from '../LoginForm';

const AuthPage = () => {
  return (
    <Block justifyContent="center" paddingTop="200px">
      <LoginForm />
    </Block>
  );
};

export default AuthPage;
