const IconArrowRight = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M80.66,45.65a5.62,5.62,0,0,1,0,8.7L26.46,98.72a5.62,5.62,0,0,1-9.18-4.35V5.63a5.62,5.62,0,0,1,9.18-4.35Z"
    />
  </svg>
);

export default IconArrowRight;
