import { entitySelectors } from '../entitySelectors';

export const useRecipient = (
  recipientId: number | undefined
): {
  recipient: RecipientEntity | undefined;
  contacts: ContactEntity[];
} => {
  const recipient = entitySelectors.selectById<RecipientEntity>('recipients', recipientId);
  const contacts = entitySelectors.selectByIds<ContactEntity>('contacts', recipient?.contactIds);

  return {
    recipient,
    contacts,
  };
};
