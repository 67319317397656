import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import styled, { CSSObject, useTheme } from 'styled-components';
import { convertNewlinesToHTML } from '../../utils/formatText';
import TextIcon from './TextIcon';

type TextVariant = 'small' | 'label';
type TextAlign = 'left' | 'center' | 'right';

interface StyledTextProps {
  variant?: TextVariant;
  color?: string;
  textAlign?: TextAlign;
  wordBreak?: boolean;
  nowrap?: boolean;
}

const StyledText = styled.div<StyledTextProps>(({ variant, color, textAlign, wordBreak, nowrap, theme }) => {
  const baseStyle: CSSObject = {
    color: theme.colors[color || ''] || color || theme.colors.ciBlue1,
    textAlign,
    overflowWrap: wordBreak ? 'break-word' : undefined,
    wordWrap: wordBreak ? 'break-word' : undefined,
    wordBreak: wordBreak ? 'break-all' : undefined,
    whiteSpace: nowrap ? 'nowrap' : undefined,
    a: {
      color: theme.colors[color || ''] || color || theme.colors.ciBlue1,
      textDecoration: 'underline',
      '&:hover': {
        opacity: 0.8,
      },
    },
  };

  switch (variant) {
    case 'small':
      return {
        ...baseStyle,
        ...theme.type.copySmall,
      };

    case 'label':
      return {
        ...baseStyle,
        ...theme.type.label,
      };

    default:
      return {
        ...baseStyle,
        ...theme.type.copy,
      };
  }
});

  interface TextProps {
  children: React.ReactNode;
  variant?: TextVariant;
  as?: 'div' | 'p' | 'span';
  color?: string;
  textAlign?: TextAlign;
  wordBreak?: boolean;
  nl2br?: boolean;
  nowrap?: boolean;
  icon?: React.ReactNode;
}

const Text: React.FC<TextProps> = ({ variant, as, color, textAlign, wordBreak, nl2br, nowrap, icon, children }) => {
  const { colors } = useTheme();
  const styledTextProps = {
    variant,
    as,
    color,
    textAlign,
    wordBreak,
    nowrap,
  };
  
  const textComponent =
    nl2br && typeof children === 'string' ? (
      <StyledText {...styledTextProps} dangerouslySetInnerHTML={{ __html: convertNewlinesToHTML(children) }} />
    ) : (
      <StyledText {...styledTextProps}>{children}</StyledText>
    );

  if (icon) {
    return (
      <UiDiv display="flex" columnGap="10px">
        <TextIcon icon={icon} color={color || colors.ciBlue1} />
        {textComponent}
      </UiDiv>
    );
  }

  return textComponent;
};

export default Text;
