import { REDUX_ACTIONS } from '../global/constants';

function locationsReducer(
    state = {
        isLocationsRequest: false,
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_LOCATIONS:
            return { ...state, isLocationsRequest: true };

        case REDUX_ACTIONS.REQUEST_LOCATIONS_ERROR:
            return { ...state, isLocationsRequest: false };

        case REDUX_ACTIONS.RECEIVE_LOCATIONS:
            return { ...state, isLocationsRequest: false };

        default:
            return state;
    }
}

export default locationsReducer;
