/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconVisibleSrc from '../../../assets/svg/icon-visible-20.svg';
import IconInvisibleSrc from '../../../assets/svg/icon-invisible-20.svg';
import './basic-input.scss';

class BasicInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeByProp: '',
            type: '',
            valueByProp: '',
            value: '',
            readOnly: props.autoFill ? false : true
        };
    }

    static getDerivedStateFromProps(props, state) {
        const stateUpdates = {};
        if (props.type !== state.typeByProp) {
            stateUpdates.typeByProp = props.type;
            stateUpdates.type = props.type;
        }
        if (props.value !== state.valueByProp) {
            stateUpdates.valueByProp = props.value;
            stateUpdates.value = props.value;
        }

        if (!Object.keys(stateUpdates).length) {
            return null;
        }

        return stateUpdates;
    }

    handleOnFocus(e) {
        const { autoFill, onFocus } = this.props;
        if (!autoFill) {
            this.setState({
                readOnly: false
            });
        }
        onFocus();
    }

    handleOnChange(e) {
        const { onChange } = this.props;
        const { value } = e.target;
        this.setState({
            value
        });
        onChange(value);
    }

    handlePasswordToggle() {
        const { type } = this.state;
        if (type === 'text') {
            this.setState({
                type: 'password'
            });
        } else if (type === 'password') {
            this.setState({
                type: 'text'
            });
        }
    }

    render() {
        const { type, value, readOnly } = this.state;
        const { layout, isPasswordToggle, name, placeholder, maxLength, isError } = this.props;

        let className = 'smg-basic-input';
        if (layout === 'white') {
            className += ' smg-basic-input--layout-white';
        }
        if (isError) {
            className += ' smg-basic-input--error';
        }

        if (type === 'textarea') {
            return (
                <div className={className}>
                    <textarea
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        readOnly={readOnly}
                        onFocus={(e) => this.handleOnFocus(e)}
                        onChange={(e) => this.handleOnChange(e)}
                    />
                </div>
            );
        } else {
            return (
                <div className={className}>
                    <input
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        maxLength={maxLength}
                        readOnly={readOnly}
                        onFocus={(e) => this.handleOnFocus(e)}
                        onChange={(e) => this.handleOnChange(e)}
                    />
                    {isPasswordToggle && (
                        <div className="smg-basic-input__password-toggle" onClick={() => this.handlePasswordToggle()}>
                            <img src={type === 'text' ? IconVisibleSrc : IconInvisibleSrc} alt="" />
                        </div>
                    )}
                </div>
            );
        }
    }
}

BasicInput.propTypes = {
    /**
     * @prop layout
     * @type string
     * @values '' | 'white'
     */
    layout: PropTypes.string,

    /**
     * @prop type
     * @type string
     * @values all html input values eg. 'text', 'textarea' or 'password'
     */
    type: PropTypes.string,

    /**
     * @prop isPasswordToggle
     * @type boolean
     * @description shows password/text toggle / dafault false
     */
    isPasswordToggle: PropTypes.bool,

    /**
     * @prop name
     * @type
     */
    name: PropTypes.string,

    /**
     * @prop placeholder
     * @type
     */
    placeholder: PropTypes.string,

    /**
     * @prop value
     * @type string | number
     * @description default value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * @prop maxLength
     * @type string | number
     * @description default is 256
     */
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * @prop autoFill
     * @type boolean
     * @description 'false' prevents autofilling field / default is 'true'
     */
    autoFill: PropTypes.bool,

    /**
     * @prop isError
     * @type boolean
     */
    isError: PropTypes.bool,

    /**
     * @description callback functions
     */
    onFocus: PropTypes.func,
    onChange: PropTypes.func
};

BasicInput.defaultProps = {
    layout: '',
    type: 'text',
    isPasswordToggle: false,
    name: '',
    placeholder: '',
    value: '',
    maxLength: 256,
    autoFill: true,
    isError: false,
    onFocus: () => false,
    onChange: () => false
};

export default BasicInput;
