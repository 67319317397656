import React from 'react';
import styled, { CSSObject } from 'styled-components';

interface StyledButtonProps {
  minWidth?: 0 | string;
}

const StyledButton = styled.button<StyledButtonProps>(({ theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    minWidth: '54px',
    minHeight: '54px',
    border: 0,
    borderRadius: '5px',
    padding: '6px 0 0 0',
    margin: 0,

    background: theme.colors.ciBlue3,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '4px',
    columnGap: '10px',

    ...(theme.type.copyTiny as CSSObject),
    lineHeight: '12px',
    fontWeight: 700,
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.1s ease',

    svg: {
      flex: '0 0 20px',
      width: '20px',
      maxWidth: 'none',
      height: '20px',
      verticalAlign: 'top',
    },

    '&:hover': {
      background: theme.colors.ciRed,
    },

    '&:active': {
      background: theme.colors.ciRed,
    },

    '&[data-active="true"]': {
      background: theme.colors.ciRed,
    },

    '&:disabled': {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
      '&:hover': {
        opacity: 0.5,
      },
    },

    [theme.mediaQueries.tablet]: {
      minWidth: '50px',
      minHeight: '50px',
      padding: '0 20px',

      flexDirection: 'row',

      ...(theme.type.button as CSSObject),

      svg: {
        flex: '0 0 14px',
        width: '14px',
        maxWidth: 'none',
        height: '14px',
        verticalAlign: 'top',
      },
    },
  };

  return {
    ...baseStyle,
  };
});

interface PageHeadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  active?: boolean;
}

const PageHeadingButton: React.FC<PageHeadingButtonProps> = ({ icon, active, type, children, ...props }) => {
  const dataAttributes = {
    'data-active': active,
  };

  return (
    <StyledButton type={type || 'button'} {...props} {...dataAttributes}>
      {icon}
      {children}
    </StyledButton>
  );
};

export default PageHeadingButton;
