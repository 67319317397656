import React from 'react';
// import PropTypes from 'prop-types';
import { UploadButton } from '@webfox-sc/core';
import { COLORS } from '../../theme/theme';

const theme = {
  button: {
    fontFamily: '"radikal", sans-serif',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
    letterSpacing: '0.5px',
    color: 'white',
    textTransform: 'uppercase',
    padding: '15px 24px',
    background: COLORS.CI_BLUE_3,
    borderRadius: '5px',
    transition: 'background-color 0.1s ease',
    span: {
      marginLeft: '10px',
    },
    svg: {
      width: '14px',
      maxWidth: 'none',
      height: '14px',
    },
    '&[data-with-icon]': {
      paddingLeft: '18px',
    },
    '&[data-with-icon-right]': {
      paddingRight: '18px',
    },
    '&:hover': {
      background: COLORS.CI_RED,
    },
    '&:active': {
      background: COLORS.CI_RED,
    },
    '&[data-active]': {
      background: COLORS.CI_RED,
    },
  },
};

const UploadButtonSecondary = (props) => {
  return <UploadButton {...props} theme={theme} />;
};

UploadButtonSecondary.propTypes = {
  ...UploadButton.propTypes,
};

UploadButtonSecondary.defaultProps = {
  ...UploadButton.defaultProps,
};

export default UploadButtonSecondary;
