/* eslint-disable react-hooks/rules-of-hooks */
import { Checkbox } from '@webfox-sc/core';
import _find from 'lodash/find';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import TableBodyCell from '../../components/elements/TableBodyCell';
import TableBodyRow from '../../components/elements/TableBodyRow';
import { themeCheckbox } from '../../theme/theme';
import AppUtil from '../../utils/AppUtil';
import { formatPrice } from '../../utils/format';

const ServiceListItem = ({ objectId, onClick, isSelected, onSelect }) => {
  const object = useSelector(({ dataState }) => dataState.services[objectId]);

  if (!object) {
    return null;
  }

  const { client, clientIdOld, updatedAt, updatedAtOld } = object;

  const company = (client && client.company) || {};
  // clientId
  //     ? useSelector(({ dataState }) => _find(dataState.companies, ({ clientIds }) => clientIds.includes(clientId)))
  //     : {};
  const oldCompany = clientIdOld
    ? useSelector(({ dataState }) => _find(dataState.companies, ({ clientIds }) => clientIds.includes(clientIdOld)))
    : {};

  const location = (client && client.location) || {};
  // clientId
  //     ? useSelector(({ dataState }) => _find(dataState.locations, ({ clientIds }) => clientIds.includes(clientId)))
  //     : {};
  const oldLocation = clientIdOld
    ? useSelector(({ dataState }) => _find(dataState.locations, ({ clientIds }) => clientIds.includes(clientIdOld)))
    : {};

  const priceFormatted = object.price !== undefined && object.price !== null ? formatPrice(object.price, 2, 4) : '';
  const priceFormattedOld =
    object.priceOld !== undefined && object.priceOld !== null ? formatPrice(object.priceOld, 2, 4) : '';

  const updatedAtFormatted = updatedAt ? AppUtil.toLocalDateString(updatedAt) : '';
  const updatedAtFormattedOld = updatedAtOld ? AppUtil.toLocalDateString(updatedAtOld) : '';

  return (
    <TableBodyRow isClickable isModified={object.isModified} isNew={object.isNew}>
      <TableBodyCell
        text={company && company.number}
        textOld={oldCompany && oldCompany.number}
        onClick={onClick}
        width="12%"
      />
      <TableBodyCell
        text={location && location.number}
        textOld={oldLocation && oldLocation.number}
        onClick={onClick}
        width="12%"
      />
      <TableBodyCell text={object.dla} textOld={object.dlaOld} onClick={onClick} width="7%" />
      <TableBodyCell text={object.description} onClick={onClick} width="25%" />
      <TableBodyCell text={object.unit} textOld={object.unitOld} onClick={onClick} width="10%" />
      <TableBodyCell text={priceFormatted} textOld={priceFormattedOld} onClick={onClick} width="10%" />
      <TableBodyCell text={updatedAtFormatted} textOld={updatedAtFormattedOld} onClick={onClick} width="10%" />
      <TableBodyCell width="7%">
        <Checkbox checked={isSelected} onChange={(e) => onSelect(e.target.checked, objectId)} theme={themeCheckbox} />
      </TableBodyCell>
      <TableBodyCell iconSrc={IconArrowDown} onClick={onClick} width="7%" />
    </TableBodyRow>
  );
};

ServiceListItem.propTypes = {
  objectId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

ServiceListItem.defaultProps = {
  onClick: () => {},
  isSelected: PropTypes.bool,
  onSelect: () => false,
};

export default ServiceListItem;
