import { entitySelectors } from '../entitySelectors';

export const useUser = (
  userId: number | undefined
): {
  user: UserEntity | undefined;
  costcenters: CostcenterEntity[];
} => {
  const user = entitySelectors.selectById<UserEntity>('users', userId);
  const costcenters = entitySelectors.selectByIds<CostcenterEntity>('costcenters', user?.costcenterIds);

  return {
    user,
    costcenters,
  };
};
