import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const BaseDataFilterContext = createContext();

export const BaseDataFilterProvider = ({
    children,
    isDataFilter,
    initialDataFilter = null,
    initialTableFilter = null,
}) => {
    const [dataFilter, setDataFilter] = useState(initialDataFilter);
    const [tableFilter, setTableFilter] = useState(initialTableFilter);

    const handleChangeDataFilter = (filter) => {
        if (isDataFilter(filter)) {
            setDataFilter(filter);
        } else {
            setDataFilter(null);
        }
    };

    const handleChangeTableFilter = (filter) => {
        if (tableFilter !== filter) {
            setTableFilter(filter);
        }
    };

    return (
        <BaseDataFilterContext.Provider
            value={{
                dataFilter,
                handleChangeDataFilter,
                tableFilter,
                handleChangeTableFilter,
            }}
        >
            {children}
        </BaseDataFilterContext.Provider>
    );
};

BaseDataFilterProvider.propTypes = {
    children: PropTypes.node,
    isDataFilter: PropTypes.func,
    initialDataFilter: PropTypes.object,
    initialTableFilter: PropTypes.object,
};

BaseDataFilterProvider.defaultProps = {
    children: null,
    isDataFilter: () => true,
    initialDataFilter: null,
    initialTableFilter: null,
};

export const useBaseDataFilterContext = () => {
    return useContext(BaseDataFilterContext);
};
