import { UiDiv } from '@webfox-sc/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { pathToQMAddAdHoc } from '../../app/paths';
import IconPlus from '../../assets/icons-v2/IconPlus';
import { useAuth } from '../auth/useAuth';
import { usePermissions } from '../auth/usePermissions';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';
import PageNoticeBox from '../page/PageNoticeBox';
import QMFilter from './QMFilter';
import QMReportsAndSchedules from './QMReportsAndSchedules';

import { forEach, orderBy, uniqBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { getEntityId } from '../../app/schemaUtils';
import { fetchCrafts } from '../../app/slices/craftsSlice';
import { QMListEntry, limitInterval, selectQMOverviewState, setQMResultState } from '../../app/slices/qmOverviewSlice';
import { fetchReports } from '../../app/slices/reportsSlice';
import { fetchSchedules } from '../../app/slices/schedulesSlice';
import QMUtil from '../../utils/QMUtil';

const QMStart: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { canViewQM } = usePermissions();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const overviewState = useAppSelector(selectQMOverviewState);

  const handleOnSubmit = async (pagination?: { viewStart?: number }) => {
    const fetchData = async () => {
      // we need at least viewStart + limitInterval entries from each collection to ensure consistant ordering
      const viewStart = pagination?.viewStart !== undefined ? pagination?.viewStart : overviewState.viewStart;

      let resSchedules;
      let didFetch = false;
      if (
        overviewState.shouldFetchSchedules &&
        (overviewState.isDirty || overviewState.scheduleCount < overviewState.scheduleTotal)
      ) {
        didFetch = true;
        resSchedules = await dispatch(
          fetchSchedules({
            populate: {
              client: {
                populate: {
                  company: '*',
                  location: '*',
                  costcenter_rel: '*',
                },
              },
              craft: {
                fields: ['id', 'name']
              },
              reports: {
                populate: {
                  schedule: {
                    fields: ['id'],
                  },
                  client: {
                    populate: {
                      company: '*',
                      location: '*',
                      costcenter_rel: '*',
                    },
                  },
                  reportrooms: {
                    fields: ['id'],
                  },
                },
                // not working
                // sort: 'date:desc'
              },
            },
            pagination: {
              limit: limitInterval,
              start: viewStart,
            },
            count: true,
            ...QMUtil.getAPIFilter(overviewState, 'schedules'),
          })
        );
      }

      let resReports;
      if (
        overviewState.shouldFetchReports &&
        (overviewState.isDirty || overviewState.reportCount < overviewState.reportTotal)
      ) {
        didFetch = true;
        resReports = await dispatch(
          fetchReports({
            populate: {
              client: {
                populate: {
                  company: '*',
                  location: '*',
                  costcenter_rel: '*',
                },
              },
              craft: {
                fields: ['id', 'name']
              },
              schedule: {
                fields: ['id'],
              },
              reportrooms: {
                fields: ['id'],
              },
              reporter: '*',
            },
            pagination: {
              limit: limitInterval,
              start: viewStart,
            },
            count: true,
            ...QMUtil.getAPIFilter(overviewState, 'reports'),
          })
        );
      }

      if (!didFetch) {
        // don't update state
        return;
      }

      const [key, direction = 'asc'] = overviewState.orderBy.split(':');

      const newReports: Record<number, ReportEntity> = {};
      const newEntities: QMListEntry[] = [];

      let reportCount = overviewState.isDirty ? 0 : overviewState.reportCount;
      let scheduleCount = overviewState.isDirty ? 0 : overviewState.scheduleCount;
      let scheduleReportTotal = overviewState.isDirty ? 0 : overviewState.scheduleReportTotal;

      if (resReports) {
        forEach(resReports?.payload?.data || [], (report) => (newReports[report.id] = report));
        reportCount += Object.keys(resReports?.payload?.data || []).length;
      }

      if (resSchedules) {
        scheduleCount += (resSchedules?.payload?.data || []).length;
        // uniqby as workaround for strapi bug (multiple copies of same result if order criteria concerns child collection)
        (uniqBy((resSchedules?.payload?.data as ScheduleData[]) || [], 'id') as ScheduleData[]).forEach((schedule) => {
          // consider only latest open report in schedule
          // remove reports in schedules from reports
          if (schedule.reports?.length) {
            const reports = schedule.reports ? orderBy(schedule.reports, ['date'], ['desc']) : null;
            const report = (reports && reports[0]) || null;
            if (report && typeof report !== 'number' && report.status !== 'finished') {
              const reportId = getEntityId(report);
              if (reportId && newReports[reportId]) {
                delete newReports[reportId];
                scheduleReportTotal++;
              }
            }
          }
          newEntities.push({
            id: schedule.id,
            type: 'schedule',
            compareValue: QMUtil.getCompareValue(schedule, 'schedules', key),
          });
        });
      }

      const filteredNewReportIds: number[] = [];
      Object.values(newReports).map((report) => {
        filteredNewReportIds.push(report.id);
        newEntities.push({
          id: report.id,
          type: 'report',
          compareValue: QMUtil.getCompareValue(report, 'reports', key),
        });
      });

      const entries = orderBy(
        [...(overviewState.isDirty ? [] : overviewState.entries || []), ...newEntities],
        ['type', 'compareValue'],
        ['desc', direction === 'asc' ? 'asc' : 'desc']
      );

      const scheduleTotal = resSchedules?.payload?.count || 0;
      const reportTotal = resReports?.payload?.count || 0;
      const hasMore = reportCount < reportTotal || scheduleCount < scheduleTotal;
      const viewCount = Math.min(viewStart + limitInterval, reportCount + scheduleCount);

      dispatch(
        setQMResultState({
          entries,
          scheduleCount,
          scheduleTotal,
          viewCount,
          viewStart,
          reportCount,
          reportTotal,
          scheduleReportTotal,
          hasMore,
        })
      );
    };
    fetchData();
  };

  useEffect(() => {
    dispatch(fetchCrafts());
    handleOnSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeading
        heading={t('report.headline.overview') || undefined}
        buttonLabel={t('report.actions.createAdHoc') || undefined}
        buttonLabelMobile={t('report.actions.createAdHoc') || undefined}
        buttonIcon={IconPlus}
        onClickButton={() => navigate(pathToQMAddAdHoc())}
        wrapMobileButton
      />
      <QMFilter onSubmit={handleOnSubmit} />
      <PageContent marginTop="m">
        {!canViewQM ? (
          <PageNoticeBox>
            {t('report.info.noAccess')}
            <br />
            <br />
            {t('common.info.currentRole', { roleName: currentUser?.roleName })}
          </PageNoticeBox>
        ) : (
          <UiDiv display="flex" flexDirection="column" rowGap="l">
            <QMReportsAndSchedules onSubmit={handleOnSubmit} />
          </UiDiv>
        )}
      </PageContent>
    </>
  );
};

export default QMStart;
