import { UiDiv, UiText } from '@webfox-sc/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { useAppDispatch } from '../../app/appHooks';
import RatingButton from '../atoms-v2/RatingButton';

import { updateCheckresults } from '../../app/slices/reportroomsSlice';

type QMReportroomAllCheckitemsControlsProps = {
  reportroomId: number | undefined;
};

const QMReportroomAllCheckitemsControls: React.FC<QMReportroomAllCheckitemsControlsProps> = ({ reportroomId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (!reportroomId) {
    return null;
  }

  const handleOnClickRating = async (rating: CheckresultRating) => {
    dispatch(
      updateCheckresults(reportroomId, {
        rating,
      })
    );
  };

  return (
    <UiDiv marginTop="m" rowGap={{ mobile: 'm', tablet: 's' }}>
      <UiText textAlign="center" variant="copy">{t('reportroom.actions.setAll')}</UiText>
      <UiDiv
        marginTop='xs'
        display="flex"
        columnGap={{ mobile: 's', tablet: 'xs' }}
        justifyContent="center"
      >
        <>
          <RatingButton variant="none" onClick={() => handleOnClickRating(-1)} active={true} />
          <RatingButton variant="bad" onClick={() => handleOnClickRating(0)} active={true} />
          <RatingButton variant="neutral" onClick={() => handleOnClickRating(1)} active={true} />
          <RatingButton variant="good" onClick={() => handleOnClickRating(2)} active={true} />
        </>
      </UiDiv>
    </UiDiv>
  );
};

export default QMReportroomAllCheckitemsControls;
