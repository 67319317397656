import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import { JOB_STATUS } from '../global/constants';
import DateUtil from './DateUtil';
import JobUtil from './JobUtil';
import { COLORS } from '../theme/theme';

class DashboardUtil {
  static formatMetrics(metrics) {
    if (!metrics || !metrics.certificates || !metrics.offers) {
      return {};
    }

    const offersTotalCount =
      metrics.offers[JOB_STATUS.OFFER_PENDING]?.count +
      metrics.offers[JOB_STATUS.OFFER_CONFIRMED]?.count +
      metrics.offers[JOB_STATUS.OFFER_REJECTED]?.count;

    const offersTotal =
      metrics.offers[JOB_STATUS.OFFER_PENDING]?.total +
      metrics.offers[JOB_STATUS.OFFER_CONFIRMED]?.total +
      metrics.offers[JOB_STATUS.OFFER_REJECTED]?.total;

    const offerMetrics = [
      {
        name: `Gesamt (${offersTotalCount})`,
        value: offersTotal,
        color: COLORS.CI_BLUE_3,
      },
      {
        name: `Ausstehend (${metrics.offers[JOB_STATUS.OFFER_PENDING]?.count})`,
        value: metrics.offers[JOB_STATUS.OFFER_PENDING]?.total,
        color: COLORS.TRAFFIC_LIGHT_YELLOW,
      },
      {
        name: `Bestätigt (${metrics.offers[JOB_STATUS.OFFER_CONFIRMED]?.count})`,
        value: metrics.offers[JOB_STATUS.OFFER_CONFIRMED]?.total,
        color: COLORS.TRAFFIC_LIGHT_GREEN,
      },
      {
        name: `Abgelehnt (${metrics.offers[JOB_STATUS.OFFER_REJECTED]?.count})`,
        value: metrics.offers[JOB_STATUS.OFFER_REJECTED]?.total,
        color: COLORS.TRAFFIC_LIGHT_RED,
      },
    ];

    const certsTotalCount =
      metrics.certificates[JOB_STATUS.CERTIFICATE_PENDING]?.count +
      metrics.certificates[JOB_STATUS.CERTIFICATE_CONFIRMED]?.count +
      metrics.certificates[JOB_STATUS.CERTIFICATE_REJECTED]?.count;

    const certsTotal =
      metrics.certificates[JOB_STATUS.CERTIFICATE_PENDING]?.total +
      metrics.certificates[JOB_STATUS.CERTIFICATE_CONFIRMED]?.total +
      metrics.certificates[JOB_STATUS.CERTIFICATE_REJECTED]?.total;

    let certMetrics = [
      {
        name: `Gesamt (${certsTotalCount})`,
        value: certsTotal,
        color: COLORS.CI_BLUE_3,
      },
      {
        name: `Ausstehend (${metrics.certificates[JOB_STATUS.CERTIFICATE_PENDING]?.count})`,
        value: metrics.certificates[JOB_STATUS.CERTIFICATE_PENDING]?.total,
        color: COLORS.TRAFFIC_LIGHT_YELLOW,
      },
      {
        name: `Bestätigt (${metrics.certificates[JOB_STATUS.CERTIFICATE_CONFIRMED]?.count})`,
        value: metrics.certificates[JOB_STATUS.CERTIFICATE_CONFIRMED]?.total,
        color: COLORS.TRAFFIC_LIGHT_GREEN,
      },
      {
        name: `Abgelehnt (${metrics.certificates[JOB_STATUS.CERTIFICATE_REJECTED]?.count})`,
        value: metrics.certificates[JOB_STATUS.CERTIFICATE_REJECTED]?.total,
        color: COLORS.TRAFFIC_LIGHT_RED,
      },
    ];

    return { offerMetrics, certMetrics };
  }

  static createDlaListFormatted(services) {
    const dlaList = services.map((service) => service.dla);
    const dlaListUnique = _uniq(dlaList);
    return _sortBy(dlaListUnique, parseInt).join(', ');
  }

  static getPriceFormatted(price) {
    if (!price && price !== 0) {
      return '';
    }
    return `${(price || '0').toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} €`;
  }

  static createCsvArray(jobs, t, withServices) {
    let csvArray = [];
    _forEach(jobs, (job) => {
      const jobData = {
        [t('common.fields.id')]: job.entryId,
        [t('common.fields.company')]: job.clientName,
        [t('common.fields.carrierNumber.medium')]: job.carrierNumber,
        [t('common.fields.costcenterNumber.long')]: job.costcenterNumber,
      };
      // order is important here
      if (!withServices) {
        jobData[t('common.fields.dla')] = this.createDlaListFormatted(job.services);
      }
      jobData[t('common.fields.createdAt')] = DateUtil.getDateFormatted(job.createdAt);
      jobData[t('job.offerStatus')] = job.offerStatus;
      jobData[t('job.status')] = job.status;
      jobData[t('job.export.totalPrice')] = this.getPriceFormatted(job.totalPrice);

      if (withServices) {
        jobData[t('job.isInvoiced')] = job.isInvoiced ? 'abgerechnet' : '';
        // new line for each service
        _forEach(job.services || [], (service) => {
          const serviceData = {
            [t('service.position.short')]: service.position,
            [t('service.serviceDescription')]: service.serviceDescription,
            [t('service.type')]: service.type,
            [t('common.fields.va')]: service.va,
            [t('common.fields.dla')]: service.dla,
            [t('service.unit')]: service.unit,
            [t('service.quantity')]: service.quantity,
            [t('service.unitPrice')]: service.unitPrice,
            [t('service.netPrice')]: JobUtil.getServiceNetPrice(service.unitPrice, service.quantity),
            [t('common.fields.comments')]: service.comments,
            [t('service.location.long')]: service.location,
            [t('service.person.long')]: service.person,
          };
          csvArray.push({ ...jobData, ...serviceData });
        });
      } else {
        csvArray.push(jobData);
      }
    });
    return csvArray;
  }
}

export default DashboardUtil;
