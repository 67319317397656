import { entitySelectors } from '../entitySelectors';

export const useCompany = (
  companyId: number | undefined
): {
  company: CompanyEntity | undefined;
  contacts: ContactEntity[];
  clients: ClientEntity[];
} => {
  const company = entitySelectors.selectById<CompanyEntity>('companies', companyId);
  const contacts = entitySelectors.selectByIds<ContactEntity>('contacts', company?.contactIds);
  const clients = entitySelectors.selectByIds<ClientEntity>('clients', company?.clientIds);

  return {
    company,
    contacts,
    clients,
  };
};
