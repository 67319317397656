/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './basic-button-group.scss';

class BasicButtonGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, wrap } = this.props;

        if (!Array.isArray(children)) {
            return (
                <div className="smg-basic-button-group">
                    {children}
                </div>
            );
        }

        return (
            <div className={`smg-basic-button-group${wrap ? " wrap" : ""}`}>
                {
                    children.map((child, i) => {
                        if (!child) {
                            return null;
                        }
                        return (
                            <div key={i} className="smg-basic-button-group__button">
                                {child}
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

BasicButtonGroup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
};

BasicButtonGroup.defaultProps = {
    children: null,
};

export default BasicButtonGroup;
