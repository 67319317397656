import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import QMUtil from '../../utils/QMUtil';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { fileSchema, reportSchema } from '../schemas';
import { RootState } from '../store';

export const createReport = (data: ReportCreateData): RequestEntitiesAction<ReportData> =>
  requestEntities({
    method: 'POST',
    path: '/reports',
    params: {
      populate: QMUtil.reportPopulateOptions,
    },
    data,
    schema: reportSchema,
  });

export const fetchReports = (params?: any, withCount?: boolean): RequestEntitiesAction<ReportData[]> =>
  requestEntities({
    method: 'GET',
    path: '/reports',
    schema: [reportSchema],
    params,
    withCount,
    type: 'fetchReports',
  });

export const getReportPDFSummry = (params?: any): RequestEntitiesAction<FileData> => {
  return requestEntities({
    method: 'GET',
    path: '/reports/pdf',
    schema: fileSchema,
    params,
  });
};

export const createReportPDF = (reportId: number): RequestEntitiesAction<FileData> => {
  return requestEntities({
    method: 'GET',
    path: `/reports/${reportId}/pdf`,
    schema: fileSchema,
  });
};

export const fetchReport = (reportId: number, params?: any): RequestEntitiesAction<ReportData> =>
  requestEntities({
    method: 'GET',
    path: `/reports/${reportId}`,
    schema: reportSchema,
    params,
  });

export const updateReport = (reportId: number, data: ReportUpdateData): RequestEntitiesAction<ReportroomData> =>
  requestEntities({
    method: 'PUT',
    path: `/reports/${reportId}`,
    data,
    schema: reportSchema,
  });

export const deleteReport = (reportId: number): RequestEntitiesAction<ReportroomData> =>
  requestEntities({
    method: 'DELETE',
    path: `/reports/${reportId}`,
    schema: reportSchema,
    type: 'deleteReport',
  });
export interface ReportsState extends EntityState<ReportEntity> {
  fetchReportsStatus: RequestStatus;
}

const reportsAdapter = createEntityAdapter<ReportEntity>();

const initialState: ReportsState = reportsAdapter.getInitialState({
  fetchReportsStatus: 'idle',
});

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchReports') {
          state.fetchReportsStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.payload.entities?.reports) {
          reportsAdapter.upsertMany(state, action.payload.entities.reports);
        }
        if (action.meta.arg.type === 'fetchReports') {
          state.fetchReportsStatus = 'idle';
        } else if (action.meta.arg.type === 'deleteReport' && action?.payload?.data?.id) {
          reportsAdapter.removeOne(state, action.payload.data.id);
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchReports') {
          state.fetchReportsStatus = 'failed';
        }
      });
  },
});

export const selectReportsState = (state: RootState): ReportsState => state.entitiesState.reports;
export default reportsSlice.reducer;
