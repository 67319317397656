import { Image, UiDiv, UiDivProps } from '@webfox-sc/core';
import React from 'react';
import preloaderSrc from '../../assets/svg/preloader.svg';

interface LoadingWrapperProps extends UiDivProps {
  isLoading?: boolean;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ isLoading, children, ...props }) => {
  return (
    <UiDiv {...props}>
      {isLoading && (
        <UiDiv {...props} display="flex" justifyContent="center">
          <Image src={preloaderSrc} alt="Loading" width="50px" height="50px" />
        </UiDiv>
      )}
      <UiDiv
        csso={{
          opacity: 1,
          transition: 'opacity 0.3s ease',
          '&[data-is-loading="true"]': {
            opacity: 0,
            transition: 'opacity 0s ease',
          },
        }}
        data-is-loading={isLoading}
      >
        {children}
      </UiDiv>
    </UiDiv>
  );
};

export default LoadingWrapper;
