import { useMemo } from 'react';
import { useAppSelector } from '../../app/appHooks';
import { entitySelectors } from '../../app/entitySelectors';

export const usePermissions = (): {
  userHasRole: (roleTypes: RoleType[]) => boolean;
  canViewJobs: boolean;
  canCreateJob: boolean;
  canEditJobIsInvoiced: boolean;
  canViewAllCostCenters: boolean;
  canViewQM: boolean;
  canViewQMAdmin: boolean;
  canCreateQMSummary: boolean;
  canDeleteAllQMReports: boolean;
  canViewBaseData: boolean;
  canViewGeneralBaseData: boolean;
  canViewCostcenterBaseData: boolean;
  canViewAllUsers: boolean;
  canViewDashboard: boolean;
  assignableRoleIds: number[];
  canEditUser: (user: UserEntity | undefined) => boolean;
} => {
  const { status: authStatus, userRoleId } = useAppSelector(({ authState }) => authState);
  const loggedIn = authStatus === 'loggedIn';
  const currentUserRole = entitySelectors.selectById<RoleEntity>('roles', userRoleId);
  const currentUserPermissions = entitySelectors.selectById<PermissionEntity>('permissions', userRoleId);
  const { permissions } = currentUserPermissions || {};
  const roles = entitySelectors.selectAll<RoleEntity>('roles');

  const userHasRole = (roleTypes: RoleType[]): boolean => {
    return currentUserRole?.type ? roleTypes.includes(currentUserRole?.type) : false;
  };

  const assignableRoleTypes = currentUserRole?.config?.editableRoles;

  const assignableRoleIds: number[] = useMemo(() => {
    if (assignableRoleTypes === '*') {
      return roles.map(({ id }) => id);
    } else if (typeof assignableRoleTypes === 'object') {
      return roles.filter((role) => role.type && assignableRoleTypes.includes(role.type)).map(({ id }) => id);
    }
    return [];
  }, [assignableRoleTypes, roles]);

  return {
    userHasRole,
    canViewJobs: loggedIn && !!permissions?.job?.find,
    canCreateJob: loggedIn && !!permissions?.job?.create,
    canEditJobIsInvoiced: loggedIn && !!permissions?.job?.updateIsInvoiced,
    canViewAllCostCenters: loggedIn && currentUserRole?.scope === 'global',
    canViewQM: loggedIn && !!permissions?.report?.find,
    canViewQMAdmin: loggedIn && !!permissions?.roomgroup?.create,
    canCreateQMSummary: loggedIn && !!permissions?.report?.getPDFSummary,
    // for now, me might need scoping in future
    canDeleteAllQMReports: loggedIn && !!permissions?.roomgroup?.create,
    canViewBaseData: loggedIn && (!!permissions?.costcenter?.update || !!permissions?.roomgroup?.create || !!permissions?.user?.create),
    canViewGeneralBaseData: loggedIn && !!permissions?.client?.update,
    canViewCostcenterBaseData: loggedIn && !!permissions?.costcenter?.update,
    canViewAllUsers: loggedIn && !!permissions?.user?.create,
    canViewDashboard: loggedIn && !!permissions?.job?.getMetrics,
    assignableRoleIds,
    canEditUser: (userToEdit) =>
      userToEdit?.roleName && userToEdit.roleId ? assignableRoleIds.includes(userToEdit.roleId) : false,
  };
};
