import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import QMUtil from '../../utils/QMUtil';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { checkresultSchema } from '../schemas';
import { getCompleteEntities } from '../schemaUtils';

export const createCheckresult = (data: CheckresultCreateData): RequestEntitiesAction<CheckresultData> =>
  requestEntities({
    method: 'POST',
    path: '/checkresults',
    data,
    params: {
      populate: QMUtil.checkresultPopulateOptions
    },
    schema: checkresultSchema,
  });

export const updateCheckresult = (
  checkresultId: number,
  data: CheckresultUpdateData
): RequestEntitiesAction<CheckresultData> =>
  requestEntities({
    method: 'PUT',
    path: `/checkresults/${checkresultId}`,
    data,
    params: {
      populate: QMUtil.checkresultPopulateOptions
    },
    schema: checkresultSchema,
  });

export const createCheckresultPictures = (
  checkresultId: number,
  data: FormData
): RequestEntitiesAction<CheckresultData> => {
  return requestEntities({
    method: 'POST',
    path: `/checkresults/${checkresultId}/pictures`,
    data,
    params: {
      populate: QMUtil.checkresultPopulateOptions
    },
    schema: checkresultSchema,
  });
};

export const deleteCheckresultPicture = (
  checkresultId: number,
  pictureId: number
): RequestEntitiesAction<CheckresultData> =>
  requestEntities({
    method: 'DELETE',
    path: `/checkresults/${checkresultId}/pictures/${pictureId}`,
    params: {
      populate: QMUtil.checkresultPopulateOptions
    },
    schema: checkresultSchema,
  });

const checkresultsAdapter = createEntityAdapter<CheckresultEntity>();

export const checkresultsSlice = createSlice({
  name: 'checkresults',
  initialState: checkresultsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.checkresults) {
        const completeEntities = getCompleteEntities(action.payload.entities.checkresults);
        if (Object.keys(completeEntities).length > 0) {
          checkresultsAdapter.upsertMany(state, completeEntities);
        }
      }
    });
  },
});

export default checkresultsSlice.reducer;
