import { UiDiv } from '@webfox-sc/core';
import { map, set } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../utils/format';
import JobHistoryDiffField from './JobHistoryDiffField';
import JobHistoryDocumentDiff from './JobHistoryDocumentDiff';
import JobHistoryRecipientDiff from './JobHistoryRecipientDiff';
import JobHistoryServiceDiff from './JobHistoryServiceDiff';

const JobHistoryDiff: React.FC<DiffEntryProps> = ({ oldDiff, newDiff }) => {
  const { t } = useTranslation();

  const servicesMap: DiffMap = {};
  map(oldDiff?.services || {}, (service, key) => {
    set(servicesMap, [key, 'old'], service);
  });
  map(newDiff?.services || {}, (service, key) => {
    set(servicesMap, [key, 'new'], service);
  });
  const services = Object.values(servicesMap);

  return (
    <>
      {/* recipient */}
      {(oldDiff?.recipient || newDiff?.recipient) && (
        <>
          <strong>
            {t('history.change')} {t('job.recipient')}:{' '}
          </strong>
          <JobHistoryRecipientDiff oldDiff={oldDiff?.recipient} newDiff={newDiff?.recipient} />
        </>
      )}
      {/* services */}
      {services.length > 0 && (
        <>
          <strong>
            {t('history.change')} {t('job.services')}:{' '}
          </strong>
          {services.map((service, index) => (
            <JobHistoryServiceDiff key={`s_${index}`} oldDiff={service?.old} newDiff={service?.new} />
          ))}
        </>
      )}
      {/* offer */}
      {(oldDiff?.offer || newDiff?.offer) && (
        <>
          <strong>
            {t('history.change')} {t('offer.headline.singular')}:{' '}
          </strong>
          <JobHistoryDocumentDiff oldDiff={oldDiff?.offer} newDiff={newDiff?.offer} />
        </>
      )}
      {/* certificate */}
      {(oldDiff?.certificate || newDiff?.certificate) && (
        <>
          <strong>
            {t('history.change')} {t('certificate.headline.singular')}:{' '}
          </strong>
          <JobHistoryDocumentDiff oldDiff={oldDiff?.certificate} newDiff={newDiff?.certificate} />
        </>
      )}
      {/* job diff */}
      <UiDiv display="flex" marginTop="xxs" flexDirection="row" flexWrap="wrap">
        {(oldDiff?.totalPrice || newDiff?.totalPrice) && (
          <JobHistoryDiffField
            label={t('job.totalPrice') || undefined}
            oldValue={oldDiff?.totalPrice !== undefined ? formatPrice(oldDiff?.totalPrice, 2, 4) : ''}
            newValue={newDiff?.totalPrice !== undefined ? formatPrice(newDiff?.totalPrice, 2, 4) : ''}
          />
        )}
      </UiDiv>
    </>
  );
};

export default JobHistoryDiff;
