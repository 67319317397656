/* eslint-disable react-hooks/exhaustive-deps */
import { UiFlex } from '@webfox-sc/core';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SHOW_MOVE_JOBS } from '../../app.config';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconDelete from '../../assets/icons-v2/IconDelete';
import ButtonPrimary from '../../atoms/ButtonPrimary';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import CheckboxLegacy from '../../atoms/CheckboxLegacy';
import Section from '../../atoms/Section';
import { useAddressActions } from '../../hooks/useAddressActions';
import {
  createClientLegacy,
  deleteClientLegacy,
  fetchClientLegacy,
  updateClientLegacy
} from '../../redux-actions/actionsClients';
import ClientsUtil from '../../utils/ClientsUtil';
import { getEntryLabel, stripObject } from '../../utils/FilterUtil';
import AddressEditor from '../AddressEditor';
import FormPanel from '../atoms-v2/FormPanel';
import CustomReactSelect from '../CustomReactSelect';
import Advice from '../elements/Advice';
import BasicInput from '../elements/BasicInput';
import FormCell from '../elements/FormCell';
import FormInfo from '../elements/FormInfo';
import FormLabel from '../elements/FormLabel';
import FormRow from '../elements/FormRow';
import ToggleBox from '../elements/ToggleBox';
import { useToastContext } from '../toast/ToastProvider';

export const stripClientGroup = (obj) => pick(obj, ['id', 'debitor_id', 'is_group']);

const populateOptions = {
  costcenter_rel: {
    fields: ['id', 'number'],
  },
  location: {
    fields: ['id', 'number', 'description'],
  },
  company: {
    fields: ['id', 'number', 'name'],
  },
  client_group: {
    fields: ['id', 'debitor_id', 'is_group'],
  },
  address: '*',
};

const ClientEditor = ({
  model = {},
  isError,
  adviceText: initialAdviceText,
  showAdvice: hasAdvice,
  isUpdateMode,
  initModel,
  onCancel,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const { fetchAddressLegacy } = useAddressActions();

  const [rawModel, setRawModel] = useState(model);
  const [modelState, setModelState] = useState(model);
  const [related, setRelated] = useState({});

  const [showAdvice, setShowAdvice] = useState(hasAdvice || model.isModified || model.isNew);

  useEffect(async () => {
    if (model !== rawModel) {
      if (model) {
        setModelState(model);
        if (model.isModified || model.isNew) {
          setShowAdvice(true);
        }
        setRawModel(model);
      } else {
        const emptyModel = ClientsUtil.getEmptyClient();
        setModelState(emptyModel);
        setRawModel(emptyModel);
        setRelated({});
      }
    }
    if (model && model.id) {
      // set related data for dropdowns
      // TODO we might not need this anymore if we add client_group relation to table
      const clientRaw = await dispatch(
        fetchClientLegacy(
          model.id,
          {
            populate: populateOptions,
          },
          true
        )
      );
      if (clientRaw) {
        const cl = ClientsUtil.parseData(clientRaw);
        setRelated({
          company: stripObject(clientRaw.company),
          location: stripObject(clientRaw.location),
          costcenter: stripObject(cl.costcenter),
          clientGroup: stripClientGroup(clientRaw.client_group),
        });
      } else {
        setRelated({});
      }
    }
  }, [model]);

  const addressModel = useSelector(
    ({ dataState }) => (model.addressId && dataState.addresses[model.addressId]) || null
  );

  useEffect(() => {
    if (model.addressId && !addressModel) {
      fetchAddressLegacy(model.addressId);
    }
  }, [model]);

  if (addressModel && !modelState.address) {
    setModelState({ ...modelState, address: addressModel });
  }

  const handleChangeFormValue = (name, value) => {
    setModelState({
      ...modelState,
      [name]: value,
    });
  };

  const handleOnClickCreate = async () => {
    const strapiData = ClientsUtil.getAPIData(modelState);
    const newClient = await dispatch(createClientLegacy(strapiData));
    onCreate(newClient);

    const emptyModel = initModel();
    setModelState(emptyModel);
    setRawModel(emptyModel);
  };

  const handleOnClickUpdate = async () => {
    const strapiData = ClientsUtil.getAPIData(modelState);
    await dispatch(updateClientLegacy(model.id, { ...strapiData, populate: populateOptions }));
    onUpdate();
  };

  const handleOnClickDelete = async () => {
    // eslint-disable-next-line no-alert
    if (
      // eslint-disable-next-line no-alert
      window.confirm('Wollen Sie das Objekt wirklich löschen? Debitoren und Technischer Platz bleiben erhalten.')
    ) {
      const deletedClients = await dispatch(deleteClientLegacy(modelState.id));
      onDelete(deletedClients);
      showToast('Das Objekt wurde gelöscht.', 'success');
    }
  };

  const handleOnClickAdvice = () => {
    setShowAdvice(false);
  };

  const onChangeCostcenter = (option) => {
    const id = (option && option.value) || null;
    setRelated({
      ...related,
      costcenter: stripObject(option),
    });
    handleChangeFormValue('costcenterId', id);
  };

  const onChangeCompany = (option) => {
    const id = (option && option.value) || null;
    setRelated({
      ...related,
      company: stripObject(option),
    });
    handleChangeFormValue('companyId', id);
  };

  const onChangeLocation = (option) => {
    const id = (option && option.value) || null;
    setRelated({
      ...related,
      location: stripObject(option),
    });
    handleChangeFormValue('locationId', id);
  };

  const onChangeClientGroup = (option) => {
    const id = (option && option.value) || null;
    setRelated({
      ...related,
      clientGroup: stripClientGroup(option),
    });
    handleChangeFormValue('clientGroupId', id);
  };

  const emptyOptions = {
    costcenter: {
      value: '',
      label: t('client.form.costcenter.empty'),
    },
    company: {
      value: '',
      label: t('common.actions.select'),
    },
    location: {
      value: '',
      label: t('common.actions.select'),
    },
    clientGroup: {
      value: '',
      label: t('common.actions.select'),
    },
  };

  const {
    companyId,
    locationId,
    costcenterId,
    clientGroupId,
    noTax,
    noTaxOld,
    debitorId,
    debitorIdOld,
    isModified,
    isNew,
  } = modelState;

  let isSuccess = false;
  let isInfo = false;
  let adviceText = initialAdviceText;

  if (isModified) {
    isInfo = true;
    adviceText = t('client.messages.changed');
  }

  if (isNew) {
    isSuccess = true;
    adviceText = t('client.messages.new');
  }

  const currentOptions = {
    costcenter: null,
    company: null,
    location: null,
    clientGroup: null,
  };

  const asyncDefaultOptions = {
    costcenter: [emptyOptions.costcenter],
    company: [emptyOptions.company],
    location: [emptyOptions.location],
    clientGroup: [emptyOptions.clientGroup],
  };

  let foundCostcenter = false;
  let foundCompany = false;
  let foundLocation = false;
  let foundClientGroup = false;

  if (related) {
    if (related.costcenter && related.costcenter.id === costcenterId) {
      foundCostcenter = true;
      currentOptions.costcenter = {
        value: related.costcenter.id,
        label: getEntryLabel('costcenter', related.costcenter),
      };
      asyncDefaultOptions.costcenter.push(currentOptions.costcenter);
    }
    if (related.company && related.company.id === companyId) {
      foundCompany = true;
      currentOptions.company = {
        value: related.company.id,
        label: getEntryLabel('company', related.company),
      };
      asyncDefaultOptions.company.push(currentOptions.company);
    }
    if (related.location && related.location.id === locationId) {
      foundLocation = true;
      currentOptions.location = {
        value: related.location.id,
        label: getEntryLabel('location', related.location),
      };
      asyncDefaultOptions.location.push(currentOptions.location);
    }
    if (related.clientGroup && related.clientGroup.id === clientGroupId) {
      foundClientGroup = true;
      const cl = ClientsUtil.parseData(related.clientGroup);
      currentOptions.clientGroup = {
        value: related.clientGroup.id,
        label: getEntryLabel('client', cl),
        ...stripClientGroup(related.clientGroup),
      };
      asyncDefaultOptions.clientGroup.push(currentOptions.clientGroup);
    }
  }

  const isDataValid = companyId && foundCompany && locationId && foundLocation && (!clientGroupId || foundClientGroup);

  return (
    <FormPanel>
      <ToggleBox show={showAdvice}>
        <Section paddingBottom="S">
          <Advice
            text={adviceText}
            isSuccess={isSuccess}
            isInfo={isInfo}
            isError={isError}
            onClick={handleOnClickAdvice}
          />
        </Section>
      </ToggleBox>
      <FormRow>
        <FormCell cols="12">
          <FormLabel text={`${t('common.entities.company.singular')} *`} />
          <CustomReactSelect
            asyncDefaultOptions={asyncDefaultOptions.company}
            selectedOption={currentOptions.company || emptyOptions.company}
            asyncApiPath="/companies"
            asyncLimit={500}
            asyncSort="number:asc"
            asyncSetParams={(inputValue) =>
              inputValue
                ? {
                    filters: {
                      $or: [
                        {
                          name: { $containsi: inputValue },
                        },
                        {
                          number: { $contains: inputValue },
                        },
                      ],
                    },
                  }
                : {}
            }
            asyncFormatOption={(entry) => ({
              value: entry.id,
              label: getEntryLabel('company', entry),
              ...stripObject(entry),
            })}
            asyncInputMinLength={3}
            isClearable
            isSearchable
            onChange={onChangeCompany}
          />
        </FormCell>
      </FormRow>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="12">
            <FormLabel text={`${t('common.entities.location.singular')} *`} />
            <CustomReactSelect
              asyncDefaultOptions={asyncDefaultOptions.location}
              selectedOption={currentOptions.location || emptyOptions.location}
              asyncApiPath="/locations"
              asyncLimit={500}
              asyncSort="number:asc"
              asyncSetParams={(inputValue) =>
                inputValue
                  ? {
                      filters: {
                        $or: [
                          {
                            description: { $containsi: inputValue },
                          },
                          {
                            number: { $contains: inputValue },
                          },
                        ],
                      },
                    }
                  : {}
              }
              asyncFormatOption={(entry) => ({
                value: entry.id,
                label: getEntryLabel('location', entry),
                ...stripObject(entry),
              })}
              asyncInputMinLength={3}
              isClearable
              isSearchable
              onChange={onChangeLocation}
            />
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="6">
            <FormLabel text={t('common.entities.costcenter.singular')} />
            <CustomReactSelect
              asyncDefaultOptions={asyncDefaultOptions.costcenter}
              selectedOption={currentOptions.costcenter || emptyOptions.costcenter}
              asyncApiPath="/costcenters"
              asyncLimit={500}
              asyncSort="number:asc"
              asyncSetParams={(inputValue) =>
                inputValue
                  ? {
                      filters: [
                        {
                          number: { $contains: inputValue },
                        },
                      ],
                    }
                  : {}
              }
              asyncFormatOption={(entry) => ({
                value: entry.id,
                label: getEntryLabel('costcenter', entry),
                ...stripObject(entry),
              })}
              asyncInputMinLength={3}
              isClearable
              isSearchable
              onChange={onChangeCostcenter}
            />
          </FormCell>
          <FormCell cols="6">
            <FormLabel text={t('client.clientGroup')} />
            <CustomReactSelect
              asyncDefaultOptions={asyncDefaultOptions.clientGroup}
              selectedOption={currentOptions.clientGroup || emptyOptions.clientGroup}
              asyncApiPath="/clientgroups"
              asyncLimit={500}
              asyncSort="debitor_id:asc"
              asyncSetParams={(inputValue) =>
                inputValue
                  ? {
                      filters: [
                        {
                          debitor_id: { $containsi: inputValue },
                        },
                      ],
                    }
                  : {}
              }
              asyncFormatOption={(entry) => {
                const cl = ClientsUtil.parseData(entry);
                return {
                  value: cl.id,
                  label: getEntryLabel('client', cl),
                  ...stripClientGroup(entry),
                };
              }}
              asyncInputMinLength={3}
              isClearable
              isSearchable
              onChange={onChangeClientGroup}
            />
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="6">
            <FormLabel text={t('client.debitorId')} />
            <BasicInput type="text" value={debitorId} onChange={(value) => handleChangeFormValue('debitorId', value)} />
            {debitorIdOld && <FormInfo text={debitorIdOld} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="6">
            <CheckboxLegacy
              label={t('client.noTax')}
              size="L"
              checked={noTax}
              onChange={(value) => handleChangeFormValue('noTax', value)}
            />
            {noTaxOld !== undefined && noTaxOld !== null && <FormInfo text={noTaxOld === true ? 'Ja' : 'Nein'} />}
          </FormCell>
        </FormRow>
      </Section>
      <AddressEditor
        model={modelState.address || {}}
        onChange={(address) => {
          handleChangeFormValue('address', address);
        }}
      />
      {!foundCostcenter && (
        <Section paddingTop="S">
          {costcenterId ? (
            <Advice text={t('client.messages.costcenterNotFound', { costcenterId })} isInfo />
          ) : (
            <Advice text={t('client.messages.costcenterNotAssigned')} isInfo />
          )}
        </Section>
      )}
      <Section paddingTop="M">
        <UiFlex justifyContent="space-between" alignItems="flex-start" gap="xxs">
            <UiFlex flexWrap="wrap" rowGap="xxs" gap="xxs">
              {!isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.create')}
                  icon={IconCheck}
                  disabled={!isDataValid}
                  onClick={handleOnClickCreate}
                />
              )}
              {isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.apply')}
                  icon={IconCheck}
                  disabled={!isDataValid}
                  onClick={handleOnClickUpdate}
                />
              )}
              {isUpdateMode && SHOW_MOVE_JOBS && (
                <ButtonSecondary
                  label={t('client.actions.showMoveJobsServices')}
                  iconRight={IconArrowRight}
                  onClick={() => navigate(`/data/copy/${model.id}`)}
                />
              )}
              <ButtonSecondary label={t('common.actions.close')} icon={IconClose} onClick={onCancel} />
            </UiFlex>
          {isUpdateMode && (
              <ButtonSecondary label={t('client.actions.delete')} icon={IconDelete} onClick={handleOnClickDelete} />
          )}
        </UiFlex>
      </Section>
    </FormPanel>
  );
};

ClientEditor.propTypes = {
  model: PropTypes.object,
  isUpdateMode: PropTypes.bool,
  showAdvice: PropTypes.bool,
  adviceText: PropTypes.string,
  isError: PropTypes.bool,
  initModel: PropTypes.func,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

ClientEditor.defaultProps = {
  model: null,
  isUpdateMode: false,
  showAdvice: false,
  adviceText: '',
  isError: false,
  initModel: () => {},
  onCancel: () => false,
  onCreate: () => false,
  onUpdate: () => false,
  onDelete: () => false,
};

export default ClientEditor;
