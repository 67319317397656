import React from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv, UiText } from '@webfox-sc/core';

import { useCertificate } from '../../app/entityHooks/useCertificate';
import { useJob } from '../../app/entityHooks/useJob';
import DateUtil from '../../utils/DateUtil';
import { formatPrice } from '../../utils/format';
import JobUtil from '../../utils/JobUtil';
import Checkbox from '../atoms-v2/Checkbox';
import CustomDatePicker from '../atoms-v2/CustomDatePicker';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import TextBox from '../atoms-v2/TextBox';
import TextInput from '../atoms-v2/TextInput';
import JobServiceItem from '../job-services/JobServiceItem';
import JobServicesSummary from '../job-services/JobServicesSummary';

interface JobCertificateEditorProps {
  jobId: number | undefined;
  isCreateMode?: boolean;
  orderNumber?: number | string;
  location?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  comments?: string;
  showPrices?: boolean;
  onChangeInput?: (key: string, value: any) => void;
  onChangeStartDate?: (startDate: any) => void;
  onChangeEndDate?: (endDate: any) => void;
}

const JobCertificateEditor: React.FC<JobCertificateEditorProps> = ({
  jobId,
  isCreateMode,
  orderNumber,
  location,
  startDate,
  endDate,
  comments,
  showPrices,
  onChangeInput,
  onChangeStartDate,
  onChangeEndDate,
}) => {
  const { t } = useTranslation();
  const { job, recipient: jobRecipient, offer } = useJob(jobId);
  const { certificate, recipient: certificateRecipient } = useCertificate(job?.certificateId);

  const clientNumber = isCreateMode ? jobRecipient?.clientNumber : certificateRecipient?.clientNumber;
  const costcenterNumber = isCreateMode ? jobRecipient?.costcenterNumber : certificateRecipient?.costcenterNumber;
  const carrierNumber = isCreateMode ? jobRecipient?.carrierNumber : certificateRecipient?.carrierNumber;
  const createdAtFormatted = isCreateMode
    ? DateUtil.getDateFormatted(new Date())
    : DateUtil.getDateFormatted(certificate?.createdAt);

  return (
    <>
      <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '2fr 2fr 2fr 3fr 3fr' }} columnGap="xxs">
        <FormElementLabel order={{ tablet: 1 }}>{t('common.fields.clientNumber')}</FormElementLabel>
        <TextBox order={{ tablet: 6 }} background="grey20" color="ciBlue1">
          {clientNumber}
        </TextBox>
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 2 }}>
          {t('common.fields.costcenterNumber.long')}
        </FormElementLabel>
        <TextBox order={{ tablet: 7 }} background="grey20" color="ciBlue1">
          {costcenterNumber}
        </TextBox>
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 3 }}>
          {t('common.fields.carrierNumber.medium')}
        </FormElementLabel>
        <TextBox order={{ tablet: 8 }} background="grey20" color="ciBlue1">
          {carrierNumber}
        </TextBox>
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 4 }}>
          {t('common.fields.orderNumber.medium')}
        </FormElementLabel>
        {isCreateMode ? (
          <UiDiv order={{ tablet: 9 }}>
            <TextInput
              value={orderNumber}
              maxLength={128}
              onChange={(e) => {
                if (onChangeInput) {
                  onChangeInput('orderNumber', e.target.value);
                }
              }}
            />
          </UiDiv>
        ) : (
          <TextBox order={{ tablet: 9 }} background="grey20" color="ciBlue1">
            {orderNumber || '–'}
          </TextBox>
        )}

        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 5 }}>
          {t('certificate.offerCreatedAt')}
        </FormElementLabel>
        <TextBox order={{ tablet: 10 }} background="grey20" color="ciBlue1">
          {offer?.createdAt ? DateUtil.getDateFormatted(offer.createdAt) : '–'}
        </TextBox>
      </UiDiv>
      <UiDiv
        marginTop="xs"
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '2fr 1fr 1fr' }}
        columnGap="xxs"
      >
        <FormElementLabel order={{ tablet: 1 }}>{t('common.fields.location')}</FormElementLabel>
        {isCreateMode ? (
          <UiDiv order={{ tablet: 4 }}>
            <TextInput
              value={location}
              maxLength={512}
              onChange={(e) => {
                if (onChangeInput) {
                  onChangeInput('location', e.target.value);
                }
              }}
            />
          </UiDiv>
        ) : (
          <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1">
            {location}
          </TextBox>
        )}
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 2 }}>
          {t('certificate.startDate')} *
        </FormElementLabel>
        {isCreateMode ? (
          <UiDiv order={{ tablet: 5 }}>
            <CustomDatePicker
              popperPlacement="top-start"
              selected={startDate}
              onChange={(startDateNew) => {
                if (onChangeStartDate) {
                  onChangeStartDate(startDateNew);
                }
              }}
              dateFormat="dd.MM.yyyy"
            />
          </UiDiv>
        ) : (
          <TextBox order={{ tablet: 5 }} background="grey20" color="ciBlue1">
            {startDate && DateUtil.getDateFormatted(startDate)}
          </TextBox>
        )}
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 3 }}>
          {t('certificate.endDate')} *
        </FormElementLabel>
        {isCreateMode ? (
          <UiDiv order={{ tablet: 6 }}>
            <CustomDatePicker
              popperPlacement="top-start"
              selected={endDate}
              minDate={startDate}
              onChange={(endDateNew) => {
                if (onChangeEndDate) {
                  onChangeEndDate(endDateNew);
                }
              }}
              dateFormat="dd.MM.yyyy"
            />
          </UiDiv>
        ) : (
          <TextBox order={{ tablet: 5 }} background="grey20" color="ciBlue1">
            {endDate && DateUtil.getDateFormatted(endDate)}
          </TextBox>
        )}
      </UiDiv>

      <UiDiv display="flex" gap="xs" alignItems="center" marginTop="s">
        <Checkbox
          checked={showPrices}
          disabled={!isCreateMode}
          onChange={(e) => {
            if (onChangeInput) {
              onChangeInput('showPrices', e.target.checked);
            }
          }}
        />
        <UiText flexGrow={1} variant="copy">
          {t('certificate.showPrices')}
        </UiText>
      </UiDiv>

      <UiDiv display="flex" flexDirection="column" rowGap="xs" marginTop="s">
        {(job?.services || []).map((jobService, i) => {
          return <JobServiceItem key={String(i)} jobService={jobService} showPrices={showPrices} />;
        })}
      </UiDiv>

      {showPrices && (
        <UiDiv marginTop="xs">
          <JobServicesSummary netPriceFormatted={formatPrice(JobUtil.getServicesTotalNetPrice(job?.services || []))} />
        </UiDiv>
      )}

      <UiDiv marginTop="xs" display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 3fr' }} columnGap="xxs">
        <FormElementLabel order={{ tablet: 1 }}>{t('common.fields.date')}</FormElementLabel>
        <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
          {createdAtFormatted}
        </TextBox>

        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 2 }}>
          {t('common.fields.comments')}
        </FormElementLabel>
        {isCreateMode ? (
          <UiDiv order={{ tablet: 4 }}>
            <TextInput
              value={comments}
              maxLength={512}
              onChange={(e) => {
                if (onChangeInput) {
                  onChangeInput('comments', e.target.value);
                }
              }}
            />
          </UiDiv>
        ) : (
          <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1">
            {comments}
          </TextBox>
        )}
      </UiDiv>
    </>
  );
};

export default JobCertificateEditor;
