import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import iconSrc from '../../assets/svg/icon-arrow-down.svg';
import FormSelectOptions from './FormSelectOptions';
import { COLORS, STYLE_TEXT_M } from '../../theme/theme';

const StyledSelect = styled.select(({ error }) => {
  return {
    appearance: 'none',
    boxSizing: 'border-box',
    width: '100%',
    height: '50px',
    padding: '14px 40px 14px 10px',
    border: `1px solid ${error ? COLORS.TRAFFIC_LIGHT_RED : COLORS.CI_BLUE_1}`,
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    backgroundImage: `url("${iconSrc}")`,
    backgroundSize: '12px auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(100% - 18px) 50%',
    cursor: 'pointer',

    ...STYLE_TEXT_M,
    transition: 'background-color 0.1s ease',

    '&:hover': {
      backgroundColor: 'rgba(239, 239, 239, 0.4)',
    },
    '&:focus': {
      outline: 0,
    },
  };
});

const FormSelect = ({ options, value, error, onChange }) => {
  const [valueDraft, setValueDraft] = useState(value);

  useEffect(() => {
    setValueDraft(value);
  }, [value]);

  const handleOnChange = (e) => {
    setValueDraft(e.target.value);
    onChange(e.target.value);
  };

  return (
    <StyledSelect value={valueDraft} error={error} onChange={handleOnChange}>
      <FormSelectOptions options={options} />
    </StyledSelect>
  );
};

FormSelect.propTypes = {
  /**
   * @description array with data objects (value | label | disabled (optional))
   */
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  error: PropTypes.bool,
  onChange: PropTypes.func,
};

FormSelect.defaultProps = {
  options: [],
  value: '',
  error: false,
  onChange: () => false,
};

export default FormSelect;
