import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const limitInterval = 10;

export interface QMState {
  selectedCostcenterOption?: SelectOption | null;
  selectedCompanyOption?: SelectOption | null;
  selectedLocationOption?: SelectOption | null;
}

const initialState: QMState = {
  selectedCostcenterOption: null,
  selectedCompanyOption: null,
  selectedLocationOption: null,
};

export const qmSlice = createSlice({
  name: 'qmAdmin',
  initialState,
  reducers: {
    setQMState: (state: QMState, action: PayloadAction<QMState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setQMState } = qmSlice.actions;
export const selectQMState = (state: RootState): QMState => state.qmState;
export default qmSlice.reducer;
