/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconCheck17 from '../../assets/icons/IconCheck17';
import './checkbox.scss';

class CheckboxLegacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedByProp: false,
      checked: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.checked !== state.checkedByProp) {
      return {
        checkedByProp: props.checked,
        checked: props.checked,
      };
    }
    return null;
  }

  handleChangeCheckbox(e) {
    const { checked } = this.state;
    const { disabled, onChange } = this.props;

    if (!disabled) {
      const checkedNew = !checked;
      this.setState({
        checked: checkedNew,
      });
      onChange(checkedNew);
    }
  }

  render() {
    const { checked } = this.state;
    const { label, disabled, size, layout } = this.props;

    let className = 'checkbox';
    if (disabled) {
      className += ' checkbox--disabled';
    }
    if (size === 'L') {
      className += ' checkbox--large';
    }
    if (layout) {
      className += ` checkbox--${layout}`;
    }

    return (
      <div className={className}>
        <button type="button" disabled={disabled} onClick={(e) => this.handleChangeCheckbox(e)}>
          {checked ? IconCheck17 : null}
        </button>
        <label>{label}</label>
      </div>
    );
  }
}

CheckboxLegacy.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  layout: PropTypes.string,
};

CheckboxLegacy.defaultProps = {
  label: '',
  checked: false,
  disabled: false,
  onChange: () => false,
  layout: '',
};

export default CheckboxLegacy;
