/* xeslint-disable */
import { REDUX_ACTIONS } from '../global/constants';
import AppUtil from '../utils/AppUtil';
import DataReducerUtil from '../utils/DataReducerUtil';

const dataReducerUtil = new DataReducerUtil();

const initialState = {
  jobs: {},
  recipients: {},
  contacts: {},
  certificates: {},
  offers: {},
  histories: {},
  clients: {},
  services: {},
  companies: {},
  locations: {},
  costcenters: {},
  addresses: {},
  importFiles: {},
};

function dataReducer(state = { ...initialState }, action) {
  switch (action.type) {
    /**
     * jobs
     */
    case REDUX_ACTIONS.RECIEVE_JOBS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setJobs(action.jobs);

    case REDUX_ACTIONS.RECIEVE_JOB:
    case REDUX_ACTIONS.RECIEVE_UPDATE_JOB:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setJob(action.job);

    /**
     * offers
     */
    case REDUX_ACTIONS.RECIEVE_OFFER:
    case REDUX_ACTIONS.RECIEVE_CREATE_OFFER:
    case REDUX_ACTIONS.RECIEVE_UPDATE_OFFER:
    case REDUX_ACTIONS.RECIEVE_DELETE_OFFER_FILE:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setOffer(action.offer);
    case REDUX_ACTIONS.RECIEVE_OFFER_PDF:
      AppUtil.openDownload(action.pdf);
      dataReducerUtil.setState(state);
      return dataReducerUtil.setOfferPdf(action.pdf, action.offerId);

    /**
     * certificates
     */
    case REDUX_ACTIONS.RECIEVE_CERTIFICATE:
    case REDUX_ACTIONS.RECIEVE_CREATE_CERTIFICATE:
    case REDUX_ACTIONS.RECIEVE_UPDATE_CERTIFICATE:
    case REDUX_ACTIONS.RECIEVE_DELETE_CERTIFICATE_FILE:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setCertificate(action.certificate);
    case REDUX_ACTIONS.RECIEVE_CERTIFICATE_PDF:
      AppUtil.openDownload(action.pdf);
      dataReducerUtil.setState(state);
      return dataReducerUtil.setCertificatePdf(action.pdf, action.certificateId);

    /**
     * recipients
     */
    case REDUX_ACTIONS.RECIEVE_RECIPIENT:
    case REDUX_ACTIONS.RECIEVE_CREATE_RECIPIENT:
    case REDUX_ACTIONS.RECIEVE_UPDATE_RECIPIENT:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setRecipient(action.recipient);

    /**
     * histories
     */
    case REDUX_ACTIONS.RECIEVE_CREATE_HISTORY:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setHistory(action.history);

    /**
     * companies
     */
    case REDUX_ACTIONS.RECEIVE_CREATE_COMPANY:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setCompany(action.company, { additionalData: { isNew: true } });

    case REDUX_ACTIONS.RECEIVE_UPDATE_COMPANY:
      dataReducerUtil.setState(state);
      const oldCompany = action.oldCompany || null;
      return dataReducerUtil.setCompany(action.company, {
        additionalData: { isModified: true },
        oldCompany,
        oldAddress: action.oldAddress || null,
      });

    case REDUX_ACTIONS.RECEIVE_DELETE_COMPANY:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteCompany(action.companyId);

    case REDUX_ACTIONS.RECEIVE_DELETE_ALL_COMPANIES:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteAllCompanies(action.companies);

    case REDUX_ACTIONS.RECEIVE_COMPANIES:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setCompanies(action.companies);

    case REDUX_ACTIONS.RECEIVE_COUNT_COMPANIES:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setCompaniesCount(action.count);

    /**
     * costCenters
     */
    case REDUX_ACTIONS.RECEIVE_COSTCENTER:
      dataReducerUtil.setState(state);
      const ccArgs = [];
      if (action.oldCostcenter) {
        ccArgs.push({
          additionalData: { isModified: true },
          oldCostcenter: action.oldCostcenter,
        });
      }
      return dataReducerUtil.setCostcenter(action.costcenter, ...ccArgs);

    case REDUX_ACTIONS.RECEIVE_CREATE_COSTCENTER:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setCostcenters(action.costcenters);

    case REDUX_ACTIONS.RECEIVE_UPDATE_COSTCENTER:
      dataReducerUtil.setState(state);
      const oldCostcenter = action.oldCostcenter || null;
      return dataReducerUtil.setCostcenters(action.costcenters, { oldCostcenter });

    case REDUX_ACTIONS.REQUEST_DELETE_COSTCENTER:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteCostcenter(action.costcenterId);

    case REDUX_ACTIONS.RECEIVE_DELETE_ALL_COSTCENTERS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteAllCostcenters(action.costcenters);

    case REDUX_ACTIONS.RECEIVE_COSTCENTERS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setCostcenters(action.costcenters);

    case REDUX_ACTIONS.RECEIVE_COUNT_COSTCENTERS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setCostcentersCount(action.count);

    /**
     * locations
     */
    case REDUX_ACTIONS.RECEIVE_CREATE_LOCATION:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setLocation(action.location, { additionalData: { isNew: true } });

    case REDUX_ACTIONS.RECEIVE_UPDATE_LOCATION:
      dataReducerUtil.setState(state);
      const oldLocation = action.oldLocation || null;
      return dataReducerUtil.setLocation(action.location, { additionalData: { isModified: true }, oldLocation });

    case REDUX_ACTIONS.RECEIVE_DELETE_LOCATION:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setLocation(action.locationId);

    case REDUX_ACTIONS.RECEIVE_DELETE_ALL_LOCATIONS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteAllLocations(action.locations);

    case REDUX_ACTIONS.RECEIVE_LOCATIONS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setLocations(action.locations);

    case REDUX_ACTIONS.RECEIVE_COUNT_LOCATIONS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setLocationsCount(action.count);

    /**
     * clients
     */
    case REDUX_ACTIONS.RECEIVE_CLIENT:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setClient(action.client, { parseServices: true });

    case REDUX_ACTIONS.RECEIVE_CREATE_CLIENT:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setClient(action.client, { additionalData: { isNew: true } });

    case REDUX_ACTIONS.RECEIVE_UPDATE_CLIENT:
      dataReducerUtil.setState(state);
      const oldClient = action.oldClient || null;
      return dataReducerUtil.setClient(action.client, {
        additionalData: { isModified: true },
        oldClient,
        oldAddress: action.oldAddress || null,
      });

    case REDUX_ACTIONS.RECEIVE_DELETE_CLIENT:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteClientLegacy(action.client);

    case REDUX_ACTIONS.RECEIVE_DELETE_ALL_CLIENTS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteAllClients(action.clients);

    case REDUX_ACTIONS.RECEIVE_CLIENTS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setClients(action.clients);

    case REDUX_ACTIONS.RECEIVE_COUNT_CLIENTS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setClientsCount(action.count);

    /**
     * addresses
     */
    case REDUX_ACTIONS.RECEIVE_ADDRESS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setAddress(action.address);

    /**
     * services
     */
    case REDUX_ACTIONS.RECEIVE_SERVICE:
      dataReducerUtil.setState(state);
      const args = [];
      if (action.oldService) {
        args.push({
          additionalData: { isModified: true },
          oldService: action.oldService,
        });
      }
      return dataReducerUtil.setService(action.service, ...args);

    case REDUX_ACTIONS.RECEIVE_CREATE_SERVICE:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setService(action.service, { additionalData: { isNew: true } });

    case REDUX_ACTIONS.RECEIVE_UPDATE_SERVICE:
      dataReducerUtil.setState(state);
      const oldService = action.oldService || null;
      return dataReducerUtil.setService(action.service, { additionalData: { isModified: true }, oldService });

    case REDUX_ACTIONS.RECEIVE_DELETE_SERVICE:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteService(action.service);

    case REDUX_ACTIONS.RECEIVE_DELETE_ALL_SERVICES:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteAllServices(action.services);

    case REDUX_ACTIONS.RECEIVE_SERVICES:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setServices(action.services);

    case REDUX_ACTIONS.RECEIVE_COUNT_SERVICES:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setServicesCount(action.count);

    case REDUX_ACTIONS.RECEIVE_CLIENT_SERVICES:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setServices(action.services);

    case REDUX_ACTIONS.RECEIVE_UPDATE_SERVICES:
      dataReducerUtil.setState(state);
      const oldServices = action.oldServices || null;
      return dataReducerUtil.setServices(action.services, { additionalData: { isModified: true }, oldServices });

    /**
     * imports
     */
    case REDUX_ACTIONS.RECEIVE_IMPORTS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setImports(action.data, true);

    case REDUX_ACTIONS.RECEIVE_UPLOAD_IMPORTS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setImports(action.data);

    case REDUX_ACTIONS.RECEIVE_IMPORT_IMPORTS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setImports(action.data);

    case REDUX_ACTIONS.RECEIVE_DELETE_IMPORT:
      dataReducerUtil.setState(state);
      return dataReducerUtil.deleteImportFile(action.data);

    case REDUX_ACTIONS.RECEIVE_DELETE_IMPORTS:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setImports(null, true);

    /**
     * landing page
     */
    case REDUX_ACTIONS.RECEIVE_PUBLIC_OFFERS:
    case REDUX_ACTIONS.RECEIVE_PUBLIC_CERTIFICATES:
    case REDUX_ACTIONS.RECEIVE_ACCEPT_CERTIFICATES:
      dataReducerUtil.setState(state);
      return dataReducerUtil.setOffersAndCertificates(action.offers, action.certificates, { setJob: true });

    default:
      return state;
  }
}

export default dataReducer;
