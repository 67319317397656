import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, UiDiv, UiText } from '@webfox-sc/core';

import IconEdit from '../../../assets/icons-v2/IconEdit';
import Checkbox from '../../atoms-v2/Checkbox';
import IconButton from '../../atoms-v2/IconButton';

import QMAdminConfigCheckitemForm from './QMAdminConfigCheckitemForm';
import { useCheckitemtype } from '../../../app/entityHooks/useCheckitemtype';

type QMAdminCheckitemProps = {
  checkitem: CheckitemNestedEntity;
  clickHandler: () => void;
  updateHandler: (value: any) => void;
};

const QMAdminConfigCheckitem: React.FC<QMAdminCheckitemProps> = ({ checkitem, clickHandler, updateHandler }) => {
  const { t } = useTranslation();
  const { checkitemtypeId, question, disabled, weight } = checkitem;
  const { checkitemtype } = useCheckitemtype(checkitemtypeId)
  const [editModeIsActive, setEditModeIsActive] = useState(false);

  if (!checkitem) {
    return null;
  }

  const handleOnSave = (value: any) => {
    updateHandler(value);
    setEditModeIsActive(false);
  };

  return (
    <UiDiv padding="s" background="white">
      <UiDiv display="flex" gap="xs" alignItems="flex-start">
        <UiDiv flexGrow={1} display="flex" gap="xs">
          <Checkbox checked={!disabled} onChange={clickHandler} />
          <UiText variant="copy">
            <b>{checkitemtype?.name}</b>
            <br />
            {question}
          </UiText>
        </UiDiv>
        <UiDiv display="flex" alignItems="center" gap="xs">
          <UiText variant="copy">
            <b>{t('checkitem.common.weight')}: {weight}</b>
          </UiText>
          <IconButton
            icon={IconEdit}
            onClick={() => {
              setEditModeIsActive(!editModeIsActive);
            }}
          />
        </UiDiv>
      </UiDiv>
      <Collapse show={editModeIsActive} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="m">
          <QMAdminConfigCheckitemForm
            checkitem={checkitem}
            onSave={(value) => {
              handleOnSave(value);
              setEditModeIsActive(false);
            }}
            onClickCancel={() => setEditModeIsActive(false)}
            showDelete={checkitem.showDelete}
          />
        </UiDiv>
      </Collapse>
    </UiDiv>
  );
};

export default QMAdminConfigCheckitem;
