import { useTranslation } from 'react-i18next';
import Section from '../../atoms/Section';
import BasicInput from '../elements/BasicInput';
import FormCell from '../elements/FormCell';
import FormInfo from '../elements/FormInfo';
import FormLabel from '../elements/FormLabel';
import FormRow from '../elements/FormRow';

interface AddressEditorProps {
  model?: Record<string, any>;
  onChange?: (model: Record<string, any>) => void;
}

const AddressEditor: React.FC<AddressEditorProps> = ({ model = {}, onChange = () => {} }) => {
  const { t } = useTranslation();
  const handleChangeFormValue = (name, value) => {
    onChange({
      ...model,
      [name]: value,
    });
  };

  const {
    line1,
    line2,
    line3,
    line4,
    street,
    zip,
    city,
    zip_po,
    po,
    line1Old,
    line2Old,
    line3Old,
    line4Old,
    streetOld,
    zipOld,
    cityOld,
    zip_poOld,
    poOld,
  } = model;

  return (
    <>
      <Section paddingTop="M">
        <FormRow>
          <FormCell cols="6">
            <FormLabel text={t('common.address.line1')} />
            <BasicInput type="text" value={line1 || ''} onChange={(value) => handleChangeFormValue('line1', value)} />
            {line1Old && <FormInfo text={line1Old} />}
          </FormCell>
          <FormCell cols="6">
            <FormLabel text={t('common.address.line2')} />
            <BasicInput type="text" value={line2 || ''} onChange={(value) => handleChangeFormValue('line2', value)} />
            {line2Old && <FormInfo text={line2Old} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="6">
            <FormLabel text={t('common.address.line3')} />
            <BasicInput type="text" value={line3 || ''} onChange={(value) => handleChangeFormValue('line3', value)} />
            {line3Old && <FormInfo text={line3Old} />}
          </FormCell>
          <FormCell cols="6">
            <FormLabel text={t('common.address.line4')} />
            <BasicInput type="text" value={line4 || ''} onChange={(value) => handleChangeFormValue('line4', value)} />
            {line4Old && <FormInfo text={line4Old} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="12">
            <FormLabel text={t('common.address.street')} />
            <BasicInput type="text" value={street || ''} onChange={(value) => handleChangeFormValue('street', value)} />
            {streetOld && <FormInfo text={streetOld} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="4">
            <FormLabel text={t('common.address.zip')} />
            <BasicInput type="text" value={zip} onChange={(value) => handleChangeFormValue('zip', value)} />
            {zipOld && <FormInfo text={zipOld} />}
          </FormCell>
          <FormCell cols="8">
            <FormLabel text={t('common.address.city')} />
            <BasicInput type="text" value={city || ''} onChange={(value) => handleChangeFormValue('city', value)} />
            {cityOld && <FormInfo text={cityOld} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="4">
            <FormLabel text={t('common.address.zipPo')} />
            <BasicInput type="text" value={zip_po || ''} onChange={(value) => handleChangeFormValue('zip_po', value)} />
            {zip_poOld && <FormInfo text={zip_poOld} />}
          </FormCell>
          <FormCell cols="8">
            <FormLabel text={t('common.address.po')} />
            <BasicInput type="text" value={po} onChange={(value) => handleChangeFormValue('po', value)} />
            {poOld && <FormInfo text={poOld} />}
          </FormCell>
        </FormRow>
      </Section>
    </>
  );
};

export default AddressEditor;
