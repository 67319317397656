import { UiDiv } from '@webfox-sc/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/appHooks';
import { pathToQMAdminClient, pathToQMAdminClientReports } from '../../../app/paths';
import { fetchReports } from '../../../app/slices/reportsSlice';
import IconArrowRight from '../../../assets/icons-v2/IconArrowRight';
import IconCalendar from '../../../assets/icons-v2/IconCalendar';
import IconCheckCircle from '../../../assets/icons-v2/IconCheckCircle';
import DateUtil from '../../../utils/DateUtil';
import ContentBox from '../../atoms-v2/ContentBox';
import IconButton from '../../atoms-v2/IconButton';
import Text from '../../atoms-v2/Text';
import QMAdminClientInfoBox from '../qm-admin-client/QMAdminClientInfoBox';
import QMAdminRoomgroupsTeaser from '../qm-admin-shared/QMAdminRoomgroupsTeaser';
import { useQMAdminClient } from '../useQMAdminClient';
import QMAdminCompanyClientSchedule from './QMAdminCompanyClientSchedule';

interface QMAdminCompanyClientProps {
  clientId: number | undefined;
}

const QMAdminCompanyClient: React.FC<QMAdminCompanyClientProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { client, schedules } = useQMAdminClient(clientId);

  const [countFinishedReports, setCountFinishedReports] = useState(0);
  const [firstReportDate, setFirstReportDate] = useState<string | undefined>(undefined);

  const fetchReportsData = useCallback(async () => {
    if (clientId) {
      const res = await dispatch(
        fetchReports(
          {
            filters: {
              status: 'finished',
              client: {
                id: clientId,
              },
            },
            pagination: {
              limit: 1,
            },
            fields: ['date'],
            sort: 'date:ASC',
          },
          true
        )
      );
      setCountFinishedReports(res?.payload?.count || 0);
      setFirstReportDate((res?.payload?.data || [])[0]?.date);
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    fetchReportsData();
  }, [fetchReportsData]);

  if (!client) {
    return null;
  }

  return (
    <UiDiv display="flex" flexDirection="column" rowGap="1px">
      <QMAdminClientInfoBox clientId={client.id} />

      <QMAdminRoomgroupsTeaser clientId={client.id} />

      <ContentBox>
        <UiDiv display="flex" gap="xs">
          <UiDiv flexGrow={1}>
            <Text icon={IconCalendar}>
              <strong>{t('schedule.headline.current.plural')}</strong>
            </Text>
          </UiDiv>
          <IconButton icon={IconArrowRight} variant="red" onClick={() => navigate(pathToQMAdminClient(client.id))} />
        </UiDiv>
        {schedules &&
          schedules.map((schedule) => (
            <UiDiv padding="20px 0 0 26px" key={`s_${schedule.id}`}>
              <QMAdminCompanyClientSchedule scheduleId={schedule?.id} />
            </UiDiv>
          ))}
      </ContentBox>

      <ContentBox>
        <UiDiv display="flex" gap="xs">
          <Text icon={IconCheckCircle}>
            <strong>{t('schedule.headline.previous.plural')}</strong>
          </Text>
          <UiDiv flexGrow={1}>
            {countFinishedReports === 0 ? (
              <Text>
                <em>{t('schedule.info.noReports')}</em>
              </Text>
            ) : (
              <Text>
                {countFinishedReports}{' '}
                {countFinishedReports === 1 ? t('common.entities.report.singular') : t('common.entities.report.plural')}{' '}
                {firstReportDate
                  ? `${
                      countFinishedReports === 1 ? t('common.form.on') : t('common.form.from')
                    } ${DateUtil.getDateFormatted(firstReportDate)}`
                  : ''}
              </Text>
            )}
          </UiDiv>
          {countFinishedReports > 0 && (
            <IconButton icon={IconArrowRight} variant="red" onClick={() => navigate(pathToQMAdminClientReports(client.id))} />
          )}
        </UiDiv>
      </ContentBox>
    </UiDiv>
  );
};

export default QMAdminCompanyClient;
