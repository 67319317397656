import { entitySelectors } from '../entitySelectors';

export const useReport = (
  reportId: number | undefined
): {
  report: ReportEntity | undefined;
  reporter: UserEntity | undefined;
  craft: CraftEntity | undefined;
} => {
  const report = entitySelectors.selectById<ReportEntity>('reports', reportId);
  const reporter = entitySelectors.selectById<UserEntity>('users', report?.reporterId);
  const craft = entitySelectors.selectById<CraftEntity>('crafts', report?.craftId);

  return {
    report,
    reporter,
    craft
  };
};
