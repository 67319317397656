function userReducer(
  state = {
    userCostCenterNumbers: [],
  },
  action
) {
  switch (action.type) {
    default:
      return state;
  }
}

export default userReducer;
