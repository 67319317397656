import React from 'react';

const IconCheck17 = (
  <svg width="17" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.751.28a.787.787 0 00-1.202 0L5.365 11.698l-3.914-4.39a.787.787 0 00-1.202 0 1.037 1.037 0 000 1.348l4.515 5.064a.787.787 0 001.202 0L16.751 1.627a1.037 1.037 0 000-1.348z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default IconCheck17;
