import { UiDiv, UiFlex, UiText } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MULTIPLE_SCHEDULES } from '../../../app.config';
import { useAppDispatch } from '../../../app/appHooks';
import { fetchGlobalRoomgroups } from '../../../app/slices/roomgroupsSlice';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import Button from '../../atoms-v2/Button';
import ContentBox from '../../atoms-v2/ContentBox';
import { useQMAdminClient } from '../useQMAdminClient';
import QMAdminClientSchedule from './QMAdminClientSchedule';

interface QMAdminClientSchedulesProps {
  clientId: number | undefined;
}

const QMAdminClientSchedules: React.FC<QMAdminClientSchedulesProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { client, company, schedules } = useQMAdminClient(clientId);

  const [isCreateMode, setIsCreateMode] = useState(false);

  const companyHasRoomgroups = company?.roomgroupIds && company.roomgroupIds.length > 0;

  useEffect(() => {
    if (client && !client.roomgroupIds?.length && company && !companyHasRoomgroups) {
      const fetchData = async () => {
        dispatch(fetchGlobalRoomgroups());
      };
      fetchData();
    }
  }, [client, company, companyHasRoomgroups, dispatch]);

  if (!client) {
    return null;
  }

  const hasSchedules = !!schedules?.length;
  return (
    <UiDiv>
      <UiText variant="h2" color="white" textAlign="center">
        {t(`common.entities.schedule.${hasSchedules ? 'plural' : 'singular'}`)}
      </UiText>

      <UiDiv marginTop="s">
        {(hasSchedules || isCreateMode) && (
          <UiDiv display="flex" flexDirection="column" rowGap="1px">
            {/* {!MULTIPLE_SCHEDULES && (
              <QMAdminRoomgroupsTeaser clientId={clientId} />
            )} */}
            {hasSchedules && schedules.map((schedule) => (
              <ContentBox key={`s_${schedule.id}`}>
                <QMAdminClientSchedule scheduleId={schedule?.id} clientId={clientId} />
              </ContentBox>
            ))}
            {isCreateMode && (
              <ContentBox>
                <QMAdminClientSchedule clientId={clientId} onFinish={() => setIsCreateMode(false)}/>
              </ContentBox>
            )}
          </UiDiv>
        )}
      </UiDiv>
      {(!hasSchedules || MULTIPLE_SCHEDULES) && (
        <UiFlex justifyContent="center" marginTop="s">
          <Button variant="primary" icon={IconPlus} onClick={() => setIsCreateMode(true)}>
            {t('common.actions.add')}
          </Button>
        </UiFlex>
      )}
    </UiDiv>
  );
};

export default QMAdminClientSchedules;
