/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './static-field.scss';

class StaticField extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { layout, align, isPadding, text, children } = this.props;

        let className = 'smg-static-field';

        if (layout === 'transparent') {
            className += ' smg-static-field--transparent';
        }

        if (align === 'right') {
            className += ' smg-static-field--align-right';
        }

        if (!isPadding) {
            className += ' smg-static-field--no-padding';
        }

        return (
            <div className={className}>
                {text && <span dangerouslySetInnerHTML={{ __html: text }} />}
                {children}
            </div>
        );
    }
}

StaticField.propTypes = {
    /**
     * @prop layout
     * @type string
     * @values 'transparent'
     */
    layout: PropTypes.string,
    /**
     * @prop align
     * @type string
     * @values 'right'
     */
    align: PropTypes.string,
    /**
     * @prop isPadding
     * @type boolean
     */
    isPadding: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.any
};

StaticField.defaultProps = {
    layout: '',
    align: '',
    isPadding: true,
    text: '',
    children: null
};

export default StaticField;
