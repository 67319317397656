import { UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import IconEdit from '../../../assets/icons-v2/IconEdit';
import Button from '../../atoms-v2/Button';
import IconButton from '../../atoms-v2/IconButton';
import CustomReactSelect from '../../CustomReactSelect';

type QMAdminSelectableLabelProps = {
  value?: number;
  options: SelectOption[];
  label?: string;
  subLabel?: string;
  onSave: (value: number) => void;
  onDelete?: () => void | undefined;
};

const QMAdminConfigSelectableLabel: React.FC<QMAdminSelectableLabelProps> = ({
  value: inputValue,
  options,
  label,
  subLabel,
  onSave,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(inputValue);
  const [editModeIsActive, setEditModeIsActive] = useState(false);

  const handleOnSave = () => {
    if (value) {
      onSave(value);
    }
    setEditModeIsActive(false);
  };

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  return (
    <UiDiv display="flex" gap="xxs" alignItems="center">
      {editModeIsActive ? (
        <>
          <UiDiv flex="1 1 auto">
            <CustomReactSelect
              options={options}
              selectedValue={value}
              isSearchable
              onChangeValue={setValue}
              placeholder={t('common.actions.select')}
            />
          </UiDiv>
          <UiDiv>
            <Button aria-label={t('common.actions.save' || undefined)} onClick={handleOnSave} icon={IconCheck} />
          </UiDiv>
          <Button
            icon={IconClose}
            variant="secondary"
            onClick={() => {
              setEditModeIsActive(!editModeIsActive);
            }}
          />
          {onDelete && (
            <UiDiv>
              <Button aria-label={t('common.actions.delete') || undefined} onClick={onDelete} icon={IconDelete} />
            </UiDiv>
          )}
        </>
      ) : (
        <UiDiv display="flex" columnGap="xs">
          <UiText variant="copy">
            <b>{label}</b> {subLabel}
          </UiText>
          <IconButton
            variant="red"
            icon={IconEdit}
            onClick={() => {
              setEditModeIsActive(!editModeIsActive);
            }}
          />
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default QMAdminConfigSelectableLabel;
