const IconMenu = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M93.75,87.5H6.25a6.25,6.25,0,0,1,0-12.5h87.5a6.25,6.25,0,0,1,0,12.5Zm0-31.25H6.25a6.25,6.25,0,0,1,0-12.5h87.5a6.25,6.25,0,0,1,0,12.5Zm0-31.25H6.25a6.25,6.25,0,0,1,0-12.5h87.5a6.25,6.25,0,0,1,0,12.5Z"
    />
  </svg>
);

export default IconMenu;
