import { UiDiv, UiFlex } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { fetchClient } from '../../../app/slices/clientsSlice';
import { createContact, deleteContact, updateContact } from '../../../app/slices/contactsSlice';
import { createSchedule, deleteSchedule, updateSchedule } from '../../../app/slices/schedulesSlice';
import IconCalendar from '../../../assets/icons-v2/IconCalendar';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconCraft from '../../../assets/icons-v2/IconCraft';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import IconEmail from '../../../assets/icons-v2/IconEmail';
import AppUtil from '../../../utils/AppUtil';
import QMUtil from '../../../utils/QMUtil';
import Button from '../../atoms-v2/Button';
import ContentBox from '../../atoms-v2/ContentBox';
import Text from '../../atoms-v2/Text';
import QMAdminCompanyClientSchedule from '../qm-admin-company/QMAdminCompanyClientSchedule';
import { useQMAdminClient } from '../useQMAdminClient';
import { useQMAdminSchedule } from '../useQMAdminSchedule';
import QMAdminClientScheduleContactsForm from './QMAdminClientScheduleContactsForm';
import QMAdminClientScheduleForm from './QMAdminClientScheduleForm';
import QMAdminClientScheduleUsersForm from './QMAdminCllientScheduleUsersForm';
import CustomReactSelect from '../../CustomReactSelect/CustomReactSelect';
import { useCrafts } from '../../../app/entityHooks/useCrafts';
import { map } from 'lodash';

interface QMAdminClientScheduleProps {
  clientId: number | undefined;
  scheduleId?: number | undefined;
  onFinish?: () => void;
}

const QMAdminClientSchedule: React.FC<QMAdminClientScheduleProps> = ({ clientId, scheduleId, onFinish }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { client, specificityByCraft } = useQMAdminClient(clientId);
  const { contacts, schedule, users } = useQMAdminSchedule(scheduleId);

  const [scheduleDraft, setScheduleDraft] = useState<ScheduleEntity>(
    schedule || {
      id: 0,
      roomCount: undefined,
      frequency: 1,
      interval: undefined,
      start: undefined,
    }
  );
  const [usersDraft, setUsersDraft] = useState<UserEntity[]>([]);
  const [contactsDraft, setContactsDraft] = useState<ContactEntity[]>(contacts || []);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isEditMode, setIsEditMode] = useState(false);

  const { crafts } = useCrafts();

  const filteredCraftOptions = map(specificityByCraft, ({ craftId }) => {
    if (craftId) {
      const craft = crafts.find((c) => c.id === craftId);
      return {
        value: craftId,
        label: craft?.name || '?',
      } as SelectOption;
    }
    return {
      value: null,
      label: t('craft.form.options.default'),
    } as SelectOption;
  });

  useEffect(() => {
    setUsersDraft(users);
  }, [users]);

  useEffect(() => {
    if (schedule) {
      setScheduleDraft(schedule);
    }
  }, [schedule]);

  useEffect(() => {
    setContactsDraft(contacts);
  }, [contacts]);

  if (!client) {
    return null;
  }

  const handleOnClickCreate = async () => {
    const formErrorsNew: FormErrors = {};
    // if (!scheduleDraft.roomCount) {
    //   formErrorsNew.roomCount = 'Bitte geben Sie eine Anzahl an Räumen ein.';
    // }
    if (!scheduleDraft.frequency || !scheduleDraft.interval) {
      formErrorsNew.frequency = t('schedule.errors.frequencyEmpty');
    }
    if (!scheduleDraft.start) {
      formErrorsNew.start = t('schedule.errors.dateEmpty');
    }
    setFormErrors(formErrorsNew);

    if (!Object.keys(formErrorsNew).length) {
      // -- create contacts
      const contactIds: number[] = [];
      for (const contact of contactsDraft) {
        if (!!contact.name && !!contact.email && AppUtil.isEmailValid(contact.email)) {
          const result = await dispatch(
            createContact({
              name: contact.name,
              email: contact.email,
            })
          );
          if (result.payload?.data?.id) {
            contactIds.push(result.payload.data.id);
          }
        }
      }

      // -- create schedule
      await dispatch(
        createSchedule({
          room_count: scheduleDraft.roomCount || undefined,
          frequency: scheduleDraft.frequency || 0,
          interval: scheduleDraft.interval,
          start: scheduleDraft.start,
          craft: scheduleDraft.craftId,
          client: client.id,
          users: usersDraft.map((user) => user.id),
          contacts: contactIds,
        })
      );

      // -- refetch client
      await dispatch(fetchClient(client.id, { populate: QMUtil.clientPopulateOptions }));

      // -- hide form
      setIsEditMode(false);
      if (onFinish) {
        onFinish();
      }
    }
  };

  const handleOnClickUpdate = async () => {
    const formErrorsNew: FormErrors = {};
    // if (!scheduleDraft.roomCount) {
    //   formErrorsNew.roomCount = 'Bitte geben Sie eine Anzahl an Räumen ein.';
    // }
    if (!scheduleDraft.frequency || !scheduleDraft.interval) {
      formErrorsNew.frequency = t('schedule.errors.frequencyEmpty');
    }
    if (!scheduleDraft.start) {
      formErrorsNew.start = t('schedule.errors.dateEmpty');
    }
    setFormErrors(formErrorsNew);

    if (!Object.keys(formErrorsNew).length && !!scheduleDraft.id) {
      // -- create/update/delete contacts
      const contactIds: number[] = [];
      for (const contact of contactsDraft) {
        // -- new
        if (contact.editState === 'new') {
          if (!!contact.name && !!contact.email && AppUtil.isEmailValid(contact.email)) {
            const result = await dispatch(
              createContact({
                name: contact.name,
                email: contact.email,
              })
            );
            if (result.payload?.data?.id) {
              contactIds.push(result.payload.data.id);
            }
          }
        }
        // -- updated
        else if (contact.editState === 'updated') {
          if (!!contact.name && !!contact.email && AppUtil.isEmailValid(contact.email)) {
            await dispatch(
              updateContact(contact.id, {
                name: contact.name,
                email: contact.email,
              })
            );
          }
          contactIds.push(contact.id);
        }
        // -- delete
        else if (contact.editState === 'delete') {
          await dispatch(deleteContact(contact.id));
        }
        // -- nothing changed
        else {
          contactIds.push(contact.id);
        }
      }

      // -- update schedule
      await dispatch(
        updateSchedule(scheduleDraft.id, {
          room_count: scheduleDraft.roomCount || undefined,
          frequency: scheduleDraft.frequency || 0,
          interval: scheduleDraft.interval,
          start: scheduleDraft.start,
          craft: scheduleDraft.craftId,
          users: usersDraft.map((user) => user.id),
          contacts: contactIds,
        })
      );

      // -- refetch client
      await dispatch(fetchClient(client.id, { populate: QMUtil.clientPopulateOptions }));

      // -- hide form
      setIsEditMode(false);
      if (onFinish) {
        onFinish();
      }
    }
  };

  const handleOnClickRemove = () => {
    if (scheduleId) {
      if (window.confirm(t('schedule.prompts.deleteForClient') || undefined)) {
        dispatch(deleteSchedule(scheduleId));
      }
    }
  };

  return (
    <>
      {!isEditMode && scheduleId ? (
        <>
          <QMAdminCompanyClientSchedule scheduleId={scheduleId} onClick={() => setIsEditMode(true)} />

          <UiDiv paddingTop="s">
            <Button variant="secondaryRed" onClick={handleOnClickRemove} icon={IconDelete}>
              {t('common.actions.remove')}
            </Button>
          </UiDiv>
        </>
      ) : (
        <>
          <QMAdminClientScheduleUsersForm clientId={client.id} scheduleId={schedule?.id} onUpdate={setUsersDraft} />

          <ContentBox>
            <Text icon={IconEmail}>
              <strong>{t('common.fields.contacts')}</strong>
            </Text>
            <UiDiv paddingTop="s">
              <QMAdminClientScheduleContactsForm scheduleId={schedule?.id} onUpdate={setContactsDraft} />
            </UiDiv>
          </ContentBox>

          <ContentBox>
            <Text icon={IconCraft}>
              <strong>{t('common.fields.craft')}</strong>
            </Text>
            <UiDiv paddingTop="s">
              <UiDiv padding="s" background="white">
                <CustomReactSelect
                  options={filteredCraftOptions}
                  selectedValue={scheduleDraft.craftId || null}
                  // don't disable if current option is not available to allow for correction
                  isDisabled={
                    filteredCraftOptions.length === 1 &&
                    !!filteredCraftOptions.find((o) => o.value === scheduleDraft.craftId || null)
                  }
                  isSearchable
                  isClearable
                  onChangeValue={(value) => {
                    setScheduleDraft({
                      ...scheduleDraft,
                      craftId: value,
                    });
                  }}
                  placeholder={t('common.actions.select')}
                />
              </UiDiv>
            </UiDiv>
          </ContentBox>

          <ContentBox>
            <Text icon={IconCalendar}>
              <strong>{t('schedule.headline.current.singular')}</strong>
            </Text>
            <UiDiv paddingTop="s">
              <QMAdminClientScheduleForm
                viewMode={schedule ? 'edit' : 'create'}
                formErrors={formErrors}
                scheduleDraft={scheduleDraft}
                onUpdateScheduleDraft={setScheduleDraft}
              />
            </UiDiv>
          </ContentBox>

          <ContentBox>
            <UiFlex flexWrap="wrap" gap="xs" justifyContent="center">
              <Button
                icon={IconCheck}
                onClick={() => {
                  if (!schedule) {
                    handleOnClickCreate();
                  } else {
                    handleOnClickUpdate();
                  }
                }}
              >
                {!schedule ? t('common.actions.save') : t('common.actions.refresh')}
              </Button>
              <Button
                variant="secondary"
                icon={IconClose}
                onClick={() => {
                  setIsEditMode(false);
                  if (onFinish) {
                    onFinish();
                  }
                }}
              >
                {t('common.actions.cancel')}
              </Button>
            </UiFlex>
          </ContentBox>
        </>
      )}
    </>
  );
};

export default QMAdminClientSchedule;
