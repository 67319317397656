/* eslint-disable */
import React, { Component } from 'react';
import preloaderSrc from '../../../assets/svg/preloader.svg'
import './preloader.scss';

class Preloader extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className="smg-preloader">
                <img src={preloaderSrc} alt="" />
            </div>
        );
    }
}

export default Preloader;
