import React from 'react';
import { UiDiv } from '@webfox-sc/core';
import { useTranslation } from 'react-i18next';
import JobHistoryDiffField from './JobHistoryDiffField';
import { formatPrice } from '../../utils/format';

const JobHistoryServiceDiff: React.FC<DiffEntryProps> = ({ oldDiff, newDiff }) => {
  const { t } = useTranslation();

  return (
    <>
      <UiDiv display="flex" flexDirection="row" flexWrap="wrap">
        {(oldDiff?.position || newDiff?.position) && (
          <JobHistoryDiffField
            label={t('service.position.short') || undefined}
            oldValue={oldDiff?.position}
            newValue={newDiff?.position}
          />
        )}
        {(oldDiff?.serviceDescription || newDiff?.serviceDescription) && (
          <JobHistoryDiffField
            label={t('service.serviceDescription') || undefined}
            oldValue={oldDiff?.serviceDescription}
            newValue={newDiff?.serviceDescription}
          />
        )}
        {(oldDiff?.comments || newDiff?.comments) && (
          <JobHistoryDiffField
            label={t('common.fields.comments') || undefined}
            oldValue={oldDiff?.comments}
            newValue={newDiff?.comments}
          />
        )}
        {(oldDiff?.location || newDiff?.location) && (
          <JobHistoryDiffField
            label={t('service.location.short') || undefined}
            oldValue={oldDiff?.location}
            newValue={newDiff?.location}
          />
        )}
        {(oldDiff?.person || newDiff?.person) && (
          <JobHistoryDiffField
            label={t('service.person.short') || undefined}
            oldValue={oldDiff?.person}
            newValue={newDiff?.person}
          />
        )}
        {(oldDiff?.va || newDiff?.va) && (
          <JobHistoryDiffField label={t('common.fields.va') || undefined} oldValue={oldDiff?.va} newValue={newDiff?.va} />
        )}
        {(oldDiff?.dla || newDiff?.dla) && (
          <JobHistoryDiffField label={t('common.fields.dla') || undefined} oldValue={oldDiff?.dla} newValue={newDiff?.dla} />
        )}
        {(oldDiff?.quantity || newDiff?.quantity) && (
          <JobHistoryDiffField
            label={t('service.quantity') || undefined}
            oldValue={oldDiff?.quantity}
            newValue={newDiff?.quantity}
          />
        )}
        {(oldDiff?.unitPrice || newDiff?.unitPrice) && (
          <JobHistoryDiffField
            label={t('service.unitPrice') || undefined}
            oldValue={oldDiff?.unitPrice !== undefined ? formatPrice(oldDiff?.unitPrice, 2, 4) : ''}
            newValue={newDiff?.unitPrice !== undefined ? formatPrice(newDiff?.unitPrice, 2, 4) : ''}
          />
        )}
        {(oldDiff?.unit || newDiff?.unit) && (
          <JobHistoryDiffField label={t('service.unit') || undefined} oldValue={oldDiff?.unit} newValue={newDiff?.unit} />
        )}
      </UiDiv>
    </>
  );
};

export default JobHistoryServiceDiff;
