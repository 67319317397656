/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function certificateReducer(
    state = {
        certificate: {},
        isRequestingCertificate: false
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_CERTIFICATE:
            return Object.assign({}, state, {
                isRequestingCertificate: true
            });

        case REDUX_ACTIONS.REQUEST_CERTIFICATE_ERROR:
            return Object.assign({}, state, {
                certificate: {},
                isRequestingCertificate: false
            });

        case REDUX_ACTIONS.RECIEVE_CERTIFICATE:
            return Object.assign({}, state, {
                certificate: action.certificate,
                isRequestingCertificate: false
            });

        case REDUX_ACTIONS.RECIEVE_UPDATE_CERTIFICATE:
            return Object.assign({}, state, {
                certificate: action.certificate
            });

        default:
            return state;
    }
}

export default certificateReducer;
