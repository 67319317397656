import React, { useContext, useState } from 'react';

type ToastType = 'success' | 'error' | undefined;

interface ToastContextInterface {
  isToastVisible: boolean;
  toastText: string;
  toastType: ToastType;
  showToast: (text: string, type?: ToastType) => void;
  closeToast: () => void;
}

export const ToastContext = React.createContext<ToastContextInterface>({} as ToastContextInterface);

export const useToastContext = () => {
  return useContext(ToastContext);
};

const ToastProvider: React.FC = ({ children }) => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [toastText, setToastText] = useState('');
  const [toastType, setToastType] = useState<ToastType>(undefined);

  const showToast = (text: string, type?: ToastType) => {
    setToastText(text);
    setToastType(type || undefined);
    setIsToastVisible(true);
  };

  const closeToast = () => {
    setIsToastVisible(false);
  };

  return (
    <ToastContext.Provider
      value={{
        isToastVisible,
        toastText,
        toastType,
        showToast,
        closeToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
