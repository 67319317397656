const IconDownload = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M73.37,100H26.63c-1.57,0-3.08-.64-4.2-1.77-1.11-1.13-1.74-2.67-1.74-4.27s.63-3.14,1.74-4.27c1.11-1.13,2.62-1.77,4.2-1.77h46.74c1.57,0,3.08,.64,4.2,1.77,1.11,1.13,1.74,2.67,1.74,4.27s-.63,3.14-1.74,4.27c-1.11,1.13-2.62,1.77-4.2,1.77Zm4.2-45.46l-23.39,23.82c-1.11,1.13-2.62,1.77-4.2,1.77s-3.08-.64-4.2-1.77l-23.35-23.77c-1.12-1.13-1.74-2.67-1.74-4.28,0-1.6,.62-3.14,1.74-4.28,1.11-1.14,2.62-1.77,4.2-1.78s3.09,.63,4.2,1.77l13.24,13.47V6.04c0-1.6,.63-3.14,1.74-4.27,1.11-1.13,2.62-1.77,4.2-1.77s3.08,.64,4.2,1.77c1.11,1.13,1.74,2.67,1.74,4.27V59.47l13.24-13.47c1.12-1.1,2.62-1.71,4.18-1.7,1.56,.01,3.05,.65,4.15,1.77,1.1,1.12,1.73,2.64,1.74,4.22,.01,1.58-.58,3.11-1.67,4.25Z"
    />
  </svg>
);

export default IconDownload;
