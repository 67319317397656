/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './form-cell.scss';

class FormCell extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { cols, align, children } = this.props;

        let className = 'smg-form-cell';

        if (cols !== '0') {
            className += ' smg-form-cell--cols-' + cols;
        }

        if (align === 'right') {
            className += ' smg-form-cell--align-right';
        }

        return <div className={className}>{children}</div>;
    }
}

FormCell.propTypes = {
    /**
     * @prop cols
     * @type string
     * @values '3' | '4' | '6' | '8'
     */
    cols: PropTypes.string,

    /**
     * @prop align
     * @type string
     * @values 'right'
     */
    align: PropTypes.string,

    /**
     * @prop children
     * @type object || array
     */
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

FormCell.defaultProps = {
    cols: '',
    align: '',
    children: null
};

export default FormCell;
