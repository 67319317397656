import { UiDiv } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { useCheckitemgroup } from '../../../app/entityHooks/useCheckitemgroup';
import { useCheckitemgrouptypeOptions } from '../../../app/entityHooks/useCheckitemgrouptypeOptions';
import {
  createCheckitemgroup,
  deleteCheckitemgroup,
  updateCheckitemgroup,
} from '../../../app/slices/checkitemgroupsSlice';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import Button from '../../atoms-v2/Button';
import Checkbox from '../../atoms-v2/Checkbox';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import CustomReactSelect from '../../CustomReactSelect';

interface BaseDataQMCheckitemgroupFormProps {
  checkitemgroupId?: number | undefined;
  roomgroupId?: number | undefined;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

const BaseDataQMCheckitemgroupForm: React.FC<BaseDataQMCheckitemgroupFormProps> = ({
  checkitemgroupId,
  roomgroupId,
  onSubmit,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formMode = checkitemgroupId ? 'update' : 'create';

  const { checkitemgroup, checkitemgrouptype } = useCheckitemgroup(checkitemgroupId);
  const { checkitemgrouptypeOptions } = useCheckitemgrouptypeOptions();
  const [checkitemgroupData, setCheckitemgroupData] = useState<CheckitemgroupUpdateData>({});

  useEffect(() => {
    setCheckitemgroupData({
      disabled: checkitemgroup ? checkitemgroup?.disabled : true,
      checkitemgrouptype: checkitemgroup?.checkitemgrouptypeId,
    });
  }, [checkitemgroup]);

  const isFormDataValid = () => {
    const { checkitemgrouptype: dataCheckitemgrouptype } = checkitemgroupData;
    return !!dataCheckitemgrouptype;
  };

  const handleChangeValue = (key: string, value: any) => {
    const checkitemgroupDataUpdated: CheckitemgroupUpdateData = { ...checkitemgroupData };
    checkitemgroupDataUpdated[key] = value;
    setCheckitemgroupData(checkitemgroupDataUpdated);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (formMode === 'create') {
      if (roomgroupId) {
        const createData: CheckitemgroupCreateData = {
          roomgroup: roomgroupId,
          ...checkitemgroupData,
        };
        await dispatch(createCheckitemgroup(createData));
      }
    } else if (checkitemgroupId) {
      await dispatch(updateCheckitemgroup(checkitemgroupId, checkitemgroupData));
    }
    if (onSubmit) {
      onSubmit(e);
    }
  };

  const handleOnClickDelete: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (checkitemgroup) {
      if (window.confirm(t('checkitemGroup.prompts.delete', { name: checkitemgrouptype?.name }) || undefined)) {
        dispatch(deleteCheckitemgroup(checkitemgroup.id));
      }
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" rowGap="s" columnGap="xs">
        <UiDiv gridColumn="1 / 10">
          <FormElementLabel>{t('common.fields.name')} *</FormElementLabel>
          <CustomReactSelect
            options={checkitemgrouptypeOptions}
            selectedValue={checkitemgroupData?.checkitemgrouptype}
            isSearchable
            onChangeValue={(value) => handleChangeValue('checkitemgrouptype', value)}
            placeholder={t('common.actions.select')}
          />
        </UiDiv>
        <UiDiv gridColumn="10 / 13">
          <FormElementLabel>{t('common.qm.preSelected')}</FormElementLabel>
          <Checkbox
            checked={!checkitemgroupData.disabled}
            onChange={(e) => handleChangeValue('disabled', !e.target.checked)}
          />
        </UiDiv>
      </UiDiv>
      <UiDiv display="flex" columnGap="xs" justifyContent="space-between" paddingTop="s">
        <UiDiv display="flex" columnGap="xs">
          <Button type="submit" icon={IconCheck} disabled={!isFormDataValid()}>
            {formMode === 'create' ? t('common.actions.create') : t('common.actions.apply')}
          </Button>
          <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
            {t('common.actions.cancel')}
          </Button>
        </UiDiv>
        {formMode === 'update' && (
          <Button icon={IconDelete} onClick={handleOnClickDelete}>
            {t('common.actions.delete')}
          </Button>
        )}
      </UiDiv>
    </form>
  );
};

export default BaseDataQMCheckitemgroupForm;
