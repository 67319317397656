import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useQMReportroomCheckitem = (
  reportroomId: number | undefined,
  checkitemId: number | undefined
): {
  checkitem: CheckitemEntity | undefined;
  checkitemtype: CheckitemtypeEntity | undefined;
  checkitemgroup: CheckitemgroupEntity | undefined;
  checkitemgrouptype: CheckitemgrouptypeEntity | undefined;
  checkresult: CheckresultEntity | undefined;
} => {
  const checkitem = entitySelectors.selectById<CheckitemEntity>('checkitems', checkitemId);
  const checkitemtype = entitySelectors.selectById<CheckitemtypeEntity>('checkitemtypes', checkitem?.checkitemtypeId);
  const checkitemgroups = entitySelectors.selectAll<CheckitemgroupEntity>('checkitemgroups');
  const checkresults = entitySelectors.selectAll<CheckresultEntity>('checkresults');

  const checkitemgroup = useMemo(() => {
    return checkitem?.id ? checkitemgroups.find((entity) => entity.checkitemIds.includes(checkitem.id)) : undefined;
  }, [checkitemgroups, checkitem?.id]);

  const checkitemgrouptype = entitySelectors.selectById<CheckitemgrouptypeEntity>('checkitemgrouptypes', checkitemgroup?.checkitemgrouptypeId);

  const checkresult = useMemo(() => {
    return !!reportroomId && !!checkitemId
      ? checkresults.find((entity) => entity.reportroomId === reportroomId && entity.checkitemId === checkitemId)
      : undefined;
  }, [reportroomId, checkitemId, checkresults]);

  return {
    checkitem,
    checkitemtype,
    checkitemgroup,
    checkitemgrouptype,
    checkresult,
  };
};
