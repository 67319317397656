import { Block, UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../app/appHooks';
import { useCraft } from '../../../app/entityHooks/useCraft';
import { pathToQMAdminClient } from '../../../app/paths';
import { fetchCheckitemtypes } from '../../../app/slices/checkitemtypesSlice';
import { fetchClient } from '../../../app/slices/clientsSlice';
import { fetchCrafts } from '../../../app/slices/craftsSlice';
import { deleteClientRoomgroups, updateClientRoomgroups } from '../../../app/slices/roomgroupsSlice';
import IconCraft from '../../../assets/icons-v2/IconCraft';
import QMUtil from '../../../utils/QMUtil';
import Text from '../../atoms-v2/Text';
import PageContent from '../../page/PageContent';
import PageHeading from '../../page/PageHeading';
import QMAdminConfigRoomgroups from '../qm-admin-config/QMAdminConfigRoomgroups';
import { useQMAdminClient } from '../useQMAdminClient';
import { useQMAdminNestedRoomgroups } from '../useQMAdminNestedRoomgroups';
import QMAdminClientInfoBox from './QMAdminClientInfoBox';

const QMAdminClientConfig: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clientId: clientIdString, craftId: craftIdString } = useParams();
  const clientId = clientIdString ? parseInt(clientIdString) : -1;
  const craftId = craftIdString ? parseInt(craftIdString) || null : null;

  const { company, client, specificityByCraft } = useQMAdminClient(clientId);
  const { roomgroups } = useQMAdminNestedRoomgroups(
    [...(client?.roomgroupIds || []), ...(company?.roomgroupIds || [])],
    craftId,
    false
  );
  const { craft } = useCraft(craftId || undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (clientId) {
        dispatch(fetchCheckitemtypes());
        dispatch(fetchCrafts());
        dispatch(fetchClient(clientId, { populate: QMUtil.clientPopulateOptions }));
      }
    };
    fetchData();
  }, [dispatch, clientId]);

  if (!clientId || !company?.id || !client) {
    return null;
  }

  const currentSpecificity = specificityByCraft[`${craftId || 0}`];

  let clientRoomgroups;
  let scope;

  if (roomgroups) {
    if (currentSpecificity?.clientId) {
      scope = 'CLIENT';
      clientRoomgroups = QMUtil.disableInParentRoomgroups(roomgroups, ['GLOBAL', 'COMPANY']);
    } else if (currentSpecificity?.companyId) {
      scope = 'COMPANY';
      clientRoomgroups = QMUtil.disableInParentRoomgroups(roomgroups, ['GLOBAL']);
    } else {
      scope = 'GLOBAL';
      clientRoomgroups = roomgroups;
    }
  }

  const handleOnSave = (currentCraftId: number | null, newData: RoomgroupNestedEntity[]) => {
    const strapiData = newData.map((entry) => QMUtil.getRoomgroupAPIData(entry, currentCraftId || undefined));
    dispatch(updateClientRoomgroups(clientId, currentCraftId || 0, strapiData));
  };

  const handleOnDelete = (currentCraftId: number | null) => {
    dispatch(deleteClientRoomgroups(clientId, currentCraftId || 0)).then(() => navigate(pathToQMAdminClient(clientId)));
  };


  return (
    <>
      <PageHeading
        heading={company.number}
        subheading={company.name}
        backlinkLabel={t('common.actions.back') || undefined}
        backlinkPath={pathToQMAdminClient(client.id)}
      />
      <PageContent marginTop="l">
        <QMAdminClientInfoBox clientId={client.id} />

        <Block marginTop="l">
          <UiText variant="h2" color="white" textAlign="center">
            {t('report.form.specificity.client.long')}
          </UiText>
        </Block>

        <UiDiv padding="m" background="white" marginTop="m">
          <Text icon={IconCraft}>
            <strong>
              {t('common.fields.craft')}: {craft ? craft.name : t('craft.form.options.default')}
            </strong>
          </Text>
          <UiText variant="copy" marginTop="s">{t('schedule.info.clientSpecificity')}</UiText>
          {clientRoomgroups && (
            <QMAdminConfigRoomgroups
              roomgroups={clientRoomgroups}
              onSave={(arg) => handleOnSave(craftId, arg)}
              configScope={scope}
              pageScope="CLIENT"
              onDelete={() => handleOnDelete(craftId)}
            />
          )}
        </UiDiv>
      </PageContent>
    </>
  );
};

export default QMAdminClientConfig;
