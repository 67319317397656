const IconDelete = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M91.67,17.4H75.13V12.23A12.23,12.23,0,0,0,62.93,0H37.07a12.23,12.23,0,0,0-12.2,12.23V17.4H8.33a4.84,4.84,0,0,0,0,9.67h3.6l5.57,61.8A12.15,12.15,0,0,0,29.67,100H70.33A12.15,12.15,0,0,0,82.5,88.87l5.57-61.8h3.6a4.84,4.84,0,0,0,0-9.67ZM34.3,74.93V42.5a4.84,4.84,0,1,1,9.67,0V74.93a4.84,4.84,0,1,1-9.67,0Zm31.4,0a4.84,4.84,0,1,1-9.67,0V42.5a4.84,4.84,0,1,1,9.67,0ZM34.53,12.23a2.56,2.56,0,0,1,2.54-2.56H62.93a2.56,2.56,0,0,1,2.54,2.56V17.4H34.53Z"
    />
  </svg>
);

export default IconDelete;
