const IconCheck = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M38.29,87.33a5.12,5.12,0,0,1-7.22,0L2.24,58.5a7.66,7.66,0,0,1,0-10.83l3.61-3.61a7.64,7.64,0,0,1,10.83,0l18,18L83.32,13.42a7.66,7.66,0,0,1,10.83,0L97.76,17a7.66,7.66,0,0,1,0,10.83Z"
    />
  </svg>
);

export default IconCheck;
