import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { certificateSchema, fileSchema, jobSchema, publicCertificatesSchema } from '../schemas';

export const createCertificate = (data: CertificateCreateData): RequestEntitiesAction<CertificateData> =>
  requestEntities({
    method: 'POST',
    path: '/certificates',
    data,
    schema: certificateSchema,
  });

export const fetchCertificate = (certificateId: number, params?: any): RequestEntitiesAction<CertificateData> =>
  requestEntities({
    method: 'GET',
    path: `/certificates/${certificateId}`,
    schema: certificateSchema,
    params,
  });

export const updateCertificate = (
  certificateId: number,
  data: CertificateUpdateData
): RequestEntitiesAction<CertificateData> =>
  requestEntities({
    method: 'PUT',
    path: `/certificates/${certificateId}`,
    data,
    schema: certificateSchema,
  });

export const createCertificatePDF = (certificateId: number): RequestEntitiesAction<FileData> => {
  return requestEntities({
    method: 'GET',
    path: `/certificates/${certificateId}/pdf`,
    schema: fileSchema,
  });
};

export const deleteCertificateFile = (
  certificateId: number,
  fileId: number
): RequestEntitiesAction<CertificateData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/certificates/${certificateId}/files/${fileId}`,
    schema: jobSchema,
  });
};

export const uploadCertificateFiles = (offerId: number, data: FormData): RequestEntitiesAction<FileData[]> => {
  return requestEntities({
    method: 'POST',
    path: `/certificates/${offerId}/files`,
    data,
    schema: jobSchema,
  });
};

export const fetchPublicCertificates = (certificateId: number, hash: string, params?: any): RequestEntitiesAction =>
  requestEntities({
    method: 'GET',
    path: `/publiccertificates/${certificateId}/${hash}`,
    schema: publicCertificatesSchema,
    params,
  });

export const acceptPublicCertificates = (
  certificateId: number | undefined,
  hash: string | null | undefined,
  data: FormData
): RequestEntitiesAction =>
  requestEntities({
    method: 'PUT',
    path: `/acceptcertificates/${certificateId}/${hash || '_'}`,
    schema: publicCertificatesSchema,
    data,
  });

export const rejectPublicCertificates = (
  certificateId: number | undefined,
  hash: string | undefined,
  data: FormData
): RequestEntitiesAction =>
  requestEntities({
    method: 'PUT',
    path: `/rejectcertificates/${certificateId}/${hash || '_'}`,
    schema: publicCertificatesSchema,
    data,
  });

const certificatesAdapter = createEntityAdapter<CertificateEntity>();

export const certificatesSlice = createSlice({
  name: 'certificates',
  initialState: certificatesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.certificates) {
        certificatesAdapter.upsertMany(state, action.payload.entities.certificates);
      }
    });
  },
});

export default certificatesSlice.reducer;
