/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
import _forEach from 'lodash/forEach';
import { DATE_FIELDS, MIN_DATE, SERVICE_UNITS } from '../global/constants';
import RestUtil from './RestUtil';

class AppUtil {
    static getBaseUrl() {
        return window.location.origin;
    }

    static sortDataObjects(dataObjects, sortingKey = '', sortingOrder = 'asc') {
        if (!sortingKey) {
            return dataObjects;
        }

        const dataObjectsSorted = [...dataObjects];
        dataObjectsSorted.sort((a, b) => {
            const varA = typeof a[sortingKey] === 'string' ? a[sortingKey].toUpperCase() : a[sortingKey];
            const varB = typeof b[sortingKey] === 'string' ? b[sortingKey].toUpperCase() : b[sortingKey];

            let comparison = 0;
            if (varA === undefined || varA === null) {
                return 1;
            }
            if (varB === undefined || varB === null) {
                return -1;
            }
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return sortingOrder === 'desc' ? comparison * -1 : comparison;
        });

        return dataObjectsSorted;
    }

    static sortModels(models, sortingKey = '', sortingOrder = 'asc') {
        return AppUtil.sortDataObjects(models, sortingKey, sortingOrder);
    }

    static isPasswordValid(password) {
        if (password.length < 8) {
            return false;
        }

        let score = 0;

        if (password.match(/[a-z]/)) {
            score++;
        }

        if (password.match(/[A-Z]/)) {
            score++;
        }

        if (password.match(/\d+/g)) {
            score++;
        }

        if (password.match(/[#?!@$%^&*\\-_]/)) {
            score++;
        }

        if (score < 3) {
            return false;
        }

        return true;
    }

    static isEmailValid(email) {
        if (!email) {
            return false;
        }

        const reValidEmail =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!reValidEmail.test(String(email).toLowerCase())) {
            return false;
        }

        return true;
    }

    static getSelectOptionsBoolean() {
        return [
            {
                value: true,
                label: 'Ja',
            },
            {
                value: false,
                label: 'Nein',
            },
        ];
    }

    static getSelectOptionsVA() {
        return [
            {
                value: 'ZRL',
                label: 'ZRL',
            },
            {
                value: 'ZRP',
                label: 'ZRP',
            },
            {
                value: 'ZAA',
                label: 'ZAA',
            },
            {
                value: 'ZAP',
                label: 'ZAP',
            },
        ];
    }

    static getSelectOptionsServiceUnit() {
        return SERVICE_UNITS.map((unit) => {
            return {
                value: unit,
                label: unit,
            };
        });
    }

    /**
     * Turns a date string formatted as "DD.MM.YY" into a date object
     * @param {string} input the date string
     * @returns {Date} the date
     */
    static toDate(input) {
        if (input) {
            const inputString = `${input}`.trim();
            if (inputString) {
                const match = input.match(/(\d*)\.(\d*)\.(\d*)/);
                if (match && match.length >= 4) {
                    if (parseInt(match[3]) < 90) {
                        match[3] = `20${match[3]}`;
                    }
                    const date = new Date(`${match[3]}-${match[2]}-${match[1]}`);
                    if (date && date >= MIN_DATE) {
                        return date;
                    }
                }
            }
        }
        return null;
    }

    static toDateString(date, setTimezoneOffset) {
        if (date) {
            if (setTimezoneOffset) {
                date.setUTCMinutes(-1 * date.getTimezoneOffset());
            }
            date.setUTCHours(0, 0, 0, 0);
            return date.toISOString().split('T')[0];
        }
        return null;
    }

    static toLocalDateString(date) {
        if (date) {
            const dateObj = typeof date === 'string' ? new Date(date) : date;
            return dateObj.toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }).split(',')[0];
        }
        return '';
    }

    static datesEqual(date1, date2) {
        if (date1 === date2) {
            return true;
        }
        if (date1 && date2) {
            const date1Obj = typeof date1 === 'object' ? date1 : new Date(date1);
            const date2Obj = typeof date2 === 'object' ? date2 : new Date(date2);
            return date1Obj.getTime() === date2Obj.getTime()
        }
        return false;
    }

    static getStatus(object) {
        if (object.isNew) {
            return 'new';
        }
        if (object.isModified) {
            return 'editedNotNew';
        }
        return 'original';
    }

    static getOldProps(object, oldObject) {
        const oldProps = {};
        _forEach(oldObject, (value, key) => {
            if (!key.endsWith('Old')) {
                if (DATE_FIELDS.includes(key)) {
                    // compare as date
                    if (!AppUtil.datesEqual(object[key], value)) {
                        oldProps[`${key}Old`] = value;
                    }
                } else if (typeof value !== 'object' && object[key] !== value) {
                    oldProps[`${key}Old`] = value;
                }
            }
        });
        return oldProps;
    }

    static getEntryId(entryObjectOrId) {
        if (!entryObjectOrId) {
            return null;
        }

        if (typeof entryObjectOrId === 'object') {
            return entryObjectOrId.id;
        }

        if (typeof entryObjectOrId === 'string' || typeof entryObjectOrId === 'number') {
            return entryObjectOrId;
        }

        return null;
    }

    static openDownload(download) {
        if (download && download.url) {
            const url = RestUtil.getDownloadUrl(download.url);
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.click();
            a.remove();
        }
    }

    static filterModels(objects, filter) {
        const filterKeys = Object.keys(filter);
        const entryCounts = {
            new: 0,
            editedNotNew: 0,
            original: 0,
        };

        const objectsFiltered = objects.filter((object) => {
            // count up entries
            const status = AppUtil.getStatus(object);
            // eslint-disable-next-line no-plusplus
            entryCounts[status]++;
            // filter
            return filterKeys.reduce((matches, key) => {
                if (!matches) {
                    return false;
                }
                const filterValue = filter[key];
                let objectValue;
                if (key === 'status') {
                    objectValue = status;
                } else {
                    objectValue = object[key];
                }
                // if filter entry is an array return true if at least one entry  matches
                if (Array.isArray(filterValue)) {
                    return filterValue.findIndex((v) => (!objectValue && !v) || v === objectValue) >= 0;
                }
                // special filter on value properties
                if (typeof filterValue === 'object') {
                    if (filterValue && filterValue.$empty === true) {
                        return objectValue === undefined || (Array.isArray(objectValue) && objectValue.length === 0);
                    }
                    return true;
                }

                return filterValue === null || filterValue === '' || filterValue === objectValue;
            }, true);
        });

        return {
            entryCounts,
            objectsFiltered,
        };
    }
}

export default AppUtil;
