import { Checkbox, InlineSVG, UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import { themeCheckbox } from '../../theme/theme';

interface DashboardJobCardProps {
  onChange?: () => void;
  checked: boolean;
}

const InvoicedCheckbox: React.FC<DashboardJobCardProps> = ({ onChange, checked }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colors } = useTheme();

  const handleOnSelect = onChange
    ? (e) => {
        e.preventDefault();
        onChange();
        setIsOpen(false);
      }
    : undefined;

  return (
    <UiDiv position="relative">
      <UiDiv cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
        <InlineSVG color={colors.ciBlue1} width="14px" height="14px">
          {checked ? IconCheck : IconClose}
        </InlineSVG>
      </UiDiv>
      {isOpen && (
        <UiDiv
          position="absolute"
          background="white"
          border={`2px solid ${colors.ciBlue3}`}
          top="100%"
          left="-25px"
          padding="20px"
          onClick={() => setIsOpen(false)}
        >
          <Checkbox checked={checked} value="" onChange={handleOnSelect} theme={themeCheckbox} />
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default InvoicedCheckbox;
