import React, { useEffect, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import IconCheck from '../../assets/icons-v2/IconCheck';

type CheckboxButtonVariant = 'outline';

const StyledRoot = styled.div({
  display: 'inline-block',
  position: 'relative',
});

const StyledInput = styled.input({
  appearance: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  height: 0,
  width: 0,
  margin: 0,
  padding: 0,
  fontSize: 0,
  lineHeight: 0,
  opacity: 0,
});

interface StyledCheckboxButtonProps {
  variant?: CheckboxButtonVariant;
  styles?: Record<string, unknown>;
}

const StyledCheckboxButton = styled.button<StyledCheckboxButtonProps>(({ variant, styles, theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    display: 'inline-flex',
    width: '30px',
    height: '30px',
    border: `1px solid ${theme.colors.ciBlue1}`,
    padding: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    color: theme.colors.ciBlue1,
    verticalAlign: 'top',
    cursor: 'pointer',
    transition: 'background-color 0.1s ease',
    svg: {
      width: '16px',
      height: '16px',
      verticalAlign: 'top',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
      '&:hover': {
        opacity: 0.5,
      },
    },
    ...styles
  };

  if (variant === 'outline') {
    return {
      ...baseStyle,
      borderColor: 'white',
      background: 'transparent',
      color: 'white',
      '&:hover': {
        background: 'rgba(255,255,255,0.1)',
      },
    };
  }

  return baseStyle;
});

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  variant?: CheckboxButtonVariant;
  styles?: Record<string, unknown>;
}

const Checkbox: React.FC<CheckboxProps> = ({ variant, checked, disabled, onChange, styles, ...props }) => {
  const refInput = useRef<HTMLInputElement>(null);
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <StyledRoot>
      <StyledInput
        ref={refInput}
        type="checkbox"
        checked={isChecked || false}
        disabled={disabled}
        onChange={(e) => {
          setIsChecked(e.target.checked);
          if (onChange) {
            onChange(e);
          }
        }}
        {...props}
      />
      <StyledCheckboxButton
        type="button"
        variant={variant}
        disabled={disabled}
        onClick={() => {
          if (refInput && refInput.current) {
            refInput.current.click();
          }
        }}
        styles={styles}
      >
        {isChecked && IconCheck}
      </StyledCheckboxButton>
    </StyledRoot>
  );
};

export default Checkbox;
