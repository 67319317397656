import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';
import { entitySelectors } from '../entitySelectors';

export const useCheckitemgrouptypeOptions = (): {
  checkitemgrouptypeOptions: SelectOption[];
} => {
  const checkitemgrouptypes = entitySelectors.selectAll<CheckitemgrouptypeEntity>('checkitemgrouptypes');

  const checkitemgrouptypeOptions = useMemo(() => {
    const options: SelectOption[] = [];
    checkitemgrouptypes.forEach((checkitemgrouptype) => {
      if (!checkitemgrouptype.disabled) {
        options.push({
          value: checkitemgrouptype.id,
          label: checkitemgrouptype.name,
        });
      }
    });
    return _orderBy(options, [(option: SelectOption) => option.label], ['asc']);
  }, [checkitemgrouptypes]);

  return {
    checkitemgrouptypeOptions,
  };
};
