import axios from 'axios';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';
import ClientsUtil from '../utils/ClientsUtil';
import { getAPIParams } from '../utils/APIParamsUtil';

export function fetchClientLegacy(clientId, params, skipDispatch, path = 'clients') {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_CLIENT,
    });

    const { paramString } = getAPIParams(params, true);
    return axios({
      method: 'get',
      url: `${RestUtil.getStrapiUrl()}/${path}/${clientId}${paramString}`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const client = res.data || {};
        if (!skipDispatch) {
          dispatch({
            type: REDUX_ACTIONS.RECEIVE_CLIENT,
            client,
          });
        }
        return client;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_CLIENT_ERROR,
        });
        throw error;
      });
  };
}

export function createClientLegacy(data, path = 'clients') {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_CREATE_CLIENT,
    });

    return axios({
      method: 'post',
      url: `${RestUtil.getStrapiUrl()}/${path}`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const client = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_CREATE_CLIENT,
          client,
        });
        return [client];
      })
      .catch(() => {
        // console.log('#createClientLegacy error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_CREATE_CLIENT_ERROR,
        });
      });
  };
}

export function updateClientLegacy(clientId, data, path = 'clients') {
  return async (dispatch, getState) => {
    const {
      dataState: { clients, addresses },
      authState,
    } = getState();

    const oldClient = clients ? clients[clientId] : {};
    let oldAddress;
    if (oldClient.addressId && addresses[oldClient.addressId]) {
      oldAddress = addresses[oldClient.addressId];
    }

    dispatch({
      type: REDUX_ACTIONS.REQUEST_UPDATE_CLIENT,
    });

    return axios({
      method: 'put',
      url: `${RestUtil.getStrapiUrl()}/${path}/${clientId}`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const client = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_UPDATE_CLIENT,
          client,
          oldClient,
          oldAddress,
        });
        return client;
      })
      .catch((error) => {
        // console.log('#updateClientLegacy error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_UPDATE_CLIENT_ERROR,
        });
        throw error;
      });
  };
}

export function deleteClientLegacy(clientId, path = 'clients') {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_CLIENT,
    });

    return axios({
      method: 'delete',
      url: `${RestUtil.getStrapiUrl()}/${path}/${clientId}`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const client = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_CLIENT,
          client,
        });
        return [client];
      })
      .catch((error) => {
        // console.log('#deleteClientLegacy error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_CLIENT_ERROR,
        });
        throw error;
      });
  };
}

export function fetchClientsLegacy(params = { _limit: -1 }) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_CLIENTS,
    });


    const { paramString } = getAPIParams(params, true);
    return axios({
      method: 'get',
      url: `${RestUtil.getStrapiUrl()}/clients${paramString}`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const clients = res.data?.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_CLIENTS,
          clients,
        });
        return clients;
      })
      .catch((error) => {
        // console.log('#fetchClientsLegacy error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_CLIENTS_ERROR,
        });
        throw error;
      });
  };
}

export const deleteAllClients = (clientsIds, path = 'clients') => {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_CLIENT,
    });

    return Promise.all(
      clientsIds.map((clientId) => {
        return axios({
          method: 'delete',
          url: `${RestUtil.getStrapiUrl()}/${path}/${clientId}`,
          headers: {
            Authorization: `Bearer ${authState.jwt}`,
          },
        });
      })
    )
      .then((res) => {
        const clients = (res || [{}]).map(({ data }) => data);
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_ALL_CLIENTS,
          clients,
        });
        return clients;
      })
      .catch((error) => {
        // console.log('#deleteClientLegacy error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_CLIENT_ERROR,
        });
        throw error;
      });
  };
};
export const createClientGroup = (data) =>
  createClientLegacy({ ...data, populate: ClientsUtil.baseViewClientGroupsPopulate }, 'clientGroups');
export const updateClientGroup = (clientId, data) =>
  updateClientLegacy(clientId, { ...data, populate: ClientsUtil.baseViewClientGroupsPopulate }, 'clientGroups');
export const deleteClientGroup = (clientId) => deleteClientLegacy(clientId, 'clientGroups');
export const fetchClientGroup = (clientId, params, skipDispatch) =>
  fetchClientLegacy(clientId, params, skipDispatch, 'clientGroups');
export const deleteAllClientGroups = (clientsIds) => deleteAllClients(clientsIds, 'clientGroups');
