import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

interface ContentContextInterface {
  dlaInfo: string[];
  maintenance: ContentSection[][];
  imprint: ContentSection[];
  privacy: ContentSection[];
}

const emptyContent = {
  dlaInfo: [],
  maintenance: [],
  imprint: [],
  privacy: [],
};

const ns = process.env.REACT_APP_I18N_NAMESPACE;

export const ContentContext = React.createContext<ContentContextInterface>({} as ContentContextInterface);

export const useContentContext = () => {
  return useContext(ContentContext);
};

const ContentProvider: React.FC = ({ children }) => {
  const [content, setContent] = useState<ContentContextInterface>(emptyContent);

  useEffect(() => {
    axios.get(`/content/${ns}.json`).then((res) => {
      if (res?.data) {
        setContent(res.data);
      }
    });
  }, []);

  return <ContentContext.Provider value={content}>{children}</ContentContext.Provider>;
};

export default ContentProvider;
