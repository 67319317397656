import { entitySelectors } from '../../app/entitySelectors';

export const useQMAdminSchedule = (
  scheduleId: number | undefined
): {
  schedule: ScheduleEntity | undefined;
  users: UserEntity[];
  contacts: ContactEntity[];
  craft: CraftEntity | undefined;
} => {
  const schedule = entitySelectors.selectById<ScheduleEntity>('schedules', scheduleId);
  const users = entitySelectors.selectByIds<UserEntity>('users', schedule?.userIds);
  const contacts = entitySelectors.selectByIds<ContactEntity>('contacts', schedule?.contactIds);
  const craft = entitySelectors.selectById<CraftEntity>('crafts', schedule?.craftId);

  return {
    schedule,
    users,
    contacts,
    craft,
  };
};
