import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';
import { entitySelectors } from '../../../app/entitySelectors';

export const useBaseDataUserForm = (): {
  roleOptions: SelectOption[];
} => {
  const roles = entitySelectors.selectAll<RoleEntity>('roles');

  const roleOptions = useMemo(() => {
    const options: SelectOption[] = [];
    roles.forEach((role) => {
      if (role.type !== 'authenticated' && role.type !== 'public') {
        options.push({
          value: role.id,
          label: role.name,
        });
      }
    });
    return _orderBy(options, [(option: SelectOption) => option.label], ['asc']);
  }, [roles]);

  return {
    roleOptions,
  };
};
