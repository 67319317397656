import { UiDiv } from '@webfox-sc/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddress } from '../../app/entityHooks/useAddress';
import { useJob } from '../../app/entityHooks/useJob';
import { useRecipient } from '../../app/entityHooks/useRecipient';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconEdit from '../../assets/icons-v2/IconEdit';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import Button from '../atoms-v2/Button';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import FormPanel from '../atoms-v2/FormPanel';
import FormRowGroup from '../atoms-v2/FormRowGroup';
import TextBox from '../atoms-v2/TextBox';
import TextInput from '../atoms-v2/TextInput';


interface JobAddressEditorProps {
  jobId?: number | undefined;
  isJobEditMode?: boolean;
  isEditMode?: boolean;
  onUpdate?: (updateData: any) => void;
}

const JobAddressEditor: React.FC<JobAddressEditorProps> = ({ jobId, isJobEditMode, isEditMode, onUpdate }) => {
  const { t } = useTranslation();

  const { job } = useJob(jobId);
  const { recipient } = useRecipient(job?.recipientId);
  const { address: addressDraft } = useAddress(recipient?.addressId);

  const [currentIsEditMode, setCurrentIsEditMode] = useState(isEditMode);
  const [currentIsEditModeAllowed, setCurrentIsEditModeAllowed] = useState(isJobEditMode);

  const [address, setAddress] = useState<AddressEntity>(addressDraft || { id: -1 });
  const [carrierDescription, setCarrierDescription] = useState<string>(recipient?.carrierDescription || '');
  const [clientDescription, setClientDescription] = useState<string>(recipient?.clientDescription || '');

  const resetData = useCallback(() => {
    if (addressDraft) {
      setAddress(addressDraft);
    }
    setCarrierDescription(recipient?.carrierDescription || '');
    setClientDescription(recipient?.clientDescription || '');
  }, [addressDraft, recipient?.carrierDescription, recipient?.clientDescription]);

  useEffect(() => {
    if (addressDraft) {
      resetData();
    }
  }, [addressDraft, resetData]);

  useEffect(() => {
    if (isJobEditMode !== currentIsEditModeAllowed) {
      setCurrentIsEditModeAllowed(isJobEditMode);
      if (!isJobEditMode) {
        resetData();
        setCurrentIsEditMode(false);
      }
    }
  }, [currentIsEditModeAllowed, isJobEditMode, resetData]);

  const handleUpdateAddress = (key, value) => {
    setAddress({ ...address, [key]: value });
  };

  const handleOnClickUpdate = async () => {
    if (onUpdate) {
      const updateData = {
        clientDescription,
        carrierDescription,
        address,
      };
      onUpdate(updateData);
    }

    setCurrentIsEditMode(false);
  };

  const handleClickEdit = () => {
    setCurrentIsEditMode(true);
  };

  const handleClickCancel = () => {
    resetData();
    setCurrentIsEditMode(false);
  };

  const isClientDataValid = (): boolean => {
    return true;
  };

  let canEditAddress = true;
  if (job) {
    canEditAddress =
      (!job.status || ['Erstellt', 'LN-erstellt', 'LN-ausstehend'].includes(job.status)) &&
      (!job.offerStatus || ['A-erstellt', 'A-ausstehend' ].includes(job.offerStatus));
  }
  const isEditing = currentIsEditMode && canEditAddress;

  return (
    <>
      <FormPanel>
        <FormRowGroup>
          <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} columnGap="xxs">
            <FormElementLabel order={{ tablet: 0 }}>{t('common.fields.clientDescription.long')}</FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 2 }}>
                <TextInput value={clientDescription} onChange={(e) => setClientDescription(e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 2 }} background="grey20" color="ciBlue1">
                {clientDescription || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 1 }}>
              {t('common.fields.carrierDescription.long')}
            </FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 3 }}>
                <TextInput value={carrierDescription} onChange={(e) => setCarrierDescription(e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
                {carrierDescription || ''}
              </TextBox>
            )}
          </UiDiv>
          <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} columnGap="xxs">
            <FormElementLabel order={{ tablet: 1 }}>{t('common.address.line1')}</FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 3 }}>
                <TextInput value={address.line1} onChange={(e) => handleUpdateAddress('line1', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
                {address.line1 || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 2 }}>
              {t('common.address.line2')}
            </FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 4 }}>
                <TextInput value={address.line2} onChange={(e) => handleUpdateAddress('line2', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1">
                {address.line2 || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px' }} order={{ tablet: 5 }}>
              {t('common.address.line3')}
            </FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 7 }}>
                <TextInput value={address.line3} onChange={(e) => handleUpdateAddress('line3', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 7 }} background="grey20" color="ciBlue1">
                {address.line3 || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px' }} order={{ tablet: 6 }}>
              {t('common.address.line4')}
            </FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 8 }}>
                <TextInput value={address.line4} onChange={(e) => handleUpdateAddress('line4', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 8 }} background="grey20" color="ciBlue1">
                {address.line4 || ''}
              </TextBox>
            )}
          </UiDiv>
          <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr' }} columnGap="xxs">
            <FormElementLabel>{t('common.address.street')}</FormElementLabel>
            {isEditing ? (
              <UiDiv>
                <TextInput value={address.street} onChange={(e) => handleUpdateAddress('street', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox background="grey20" color="ciBlue1">{address.street || ''}</TextBox>
            )}
          </UiDiv>
          <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 2fr' }} columnGap="xxs">
            <FormElementLabel order={{ tablet: 1 }}>{t('common.address.zip')}</FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 3 }}>
                <TextInput value={address.zip} onChange={(e) => handleUpdateAddress('zip', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
                {address.zip || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 2 }}>
              {t('common.address.city')}
            </FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 4 }}>
                <TextInput value={address.city} onChange={(e) => handleUpdateAddress('city', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1">
                {address.city || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px' }} order={{ tablet: 5 }}>
              {t('common.address.zipPo')}
            </FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 7 }}>
                <TextInput value={address.zip_po} onChange={(e) => handleUpdateAddress('zip_po', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 7 }} background="grey20" color="ciBlue1">
                {address.zip_po || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px' }} order={{ tablet: 6 }}>
              {t('common.address.po')}
            </FormElementLabel>
            {isEditing ? (
              <UiDiv order={{ tablet: 8 }}>
                <TextInput value={address.po} onChange={(e) => handleUpdateAddress('po', e.target.value)} />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 8 }} background="grey20" color="ciBlue1">
                {address.po || ''}
              </TextBox>
            )}
          </UiDiv>
        </FormRowGroup>

        {currentIsEditMode && (
          <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="m">
            <Button icon={IconCheck} disabled={!isClientDataValid()} onClick={handleOnClickUpdate}>
              {t('common.actions.apply')}
            </Button>
            {recipient && (
              <Button variant="secondary" icon={IconClose} onClick={handleClickCancel}>
                {t('common.actions.cancel')}
              </Button>
            )}
          </UiDiv>
        )}

        {currentIsEditModeAllowed && canEditAddress && !currentIsEditMode && (
          <UiDiv display="flex" justifyContent="flex-end" paddingTop="s">
            <ButtonSecondary icon={IconEdit} ariaLabel="Edit" onClick={handleClickEdit} />
          </UiDiv>
        )}
      </FormPanel>
    </>
  );
};

export default JobAddressEditor;
