import { Collapse, UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../../assets/icons-v2/IconArrowUp';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import QMUtil from '../../../utils/QMUtil';
import Button from '../../atoms-v2/Button';
import Checkbox from '../../atoms-v2/Checkbox';
import TextButton from '../../atoms-v2/TextButton';

import QMAdminConfigCheckitemgroup from './QMAdminConfigCheckitemgroup';
import QMAdminConfigCheckitemgroupCreateForm from './QMAdminConfigCheckitemgroupCreateForm';
import QMAdminConfigEditableLabel from './QMAdminConfigEditableLabel';

type QMAdminConfigRoomgroupProps = {
  roomgroup: RoomgroupNestedEntity;
  clickHandler: (path: (string | number)[]) => void;
  updateHandler: (path: (string | number)[], value: any) => void;
};

const QMAdminConfigRoomgroup: React.FC<QMAdminConfigRoomgroupProps> = ({ roomgroup, clickHandler, updateHandler }) => {
  const { t } = useTranslation();
  
  const [isVisible, setIsVisible] = useState(true);
  const [showCreateCheckitemgroupForm, setShowCreateCheckitemgroupForm] = useState(false);

  if (!roomgroup) {
    return null;
  }

  const handleOnAdd = (checkitemgrouptype: number) => {
    const newPath = [...roomgroup.path, 'checkitemgroups', roomgroup.checkitemgroups.length];
    updateHandler(newPath, QMUtil.getEmptyNestedCheckitemgroup({ checkitemgrouptypeId: checkitemgrouptype, roomgroupId: roomgroup.id, path: newPath }));
  };

  let subLabel: string | undefined = undefined;
  if (!roomgroup.showDelete) {
    subLabel = roomgroup.source === 'GLOBAL' ? `(${t('report.form.specificity.default.short')})` : `(${t('report.form.specificity.company.short')})`;
  }

  return (
    <UiDiv padding="s" background="grey20">
      <UiDiv display="flex" columnGap="xs" alignItems="center">
        <UiDiv display="flex" flexGrow={1} gap="xs" alignItems="center">
          <Checkbox checked={!roomgroup.disabled} onChange={() => clickHandler(roomgroup.path)} />
          <UiDiv flexGrow={1}>
            <QMAdminConfigEditableLabel
              label={roomgroup.name || ''}
              subLabel={subLabel}
              onSave={(value) => updateHandler([...roomgroup.path, 'name'], value)}
              onDelete={roomgroup.showDelete ? () => updateHandler(roomgroup.path, null) : undefined}
            />
          </UiDiv>
        </UiDiv>
        <Button
          variant={isVisible ? 'primary' : 'secondary'}
          icon={isVisible ? IconArrowUp : IconArrowDown}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          {roomgroup.checkitemCount || 0} {roomgroup.checkitemCount === 1 ? t('common.entities.checkitem.singular') : t('common.entities.checkitem.plural')}
        </Button>
      </UiDiv>
      {isVisible && (
        <UiDiv paddingLeft="40px">
          {roomgroup.checkitemgroups.length > 0 && (
            <UiDiv display="flex" flexDirection="column" rowGap="m" marginTop="s">
              {roomgroup.checkitemgroups.map((checkitemgroup, index) => (
                <QMAdminConfigCheckitemgroup
                  key={checkitemgroup.id || index}
                  checkitemgroup={checkitemgroup}
                  clickHandler={clickHandler}
                  updateHandler={updateHandler}
                />
              ))}
            </UiDiv>
          )}

          <UiDiv marginTop="s">
            <TextButton
              icon={IconPlus}
              onClick={(event) => {
                event.preventDefault();
                setShowCreateCheckitemgroupForm(!showCreateCheckitemgroupForm);
              }}
            >
              {t('roomgroup.actions.addCustomCheckitemgroup')}
            </TextButton>
            <Collapse show={showCreateCheckitemgroupForm} fadeInOut keepChildren={false}>
              <UiDiv paddingTop="xs">
                <UiDiv padding="s" background="white">
                  <QMAdminConfigCheckitemgroupCreateForm
                    onClickSave={(checkitemgrouptype) => {
                      handleOnAdd(checkitemgrouptype);
                      setShowCreateCheckitemgroupForm(false);
                    }}
                    onClickCancel={() => setShowCreateCheckitemgroupForm(false)}
                  />
                </UiDiv>
              </UiDiv>
            </Collapse>
          </UiDiv>
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default QMAdminConfigRoomgroup;
