import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const limitInterval = 10;

export type QMFilterStatus = '' | 'open' | 'pending' | 'finished';
export type QMListEntryType = 'report' | 'schedule';

export interface QMListEntry {
  id: number,
  type: QMListEntryType,
  compareValue: string | number
}
export interface QMListState {
  entries?: QMListEntry[]
}

export interface QMPaginationState {
  viewLimit?: number;
  viewCount?: number;
  viewStart?: number;
  reportCount?: number;
  reportTotal?: number;
  scheduleCount?: number;
  scheduleTotal?: number;
  scheduleReportTotal?: number;
  shouldFetchReports?: boolean;
  shouldFetchSchedules?: boolean;
  isDirty?: boolean;
  hasMore?: boolean;
}

export interface QMFilterState {
  clientId?: number | null;
  costcenterId?: number | null;
  costcenterClientIds?: number[] | null;
  costcenterNumber?: string;
  companyId?: number | null;
  companyClientIds?: number[] | null;
  companyNumber?: string;
  locationId?: number | null;
  locationClientIds?: number[] | null;
  locationNumber?: string;
  status?: QMFilterStatus;
  craftId?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  orderBy?: string;
  userId?: number | null;
}

export interface QMViewState {
  showFilter?: boolean;
}

export interface QMQueryState extends QMPaginationState, QMFilterState, QMViewState {}
export interface QMResultState extends QMListState, QMPaginationState {}
interface QMOverviewStateIF extends QMPaginationState, QMListState, QMFilterState, QMViewState {}

export type QMOverviewState = {
  [Property in keyof QMOverviewStateIF]-?: QMOverviewStateIF[Property];
};

const initialState: QMOverviewState = {
  clientId: null,
  costcenterId: null,
  costcenterClientIds: null,
  costcenterNumber: '',
  companyId: null,
  companyClientIds: null,
  companyNumber: '',
  locationId: null,
  locationClientIds: null,
  locationNumber: '',
  craftId: null,
  status: '',
  startDate: null,
  endDate: null,
  orderBy: 'date:desc',
  userId: null,
  showFilter: false,
  entries: [],
  viewLimit: limitInterval,
  viewCount: 0,
  viewStart: 0,
  reportCount: 0,
  reportTotal: 0,
  scheduleCount: 0,
  scheduleTotal: 0,
  scheduleReportTotal: 0,
  shouldFetchReports: true,
  shouldFetchSchedules: true,
  isDirty: false,
  hasMore: false,
};

export const qmOverviewSlice = createSlice({
  name: 'qmOverview',
  initialState,
  reducers: {
    setQMViewState: (state: QMOverviewState, action: PayloadAction<QMViewState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setQMFilterState: (state: QMOverviewState, action: PayloadAction<QMFilterState>) => {
      let { shouldFetchReports, shouldFetchSchedules } = state;
      // determine collections to fetch
      if (action.payload.status !== undefined) {
        if (action.payload.status === '') {
          shouldFetchReports = true;
          shouldFetchSchedules = true;
        } else if (action.payload.status === 'open') {
          shouldFetchReports = false;
          shouldFetchSchedules = true;
        } else {
          shouldFetchReports = true;
          shouldFetchSchedules = false;
        }
      }

      return {
        ...state,
        ...action.payload,
        shouldFetchReports,
        shouldFetchSchedules,
        reportCount: 0,
        scheduleCount: 0,
        viewStart: 0,
        hasMore: false,
        isDirty: true,
      };
    },
    setQMResultState: (state: QMOverviewState, action: PayloadAction<QMResultState>) => {
      return {
        ...state,
        ...action.payload,
        isDirty: false,
      };
    },
  },
});

export const { setQMResultState, setQMFilterState, setQMViewState } = qmOverviewSlice.actions;
export const selectQMResultState = (state: RootState): QMResultState => state.qmOverviewState;
export const selectQMOverviewState = (state: RootState): QMOverviewState => state.qmOverviewState;
export default qmOverviewSlice.reducer;
