/* xeslint-disable */
import axios from 'axios';
import RestUtil from './RestUtil';

class UserUtil {
  static async sendForgotPassword(email) {
    try {
      const response = await axios.post(RestUtil.getStrapiUrl() + '/auth/forgot-password', {
        email,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async sendResetPassword(code, password, passwordConfirmation) {
    try {
      const response = await axios.post(RestUtil.getStrapiUrl() + '/auth/reset-password', {
        code,
        password,
        passwordConfirmation,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default UserUtil;
