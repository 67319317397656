import { UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useReport } from '../../app/entityHooks/useReport';
import { pathToQMReport } from '../../app/paths';
import IconQuality from '../../assets/icons-v2/IconQuality';
import DateUtil from '../../utils/DateUtil';
import ScheduleUtil from '../../utils/ScheduleUtil';
import Button from '../atoms-v2/Button';
import ResultBubble from '../atoms-v2/ResultBubble';

interface QMReportTeaserProps {
  reportId: number | undefined;
}

const QMReportTeaser: React.FC<QMReportTeaserProps> = ({ reportId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { report, reporter, craft } = useReport(reportId);

  if (!report) {
    return null;
  }

  const isFinished = report.status === 'finished';

  return (
    <UiDiv display="flex" flexDirection="column" rowGap="1px">
      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '200px 3fr 1fr 100px' }}
        rowGap="xs"
        columnGap="s"
        background="white"
        padding="15px"
        minHeight="40px"
      >
        <UiDiv>
          <UiText variant="copySmall">{report.companyName}</UiText>
        </UiDiv>
        <UiDiv>
          <UiText variant="copySmall">{report.locationDescription}</UiText>
        </UiDiv>
        <UiDiv>
          <UiText variant="copySmall">{craft?.name || t('craft.form.options.default')}</UiText>
        </UiDiv>
        <UiDiv>
          {isFinished && report.scorePercentage !== undefined && report.scorePercentage !== null && (
            <ResultBubble result={report.scorePercentage} />
          )}
        </UiDiv>
      </UiDiv>
      <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 260px' }} rowGap="1px" columnGap="1px">
        <UiDiv
          display="grid"
          gridTemplateColumns={{ mobile: '1fr', tablet: '200px 1fr 1fr' }}
          rowGap="xs"
          columnGap="s"
          background="ciBlue5"
          padding="5px 15px"
        >
          {report.isAdHoc ? (
            <UiDiv columnGap="xs" display="flex" alignItems="center">
              <UiText variant="copySmall">
                <strong>{t('report.form.adHoc.true')}</strong>
              </UiText>
            </UiDiv>
          ) : (
            <UiDiv columnGap="xs">
              <UiText variant="copySmall">
                <strong>{t('report.dueDateTeaser')}</strong>
              </UiText>
              <UiText variant="copySmall">
                {ScheduleUtil.getScheduleDateFormatted(
                  report?.dueDate ? new Date(report.dueDate) : undefined,
                  report?.interval,
                  t
                )}
              </UiText>
            </UiDiv>
          )}
          <UiDiv columnGap="xs">
            <UiText variant="copySmall">
              <strong>{t('report.date')}</strong>
            </UiText>
            <UiText variant="copySmall">{DateUtil.getDateFormatted(report.date)}</UiText>
          </UiDiv>
          <UiDiv columnGap="xs">
            <UiText variant="copySmall">
              <strong>{t('common.fields.reporter')}</strong>
            </UiText>
            <UiText variant="copySmall">{reporter?.username}</UiText>
          </UiDiv>
        </UiDiv>
        <UiDiv>
          <Button
            variant={isFinished ? 'grey' : 'yellow'}
            icon={IconQuality}
            onClick={() => navigate(pathToQMReport(report.id, `${pathname}${search}`))}
            minWidth="260px"
          >
            {isFinished ? t('report.actions.show') : t('report.actions.continue')}
          </Button>
        </UiDiv>
      </UiDiv>
    </UiDiv>
  );
};

export default QMReportTeaser;
