import React from 'react';
import { UiDiv, UiDivProps } from '@webfox-sc/core';

const FormPanel: React.FC<UiDivProps> = ({ children, ...props }) => {
  return (
    <UiDiv padding={{ mobile: '15px', tablet: '30px' }} background="white" {...props}>
      {children}
    </UiDiv>
  );
};

export default FormPanel;
