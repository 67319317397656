import { useMemo } from 'react';

import { entitySelectors } from '../../app/entitySelectors';

type ClientSelectOption = {
  value: number;
  label: string;
};

export const useQMAdminCompany = (
  companyId: number | undefined
): {
  company: CompanyEntity | undefined;
  clients: ClientEntity[];
  clientsWithSchedule: ClientEntity[];
  clientsWithoutSchedule: ClientEntity[];
  clientsWithoutScheduleOptions: ClientSelectOption[];
  schedules: ScheduleEntity[];
  specificityByCraft: Record<string, SpecificityEntry>;
} => {
  const company = entitySelectors.selectById<CompanyEntity>('companies', companyId);
  const clients = entitySelectors.selectByFilter<ClientEntity>('clients', (client) => client.companyId === company?.id);
  const roomgroups = entitySelectors.selectByFilter<RoomgroupEntity>('roomgroups', (roomgroup) => roomgroup.companyId === companyId);
  const allSchedules = entitySelectors.selectAll<ScheduleEntity>('schedules');
  const locationEntities = entitySelectors.selectEntities<LocationEntity>('locations');
  const costcenterEntities = entitySelectors.selectEntities<CostcenterEntity>('costcenters');

  const schedules = useMemo(() => {
    return allSchedules.filter((schedule) => {
      const scheduleClient = clients.find((client) => client.id === schedule.clientId);
      return !!scheduleClient;
    });
  }, [clients, allSchedules]);

  const clientsWithSchedule = useMemo(() => {
    return clients.filter((client) => {
      const clientSchedule = schedules.find((schedule) => schedule.clientId === client.id);
      return !!clientSchedule;
    });
  }, [clients, schedules]);

  const clientsWithoutSchedule = useMemo(() => {
    return clients.filter((n) => !clientsWithSchedule.includes(n));
  }, [clients, clientsWithSchedule]);

  const clientsWithoutScheduleOptions: ClientSelectOption[] = useMemo(() => {
    return clientsWithoutSchedule.map((client) => {
      const location = locationEntities[client.locationId || ''];
      const costcenter = costcenterEntities[client.costcenterId || ''];
      return {
        value: client.id,
        label: `${location?.description} (Arbeitsplatz ${costcenter?.number})`,
      };
    });
  }, [clientsWithoutSchedule, locationEntities, costcenterEntities]);

  const specificityByCraft: Record<string, SpecificityEntry> = useMemo(() => {
    const craftMap = {};
    if (roomgroups?.length > 0) {
      roomgroups.forEach(({ craftId }) => {
        const key = `${craftId || 0}`;
        if (!craftMap[key]) {
          craftMap[key] = {
            craftId: craftId || 0,
            clientId: undefined,
            companyId: company?.id,
          };
        }
      });
    }
    // add all entries for default and all enabled crafts if not existing
    [0, ...(company?.craftIds || [])].forEach((craftId) => {
      const key = `${craftId || 0}`;
      if (!craftMap[key]) {
        craftMap[key] = {
          craftId: craftId || 0,
          clientId: undefined,
          companyId: undefined,
        };
      }
    });
    return craftMap;
  }, [company, roomgroups]);

  return {
    company,
    clients,
    clientsWithSchedule,
    clientsWithoutSchedule,
    clientsWithoutScheduleOptions,
    specificityByCraft,
    schedules,
  };
};
