const IconEdit = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M61.76,16.75l-55,55a2.31,2.31,0,0,0-.57,1L.06,97.26A2.17,2.17,0,0,0,2.16,100a1.88,1.88,0,0,0,.53-.07l24.48-6.1a2.1,2.1,0,0,0,1-.57l55-55Zm35.07-7.5L90.7,3.12a11.09,11.09,0,0,0-15.31,0l-7.5,7.5L89.33,32.06l7.5-7.5a10.83,10.83,0,0,0,0-15.31Z"
    />
  </svg>
);

export default IconEdit;
