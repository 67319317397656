import React from 'react';
import { useTranslation } from 'react-i18next';

const QMAdminConfigSpecificityLabel: React.FC<SpecificityEntry> = ({ clientId, companyId }) => {
  const { t } = useTranslation();

  if (clientId) {
    return <>{t('report.form.specificity.client.short')}</>;
  }
  if (companyId) {
    return <>{t('report.form.specificity.company.short')}</>;
  }
  return <>{t('report.form.specificity.default.long')}</>;
};

export default QMAdminConfigSpecificityLabel;
