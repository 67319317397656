import { useAppDispatch } from '../../app/appHooks';
import { useJob } from '../../app/entityHooks/useJob';
import { updateJob } from '../../app/slices/jobsSlice';

export const useJobRecipient = (
  jobId: number | undefined
): {
  handleUpdateJobRecipient: (jobRecipientEditorData: any) => Promise<boolean>;
  handleUpdateJobAddress: (jobAddressEditorData: any) => Promise<boolean>;
} => {
  const dispatch = useAppDispatch();
  const { job, recipient: jobRecipient } = useJob(jobId);

  const handleUpdateJobRecipient = async (jobRecipientEditorData: any) => {
    if (!job || !jobRecipient) {
      return false;
    }

    const {
      clientId,
      debitorId,
      costcenter,
      carrierNumber,
      carrierDescription,
      clientNumber,
      clientDescription,
      address,
      noTax,
      contacts,
    } = jobRecipientEditorData;

    const firstContact = contacts[0];

    // -- update job
    // -- remove services if carrier or client has changed
    const jobUpdateData: JobUpdateData = {
      client_id: clientId,
      client_name: clientDescription,
      carrier_number: carrierNumber,
      costcenter,
      contact_person_name: firstContact ? firstContact.name : '',
      contact_person_email: firstContact ? firstContact.email : '',
      no_tax: noTax,
      recipient: {
        id: jobRecipient.id,
        client_id: clientId,
        debitor_id: debitorId,
        costcenter,
        carrier_number: carrierNumber,
        carrier_description: carrierDescription,
        client_number: clientNumber,
        client_description: clientDescription,
        address,
        contacts,
      }
    };

    if (carrierNumber !== jobRecipient?.carrierNumber || clientNumber !== jobRecipient?.clientNumber) {
      jobUpdateData.services = [];
    }

    await dispatch(updateJob(job.id, jobUpdateData));

    return true;
  };

  const handleUpdateJobAddress = async (jobRecipientEditorData: any) => {
    if (!job || !jobRecipient) {
      return false;
    }

    const {
      carrierDescription,
      clientDescription,
      address,
    } = jobRecipientEditorData;

    // -- update job
    // -- remove services if carrier or client has changed
    const jobUpdateData: JobUpdateData = {
      client_name: clientDescription,
      recipient: {
        id: jobRecipient.id,
        carrier_description: carrierDescription,
        client_description: clientDescription,
        address,
      }
    };

    await dispatch(updateJob(job.id, jobUpdateData));

    return true;
  };

  return {
    handleUpdateJobRecipient,
    handleUpdateJobAddress,
  };
};
