import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UiDiv, UiFlex } from '@webfox-sc/core';

import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { limitInterval, selectJobListState, setJobListState } from '../../app/slices/jobListSlice';
import { fetchJobs, selectJobsState } from '../../app/slices/jobsSlice';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import IconNotFound from '../../assets/icons-v2/IconNotFound';
import IconPlus from '../../assets/icons-v2/IconPlus';
import Button from '../atoms-v2/Button';
import LoadingWrapper from '../atoms-v2/LoadingWrapper';
import NoResultsNotice from '../atoms-v2/NoResultsNotice';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';

import JobCardWrapper from './JobCardWrapper';
import JobsFilters from './JobsFilters';
import JobsLegend from './JobsLegend';
import JobsSorting from './JobsSorting';
import { useJobsSorting } from './useJobsSorting';

interface JobsProps {
  viewMode?: 'archive';
}

const Jobs: React.FC<JobsProps> = ({ viewMode }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { fetchJobsStatus } = useAppSelector(selectJobsState);

  const { selectedCostcenterNumber, selectedCompanyName, selectedStatus, searchValue, limit, jobIds, jobsCount } =
    useAppSelector(selectJobListState);

  const { selectedSortingOption } = useJobsSorting();

  useEffect(() => {
    const fetchJobsData = async () => {
      const params: {
        [key: string]: any;
      } = {
        costcenter: selectedCostcenterNumber || undefined,
        client_name: selectedCompanyName || undefined,
        _limit: limit,
        _start: 0,
        _sort: `${selectedSortingOption?.sortingKey}:${String(selectedSortingOption?.sortingOrder).toUpperCase()}`,
      };

      if (viewMode === 'archive') {
        params.is_archive = true;
      } else {
        params.filters = [
          {
            $or: [
              {
                is_archive: { $ne: true },
              },
              {
                is_archive: { $null: true },
              },
            ],
          },
        ];
      }

      if (selectedStatus?.startsWith('LN-')) {
        params.status = selectedStatus;
      } else if (selectedStatus?.startsWith('A-')) {
        params.offer_status = selectedStatus;
      } else if (selectedStatus === 'Erstellt') {
        params.status_notContains = 'LN-';
        params.offer_status_notContains = 'A-';
      }

      if (searchValue) {
        params.filters = [
          ...(params.filters || []),
          {
            $or: [
              {
                entry_id: {
                  $containsi: searchValue,
                },
              },
              {
                client_name: {
                  $containsi: searchValue,
                },
              },
              {
                carrier_number: {
                  $containsi: searchValue,
                },
              },
            ],
          },
        ];
      }

      const res = await dispatch(fetchJobs(params, true));
      const receivedJobIds = (res.payload?.data || []).map((jobData) => jobData.id);

      dispatch(
        setJobListState({
          jobIds: receivedJobIds,
          jobsCount: res.payload?.count || 0,
        })
      );
    };
    fetchJobsData();
  }, [
    dispatch,
    viewMode,
    selectedCostcenterNumber,
    selectedCompanyName,
    selectedStatus,
    searchValue,
    selectedSortingOption,
    limit,
  ]);

  return (
    <>
      {viewMode === 'archive' ? (
        <PageHeading heading={t('job.headline.archive') || undefined} />
      ) : (
        <PageHeading
          heading={t('job.headline.myJobs') || undefined}
          buttonLabel={t('job.actions.create') || undefined}
          buttonLabelMobile={t('common.actions.create') || undefined}
          buttonIcon={IconPlus}
          onClickButton={() => navigate('/addjob')}
        />
      )}

      <PageContent marginTop="m">
        <UiDiv>
          <JobsFilters />
        </UiDiv>

        <UiDiv marginTop="s">
          <JobsLegend />
        </UiDiv>

        <UiDiv marginTop="l">
          <JobsSorting />
        </UiDiv>

        <LoadingWrapper isLoading={fetchJobsStatus === 'loading' && (jobIds || []).length === 0} marginTop="m">
          {(jobIds || []).length === 0 ? (
            <NoResultsNotice icon={IconNotFound}>{t('common.messages.noJobsFound')}</NoResultsNotice>
          ) : (
            <>
              <UiFlex flexDirection="column" rowGap="xs" marginTop="m">
                {(jobIds || []).map((jobId) => {
                  return <JobCardWrapper key={jobId} jobId={jobId} sortingKey={selectedSortingOption?.sortingKey} />;
                })}
              </UiFlex>

              {(jobsCount || 0) > (jobIds || []).length && (
                <UiFlex justifyContent="center" marginTop="m">
                  <Button
                    icon={IconArrowDown}
                    onClick={() => {
                      dispatch(
                        setJobListState({
                          limit: (limit || 0) + limitInterval,
                        })
                      );
                    }}
                  >
                    {t('common.actions.showMoreEntries')}
                  </Button>
                </UiFlex>
              )}
            </>
          )}
        </LoadingWrapper>
      </PageContent>
    </>
  );
};

export default Jobs;
