const fontFamily = '"radikal", "Arial", "Helvetica", sans-serif';

export const mediaQueries = {
  mobile: '@media (min-width: 0px)',
  tablet: '@media (min-width: 768px)',
  laptop: '@media (min-width: 1024px)',
  desktop: '@media (min-width: 1200px)',
};

export const appTheme = {
  screens: {
    mobile: '0px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '1200px',
  },
  mediaQueries,
  spacings: {
    xxs: '5px',
    xs: '10px',
    s: '20px',
    m: '30px',
    l: '50px',
    xl: '70px',
    xxl: '90px',
  },
  colors: {
    white: '#FFFFFF',
    black: '#000000',

    // -- ci colors
    ciBlue1: '#001e49',
    ciBlue2: '#12446c',
    ciBlue3: '#4c668b',
    ciBlue4: '#829db6',
    ciBlue5: '#becfdd',
    ciRed: '#e73838',

    grey80: '#585858',
    grey60: '#888888',
    grey40: '#b2b2b2',
    grey20: '#dadada',
  
    // -- accent colors
    acTurquoise: '#00a29d',
    acTurquoiseDark: '#005755',
    acTurquoise80: '#22b4b1',
    acTurquoise60: '#7cc8c4',
    acTurquoise40: '#afdcd9',
    acTurquoise20: '#d9edec',

    acGreen: '#afca05',
    acGreenDark: '#6e7f28',
    acGreen80: '#c2d34f',
    acGreen60: '#d2df83',
    acGreen40: '#e1eab1',
    acGreen20: '#f0f4db',

    acOrange: '#ffaa00',
    acOrangeDark: '#aa7419',
    acOrange80: '#faba58',
    acOrange60: '#fccc86',
    acOrange40: '#feddb0',
    acOrange20: '#ffeed9',

    acSalmon: '#e08976',
    acSalmonDark: '#945b4e',
    acSalmon80: '#e7a390',
    acSalmon60: '#ecbbac',
    acSalmon40: '#f3d3c8',
    acSalmon20: '#f8e9e2',

    acSand: '#bea797',
    acSandDark: '#73645c',
    acSand80: '#cbb8aa',
    acSand60: '#d7c9c0',
    acSand40: '#e5dad4',
    acSand20: '#f2edea',

    trafficLightRed: '#F43636',
    trafficLightYellow: '#ffb700',
    trafficLightGreen: '#79D647',

    trafficLightGreenText: '#42BF00',
    // -- rgba
    // smgInputHover: 'rgba(75,75,75,0.04)',
    // smgInputOutlineHover: 'rgba(255,255,255,0.1)',
  },
  textVariants: {
    h1: {
      fontFamily,
      fontWeight: 700,
      fontSize: '35px',
      lineHeight: '45px',
      [mediaQueries.tablet]: {
        fontSize: '45px',
        lineHeight: '53px',
      },
    },
    h2: {
      fontFamily,
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '29px',
    },
    h3: {
      fontFamily,
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
    },
    copy: {
      fontFamily,
      fontWeight: 400,
      color: '#001e49',
      fontSize: '16px',
      lineHeight: '22px',
    },
    copySmall: {
      fontFamily,
      fontWeight: 400,
      color: '#001e49',
      fontSize: '14px',
      lineHeight: '20px',
    },
    copyTiny: {
      fontFamily,
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '15px',
    },
    label: {
      fontFamily,
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '20px',
    },
    formElementLabel: {
      fontFamily,
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '20px',
    },
    bubbleXL: {
      fontFamily,
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '36px',
    },
  },
  type: {
    copy: {
      fontFamily,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
    },
    copySmall: {
      fontFamily,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    copyTiny: {
      fontFamily,
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '15px',
    },
    label: {
      fontFamily,
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '20px',
    },
    h1Mobile: {
      fontFamily,
      fontWeight: 700,
      fontSize: '35px',
      lineHeight: '45px',
    },
    h1: {
      fontFamily,
      fontWeight: 700,
      fontSize: '45px',
      lineHeight: '53px',
    },
    h2: {
      fontFamily,
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '29px',
    },
    h3: {
      fontFamily,
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
    },
    button: {
      fontFamily,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '22px',
      textTransform: 'uppercase',
    },
  },
  page: {
    contentMaxWidth: '1070px',
  },
};

export const SPACINGS = {
  XXS: '5px', // xxxs
  XS: '8px', // xxs
  S: '20px', // s
  M: '30px', // m
  L: '50px', // l
  XL: '80px', // xxl
  XXL: '130px', // xxxl
};

export type AppTheme = typeof appTheme;
