import { Checkbox } from '@webfox-sc/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import TableBodyCell from '../../components/elements/TableBodyCell';
import TableBodyRow from '../../components/elements/TableBodyRow';
import { themeCheckbox } from '../../theme/theme';
import DateUtil from '../../utils/DateUtil';

const CostcenterListItem = ({ objectId, onClick, isSelected, onSelect }) => {
  const { t } = useTranslation();
  const object = useSelector(({ dataState }) => dataState.costcenters[objectId]);
  const users = useSelector(({ usersState }) => usersState.userModels);

  if (!object) {
    return null;
  }

  const { number, userIds, updatedAt, isModified, isNew } = object;

  const filteredUsers = users ? users.filter((user) => userIds.includes(user.id)) : [];

  const userNamesString =
    filteredUsers.length > 0
      ? filteredUsers.map(({ username }) => username).join(', ')
      : `** ${t('costcenter.info.noUsersAssigned')} **`;

  const updatedAtFormatted = updatedAt ? DateUtil.getDateFormatted(updatedAt) : '';

  return (
    <TableBodyRow isClickable isModified={isModified} isNew={isNew}>
      <TableBodyCell text={number} onClick={onClick} width="20%" />
      <TableBodyCell text={userNamesString} onClick={onClick} width="56%" />
      <TableBodyCell text={updatedAtFormatted} onClick={onClick} width="10%" />
      <TableBodyCell width="7%">
        <Checkbox checked={isSelected} onChange={(e) => onSelect(e.target.checked, objectId)} theme={themeCheckbox} />
      </TableBodyCell>
      <TableBodyCell iconSrc={IconArrowDown} onClick={onClick} width="7%" />
    </TableBodyRow>
  );
};

CostcenterListItem.propTypes = {
  objectId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

CostcenterListItem.defaultProps = {
  onClick: () => false,
  isSelected: PropTypes.bool,
  onSelect: () => false,
};

export default CostcenterListItem;
