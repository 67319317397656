import { UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../app/appHooks';
import { selectDashboardState } from '../../app/slices/dashboardSlice';
import Chart from '../../components/BarChart';
import DashboardUtil from '../../utils/DashboardUtil';

const DashboardCharts: React.FC = () => {
  const { t } = useTranslation();
  const { metrics } = useAppSelector(selectDashboardState);

  const { offerMetrics, certMetrics } = DashboardUtil.formatMetrics(metrics);

  return (
    <UiDiv
      padding={{ mobile: '15px', tablet: '30px' }}
      background="white"
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap="m"
    >
      <UiDiv gridColumn={{ mobile: '1 / 13', tablet: '1 / 7' }}>
        <UiText variant="h2">{t('offer.headline.plural')}</UiText>
        {offerMetrics && <Chart data={offerMetrics} />}
      </UiDiv>
      <UiDiv gridColumn={{ mobile: '1 / 13', tablet: '7 / 13' }}>
        <UiText variant="h2">{t('certificate.headline.plural')}</UiText>
        {certMetrics && <Chart data={certMetrics} />}
      </UiDiv>
    </UiDiv>
  );
};

export default DashboardCharts;
