/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse } from '@webfox-sc/core';
import { pick } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import CheckboxLegacy from '../../atoms/CheckboxLegacy';

import Section from '../../atoms/Section';
import BasicButtonGroup from '../../components/elements/BasicButtonGroup';
import CSVUploader from '../../components/elements/CSVUploader';
import HeadlineTwo from '../../components/elements/HeadlineTwo';
import Preloader from '../../components/elements/Preloader';
import TableBodyCell from '../../components/elements/TableBodyCell';
import TableBodyRow from '../../components/elements/TableBodyRow';
import TableHeaderCell from '../../components/elements/TableHeaderCell';
import TableHeaderRow from '../../components/elements/TableHeaderRow';
import {
  deleteImportFile,
  fetchImportFiles,
  importImportFiles,
  uploadImportFiles,
} from '../../redux-actions/actionsImports';
import DateUtil from '../../utils/DateUtil';
import { getEncodingLabel, getSchemaLabel } from '../../utils/importUtils';
import './base-data-import.scss';
import DataImportEntry from './DataImportEntry';

const ViewBaseDataImport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const importFiles = useSelector(({ dataState }) => dataState.importFiles);
  const isImportFileRequest = useSelector(({ importState }) => importState.isImportFileRequest);
  const isImportRequest = useSelector(({ importState }) => importState.isImportRequest);

  const [selection, setSelection] = useState({});
  const [activeId, setActiveId] = useState(null);

  const handleOnClickTableBodyRow = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  const handleOnDelete = (id) => {
    dispatch(deleteImportFile(id));
  };

  const handleOnUpload = (files) => {
    dispatch(uploadImportFiles(files));
  };

  const selectEntry = (value, id) => {
    const newSelection = { ...selection };
    if (value) {
      newSelection[id] = true;
    } else if (newSelection[id]) {
      delete newSelection[id];
    }
    setSelection(newSelection);
  };

  const handleOnImport = () => {
    dispatch(importImportFiles({ ids: Object.keys(selection) }));
  };

  useEffect(() => {
    if (!isImportFileRequest) {
      dispatch(fetchImportFiles());
    }
  }, []);

  useEffect(() => {
    const importFileIds = Object.keys(importFiles);
    // remove files from selection if files change
    setSelection(pick(selection, importFileIds));
  }, [importFiles]);

  const importFileValues = Object.values(importFiles);

  return (
    <div className="smg-view-base-data-import">
      <Section paddingTop="L">
        <CSVUploader inputId="csv-upload" onUpload={handleOnUpload} />
      </Section>
      <Section paddingTop="L">
        <HeadlineTwo text={t('common.import.headline')} />
      </Section>
      {isImportFileRequest ? (
        <Section paddingTop="L" align="center">
          <Preloader />
        </Section>
      ) : (
        <Section paddingTop="M">
          {importFileValues && importFileValues.length > 0 ? (
            <>
              <TableHeaderRow>
                <TableHeaderCell text={t('common.import.fileName')} width="auto" />
                <TableHeaderCell text={t('common.import.encoding')} width="10%" />
                <TableHeaderCell text={t('common.import.schema')} width="14%" />
                <TableHeaderCell text={t('common.fields.date')} width="38%" />
              </TableHeaderRow>
              {importFileValues.map(
                ({ id, createdAt, autoImport, imported, schema, encoding, file, data, errors, log, report }) => (
                  <Fragment key={id}>
                    <Section paddingTop="XS">
                      <TableBodyRow
                        objectId={id}
                        isError={errors && errors.length > 0}
                        isNew={report && !!Object.keys(report).length}
                      >
                        <TableBodyCell
                          text={`${file?.name || ''}<br>(${
                            autoImport ? t('import.autoImport.true') : t('import.autoImport.false')
                          })`}
                          onClick={() => handleOnClickTableBodyRow(id)}
                          width="auto"
                        />
                        <TableBodyCell
                          text={getEncodingLabel(encoding)}
                          onClick={() => handleOnClickTableBodyRow(id)}
                          width="10%"
                        />
                        <TableBodyCell
                          text={getSchemaLabel(schema)}
                          onClick={() => handleOnClickTableBodyRow(id)}
                          width="14%"
                        />
                        <TableBodyCell
                          text={`${createdAt ? DateUtil.getDateFormatted(createdAt) : ''}<br>(${
                            imported ? t('import.imported.true') : t('import.imported.false')
                          })`}
                          onClick={() => handleOnClickTableBodyRow(id)}
                          width="13%"
                        />
                        <TableBodyCell onClick={() => handleOnClickTableBodyRow(id)} width="15%">
                          <ButtonSecondary label={t('common.actions.delete')} onClick={() => handleOnDelete(id)} />
                        </TableBodyCell>
                        <TableBodyCell width="10%">
                          {/* {!autoImport && */}
                            <CheckboxLegacy
                              size="L"
                              checked={!!selection[id]}
                              onChange={(value) => selectEntry(value, id)}
                            />
                          {/* } */}
                        </TableBodyCell>
                      </TableBodyRow>
                      <Collapse show={id === activeId} keepChildren={false}>
                        <DataImportEntry data={data} errors={errors} report={log?.report || report || undefined} schema={schema} />
                      </Collapse>
                    </Section>
                  </Fragment>
                )
              )}
            </>
          ) : (
            <span className="smg-view-base-data__text">{t('common.import.info.noFiles')}</span>
          )}
        </Section>
      )}
      <Section paddingTop="M">
        <div className="smg-view-base-data__toolbar">
          <BasicButtonGroup>
            <ButtonSecondary
              label={t('import.actions.importSelected')}
              onClick={handleOnImport}
              disabled={isImportRequest || !Object.values(selection).length}
            />
          </BasicButtonGroup>
        </div>
      </Section>
    </div>
  );
};

export default ViewBaseDataImport;
