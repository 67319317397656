import { UiDiv } from '@webfox-sc/core';
import { useTranslation } from 'react-i18next';

import Button from '../atoms-v2/Button';

interface QMFilterSubmitProps {
  searchDisabled?: boolean;
  summaryDisabled?: boolean;
  onSubmit: () => void;
  onCreateSummary?: () => void;
}

const QMFilterSubmit: React.FC<QMFilterSubmitProps> = ({
  onSubmit,
  onCreateSummary,
  searchDisabled,
  summaryDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <UiDiv display="flex" justifyContent="space-between" columnGap="xs" marginTop="xs">
      <Button disabled={searchDisabled} onClick={onSubmit}>
        {t('common.actions.search')}
      </Button>
      {onCreateSummary ? (
        <Button disabled={summaryDisabled} onClick={onCreateSummary}>
          {t('report.actions.generateSummary')}
        </Button>
      ) : null}
    </UiDiv>
  );
};

export default QMFilterSubmit;
