import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../theme/theme';

const OldSpan = styled.span({
  color: COLORS.TRAFFIC_LIGHT_RED,
  textDecoration: 'line-through',
});

const NewSpan = styled.span({
  color: COLORS.TRAFFIC_LIGHT_GREEN_TEXT,
});

const JobHistoryDiffField: React.FC<DiffFieldProps> = ({ label, oldValue, newValue }) => {
  return (
    <UiDiv marginRight="xxs">
      {label && <span>{label}: </span>}
      {oldValue && <OldSpan>{oldValue}</OldSpan>}
      {newValue && <NewSpan> {newValue}</NewSpan>}
    </UiDiv>
  );
};

export default JobHistoryDiffField;
