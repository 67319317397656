import { useState } from 'react';
import { useAppDispatch } from '../../app/appHooks';
import { useCertificate } from '../../app/entityHooks/useCertificate';
import { useJob } from '../../app/entityHooks/useJob';
import {
  createCertificate,
  createCertificatePDF,
  deleteCertificateFile,
  uploadCertificateFiles,
} from '../../app/slices/certificatesSlice';
import { createRecipient } from '../../app/slices/recipientsSlice';
import AppUtil from '../../utils/AppUtil';
import { useAuth } from '../auth/useAuth';

export const useJobCertificate = (
  jobId: number | undefined
): {
  certificate: CertificateEntity | undefined;
  createCertificateStatus: RequestStatus;
  handleCreateCertificate: (
    orderNumber: string | undefined,
    location: string | undefined,
    serviceStartDate: Date | null | undefined,
    serviceEndDate: Date | null | undefined,
    comments: string | undefined,
    showPrices: boolean | undefined
  ) => Promise<boolean>;
  handleCreateCertificatePDF: () => void;
  handleUploadCertificateFiles: (fileList: FileList) => void;
  handleDeleteCertificateFile: (fileId: number) => void;
} => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();
  const { job, recipient: jobRecipient } = useJob(jobId);
  const { certificate } = useCertificate(job?.certificateId);

  const [createCertificateStatus, setCreateCertificateStatus] = useState<RequestStatus>('idle');

  const handleCreateCertificate = async (
    orderNumber: string | undefined,
    location: string | undefined,
    serviceStartDate: Date | null | undefined,
    serviceEndDate: Date | null | undefined,
    comments: string | undefined,
    showPrices: boolean | undefined
  ) => {
    if (!job || !jobRecipient) {
      return false;
    }

    // request status
    setCreateCertificateStatus('loading');

    // -- create certificate
    const resCertificateCreated = await dispatch(
      createCertificate({
        job: job.id,
        entry_id: `${job.entryId}-L`,
        order_number: orderNumber,
        location,
        service_start_date: AppUtil.toDateString(serviceStartDate, true),
        service_end_date: AppUtil.toDateString(serviceEndDate, true),
        comments,
        show_prices: showPrices,
        confirmed: 'INIT',
        creator_name: currentUser?.username,
        creator_email: currentUser?.email,
        creator_tel: currentUser?.tel,
      })
    );
    const certificateCreatedData = resCertificateCreated.payload?.data;
    if (!certificateCreatedData) {
      return false;
    }

    // -- create certificate recipient
    const resRecipientCreated = await dispatch(
      createRecipient({
        client_id: jobRecipient.clientId,
        debitor_id: jobRecipient.debitorId,
        costcenter: jobRecipient.costcenterNumber,
        carrier_number: jobRecipient.carrierNumber,
        carrier_description: jobRecipient.carrierDescription,
        client_number: jobRecipient.clientNumber,
        client_description: jobRecipient.clientDescription,
        contacts: jobRecipient.contactIds,
        address: jobRecipient.addressData,
        certificate: certificateCreatedData.id,
      })
    );
    const recipientCreatedData = resRecipientCreated.payload?.data;
    if (!recipientCreatedData) {
      return false;
    }

    // request status
    setCreateCertificateStatus('idle');

    // -- return true
    return true;
  };

  const handleCreateCertificatePDF = async () => {
    if (certificate?.id) {
      const res = await dispatch(createCertificatePDF(certificate.id));
      AppUtil.openDownload(res.payload?.data);
    }
  };

  const handleUploadCertificateFiles = async (fileList: FileList) => {
    if (certificate?.id) {
      const formData = new FormData();
      Object.values(fileList).forEach((file) => {
        formData.append('files', file, `${certificate.entryId}-${file.name}`);
      });

      await dispatch(uploadCertificateFiles(certificate?.id, formData));
    }
  };

  const handleDeleteCertificateFile = async (fileId: number) => {
    if (certificate?.id) {
      dispatch(deleteCertificateFile(certificate.id, fileId));
    }
  };

  return {
    certificate,
    createCertificateStatus,
    handleCreateCertificate,
    handleCreateCertificatePDF,
    handleUploadCertificateFiles,
    handleDeleteCertificateFile,
  };
};
