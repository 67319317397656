import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { scheduleSchema } from '../schemas';
import { getCompleteEntities } from '../schemaUtils';
import { RootState } from '../store';

export const createSchedule = (data: ScheduleCreateData): RequestEntitiesAction<ScheduleData> =>
  requestEntities({
    method: 'POST',
    path: '/schedules',
    data,
    schema: scheduleSchema,
  });

export const fetchSchedules = (params?: any): RequestEntitiesAction<ScheduleData[]> =>
  requestEntities({
    method: 'GET',
    path: '/schedules',
    schema: [scheduleSchema],
    params: params || { _limit: -1, populate: '*' },
    type: 'fetchSchedules',
  });

export const fetchSchedule = (scheduleId: number, params?: any): RequestEntitiesAction<ScheduleData> =>
  requestEntities({
    method: 'GET',
    path: `/schedules/${scheduleId}`,
    schema: scheduleSchema,
    params,
  });

export const updateSchedule = (scheduleId: number, data: ScheduleUpdateData): RequestEntitiesAction<ScheduleData> =>
  requestEntities({
    method: 'PUT',
    path: `/schedules/${scheduleId}`,
    data,
    schema: scheduleSchema,
  });

export const deleteSchedule = (scheduleId: number): RequestEntitiesAction<ScheduleData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/schedules/${scheduleId}`,
    schema: scheduleSchema,
    type: 'deleteSchedule',
  });
};

export interface SchedulesState extends EntityState<ScheduleEntity> {
  fetchSchedulesStatus: RequestStatus;
}

const schedulesAdapter = createEntityAdapter<ScheduleEntity>();

const initialState: SchedulesState = schedulesAdapter.getInitialState({
  fetchSchedulesStatus: 'idle',
});

export const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchSchedules') {
          state.fetchSchedulesStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.payload.entities?.schedules) {
          const completeEntities = getCompleteEntities(action.payload.entities.schedules);
          if (Object.keys(completeEntities).length > 0) {
            schedulesAdapter.upsertMany(state, completeEntities);
          }
        }
        if (action.meta.arg.type === 'fetchSchedules') {
          state.fetchSchedulesStatus = 'idle';
        }
        if (action.meta.arg.type === 'deleteSchedule') {
          schedulesAdapter.removeOne(state, action.payload.data.id);
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchSchedules') {
          state.fetchSchedulesStatus = 'failed';
        }
      });
  },
});

export const selectSchedulesState = (state: RootState): SchedulesState => state.entitiesState.schedules;
export default schedulesSlice.reducer;
