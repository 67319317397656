import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialState } from '../../app/slices/clientFilterSlice';
import CheckboxLegacy from '../../atoms/CheckboxLegacy';

import { useBaseDataFilterContext } from '../../hooks/baseDataFilter';
import { useBaseDataViewContext } from '../../hooks/baseDataView';
import { useDynamicClientFilter } from '../../hooks/useDynamicClientFilter';
import ClientsUtil from '../../utils/ClientsUtil';
import { createClientDropdowns, getEntries, getEntryLabel, getGroupLabel } from '../../utils/FilterUtil';
import CustomReactSelect from '../CustomReactSelect';

const ClientsFilter = ({ withClientGroups, withEmptyClientFilter, emptyClientFilterlabel }) => {
  const { t } = useTranslation();
  const { dataFilter, handleChangeDataFilter } = useBaseDataFilterContext();
  const { setShowAdd, setActiveId } = useBaseDataViewContext();

  const [currentFilterState, handleSetFilterState] = useState(initialState);
  const { company, setCompany, location, setLocation, costcenter, setCostcenter, filterMap } = useDynamicClientFilter(
    currentFilterState,
    handleSetFilterState
  );

  // client groups filter is computed independently
  const [clientGroup, setClientGroup] = useState(null);

  const clientDropdowns = useMemo(() => {
    return createClientDropdowns(filterMap);
  }, [filterMap]);

  const onChangeCostcenter = async (option) => {
    setShowAdd(false);
    setActiveId(false);
    const id = (option && option.value) || null;
    const number = (option && option.number) || null;
    const cc = await setCostcenter({
      id,
      number,
    });
    handleChangeDataFilter({
      ...dataFilter,
      clientId: null,
      costcenterId: id,
      costcenterClientIds: cc?.clientIds || null,
      costcenterNumber: number || '',
    });
  };

  const onChangeCompany = async (option) => {
    setShowAdd(false);
    setActiveId(false);
    const id = (option && option.value) || null;
    const number = (option && option.number) || null;
    const com = await setCompany({
      id,
      number,
      name: (option && option.name) || null,
    });
    handleChangeDataFilter({
      ...dataFilter,
      clientId: null,
      companyId: id,
      companyClientIds: com?.clientIds || null,
      clientNumber: number || '',
      optionLabel: option?.label,
    });
  };

  const onChangeLocation = async (option) => {
    setShowAdd(false);
    setActiveId(false);
    const id = (option && option.value) || null;
    const number = (option && option.number) || null;
    const loc = await setLocation({
      id,
      number,
      description: (option && option.description) || null,
    });
    handleChangeDataFilter({
      ...dataFilter,
      clientId: null,
      locationId: id,
      locationClientIds: loc?.clientIds || null,
      carrierNumber: number || '',
    });
  };

  const onChangeClient = async (option) => {
    setShowAdd(false);
    setActiveId(false);
    const id = (option && option.value) || null;
    setClientGroup(option);
    handleChangeDataFilter({
      ...dataFilter,
      clientId: id,
    });
  };
  const onChangeEmptyClientChekbox = async (showEmpty) => {
    setShowAdd(showEmpty);
    setActiveId(false);
    if (showEmpty) {
      setClientGroup(null);
      await setLocation({});
      await setCompany({});
      await setCostcenter({});
    }
    handleChangeDataFilter({
      clientId: {
        $empty: showEmpty,
      },
    });
  };

  return (
    <>
      {withEmptyClientFilter && (
        <div className="smg-view-base-data__toolbar">
          <div className="smg-view-base-data__toolbar-filter">
            <CheckboxLegacy label={emptyClientFilterlabel} size="L" layout="white" checked={dataFilter?.clientId?.$empty} onChange={onChangeEmptyClientChekbox} />
          </div>
        </div>
      )}
      <div className="smg-view-base-data__toolbar">
        <div className="smg-view-base-data__toolbar-filter">
          <CustomReactSelect
            layout="outline"
            asyncDefaultOptions={[
              { value: '', label: t('common.form.showAllCostcenters') },
              ...clientDropdowns?.costcenter,
            ]}
            selectedOption={{
              value: (costcenter && costcenter.id) || '',
              label: (costcenter && costcenter.number) || t('common.form.showAllCostcenters'),
            }}
            asyncApiPath="/costcenters"
            asyncLimit={500}
            asyncSort="number:asc"
            asyncSetParams={(inputValue) =>
              inputValue
                ? {
                    filters: [
                      {
                        number: { $contains: inputValue },
                      },
                    ],
                  }
                : {}
            }
            asyncFormatOptions={(entries) => [
              {
                label: getGroupLabel('none'),
                options: getEntries('costcenter', entries),
              },
            ]}
            asyncInputMinLength={3}
            isClearable
            isSearchable
            onChange={onChangeCostcenter}
          />
        </div>
        {withClientGroups && (
          <div className="smg-view-base-data__toolbar-filter">
            <CustomReactSelect
              layout="outline"
              leadingOptions={[
                { value: '', label: t('common.form.showAllClientGroups') },
                // ...clientDropdowns?.clientGroups,
              ]}
              asyncDefaultOptions
              selectedOption={{
                value: (clientGroup && clientGroup.id) || '',
                label: (clientGroup && clientGroup.label) || t('common.form.showAllClientGroups'),
                debitorId: clientGroup && clientGroup.debitorId,
              }}
              asyncApiPath="/clientgroups"
              asyncLimit={500}
              asyncSort="number:asc"
              asyncSetParams={(inputValue) =>
                inputValue
                  ? {
                      filters: [
                        {
                          debitor_id: { $contains: inputValue },
                        },
                      ],
                    }
                  : {}
              }
              asyncFormatOptions={(entries) => [
                {
                  label: getGroupLabel('none'),
                  options: entries.map((entry) => {
                    const clG = ClientsUtil.parseData(entry);
                    return {
                      value: entry?.id,
                      label: getEntryLabel('client', clG),
                      debitorId: entry.debitorId,
                    };
                  }),
                },
              ]}
              asyncInputMinLength={3}
              isClearable
              isSearchable
              onChange={onChangeClient}
            />
          </div>
        )}
      </div>
      <div className="smg-view-base-data__toolbar">
        <div className="smg-view-base-data__toolbar-filter">
          <CustomReactSelect
            layout="outline"
            asyncDefaultOptions={[
              { value: '', label: t('common.form.showAllDebitorNumbers') },
              ...clientDropdowns?.company,
            ]}
            selectedOption={{
              value: (company && company.id) || '',
              label: (company && company.number) || t('common.form.showAllDebitorNumbers'),
            }}
            asyncApiPath="/companies"
            asyncLimit={500}
            asyncSort="number:asc"
            asyncSetParams={(inputValue) =>
              inputValue
                ? {
                    filters: {
                      $or: [
                        {
                          name: { $containsi: inputValue },
                        },
                        {
                          number: { $contains: inputValue },
                        },
                      ],
                    },
                  }
                : {}
            }
            asyncFormatOptions={(entries) => [
              {
                label: getGroupLabel('none'),
                options: getEntries('company', entries),
              },
            ]}
            asyncInputMinLength={3}
            isClearable
            isSearchable
            onChange={onChangeCompany}
          />
        </div>
        <div className="smg-view-base-data__toolbar-filter">
          <CustomReactSelect
            layout="outline"
            asyncDefaultOptions={[{ value: '', label: t('common.form.showAllLocations') }, ...clientDropdowns.location]}
            selectedOption={{
              value: (location && location.id) || '',
              label: (location && location.description) || t('common.form.showAllLocations'),
            }}
            asyncApiPath="/locations"
            asyncLimit={500}
            asyncSort="number:asc"
            asyncSetParams={(inputValue) =>
              inputValue
                ? {
                    filters: {
                      $or: [
                        {
                          description: { $containsi: inputValue },
                        },
                        {
                          number: { $contains: inputValue },
                        },
                      ],
                    },
                  }
                : {}
            }
            asyncFormatOptions={(entries) => [
              {
                label: getGroupLabel('none'),
                options: getEntries('location', entries),
              },
            ]}
            asyncInputMinLength={3}
            isClearable
            isSearchable
            onChange={onChangeLocation}
          />
        </div>
      </div>
    </>
  );
};

ClientsFilter.propTypes = {
  withClientGroups: PropTypes.bool,
};

ClientsFilter.defaultProps = {
  withClientGroups: false,
};

export default ClientsFilter;
