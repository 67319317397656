import { Collapse, UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';

import IconPlus from '../../../assets/icons-v2/IconPlus';
import QMUtil from '../../../utils/QMUtil';
import Checkbox from '../../atoms-v2/Checkbox';
import TextButton from '../../atoms-v2/TextButton';

import { useCheckitemgrouptype } from '../../../app/entityHooks/useCheckitemgrouptype';
import { useCheckitemgrouptypeOptions } from '../../../app/entityHooks/useCheckitemgrouptypeOptions';
import QMAdminConfigCheckitem from './QMAdminConfigCheckitem';
import QMAdminConfigCheckitemForm from './QMAdminConfigCheckitemForm';
import QMAdminConfigSelectableLabel from './QMAdminConfigSelectableLabel';

type QMAdminConfigCheckitemgroupProps = {
  checkitemgroup: CheckitemgroupNestedEntity;
  clickHandler: (path: (string | number)[]) => void;
  updateHandler: (path: (string | number)[], value: any) => void;
};

const QMAdminConfigCheckitemgroup: React.FC<QMAdminConfigCheckitemgroupProps> = ({
  checkitemgroup,
  clickHandler,
  updateHandler,
}) => {
  const { checkitemgrouptypeId } = checkitemgroup;
  const { checkitemgrouptype } = useCheckitemgrouptype(checkitemgrouptypeId)
  const [showCreateCheckitemForm, setShowCreateCheckitemForm] = useState(false);
  const { checkitemgrouptypeOptions } = useCheckitemgrouptypeOptions();

  if (!checkitemgroup) {
    return null;
  }

  const checkitems = checkitemgroup.checkitems || [];

  const handleOnAdd = (newItem: any) => {
    const newPath = [...checkitemgroup.path, 'checkitems', checkitemgroup.checkitems.length];
    updateHandler(newPath, { ...newItem, path: newPath, checkitemgroupId: checkitemgroup.id });
  };

  return (
    <UiDiv>
      <UiDiv display="flex" gap="xs" alignItems="center" minHeight="50px">
        <Checkbox checked={!checkitemgroup.disabled} onChange={() => clickHandler(checkitemgroup.path)} />
        <UiDiv flexGrow={1}>
          <QMAdminConfigSelectableLabel
            value={checkitemgrouptype?.id}
            label={checkitemgrouptype?.name}
            options={checkitemgrouptypeOptions}
            onSave={(value) => updateHandler([...checkitemgroup.path, 'checkitemgrouptypeId'], value)}
            onDelete={checkitemgroup.showDelete ? () => updateHandler(checkitemgroup.path, null) : undefined}
          />
        </UiDiv>
      </UiDiv>
      <UiDiv paddingLeft="40px">
        {checkitems.length > 0 && (
          <UiDiv display="flex" flexDirection="column" rowGap="1px" marginTop="xs">
            {checkitems.map((checkitem, index) => (
              <QMAdminConfigCheckitem
                key={checkitem.id || index}
                checkitem={checkitem}
                clickHandler={() => clickHandler(checkitem.path)}
                updateHandler={(value) => updateHandler(checkitem.path, value)}
              />
            ))}
          </UiDiv>
        )}

        <UiDiv marginTop="xs">
          <TextButton
            icon={IconPlus}
            onClick={(event) => {
              event.preventDefault();
              setShowCreateCheckitemForm(!showCreateCheckitemForm);
            }}
          >
            Individuellen Prüfpunkt hinzufügen
          </TextButton>
          <Collapse show={showCreateCheckitemForm} fadeInOut keepChildren={false}>
            <UiDiv paddingTop="xs">
              <UiDiv padding="s" background="white">
                <QMAdminConfigCheckitemForm
                  checkitem={QMUtil.getEmptyNestedCheckitem({})}
                  onSave={(newItem) => {
                    handleOnAdd(newItem);
                    setShowCreateCheckitemForm(false);
                  }}
                  onClickCancel={() => setShowCreateCheckitemForm(false)}
                />
              </UiDiv>
            </UiDiv>
          </Collapse>
        </UiDiv>
      </UiDiv>
    </UiDiv>
  );
};

export default QMAdminConfigCheckitemgroup;
