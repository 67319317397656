const IconPlus = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M93.18,40.91H59.09V6.82A6.82,6.82,0,0,0,52.27,0H47.73a6.82,6.82,0,0,0-6.82,6.82V40.91H6.82A6.82,6.82,0,0,0,0,47.73v4.54a6.82,6.82,0,0,0,6.82,6.82H40.91V93.18A6.82,6.82,0,0,0,47.73,100h4.54a6.82,6.82,0,0,0,6.82-6.82V59.09H93.18A6.82,6.82,0,0,0,100,52.27V47.73A6.82,6.82,0,0,0,93.18,40.91Z"
    />
  </svg>
);

export default IconPlus;
