import { UiDiv, UiText } from '@webfox-sc/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { useAppSelector } from '../../app/appHooks';
import { selectReportsState } from '../../app/slices/reportsSlice';
import { selectSchedulesState } from '../../app/slices/schedulesSlice';
import LoadingWrapper from '../atoms-v2/LoadingWrapper';

import { selectQMOverviewState } from '../../app/slices/qmOverviewSlice';
import IconNotFound from '../../assets/icons-v2/IconNotFound';
import IconPlus from '../../assets/icons-v2/IconPlus';
import Button from '../atoms-v2/Button';
import NoResultsNotice from '../atoms-v2/NoResultsNotice';
import QMReportTeaser from '../qm-shared/QMReportTeaser';
import QMScheduleReportTeaser from '../qm-shared/QMScheduleReportTeaser';

interface QMReportsAndSchedulesProps {
  onSubmit: (pagination?: { viewStart?: number }) => void;
}

const QMReportsAndSchedules: React.FC<QMReportsAndSchedulesProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { fetchSchedulesStatus } = useAppSelector(selectSchedulesState);
  const { fetchReportsStatus } = useAppSelector(selectReportsState);

  const { entries, isDirty, hasMore, viewCount, reportTotal, scheduleTotal, scheduleReportTotal } =
    useAppSelector(selectQMOverviewState);

  // might change, since it is inially unknown how many reports are in schedules
  const totalCount = reportTotal + scheduleTotal - scheduleReportTotal;

  const onClickMoreButton = () => onSubmit({ viewStart: viewCount });

  return (
    <LoadingWrapper
      isLoading={
        (fetchSchedulesStatus === 'loading' && entries.length === 0) ||
        (fetchReportsStatus === 'loading' && entries.length === 0)
      }
    >
      {entries?.length ? (
        <UiDiv marginBottom="l">
          <UiText variant="h2" color="white" textAlign="center">
            {t('report.headline.results')} {totalCount ? `(${totalCount})` : ''}
          </UiText>
          <UiDiv display="flex" flexDirection="column" rowGap="xs" marginTop="m">
            {entries.slice(0, viewCount).map((entry) => {
              if (entry.type === 'report') {
                return <QMReportTeaser key={`report_${entry.id}`} reportId={entry.id} />;
              }
              if (entry.type === 'schedule') {
                return <QMScheduleReportTeaser key={`schedule_${entry.id}`} scheduleId={entry.id} />;
              }
              return null;
            })}
          </UiDiv>
        </UiDiv>
      ) : (
        <UiDiv marginBottom="l" flexGrow={1} textAlign="center">
          <NoResultsNotice icon={IconNotFound}>{t('report.info.noneFound')}</NoResultsNotice>
        </UiDiv>
      )}
      {!isDirty && hasMore && (
        <UiDiv display="flex" justifyContent="center" marginTop="xs">
          <Button icon={IconPlus} onClick={onClickMoreButton}>
            {t('common.actions.showMore')}
          </Button>
        </UiDiv>
      )}
    </LoadingWrapper>
  );
};

export default QMReportsAndSchedules;
