import React from 'react';
import styled, { CSSObject } from 'styled-components';

type ButtonVariant = 'primary';

interface StyledButtonProps {
  variant?: ButtonVariant;
  isIconOnly?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>(({ isIconOnly, theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    width: '80px', // only on mobile
    height: '80px',
    minWidth: '50px',
    minHeight: '50px',
    border: 0,
    borderRadius: '5px',
    padding: '0 14px',
    paddingBottom: '12px',
    fontFamily: '"radikal", sans-serif',
    fontSize: '11px',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    fontWeight: 700,
    margin: 0,
    background: theme.colors.ciBlue4,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.1s ease',
    svg: {
      flex: '100%',
      width: '30px',
      maxWidth: 'none',
      height: '30px',
      paddingTop: '16px',
      verticalAlign: 'top',
    },
    '&:hover': {
      background: theme.colors.ciRed,
    },
    '&:active': {
      background: theme.colors.ciRed,
    },
    '&[data-active="true"]': {
      background: theme.colors.ciRed,
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
      '&:hover': {
        opacity: 0.5,
      },
    },
    [theme.mediaQueries.tablet]: {
      svg: {
        flex: '0 0 14px',
        width: '14px',
        maxWidth: 'none',
        height: '14px',
        verticalAlign: 'top',
        paddingTop: '0px',
      },
      ...(theme.type.button as CSSObject),
      width: 'auto', // only on mobile
      height: 'auto',
      minWidth: '50px',
      minHeight: '50px',
      padding: isIconOnly ? 0 : '0 20px',
      margin: 0,
      flexWrap: 'nowrap',
      paddingBottom: '0px',
    },
  };

  return {
    ...baseStyle,
  };
});

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  icon: React.ReactNode;
  active?: boolean;
}

const ButtonJobDetails: React.FC<ButtonProps> = ({ variant, icon, active, type, children, ...props }) => {
  const isIconOnly = !!icon && !children;

  const dataAttributes = {
    'data-active': active,
  };

  return (
    <StyledButton variant={variant} isIconOnly={isIconOnly} type={type || 'button'} {...props} {...dataAttributes}>
      {icon}
      {children}
    </StyledButton>
  );
};

export default ButtonJobDetails;
