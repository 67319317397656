import React, { useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

type TextAreaVariant = 'outline';

interface StyledTextAreaProps {
  variant?: TextAreaVariant;
  width?: 0 | string;
  error?: boolean;
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>(({ variant, width, error, theme }) => {
  let baseStyle: CSSObject = {
    boxSizing: 'border-box',
    width: width || '100%',
    maxWidth: '100%',
    border: `1px solid ${theme.colors.ciBlue1}`,
    background: 'white',
    padding: '13px 10px',
    transition: 'background-color 0.1s ease',
    ...theme.type.copy,
    color: theme.colors.ciBlue1,
    verticalAlign: 'top',
    '&::placeholder': {
      fontStyle: 'italic',
      color: theme.colors.ciBlue1,
      opacity: 0.5,
    },
    '&:hover': {
      background: theme.colors.grey20,
    },
    '&:focus': {
      outline: 0,
      background: 'white',
    },
    '&:disabled': {
      '&:hover': {
        background: 'white',
      },
    },
  };

  if (variant === 'outline') {
    baseStyle = {
      ...baseStyle,
      borderColor: 'white',
      background: 'transparent',
      color: 'white',
      '&::placeholder': {
        fontStyle: 'italic',
        color: 'white',
        opacity: 0.5,
      },
      '&:hover': {
        background: theme.colors.grey20,
      },
      '&:focus': {
        outline: 0,
        background: theme.colors.grey20,
      },
      '&:disabled': {
        '&:hover': {
          background: 'transparent',
        },
      },
    };
  }

  if (error) {
    baseStyle = {
      ...baseStyle,
      borderColor: theme.colors.trafficLightRed,
    };
  }

  return {
    ...baseStyle,
  };
});

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: TextAreaVariant;
  width?: 0 | string;
  error?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({ variant, width, error, value, onChange, ...props }) => {
  const [draftValue, setDraftValue] = useState(value);

  useEffect(() => {
    setDraftValue(value);
  }, [value]);

  return (
    <StyledTextArea
      variant={variant}
      width={width}
      error={error}
      value={draftValue !== undefined && draftValue !== null ? draftValue : ''}
      onChange={(e): void => {
        setDraftValue(e.target.value);
        if (onChange) {
          onChange(e);
        }
      }}
      {...props}
    />
  );
};

export default TextArea;
