import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../atoms/Section';
import FormPanel from '../../components/atoms-v2/FormPanel';
import Advice from '../../components/elements/Advice';
import FormCell from '../../components/elements/FormCell';
import FormLabel from '../../components/elements/FormLabel';
import FormRow from '../../components/elements/FormRow';
import StaticField from '../../components/elements/StaticField';
import ToggleBox from '../../components/elements/ToggleBox';
import AppUtil from '../../utils/AppUtil';
import { formatPrice } from '../../utils/format';
import DataImportAddressEntry from './DataImportAddressEntry';

const ERROR_LIMIT = 3;

const getDetailsText = (details) => details.map(({ field, values: [v1, v2] }) => `${field} ${v1} != ${v2}`).join(', ');

const DataImportEntry = ({ data, errors, report, schema }) => {
  const { t } = useTranslation();

  const getErrorText = ({ type, collection, identifier, line, details }) => {
    switch (type) {
      case 'denormalization':
        // eslint-disable-next-line
        return t('import.errors.denormalization', {
          line,
          collection: t(`common.entities.${collection}.singular`),
          identifier: identifier ? ` (${identifier})` : '',
          details: getDetailsText(details),
        });
      case 'fileSchema':
        return t('import.errors.fileSchema');
      case 'fileNotFound':
        return t('import.errors.fileNotFound');
      default:
        return t('import.errors.unknown');
    }
  };

  const { locations, companies, clients, clientGroups, services, costcenters, addresses, locationAddresses, clientAddresses } = data;

  const isError = errors && errors.length > 0;
  const isSuccess = report && Object.keys(report).length > 0;
  const showAdvice = isError || isSuccess;

  let adviceText;
  if (isError) {
    const firstErrors = errors.slice(0, ERROR_LIMIT);
    const errorTexts = firstErrors.map(getErrorText);
    if (errors.length > ERROR_LIMIT) {
      errorTexts.push(t('import.errors.more', { length: errors.length - ERROR_LIMIT }));
    }
    adviceText = errorTexts.join('<br />');
  } else if (isSuccess) {
    const texts = [t('import.success.headline')];
    const reportKeys = Object.keys(report);
    reportKeys.forEach((collection) => {
      const { changed, checked, created } = report[collection];
      texts.push(
        t('import.success.details', {
          collection: t(`common.entities.${collection}.plural`),
          checked,
          changed,
          created,
        })
      );
    });
    adviceText = texts.join('<br />');
  }

  return (
    <FormPanel>
      <ToggleBox show={showAdvice}>
        <Section paddingBottom="S">
          <Advice text={adviceText} isSuccess={isSuccess} isError={isError} />
        </Section>
      </ToggleBox>
      <Section>
        <h4>{t('import.headline.preview')}</h4>
      </Section>
      {(schema === 'companies' || schema === 'ibsCompanies') && companies && companies[0] && (
        <>
          <Section paddingTop="S">
            <FormRow>
              <FormCell cols="4">
                <FormLabel text={t('company.number')} />
                <StaticField text={companies[0].number} />
              </FormCell>
              <FormCell cols="8">
                <FormLabel text={t('company.name')} />
                <StaticField text={companies[0].name} />
              </FormCell>
            </FormRow>
          </Section>
          {addresses && addresses[0] && (
            <Section>
              <FormRow>
                <DataImportAddressEntry address={addresses[0]} title={t('company.address')} key="address_companies" />
              </FormRow>
            </Section>
          )}
        </>
      )}
      {(schema === 'locations' || schema === 'ibsCompanies') && locations && locations[0] && (
        <Section paddingTop="S">
          <FormRow>
            <FormCell cols="4">
              <FormLabel text={t('common.fields.carrierNumber.long')} />
              <StaticField text={locations[0].number} />
            </FormCell>
            <FormCell cols="8">
              <FormLabel text={t('location.descrtiption')} />
              <StaticField text={locations[0].description} />
            </FormCell>
          </FormRow>
        </Section>
      )}
      {schema === 'ibsCompanies' && locationAddresses && locationAddresses[0] && (
        <Section>
          <FormRow>
            <DataImportAddressEntry address={locationAddresses[0]} title={t('location.address')} key="address_locations" />
          </FormRow>
        </Section>
      )}
      {schema === 'ibsCompanies' && clientAddresses && clientAddresses[0] && (
        <Section>
          <FormRow>
            <DataImportAddressEntry address={clientAddresses[0]} title={t('client.address')} key="address_clients" />
          </FormRow>
        </Section>
      )}
      {(schema === 'services' || schema === 'ibsServices') && (
        <>
          {clients && clients[0] && (
            <Section paddingTop="S">
              <FormRow>
                <FormCell cols="4">
                  {companies && companies[0] && companies[0].id === clients[0].companyId && (
                    <>
                      <FormLabel text={t('company.number')} />
                      <StaticField text={companies[0].number} />
                    </>
                  )}
                </FormCell>
                <FormCell cols="4">
                  {locations && locations[0] && locations[0].id === clients[0].locationId && (
                    <>
                      <FormLabel text={t('common.fields.carrierNumber.long')} />
                      <StaticField text={locations[0].number} />
                    </>
                  )}
                </FormCell>
                {costcenters && costcenters[0] && costcenters[0].id === clients[0].costcenterId && (
                  <FormCell cols="4">
                    <FormLabel text={t('costcenter.number')} />
                    <StaticField text={costcenters[0].number} />
                  </FormCell>
                )}
              </FormRow>
            </Section>
          )}
          {clientGroups && clientGroups[0] && (
            <Section paddingTop="S">
              <FormRow>
                <FormCell cols="6">
                  <FormLabel text={t('clientGroup.headline.singular')} />
                  <StaticField text={clientGroups[0].debitorId} />
                </FormCell>
              </FormRow>
            </Section>
          )}
          {addresses && addresses[0].number && clients[0].addressId === addresses[0].id && (
            <Section paddingTop="S">
              <FormRow>
                <FormCell cols="4">
                  <FormLabel text={t('import.address.number')} />
                  <StaticField text={addresses[0].number} />
                </FormCell>
              </FormRow>
            </Section>
          )}
          {locationAddresses && locationAddresses[0].number && clients[0].addressId === locationAddresses[0].id && (
            <Section paddingTop="S">
              <FormRow>
                <FormCell cols="4">
                  <FormLabel text={t('import.address.number')} />
                  <StaticField text={locationAddresses[0].number} />
                </FormCell>
              </FormRow>
            </Section>
          )}
          {clientAddresses && clientAddresses[0].number && clients[0].addressId === clientAddresses[0].id && (
            <Section paddingTop="S">
              <FormRow>
                <FormCell cols="4">
                  <FormLabel text={t('import.address.number')} />
                  <StaticField text={clientAddresses[0].number} />
                </FormCell>
              </FormRow>
            </Section>
          )}
        </>
      )}
      {schema === 'clientGroups' &&
        clientGroups &&
        clientGroups.map((clientGroup) => (
          <Fragment key={`client_${clientGroup.id}`}>
            <Section paddingTop="S">
              <FormRow>
                <FormCell cols="6">
                  <FormLabel text={t('clientGroup.headline.singular')} />
                  <StaticField text={clientGroup.debitorId} />
                </FormCell>
              </FormRow>
            </Section>
            <Section paddingTop="S">
              <FormRow>
                <FormCell cols="12">
                  <FormLabel text={t('costcenter.clients')} />
                  <StaticField
                    text={clients
                      .filter((cl) => cl?.clientGroupId === clientGroup.id)
                      .map((cl) => {
                        const location = locations && locations.find((loc) => loc.id === cl.locationId);
                        if (location) {
                          return location.number;
                        }
                        return null;
                      })
                      .filter((label) => !!label)
                      .join(', ')}
                  />
                </FormCell>
              </FormRow>
            </Section>
          </Fragment>
        ))}
      {services && services[0] && (
        <>
          <Section paddingTop="S">
            <FormRow>
              <FormCell cols="12">
                <h5>{t('common.entities.service.singular')}</h5>
              </FormCell>
            </FormRow>
          </Section>
          <Section paddingTop="S">
            <FormRow>
              <FormCell cols="12">
                <FormLabel text={t('service.serviceDescription')} />
                <StaticField text={services[0].description} />
              </FormCell>
            </FormRow>
          </Section>
          <Section paddingTop="XS">
            <FormRow>
              <FormCell cols="3">
                <FormLabel text={t('common.fields.dla')} />
                <StaticField text={services[0].dla} />
              </FormCell>
              <FormCell cols="3">
                <FormLabel text={t('common.fields.va')} />
                <StaticField text={services[0].va} />
              </FormCell>
              <FormCell cols="3">
                <FormLabel text={t('common.fields.taxCode')} />
                <StaticField text={services[0].taxCode} />
              </FormCell>
              <FormCell cols="3">
                <FormLabel text={t('common.entities.location.singular')} />
                <StaticField text={services[0].locationCode} />
              </FormCell>
            </FormRow>
          </Section>
          <Section paddingTop="XS">
            <FormRow>
              <FormCell cols="4">
                <FormLabel text={t('service.unit')} />
                <StaticField text={services[0].unit} />
              </FormCell>
              <FormCell cols="4">
                <FormLabel text={t('service.unitPrice')} />
                <StaticField text={formatPrice(services[0].price, 2, 4)} />
              </FormCell>
              <FormCell cols="4">
                <FormLabel text={t('service.baseQuantity')} />
                <StaticField text={services[0].baseQuantity} />
              </FormCell>
            </FormRow>
          </Section>
          <Section paddingTop="XS">
            <FormRow>
              <FormCell cols="6">
                <FormLabel text={t('service.validFrom')} />
                <StaticField text={AppUtil.toLocalDateString(services[0].validFrom)} />
              </FormCell>
              <FormCell cols="6">
                <FormLabel text={t('service.validTo')} />
                <StaticField text={AppUtil.toLocalDateString(services[0].validTo)} />
              </FormCell>
            </FormRow>
          </Section>
        </>
      )}
    </FormPanel>
  );
};

DataImportEntry.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
  schema: PropTypes.string,
  report: PropTypes.object,
};

DataImportEntry.defaultProps = {
  data: null,
  errors: null,
  schema: null,
  report: null,
};

export default DataImportEntry;
