import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { recipientSchema } from '../schemas';

export const createRecipient = (data: RecipientCreateData): RequestEntitiesAction<RecipientData> =>
  requestEntities({
    method: 'POST',
    path: '/recipients',
    data,
    schema: recipientSchema,
  });
// obsolete
// export const updateRecipient = (recipientId: number, data: RecipientUpdateData): RequestEntitiesAction<RecipientData> =>
//   requestEntities({
//     method: 'PUT',
//     path: `/recipients/${recipientId}`,
//     data,
//     schema: recipientSchema,
//   });

const recipientsAdapter = createEntityAdapter<RecipientEntity>();

export const recipientsSlice = createSlice({
  name: 'recipients',
  initialState: recipientsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.recipients) {
        recipientsAdapter.upsertMany(state, action.payload.entities.recipients);
      }
    });
  },
});

export default recipientsSlice.reducer;
