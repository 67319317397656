import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const limitInterval = 10;

export interface QMAdminState {
  searchInputValue?: string;
  searchValue?: string;
  showClientsWithSchedules?: boolean;
  limit?: number;
}

const initialState: QMAdminState = {
  searchInputValue: '',
  searchValue: '',
  showClientsWithSchedules: true,
  limit: limitInterval,
};

export const qmAdminSlice = createSlice({
  name: 'qmAdmin',
  initialState,
  reducers: {
    setQMAdminState: (state: QMAdminState, action: PayloadAction<QMAdminState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setQMAdminState } = qmAdminSlice.actions;
export const selectQMAdminState = (state: RootState): QMAdminState => state.qmAdmimState;
export default qmAdminSlice.reducer;
