import { UiDiv, UiFlex } from '@webfox-sc/core';
import addMonths from 'date-fns/addMonths';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EXPORT_JOB_SERVICES } from '../../app.config';
import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { limitInterval, selectDashboardState, setDashboardState } from '../../app/slices/dashboardSlice';
import { fetchJobMetrics, selectJobsState } from '../../app/slices/jobsSlice';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import IconNotFound from '../../assets/icons-v2/IconNotFound';
import DashboardUtil from '../../utils/DashboardUtil';
import Button from '../atoms-v2/Button';
import LoadingWrapper from '../atoms-v2/LoadingWrapper';
import NoResultsNotice from '../atoms-v2/NoResultsNotice';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';
import DashboardCharts from './DashboardCharts';
import DashboardFilters from './DashboardFilters';
import DashboardJobCardWrapper from './DashboardJobCardWrapper';
import './dashboard.scss';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { fetchJobsStatus } = useAppSelector(selectJobsState);

  const {
    selectedLocationNumber,
    selectedCompanyNumber,
    selectedStatus,
    selectedStartDate,
    selectedEndDate,
    selectedIsInvoiced,
    dashboardLimit,
    limit,
    jobIds,
    jobsCount,
    // metrics
  } = useAppSelector(selectDashboardState);

  const handleOnChanged = () => {
    // reset filter
    dispatch(
      setDashboardState({
        selectedIsInvoiced: '',
      })
    );
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!selectedStartDate || !selectedEndDate) {
        return;
      }
      const params: {
        [key: string]: any;
      } = {
        filters: {
          carrier_number: selectedLocationNumber || undefined,
          client_number: selectedCompanyNumber || undefined,
          createdAt: { $between: [selectedStartDate, addMonths(selectedEndDate, 1)] },
        },
        pagination: {
          limit: Math.max(limit || 0, dashboardLimit || 0),
          start: 0,
        },
      };
      if (selectedIsInvoiced === 'true') {
        params.filters.is_invoiced = true
      } else if (selectedIsInvoiced === 'false') {
        // in this case false is cast to 'is_invoiced is not true', because query is parsed manually
        params.filters.is_invoiced = false
      }
      if (EXPORT_JOB_SERVICES) {
        params.populate = { services: '*' };
      }
      if (selectedStatus?.startsWith('LN-')) {
        params.filters.status = selectedStatus;
      } else if (selectedStatus?.startsWith('A-')) {
        params.filters.offer_status = selectedStatus;
      } else if (selectedStatus === 'Erstellt') {
        params.filters.status = { $notContains: 'LN-' };
        params.filters.offer_status = { $null: true };
      }

      const res = await dispatch(fetchJobMetrics(params));
      const receivedJobIds = (res.payload?.data?.jobs || []).map((jobData) => jobData.id);
      const newJobsCount = res.payload?.data?.count || 0;

      let csvData;
      if (receivedJobIds.length >= newJobsCount) {
        // create CSV data with all jobs
        csvData = DashboardUtil.createCsvArray(res.payload?.entities?.jobs, t, EXPORT_JOB_SERVICES);
      } else {
        csvData = null;
      }

      dispatch(
        setDashboardState({
          // only display jobs up to dashboard limit
          jobIds: receivedJobIds.slice(0, Math.min(limit || 0, dashboardLimit || 0)),
          jobsCount: newJobsCount,
          metrics: res.payload?.data?.metrics || { offers: {}, certificates: {} },
          csvData,
        })
      );
    };
    fetchDashboardData();
  }, [
    dispatch,
    selectedStatus,
    limit,
    selectedLocationNumber,
    selectedCompanyNumber,
    selectedStartDate,
    selectedEndDate,
    selectedIsInvoiced,
    dashboardLimit,
    t,
  ]);

  return (
    <>
      <PageHeading heading="Dashboard" />

      <PageContent marginTop="m">
        <UiDiv>
          <DashboardFilters />
        </UiDiv>

        <UiDiv marginTop="l">
          <DashboardCharts />
        </UiDiv>
        <LoadingWrapper isLoading={fetchJobsStatus === 'loading' && (jobIds || []).length === 0} marginTop="m">
          {(jobIds || []).length === 0 ? (
            <NoResultsNotice icon={IconNotFound}>{t('common.messages.noJobsFound')}</NoResultsNotice>
          ) : (
            <>
              <UiFlex flexDirection="column" rowGap="xs" marginTop="m">
                {(jobIds || []).map((jobId) => {
                  return (
                    <DashboardJobCardWrapper
                      key={jobId}
                      jobId={jobId}
                      pathname={pathname}
                      onChanged={handleOnChanged}
                    />
                  );
                })}
              </UiFlex>

              {(jobsCount || 0) > (jobIds || []).length && (
                <UiFlex justifyContent="center" marginTop="m">
                  <Button
                    icon={IconArrowDown}
                    onClick={() => {
                      dispatch(
                        setDashboardState({
                          limit: (limit || 0) + limitInterval,
                        })
                      );
                    }}
                  >
                    {t('common.actions.showMoreEntries')}
                  </Button>
                </UiFlex>
              )}
            </>
          )}
        </LoadingWrapper>
      </PageContent>
    </>
  );
};

export default Dashboard;
