import { entitySelectors } from '../entitySelectors';

export const useCraft = (
  craftId: number | undefined
): {
  craft: CraftEntity | undefined;
} => {
  const craft = entitySelectors.selectById<CraftEntity>('crafts', craftId);

  return {
    craft,
  };
};
