import React from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv, UiText } from '@webfox-sc/core';

import { useClient } from '../../../app/entityHooks/useClient';
import IconMapMarker from '../../../assets/icons-v2/IconMapMarker';
import ContentBox from '../../atoms-v2/ContentBox';
import Text from '../../atoms-v2/Text';

interface QMAdminClientInfoBoxProps {
  clientId: number | undefined;
}

const QMAdminClientInfoBox: React.FC<QMAdminClientInfoBoxProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const { location, costcenter } = useClient(clientId);

  return (
    <ContentBox background="white">
      <UiDiv display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} gap="xs" justifyContent="space-between">
        <Text icon={IconMapMarker}>
          <strong>{location?.description || '?'}</strong>
        </Text>
        <UiText variant="copy" paddingLeft={{ mobile: '26px', desktop: undefined }}>
          {t('common.entities.costcenter.singular')}: <b>{costcenter?.number || '?'}</b>
        </UiText>
      </UiDiv>
    </ContentBox>
  );
};

export default QMAdminClientInfoBox;
