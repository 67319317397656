import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv, UiText } from '@webfox-sc/core';

import IconCheck from '../../assets/icons-v2/IconCheck';
import IconComment from '../../assets/icons-v2/IconComment';
import IconEdit from '../../assets/icons-v2/IconEdit';
import Button from '../atoms-v2/Button';
import IconButton from '../atoms-v2/IconButton';
import TextArea from '../atoms-v2/TextArea';
import TextLink from '../atoms-v2/TextLink';

type QMReportCommentProps = {
  variant?: 'box';
  editMode?: boolean;
  isRequired?: boolean;
  comment?: string;
  onToggle?: (isExtended: boolean) => void;
  onSave: (comment: string) => void;
};

const QMReportComment: React.FC<QMReportCommentProps> = ({ variant, editMode, isRequired, comment, onToggle, onSave }) => {
  const { t } = useTranslation();
  const [isExtended, setIsExtended] = useState(false);
  const [commentValue, setCommentValue] = useState(comment || undefined);

  useEffect(() => {
    setCommentValue(comment);
  }, [comment]);

  useEffect(() => {
    if(isRequired) {
      setIsExtended(true);
    }
  }, [isRequired]);

  if (!editMode && !comment) {
    return null;
  }

  const handleToggle = (isExtendedNext: boolean) => {
    setCommentValue(comment);
    setIsExtended(isExtendedNext);
    if (onToggle) {
      onToggle(isExtendedNext);
    }
  };

  return (
    <UiDiv>
      <UiDiv display="flex" justifyContent={variant === 'box' ? 'center' : undefined}>
        {editMode && !comment && (
          <TextLink
            onClick={() => {
              handleToggle(!isExtended);
            }}
            icon={IconComment}
          >
            {t('common.actions.comment')}{isRequired ? ' *' : ''}
          </TextLink>
        )}
        {comment && (
          <UiDiv display="flex" gap="xs" alignItems="center">
            <UiText variant="copy">
              <b>{t('common.fields.comment')}</b>
            </UiText>
            {editMode && (
              <IconButton
                icon={IconEdit}
                onClick={() => {
                  handleToggle(!isExtended);
                }}
              />
            )}
          </UiDiv>
        )}
      </UiDiv>
      {isExtended && (
        <UiDiv
          marginTop="xs"
          background={variant === 'box' ? 'white' : undefined}
          padding={variant === 'box' ? 's' : undefined}
        >
          <UiDiv maxWidth="640px">
            <TextArea
              value={commentValue}
              onChange={(event) => {
                setCommentValue(event.target.value);
              }}
            >
              {commentValue}
            </TextArea>
          </UiDiv>
          <UiDiv display="flex" columnGap="xs" justifyContent={variant === 'box' ? 'center' : undefined} marginTop="xs">
            <Button
              icon={IconCheck}
              disabled={isRequired && !commentValue}
              onClick={() => {
                if (!!onSave) {
                  onSave(commentValue || '');
                }
                handleToggle(false);
              }}
            >
              {t('common.actions.save')}
            </Button>
            <Button
              variant="secondary"
              icon={IconCheck}
              onClick={() => {
                handleToggle(false);
              }}
            >
              {t('common.actions.cancel')}
            </Button>
          </UiDiv>
        </UiDiv>
      )}
      {!isExtended && !!comment && (
        <UiText
          variant="copy"
          maxWidth="640px"
          marginTop="xs"
          background={variant === 'box' ? 'white' : undefined}
          padding={variant === 'box' ? 's' : undefined}
        >
          {comment}
        </UiText>
      )}
    </UiDiv>
  );
};

export default QMReportComment;
