const IconCockpit = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M99.56,52.07A51.28,51.28,0,0,0,56,7.47a48.58,48.58,0,0,0-38.46,12,53.32,53.32,0,0,0-9,68.5,10.31,10.31,0,0,0,3.06,3.11,10.48,10.48,0,0,0,4.08,1.57,9,9,0,0,0,1.64,0,10.68,10.68,0,0,0,6.57-2.33,41.36,41.36,0,0,1,52.21,0,10.36,10.36,0,0,0,8.18,2.47A10.75,10.75,0,0,0,91.42,88,53.69,53.69,0,0,0,99.56,52.07ZM21.43,62.61H17.86a3.58,3.58,0,0,1,0-7.15h3.57a3.58,3.58,0,0,1,0,7.15Zm10.71-22a3.59,3.59,0,0,1-3.9.71,3.67,3.67,0,0,1-1.17-.81l-2.5-2.61a3.57,3.57,0,0,1,.09-5,3.55,3.55,0,0,1,5.05.09l2.54,2.64a3.57,3.57,0,0,1-.11,5Zm14.29-15a3.57,3.57,0,0,1,7.14,0v3.58a3.57,3.57,0,1,1-7.14,0Zm3.57,37a3.54,3.54,0,0,1-2.47-6L70.24,32.9a3.69,3.69,0,0,1,1.15-.81,3.59,3.59,0,0,1,1.37-.3,3.45,3.45,0,0,1,1.38.25,3.59,3.59,0,0,1,1.18.75,3.51,3.51,0,0,1,.8,1.14,3.64,3.64,0,0,1,.06,2.75,3.68,3.68,0,0,1-.76,1.18L52.67,61.5a3.55,3.55,0,0,1-1.22.84A3.59,3.59,0,0,1,50,62.61Zm32.14,0H78.57a3.58,3.58,0,0,1,0-7.15h3.57a3.58,3.58,0,0,1,0,7.15Z"
    />
  </svg>
);

export default IconCockpit;
