import _ from 'lodash';
import { schema } from 'normalizr';

import QMUtil from '../utils/QMUtil';

import { getEntityId, isCompleteEntity } from './schemaUtils';

/**
 * schemas
 */
const companySchema = new schema.Entity<CompanyEntity>(
  'companies',
  {},
  {
    processStrategy: (data: CompanyData) => {
      if (!isCompleteEntity(data)) {
        return { id: data.id };
      }
      const entity: CompanyEntity = {
        ...data,
        addressId: getEntityId(data.address),
      };

      if (data.clients) {
        entity.clientIds = (data.clients || []).map((client) => getEntityId(client) as number);
      }

      if (data.roomgroups) {
        entity.roomgroupIds = (data.roomgroups || []).map((roomgroup) => getEntityId(roomgroup) as number);
      }

      if (data.contacts) {
        entity.contactIds = (data.contacts || []).map((contact) => getEntityId(contact) as number);
      }

      if (data.crafts) {
        entity.craftIds = (data.crafts || []).map((craft) => getEntityId(craft) as number);
      }

      return entity;
    },
  }
);

const clientSchema = new schema.Entity<ClientEntity>(
  'clients',
  {},
  {
    processStrategy: (
      data: ClientApiData,
      parent:
        | ContactData
        | ServiceData
        | AddressData
        | CompanyData
        | LocationData
        | ScheduleData
        | ReportData
        | RoomgroupData
        | UserData
        | ClientApiData
        | CostcenterData,
      field: string
    ) => {
      if (!isCompleteEntity(data)) {
        return { id: data.id };
      }

      const hasParent = !!parent;
      const parentIsCompany =
        hasParent &&
        field === 'clients' &&
        (parent as CompanyData).name !== undefined &&
        (parent as UserData).email === undefined;
      const parentIsLocation = hasParent && field === 'clients' && (parent as LocationData).description !== undefined;
      const parentIsClientGroup = hasParent && (parent as ClientApiData).is_group === true;
      const parentIsCostcenter =
        hasParent && !parentIsCompany && !parentIsClientGroup && (parent as CostcenterData).number !== undefined;
      const parentIsAddress = hasParent && (parent as AddressData).line1 !== undefined;

      const company = data.company || (parentIsCompany && (parent as CompanyData)) || null;
      const location = data.location || (parentIsLocation && (parent as LocationData)) || null;
      const clientGroup = data.client_group || (parentIsClientGroup && (parent as ClientApiData)) || null;
      const costcenter = data.costcenter_rel || (parentIsCostcenter && (parent as CostcenterData)) || null;
      const address = data.address || (parentIsAddress && (parent as AddressData)) || null;

      const entity: ClientEntity = {
        ...data,
        carrierDescription: data.carrier_description,
        carrierNumber: data.carrier_number,
        noTax: data.no_tax,
        debitorId: data.debitor_id,
        isGroup: data.is_group,
        companyId: getEntityId(company),
        locationId: getEntityId(location),
        costcenterId: getEntityId(costcenter),
        clientGroupId: getEntityId(clientGroup),
        addressId: getEntityId(address),
      };

      if (data.services) {
        entity.serviceIds = (data.services || []).map((service) => getEntityId(service) as number);
      }

      if (data.roomgroups) {
        entity.roomgroupIds = (data.roomgroups || []).map((roomgroup) => getEntityId(roomgroup) as number);
      }

      if (data.schedules) {
        entity.scheduleIds = (data.schedules || []).map((schedule) => getEntityId(schedule) as number);
      }

      if (data.contacts) {
        entity.contactIds = (data.contacts || []).map((contact) => getEntityId(contact) as number);
      }

      return entity;
    },
  }
);

const serviceSchema = new schema.Entity<ServiceEntity>(
  'services',
  {},
  {
    processStrategy: (data: ServiceData, parent: ClientApiData) => {
      const hasParent = !!parent;
      const client = data.client || (hasParent && (parent as ClientApiData)) || null;

      const entity: ServiceEntity = {
        ...data,
        locationCode: data.location_code,
        taxCode: data.tax_code,
        baseQuantity: data.base_quantity,
        clientNumber: data.client_number,
        contractNumber: data.contract_number,
        contractItem: data.contract_item,
        carrierNumber: data.carrier_number,
        validFrom: data.valid_from || undefined,
        validTo: data.valid_to || undefined,
        clientId: getEntityId(client),
      };

      return entity;
    },
  }
);

const userSchema = new schema.Entity<UserEntity>(
  'users',
  {},
  {
    processStrategy: (data: UserData) => {
      if (!isCompleteEntity(data)) {
        return { id: data.id };
      }
      const entity: UserEntity = {
        ...data,
        id: data.id || 0,
        roleId: getEntityId(data.role),
      };

      if (data.role && typeof data.role === 'object') {
        entity.roleName = data.role.name;
        entity.roleType = data.role.type;
      }

      if (data.costcenters) {
        entity.costcenterIds = (data.costcenters || []).map((costcenter) => getEntityId(costcenter) as number);
      }

      return entity;
    },
  }
);

const permissionsSchema = new schema.Entity<PermissionEntity>(
  'permissions',
  {},
  {
    processStrategy: (data: PermissionData) => {
      const { id, permissions } = data;
      const controllerPermissions: ControllerPermissions = {};
      if (permissions) {
        _.forEach(permissions, (permission) => {
          _.forEach(permission?.controllers || {}, (controller, controllerName) => {
            _.forEach(controller, (action, actionName) => {
              if (action?.enabled) {
                _.set(controllerPermissions, [controllerName, actionName], true);
              }
            });
          });
        });
      }
      const entity: PermissionEntity = {
        id,
        permissions: controllerPermissions,
      };

      return entity;
    },
  }
);

const roleSchema = new schema.Entity<RoleEntity>('roles', {});
const usersPermissionsRolesSchema = new schema.Entity('rolesObject', {});

const costcenterSchema = new schema.Entity<CostcenterEntity>(
  'costcenters',
  {},
  {
    processStrategy: (data: CostcenterData) => {
      const entity: CostcenterEntity = {
        ...data,
      };

      if (data.users) {
        entity.userIds = (data.users || []).map((user) => getEntityId(user) as number);
      }

      if (data.clients) {
        entity.clientIds = (data.clients || []).map((client) => getEntityId(client) as number);
      }

      return entity;
    },
  }
);

const checkitemtypeSchema = new schema.Entity<CheckitemtypeEntity>(
  'checkitemtypes',
  {},
  {
    processStrategy: (data: CheckitemtypeData) => {
      const entity: CheckitemtypeEntity = {
        ...data,
        disabled: data.disabled || false,
      };

      return entity;
    },
  }
);

const checkitemSchema = new schema.Entity<CheckitemEntity>(
  'checkitems',
  {},
  {
    processStrategy: (
      data: CheckitemData,
      parent: CheckitemgroupData | CheckitemData | CheckresultData | CheckitemtypeData
    ) => {
      // same key, use question to determine type
      const hasParent = !!parent;
      const parentIsCheckitem = hasParent && (parent as CheckitemData).question !== undefined;
      const parentIsCheckitemgroup = hasParent && (parent as CheckitemgroupData).roomgroup !== undefined;
      const checkitemgroup = data.checkitemgroup || (parentIsCheckitemgroup && (parent as CheckitemgroupData)) || null;
      const default_checkitem = data.default_checkitem || (parentIsCheckitem && (parent as CheckitemData)) || null;

      const entity: CheckitemEntity = {
        ...data,
        weight: data.weight || 0,
        disabled: data.disabled || false,
      };

      if (checkitemgroup) {
        entity.checkitemgroupId = getEntityId(checkitemgroup);
      }

      if (default_checkitem) {
        entity.defaultCheckitemId = getEntityId(default_checkitem);
      }

      if (data.checkitems) {
        entity.checkitemIds = (data.checkitems || []).map((checkitem) => getEntityId(checkitem) as number);
      }

      if (data.checkitemtype) {
        entity.checkitemtypeId = getEntityId(data.checkitemtype);
      }

      return entity;
    },
  }
);

const checkitemgrouptypeSchema = new schema.Entity<CheckitemgrouptypeEntity>(
  'checkitemgrouptypes',
  {},
  {
    processStrategy: (data: CheckitemgrouptypeData) => {
      const entity: CheckitemgrouptypeEntity = {
        ...data,
        disabled: data.disabled || false,
      };

      return entity;
    },
  }
);

const checkitemgroupSchema = new schema.Entity<CheckitemgroupEntity>(
  'checkitemgroups',
  {},
  {
    processStrategy: (data: CheckitemgroupData, parent: RoomgroupData | CheckitemgroupData | CheckitemData) => {
      // same key, use question to determine type
      const hasParent = !!parent;
      const parentIsCheckitemgroup = hasParent && (parent as CheckitemgroupData).checkitems !== undefined;
      const parentIsCheckitem = hasParent && (parent as CheckitemData).question !== undefined;
      const parentIsRoomgroup = hasParent && !parentIsCheckitemgroup && !parentIsCheckitem;

      const roomgroup = data.roomgroup || (parentIsRoomgroup && (parent as RoomgroupData)) || null;
      const default_checkitemgroup =
        data.default_checkitemgroup || (parentIsCheckitemgroup && (parent as CheckitemgroupData)) || null;

      const entity: CheckitemgroupEntity = {
        ...data,
        disabled: data.disabled || false,
        checkitemIds: (data.checkitems || []).map((checkitem) => getEntityId(checkitem) as number),
      };

      if (roomgroup) {
        entity.roomgroupId = getEntityId(roomgroup);
      }

      if (default_checkitemgroup) {
        entity.defaultCheckitemgroupId = getEntityId(default_checkitemgroup);
      }

      if (data.checkitemgroups) {
        entity.checkitemgroupIds = (data.checkitemgroups || []).map(
          (checkitemgroup) => getEntityId(checkitemgroup) as number
        );
      }

      if (data.checkitemgrouptype) {
        entity.checkitemgrouptypeId = getEntityId(data.checkitemgrouptype);
      }

      return entity;
    },
  }
);

const roomgroupSchema = new schema.Entity<RoomgroupEntity>(
  'roomgroups',
  {},
  {
    processStrategy: (
      data: RoomgroupData,
      parent: ReportroomData | CheckitemgroupData | RoomgroupData | ClientApiData | CompanyData
    ) => {
      // same key, use question to determine type
      const hasParent = !!parent;
      const parentIsClient = hasParent && (parent as ClientApiData).costcenter_rel !== undefined;
      const parentIsCompany = hasParent && !parentIsClient && (parent as CompanyData).clients !== undefined;
      const parentIsRoomgroup = hasParent && (parent as RoomgroupData).checkitemgroups !== undefined;

      const client = data.client || (parentIsClient && (parent as ClientApiData)) || null;
      const company = data.company || (parentIsCompany && (parent as CompanyData)) || null;
      const default_roomgroup = data.default_roomgroup || (parentIsRoomgroup && (parent as RoomgroupData)) || null;

      const entity: RoomgroupEntity = {
        ...data,
        source: QMUtil.getChecksource(data),
        companyId: getEntityId(company),
        clientId: getEntityId(client),
        disabled: data.disabled || false,
        checkitemgroupIds: (data.checkitemgroups || []).map((checkitemgroup) => getEntityId(checkitemgroup) as number),
        checkitemCount: (data.checkitemgroups || []).reduce(
          (cnt, checkitemgroup) =>
            (cnt as number) +
            (typeof checkitemgroup === 'object'
              ? checkitemgroup?.checkitems?.filter((checkitem) => typeof checkitem === 'object' && !checkitem.disabled)
                  .length || 0
              : 0),
          0
        ) as number,
      };

      if (default_roomgroup) {
        entity.defaultRoomgroupId = getEntityId(default_roomgroup);
      }

      if (data.roomgroups) {
        entity.roomgroupIds = (data.roomgroups || []).map((roomgroup) => getEntityId(roomgroup) as number);
      }

      if (data.craft) {
        entity.craftId = getEntityId(data.craft);
      }

      return entity;
    },
  }
);

const craftSchema = new schema.Entity<CraftEntity>(
  'crafts',
  {},
  {
    processStrategy: (data: CraftData) => {
      const entity: CraftEntity = {
        ...data,
        disabled: data.disabled || false,
      };

      return entity;
    },
  }
);

const scheduleSchema = new schema.Entity<ScheduleEntity>(
  'schedules',
  {},
  {
    processStrategy: (data: ScheduleData, parent: UserData | ContactData | ReportData | ClientApiData) => {
      if (!isCompleteEntity(data)) {
        return { id: data.id };
      }
      const hasParent = !!parent;
      const parentIsClient =
        hasParent &&
        ((parent as ClientApiData).costcenter_rel !== undefined || (parent as ClientApiData).location !== undefined);
      const client = data.client || (parentIsClient && (parent as ClientApiData)) || null;

      const entity: ScheduleEntity = {
        ...data,
        roomCount: data.room_count,
        start: data.start ? new Date(data.start) : undefined,
        dueDate: data.due_date,
        clientId: getEntityId(client),
        // frequencyIntervalFormatted: ScheduleUtil.getFrequencyIntervalFormatted(data.frequency, data.interval),
        // startDateFormatted: data.start ? DateUtil.getDateFormatted(data.start) : undefined,
      };

      if (data.craft) {
        entity.craftId = getEntityId(data.craft);
      }

      if (data.users) {
        entity.userIds = (data.users || []).map((user) => getEntityId(user) as number);
      }

      if (data.contacts) {
        entity.contactIds = (data.contacts || []).map((contact) => getEntityId(contact) as number);
      }

      if (data.reports) {
        entity.reportIds = (data.reports || []).map((report) => getEntityId(report) as number);
      }

      return entity;
    },
  }
);

const locationSchema = new schema.Entity<LocationEntity>(
  'locations',
  {},
  {
    processStrategy: (data: LocationData) => {
      if (!isCompleteEntity(data)) {
        return { id: data.id };
      }
      const entity: LocationEntity = {
        ...data,
      };

      if (data.clients) {
        entity.clientIds = (data.clients || []).map((client) => getEntityId(client) as number);
      }

      return entity;
    },
  }
);

const contactSchema = new schema.Entity<ContactEntity>(
  'contacts',
  {},
  {
    processStrategy: (data: ContactData) => {
      const entity: ContactEntity = {
        ...data,
      };

      if (data.recipients) {
        entity.recipientIds = (data.recipients || []).map((recipient) => getEntityId(recipient) as number);
      }

      if (data.reports) {
        entity.reportIds = (data.reports || []).map((report) => getEntityId(report) as number);
      }

      if (data.clients) {
        entity.clientIds = (data.clients || []).map((client) => getEntityId(client) as number);
      }

      if (data.companies) {
        entity.companyIds = (data.companies || []).map((company) => getEntityId(company) as number);
      }

      return entity;
    },
  }
);

const reportSchema = new schema.Entity<ReportEntity>(
  'reports',
  {},
  {
    processStrategy: (
      data: ReportData,
      parent: UserData | ContactData | ScheduleData | ReportroomData | ClientApiData
    ) => {
      const hasParent = !!parent;
      const parentIsClient = hasParent && (parent as ClientApiData).costcenter_rel !== undefined;
      const parentIsSchedule = hasParent && (parent as ScheduleData).room_count !== undefined;
      const parentIsUser = hasParent && (parent as UserData).email !== undefined;
      const schedule = data.schedule || (parentIsSchedule && (parent as ScheduleData)) || null;
      const client = data.client || (parentIsClient && (parent as ClientApiData)) || null;
      const reporter = data.reporter || (parentIsUser && (parent as UserData)) || null;

      const entity: ReportEntity = {
        ...data,
        dueDate: data.due_date,
        interval: data.interval,
        status: !!data.status ? data.status : 'pending',
        roomCount: data.room_count,
        companyName: data.company_name,
        companyNumber: data.company_number,
        locationDescription: data.location_description,
        locationNumber: data.location_number,
        costcenterNumber: data.costcenter_number,
        reporterId: getEntityId(reporter),
        scheduleId: getEntityId(schedule),
        clientId: getEntityId(client),
        isAdHoc: !data.due_date,
      };

      if (data.score_percentage !== undefined) {
        entity.scorePercentage = data.score_percentage;
      }

      if (data.craft) {
        entity.craftId = getEntityId(data.craft);
      }

      if (data.contacts) {
        entity.contactIds = (data.contacts || []).map((contact) => getEntityId(contact) as number);
      }

      if (data.reportrooms) {
        entity.reportroomIds = (data.reportrooms || []).map((reportroom) => getEntityId(reportroom) as number);
      }

      if (data.roomgroups) {
        entity.roomgroupIds = (data.roomgroups || []).map((roomgroup) => getEntityId(roomgroup) as number);
      }

      return entity;
    },
  }
);

const reportroomSchema = new schema.Entity<ReportroomEntity>(
  'reportrooms',
  {},
  {
    processStrategy: (data: ReportroomData, parent: CheckresultData | ReportData | RoomgroupData) => {
      if (!isCompleteEntity(data)) {
        return { id: data.id };
      }
      const hasParent = !!parent;
      const parentIsRoomgroup = hasParent && (parent as RoomgroupData).name !== undefined;
      const parentIsReport = hasParent && (parent as ReportData).company_number !== undefined;

      const roomgroup = data.roomgroup || (parentIsRoomgroup && (parent as RoomgroupData) && parent) || null;
      const report = data.report || (parentIsReport && (parent as ReportData) && parent) || null;

      const entity: ReportroomEntity = {
        ...data,
        status: !!data.status ? data.status : 'pending',
        pictureCount: data.picture_count,
        hasComments: data.has_comments,
        roomgroupId: getEntityId(roomgroup),
        reportId: getEntityId(report),
      };

      if (data.max_score !== undefined) {
        entity.maxScore = data.max_score;
      }

      if (data.score_percentage !== undefined) {
        entity.scorePercentage = data.score_percentage;
      }

      if (data.checkresults) {
        entity.checkresultIds = (data.checkresults || []).map((checkresult) => getEntityId(checkresult) as number);
      }

      return entity;
    },
  }
);

const checkresultSchema = new schema.Entity<CheckresultEntity>(
  'checkresults',
  {},
  {
    processStrategy: (
      data: CheckresultData,
      parent: CheckitemData | CheckitemtypeData | CheckitemgrouptypeData | ReportroomData
    ) => {
      if (!isCompleteEntity(data)) {
        return { id: data.id };
      }
      const hasParent = !!parent;
      const parentIsReportroom = hasParent && (parent as ReportroomData).number !== undefined;
      const parentIsCheckitem = hasParent && (parent as CheckitemData).weight !== undefined;
      // TODO we cannot determine whether parent is CheckitemtypeData or CheckitemgrouptypeData
      const parentIsCheckitemtype = hasParent && !parentIsCheckitem && (parent as CheckitemtypeData).name !== undefined;

      const reportroom = data.reportroom || (parentIsReportroom && (parent as ReportroomData) && parent) || null;
      const checkitem = data.checkitem || (parentIsCheckitem && (parent as CheckitemData) && parent) || null;
      const checkitemtype =
        data.checkitemtype || (parentIsCheckitemtype && (parent as CheckitemtypeData) && parent) || null;
      const checkitemgrouptype = data.checkitemgrouptype;

      const entity: CheckresultEntity = {
        ...data,
        reportroomId: getEntityId(reportroom),
        checkitemId: getEntityId(checkitem),
        checkitemtypeId: getEntityId(checkitemtype),
        checkitemgrouptypeId: getEntityId(checkitemgrouptype),
      };

      entity.pictureIds = (data.pictures || []).map((picture) => getEntityId(picture) as number);

      return entity;
    },
  }
);

const pictureSchema = new schema.Entity<PictureEntity>(
  'pictures',
  {},
  {
    processStrategy: (data: PictureData) => {
      const entity: PictureEntity = {
        ...data,
      };

      if (data.related) {
        entity.checkresultIds = (data.related || []).map((checkresult) => getEntityId(checkresult) as number);
      }

      return entity;
    },
  }
);

const jobSchema = new schema.Entity<JobEntity>(
  'jobs',
  {},
  {
    processStrategy: (data: JobData) => {
      const entity: JobEntity = {
        ...data,
        creatorId: data.creator_id,
        entryId: data.entry_id,
        costcenterNumber: data.costcenter,
        carrierNumber: data.carrier_number,
        clientId: data.client_id,
        clientName: data.client_name,
        contactPersonName: data.contact_person_name,
        contactPersonEmail: data.contact_person_email,
        offerStatus: data.offer_status,
        noTax: data.no_tax,
        isArchive: data.is_archive,
        isInvoiced: data.is_invoiced,
        recipientId: getEntityId(data.recipient),
      };

      if (data.offer) {
        entity.offerId = getEntityId(data.offer);
      }

      if (data.certificate) {
        entity.certificateId = getEntityId(data.certificate);
      }

      if (data.histories) {
        entity.historyIds = (data.histories || []).map((history) => getEntityId(history) as number);
      }

      return entity;
    },
  }
);

const dashboardSchema = new schema.Entity('dashboard');

const countSchema = new schema.Entity<number>('count');

const metricsSchema = new schema.Entity<MetricsData>('metrics');

const recipientSchema = new schema.Entity<RecipientEntity>(
  'recipients',
  {},
  {
    processStrategy: (data: RecipientData) => {
      const entity: RecipientEntity = {
        ...data,
        costcenterNumber: data.costcenter,
        carrierNumber: data.carrier_number,
        carrierDescription: data.carrier_description,
        clientId: data.client_id,
        debitorId: data.debitor_id,
        clientNumber: data.client_number,
        clientDescription: data.client_description,
        addressId: getEntityId(data.address),
        addressData: data.address,
        jobId: getEntityId(data.job),
      };

      if (data.contacts) {
        entity.contactIds = (data.contacts || []).map((contact) => getEntityId(contact) as number);
      }

      return entity;
    },
  }
);

const addressSchema = new schema.Entity<AddressEntity>('addresses', {});

const offerSchema = new schema.Entity<OfferEntity>(
  'offers',
  {},
  {
    processStrategy: (data: OfferData) => {
      const entity: OfferEntity = {
        ...data,
        entryId: data.entry_id,
        creatorEmail: data.creator_email,
        creatorName: data.creator_name,
        creatorTel: data.creator_tel,
        confirmedBy: data.confirmed_by,
        confirmedReason: data.confirmed_reason,
        confirmedDate: data.confirmed_date,
        orderNumber: data.order_number,
        jobId: getEntityId(data.job),
        recipientId: getEntityId(data.recipient),
        pdfFileId: getEntityId(data.pdf),
      };

      if (data.files) {
        entity.fileIds = (data.files || []).map((file) => getEntityId(file) as number);
      } else {
        entity.fileIds = [];
      }

      return entity;
    },
  }
);

const publicOffersSchema = new schema.Entity('publicOffers');

const certificateSchema = new schema.Entity<CertificateEntity>(
  'certificates',
  {},
  {
    processStrategy: (data: CertificateData) => {
      const entity: CertificateEntity = {
        ...data,
        entryId: data.entry_id,
        creatorEmail: data.creator_email,
        creatorName: data.creator_name,
        creatorTel: data.creator_tel,
        confirmedBy: data.confirmed_by,
        confirmedReason: data.confirmed_reason,
        confirmedDate: data.confirmed_date,
        orderNumber: data.order_number,
        serviceStartDate: data.service_start_date ? new Date(data.service_start_date) : undefined,
        serviceEndDate: data.service_end_date ? new Date(data.service_end_date) : undefined,
        showPrices: data.show_prices,
        jobId: getEntityId(data.job),
        recipientId: getEntityId(data.recipient),
        pdfFileId: getEntityId(data.pdf),
      };

      if (data.files) {
        entity.fileIds = (data.files || []).map((file) => getEntityId(file) as number);
      } else {
        entity.fileIds = [];
      }

      return entity;
    },
  }
);

const publicCertificatesSchema = new schema.Entity('publicCertificates');

const fileSchema = new schema.Entity<FileEntity>('files', {});

const historySchema = new schema.Entity<HistoryEntity>(
  'histories',
  {},
  {
    processStrategy: (data: HistoryData) => {
      const entity: HistoryEntity = {
        ...data,
        actionDoneBy: data.action_done_by,
        targetStatus: data.target_status,
        recipientName: data.recipient_name,
        recipientEmail: data.recipient_email,
        recipientPersonList: data.recipient_person_list,
        diffOld: data.diff_old,
        diffNew: data.diff_new,
      };

      if (data.job) {
        entity.jobId = getEntityId(data.job) as number;
      }

      return entity;
    },
  }
);

/**
 * schema definitions
 */
companySchema.define({
  address: addressSchema,
  clients: [clientSchema],
  roomgroups: [roomgroupSchema],
  contacts: [contactSchema],
  crafts: [craftSchema],
});

clientSchema.define({
  services: [serviceSchema],
  company: companySchema,
  location: locationSchema,
  costcenter_rel: costcenterSchema,
  address: addressSchema,
  roomgroups: [roomgroupSchema],
  schedules: [scheduleSchema],
  contacts: [contactSchema],
  client_group: clientSchema,
});

serviceSchema.define({
  client: clientSchema,
});

locationSchema.define({
  clients: [clientSchema],
});

userSchema.define({
  role: roleSchema,
  costcenters: [costcenterSchema],
});

usersPermissionsRolesSchema.define({
  roles: [roleSchema],
});

costcenterSchema.define({
  users: [userSchema],
  clients: [clientSchema],
});

checkitemSchema.define({
  // defaultCheckitem: checkitemSchema,
  // checkitems: [checkitemSchema]
  checkitemtype: checkitemtypeSchema,
  checkitemgroup: checkitemgroupSchema,
});

checkitemgroupSchema.define({
  // defaultCheckitemgroup: checkitemgroupSchema,
  // checkitemgroups: [checkitemgroupSchema],
  checkitemgrouptype: checkitemgrouptypeSchema,
  roomgroup: roomgroupSchema,
  checkitems: [checkitemSchema],
});

roomgroupSchema.define({
  // defaultRoomgroup: roomgroupSchema,
  // roomgroups: [roomgroupSchema],
  craft: craftSchema,
  checkitemgroups: [checkitemgroupSchema],
});

scheduleSchema.define({
  client: clientSchema,
  craft: craftSchema,
  users: [userSchema], // TODO fix API: id is missing in users data
  contacts: [contactSchema],
  reports: [reportSchema],
});

contactSchema.define({
  recipients: [recipientSchema],
  reports: [reportSchema],
});

reportSchema.define({
  roomgroups: [roomgroupSchema],
  reporter: userSchema,
  schedule: scheduleSchema,
  client: clientSchema,
  craft: craftSchema,
  contacts: [contactSchema],
  reportrooms: [reportroomSchema],
});

reportroomSchema.define({
  roomgroup: roomgroupSchema,
  report: reportSchema,
  checkresults: [checkresultSchema],
});

checkresultSchema.define({
  reportroom: reportroomSchema,
  checkitem: checkitemSchema,
  pictures: [pictureSchema],
});

pictureSchema.define({
  related: [checkresultSchema],
});

jobSchema.define({
  recipient: recipientSchema,
  offer: offerSchema,
  certificate: certificateSchema,
  histories: [historySchema],
});

recipientSchema.define({
  address: addressSchema,
  contacts: [contactSchema],
  job: jobSchema,
});

offerSchema.define({
  job: jobSchema,
  recipient: recipientSchema,
  files: [fileSchema],
  pdf: fileSchema,
});

publicOffersSchema.define({
  offers: [offerSchema],
  certificates: [certificateSchema],
});

certificateSchema.define({
  job: jobSchema,
  recipient: recipientSchema,
  files: [fileSchema],
  pdf: fileSchema,
});

publicCertificatesSchema.define({
  offers: [offerSchema],
  certificates: [certificateSchema],
});

dashboardSchema.define({
  jobs: [jobSchema],
  metrics: metricsSchema,
  count: countSchema,
});

export {
  companySchema,
  clientSchema,
  serviceSchema,
  userSchema,
  permissionsSchema,
  roleSchema,
  usersPermissionsRolesSchema,
  costcenterSchema,
  checkitemtypeSchema,
  checkitemSchema,
  checkitemgrouptypeSchema,
  checkitemgroupSchema,
  craftSchema,
  roomgroupSchema,
  scheduleSchema,
  locationSchema,
  contactSchema,
  reportSchema,
  reportroomSchema,
  checkresultSchema,
  pictureSchema,
  jobSchema,
  recipientSchema,
  addressSchema,
  offerSchema,
  publicOffersSchema,
  certificateSchema,
  publicCertificatesSchema,
  fileSchema,
  historySchema,
  dashboardSchema,
};
