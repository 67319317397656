const IconChecklist = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M84.92,8.44a8.51,8.51,0,0,0-6.11-2.58H75V9.77a7.75,7.75,0,0,1-7.67,7.81H32.79a7.75,7.75,0,0,1-7.67-7.81V5.86H21.27a8.73,8.73,0,0,0-8.63,8.78L12.56,91.2a8.81,8.81,0,0,0,2.52,6.22A8.51,8.51,0,0,0,21.19,100H78.73a8.73,8.73,0,0,0,8.63-8.78l.08-76.56A8.81,8.81,0,0,0,84.92,8.44ZM51,35.16H71.42a2.93,2.93,0,0,1,0,5.86H51a2.93,2.93,0,0,1,0-5.86Zm0,19.53H71.42a2.93,2.93,0,0,1,0,5.86H51a2.93,2.93,0,0,1,0-5.86Zm0,19.53H71.5a2.93,2.93,0,0,1,0,5.86H51a2.93,2.93,0,0,1,0-5.86ZM26.47,35.27a2.85,2.85,0,0,1,4.07,0l1.56,1.6,6.63-6.76a2.83,2.83,0,0,1,4.07,0,3,3,0,0,1,0,4.14l-8.67,8.83a2.84,2.84,0,0,1-4.07,0l-3.59-3.67A3,3,0,0,1,26.47,35.27Zm0,21a2.85,2.85,0,0,1,4.07,0l1.56,1.6,6.63-6.76a2.85,2.85,0,0,1,4.07,0,3,3,0,0,1,0,4.14L34.13,64.1a2.84,2.84,0,0,1-4.07,0l-3.59-3.66A3,3,0,0,1,26.47,56.29Zm0,19.54a2.83,2.83,0,0,1,4.07,0l1.56,1.59,6.63-6.76a2.85,2.85,0,0,1,4.07,0,3,3,0,0,1,0,4.15l-8.67,8.82a2.84,2.84,0,0,1-4.07,0L26.47,80A3,3,0,0,1,26.47,75.83Zm4.4-66.06a1.94,1.94,0,0,0,1.92,2H67.31a1.94,1.94,0,0,0,1.92-2V2A1.93,1.93,0,0,0,67.31,0H32.79a1.93,1.93,0,0,0-1.92,2Z"
    />
  </svg>
);

export default IconChecklist;
