import { Checkbox, UiDiv } from '@webfox-sc/core';
import { useTranslation } from 'react-i18next';
import { QMFilterState } from '../../app/slices/qmOverviewSlice';

import { SHOW_QM_USER_FILTER } from '../../app.config';
import { themeCheckboxLarge } from '../../theme/theme';
import QMUtil from '../../utils/QMUtil';
import CustomReactSelect from '../CustomReactSelect';
import CustomDatePicker from '../atoms-v2/CustomDatePicker';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import { useAuth } from '../auth/useAuth';
import QMUserFilter from './QMUserFilter';

interface QMStatusFilterProps {
  filter: QMFilterState;
  onChangeFilter: (filter: QMFilterState) => void;
}

const QMStatusFilter: React.FC<QMStatusFilterProps> = ({ filter, onChangeFilter }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const qmStatusOptions = [
    {
      value: QMUtil.QM_STAUS_OPTONS.ALL,
      label: t('common.form.all'),
    },
    {
      value: QMUtil.QM_STAUS_OPTONS.OPEN,
      label: t('report.form.status.open'),
    },
    {
      value: QMUtil.QM_STAUS_OPTONS.PENDING,
      label: t('report.form.status.inProgress'),
    },
    {
      value: QMUtil.QM_STAUS_OPTONS.FINISHED,
      label: t('report.form.status.finished'),
    },
  ];

  const qmOrderByOptions = [
    {
      value: 'date:asc',
      label: `${t('common.fields.date')} (${t('common.form.ascending')})`,
    },
    {
      value: 'date:desc',
      label: `${t('common.fields.date')} (${t('common.form.descending')})`,
    },
    {
      value: 'company:asc',
      label: `${t('common.entities.company.singular')} (${t('common.form.ascending')})`,
    },
    {
      value: 'company:desc',
      label: `${t('common.entities.company.singular')} (${t('common.form.descending')})`,
    },
    {
      value: 'location:asc',
      label: `${t('common.fields.carrierNumber.short')} (${t('common.form.ascending')})`,
    },
    {
      value: 'location:desc',
      label: `${t('common.fields.carrierNumber.short')} (${t('common.form.descending')})`,
    },
    {
      value: 'score_percentage:asc',
      label: `${t('report.score')} (${t('common.form.ascending')})`,
    },
    {
      value: 'score_percentage:desc',
      label: `${t('report.score')} (${t('common.form.descending')})`,
    },
  ];

  const onChangeStatusValue = async (value) => {
    onChangeFilter({
      status: value,
    });
  };

  const onChangeDates = (months: [Date | null, Date | null]) => {
    onChangeFilter({
      startDate: months[0] || null,
      endDate: months[1] || null,
    });
  };

  const onChangeOrderByValue = async (value) => {
    onChangeFilter({
      orderBy: value,
    });
  };

  const onChangeOwn = async (value) => {
    onChangeFilter({
      userId: value ? currentUser?.id : null,
    });
  };

  const onChangeReporter = async (userId) => {
    onChangeFilter({
      userId: userId || null,
    });
  };

  return (
    <UiDiv display="grid" gridTemplateColumns="1fr 1fr" columnGap="xs" rowGap="xs" marginTop="xs">
      <UiDiv>
        <FormElementLabel color="white">{t('common.fields.status')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          options={qmStatusOptions}
          selectedValue={filter?.status || QMUtil.QM_STAUS_OPTONS.ALL}
          defaultValue={QMUtil.QM_STAUS_OPTONS.ALL}
          onChangeValue={onChangeStatusValue}
        />
      </UiDiv>
      <UiDiv>
        <FormElementLabel color="white">{t('common.fields.timeSpan')}</FormElementLabel>
        <CustomDatePicker
          popperPlacement="top-start"
          variant="outline"
          selectsRange={true}
          startDate={filter?.startDate}
          endDate={filter?.endDate}
          onChange={onChangeDates}
          isClearable={false}
          dateFormat="dd.MM.yyyy"
        />
      </UiDiv>
      <UiDiv>
        <FormElementLabel color="white">{t('common.form.sortBy')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          options={qmOrderByOptions}
          selectedValue={filter?.orderBy || qmOrderByOptions[0].value}
          defaultValue={qmOrderByOptions[0].value}
          onChangeValue={onChangeOrderByValue}
        />
      </UiDiv>
      <UiDiv>
        {SHOW_QM_USER_FILTER ? (
          <QMUserFilter onChange={onChangeReporter} selectedValue={filter?.userId || null}/>
        ) : (
          <>
            <FormElementLabel color="white">{t('report.form.showOwn')}</FormElementLabel>
            <Checkbox
              checked={!!filter?.userId}
              value=""
              onChange={(e) => onChangeOwn(e.target.checked)}
              theme={themeCheckboxLarge}
            />
          </>
        )}
      </UiDiv>
    </UiDiv>
  );
};

export default QMStatusFilter;
