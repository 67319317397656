/* xeslint-disable */

class JobUtil {
    static getServiceNetPrice(unitPrice, quantity) {
        const _unitPrice = unitPrice || 0;
        const _quantity = quantity || 0;
        const netPrice = Math.round(_unitPrice * _quantity * 100) / 100;
        return netPrice;
    }

    /**
     * get total net price of services
     * @param {Array} services
     */
    static getServicesTotalNetPrice(services = []) {
        let totalNetPrice = 0;
        for (let i = 0; i < services.length; i += 1) {
            totalNetPrice += JobUtil.getServiceNetPrice(services[i].unitPrice, services[i].quantity);
        }
        return totalNetPrice;
    }
}

export default JobUtil;
