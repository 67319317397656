import { REDUX_ACTIONS } from '../global/constants';

function importsReducer(
    state = {
        isImportFileRequest: false,
        isImportRequest: false,
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_IMPORTS:
            return { ...state, isImportFileRequest: true };

        case REDUX_ACTIONS.REQUEST_IMPORTS_ERROR:
            return { ...state, isImportFileRequest: false };

        case REDUX_ACTIONS.RECEIVE_IMPORTS:
            return { ...state, isImportFileRequest: false };

        case REDUX_ACTIONS.REQUEST_UPLOAD_IMPORTS:
            return { ...state, isImportFileRequest: true };

        case REDUX_ACTIONS.REQUEST_UPLOAD_IMPORTS_ERROR:
            return { ...state, isImportFileRequest: false };

        case REDUX_ACTIONS.RECEIVE_UPLOAD_IMPORTS:
            return { ...state, isImportFileRequest: false };

        case REDUX_ACTIONS.REQUEST_DELETE_IMPORT:
            return { ...state, isImportFileRequest: true };

        case REDUX_ACTIONS.REQUEST_DELETE_IMPORT_ERROR:
            return { ...state, isImportFileRequest: false };

        case REDUX_ACTIONS.RECEIVE_DELETE_IMPORT:
            return { ...state, isImportFileRequest: false };

        case REDUX_ACTIONS.REQUEST_DELETE_IMPORTS:
            return { ...state, isImportFileRequest: true };

        case REDUX_ACTIONS.REQUEST_DELETE_IMPORTS_ERROR:
            return { ...state, isImportFileRequest: false };

        case REDUX_ACTIONS.RECEIVE_DELETE_IMPORTS:
            return { ...state, isImportFileRequest: false };

        case REDUX_ACTIONS.REQUEST_IMPORT_IMPORTS:
            return { ...state, isImportRequest: true };

        case REDUX_ACTIONS.REQUEST_IMPORT_IMPORTS_ERROR:
            return { ...state, isImportRequest: false };

        case REDUX_ACTIONS.RECEIVE_IMPORT_IMPORTS:
            return { ...state, isImportRequest: false };

        default:
            return state;
    }
}

export default importsReducer;
