import { useState } from 'react';
import { useAppDispatch } from '../../app/appHooks';
import { useJob } from '../../app/entityHooks/useJob';
import { useOffer } from '../../app/entityHooks/useOffer';
import { createOffer, createOfferPDF, deleteOfferFile, uploadOfferFiles } from '../../app/slices/offersSlice';
import { createRecipient } from '../../app/slices/recipientsSlice';
import AppUtil from '../../utils/AppUtil';
import { useAuth } from '../auth/useAuth';

export const useJobOffer = (
  jobId: number | undefined
): {
  offer: OfferEntity | undefined;
  createOfferStatus: RequestStatus;
  handleCreateOffer: (
    orderNumber: string | undefined,
    location: string | undefined,
    comments: string | undefined
  ) => Promise<boolean>;
  handleCreateOfferPDF: () => void;
  handleUploadOfferFiles: (fileList: FileList) => void;
  handleDeleteOfferFile: (fileId: number) => void;
} => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();
  const { job, recipient: jobRecipient } = useJob(jobId);
  const { offer } = useOffer(job?.offerId);

  const [createOfferStatus, setCreateOfferStatus] = useState<RequestStatus>('idle');

  const handleCreateOffer = async (
    orderNumber: string | undefined,
    location: string | undefined,
    comments: string | undefined
  ) => {
    if (!job || !jobRecipient) {
      return false;
    }

    // request status
    setCreateOfferStatus('loading');

    // -- create offer
    const resOfferCreated = await dispatch(
      createOffer({
        job: job.id,
        order_number: orderNumber,
        location,
        comments,
        confirmed: 'INIT',
        entry_id: `${job.entryId}-A`,
        services: job.services,
        creator_name: currentUser?.username,
        creator_email: currentUser?.email,
        creator_tel: currentUser?.tel,
      })
    );
    const offerCreatedData = resOfferCreated.payload?.data;
    if (!offerCreatedData) {
      return false;
    }

    // -- create offer recipient
    const resRecipientCreated = await dispatch(
      createRecipient({
        client_id: jobRecipient.clientId,
        debitor_id: jobRecipient.debitorId,
        costcenter: jobRecipient.costcenterNumber,
        carrier_number: jobRecipient.carrierNumber,
        carrier_description: jobRecipient.carrierDescription,
        client_number: jobRecipient.clientNumber,
        client_description: jobRecipient.clientDescription,
        contacts: jobRecipient.contactIds,
        address: jobRecipient.addressData,
        offer: offerCreatedData.id,
      })
    );
    const recipientCreatedData = resRecipientCreated.payload?.data;
    if (!recipientCreatedData) {
      return false;
    }

    // request status
    setCreateOfferStatus('idle');

    // -- return true
    return true;
  };

  const handleCreateOfferPDF = async () => {
    if (offer?.id) {
      const res = await dispatch(createOfferPDF(offer.id));
      AppUtil.openDownload(res.payload?.data);
    }
  };

  const handleUploadOfferFiles = async (fileList: FileList) => {
    if (offer?.id) {
      const formData = new FormData();
      Object.values(fileList).forEach((file) => {
        formData.append('files', file, `${offer.entryId}-${file.name}`);
      });
      dispatch(uploadOfferFiles(offer?.id, formData));
    }
  };

  const handleDeleteOfferFile = async (fileId: number) => {
    if (offer?.id) {
      dispatch(deleteOfferFile(offer.id, fileId));
    }
  };

  return {
    offer,
    createOfferStatus,
    handleCreateOffer,
    handleCreateOfferPDF,
    handleUploadOfferFiles,
    handleDeleteOfferFile,
  };
};
