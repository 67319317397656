import React from 'react';
import { InlineSVG, UiDiv, UiDivProps, UiText } from '@webfox-sc/core';

import IconInfo from '../../assets/icons-v2/IconInfo';

const PageNoticeBox: React.FC<UiDivProps> = ({ children, ...props }) => {
  return (
    <UiDiv padding="m" background="ciBlue3" maxWidth="640px" margin="0px auto" {...props}>
      <UiDiv display="flex" justifyContent="center" color="white">
        <InlineSVG width="30px" height="30px">
          {IconInfo}
        </InlineSVG>
      </UiDiv>
      <UiText variant="copy" color="white" textAlign="center" marginTop="s">
        {children}
      </UiText>
    </UiDiv>
  );
};

export default PageNoticeBox;
