const IconList = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M92.45,72.47H32.07a7.55,7.55,0,0,0,0,15.1H92.45a7.55,7.55,0,0,0,0-15.1Zm0-30H32.07a7.55,7.55,0,0,0,0,15.1H92.45a7.55,7.55,0,0,0,0-15.1Zm-60.38-15H92.45a7.55,7.55,0,1,0,0-15.1H32.07a7.55,7.55,0,0,0,0,15.1Zm-24.41.18A7.67,7.67,0,1,0,0,20,7.66,7.66,0,0,0,7.66,27.65Zm0,30A7.67,7.67,0,1,0,0,50,7.66,7.66,0,0,0,7.66,57.63Zm0,30.05A7.67,7.67,0,1,0,0,80,7.66,7.66,0,0,0,7.66,87.68Z"
    />
  </svg>
);

export default IconList;
