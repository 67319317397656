import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { useCheckitem } from '../../../app/entityHooks/useCheckitem';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconEdit from '../../../assets/icons-v2/IconEdit';
import IconButton from '../../atoms-v2/IconButton';
import TextIcon from '../../atoms-v2/TextIcon';

import { useTheme } from 'styled-components';
import BaseDataQMCheckitemForm from './BaseDataQMCheckitemForm';
import BaseDataQMDragHandle from './BaseDataQMDragHandle';

interface BaseDataQMCheckitemProps {
  checkitemId: number | undefined;
  checkitemgroupId: number | undefined;
  index: number;
}

const BaseDataQMCheckitem: React.FC<BaseDataQMCheckitemProps> = ({ checkitemId, checkitemgroupId, index }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { checkitem, checkitemtype } = useCheckitem(checkitemId);
  const [showEditForm, setShowEditForm] = useState(false);

  const dragItem: DragItem = { index, id: checkitemId || 0, parentId: checkitemgroupId };

  const [{ isDragging }, dragHandle, dragPreview] = useDrag(
    () => ({
      type: 'checkitem',
      item: dragItem,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [dragItem, index, checkitemgroupId]
  );

  if (!checkitem) {
    return null;
  }

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0 : 1 }}>
      <UiDiv padding="15px" background="white">
        <UiDiv display="grid" gridTemplateColumns="auto 1fr 20% 20% 22px" columnGap="s" alignItems="center">
          <UiDiv>
            <BaseDataQMDragHandle itemRef={dragHandle} />
          </UiDiv>
          <UiText variant="copy">
            <b>{checkitemtype?.name}</b>
            <br />
            {checkitem.question || '–'}
          </UiText>
          <UiText variant="copySmall" textAlign="right">
            {t('checkitem.common.weight')}: <b>{checkitem.weight || '–'}</b>
          </UiText>
          <UiDiv display="flex" gap="xs" justifyContent="flex-end" alignItems="center">
            {!checkitem.disabled && (
              <>
                <TextIcon icon={IconCheck} color={colors.trafficLightGreen} />
                <UiText variant="copySmall">{t('common.qm.preSelected')}</UiText>
              </>
            )}
          </UiDiv>
          <IconButton icon={IconEdit} onClick={() => setShowEditForm(!showEditForm)} />
        </UiDiv>
      </UiDiv>
      <Collapse show={showEditForm} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey20">
            <BaseDataQMCheckitemForm
              checkitemId={checkitem.id}
              onSubmit={() => setShowEditForm(false)}
              onClickCancel={() => setShowEditForm(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
    </div>
  );
};

export default BaseDataQMCheckitem;
