import React from 'react';
import { UiDiv } from '@webfox-sc/core';

import JobHistoryListItem from './JobHistoryListItem';
import { useJobHistories } from './useJobHistories';

interface JobHistoryListProps {
  jobId?: number | undefined;
  type: 'offer' | 'certificate' | 'job';
}

const JobHistoryList: React.FC<JobHistoryListProps> = ({ jobId, type }) => {
  const { offerHistoriesSorted, certificateHistoriesSorted, jobHistoriesSorted } = useJobHistories(jobId);

  const histories =
    type === 'offer'
      ? offerHistoriesSorted
      : type === 'certificate'
      ? certificateHistoriesSorted
      : type === 'job'
      ? jobHistoriesSorted
      : [];

  if (!histories.length) {
    return null;
  }

  return (
    <UiDiv display="flex" flexDirection="column" rowGap="xxs">
      {histories.map((history) => (
        <JobHistoryListItem key={history.id} historyId={history.id} />
      ))}
    </UiDiv>
  );
};

export default JobHistoryList;
