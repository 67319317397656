import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities } from '../requestEntities';
import { getCompleteEntities } from '../schemaUtils';

const locationsAdapter = createEntityAdapter<LocationEntity>();

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: locationsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.locations) {
        const completeEntities = getCompleteEntities(action.payload.entities.locations);
        if (Object.keys(completeEntities).length > 0) {
          locationsAdapter.upsertMany(state, completeEntities);
        }
      }
    });
  },
});

export default locationsSlice.reducer;
