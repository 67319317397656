/* eslint-disable camelcase */
import _isEqual from 'lodash/isEqual';
import AddressUtil from './AddressUtil';
import AppUtil from './AppUtil';
import ClientsUtil from './ClientsUtil';
import CompaniesUtil from './CompaniesUtil';
import CostcenterUtil from './CostcenterUtil';
import LocationsUtil from './LocationsUtil';
import ServicesUtil from './ServicesUtil';

class DataReducerUtil {
  constructor() {
    this.state = {};
  }

  setState(state) {
    this.state = { ...state };
  }

  setJob(jobData) {
    if (!jobData || typeof jobData !== 'object') {
      return this.state;
    }

    const {
      id,
      createdAt,
      updatedAt,
      creator_id,
      entry_id,
      no_tax,
      costcenter,
      carrier_number,
      client_id,
      client_name,
      contact_person_name,
      contact_person_email,
      services,
      totalPrice,
      status,
      offer_status,
      is_archive,
      recipient,
      // certificate_id, // TODO OBSOLETE ?
      certificate,
      // offer_id, // TODO OBSOLETE ?
      offer,
      histories,
    } = jobData;

    this.setRecipient(recipient);
    this.setCertificate(certificate);
    this.setOffer(offer);
    this.setHistories(histories);

    const job = {
      id: id || null,
      createdAt: createdAt || null,
      updatedAt: updatedAt || null,
      creatorId: creator_id || null,
      entryId: entry_id || null,
      noTax: no_tax || false,
      costcenter: costcenter || null,
      carrierNumber: carrier_number || null,
      clientId: client_id || null,
      clientName: client_name || null,
      contactPersonName: contact_person_name || null,
      contactPersonEmail: contact_person_email || null,
      services: services || [],
      totalPrice: totalPrice || null,
      status: status || null,
      offerStatus: offer_status || null,
      isArchive: is_archive || false,
      recipientId: AppUtil.getEntryId(recipient),
      certificateId: AppUtil.getEntryId(certificate),
      offerId: AppUtil.getEntryId(offer),
      historyIds: [],
    };

    if (histories && Array.isArray(histories)) {
      job.historyIds = histories.map((history) => AppUtil.getEntryId(history));
    }

    if (!_isEqual(job, this.state.jobs[id])) {
      this.state.jobs = { ...this.state.jobs };
      this.state.jobs[id] = job;
    }

    return this.state;
  }

  setJobs(jobsData) {
    if (!jobsData || !Array.isArray(jobsData)) {
      return this.state;
    }

    jobsData.forEach((jobData) => {
      this.setJob(jobData);
    });

    return this.state;
  }

  setCertificate(certificateData, options = {}) {
    if (!certificateData || typeof certificateData !== 'object') {
      return this.state;
    }

    const { setJob } = options;

    const {
      id,
      createdAt,
      updatedAt,
      entry_id,
      creator_name,
      creator_email,
      creator_tel,
      date,
      order_number,
      location,
      comments,
      service_start_date,
      service_end_date,
      show_prices,
      confirmed,
      confirmed_by,
      confirmed_reason,
      confirmed_date,
      job,
      recipient,
      files,
      pdf,
    } = certificateData;

    if (setJob) {
      this.setJob(job); // TODO: job data here is invalid !
    }
    this.setRecipient(recipient);

    const certificate = {
      id: id || null,
      createdAt: createdAt || null,
      updatedAt: updatedAt || null,
      entryId: entry_id || null,
      creatorName: creator_name || null,
      creatorEmail: creator_email || null,
      creatorTel: creator_tel || null,
      date: date || null,
      orderNumber: order_number || null,
      location: location || null,
      comments: comments || null,
      serviceStartDate: service_start_date || null,
      serviceEndDate: service_end_date || null,
      showPrices: show_prices || false,
      confirmed: confirmed || null,
      confirmedBy: confirmed_by || null,
      confirmedReason: confirmed_reason || null,
      confirmedDate: confirmed_date || null,
      jobId: AppUtil.getEntryId(job),
      recipientId: AppUtil.getEntryId(recipient),
      files: files || [],
      pdf: pdf || null,
    };

    if (!_isEqual(certificate, this.state.certificates[id])) {
      this.state.certificates = { ...this.state.certificates };
      this.state.certificates[id] = certificate;
    }

    return this.state;
  }

  setCertificatePdf(pdf, certificateId) {
    const certificate = this.state.certificates[certificateId];
    if (certificate) {
      if (!_isEqual(pdf, certificate.pdf)) {
        this.state.certificates[certificateId] = { ...certificate, pdf };
      }
    }
    return this.state;
  }

  setCertificates(certificatesData, options) {
    if (!certificatesData || !Array.isArray(certificatesData)) {
      return this.state;
    }

    certificatesData.forEach((certificateData) => {
      this.setCertificate(certificateData, options);
    });

    return this.state;
  }

  setOffer(offerData, options = {}) {
    if (!offerData || typeof offerData !== 'object') {
      return this.state;
    }

    const { setJob } = options;

    const {
      id,
      createdAt,
      updatedAt,
      entry_id,
      creator_name,
      creator_email,
      creator_tel,
      order_number,
      location,
      comments,
      services,
      confirmed,
      confirmed_by,
      confirmed_reason,
      confirmed_date,
      job,
      recipient,
      files,
      pdf,
    } = offerData;

    if (setJob) {
      this.setJob(job); // TODO: job data here is invalid !
    }
    this.setRecipient(recipient);

    const offer = {
      id: id || null,
      createdAt: createdAt || null,
      updatedAt: updatedAt || null,
      entryId: entry_id || null,
      creatorName: creator_name || null,
      creatorEmail: creator_email || null,
      creatorTel: creator_tel || null,
      orderNumber: order_number || null,
      location: location || null,
      comments: comments || null,
      services: services || [],
      confirmed: confirmed || null,
      confirmedBy: confirmed_by || null,
      confirmedReason: confirmed_reason || null,
      confirmedDate: confirmed_date || null,
      jobId: AppUtil.getEntryId(job),
      recipientId: AppUtil.getEntryId(recipient),
      files: files || [],
      pdf: pdf || null,
    };

    if (!_isEqual(offer, this.state.offers[id])) {
      this.state.offers = { ...this.state.offers };
      this.state.offers[id] = offer;
    }

    return this.state;
  }

  setOfferPdf(pdf, offerId) {
    const offer = this.state.offers[offerId];
    if (offer) {
      if (!_isEqual(pdf, offer.pdf)) {
        this.state.offers[offerId] = { ...offer, pdf };
      }
    }
    return this.state;
  }

  setOffers(offersData, options) {
    if (!offersData || !Array.isArray(offersData)) {
      return this.state;
    }

    offersData.forEach((offerData) => {
      this.setOffer(offerData, options);
    });

    return this.state;
  }

  setOffersAndCertificates(offersData, certificatesData, options = {}) {
    this.setOffers(offersData, options);
    this.setCertificates(certificatesData, options);
    return this.state;
  }

  setRecipient(recipientData) {
    if (!recipientData || typeof recipientData !== 'object') {
      return this.state;
    }

    const {
      id,
      createdAt,
      updatedAt,
      job_id,
      certificate_id,
      offer_id,
      costcenter,
      carrier_number,
      carrier_description,
      client_id,
      client_number,
      client_description,
      contacts,
      address,
    } = recipientData;

    this.setContacts(contacts);

    const recipient = {
      id: id || null,
      createdAt: createdAt || null,
      updatedAt: updatedAt || null,
      jobId: job_id || null,
      certificateId: certificate_id || null,
      offerId: offer_id || null,
      costcenter: costcenter || null,
      carrierNumber: carrier_number || null,
      carrierDescription: carrier_description || null,
      clientId: client_id || null,
      clientNumber: client_number || null,
      clientDescription: client_description || null,
      contactIds: [],
      address: address || null,
    };

    if (contacts && Array.isArray(contacts)) {
      recipient.contactIds = contacts.map((contact) => AppUtil.getEntryId(contact));
    }

    if (!_isEqual(recipient, this.state.recipients[id])) {
      this.state.recipients = { ...this.state.recipients };
      this.state.recipients[id] = recipient;
    }

    return this.state;
  }

  setContact(contactData) {
    if (!contactData || typeof contactData !== 'object') {
      return this.state;
    }

    const { id, name, email, recipients } = contactData;

    const contact = {
      id: id || null,
      name: name || null,
      email: email || null,
      recipientIds: [],
    };

    if (recipients && Array.isArray(recipients)) {
      contact.recipientIds = recipients.map((recipient) => AppUtil.getEntryId(recipient));
    }

    if (!_isEqual(contact, this.state.contacts[id])) {
      this.state.contacts = { ...this.state.contacts };
      this.state.contacts[id] = contact;
    }

    return this.state;
  }

  setContacts(contactsData) {
    if (!contactsData || !Array.isArray(contactsData)) {
      return this.state;
    }

    contactsData.forEach((contactData) => {
      this.setContact(contactData);
    });

    return this.state;
  }

  setHistory(historyData) {
    if (!historyData || typeof historyData !== 'object') {
      return this.state;
    }

    const {
      id,
      createdAt,
      updatedAt,
      job_id,
      target_status,
      action_done_by,
      comment,
      recipient_name,
      recipient_email,
      recipient_person_list,
    } = historyData;

    const history = {
      id: id || null,
      createdAt: createdAt || null,
      updatedAt: updatedAt || null,
      jobId: job_id || null,
      targetStatus: target_status || '',
      actionDoneBy: action_done_by || null,
      comment: comment || null,
      recipientName: recipient_name || null,
      recipientEmail: recipient_email || null,
      recipientPersonList: recipient_person_list || [],
    };

    if (!_isEqual(history, this.state.histories[id])) {
      this.state.histories = { ...this.state.histories };
      this.state.histories[id] = history;
    }

    return this.state;
  }

  setHistories(historiesData) {
    if (!historiesData) {
      return this.state;
    }

    historiesData.forEach((historyData) => {
      this.setHistory(historyData);
    });

    return this.state;
  }

  setCompany(companyData, options = {}) {
    if (!companyData || typeof companyData !== 'object') {
      return this.state;
    }

    const company = CompaniesUtil.parseData(companyData, options);

    if (!_isEqual(company, this.state.companies[company.id])) {
      this.state.companies = { ...this.state.companies };
      this.state.companies[company.id] = company;
    }

    if (companyData.address && typeof companyData.address === 'object') {
      this.setAddress(companyData.address, options);
    }

    return this.state;
  }

  setCompanies(companiesData) {
    if (!companiesData) {
      return this.state;
    }
    // const start = new Date();
    companiesData.forEach((companyData) => {
      this.setCompany(companyData);
    });
    // const end = new Date();
    // console.log(`time to load clients: ${(end.getTime() - start.getTime()) / 1000}`);
    return this.state;
  }

  setCompaniesCount(count) {
    this.state.companiesCount = count;
    return this.state;
  }

  deleteCompany(companyId) {
    const companies = { ...this.state.companies };
    delete companies[companyId];
    this.state.companies = companies;

    return this.state;
  }

  deleteAllCompanies(deletedCompanies) {
    const companies = { ...this.state.companies };
    if (deletedCompanies) {
      deletedCompanies.forEach(({ id }) => {
        delete companies[id];
      });
    }
    this.state.companies = companies;
    return this.state;
  }

  setLocation(locationData, options = {}) {
    if (!locationData || typeof locationData !== 'object') {
      return this.state;
    }

    const location = LocationsUtil.parseData(locationData, options);

    if (!_isEqual(location, this.state.locations[location.id])) {
      this.state.locations = { ...this.state.locations };
      this.state.locations[location.id] = location;
    }

    return this.state;
  }

  setLocations(locationsData) {
    if (!locationsData) {
      return this.state;
    }
    // const start = new Date();
    locationsData.forEach((locationData) => {
      this.setLocation(locationData);
    });
    // const end = new Date();
    // console.log(`time to load clients: ${(end.getTime() - start.getTime()) / 1000}`);
    return this.state;
  }

  setLocationsCount(count) {
    this.state.locationsCount = count;
    return this.state;
  }

  deleteLocation(locationId) {
    const locations = { ...this.state.locations };
    delete locations[locationId];
    this.state.locations = locations;

    return this.state;
  }

  deleteAllLocations(deletedLocations) {
    const locations = { ...this.state.locations };
    if (deletedLocations) {
      deletedLocations.forEach(({ id }) => {
        delete locations[id];
      });
    }
    this.state.locations = locations;
    return this.state;
  }

  setCostcenter(costcenterData, options = {}) {
    if (!costcenterData || typeof costcenterData !== 'object') {
      return this.state;
    }

    const costcenter = CostcenterUtil.parseData(costcenterData, options);

    if (!_isEqual(costcenter, this.state.costcenters[costcenter.id])) {
      this.state.costcenters = { ...this.state.costcenters };
      this.state.costcenters[costcenter.id] = costcenter;
    }

    return this.state;
  }

  setCostcenters(costcentersData, options = {}) {
    if (!costcentersData) {
      return this.state;
    }
    const { oldCostcenter, ...additionalOptions } = options;
    // const start = new Date();
    costcentersData.forEach((costcenterData) => {
      if (oldCostcenter && oldCostcenter.id === costcenterData.id) {
        this.setCostcenter(costcenterData, options);
      } else {
        this.setCostcenter(costcenterData, additionalOptions);
      }
    });
    // const end = new Date();
    // console.log(`time to load clients: ${(end.getTime() - start.getTime()) / 1000}`);
    return this.state;
  }

  setCostcentersCount(count) {
    this.state.costcentersCount = count;
    return this.state;
  }

  deleteCostcenter(costcenterId) {
    const costcenters = { ...this.state.costcenters };
    delete costcenters[costcenterId];
    this.state.costcenters = costcenters;

    return this.state;
  }

  deleteAllCostcenters(deletedCostcenters) {
    const costcenters = { ...this.state.costcenters };
    if (deletedCostcenters) {
      deletedCostcenters.forEach(({ id }) => {
        delete costcenters[id];
      });
    }
    this.state.costcenters = costcenters;
    return this.state;
  }

  setClient(clientData, options = {}) {
    if (!clientData || typeof clientData !== 'object') {
      return this.state;
    }

    const { parseServices } = options;

    const client = ClientsUtil.parseData(clientData, options);

    const { services } = clientData;

    if (services) {
      client.serviceIds = services.map((service) => AppUtil.getEntryId(service));
      if (parseServices) {
        this.setServices(services);
      }
    } else {
      client.serviceIds = [];
    }

    if (!_isEqual(client, this.state.clients[client.id])) {
      this.state.clients = { ...this.state.clients };
      this.state.clients[client.id] = client;
    }

    if (clientData.address && typeof clientData.address === 'object') {
      this.setAddress(clientData.address, options);
    }

    return this.state;
  }

  setClients(clientsData) {
    if (!clientsData) {
      return this.state;
    }
    // const start = new Date();
    clientsData.forEach((clientData) => {
      this.setClient(clientData, { parseServices: false });
    });
    // const end = new Date();
    // console.log(`time to load clients: ${(end.getTime() - start.getTime()) / 1000}`);
    return this.state;
  }

  setClientsCount(count) {
    this.state.clientsCount = count;
    return this.state;
  }

  deleteClientLegacy(client) {
    if (client) {
      const { id } = client;
      const clients = { ...this.state.clients };
      delete clients[id];
      this.state.clients = clients;
    }
    return this.state;
  }

  deleteAllClients(deletedClients) {
    const clients = { ...this.state.clients };
    if (deletedClients) {
      deletedClients.forEach(({ id }) => {
        delete clients[id];
      });
    }
    this.state.clients = clients;
    return this.state;
  }

  setService(serviceData, options = {}) {
    if (!serviceData || typeof serviceData !== 'object') {
      return this.state;
    }

    const service = ServicesUtil.parseData(serviceData, options);

    if (!_isEqual(service, this.state.services[service.id])) {
      this.state.services = { ...this.state.services };
      this.state.services[service.id] = service;
    }

    return this.state;
  }

  setServices(servicesData, options = {}) {
    if (!servicesData) {
      return this.state;
    }
    const { oldServices, ...otherOptions } = options;

    servicesData.forEach((serviceData) => {
      const serviceOptions = { ...otherOptions };
      if (oldServices) {
        const { id } = serviceData;
        const oldService = oldServices[id];
        if (oldService) {
          serviceOptions.oldService = oldService;
        }
      }
      this.setService(serviceData, serviceOptions);
    });

    return this.state;
  }

  setServicesCount(count) {
    this.state.servicesCount = count;
    return this.state;
  }

  deleteService(service) {
    if (service) {
      const { id } = service;
      const services = { ...this.state.services };
      delete services[id];
      this.state.services = services;
    }
    return this.state;
  }

  deleteAllServices(deletedServices) {
    const services = { ...this.state.services };
    if (deletedServices) {
      deletedServices.forEach(({ id }) => {
        delete services[id];
      });
    }
    this.state.services = services;
    return this.state;
  }

  setAddress(addressData, options) {
    if (!addressData || typeof addressData !== 'object') {
      return this.state;
    }

    const address = AddressUtil.parseData(addressData, options);

    if (!_isEqual(address, this.state.addresses[address.id])) {
      this.state.addresses = { ...this.state.addresses };
      this.state.addresses[address.id] = address;
    }

    return this.state;
  }

  setImport(importData) {
    if (!importData || typeof importData !== 'object') {
      return this.state;
    }

    const { id, createdAt, schema, encoding, auto_import, import_time, imported, log, file, data, errors, report } =
      importData;

    const parsedData = {};
    if (data) {
      const {
        company: companies,
        location: locations,
        client: clients,
        clientGroup: clientGroups,
        service: services,
        costcenter: costcenters,
        address: addresses,
        locationAddress: locationAddresses,
        clientAddress: clientAddresses,
      } = data;
      if (companies) {
        parsedData.companies = companies.map(CompaniesUtil.parseData);
      }
      if (locations) {
        parsedData.locations = locations.map(LocationsUtil.parseData);
      }
      if (clients) {
        parsedData.clients = clients.map(ClientsUtil.parseData);
      }
      if (clientGroups) {
        parsedData.clientGroups = clientGroups.map(ClientsUtil.parseData);
      }
      if (services) {
        parsedData.services = services.map(ServicesUtil.parseData);
      }
      if (costcenters) {
        parsedData.costcenters = costcenters.map(CostcenterUtil.parseData);
      }
      if (addresses) {
        parsedData.addresses = addresses.map(AddressUtil.parseData);
      }
      if (locationAddresses) {
        parsedData.locationAddresses = locationAddresses.map(AddressUtil.parseData);
      }
      if (clientAddresses) {
        parsedData.clientAddresses = clientAddresses.map(AddressUtil.parseData);
      }
    }
    const parsedEntry = {
      id,
      file,
      errors,
      report,
      data: parsedData,
      log,
      schema,
      encoding,
      autoImport: auto_import,
      importTime: import_time,
      createdAt,
      imported,
    };

    if (!_isEqual(parsedEntry, this.state.importFiles[importData.id])) {
      this.state.importFiles = { ...this.state.importFiles };
      this.state.importFiles[importData.id] = parsedEntry;
    }

    return this.state;
  }

  setImports(data, deleteAll) {
    if (deleteAll) {
      this.state.importFiles = {};
    }
    if (data) {
      data.forEach((entry) => {
        this.setImport(entry);
      });
    }
    return this.state;
  }

  deleteImportFile(file) {
    if (file) {
      const { id } = file;
      const importFiles = { ...this.state.importFiles };
      delete importFiles[id];
      this.state.importFiles = importFiles;
    }
    return this.state;
  }

  setImportData() {
    // TODO
  }
}

export default DataReducerUtil;
