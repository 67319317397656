import { UiDiv, UiText } from '@webfox-sc/core';
import _set from 'lodash/set';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../app/appHooks';
import { fetchCompanies, selectCompaniesState } from '../../../app/slices/companiesSlice';
import { limitInterval, selectQMAdminState, setQMAdminState } from '../../../app/slices/qmAdminSlice';
import { fetchGlobalRoomgroups } from '../../../app/slices/roomgroupsSlice';
import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconNotFound from '../../../assets/icons-v2/IconNotFound';
import IconSearch from '../../../assets/icons-v2/IconSearch';
import QMUtil from '../../../utils/QMUtil';
import Button from '../../atoms-v2/Button';
import Checkbox from '../../atoms-v2/Checkbox';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import LoadingWrapper from '../../atoms-v2/LoadingWrapper';
import NoResultsNotice from '../../atoms-v2/NoResultsNotice';
import TextInput from '../../atoms-v2/TextInput';
import PageContent from '../../page/PageContent';
import PageHeading from '../../page/PageHeading';

import QMAdminStartCompany from './QMAdminStartCompany';

const QMAdminStart: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchInputValue, searchValue, showClientsWithSchedules, limit } = useAppSelector(selectQMAdminState);
  const { fetchCompaniesStatus } = useAppSelector(selectCompaniesState);

  const [companyIds, setCompanyIds] = useState<number[]>([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);

  useEffect(() => {
    dispatch(fetchGlobalRoomgroups());
  }, [dispatch]);

  const fetchCompanyData = useCallback(async () => {
    const params: {
      [key: string]: any;
    } = {
      // _schedules: showClientsWithSchedules,
      _sort: 'name:ASC',
    };

    if (searchValue !== '') {
      params.filters = {
        $or: [
          {
            name: { $containsi: searchValue },
          },
          {
            number: { $contains: searchValue },
          },
        ],
      };
    }

    if (showClientsWithSchedules) {
      _set(params, ['filters', 'clients', 'schedules', 'id', '$notNull'], true);
    }

    const companyData = await dispatch(
      fetchCompanies({ _limit: limit, populate: QMUtil.companyPopulateOptions, ...params }, true)
    );

    setCompanyCount(companyData.payload?.count || 0);
    setCompanyIds((companyData.payload?.data || []).map(({ id }) => id));
    setCompaniesLoaded(true);
  }, [dispatch, limit, searchValue, showClientsWithSchedules]);

  useEffect(() => {
    setCompaniesLoaded(false);
  }, [searchValue, showClientsWithSchedules]);

  useEffect(() => {
    fetchCompanyData();
  }, [fetchCompanyData]);

  const handleOnSubmitSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    dispatch(
      setQMAdminState({
        searchValue: searchInputValue,
        limit: limitInterval,
      })
    );
  };

  return (
    <>
      <PageHeading heading="QM Administration" />
      <PageContent marginTop="m">
        <form onSubmit={handleOnSubmitSearch}>
          <FormElementLabel color="ciBlue4">{t('schedule.headline.searchCompany')}</FormElementLabel>
          <UiDiv display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} gap="xxs">
            <UiDiv flexGrow={1} display="flex" gap="xxs">
              <UiDiv flexGrow={1}>
                <TextInput
                  variant="outline"
                  value={searchInputValue}
                  onChange={(event) => {
                    dispatch(
                      setQMAdminState({
                        searchInputValue: event.target.value,
                      })
                    );
                  }}
                />
              </UiDiv>
              {searchInputValue && (
                <UiDiv>
                  <Button
                    variant="secondaryRed"
                    aria-label={t('common.actions.resetSearch') || undefined}
                    icon={IconClose}
                    onClick={() => {
                      dispatch(
                        setQMAdminState({
                          searchInputValue: '',
                          searchValue: '',
                        })
                      );
                    }}
                  />
                </UiDiv>
              )}
            </UiDiv>
            <UiDiv>
              <Button variant="secondaryRed" type="submit" icon={IconSearch}>
                {t('common.actions.search')}
              </Button>
            </UiDiv>
          </UiDiv>
        </form>
        <UiDiv display="flex" gap="xs" alignItems="center" marginTop="xs">
          <Checkbox
            variant="outline"
            checked={showClientsWithSchedules}
            onChange={(e) => {
              dispatch(
                setQMAdminState({
                  showClientsWithSchedules: e.target.checked,
                })
              );
            }}
          />
          <UiDiv flexGrow={1}>
            <UiText variant="copy" color="white">
              {t('schedule.search.isConfigured')}
            </UiText>
          </UiDiv>
        </UiDiv>

        <LoadingWrapper
          isLoading={(fetchCompaniesStatus === 'idle' || fetchCompaniesStatus === 'loading') && !companiesLoaded}
          marginTop="l"
        >
          {companyIds.length === 0 ? (
            <NoResultsNotice icon={IconNotFound}>{t('schedule.info.noCompaniesFound')}</NoResultsNotice>
          ) : (
            <>
              <UiText variant="h2" color="white" textAlign="center">
                {companyCount} {companyCount === 1 ? t('common.entities.company.singular') : t('common.entities.company.plural')}
              </UiText>
              <UiDiv display="flex" flexDirection="column" gap="xs" marginTop="l">
                {companyIds.map((companyId) => {
                  return <QMAdminStartCompany key={companyId} companyId={companyId} />;
                })}
              </UiDiv>
              {companyCount > companyIds.length && (
                <UiDiv display="flex" justifyContent="center" marginTop="m">
                  <Button
                    icon={IconArrowDown}
                    onClick={() => {
                      dispatch(
                        setQMAdminState({
                          limit: (limit || 0) + limitInterval,
                        })
                      );
                    }}
                  >
                    {t('common.actions.showMoreEntries')}
                  </Button>
                </UiDiv>
              )}
            </>
          )}
        </LoadingWrapper>
      </PageContent>
    </>
  );
};

export default QMAdminStart;
