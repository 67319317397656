import React from 'react';
import { InlineSVG, UiDiv, UiText } from '@webfox-sc/core';

import IconCheck from '../../assets/icons-v2/IconCheck';

interface IndicatorBubbleProps {
  number?: number;
  check?: boolean;
}

const IndicatorBubble: React.FC<IndicatorBubbleProps> = ({ check, number }) => {
  if (!check && number === undefined) {
    return null;
  }

  return (
    <UiDiv
      display="flex"
      borderRadius="100em"
      background="white"
      width="17px"
      height="17px"
      alignItems="center"
      justifyContent="center"
    >
      {check ? (
        <InlineSVG width="7px" height="7px">
          {IconCheck}
        </InlineSVG>
      ) : (
        <UiText variant="copyTiny" fontWeight={700}>
          {number}
        </UiText>
      )}
    </UiDiv>
  );
};

export default IndicatorBubble;
