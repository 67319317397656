import { pick, some } from 'lodash';
import AppUtil from './AppUtil';

class AddressUtil {
    // static filterKeys = ['id', 'createdAt', 'updatedAt', 'name', 'number'];
    static fieldKeys = [
        'id',
        'line1',
        'line2',
        'line3',
        'line4',
        'street',
        'zip',
        'city',
        'zip_po',
        'po',
        'number',
        'debitorId',
    ];

    static parseData(addressData, options = {}) {
        if (!addressData) {
            return {};
        }
        const { additionalData, oldAddress } = options;

        const baseData = pick(addressData, AddressUtil.fieldKeys);
        AddressUtil.fieldKeys.forEach((key) => {
            if (baseData[key] === undefined) {
                baseData[key] = null;
            }
        });

        let oldProps = {};
        if (oldAddress) {
            oldProps = AppUtil.getOldProps(baseData, oldAddress);
        }

        return {
            ...baseData,
            ...oldProps,
            ...additionalData,
        };
    }

    /**
     * Returns data object for a address to be used in API requests
     * @param {Object} address the address Object
     * @returns {Object} the data
     */
    static getAPIData(address) {
        if (address) {
            const hasAddress = some(Object.values(address || {}), (val) => !!val);
            if (hasAddress) {
                return pick(address, AddressUtil.fieldKeys);
            }
        }
        return null;
    }
}

export default AddressUtil;
