import React from 'react';
import { UiDiv, UiText } from '@webfox-sc/core';
import { useNavigate } from 'react-router-dom';
import ContentBox from '../../atoms-v2/ContentBox';
import { pathToQMAdminClient } from '../../../app/paths';
import { useClient } from '../../../app/entityHooks/useClient';
import { useTranslation } from 'react-i18next';

interface QMAdminCompanyClientsItemProps {
  clientId: number | undefined;
}

const QMAdminCompanyClientsItem: React.FC<QMAdminCompanyClientsItemProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { client, location, costcenter } = useClient(clientId);

  if (!client) {
    return null;
  }

  return (
    <UiDiv display="flex" flexDirection="column" gap="1px">
      <ContentBox
        variant="white"
        withLink
        linkHref={pathToQMAdminClient(client.id)}
        onClickLink={() => navigate(pathToQMAdminClient(client.id))}
      >
        <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1.5fr 1fr' }} gap="xs">
          <UiText variant="copy">{location?.description}</UiText>
          <UiText variant="copy">
            {t('common.entities.costcenter.singular')}: <b>{costcenter?.number}</b>
          </UiText>
        </UiDiv>
      </ContentBox>
    </UiDiv>
  );
};

export default QMAdminCompanyClientsItem;
