import { map, pick } from 'lodash';
import AppUtil from '../utils/AppUtil';
import LocationsUtil from '../utils/LocationsUtil';

export const useLocationsFilter = () => {
  const isDataFilter = ({ number, description, clientIds, searchFilter }) => {
    return number || description || searchFilter || (clientIds && clientIds.$empty);
  };

  const filterObjects = (objects, filter) => {
    const objectFilter = pick(filter || {}, ['status']);
    return AppUtil.filterModels(objects, objectFilter);
  };

  const getAPIFilter = (filter) => {
    const reducedFilter = pick(filter || {}, LocationsUtil.filterKeys);

    const apiFilter = map(reducedFilter, (value, key) => ({
      [key]: value,
    }));

    // setNoClients filter
    if (filter.clientIds && filter.clientIds.$empty) {
      apiFilter.push({
        clients: {
          id: {
            $null: true,
          }
        },
      });
    }
    // set text search filter
    if (filter.searchFilter) {
      apiFilter.push({
        $or: [
          {
            description: {
              $containsi: filter.searchFilter,
            },
          },
          {
            number: {
              $contains: filter.searchFilter,
            },
          },
        ],
      });
    }

    if (apiFilter.length > 0) {
      return apiFilter;
    }
    return null;
  };

  const initialDataFilter = null;

  const initialTableFilter = {
    status: ['new', 'editedNotNew', 'invalid', 'original'],
  };

  const uniqueKeys = ['number'];

  return {
    uniqueKeys,
    getAPIFilter,
    isDataFilter,
    filterObjects,
    initialDataFilter,
    initialTableFilter,
  };
};
