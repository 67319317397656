import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { REDUX_ACTIONS } from '../global/constants';
import { apiUrl } from '../app/api';
import { getAPIParams } from '../utils/APIParamsUtil';

export const useApi = () => {
  const dispatch = useDispatch();
  const { jwt } = useSelector(({ authState }) => authState);

  const apiRequest = useCallback(
    async (apiRequestConfig, withAuth) => {
      const { method, path, params, data, onRequest, onResponse, onError } = apiRequestConfig;

      dispatch({
        type: REDUX_ACTIONS.API_REQUEST,
        config: apiRequestConfig,
      });

      if (typeof onRequest === 'function') onRequest();

      const { paramString } = getAPIParams(params);
      try {
        const axiosRequestConfig = {
          method,
          url: `${apiUrl}${path}${paramString}`,
          data,
        };

        if (withAuth) {
          axiosRequestConfig.headers = {
            Authorization: `Bearer ${jwt}`,
          };
        }

        const response = await axios(axiosRequestConfig);

        dispatch({
          type: REDUX_ACTIONS.API_RESPONSE,
          config: apiRequestConfig,
          response,
        });

        if (typeof onResponse === 'function') onResponse(response);

        return response;
      } catch (error) {
        dispatch({
          type: REDUX_ACTIONS.API_REQUEST_ERROR,
          config: apiRequestConfig,
          error,
        });

        if (typeof onError === 'function') onError(error);
      }

      return true;
    },
    [dispatch, jwt]
  );

  const apiPost = useCallback(
    async (apiRequestConfig) => {
      return apiRequest(
        {
          ...apiRequestConfig,
          method: 'POST',
        },
        true
      );
    },
    [apiRequest]
  );

  const apiGet = useCallback(
    async (apiRequestConfig) => {
      return apiRequest(
        {
          ...apiRequestConfig,
          method: 'GET',
        },
        true
      );
    },
    [apiRequest]
  );

  const apiPut = useCallback(
    async (apiRequestConfig) => {
      return apiRequest(
        {
          ...apiRequestConfig,
          method: 'PUT',
        },
        true
      );
    },
    [apiRequest]
  );

  const apiDelete = useCallback(
    async (apiRequestConfig) => {
      return apiRequest(
        {
          ...apiRequestConfig,
          method: 'DELETE',
        },
        true
      );
    },
    [apiRequest]
  );

  return {
    apiPost,
    apiGet,
    apiPut,
    apiDelete,
  };
};
