/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconDelete from '../../assets/icons-v2/IconDelete';
import ButtonPrimary from '../../atoms/ButtonPrimary';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import FormSelect from '../../atoms/FormSelect';
import Section from '../../atoms/Section';
import { createService, deleteService, updateService } from '../../redux-actions/actionsServices';
import AppUtil from '../../utils/AppUtil';
import ClientsUtil from '../../utils/ClientsUtil';
import { getEntryLabel, stripObject } from '../../utils/FilterUtil';
import { formatPrice } from '../../utils/format';
import ServicesUtil from '../../utils/ServicesUtil';
import CustomDatePicker from '../atoms-v2/CustomDatePicker';
import FormPanel from '../atoms-v2/FormPanel';
import TextInputSpecialNumber from '../atoms-v2/TextInputSpecialNumber';
import CustomReactSelect from '../CustomReactSelect';
import Advice from '../elements/Advice';
import BasicButtonGroup from '../elements/BasicButtonGroup';
import BasicInput from '../elements/BasicInput';
import FormCell from '../elements/FormCell';
import FormInfo from '../elements/FormInfo';
import FormLabel from '../elements/FormLabel';
import FormRow from '../elements/FormRow';
import ToggleBox from '../elements/ToggleBox';
import { useToastContext } from '../toast/ToastProvider';
import { USE_GLOBAL_SERVICES } from '../../app.config';

const ServiceEditor = ({
  model = {},
  isError,
  adviceText: initialAdviceText,
  showAdvice: hasAdvice,
  isUpdateMode,
  initModel,
  onCancel,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = useToastContext();

  const [rawModel, setRawModel] = useState(model);
  const [modelState, setModelState] = useState(model);

  const [showAdvice, setShowAdvice] = useState(hasAdvice || model.isModified || model.isNew);

  useEffect(() => {
    if (model !== rawModel) {
      if (model) {
        setModelState(model);
        if (model.isModified || model.isNew) {
          setShowAdvice(true);
        }
        setRawModel(model);
      } else {
        const emptyModel = ServicesUtil.getEmptyObject();
        setModelState(emptyModel);
        setRawModel(emptyModel);
      }
    }
  }, [model]);

  const handleChangeFormValue = (name, value) => {
    setModelState({
      ...modelState,
      [name]: value,
    });
  };

  const emptyOption = {
    value: '',
    label: t('common.actions.select'),
  };

  const handleOnClickCreate = async () => {
    const strapiData = ServicesUtil.getAPIData(modelState);
    const newService = await dispatch(createService(strapiData));
    onCreate([newService]);

    const emptyModel = initModel();
    setModelState(emptyModel);
    setRawModel(emptyModel);
  };

  const handleOnClickUpdate = async () => {
    const strapiData = ServicesUtil.getAPIData(modelState);
    await dispatch(
      updateService(
        model.id,
        strapiData,
        { populate: ServicesUtil.baseViewPopulate },
        { oldService: ServicesUtil.parseData(rawModel) }
      )
    );
    onUpdate();
  };

  const handleOnClickDelete = async () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(t('service.prompts.delete', { description: modelState.description }))
    ) {
      const deletedServices = await dispatch(deleteService(modelState.id));
      onDelete(deletedServices);
      showToast(t('service.success.deleted', { description: modelState.description }), 'success');
    }
  };

  const handleOnChangeValidFrom = (validFrom) => {
    const { validTo } = modelState;
    const update = { validFrom };

    if (validFrom && validTo && validTo < validFrom) {
      update.validTo = validFrom;
    }
    setModelState({
      ...modelState,
      ...update,
    });
  };

  const handleOnChangeValidTo = (validTo) => {
    const { validFrom } = modelState;
    const update = { validTo };

    if (validTo && validFrom && validFrom > validTo) {
      update.validFrom = validTo;
    }
    setModelState({
      ...modelState,
      ...update,
    });
  };

  const handleOnClickAdvice = () => {
    setShowAdvice(false);
  };

  const onChangeClient = (option) => {
    const id = option?.value || null;
    setModelState({
      ...modelState,
      client: option
        ? {
            id,
            company: option.company || null,
            location: option.location || null,
            costcenter: option.costcenter || null,
            isGroup: option.isGroup || false,
            debitorId: option.debitorId || null,
          }
        : undefined,
      clientId: id,
    });
  };

  const {
    clientId,
    client,
    description,
    descriptionOld,
    dla,
    dlaOld,
    va,
    vaOld,
    price,
    priceOld,
    unit,
    unitOld,
    validFrom,
    validFromOld,
    validTo,
    validToOld,
    isModified,
    isNew,
  } = modelState;

  let isSuccess = false;
  let isInfo = false;
  let adviceText = initialAdviceText;

  if (isModified) {
    isInfo = true;
    adviceText = t('service.info.changed');
  }

  if (isNew) {
    isSuccess = true;
    adviceText = t('service.info.new');
  }

  let currentOption = null;

  const asyncDefaultOptions = [emptyOption];

  let foundClient = false;

  if (client) {
    if (client.id === clientId) {
      foundClient = true;
      currentOption = {
        value: client.id,
        label: getEntryLabel('client', client),
        company: stripObject(client.company || {}),
        location: stripObject(client.location || {}),
        costcenter: stripObject(client.costcenter || {}),
        isGroup: client.isGroup,
        debitorId: client.debitorId,
      };
      asyncDefaultOptions.push(currentOption);
    }
  }

  const isDataValid = (USE_GLOBAL_SERVICES || (clientId && foundClient)) && description && dla && va && price && unit;

  return (
    <FormPanel>
      <ToggleBox show={showAdvice}>
        <Section paddingBottom="S">
          <Advice
            text={adviceText}
            isSuccess={isSuccess}
            isInfo={isInfo}
            isError={isError}
            onClick={handleOnClickAdvice}
          />
        </Section>
      </ToggleBox>
      <FormRow>
        <FormCell cols="12">
          <FormLabel text={`${t('company.headline.singular')}${USE_GLOBAL_SERVICES ? '' : ' *'}`} />
          <CustomReactSelect
            asyncDefaultOptions={asyncDefaultOptions}
            selectedOption={currentOption || emptyOption}
            asyncApiPath="/clientsandgroups"
            asyncLimit={500}
            asyncSort="number:asc"
            asyncSetParams={(inputValue) => ClientsUtil.getClientGroupSearchParams(inputValue)}
            asyncFormatOption={(rawClient) => {
              const cl = ClientsUtil.parseData(rawClient);
              const entry = {
                company: stripObject(cl.company),
                location: stripObject(cl.location),
                costcenter: stripObject(cl.costcenter),
                isGroup: cl.isGroup,
                debitorId: cl.debitorId,
              };
              return {
                value: cl.id,
                label: getEntryLabel('client', entry),
                ...entry,
              };
            }}
            asyncInputMinLength={3}
            isClearable
            isSearchable
            onChange={onChangeClient}
          />
        </FormCell>
      </FormRow>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="12">
            <FormLabel text={`${t('service.serviceDescription')} *`} />
            <BasicInput
              type="textarea"
              value={description}
              onChange={(value) => handleChangeFormValue('description', value)}
            />
            {descriptionOld && <FormInfo text={descriptionOld} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="3">
            <FormLabel text={`${t('common.fields.dla')} *`} />
            <BasicInput type="text" value={dla} onChange={(value) => handleChangeFormValue('dla', value)} />
            {dlaOld && <FormInfo text={dlaOld} />}
          </FormCell>
          <FormCell cols="3">
            <FormLabel text={`${t('common.fields.va')} *`} />
            <FormSelect
              options={AppUtil.getSelectOptionsVA()}
              value={va}
              onChange={(value) => handleChangeFormValue('va', value)}
            />
            {vaOld && <FormInfo text={vaOld} />}
          </FormCell>
          <FormCell cols="3">
            <FormLabel text={`${t('service.unit')} *`} />
            <FormSelect
              options={AppUtil.getSelectOptionsServiceUnit()}
              value={unit}
              onChange={(value) => handleChangeFormValue('unit', value)}
            />
            {unitOld && <FormInfo text={unitOld} />}
          </FormCell>
          <FormCell cols="3">
            <FormLabel text={`${t('service.unitPrice')} *`} />
            <TextInputSpecialNumber
              value={price}
              maximumIntegerDigits={5}
              minimumFractionDigits={2}
              maximumFractionDigits={4}
              onChange={(_, value) => handleChangeFormValue('price', value)}
            />
            {priceOld && <FormInfo text={formatPrice(priceOld, 2, 4)} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="6">
            <FormLabel text={`${t('service.validFrom')}`} />
            <CustomDatePicker
              popperPlacement="top-start"
              selected={validFrom}
              onChange={handleOnChangeValidFrom}
              dateFormat="dd.MM.yyyy"
            />
            {validFromOld && <FormInfo text={AppUtil.toLocalDateString(validFromOld)} />}
          </FormCell>
          <FormCell cols="6">
            <FormLabel text={`${t('service.validTo')}`} />
            <CustomDatePicker
              popperPlacement="top-start"
              selected={validTo}
              onChange={handleOnChangeValidTo}
              dateFormat="dd.MM.yyyy"
            />
            {validToOld && <FormInfo text={AppUtil.toLocalDateString(validToOld)} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="M">
        <FormRow>
          <FormCell cols="8">
            <BasicButtonGroup>
              {!isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.create')}
                  icon={IconCheck}
                  disabled={!isDataValid}
                  onClick={handleOnClickCreate}
                />
              )}
              {isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.apply')}
                  icon={IconCheck}
                  disabled={!isDataValid}
                  onClick={handleOnClickUpdate}
                />
              )}
              <ButtonSecondary label={t('common.actions.close')} icon={IconClose} onClick={onCancel} />
            </BasicButtonGroup>
          </FormCell>
          {isUpdateMode && (
            <FormCell cols="4" align="right">
              <ButtonSecondary label={t('service.actions.delete')} icon={IconDelete} onClick={handleOnClickDelete} />
            </FormCell>
          )}
        </FormRow>
      </Section>
    </FormPanel>
  );
};

ServiceEditor.propTypes = {
  model: PropTypes.object,
  isUpdateMode: PropTypes.bool,
  showAdvice: PropTypes.bool,
  adviceText: PropTypes.string,
  isError: PropTypes.bool,
  initModel: PropTypes.func,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

ServiceEditor.defaultProps = {
  model: null,
  isUpdateMode: false,
  showAdvice: false,
  adviceText: '',
  isError: false,
  initModel: () => {},
  onCancel: () => false,
  onCreate: () => false,
  onUpdate: () => false,
  onDelete: () => false,
};

export default ServiceEditor;
