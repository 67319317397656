import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { NormalizedEntities } from '../requestEntities';
import { permissionsSchema } from '../schemas';
import { autoLogin, login } from './authSlice';

const permissionsAdapter = createEntityAdapter<PermissionEntity>();

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: permissionsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.role) {
          const normalized = normalize<any, NormalizedEntities>(action.payload.role, permissionsSchema);
          if (normalized.entities.permissions) {
            permissionsAdapter.upsertMany(state, normalized.entities.permissions);
          }
        }
      })
      .addCase(autoLogin.fulfilled, (state, action) => {
        if (action.payload.role) {
          const normalized = normalize<any, NormalizedEntities>(action.payload.role, permissionsSchema);
          if (normalized.entities.permissions) {
            permissionsAdapter.upsertMany(state, normalized.entities.permissions);
          }
        }
      })
  },
});

export default permissionsSlice.reducer;
