import { Collapse, UiDiv, UiFlex, UiText } from '@webfox-sc/core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import IconClose from '../../assets/icons-v2/IconClose';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import IconButton from '../atoms-v2/IconButton';
import { useToastContext } from './ToastProvider';

const Toast: React.FC = () => {
  const { t } = useTranslation();
  const { page } = useTheme();
  const { isToastVisible, toastText, toastType, closeToast } = useToastContext();

  const ref = useRef(null);
  useOnClickOutside(ref, closeToast);

  return (
    <UiDiv position="fixed" bottom={0} left={0} width="100%">
      <UiDiv
        innerRef={ref}
        background={toastType === 'success' ? 'trafficLightGreenText' : toastType === 'error' ? 'trafficLightRed' : 'ciBlue3'}
      >
        <Collapse show={isToastVisible && !!toastText}>
          <UiDiv maxWidth={page.contentMaxWidth} margin="0 auto" paddingBottom="10px">
            <UiFlex padding="20px 15px" columnGap="s">
              <UiText
                flexGrow={1}
                variant="copy"
                fontWeight={700}
                color="white"
                dangerouslySetInnerHTML={{ __html: toastText }}
              />
              <IconButton variant="white" icon={IconClose} aria-label={t('common.actions.close')} onClick={closeToast} />
            </UiFlex>
          </UiDiv>
        </Collapse>
      </UiDiv>
    </UiDiv>
  );
};

export default Toast;
