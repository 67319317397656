import { useMemo } from 'react';
import { useCostcenter } from '../../app/entityHooks/useCostcenter';

export const useQMAdminCostcenter = (
  costcenterId: number | undefined
): {
  costcenter: CostcenterEntity | undefined;
  qmUsers: UserEntity[];
  qmUsersSelectOption: UserSelectOption[];
} => {
  const { costcenter, users } = useCostcenter(costcenterId);

  const qmUsers = useMemo(() => {
    return users.filter((user) => user.roleType && ['servicemanager', 'qm-prüfer'].includes(user.roleType));
  }, [users]);

  const qmUsersSelectOption: UserSelectOption[] = useMemo(() => {
    return qmUsers.map((user) => {
      return {
        value: user.id,
        label: `${user.username} (${user.roleName})`,
      };
    });
  }, [qmUsers]);

  return {
    costcenter,
    qmUsers,
    qmUsersSelectOption,
  };
};
