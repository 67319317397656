import React, { Suspense } from 'react';

const LandingProvider = React.lazy(() => import('./LandingProvider'));
const LandingView = React.lazy(() => import('./LandingView'));

interface LandingProps {
  defaultViewType: 'offer' | 'certificate';
}

const Landing: React.FC<LandingProps> = ({ defaultViewType }) => {
  return (
    <Suspense fallback={<></>}>
      <LandingProvider>
        <LandingView defaultViewType={defaultViewType} />
      </LandingProvider>
    </Suspense>
  );
};

export default Landing;
