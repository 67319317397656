import { entitySelectors } from '../entitySelectors';

export const useHistory = (
  historyId: number | undefined
): {
  history: HistoryEntity | undefined;
} => {
  const history = entitySelectors.selectById<HistoryEntity>('histories', historyId);

  return {
    history,
  };
};
