const IconSettings = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M94,36.11a13.09,13.09,0,0,1-10.3-17.67,6.15,6.15,0,0,0-1.83-6.9A49.29,49.29,0,0,0,67.23,3a6.16,6.16,0,0,0-7,1.94,12.94,12.94,0,0,1-4.56,3.68A13,13,0,0,1,50,10a13,13,0,0,1-5.71-1.32A12.94,12.94,0,0,1,39.73,5a6.16,6.16,0,0,0-7-1.94A49.48,49.48,0,0,0,19.1,10.71,6.11,6.11,0,0,0,17,13.94a6.18,6.18,0,0,0,.2,3.87A13,13,0,0,1,6.33,35.68a6.16,6.16,0,0,0-5.4,5A47.26,47.26,0,0,0,0,50a48.93,48.93,0,0,0,.63,7.83A6.18,6.18,0,0,0,6.07,63,13,13,0,0,1,17.84,75.31a13.14,13.14,0,0,1-1.17,6,6.07,6.07,0,0,0,1.7,7.27A49.81,49.81,0,0,0,32.9,97a6.81,6.81,0,0,0,2.1.36,6.15,6.15,0,0,0,2.82-.69A6.08,6.08,0,0,0,40,94.74a13,13,0,0,1,21.13-.33,6.15,6.15,0,0,0,3.26,2.24,6.05,6.05,0,0,0,3.94-.21,50,50,0,0,0,13.34-8,6.2,6.2,0,0,0,1.8-7,12.87,12.87,0,0,1-1-5.88A13,13,0,0,1,93.9,63.48a6.18,6.18,0,0,0,5.23-5.07A47.74,47.74,0,0,0,100,50a48.25,48.25,0,0,0-.83-8.9,6.1,6.1,0,0,0-5.14-5ZM66.67,50A16.65,16.65,0,0,1,56.38,65.37a16.55,16.55,0,0,1-9.63.95A16.67,16.67,0,1,1,66.67,50Z"
    />
  </svg>
);

export default IconSettings;
