import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '@webfox-sc/core';
import Headline from '../../components/atoms-v2/Headline';
import CopyText from '../../atoms/CopyText';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import { COLORS, SPACINGS } from '../../theme/theme';

const Dialog = ({ heading, text, showCloseButton, onClickCloseButton }) => {
  return (
    <Block
      position="fixed"
      zIndex="100"
      top={0}
      left={0}
      right={0}
      bottom={0}
      background={COLORS.SHADER_BLUE}
      justify="center"
      alignItems="center"
    >
      <Block
        width="90%"
        maxWidth="640px"
        padding={SPACINGS.L}
        background={COLORS.WHITE}
        border={`4px solid ${COLORS.CI_BLUE_1}`}
      >
        <Headline variant="h3" color={COLORS.CI_BLUE_1} textAlign="center">
          {heading}
        </Headline>
        {text && (
          <Block paddingTop={SPACINGS.S} justify="center">
            <CopyText color={COLORS.CI_BLUE_1} textAlign="center">
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </CopyText>
          </Block>
        )}
        {showCloseButton && (
          <Block paddingTop={SPACINGS.M} justify="center">
            <ButtonSecondary label="Schließen" onClick={onClickCloseButton} />
          </Block>
        )}
      </Block>
    </Block>
  );
};

Dialog.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClickCloseButton: PropTypes.func,
};

Dialog.defaultProps = {
  heading: '',
  text: '',
  showCloseButton: false,
  onClickCloseButton: () => {},
};

export default Dialog;
