import { useSelector } from 'react-redux';
import DateUtil from '../../utils/DateUtil';

export function useLocationListItem(locationId) {
    const location = useSelector(({ dataState }) => dataState.locations[locationId]);

    const createdAtFormatted = location?.createdAt ? DateUtil.getDateFormatted(location?.createdAt) : '';
    const updatedAtFormatted = location?.updatedAt ? DateUtil.getDateFormatted(location?.updatedAt) : '';

    return {
        location,
        createdAtFormatted,
        updatedAtFormatted,
    };
}
