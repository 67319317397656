import axios from 'axios';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';
import { getAPIParams } from '../utils/APIParamsUtil';

export function createCompany(data) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_CREATE_COMPANY,
    });

    return axios({
      method: 'post',
      url: `${RestUtil.getStrapiUrl()}/companies`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const company = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_CREATE_COMPANY,
          company,
        });
        return [company];
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_CREATE_COMPANY_ERROR,
        });
        throw error;
      });
  };
}

export function updateCompanyLegacy(companyId, data) {
  return async (dispatch, getState) => {
    const {
      dataState: { companies, addresses },
      authState,
    } = getState();

    const oldCompany = companies ? companies[companyId] : {};
    let oldAddress;
    if (oldCompany.addressId && addresses[oldCompany.addressId]) {
      oldAddress = addresses[oldCompany.addressId];
    }

    dispatch({
      type: REDUX_ACTIONS.REQUEST_UPDATE_COMPANY,
    });
    const { paramString } = getAPIParams({ populate: { address: '*' } });
    return axios({
      method: 'put',
      url: `${RestUtil.getStrapiUrl()}/companies/${companyId}${paramString}`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const company = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_UPDATE_COMPANY,
          company,
          oldCompany,
          oldAddress,
        });
        return company;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_UPDATE_COMPANY_ERROR,
        });
        throw error;
      });
  };
}

export function deleteCompany(companyId) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_COMPANY,
    });

    return axios({
      method: 'delete',
      url: `${RestUtil.getStrapiUrl()}/companies/${companyId}`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const company = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_COMPANY,
          companyId,
          company,
        });
        return [company];
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_COMPANY_ERROR,
        });
        throw error;
      });
  };
}

export function deleteAllCompanies(companyIds) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_COMPANY,
    });

    return Promise.all(
      companyIds.map((companyId) => {
        return axios({
          method: 'delete',
          url: `${RestUtil.getStrapiUrl()}/companies/${companyId}`,
          headers: {
            Authorization: `Bearer ${authState.jwt}`,
          },
        });
      })
    )
      .then((res) => {
        const companies = (res || [{}]).map(({ data }) => data);
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_ALL_COMPANIES,
          companies,
        });
        return companies;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_COMPANY_ERROR,
        });
        throw error;
      });
  };
}
