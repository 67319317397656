/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';
import { getAPIParams } from '../utils/APIParamsUtil';

export const useCostcenterActions = () => {
  const dispatch = useDispatch();
  const { jwt } = useSelector(({ authState }) => authState);

  const fetchCostcentersLegacy = useCallback(async (params) => {
    dispatch({
      type: REDUX_ACTIONS.REQUEST_COSTCENTERS,
    });

    const { paramString } = getAPIParams(params, true);
    let data;
    try {
      ({ data } = await axios({
        method: 'get',
        url: `${RestUtil.getStrapiUrl()}/costcenters${paramString}`,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Cache-Control': 'no-store',
          'If-Modified-Since': '0',
        },
      }));
      
      const objects = data?.data;
      const count = data?.pagination?.total || 0;
      dispatch({
        type: REDUX_ACTIONS.RECEIVE_COSTCENTERS,
        costcenters: objects,
      });
      if (params && params._limit === -1 && objects) {
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_COUNT_COSTCENTERS,
          count,
        });
      }
      return { objects, count };
    } catch (error) {
      dispatch({
        type: REDUX_ACTIONS.REQUEST_COSTCENTERS_ERROR,
      });
      return { objects: [], count: 0};
    }
  }, []);

  return {
    fetchCostcentersLegacy,
  };
};
