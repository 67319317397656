export const USER_ROLES = {
  ADMIN: 'Administrator',
  MANAGEMENT: 'Management',
  SERVICE_MANAGER: 'Servicemanager',
  AUFTRAGS_MANAGER: 'Auftragsmanager',
  APPLICATION_MANAGER: 'Applicationmanager',
  NIEDERLASSUNGS_LEITER: 'Niederlassungsleiter',
};

export const JOB_STATUS = {
  CREATED: 'Erstellt',
  OFFER_CREATED: 'A-erstellt',
  OFFER_PENDING: 'A-ausstehend',
  OFFER_CONFIRMED: 'A-bestaetigt',
  OFFER_REJECTED: 'A-abgelehnt',
  CERTIFICATE_CREATED: 'LN-erstellt',
  CERTIFICATE_PENDING: 'LN-ausstehend',
  CERTIFICATE_CONFIRMED: 'LN-bestaetigt',
  CERTIFICATE_REJECTED: 'LN-abgelehnt',
};

export const CERTIFICATE_STATUS = {
  NEW: 'INIT',
  PENDING: 'DEFAULT',
  CONFIRMATION_PENDING: 'CONFIRMATION_PENDING',
  CONFIRMED: 'BESTAETIGT',
  REJECTION_PENDING: 'REJECTION_PENDING',
  REJECTED: 'ABGELEHNT',
};

export const OFFER_STATUS = {
  NEW: 'INIT',
  PENDING: 'DEFAULT',
  CONFIRMATION_PENDING: 'CONFIRMATION_PENDING',
  CONFIRMED: 'BESTAETIGT',
  REJECTION_PENDING: 'REJECTION_PENDING',
  REJECTED: 'ABGELEHNT',
};

export const REDUX_ACTIONS = {
  UPDATE_JOB_MODELS: 'UPDATE_JOB_MODELS',
  UPDATE_IS_LOADING_JOBS: 'UPDATE_IS_LOADING_JOBS',

  UPDATE_SERVICE_MODEL: 'UPDATE_SERVICE_MODEL',
  UPDATE_SERVICE_MODELS: 'UPDATE_SERVICE_MODELS',
  UPDATE_IS_LOADING_SERVICES: 'UPDATE_IS_LOADING_SERVICES',

  UPDATE_USER_MODELS: 'UPDATE_USER_MODELS',
  UPDATE_IS_LOADING_USERS: 'UPDATE_IS_LOADING_USERS',

  DASHBOARD__UPDATE_HISTORY_ITEM_MODELS: 'DASHBOARD__UPDATE_HISTORY_ITEM_MODELS',
  DASHBOARD__UPDATE_JOB_MODELS: 'DASHBOARD__UPDATE_JOB_MODELS',
  UPDATE_CLIENT_OPTIONS: 'UPDATE_CLIENT_OPTIONS',
  UPDATE_USER_OPTIONS: 'UPDATE_USER_OPTIONS',

  /**
   * app
   */
  REQUEST_UPLOAD: 'REQUEST_UPLOAD',
  REQUEST_UPLOAD_ERROR: 'REQUEST_UPLOAD_ERROR',
  RECIEVE_UPLOAD: 'RECIEVE_UPLOAD',

  /**
   * jobs
   */
  SET_JOBS_STATE: 'SET_JOBS_STATE',

  REQUEST_JOBS: 'REQUEST_JOBS',
  REQUEST_JOBS_ERROR: 'REQUEST_JOBS_ERROR',
  RECIEVE_JOBS: 'RECIEVE_JOBS',

  REQUEST_JOBS_COUNT: 'REQUEST_JOBS_COUNT',
  REQUEST_JOBS_COUNT_ERROR: 'REQUEST_JOBS_COUNT_ERROR',
  RECIEVE_JOBS_COUNT: 'RECIEVE_JOBS_COUNT',

  REQUEST_JOB: 'REQUEST_JOB',
  REQUEST_JOB_ERROR: 'REQUEST_JOB_ERROR',
  RECIEVE_JOB: 'RECIEVE_JOB',

  REQUEST_CREATE_JOB: 'REQUEST_CREATE_JOB',
  REQUEST_CREATE_JOB_ERROR: 'REQUEST_CREATE_JOB_ERROR',
  RECIEVE_CREATE_JOB: 'RECIEVE_CREATE_JOB',

  RECIEVE_UPDATE_JOB: 'RECIEVE_UPDATE_JOB',

  REQUEST_DELETE_JOB: 'REQUEST_DELETE_JOB',
  REQUEST_DELETE_JOB_ERROR: 'REQUEST_DELETE_JOB_ERROR',
  RECIEVE_DELETE_JOB: 'RECIEVE_DELETE_JOB',

  /**
   * recipients
   */
  REQUEST_RECIPIENT: 'REQUEST_RECIPIENT',
  REQUEST_RECIPIENT_ERROR: 'REQUEST_RECIPIENT_ERROR',
  RECIEVE_RECIPIENT: 'RECIEVE_RECIPIENT',

  REQUEST_CREATE_RECIPIENT: 'REQUEST_CREATE_RECIPIENT',
  REQUEST_CREATE_RECIPIENT_ERROR: 'REQUEST_CREATE_RECIPIENT_ERROR',
  RECIEVE_CREATE_RECIPIENT: 'RECIEVE_CREATE_RECIPIENT',

  REQUEST_UPDATE_RECIPIENT: 'REQUEST_UPDATE_RECIPIENT',
  REQUEST_UPDATE_RECIPIENT_ERROR: 'REQUEST_UPDATE_RECIPIENT_ERROR',
  RECIEVE_UPDATE_RECIPIENT: 'RECIEVE_UPDATE_RECIPIENT',

  /**
   * contacts
   */
  REQUEST_CONTACT: 'REQUEST_CONTACT',
  REQUEST_CONTACT_ERROR: 'REQUEST_CONTACT_ERROR',
  RECIEVE_CONTACT: 'RECIEVE_CONTACT',

  REQUEST_CREATE_CONTACT: 'REQUEST_CREATE_CONTACT',
  REQUEST_CREATE_CONTACT_ERROR: 'REQUEST_CREATE_CONTACT_ERROR',
  RECIEVE_CREATE_CONTACT: 'RECIEVE_CREATE_CONTACT',

  REQUEST_UPDATE_CONTACT: 'REQUEST_UPDATE_CONTACT',
  REQUEST_UPDATE_CONTACT_ERROR: 'REQUEST_UPDATE_CONTACT_ERROR',
  RECIEVE_UPDATE_CONTACT: 'RECIEVE_UPDATE_CONTACT',

  /**
   * offer
   */
  REQUEST_OFFER: 'REQUEST_OFFER',
  REQUEST_OFFER_ERROR: 'REQUEST_OFFER_ERROR',
  RECIEVE_OFFER: 'RECIEVE_OFFER',

  REQUEST_CREATE_OFFER: 'REQUEST_CREATE_OFFER',
  REQUEST_CREATE_OFFER_ERROR: 'REQUEST_CREATE_OFFER_ERROR',
  RECIEVE_CREATE_OFFER: 'RECIEVE_CREATE_OFFER',

  REQUEST_UPDATE_OFFER: 'REQUEST_UPDATE_OFFER',
  REQUEST_UPDATE_OFFER_ERROR: 'REQUEST_UPDATE_OFFER_ERROR',
  RECIEVE_UPDATE_OFFER: 'RECIEVE_UPDATE_OFFER',

  REQUEST_DELETE_OFFER_FILE: 'REQUEST_DELETE_OFFER_FILE',
  REQUEST_DELETE_OFFER_FILE_ERROR: 'REQUEST_DELETE_OFFER_FILE_ERROR',
  RECIEVE_DELETE_OFFER_FILE: 'RECIEVE_DELETE_OFFER_FILE',

  REQUEST_OFFER_PDF: 'REQUEST_OFFER_PDF',
  REQUEST_OFFER_PDF_ERROR: 'REQUEST_OFFER_PDF_ERROR',
  RECIEVE_OFFER_PDF: 'RECIEVE_OFFER_PDF',

  /**
   * certificate
   */
  REQUEST_CERTIFICATE: 'REQUEST_CERTIFICATE',
  REQUEST_CERTIFICATE_ERROR: 'REQUEST_CERTIFICATE_ERROR',
  RECIEVE_CERTIFICATE: 'RECIEVE_CERTIFICATE',

  REQUEST_CREATE_CERTIFICATE: 'REQUEST_CREATE_CERTIFICATE',
  REQUEST_CREATE_CERTIFICATE_ERROR: 'REQUEST_CREATE_CERTIFICATE_ERROR',
  RECIEVE_CREATE_CERTIFICATE: 'RECIEVE_CREATE_CERTIFICATE',

  REQUEST_UPDATE_CERTIFICATE: 'REQUEST_UPDATE_CERTIFICATE',
  REQUEST_UPDATE_CERTIFICATE_ERROR: 'REQUEST_UPDATE_CERTIFICATE_ERROR',
  RECIEVE_UPDATE_CERTIFICATE: 'RECIEVE_UPDATE_CERTIFICATE',

  REQUEST_DELETE_CERTIFICATE_FILE: 'REQUEST_DELETE_CERTIFICATE_FILE',
  REQUEST_DELETE_CERTIFICATE_FILE_ERROR: 'REQUEST_DELETE_CERTIFICATE_FILE_ERROR',
  RECIEVE_DELETE_CERTIFICATE_FILE: 'RECIEVE_DELETE_CERTIFICATE_FILE',

  REQUEST_CERTIFICATE_PDF: 'REQUEST_CERTIFICATE_PDF',
  REQUEST_CERTIFICATE_PDF_ERROR: 'REQUEST_CERTIFICATE_PDF_ERROR',
  RECIEVE_CERTIFICATE_PDF: 'RECIEVE_CERTIFICATE_PDF',

  /**
   * history
   */
  REQUEST_CREATE_HISTORY: 'REQUEST_CREATE_HISTORY',
  REQUEST_CREATE_HISTORY_ERROR: 'REQUEST_CREATE_HISTORY_ERROR',
  RECIEVE_CREATE_HISTORY: 'RECIEVE_CREATE_HISTORY',

  /**
   * costCenter
   */
  REQUEST_COSTCENTER: 'REQUEST_COSTCENTER',
  REQUEST_COSTCENTER_ERROR: 'REQUEST_COSTCENTER_ERROR',
  RECEIVE_COSTCENTER: 'RECEIVE_COSTCENTER',

  REQUEST_COSTCENTERS: 'REQUEST_COSTCENTERS',
  REQUEST_COSTCENTERS_ERROR: 'REQUEST_COSTCENTERS_ERROR',
  RECEIVE_COSTCENTERS: 'RECEIVE_COSTCENTERS',

  REQUEST_COUNT_COSTCENTERS: 'REQUEST_COUNT_COSTCENTERS',
  REQUEST_COUNT_COSTCENTERS_ERROR: 'REQUEST_COUNT_COSTCENTERS_ERROR',
  RECEIVE_COUNT_COSTCENTERS: 'RECEIVE_COUNT_COSTCENTERS',

  REQUEST_CREATE_COSTCENTER: 'REQUEST_CREATE_COSTCENTER',
  REQUEST_CREATE_COSTCENTER_ERROR: 'REQUEST_CREATE_COSTCENTER_ERROR',
  RECEIVE_CREATE_COSTCENTER: 'RECEIVE_CREATE_COSTCENTER',

  REQUEST_UPDATE_COSTCENTER: 'REQUEST_UPDATE_COSTCENTER',
  REQUEST_UPDATE_COSTCENTER_ERROR: 'REQUEST_UPDATE_COSTCENTER_ERROR',
  RECEIVE_UPDATE_COSTCENTER: 'RECEIVE_UPDATE_COSTCENTER',

  REQUEST_DELETE_COSTCENTER: 'REQUEST_DELETE_COSTCENTER',
  REQUEST_DELETE_COSTCENTER_ERROR: 'REQUEST_DELETE_COSTCENTER_ERROR',
  RECEIVE_DELETE_COSTCENTER: 'RECEIVE_DELETE_COSTCENTER',
  RECEIVE_DELETE_ALL_COSTCENTERS: 'RECEIVE_DELETE_ALL_COSTCENTERS',

  /**
   * company
   */
  REQUEST_COMPANIES: 'REQUEST_COMPANIES',
  REQUEST_COMPANIES_ERROR: 'REQUEST_COMPANIES_ERROR',
  RECEIVE_COMPANIES: 'RECEIVE_COMPANIES',

  REQUEST_COUNT_COMPANIES: 'REQUEST_COUNT_COMPANIES',
  REQUEST_COUNT_COMPANIES_ERROR: 'REQUEST_COUNT_COMPANIES_ERROR',
  RECEIVE_COUNT_COMPANIES: 'RECEIVE_COUNT_COMPANIES',

  REQUEST_CREATE_COMPANY: 'REQUEST_CREATE_COMPANY',
  REQUEST_CREATE_COMPANY_ERROR: 'REQUEST_CREATE_COMPANY_ERROR',
  RECEIVE_CREATE_COMPANY: 'RECEIVE_CREATE_COMPANY',

  REQUEST_UPDATE_COMPANY: 'REQUEST_UPDATE_COMPANY',
  REQUEST_UPDATE_COMPANY_ERROR: 'REQUEST_UPDATE_COMPANY_ERROR',
  RECEIVE_UPDATE_COMPANY: 'RECEIVE_UPDATE_COMPANY',

  REQUEST_DELETE_COMPANY: 'REQUEST_DELETE_COMPANY',
  REQUEST_DELETE_COMPANY_ERROR: 'REQUEST_DELETE_COMPANY_ERROR',
  RECEIVE_DELETE_COMPANY: 'RECEIVE_DELETE_COMPANY',
  RECEIVE_DELETE_ALL_COMPANIES: 'RECEIVE_DELETE_ALL_COMPANIES',

  /**
   * location
   */
  REQUEST_LOCATION: 'REQUEST_LOCATION',
  REQUEST_LOCATION_ERROR: 'REQUEST_LOCATION_ERROR',
  RECEIVE_LOCATION: 'RECEIVE_LOCATION',

  REQUEST_LOCATIONS: 'REQUEST_LOCATIONS',
  REQUEST_LOCATIONS_ERROR: 'REQUEST_LOCATIONS_ERROR',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',

  REQUEST_COUNT_LOCATIONS: 'REQUEST_COUNT_LOCATIONS',
  REQUEST_COUNT_LOCATIONS_ERROR: 'REQUEST_COUNT_LOCATIONS_ERROR',
  RECEIVE_COUNT_LOCATIONS: 'RECEIVE_COUNT_LOCATIONS',

  REQUEST_CREATE_LOCATION: 'REQUEST_CREATE_LOCATION',
  REQUEST_CREATE_LOCATION_ERROR: 'REQUEST_CREATE_LOCATION_ERROR',
  RECEIVE_CREATE_LOCATION: 'RECEIVE_CREATE_LOCATION',

  REQUEST_UPDATE_LOCATION: 'REQUEST_UPDATE_LOCATION',
  REQUEST_UPDATE_LOCATION_ERROR: 'REQUEST_UPDATE_LOCATION_ERROR',
  RECEIVE_UPDATE_LOCATION: 'RECEIVE_UPDATE_LOCATION',

  REQUEST_DELETE_LOCATION: 'REQUEST_DELETE_LOCATION',
  REQUEST_DELETE_LOCATION_ERROR: 'REQUEST_DELETE_LOCATION_ERROR',
  RECEIVE_DELETE_LOCATION: 'RECEIVE_DELETE_LOCATION',
  RECEIVE_DELETE_ALL_LOCATIONS: 'RECEIVE_DELETE_ALL_LOCATIONS',

  /**
   * client
   */
  REQUEST_CLIENT: 'REQUEST_CLIENT',
  REQUEST_CLIENT_ERROR: 'REQUEST_CLIENT_ERROR',
  RECEIVE_CLIENT: 'RECEIVE_CLIENT',

  REQUEST_CLIENTS: 'REQUEST_CLIENTS',
  REQUEST_CLIENTS_ERROR: 'REQUEST_CLIENTS_ERROR',
  RECEIVE_CLIENTS: 'RECEIVE_CLIENTS',

  REQUEST_COUNT_CLIENTS: 'REQUEST_COUNT_CLIENTS',
  REQUEST_COUNT_CLIENTS_ERROR: 'REQUEST_COUNT_CLIENTS_ERROR',
  RECEIVE_COUNT_CLIENTS: 'RECEIVE_COUNT_CLIENTS',

  REQUEST_CREATE_CLIENT: 'REQUEST_CREATE_CLIENT',
  REQUEST_CREATE_CLIENT_ERROR: 'REQUEST_CREATE_CLIENT_ERROR',
  RECEIVE_CREATE_CLIENT: 'RECEIVE_CREATE_CLIENT',

  REQUEST_UPDATE_CLIENT: 'REQUEST_UPDATE_CLIENT',
  REQUEST_UPDATE_CLIENT_ERROR: 'REQUEST_UPDATE_CLIENT_ERROR',
  RECEIVE_UPDATE_CLIENT: 'RECEIVE_UPDATE_CLIENT',

  REQUEST_DELETE_CLIENT: 'REQUEST_DELETE_CLIENT',
  REQUEST_DELETE_CLIENT_ERROR: 'REQUEST_DELETE_CLIENT_ERROR',
  RECEIVE_DELETE_CLIENT: 'RECEIVE_DELETE_CLIENT',
  RECEIVE_DELETE_ALL_CLIENTS: 'RECEIVE_DELETE_ALL_CLIENTS',

  /**
   * import files
   */
  REQUEST_IMPORTS: 'REQUEST_IMPORTS',
  REQUEST_IMPORTS_ERROR: 'REQUEST_IMPORTS_ERROR',
  RECEIVE_IMPORTS: 'RECEIVE_IMPORTS',

  REQUEST_UPLOAD_IMPORTS: 'REQUEST_UPLOAD_IMPORTS',
  REQUEST_UPLOAD_IMPORTS_ERROR: 'REQUEST_UPLOAD_IMPORTS_ERROR',
  RECEIVE_UPLOAD_IMPORTS: 'RECEIVE_UPLOAD_IMPORTS',

  REQUEST_IMPORT_IMPORTS: 'REQUEST_IMPORT_IMPORTS',
  REQUEST_IMPORT_IMPORTS_ERROR: 'REQUEST_IMPORT_IMPORTS_ERROR',
  RECEIVE_IMPORT_IMPORTS: 'RECEIVE_IMPORT_IMPORTS',

  REQUEST_DELETE_IMPORT: 'REQUEST_DELETE_IMPORT',
  REQUEST_DELETE_IMPORT_ERROR: 'REQUEST_DELETE_IMPORT_ERROR',
  RECEIVE_DELETE_IMPORT: 'RECEIVE_DELETE_IMPORT',

  REQUEST_DELETE_IMPORTS: 'REQUEST_DELETE_IMPORTS',
  REQUEST_DELETE_IMPORTS_ERROR: 'REQUEST_DELETE_IMPORTS_ERROR',
  RECEIVE_DELETE_IMPORTS: 'RECEIVE_DELETE_IMPORTS',

  /**
   * import files
   */
  REQUEST_ADDRESS: 'REQUEST_ADDRESS',
  REQUEST_ADDRESS_ERROR: 'REQUEST_ADDRESS_ERROR',
  RECEIVE_ADDRESS: 'RECEIVE_ADDRESS',

  /**
   * services
   */
  REQUEST_SERVICE: 'REQUEST_SERVICE',
  REQUEST_SERVICE_ERROR: 'REQUEST_SERVICE_ERROR',
  RECEIVE_SERVICE: 'RECEIVE_SERVICE',

  REQUEST_SERVICES: 'REQUEST_SERVICES',
  REQUEST_SERVICES_ERROR: 'REQUEST_SERVICES_ERROR',
  RECEIVE_SERVICES: 'RECEIVE_SERVICES',

  REQUEST_COUNT_SERVICES: 'REQUEST_COUNT_SERVICES',
  REQUEST_COUNT_SERVICES_ERROR: 'REQUEST_COUNT_SERVICES_ERROR',
  RECEIVE_COUNT_SERVICES: 'RECEIVE_COUNT_SERVICES',

  REQUEST_UPDATE_SERVICES: 'REQUEST_UPDATE_SERVICES',
  REQUEST_UPDATE_SERVICES_ERROR: 'REQUEST_UPDATE_SERVICES_ERROR',
  RECEIVE_UPDATE_SERVICES: 'RECEIVE_UPDATE_SERVICES',

  REQUEST_CREATE_SERVICE: 'REQUEST_CREATE_SERVICE',
  REQUEST_CREATE_SERVICE_ERROR: 'REQUEST_CREATE_SERVICE_ERROR',
  RECEIVE_CREATE_SERVICE: 'RECEIVE_CREATE_SERVICE',

  REQUEST_UPDATE_SERVICE: 'REQUEST_UPDATE_SERVICE',
  REQUEST_UPDATE_SERVICE_ERROR: 'REQUEST_UPDATE_SERVICE_ERROR',
  RECEIVE_UPDATE_SERVICE: 'RECEIVE_UPDATE_SERVICE',

  REQUEST_DELETE_SERVICE: 'REQUEST_DELETE_SERVICE',
  REQUEST_DELETE_SERVICE_ERROR: 'REQUEST_DELETE_SERVICE_ERROR',
  RECEIVE_DELETE_SERVICE: 'RECEIVE_DELETE_SERVICE',
  RECEIVE_DELETE_ALL_SERVICES: 'RECEIVE_DELETE_ALL_SERVICES',

  REQUEST_CLIENT_SERVICES: 'REQUEST_CLIENT_SERVICES',
  REQUEST_CLIENT_SERVICES_ERROR: 'REQUEST_CLIENT_SERVICES_ERROR',
  RECEIVE_CLIENT_SERVICES: 'RECEIVE_CLIENT_SERVICES',

  /**
   * landing page
   */
  REQUEST_PUBLIC_OFFERS: 'REQUEST_PUBLIC_OFFERS',
  REQUEST_PUBLIC_OFFERS_ERROR: 'REQUEST_PUBLIC_OFFERS_ERROR',
  RECEIVE_PUBLIC_OFFERS: 'RECEIVE_PUBLIC_OFFERS',

  REQUEST_PUBLIC_CERTIFICATES: 'REQUEST_PUBLIC_CERTIFICATES',
  REQUEST_PUBLIC_CERTIFICATES_ERROR: 'REQUEST_PUBLIC_CERTIFICATES_ERROR',
  RECEIVE_PUBLIC_CERTIFICATES: 'RECEIVE_PUBLIC_CERTIFICATES',

  REQUEST_ACCEPT_CERTIFICATES: 'REQUEST_ACCEPT_CERTIFICATES',
  REQUEST_ACCEPT_CERTIFICATES_ERROR: 'REQUEST_ACCEPT_CERTIFICATES_ERROR',
  RECEIVE_ACCEPT_CERTIFICATES: 'RECEIVE_ACCEPT_CERTIFICATES',

  /**
   * api
   */
  API_REQUEST: 'API_REQUEST',
  API_REQUEST_ERROR: 'API_REQUEST_ERROR',
  API_RESPONSE: 'API_RESPONSE',
};

export const SERVICE_UNITS = [
  'Pauschal',
  'pro Ausführung',
  'Stunde',
  'Stück',
  'qm',
  'm³',
  'kg',
  'Dose',
  'Flasche',
  'Kannister',
  'Karton',
  'Laufender Meter',
  'Liter',
  'Paket',
  'Palette',
  'Rolle',
  'Tonne(n)',
  'Box',
  'Minuten',
  'Trommel',
  'Wochen',
  'Wohneinheit',
  'Tag(e)',
  'Paar',
  'Monat(e)',
  'Kilometer',
  'Jahr(e)',
  'Zentimeter',
  'Prozent',
];

export const MIN_DATE = new Date('2000-01-01');

export const DATE_FIELDS = ['validFrom', 'validTo', 'createdAt', 'updatedAt'];

export const ROWS_STEP = 40;
