import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS, STYLES } from '../../theme/theme';

const StyledText = styled.div(({ cssStyle }) => cssStyle);

const CopyText = (props) => {
    const { size, color, textAlign, children } = props;

    let cssStyle = {};

    switch (size) {
        case 'M':
            cssStyle = STYLES.COPY_TEXT_M;
            break;

        case 'S':
            cssStyle = STYLES.COPY_TEXT_S;
            break;

        default:
            cssStyle = STYLES.COPY_TEXT_M;
            break;
    }

    cssStyle.color = color || COLORS.CI_BLUE_1;

    // if (textAlign) {
    cssStyle.textAlign = textAlign || 'left';
    // }

    return <StyledText cssStyle={cssStyle}>{children}</StyledText>;
};

CopyText.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    textAlign: PropTypes.string,
    children: PropTypes.any,
};

CopyText.defaultProps = {
    size: 'M',
    color: null,
    textAlign: null,
    children: null,
};

export default CopyText;
