import React from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv } from '@webfox-sc/core';

import FormElementLabel from '../atoms-v2/FormElementLabel';
import CustomReactSelect from '../CustomReactSelect';
import { useJobsSorting } from './useJobsSorting';

const JobsSorting: React.FC = () => {
  const { t } = useTranslation();
  const { sortingOptions, selectedSortingOption, setSelectedSortingOption } = useJobsSorting();

  return (
    <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" rowGap="xs" columnGap="xxs">
      <UiDiv gridColumn={{ mobile: '1 / 13', tablet: '1 / 7' }}>
        <FormElementLabel color="ciBlue4">{t('common.form.sortBy')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          options={sortingOptions}
          selectedOption={selectedSortingOption || null}
          onChange={setSelectedSortingOption}
        />
      </UiDiv>
    </UiDiv>
  );
};

export default JobsSorting;
