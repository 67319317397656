const IconRatingNeutral = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M50.4,0C22.79,0,.4,22.39,.4,50s22.39,50,50,50,50-22.39,50-50S78.02,0,50.4,0Zm-17,50c-2.76,0-5-2.69-5-6s2.24-6,5-6,5,2.69,5,6-2.24,6-5,6Zm24,16h-12c-1.66,0-3-1.34-3-3s1.34-3,3-3h12c1.66,0,3,1.34,3,3s-1.34,3-3,3Zm10-16c-2.76,0-5-2.69-5-6s2.24-6,5-6,5,2.69,5,6-2.24,6-5,6Z"
    />
  </svg>
);

export default IconRatingNeutral;
