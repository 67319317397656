const IconNotFound = (
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M13.13,71.4l-9.93,9.93C1.14,83.4,0,86.15,0,89.07s1.14,5.67,3.2,7.73c2.13,2.13,4.93,3.2,7.73,3.2s5.6-1.07,7.73-3.2l9.93-9.93-15.46-15.47ZM77.99,22c-4.12-4.12-9.59-6.38-15.41-6.38s-11.29,2.27-15.41,6.38c-4.11,4.11-6.37,9.58-6.37,15.41s2.26,11.3,6.37,15.41c4.12,4.12,9.59,6.38,15.41,6.38s11.29-2.27,15.41-6.38c4.11-4.11,6.37-9.58,6.37-15.41s-2.26-11.3-6.37-15.41Zm-12.31,4.76h5.86v5.82h-5.86v-5.82Zm-12.07,0h5.86v5.82h-5.86v-5.82Zm15.36,22.31c-1.11-2.55-3.63-4.19-6.4-4.19s-5.29,1.64-6.4,4.19l-5.37-2.35c2.05-4.68,6.67-7.7,11.77-7.7s9.73,3.02,11.77,7.7l-5.37,2.35ZM89.04,10.94c-14.59-14.59-38.33-14.59-52.92,0-7.07,7.07-10.96,16.47-10.96,26.46,0,9.01,3.17,17.54,8.97,24.31l-3.94,3.95-5.66-5.66-7.25,7.25,15.46,15.47,7.25-7.25-5.66-5.67,3.94-3.95c6.97,5.97,15.64,8.95,24.31,8.95,9.58,0,19.17-3.65,26.46-10.94,7.07-7.07,10.96-16.47,10.96-26.46s-3.89-19.4-10.96-26.46Zm-6.91,46.02c-5.22,5.22-12.17,8.1-19.55,8.1s-14.33-2.88-19.55-8.1c-5.22-5.22-8.09-12.16-8.09-19.56s2.87-14.34,8.09-19.56c5.22-5.22,12.17-8.1,19.55-8.1s14.33,2.88,19.55,8.1c5.22,5.22,8.09,12.16,8.09,19.56s-2.87,14.34-8.09,19.56Z"
    />
  </svg>
);

export default IconNotFound;
