import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useJob } from '../../app/entityHooks/useJob';
import DateUtil from '../../utils/DateUtil';
import FormatUtil from '../../utils/FormatUtil';
import JobCardStatus from '../jobs/JobCardStatus';

import DashboardJobCard from './DashboardJobCard';
import { usePermissions } from '../auth/usePermissions';
import { useAppDispatch } from '../../app/appHooks';
import { updateJobIsInvoiced } from '../../app/slices/jobsSlice';

interface DashboardJobCardWrapperProps {
  jobId: number | undefined;
  pathname?: string;
  onChanged?: () => void;
  isSelected?: boolean;
}

const DashboardJobCardWrapper: React.FC<DashboardJobCardWrapperProps> = ({ jobId, pathname, onChanged }) => {
  const navigate = useNavigate();
  const { job, dlasFormatted } = useJob(jobId);
  const { canEditJobIsInvoiced } = usePermissions();
  const dispatch = useAppDispatch();

  const useSelect = canEditJobIsInvoiced;
  const handleOnSelect = () => {
    if (jobId && job && useSelect) {
      if (onChanged) {
        onChanged();
      }
      dispatch(updateJobIsInvoiced(jobId, !job?.isInvoiced));
    }
  };

  if (!job) {
    return null;
  }

  let listStatusOffer: JobOfferStatus | undefined = undefined;
  if (
    job?.offerStatus === 'A-erstellt' ||
    job?.offerStatus === 'A-ausstehend' ||
    job?.offerStatus === 'A-bestaetigt' ||
    job?.offerStatus === 'A-abgelehnt'
  ) {
    listStatusOffer = job?.offerStatus;
  }

  let listStatusCertificate: JobStatus | undefined = undefined;
  if (
    job?.status === 'LN-erstellt' ||
    job?.status === 'LN-ausstehend' ||
    job?.status === 'LN-bestaetigt' ||
    job?.status === 'LN-abgelehnt'
  ) {
    listStatusCertificate = job?.status;
  }

  let listStatusJob: JobStatus | undefined = undefined;
  if (!listStatusOffer && !listStatusCertificate) {
    listStatusJob = 'Erstellt';
  }

  const pathToJob = `/job/${job.id}${pathname ? `?ref=${encodeURIComponent(pathname)}` : ''}`;

  return (
    <DashboardJobCard
      entryId={job.entryId}
      clientName={job?.clientName}
      dlasFormatted={dlasFormatted}
      carrierNumber={job?.carrierNumber}
      createdAt={DateUtil.getDateFormatted(job.createdAt)}
      totalPrice={`${FormatUtil.getDecimalFormatted(job.totalPrice)} €`}
      href={pathToJob}
      isSelected={job.isInvoiced || false}
      onSelect={useSelect ? handleOnSelect : undefined}
      onNavigate={() => navigate(pathToJob)}
    >
      {listStatusJob && <JobCardStatus type="job" />}
      {listStatusOffer && <JobCardStatus status={job.offerStatus} type="offer" />}
      {listStatusCertificate && <JobCardStatus status={job.status} type="certificate" />}
    </DashboardJobCard>
  );
};

export default DashboardJobCardWrapper;
