import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Section from '../../atoms/Section';
import FormCell from '../../components/elements/FormCell';
import FormLabel from '../../components/elements/FormLabel';
import FormRow from '../../components/elements/FormRow';
import StaticField from '../../components/elements/StaticField';

const DataImportAddressEntry = ({ address, title }) => {
    const { t } = useTranslation();

    return (
        <FormCell cols="12">
            <h5>{title}</h5>
            <Section>
                <FormRow>
                    <FormCell cols="6">
                        <FormLabel text={t('common.address.line1')} />
                        <StaticField text={address.line1} />
                    </FormCell>
                    <FormCell cols="6">
                        <FormLabel text={t('common.address.line2')} />
                        <StaticField text={address.line2} />
                    </FormCell>
                </FormRow>
            </Section>
            <Section paddingTop="XS">
                <FormRow>
                    <FormCell cols="6">
                        <FormLabel text={t('common.address.line3')} />
                        <StaticField text={address.line3} />
                    </FormCell>
                    <FormCell cols="6">
                        <FormLabel text={t('common.address.line4')} />
                        <StaticField text={address.line4} />
                    </FormCell>
                </FormRow>
            </Section>
            <Section paddingTop="XS">
                <FormRow>
                    <FormCell cols="12">
                        <FormLabel text={t('common.address.street')} />
                        <StaticField text={address.street} />
                    </FormCell>
                </FormRow>
            </Section>
            <Section paddingTop="XS">
                <FormRow>
                    <FormCell cols="4">
                        <FormLabel text={t('common.address.zip')} />
                        <StaticField text={address.zip} />
                    </FormCell>
                    <FormCell cols="8">
                        <FormLabel text={t('common.address.city')} />
                        <StaticField text={address.city} />
                    </FormCell>
                </FormRow>
            </Section>
            <Section paddingTop="XS">
                <FormRow>
                    <FormCell cols="4">
                        <FormLabel text={t('common.address.zipPo')} />
                        <StaticField text={address.zip_po} />
                    </FormCell>
                    <FormCell cols="8">
                        <FormLabel text={t('common.address.po')} />
                        <StaticField text={address.po} />
                    </FormCell>
                </FormRow>
            </Section>
        </FormCell>
    );
};

DataImportAddressEntry.propTypes = {
    address: PropTypes.object,
    title: PropTypes.string,
};

DataImportAddressEntry.defaultProps = {
    address: null,
    title: '',
};

export default DataImportAddressEntry;
