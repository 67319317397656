import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import BaseDataFilteredList from '../../components/base-data/shared/BaseDataFilteredList';
import { usePaginatedList } from '../../components/base-data/shared/usePaginatedList';
import TableHeaderCell from '../../components/elements/TableHeaderCell';
import TableHeaderRow from '../../components/elements/TableHeaderRow';
import IsEmptyFilter from '../../components/IsEmptyFilter';
import LocationEditor from '../../components/LocationEditor';
import SearchFilter from '../../components/SearchFilter';
import { BaseDataFilterProvider } from '../../hooks/baseDataFilter';
import { BaseDataViewProvider } from '../../hooks/baseDataView';
import { useLocationActions } from '../../hooks/useLocationActions';
import { useLocationsFilter } from '../../hooks/useLocationsFilter';
import { deleteAllLocations } from '../../redux-actions/actionsLocations';
import LocationsUtil from '../../utils/LocationsUtil';
import LocationListItem from './LocationListItem';

const ViewBaseDataLocations = () => {
  const { t } = useTranslation();
  const { isDataFilter, initialTableFilter } = useLocationsFilter();
  const { fetchLocations } = useLocationActions();

  const dataLoaderProps = {
    objectPath: 'locations',
    fetchObjects: fetchLocations,
  };

  const dispatch = useDispatch();

  return (
    <BaseDataFilterProvider isDataFilter={isDataFilter} initialTableFilter={initialTableFilter}>
      <BaseDataViewProvider
        objectPath="locations"
        getNewObject={LocationsUtil.getEmptyObject}
        useFilter={useLocationsFilter}
        deleteObjects={(ids) => dispatch(deleteAllLocations(ids))}
        dataLoader={usePaginatedList}
        dataLoaderProps={dataLoaderProps}
      >
        <BaseDataFilteredList
          labelObjects={t('common.entities.location.singular')}
          labelNew={t('location.actions.new')}
          toolbar={
            <>
              <IsEmptyFilter label={t('location.form.showWithoutClients')} filterKey="clientIds" />
              <SearchFilter />
            </>
          }
          Editor={LocationEditor}
          TableHeader={({ handleSort, sortingKey, sortingOrder }) => (
            <TableHeaderRow>
              <TableHeaderCell
                text={t('common.fields.carrierNumber.long')}
                width="13%"
                isSorting
                onClick={() => handleSort('number')}
                sortingOrder={sortingKey === 'number' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('location.descrtiption')}
                width="auto"
                isSorting
                onClick={() => handleSort('description')}
                sortingOrder={sortingKey === 'description' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.createdAt')}
                width="13%"
                isSorting
                onClick={() => handleSort('createdAt')}
                sortingOrder={sortingKey === 'createdAt' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.updatedAt')}
                width="27%"
                isSorting
                onClick={() => handleSort('updatedAt')}
                sortingOrder={sortingKey === 'updatedAt' ? sortingOrder : ''}
              />
            </TableHeaderRow>
          )}
          TableRow={LocationListItem}
          newEditorPresets={{
            showAdvice: true,
            isError: true,
          }}
        />
      </BaseDataViewProvider>
    </BaseDataFilterProvider>
  );
};

export default ViewBaseDataLocations;
