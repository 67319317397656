import { get, set } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _differenceWith from 'lodash/differenceWith';
import _filter from 'lodash/filter';
import _flatten from 'lodash/flatten';
import _forEach from 'lodash/forEach';
import { USE_REPORTER_FILTER_IN_QM_SUMMARY } from '../app.config';

// ms per day
const DAY_MS = 1000 * 60 * 60 * 24;

const roomgroupsPopulateOptions = {
  checkitemgroups: {
    populate: {
      checkitems: {
        populate: {
          checkitemtype: '*',
          checkitemgroup: {
            fields: ['id'],
          },
          default_checkitem: {
            fields: ['id'],
          },
        },
      },
      checkitemgrouptype: '*',
      roomgroup: {
        fields: ['id'],
      },
      default_checkitemgroup: {
        fields: ['id'],
      },
    },
  },
  craft: {
    fields: ['id', 'name'],
  },
  client: {
    fields: ['id'],
  },
  company: {
    fields: ['id'],
  },
  default_roomgroup: {
    fields: ['id'],
  },
};
class QMUtil {
  static QM_STAUS_OPTONS = {
    ALL: '',
    OPEN: 'open',
    PENDING: 'pending',
    FINISHED: 'finished',
  };

  static SUMMARY_FILTER_KEYS = USE_REPORTER_FILTER_IN_QM_SUMMARY
    ? ['companyNumber', 'locationNumber', 'costcenterNumber', 'craftId', 'startDate', 'endDate', 'userId']
    : ['companyNumber', 'locationNumber', 'costcenterNumber', 'craftId', 'startDate', 'endDate'];

  /**
   * Returns an empty roomgroup, initialized with data provided
   * @param {Object} initialData data to initialize
   * @reutrn {Object} roomgroup
   */
  static getEmptyRoomgroup(initialData: RoomgroupInitData = {}): RoomgroupEntity {
    return {
      id: -new Date().getTime(),
      name: '',
      disabled: true,
      checkitemgroupIds: [],
      ...initialData,
    };
  }

  /**
   * Returns an empty roomgroup, initialized with data provided
   * @param {Object} initialData data to initialize
   * @reutrn {Object} roomgroup
   */
  static getEmptyNestedRoomgroup(initialData: RoomgroupNestedInitData): RoomgroupNestedEntity {
    return {
      id: -new Date().getTime(),
      name: '',
      disabled: false,
      checkitemgroups: [],
      showDelete: true,
      ...initialData,
    };
  }

  /**
   * Returns an empty checkitemgroup, initialized with data provided
   * @param {Object} initialData data to initialize
   * @reutrn {Object} checkitemgroup
   */
  static getEmptyCheckitemgroup(initialData: CheckitemgroupInitData): CheckitemgroupEntity {
    return {
      id: -new Date().getTime(),
      checkitemIds: [],
      disabled: true,
      ...initialData,
    } as CheckitemgroupEntity;
  }

  /**
   * Returns an empty checkitemgroup, initialized with data provided
   * @param {Object} initialData data to initialize
   * @reutrn {Object} checkitemgroup
   */
  static getEmptyNestedCheckitemgroup(initialData: CheckitemgroupNestedInitData): CheckitemgroupNestedEntity {
    return {
      id: -new Date().getTime(),
      checkitems: [],
      disabled: false,
      ...initialData,
      showDelete: true,
    } as CheckitemgroupNestedEntity;
  }

  /**
   * Returns an empty checkitem, initialized with data provided
   * @param {Object} initialData data to initialize
   * @reutrn {Object} checkitem
   */
  static getEmptyCheckitem(initialData: CheckitemInitData): CheckitemEntity {
    return {
      question: '',
      weight: 3,
      disabled: true,
      ...initialData,
    } as CheckitemEntity;
  }

  static getEmptyNestedCheckitem(initialData: CheckitemInitData): CheckitemNestedEntity {
    return {
      question: '',
      weight: 3,
      disabled: true,
      showDelete: true,
      ...initialData,
    } as CheckitemNestedEntity;
  }

  /**
   * Returns data object for a checkitem to be used in API requests
   * @param {CheckitemEntity} checkitemgroup the checkitem Object
   * @returns {CheckitemgUpdateData} the data
   */
  static getCheckitemAPIData(checkitem: CheckitemEntity): CheckitemUpdateData {
    const { id, checkitemtypeId, question, weight, disabled, defaultCheckitemId } = checkitem;

    const data = {
      id: id && id > 0 ? id : undefined,
      checkitemtype: checkitemtypeId,
      question,
      weight,
      disabled,
      default_checkitem: defaultCheckitemId,
    } as CheckitemUpdateData;

    if (checkitem.checkitemgroupId && checkitem.checkitemgroupId > 0) {
      data.checkitemgroup = checkitem.checkitemgroupId;
    }

    return data as CheckitemUpdateData;
  }

  /**
   * Returns data object for a checkitemgroup to be used in API requests
   * @param {CheckitemgroupEntity | CheckitemgroupNestedEntity} checkitemgroup the checkitemgroup Object
   * @returns {CheckitemgroupUpdateData} the data
   */
  static getCheckitemgroupAPIData(
    checkitemgroup: CheckitemgroupEntity | CheckitemgroupNestedEntity
  ): CheckitemgroupUpdateData {
    const { id, checkitemgrouptypeId, disabled, defaultCheckitemgroupId } = checkitemgroup;

    let checkitems;
    if ((<CheckitemgroupEntity>checkitemgroup).checkitemIds) {
      checkitems = (<CheckitemgroupEntity>checkitemgroup).checkitemgroupIds;
    } else if ((<CheckitemgroupNestedEntity>checkitemgroup).checkitems) {
      checkitems = (<CheckitemgroupNestedEntity>checkitemgroup).checkitems.map((checkitem) =>
        QMUtil.getCheckitemAPIData({ ...checkitem })
      );
    }

    const data = {
      id: id && id > 0 ? id : undefined,
      checkitemgrouptype: checkitemgrouptypeId,
      disabled,
      default_checkitemgroup: defaultCheckitemgroupId,
      checkitems,
    } as CheckitemgroupUpdateData;

    if (checkitemgroup.roomgroupId && checkitemgroup.roomgroupId > 0) {
      data.roomgroup = checkitemgroup.roomgroupId;
    }

    return data as CheckitemgroupUpdateData;
  }

  /**
   * Returns data object for a roomgroup to be used in API requests
   * @param {RoomgroupEntity | RoomgroupNestedEntity} roomgroup the roomgroup Object
   * @param {number} craftId the craft for all roomgroups
   * @returns {RoomgroupUpdateData} the data
   */
  static getRoomgroupAPIData(
    roomgroup: RoomgroupEntity | RoomgroupNestedEntity,
    craftId?: number
  ): RoomgroupUpdateData {
    const { id, name, companyId, clientId, disabled, defaultRoomgroupId } = roomgroup;

    let checkitemgroups;
    if ((<RoomgroupEntity>roomgroup).checkitemgroupIds) {
      checkitemgroups = (<RoomgroupEntity>roomgroup).checkitemgroupIds;
    } else if ((<RoomgroupNestedEntity>roomgroup).checkitemgroups) {
      checkitemgroups = (<RoomgroupNestedEntity>roomgroup).checkitemgroups.map((checkitemgroup) =>
        QMUtil.getCheckitemgroupAPIData({ ...checkitemgroup })
      );
    }

    const data = {
      id: id && id > 0 ? id : undefined,
      name,
      company: companyId,
      client: clientId,
      craft: craftId,
      disabled,
      default_roomgroup: defaultRoomgroupId,
      checkitemgroups,
    };

    return data as RoomgroupUpdateData;
  }

  static disableInParentRoomgroups(
    roomgroups: RoomgroupNestedEntity[],
    sources: CheckSource[]
  ): RoomgroupNestedEntity[] {
    return roomgroups.map(({ disabled: rgDisabled, checkitemgroups, ...roomgroup }) => ({
      ...roomgroup,
      checkitemgroups: checkitemgroups.map(({ disabled: cigDisabled, checkitems, ...checkitemgroup }) => ({
        ...checkitemgroup,
        checkitems: checkitems.map(({ disabled: ciDisabled, ...checkitem }) => ({
          ...checkitem,
          disabled: roomgroup.source && sources.includes(roomgroup.source) ? true : ciDisabled,
        })),
        disabled: roomgroup.source && sources.includes(roomgroup.source) ? true : cigDisabled,
      })),
      disabled: roomgroup.source && sources.includes(roomgroup.source) ? true : rgDisabled,
    }));
  }

  static getChecksource({
    company,
    client,
  }: {
    company?: number | undefined;
    client?: number | undefined;
  }): CheckSource {
    if (client) {
      return 'CLIENT';
    }
    if (company) {
      return 'COMPANY';
    }
    return 'GLOBAL';
  }

  static treeSelectors = [
    ['defaultRoomgroupId', 'checkitemgroups'],
    ['defaultCheckitemgroupId', 'checkitems'],
    ['defaultCheckitemId'],
  ];

  static mergeRoomgroupTree(items: RoomgroupNestedEntity[]): RoomgroupNestedEntity[] {
    return QMUtil.mergeDataTree(_cloneDeep(items), 0) as RoomgroupNestedEntity[];
  }

  static mergeDataTree(
    items: (RoomgroupNestedEntity | CheckitemgroupNestedEntity | CheckitemEntity)[],
    level: number
  ): (RoomgroupNestedEntity | CheckitemgroupNestedEntity | CheckitemEntity)[] {
    const [parentSelector, childSelector] = QMUtil.treeSelectors[level];

    const allParentIds = [] as number[];
    _forEach(items, (item) => {
      const parents = QMUtil.collectParents(item, items, parentSelector);
      if (parents) {
        // remove item itself
        parents.splice(0, 1);
        const parentIds = _filter(
          parents.map((parent) => parent?.id || null),
          (i) => !!i
        ) as number[];
        allParentIds.push(...parentIds);
      }
      if (childSelector) {
        const allChildren = _flatten(
          [item, ...parents].map((parent) => (childSelector && parent[childSelector]) || [])
        );
        const filteredChildren = QMUtil.mergeDataTree(allChildren, level + 1);
        item[childSelector] = filteredChildren;
      }
    });
    // we only need this on the top level
    return _differenceWith(items, allParentIds, (item, id) => !item.id || item.id === id);
  }

  static collectParents(
    item: RoomgroupNestedEntity | CheckitemgroupNestedEntity | CheckitemEntity,
    items: (RoomgroupNestedEntity | CheckitemgroupNestedEntity | CheckitemEntity)[],
    parentSelector: string
  ): (RoomgroupNestedEntity | CheckitemgroupNestedEntity | CheckitemEntity)[] {
    if (item[parentSelector]) {
      const parentId = item[parentSelector];
      const parent = items.find((it) => it.id === parentId);
      if (parent) {
        const parents = QMUtil.collectParents(parent, items, parentSelector);
        return [item, ...parents];
      }
    }
    return [item];
  }

  static companyPopulateOptions = {
    clients: {
      populate: {
        roomgroups: {
          populate: roomgroupsPopulateOptions,
        },
        schedules: { populate: { users: { populate: '*' } } },
        location: '*',
        costcenter_rel: '*',
        contacts: '*',
      },
    },
    roomgroups: {
      populate: roomgroupsPopulateOptions,
    },
    crafts: {
      fields: ['id'],
    },
    contacts: '*',
  };

  static clientPopulateOptions = {
    company: {
      populate: {
        roomgroups: {
          populate: roomgroupsPopulateOptions,
        },
        crafts: {
          fields: ['id'],
        },
        contacts: '*',
      },
    },
    costcenter_rel: { populate: { users: { populate: { role: '*' } } } },
    schedules: { populate: { users: { populate: '*' } } },
    location: '*',
    roomgroups: {
      populate: roomgroupsPopulateOptions,
    },
    contacts: '*',
  };

  static reportPopulateOptions = {
    client: {
      populate: {
        company: '*',
        location: '*',
      },
    },
    schedule: {
      fields: ['id'],
    },
  };

  static reportroomPopulateOptions = {
    report: {
      populate: {
        reporter: '*',
      },
    },
    roomgroup: {
      populate: {
        checkitemgroups: {
          populate: {
            checkitems: {
              populate: {
                checkitemtype: '*',
              },
            },
          },
        },
      },
    },
    checkresults: {
      populate: {
        checkitem: {
          populate: {
            pictures: '*',
          },
        },
      },
    },
  };

  static checkresultPopulateOptions = {
    checkitem: {
      fields: ['id'],
    },
    pictures: '*',
    reportroom: {
      populate: {
        report: {
          populate: {
            client: {
              populate: ['schedules'],
            },
            reporter: '*',
            reportrooms: '*',
          },
        },
        roomgroup: '*',
        checkresults: {
          fields: ['id'],
        },
      },
    },
  };

  // for add/edit client schedules
  static schedulesQueryOptions = {
    populate: {
      contacts: '*',
      users: '*',
      client: {
        populate: {
          company: { fields: ['id'] },
          location: '*',
          costcenter_rel: { populate: { users: { populate: { role: '*' } } } },
        },
        reports: '*',
      },
      craft: {
        fields: ['id', 'name'],
      },
    },
  };

  static getAPIFilter(filter, collection) {
    const {
      companyId,
      companyNumber,
      locationId,
      locationNumber,
      costcenterId,
      costcenterNumber,
      userId,
      craftId,
      orderBy,
      status,
      startDate,
      endDate,
    } = filter;

    const res = {};

    let endDateString;
    let startDateString;

    if (startDate) {
      const adjustedStartDate = new Date(startDate);
      adjustedStartDate.setMinutes(adjustedStartDate.getMinutes() - adjustedStartDate.getTimezoneOffset());
      startDateString = adjustedStartDate ? adjustedStartDate.toISOString().split('T')[0] : null;
    }
    if (endDate) {
      const adjustedEndDate = new Date(startDate);
      adjustedEndDate.setTime(endDate.getTime() + DAY_MS);
      adjustedEndDate.setMinutes(adjustedEndDate.getMinutes() - adjustedEndDate.getTimezoneOffset());
      endDateString = adjustedEndDate.toISOString().split('T')[0];
    }

    // map to API params
    if (collection === 'schedules') {
      const now = new Date();
      if (companyId) {
        set(res, ['filters', 'client', 'company', 'id'], companyId);
      }
      if (locationId) {
        set(res, ['filters', 'client', 'location', 'id'], locationId);
      }
      if (costcenterId) {
        set(res, ['filters', 'client', 'costcenter_rel', 'id'], costcenterId);
      }
      if (userId) {
        set(res, ['filters', 'users', 'id', '$in'], userId);
      }
      // show due schedules only
      set(
        res,
        ['filters', '$or'],
        [
          { $and: [{ interval: 'annually' }, { due_date: { $lte: new Date().setFullYear(now.getFullYear() + 1) } }] },
          { $and: [{ interval: 'semiAnnually' }, { due_date: { $lte: new Date().setMonth(now.getMonth() + 6) } }] },
          { $and: [{ interval: 'quarterly' }, { due_date: { $lte: new Date().setMonth(now.getMonth() + 4) } }] },
          { $and: [{ interval: 'monthly' }, { due_date: { $lte: new Date().setMonth(now.getMonth() + 1) } }] },
          { $and: [{ interval: 'weekly' }, { due_date: { $lte: new Date().setDate(now.getDate() + 7) } }] },
        ]
      );

      if (orderBy) {
        const [key, direction = 'asc'] = orderBy.split(':');
        if (key === 'date') {
          set(res, 'sort.0', `due_date:${direction}`);
        } else if (key === 'company') {
          set(res, 'sort.0', `client.company.name:${direction}`);
        } else if (key === 'location') {
          set(res, 'sort.0', `client.location.description:${direction}`);
        } else if (key === 'score_percentage') {
          set(res, 'sort.0', `reports.0.score_percentage:${direction}`);
        }
      }
    } else if (collection === 'reports') {
      if (companyNumber) {
        set(res, ['filters', 'company_number'], companyNumber);
      }
      if (locationNumber) {
        set(res, ['filters', 'location_number'], locationNumber);
      }
      if (costcenterNumber) {
        set(res, ['filters', 'costcenter_number'], costcenterNumber);
      }
      if (userId) {
        set(res, ['filters', 'reporter', 'id'], userId);
      }
      if (status === 'finished') {
        set(res, ['filters', 'status'], 'finished');
      }
      if (status === 'pending') {
        set(res, ['filters', '$or'], [{ status: { $null: true } }, { status: 'pending' }]);
      }
      if (startDateString) {
        set(res, ['filters', 'date', '$gte'], startDateString);
      }
      if (endDateString) {
        set(res, ['filters', 'date', '$lt'], endDateString);
      }

      if (orderBy) {
        const [key, direction = 'asc'] = orderBy.split(':');
        if (key === 'date') {
          set(res, 'sort.0', `date:${direction}`);
        } else if (key === 'company') {
          set(res, 'sort.0', `company_name:${direction}`);
        } else if (key === 'location') {
          set(res, 'sort.0', `location_description:${direction}`);
        } else if (key === 'score_percentage') {
          set(res, 'sort.0', `score_percentage:${direction}`);
        }
      }
    }
    if (craftId) {
      set(res, ['filters', 'craft', 'id'], craftId);
    }
    if (craftId === 0) {
      // only show the ones without craft
      set(res, ['filters', 'craft', 'id', '$null'], true);
    }
    // neccessary to prevent fetching entry again when using pagination
    set(res, 'sort.1', 'id');
    return res;
  }

  static getCompareValue(entity, collection, key) {
    if (collection === 'schedules') {
      if (key === 'date') {
        return get(entity, 'due_date');
      } else if (key === 'company') {
        return get(entity, 'client.company.name');
      } else if (key === 'location') {
        return get(entity, 'client.location.description');
      } else if (key === 'score_percentage') {
        return get(entity, 'reports.0.score_percentage');
      }
    }
    if (collection === 'reports') {
      if (key === 'date') {
        return get(entity, 'date');
      } else if (key === 'company') {
        return get(entity, 'company_name');
      } else if (key === 'location') {
        return get(entity, 'location_description');
      } else if (key === 'score_percentage') {
        return get(entity, 'score_percentage');
      }
    }
  }
}
export default QMUtil;
