import React, { useState } from 'react';
import { Collapse, InlineSVG, UiDiv, UiText } from '@webfox-sc/core';
import { useTheme } from 'styled-components';

import { useAppDispatch } from '../../app/appHooks';
import {
  createCheckresult,
  createCheckresultPictures,
  deleteCheckresultPicture,
  updateCheckresult,
} from '../../app/slices/checkresultsSlice';
import IconRatingBad from '../../assets/icons-v2/IconRatingBad';
import IconRatingGood from '../../assets/icons-v2/IconRatingGood';
import IconRatingNeutral from '../../assets/icons-v2/IconRatingNeutral';
import IconRatingNone from '../../assets/icons-v2/IconRatingNone';
import RatingButton from '../atoms-v2/RatingButton';

import QMReportComment from './QMReportComment';
import QMReportroomCheckitemPictures from './QMReportroomCheckitemPictures';
import { useQMReportroomCheckitem } from './useQMReportroomCheckitem';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../assets/icons-v2/IconArrowUp';
import { REQUIRE_COMMENT_IF_NEGATIVE } from '../../app.config';

type QMReportroomCheckitemProps = {
  reportroomId: number | undefined;
  checkitemId: number | undefined;
  editMode?: boolean;
};

const QMReportroomCheckitem: React.FC<QMReportroomCheckitemProps> = ({ reportroomId, checkitemId, editMode }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { checkitem, checkitemtype, checkitemgroup, checkitemgrouptype, checkresult } = useQMReportroomCheckitem(
    reportroomId,
    checkitemId
  );

  const [isCommentExtended, setIsCommentExtended] = useState(false);
  const [showContent, setShowContent] = useState<boolean>(true);
  const [negativeRatingPending, setNegativeRatingPending] = useState(false);

  if (!reportroomId || !checkitem || !checkitemgroup) {
    return null;
  }

  const createData: CheckresultCreateData = {
    checkitemtype: checkitemtype?.id,
    checkitemgrouptype: checkitemgrouptype?.id,
    question: checkitem.question,
    weight: checkitem.weight,
    reportroom: reportroomId,
    checkitem: checkitem.id,
    checkitem_id: checkitem.id,
    checkitemgroup_id: checkitemgroup.id,
  };

  const onClickToggle = () => {
    setShowContent(!showContent);
  };

  const handleOnClickRating = async (rating: CheckresultRating) => {
    // if negative, comment is required
    if (REQUIRE_COMMENT_IF_NEGATIVE && rating === 0 && !checkresult?.comment) {
      setNegativeRatingPending(true);
      setIsCommentExtended(true);
    } else {
      if (negativeRatingPending) {
        setNegativeRatingPending(true);
      }
      if (!checkresult) {
        dispatch(
          createCheckresult({
            ...createData,
            rating,
          })
        );
      } else if (rating !== checkresult?.rating) {
        dispatch(
          updateCheckresult(checkresult.id, {
            rating,
          })
        );
      }
    }
  };

  const handleToggleComment = (show: boolean) => {
    if (!show && negativeRatingPending) {
      // reset rating
      setNegativeRatingPending(false);
    }
    setIsCommentExtended(false);
  };

  const handleOnSaveComment = (comment?: string) => {
    if (!checkresult) {
      dispatch(
        createCheckresult({
          ...createData,
          ...(negativeRatingPending ? { rating: 0 } : {}),
          comment,
        })
      );
    } else if (comment !== checkresult?.comment) {
      dispatch(
        updateCheckresult(checkresult.id, {
          ...(negativeRatingPending ? { rating: 0 } : {}),
          comment,
        })
      );
    }
  };

  const handleOnCreatePictures = async (fileList: FileList | null) => {
    if (fileList) {
      const formData = new FormData();
      Object.values(fileList).forEach((file) => {
        formData.append('files.files', file, file.name);
      });
      formData.append('data', JSON.stringify({}));

      if (!checkresult) {
        const res = await dispatch(
          createCheckresult({
            ...createData,
          })
        );
        const checkresultIdNew = res.payload?.data?.id;
        if (checkresultIdNew) {
          dispatch(createCheckresultPictures(checkresultIdNew, formData));
        }
      } else {
        dispatch(createCheckresultPictures(checkresult.id, formData));
      }
    }
  };

  const handleOnDeletePicture = async (pictureId: number) => {
    if (checkresult) {
      dispatch(deleteCheckresultPicture(checkresult.id, pictureId));
    }
  };

  let ratingColor;
  let RatingIcon;
  switch (checkresult?.rating) {
    case -1:
      ratingColor = theme.colors.smgDarkGrey;
      RatingIcon = IconRatingNone;
      break;
    case 0:
      ratingColor = theme.colors.trafficLightRed;
      RatingIcon = IconRatingBad;
      break;
    case 1:
      ratingColor = theme.colors.trafficLightYellow;
      RatingIcon = IconRatingNeutral;
      break;
    case 2:
      ratingColor = theme.colors.trafficLightGreen;
      RatingIcon = IconRatingGood;
  }

  return (
    <UiDiv background="white" color="ciBlue1"padding="s" display="flex" flexDirection="column">
      <UiDiv display="flex" alignItems="center" columnGap="m" cursor="pointer" onClick={onClickToggle}>
        <UiDiv flexShrink={0} display="flex">
          <InlineSVG width="12px" height="12px">
            {showContent ? IconArrowUp : IconArrowDown}
          </InlineSVG>
        </UiDiv>
        <UiDiv flexGrow={1} minHeight="36px" display="flex" alignItems="center">
          <UiText variant="h3">
            {checkitemgrouptype?.name}: {checkitemtype?.name}
          </UiText>
        </UiDiv>
        <UiDiv>
          {RatingIcon && !showContent && (
            <InlineSVG width="36px" height="36px" color={ratingColor}>
              {RatingIcon}
            </InlineSVG>
          )}
        </UiDiv>
      </UiDiv>
      <Collapse show={showContent} fadeInOut keepChildren={false}>
        <UiDiv display={{ tablet: 'flex' }} gap="m">
          <UiDiv flexGrow={1} marginTop="xs" marginBottom="s">
            <UiDiv>{checkitem?.question}</UiDiv>
          </UiDiv>
          <UiDiv marginTop={{ mobile: 'm', tablet: 's' }}>
            <UiDiv display="flex" columnGap={{ mobile: 's', tablet: 'xs' }} justifyContent="center">
              {editMode ? (
                <>
                  <RatingButton
                    variant="none"
                    onClick={() => handleOnClickRating(-1)}
                    active={!negativeRatingPending && checkresult?.rating === -1}
                  />
                  <RatingButton
                    variant={negativeRatingPending ? 'bad-pending' : 'bad'}
                    onClick={() => handleOnClickRating(0)}
                    active={checkresult?.rating === 0 || negativeRatingPending}
                  />
                  <RatingButton
                    variant="neutral"
                    onClick={() => handleOnClickRating(1)}
                    active={!negativeRatingPending && checkresult?.rating === 1}
                  />
                  <RatingButton
                    variant="good"
                    onClick={() => handleOnClickRating(2)}
                    active={!negativeRatingPending && checkresult?.rating === 2}
                  />
                </>
              ) : (
                <>
                  <InlineSVG
                    width="50px"
                    height="50px"
                    color={checkresult?.rating === -1 ? theme.colors.grey80 : theme.colors.grey20}
                  >
                    {IconRatingNone}
                  </InlineSVG>
                  <InlineSVG
                    width="50px"
                    height="50px"
                    color={checkresult?.rating === 0 ? theme.colors.trafficLightRed : theme.colors.grey20}
                  >
                    {IconRatingBad}
                  </InlineSVG>
                  <InlineSVG
                    width="50px"
                    height="50px"
                    color={checkresult?.rating === 1 ? theme.colors.trafficLightYellow : theme.colors.grey20}
                  >
                    {IconRatingNeutral}
                  </InlineSVG>
                  <InlineSVG
                    width="50px"
                    height="50px"
                    color={checkresult?.rating === 2 ? theme.colors.trafficLightGreen : theme.colors.grey20}
                  >
                    {IconRatingGood}
                  </InlineSVG>
                </>
              )}
            </UiDiv>
          </UiDiv>
        </UiDiv>
        {(editMode || !!checkresult?.comment || (checkresult?.pictureIds || []).length > 0) && (
          <UiDiv
            display="flex"
            flexDirection={
              isCommentExtended || !!checkresult?.comment || (checkresult?.pictureIds || []).length > 0
                ? 'column'
                : 'row'
            }
            marginTop={{ mobile: 'm', tablet: 's' }}
            rowGap="m"
            columnGap="15px"
          >
            <QMReportComment
              editMode={editMode}
              comment={checkresult?.comment}
              isRequired={negativeRatingPending}
              onToggle={handleToggleComment}
              onSave={(comment) => handleOnSaveComment(comment)}
            />
            <QMReportroomCheckitemPictures
              editMode={editMode}
              checkresultId={checkresult?.id}
              onSelectPictures={(fileList) => handleOnCreatePictures(fileList)}
              onDeletePicture={handleOnDeletePicture}
            />
          </UiDiv>
        )}
      </Collapse>
    </UiDiv>
  );
};

export default QMReportroomCheckitem;
