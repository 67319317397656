const IconCheckCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M50,0C23,0,.85,22.54.85,50S23,100,50,100,99.15,77.46,99.15,50,77,0,50,0Zm-6,72.74L22.53,50.85l8.14-8.29,13.76,14L72,31l7.76,8.67Z"
    />
  </svg>
);

export default IconCheckCircle;
