import { apiUrl, downloadUrl } from '../app/api';

class RestUtil {
    static getStrapiUrl() {
        return apiUrl;
    }

    static getDownloadUrl(url) {
        if (url.startsWith('http')) {
            return url;
        }
        return `${downloadUrl}${url}`;
    }
}

export default RestUtil;
