import React from 'react';
import styled, { CSSObject } from 'styled-components';

const StyledButton = styled.button(({ theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '22px',
    border: 0,
    borderRadius: 0,
    padding: 0,
    margin: 0,
    background: 'transparent',
    ...theme.type.copy,
    fontSize: '22px',
    lineHeight: '27px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    color: 'white',
    cursor: 'pointer',
    transition: 'color 0.2s ease',
    svg: {
      width: '24px',
      height: '24px',
      verticalAlign: 'top',
    },
    '&:hover': {
      color: theme.colors.ciBlue4,
    },
    '&:active': {
      color: theme.colors.ciBlue5,
    },
    '&[data-active="true"]': {
      color: theme.colors.ciRed,
    },
  };

  return baseStyle;
});

interface MenuOverlayButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  iconSize?: 'large';
  active?: boolean;
}

const MenuOverlayButton: React.FC<MenuOverlayButtonProps> = ({ icon, iconSize, active, type, children, ...props }) => {
  const dataAttributes = {
    'data-active': active,
  };

  return (
    <StyledButton type={type || 'button'} {...props} {...dataAttributes}>
      {children}
      {icon}
    </StyledButton>
  );
};

export default MenuOverlayButton;
