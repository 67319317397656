import React, { useEffect, useState } from 'react';
import { UiDiv, UiInput, UiInputProps } from '@webfox-sc/core';
import { CSSObject, useTheme } from 'styled-components';

export interface TextInputProps extends UiInputProps {
  variant?: 'outline';
  endIcon?: React.ReactNode;
  error?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({ variant, endIcon, error, width, value, onChange, ...props }) => {
  const theme = useTheme();
  const [draftValue, setDraftValue] = useState(value);

  useEffect(() => {
    setDraftValue(value);
  }, [value]);

  let csso: CSSObject = {
    border: `1px solid ${theme.colors.ciBlue1}`,
    background: 'white',
    padding: '13px 10px',
    paddingRight: endIcon ? '48px' : undefined,
    height: '50px',
    boxSizing: 'border-box',
    transition: 'background-color 0.1s ease',
    ...theme.textVariants.copy,
    color: theme.colors.ciBlue1,
    '&::placeholder': {
      fontStyle: 'italic',
      color: theme.colors.ciBlue1,
      opacity: 0.5,
    },
    '&:hover': {
      background: theme.colors.grey20,
    },
    '&:focus': {
      outline: 0,
      background: 'white',
    },
    '&:disabled': {
      '&:hover': {
        background: 'white',
      },
    },
  };

  if (variant === 'outline') {
    csso = {
      ...csso,
      borderColor: theme.colors.white,
      background: 'transparent',
      color: theme.colors.white,
      '&::placeholder': {
        fontStyle: 'italic',
        color: theme.colors.white,
        opacity: 0.5,
      },
      '&:hover': {
        background: theme.colors.ciBlue2,
      },
      '&:focus': {
        outline: 0,
        background: theme.colors.ciBlue3,
      },
      '&:disabled': {
        color: theme.colors.white,
        '&:hover': {
          background: 'transparent',
        },
      },
    };
  }

  if (error) {
    csso = {
      ...csso,
      borderColor: theme.colors.trafficLightRed,
    };
  }

  const inputComponent = (
    <UiInput
      value={draftValue !== undefined && draftValue !== null ? draftValue : ''}
      csso={csso}
      width={width || '100%'}
      onChange={(e): void => {
        setDraftValue(e.target.value);
        if (onChange) {
          onChange(e);
        }
      }}
      {...props}
    />
  );

  if (endIcon) {
    return (
      <UiDiv position="relative">
        {inputComponent}
        <UiDiv
          position="absolute"
          top="1px"
          right="1px"
          width="48px"
          height="48px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <UiDiv
            csso={{
              svg: { width: '14px', height: '14px', verticalAlign: 'top' },
            }}
            color={variant === 'outline' ? 'white' : 'ciBlue1'}
            width="14px"
            height="14px"
          >
            {endIcon}
          </UiDiv>
        </UiDiv>
      </UiDiv>
    );
  }

  return inputComponent;
};

export default TextInput;
