import React from 'react';
import { Image, UiDiv } from '@webfox-sc/core';

import { usePicture } from '../../app/entityHooks/usePicture';
import IconDelete from '../../assets/icons-v2/IconDelete';
import IconResize from '../../assets/icons-v2/IconResize';
import IconButton from '../atoms-v2/IconButton';
import IconLink from '../atoms-v2/IconLink';

interface QMReportroomCheckitemPictureProps {
  pictureId: number | undefined;
  editMode?: boolean;
  onClickDelete?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const QMReportroomCheckitemPicture: React.FC<QMReportroomCheckitemPictureProps> = ({
  pictureId,
  editMode,
  onClickDelete,
}) => {
  const { picture, pictureTeaserUrl, pictureUrl } = usePicture(pictureId);

  return (
    <UiDiv position="relative" minWidth="200px" maxWidth="100%" height="200px" background="grey80">
      <Image
        src={pictureTeaserUrl}
        alt={picture?.name}
        minWidth="200px"
        maxWidth="100%"
        height="100%"
        objectFit="contain"
      />
      <UiDiv position="absolute" top="5px" right="5px" display="flex" columnGap="xxs">
        <UiDiv
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="40px"
          height="40px"
          background="ciBlue4"
        >
          <IconLink variant="white" aria-label="Vergrößern" icon={IconResize} href={pictureUrl} target="_blank" />
        </UiDiv>
        {editMode && (
          <UiDiv
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="40px"
            height="40px"
            background="ciBlue4"
          >
            <IconButton variant="white" aria-label="Entfernen" icon={IconDelete} onClick={onClickDelete} />
          </UiDiv>
        )}
      </UiDiv>
    </UiDiv>
  );
};

export default QMReportroomCheckitemPicture;
