import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { checkitemtypeSchema } from '../schemas';

export const fetchCheckitemtypes = (): RequestEntitiesAction<CheckitemtypeData[]> =>
  requestEntities({
    method: 'GET',
    path: '/checkitemtypes',
    params: {
      sort: 'name',
    },
    schema: [checkitemtypeSchema],
  });

export const createCheckitemtype = (data: CheckitemtypeCreateData): RequestEntitiesAction<CheckitemtypeData> =>
  requestEntities({
    method: 'POST',
    path: '/checkitemtypes',
    data,
    schema: checkitemtypeSchema,
  });

export const updateCheckitemtype = (
  checkitemtypeId: number,
  data: CheckitemtypeUpdateData
): RequestEntitiesAction<CheckitemtypeData> =>
  requestEntities({
    method: 'PUT',
    path: `/checkitemtypes/${checkitemtypeId}`,
    data,
    schema: checkitemtypeSchema,
  });

export const deleteCheckitemtype = (checkitemtypeId: number): RequestEntitiesAction<CheckitemtypeData> =>
  requestEntities({
    method: 'DELETE',
    path: `/checkitemtypes/${checkitemtypeId}`,
    schema: checkitemtypeSchema,
    type: 'deleteCheckitemtype',
  });

const checkitemtypesAdapter = createEntityAdapter<CheckitemtypeEntity>();

export const checkitemtypesSlice = createSlice({
  name: 'checkitemtypes',
  initialState: checkitemtypesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.checkitemtypes) {
        checkitemtypesAdapter.upsertMany(state, action.payload.entities.checkitemtypes);
      }
      if (action.meta.arg.type === 'deleteCheckitemtype') {
        checkitemtypesAdapter.removeOne(state, action.payload.data.id);
      }
    });
  },
});

export default checkitemtypesSlice.reducer;
