/* xeslint-disable */

class StoreModel {
  constructor() {
    this._store = null;
  }

  set store(store) {
    this._store = store;
  }

  get store() {
    return this._store;
  }

  get state() {
    return this._store.getState();
  }

  get authState() {
    const { authState } = this.state;
    return authState;
  }

  get companiesState() {
    const { companiesState } = this.state;
    return companiesState;
  }

  get locationsState() {
    const { locationsState } = this.state;
    return locationsState;
  }

  get clientsState() {
    const { clientsState } = this.state;
    return clientsState;
  }

  get configsState() {
    const { configsState } = this.state;
    return configsState;
  }

  get costcentersState() {
    const { costcentersState } = this.state;
    return costcentersState;
  }

  get historyState() {
    const { historyState } = this.state;
    return historyState;
  }

  get jobsStateLegacy() {
    const { jobsStateLegacy } = this.state;
    return jobsStateLegacy;
  }

  get servicesState() {
    const { servicesState } = this.state;
    return servicesState;
  }

  get userState() {
    const { userState } = this.state;
    return userState;
  }

  get usersState() {
    const { usersState } = this.state;
    return usersState;
  }

  dispatch(action) {
    this._store.dispatch(action);
  }
}

const storeModel = new StoreModel();
export default storeModel;
