/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconDelete from '../../assets/icons-v2/IconDelete';
import ButtonPrimary from '../../atoms/ButtonPrimary';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import Section from '../../atoms/Section';
import { useAddressActions } from '../../hooks/useAddressActions';
import { createCompany, deleteCompany, updateCompanyLegacy } from '../../redux-actions/actionsCompanies';
import CompaniesUtil from '../../utils/CompaniesUtil';
import AddressEditor from '../AddressEditor';
import FormPanel from '../atoms-v2/FormPanel';
import Advice from '../elements/Advice';
import BasicButtonGroup from '../elements/BasicButtonGroup';
import BasicInput from '../elements/BasicInput';
import FormCell from '../elements/FormCell';
import FormInfo from '../elements/FormInfo';
import FormLabel from '../elements/FormLabel';
import FormRow from '../elements/FormRow';
import ToggleBox from '../elements/ToggleBox';
import { useToastContext } from '../toast/ToastProvider';

const CompanyEditor = ({
  model = {},
  isError,
  adviceText: initialAdviceText,
  showAdvice: hasAdvice,
  isUpdateMode,
  initModel,
  onCancel,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = useToastContext();
  const { fetchAddressLegacy } = useAddressActions();

  const [rawModel, setRawModel] = useState(model);
  const [modelState, setModelState] = useState(model);

  const [showAdvice, setShowAdvice] = useState(hasAdvice || model.isModified || model.isNew);

  useEffect(() => {
    if (model !== rawModel) {
      if (model) {
        setModelState(model);
        if (model.isModified || model.isNew) {
          setShowAdvice(true);
        }
        setRawModel(model);
      } else {
        const emptyModel = CompaniesUtil.getEmptyObject();
        setModelState(emptyModel);
        setRawModel(emptyModel);
      }
    }
  }, [model]);

  const addressModel = useSelector(
    ({ dataState }) => (model.addressId && dataState.addresses[model.addressId]) || null
  );

  useEffect(() => {
    if (model.addressId && !addressModel) {
      fetchAddressLegacy(model.addressId);
    }
  }, [model]);

  if (addressModel && !modelState.address) {
    setModelState({ ...modelState, address: addressModel });
  }

  const handleChangeFormValue = (name, value) => {
    setModelState({
      ...modelState,
      [name]: value,
    });
  };

  const handleOnClickCreate = async () => {
    const strapiData = CompaniesUtil.getAPIData(modelState);
    const newObject = await dispatch(createCompany(strapiData));
    onCreate(newObject);

    const emptyModel = initModel();
    setModelState(emptyModel);
    setRawModel(emptyModel);
  };

  const handleOnClickUpdate = async () => {
    const strapiData = CompaniesUtil.getAPIData(modelState);
    await dispatch(updateCompanyLegacy(model.id, strapiData));
    onUpdate();
  };

  const handleOnClickDelete = async () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(t('company.prompts.delete'))
    ) {
      const deletedObjects = await dispatch(deleteCompany(modelState.id));
      onDelete(deletedObjects);
      showToast(t('company.success.deleted'), 'success');
    }
  };

  const handleOnClickAdvice = () => {
    setShowAdvice(false);
  };

  const { number, numberOld, name, nameOld, isModified, isNew } = modelState;

  let isSuccess = false;
  let isInfo = false;
  let adviceText = initialAdviceText;

  if (isModified) {
    isInfo = true;
    adviceText = t('company.messages.changed');
  }

  if (isNew) {
    isSuccess = true;
    adviceText = t('company.messages.new');
  }

  const isDataValid = number && name;

  return (
    <FormPanel>
      <ToggleBox show={showAdvice}>
        <Section paddingBottom="S">
          <Advice
            text={adviceText}
            isSuccess={isSuccess}
            isInfo={isInfo}
            isError={isError}
            onClick={handleOnClickAdvice}
          />
        </Section>
      </ToggleBox>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="4">
            <FormLabel text={`${t('company.number')} *`} />
            <BasicInput type="text" value={number} onChange={(value) => handleChangeFormValue('number', value)} />
            {numberOld && <FormInfo text={numberOld} />}
          </FormCell>
          <FormCell cols="8">
            <FormLabel text={`${t('company.name')} *`} />
            <BasicInput type="text" value={name} onChange={(value) => handleChangeFormValue('name', value)} />
            {nameOld && <FormInfo text={nameOld} />}
          </FormCell>
        </FormRow>
      </Section>
      <AddressEditor
        model={modelState.address || {}}
        onChange={(address) => {
          handleChangeFormValue('address', address);
        }}
      />
      <Section paddingTop="M">
        <FormRow>
          <FormCell cols="8">
            <BasicButtonGroup>
              {!isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.create')}
                  icon={IconCheck}
                  disabled={!isDataValid}
                  onClick={handleOnClickCreate}
                />
              )}
              {isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.apply')}
                  icon={IconCheck}
                  disabled={!isDataValid}
                  onClick={handleOnClickUpdate}
                />
              )}
              <ButtonSecondary label={t('common.actions.close')} icon={IconClose} onClick={onCancel} />
            </BasicButtonGroup>
          </FormCell>
          {isUpdateMode && (
            <FormCell cols="4" align="right">
              <ButtonSecondary label={t('company.actions.delete')} icon={IconDelete} onClick={handleOnClickDelete} />
            </FormCell>
          )}
        </FormRow>
      </Section>
    </FormPanel>
  );
};

CompanyEditor.propTypes = {
  model: PropTypes.object,
  isUpdateMode: PropTypes.bool,
  showAdvice: PropTypes.bool,
  adviceText: PropTypes.string,
  isError: PropTypes.bool,
  initModel: PropTypes.func,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

CompanyEditor.defaultProps = {
  model: null,
  isUpdateMode: false,
  showAdvice: false,
  adviceText: '',
  isError: false,
  initModel: () => {},
  onCancel: () => false,
  onCreate: () => false,
  onUpdate: () => false,
  onDelete: () => false,
};

export default CompanyEditor;
