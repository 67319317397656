import React from 'react';
import { UiDiv } from '@webfox-sc/core';
import { useTranslation } from 'react-i18next';
import JobHistoryRecipientDiff from './JobHistoryRecipientDiff';
import JobHistoryServiceDiff from './JobHistoryServiceDiff';
import { isEmpty, map, set } from 'lodash';
import JobHistoryDiffField from './JobHistoryDiffField';

const JobHistoryDocumentDiff: React.FC<DiffEntryProps> = ({ oldDiff, newDiff }) => {
  const { t } = useTranslation();

  const servicesMap: { [k in 'old' | 'new']?: any } = {};
  map(oldDiff?.services || {}, (service, key) => {
    set(servicesMap, [key, 'old'], service);
  });
  map(newDiff?.services || {}, (service, key) => {
    set(servicesMap, [key, 'new'], service);
  });
  const services = Object.values(servicesMap);

  return (
    <UiDiv>
      {(oldDiff?.confirmed || newDiff?.confirmed) && (
        <JobHistoryDiffField
          label={t('common.fields.status') || undefined}
          oldValue={t(`common.documentStatus.${oldDiff.confirmed}`) || undefined}
          newValue={t(`common.documentStatus.${newDiff.confirmed}`) || undefined}
        />
      )}
      {(oldDiff?.confirmed_by || newDiff?.confirmed_by) && (
        <JobHistoryDiffField
          label={t('common.fields.confirmedBy') || undefined}
          oldValue={oldDiff?.confirmed_by}
          newValue={newDiff?.confirmed_by}
        />
      )}
      {(oldDiff?.order_number || newDiff?.order_number) && (
        <JobHistoryDiffField
          label={t('common.fields.orderNumber.short') || undefined}
          oldValue={oldDiff?.order_number}
          newValue={newDiff?.order_number}
        />
      )}
      {(oldDiff?.confirmed_reason || newDiff?.confirmed_reason) && (
        <JobHistoryDiffField
          label={t('common.fields.confirmedReason') || undefined}
          oldValue={oldDiff?.confirmed_reason}
          newValue={newDiff?.confirmed_reason}
        />
      )}
      {/* recipient */}
      {(oldDiff?.recipient || newDiff?.recipient) && (
        <>
          <strong>{t('job.recipient')}: </strong>
          <JobHistoryRecipientDiff oldDiff={oldDiff?.recipient} newDiff={newDiff?.recipient} />
        </>
      )}
      {/* services */}
      {services.length > 0 && (
        <>
          <strong>{t('job.services')}: </strong>
          {services.map((service, index) => (
            <JobHistoryServiceDiff key={`s_${index}`} oldDiff={service?.old} newDiff={service?.new} />
          ))}
        </>
      )}
      {/* files */}
      {(!isEmpty(oldDiff?.files) || !isEmpty(newDiff?.files)) && (
        <>
          <strong>{t('common.fields.files')}: </strong>
          {/* <UiDiv display="flex" flexDirection="row" flexWrap="wrap"> */}
          {map(oldDiff?.files || [], (file, index) => (
            <JobHistoryDiffField key={`f_${index}`} oldValue={file} />
          ))}
          {map(newDiff?.files || [], (file, index) => (
            <JobHistoryDiffField key={`f_${index}`} newValue={file} />
          ))}
          {/* </UiDiv> */}
        </>
      )}
    </UiDiv>
  );
};

export default JobHistoryDocumentDiff;
