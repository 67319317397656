import { UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../app/appHooks';
import { pathToQMAdminCompany } from '../../../app/paths';
import { fetchCompany, updateCompany } from '../../../app/slices/companiesSlice';
import {
  deleteCompanyRoomgroups,
  fetchGlobalRoomgroups,
  updateCompanyRoomgroups,
} from '../../../app/slices/roomgroupsSlice';
import QMUtil from '../../../utils/QMUtil';
import PageContent from '../../page/PageContent';
import PageHeading from '../../page/PageHeading';
import { useQMAdminCompany } from '../useQMAdminCompany';
import { useQMAdminNestedRoomgroups } from '../useQMAdminNestedRoomgroups';
import { useCraft } from '../../../app/entityHooks/useCraft';
import { fetchCheckitemtypes } from '../../../app/slices/checkitemtypesSlice';
import { fetchCrafts } from '../../../app/slices/craftsSlice';
import IconCraft from '../../../assets/icons-v2/IconCraft';
import Text from '../../atoms-v2/Text';
import QMAdminConfigRoomgroups from '../qm-admin-config/QMAdminConfigRoomgroups';

const QMAdminCompanyConfig: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companyId: companyIdString, craftId: craftIdString } = useParams();
  const companyId = companyIdString ? parseInt(companyIdString) : -1;
  const craftId = (craftIdString && parseInt(craftIdString)) || null;

  const { company, specificityByCraft } = useQMAdminCompany(companyId);
  const { roomgroups } = useQMAdminNestedRoomgroups(company?.roomgroupIds || [], craftId, true);
  const { craft } = useCraft(craftId || undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (companyId) {
        dispatch(fetchCheckitemtypes());
        dispatch(fetchGlobalRoomgroups());
        dispatch(fetchCrafts());
        dispatch(fetchCompany(companyId, { populate: QMUtil.companyPopulateOptions }));
      }
    };
    fetchData();
  }, [dispatch, companyId]);

  if (!companyId || !company) {
    return null;
  }

  let companyRoomgroups;
  const isCheckSpecific = !!specificityByCraft[`${craftId || 0}`]?.companyId;
  if (isCheckSpecific && roomgroups) {
    // disable all global roomgroups and children
    companyRoomgroups = QMUtil.disableInParentRoomgroups(roomgroups, ['GLOBAL']);
  } else {
    companyRoomgroups = roomgroups;
  }
  const pageScope = 'COMPANY';
  const scope = isCheckSpecific ? 'COMPANY' : 'GLOBAL';

  const handleOnSave = (currentCraftId: number | null, newData: RoomgroupNestedEntity[]) => {
    const strapiData = newData.map((entry) => QMUtil.getRoomgroupAPIData(entry, currentCraftId || undefined));
    dispatch(updateCompanyRoomgroups(companyId, currentCraftId || 0, strapiData));
  };

  const handleOnDelete = (currentCraftId: number | null) => {
    dispatch(deleteCompanyRoomgroups(companyId, currentCraftId || 0));
  };

  const handleOnRemove = (currentCraftId: number | null) => {
    // don't remove default
    if (craftId && company.craftIds && company.craftIds.length > 0) {
      const crafts = company.craftIds.filter((cid) => cid !== currentCraftId);
      dispatch(updateCompany(companyId, { crafts }, { populate: QMUtil.companyPopulateOptions })).then(() =>
        navigate(pathToQMAdminCompany(companyId))
      );
    }
  };

  return (
    <>
      <PageHeading
        heading={company.number}
        subheading={company.name}
        backlinkLabel={t('common.actions.back') || undefined}
        backlinkPath={pathToQMAdminCompany(companyId)}
      />
      <PageContent paddingTop="l">
        <UiText variant="h2" color="white" textAlign="center">
          {t('report.form.specificity.company.long')}
        </UiText>
        <UiDiv padding="m" background="white" marginTop="m">
          <Text icon={IconCraft}>
            <strong>
              {t('common.fields.craft')}: {craft ? craft.name : t('craft.form.options.default')}
            </strong>
          </Text>
          <UiText variant="copy" marginTop="s">
            {t('schedule.info.companySpecificity1')}
            <br />
            {t('schedule.info.companySpecificity2')}
          </UiText>
          {companyRoomgroups && (
            <QMAdminConfigRoomgroups
              roomgroups={companyRoomgroups}
              onSave={(...args) => handleOnSave(craftId, ...args)}
              configScope={scope}
              pageScope={pageScope}
              onDelete={() => handleOnDelete(craftId)}
              onRemove={craftId ? () => handleOnRemove(craftId) : undefined}
            />
          )}
        </UiDiv>
      </PageContent>
    </>
  );
};

export default QMAdminCompanyConfig;
