import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';
import { entitySelectors } from '../entitySelectors';

export const useCraftOptions = (): {
  craftOptions: SelectOption[];
} => {
  const crafts = entitySelectors.selectAll<CraftEntity>('crafts');

  const craftOptions = useMemo(() => {
    const options: SelectOption[] = [];
    crafts.forEach((craft) => {
      if (!craft.disabled) {
        options.push({
          value: craft.id,
          label: craft.name,
        });
      }
    });
    return _orderBy(options, [(option: SelectOption) => option.label], ['asc']);
  }, [crafts]);

  return {
    craftOptions,
  };
};
