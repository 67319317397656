/**
 * COLORS
 */
export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',

  GREY_80: '#585858',
  GREY_60: '#888888',
  GREY_40: '#b2b2b2',
  GREY_20: '#dadada',

  CI_BLUE_1: '#001e49',
  CI_BLUE_2: '#12446c',
  CI_BLUE_3: '#4c668b',
  CI_BLUE_4: '#829db6',
  CI_BLUE_5: '#becfdd',

  CI_RED: '#e73838',

  AC_TURQUOISE: '#00a29d',
  AC_TURQUOISE_DARK: '#005755',
  AC_TURQUOISE_80: '#22b4b1',
  AC_TURQUOISE_60: '#7cc8c4',
  AC_TURQUOISE_40: '#afdcd9',
  AC_TURQUOISE_20: '#d9edec',

  AC_GREEN: '#afca05',
  AC_GREEN_DARK: '#6e7f28',
  AC_GREEN_80: '#c2d34f',
  AC_GREEN_60: '#d2df83',
  AC_GREEN_40: '#e1eab1',
  AC_GREEN_20: '#f0f4db',

  AC_ORANGE: '#ffaa00',
  AC_ORANGE_DARK: '#aa7419',
  AC_ORANGE_80: '#faba58',
  AC_ORANGE_60: '#fccc86',
  AC_ORANGE_40: '#feddb0',
  AC_ORANGE_20: '#ffeed9',

  AC_SALMON: '#e08976',
  AC_SALMON_DARK: '#945b4e',
  AC_SALMON_80: '#e7a390',
  AC_SALMON_60: '#ecbbac',
  AC_SALMON_40: '#f3d3c8',
  AC_SALMON_20: '#f8e9e2',

  AC_SAND: '#bea797',
  AC_SAND_DARK: '#73645c',
  AC_SAND_80: '#cbb8aa',
  AC_SAND_60: '#d7c9c0',
  AC_SAND_40: '#e5dad4',
  AC_SAND_20: '#f2edea',

  TRAFFIC_LIGHT_RED: '#F43636',
  TRAFFIC_LIGHT_YELLOW: '#ffb700',
  TRAFFIC_LIGHT_GREEN: '#79D647',

  TRAFFIC_LIGHT_GREEN_TEXT: '#42BF00',

  SHADER_BLUE: 'rgba(18, 68, 108, 0.8)',
};

/**
 * SPACINGS // appTheme.spacings
 */
export const SPACINGS = {
  XXS: '5px', // xxxs
  XS: '8px', // xxs
  S: '20px', // s
  M: '30px', // m
  L: '50px', // l
  XL: '80px', // xxl
  XXL: '130px', // xxxl
};

/**
 * VARS
 */
export const VARS = {
  FONT_FAMILY: '"radikal", sans-serif',
  COLLAPSE_DEFAULT_DURATION: 400,
};

export const STYLE_TEXT_M = {
  fontFamily: VARS.FONT_FAMILY,
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 400,
  color: COLORS.CI_BLUE_1,
  margin: 0,
};

/**
 * STYLE
 */
export const STYLES = {
  COPY_TEXT_M: {
    fontFamily: VARS.FONT_FAMILY,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    color: COLORS.CI_BLUE_1,
    margin: 0,
  },
  COPY_TEXT_S: {
    fontFamily: VARS.FONT_FAMILY,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    color: COLORS.CI_BLUE_1,
    margin: 0,
  },
};

export const themeCheckbox = {
  checkbox: {
    svg: {
      color: COLORS.CI_BLUE_1,
    },
  },
};

export const themeCheckboxLarge = {
  checkbox: {
    svg: {
      color: COLORS.WHITE,
      height: '36px',
      width: '36px',
    },
    height: '50px',
    width: '50px',
    backgroundColor: 'transparent',
    borderColor: COLORS.WHITE,

  },
};
