import { pick } from 'lodash';
import AddressUtil from './AddressUtil';
import AppUtil from './AppUtil';

class CompaniesUtil {
    static filterKeys = ['id', 'createdAt', 'updatedAt', 'name', 'number'];

    /**
     * Returns an empty company, initialized with data provided
     * @param {Object} initialData data to initialize
     * @reutrn {Object} company
     */
    static getEmptyObject(initialData = {}) {
        const strippedInitData = pick(initialData, CompaniesUtil.filterKeys);
        return {
            name: '',
            number: '',
            address: {},
            ...strippedInitData,
        };
    }

    static parseData(companyData, options = {}) {
        const { additionalData, oldCompany } = options;

        const { id, createdAt, updatedAt, name, number, client_ids, address } = companyData;

        const baseCompany = {
            id: id || null,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            number: number || null,
            name: name || null,
            clientIds: client_ids || [],
            addressId: AppUtil.getEntryId(address),
        };

        let oldProps = {};
        if (oldCompany) {
            oldProps = AppUtil.getOldProps(baseCompany, oldCompany);
        }

        return {
            ...baseCompany,
            ...oldProps,
            ...additionalData,
        };
    }

    /**
     * Returns data object for a company to be used in API requests
     * @param {Object} company the company Object
     * @returns {Object} the data
     */
    static getAPIData(company) {
        const { name, number, address, addressId } = company;

        const data = { name, number };

        if (address) {
            // keep address.number in sync with number
            data.address = AddressUtil.getAPIData({ ...address, number });
        } else if (addressId) {
            data.address = addressId;
        }

        return data;
    }
}

export default CompaniesUtil;
