import axios from 'axios';
import _pick from 'lodash/pick';
import { REDUX_ACTIONS, ROWS_STEP } from '../global/constants';
import { getAPIParams } from '../utils/APIParamsUtil';
import RestUtil from '../utils/RestUtil';
import ServicesUtil from '../utils/ServicesUtil';

const { paramString } = getAPIParams({ populate: { client: { populate: { company: '*', location: '*' } } } });

export function updateServiceModels(serviceModels) {
  return {
    type: REDUX_ACTIONS.UPDATE_SERVICE_MODELS,
    serviceModels,
  };
}

export function updateIsLoadingServices(isLoadingServices) {
  return {
    type: REDUX_ACTIONS.UPDATE_IS_LOADING_SERVICES,
    isLoadingServices,
  };
}

export function fetchService(serviceId, params, dispatchOptions = {}) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_SERVICE,
    });

    return axios({
      method: 'get',
      url: `${RestUtil.getStrapiUrl()}/services/${serviceId}${paramString}`,
      params,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const service = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_SERVICE,
          service,
          ...dispatchOptions,
        });
        return service;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_SERVICE_ERROR,
        });
        throw error;
      });
  };
}

export function createService(data) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_CREATE_SERVICE,
    });

    return axios({
      method: 'post',
      url: `${RestUtil.getStrapiUrl()}/services${paramString}`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const service = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_CREATE_SERVICE,
          service,
        });
        return service;
      })
      .catch(() => {
        // console.log('#createServices error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_CREATE_SERVICE_ERROR,
        });
      });
  };
}

export function updateService(serviceId, data, params) {
  return async (dispatch, getState) => {
    const {
      dataState: { services },
      authState,
    } = getState();

    const oldService = services ? services[serviceId] : {};

    dispatch({
      type: REDUX_ACTIONS.REQUEST_UPDATE_SERVICE,
    });

    return axios({
      method: 'put',
      url: `${RestUtil.getStrapiUrl()}/services/${serviceId}${paramString}`,
      data,
      params,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const service = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_UPDATE_SERVICE,
          service,
          oldService,
        });
        return service;
      })
      .catch((error) => {
        // console.log('#updateService error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_UPDATE_SERVICE_ERROR,
        });
        throw error;
      });
  };
}

export function deleteService(serviceId) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_SERVICE,
    });

    return axios({
      method: 'delete',
      url: `${RestUtil.getStrapiUrl()}/services/${serviceId}${paramString}`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const service = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_SERVICE,
          service,
        });
        return [service];
      })
      .catch((error) => {
        // console.log('#deleteService error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_SERVICE_ERROR,
        });
        throw error;
      });
  };
}

export function countServices() {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_COUNT_SERVICES,
    });

    return axios({
      method: 'get',
      url: `${RestUtil.getStrapiUrl()}/services/count`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const count = res.data || -1;
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_COUNT_SERVICES,
          count,
        });
        return count;
      })
      .catch((error) => {
        // console.log('#countServices error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_COUNT_SERVICES_ERROR,
        });
        throw error;
      });
  };
}

export function fetchServices(filter) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_SERVICES,
    });

    let unparsedParams = {};
    let filterParams = {};

    if (filter) {
      filterParams = ServicesUtil.getAPIData(filter, true);

      if (filter.clientIds) {
        filterParams.client_in = filter.clientIds;
      }

      unparsedParams = _pick(filter, ['_limit', '_start', 'contract_number_in']);
    }

    const params = {
      _limit: ROWS_STEP,
      ...unparsedParams,
      ...filterParams,
    };

    return axios({
      method: 'get',
      url: `${RestUtil.getStrapiUrl()}/services`,
      params,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const services = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_SERVICES,
          services,
        });
        return services;
      })
      .catch((error) => {
        // console.log('#fetchServices error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_SERVICES_ERROR,
        });
        throw error;
      });
  };
}

export function deleteAllServices(serviceIds) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_SERVICE,
    });

    return Promise.all(
      serviceIds.map((serviceId) => {
        return axios({
          method: 'delete',
          url: `${RestUtil.getStrapiUrl()}/services/${serviceId}`,
          headers: {
            Authorization: `Bearer ${authState.jwt}`,
          },
        });
      })
    )
      .then((res) => {
        const services = (res || [{}]).map(({ data }) => data);
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_ALL_SERVICES,
          services,
        });
        return services;
      })
      .catch((error) => {
        // console.log('#deleteService error', error);
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_SERVICE_ERROR,
        });
        throw error;
      });
  };
}
