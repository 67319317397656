import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@webfox-sc/core';
import { COLORS } from '../../theme/theme';

const theme = {
  root: {
    fontFamily: '"radikal", sans-serif',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
    letterSpacing: '0.5px',
    color: COLORS.BLACK,
    textTransform: 'uppercase',
    padding: '15px 24px',
    background: COLORS.AC_GREEN,
    borderRadius: '5px',
    transition: 'background-color 0.1s ease',
    span: {
      marginLeft: '10px',
    },
    svg: {
      width: '14px',
      maxWidth: 'none',
      height: '14px',
    },
    '&[data-with-icon]': {
      paddingLeft: '18px',
    },
    '&[data-with-icon-right]': {
      paddingRight: '18px',
    },
    '&[data-with-icon-only]': {
      padding: '18px',
    },
    '&:hover': {
      background: COLORS.AC_GREEN_DARK,
    },
    '&:active': {
      background: COLORS.AC_GREEN_DARK,
    },
    '&[data-active]': {
      background: COLORS.AC_GREEN_DARK,
    },
  },
};

const ButtonPrimary = (props) => {
  return <Button {...props} theme={theme} />;
};

ButtonPrimary.propTypes = {
  ...Button.propTypes,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  icon: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ButtonPrimary.defaultProps = {
  ...Button.defaultProps,
  label: undefined,
  ariaLabel: undefined,
  icon: undefined,
  active: false,
  disabled: false,
  onClick: () => {},
};

export default ButtonPrimary;
