/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';

export const useAddressActions = () => {
  const dispatch = useDispatch();
  const { jwt } = useSelector(({ authState }) => authState);

  const fetchAddressLegacy = useCallback(async (addressId) => {
    dispatch({
      type: REDUX_ACTIONS.REQUEST_ADDRESS,
    });

    try {
      const response = await axios({
        method: 'get',
        url: `${RestUtil.getStrapiUrl()}/addresses/${addressId}`,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Cache-Control': 'no-store',
          'If-Modified-Since': '0',
        },
      });

      dispatch({
        type: REDUX_ACTIONS.RECEIVE_ADDRESS,
        address: response.data,
      });

      return response.data;
    } catch (error) {
      dispatch({
        type: REDUX_ACTIONS.REQUEST_ADDRESS_ERROR,
      });
      return [];
    }
  }, []);

  return {
    fetchAddressLegacy,
  };
};
