/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';
import { getAPIParams } from '../utils/APIParamsUtil';

export const useLocationActions = () => {
  const dispatch = useDispatch();
  const { jwt } = useSelector(({ authState }) => authState);

  const fetchLocations = useCallback(async (params) => {
    dispatch({
      type: REDUX_ACTIONS.REQUEST_LOCATIONS,
    });

    const { paramString } = getAPIParams(params, true);
    let data;
    try {
      ({ data } = await axios({
        method: 'get',
        url: `${RestUtil.getStrapiUrl()}/locations${paramString}`,
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Cache-Control': 'no-store',
          'If-Modified-Since': '0',
        },
      }));

      const objects = data?.data;
      const count = data?.pagination?.total || 0;
      dispatch({
        type: REDUX_ACTIONS.RECEIVE_LOCATIONS,
        locations: objects,
      });
      if (params && params._limit === -1 && objects) {
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_COUNT_LOCATIONS,
          count,
        });
      }

      return { objects, count };
    } catch (error) {
      dispatch({
        type: REDUX_ACTIONS.REQUEST_LOCATIONS_ERROR,
      });
      return { objects: [], count: 0};
    }
  }, []);

  return {
    fetchLocations,
  };
};
