import { useAppSelector } from '../../app/appHooks';
import { entitySelectors } from '../../app/entitySelectors';

export const useAuth = (): {
  jwt: string | undefined;
  status: 'loggedIn' | 'autoLogin' | 'idle' | 'loading' | 'failed';
  loggedIn: boolean;
  currentUser: UserEntity | undefined;
  currentUserRole: RoleEntity | undefined;
} => {
  const { jwt, status, userId } = useAppSelector(({ authState }) => authState);
  const loggedIn = status === 'loggedIn';
  const currentUser = entitySelectors.selectById<UserEntity>('users', userId);
  const currentUserRole = entitySelectors.selectById<RoleEntity>('roles', currentUser?.roleId);

  return {
    jwt,
    status,
    loggedIn,
    currentUser,
    currentUserRole,
  };
};
