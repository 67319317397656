const IconComment = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M90,3.33H10a10,10,0,0,0-10,10V66.67a10,10,0,0,0,10,10H20V93.33a3.34,3.34,0,0,0,5.42,2.61L49.5,76.67H90a10,10,0,0,0,10-10V13.33A10,10,0,0,0,90,3.33ZM50,50H23.33a3.34,3.34,0,0,1,0-6.67H50A3.34,3.34,0,0,1,50,50ZM76.67,36.67H23.33a3.34,3.34,0,0,1,0-6.67H76.67a3.34,3.34,0,0,1,0,6.67Z"
    />
  </svg>
);

export default IconComment;
