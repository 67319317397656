const IconRefresh = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M97.46,4.49c-1.78-.74-3.3-.43-4.56,.91l-8.46,8.4c-4.64-4.38-9.95-7.78-15.92-10.19-5.97-2.41-12.14-3.61-18.52-3.61-6.77,0-13.24,1.32-19.4,3.97-6.16,2.65-11.48,6.21-15.95,10.68-4.47,4.47-8.03,9.79-10.68,15.95-2.65,6.16-3.97,12.63-3.97,19.4s1.32,13.24,3.97,19.4c2.65,6.16,6.21,11.48,10.68,15.95,4.47,4.47,9.79,8.03,15.95,10.68,6.16,2.65,12.63,3.97,19.4,3.97,7.46,0,14.56-1.57,21.29-4.72,6.73-3.15,12.46-7.58,17.19-13.31,.35-.43,.51-.92,.49-1.46-.02-.54-.23-.99-.62-1.33l-8.92-8.98c-.48-.39-1.02-.59-1.63-.59-.69,.09-1.19,.35-1.5,.78-3.17,4.12-7.05,7.31-11.65,9.57-4.6,2.26-9.48,3.38-14.65,3.38-4.51,0-8.82-.88-12.92-2.64-4.1-1.76-7.65-4.13-10.64-7.13-2.99-3-5.37-6.54-7.13-10.64-1.76-4.1-2.64-8.41-2.64-12.92s.88-8.82,2.64-12.92c1.76-4.1,4.13-7.65,7.13-10.64,2.99-2.99,6.54-5.37,10.64-7.13,4.1-1.76,8.41-2.64,12.92-2.64,8.72,0,16.3,2.97,22.72,8.92l-8.99,8.98c-1.35,1.3-1.65,2.8-.91,4.49,.74,1.74,2.02,2.6,3.84,2.6h29.17c1.13,0,2.1-.41,2.93-1.24,.82-.82,1.24-1.8,1.24-2.93V8.33c0-1.82-.84-3.1-2.54-3.84Z"
    />
  </svg>
);

export default IconRefresh;
