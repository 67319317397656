import React from 'react';
import PropTypes from 'prop-types';
import CheckboxLegacy from '../../atoms/CheckboxLegacy';
import { useBaseDataFilterContext } from '../../hooks/baseDataFilter';

const IsEmptyFilter = ({ label, filterKey }) => {
  const { dataFilter, handleChangeDataFilter } = useBaseDataFilterContext();

  const onChangeCheckbox = (value) => {
    handleChangeDataFilter({
      ...dataFilter,
      [filterKey]: {
        $empty: value,
      },
    });
  };

  const checked = dataFilter && dataFilter[filterKey] && dataFilter[filterKey].$empty;

  return (
    <div className="smg-view-base-data__toolbar">
      <div className="smg-view-base-data__toolbar-filter">
        <CheckboxLegacy label={label} size="L" layout="white" checked={checked} onChange={onChangeCheckbox} />
      </div>
    </div>
  );
};

IsEmptyFilter.propTypes = {
  label: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
};

IsEmptyFilter.defaultProps = {
  label: '',
};

export default IsEmptyFilter;
