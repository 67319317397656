/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import BaseDataFilteredList from '../../components/base-data/shared/BaseDataFilteredList';
import { usePaginatedList } from '../../components/base-data/shared/usePaginatedList';
import ClientsFilter from '../../components/ClientsFilter';
import TableHeaderCell from '../../components/elements/TableHeaderCell';
import TableHeaderRow from '../../components/elements/TableHeaderRow';
import ServiceEditor from '../../components/ServiceEditor';
import { BaseDataFilterProvider } from '../../hooks/baseDataFilter';
import { BaseDataViewProvider } from '../../hooks/baseDataView';
import { useServicesActions } from '../../hooks/useServicesActions';
import { useServicesFilter } from '../../hooks/useServicesFilter';
import { deleteAllServices } from '../../redux-actions/actionsServices';
import ServicesUtil from '../../utils/ServicesUtil';
import ServiceListItem from './ServiceListItem';

const ViewBaseDataServices = () => {
  const { t } = useTranslation();
  const { isDataFilter, initialTableFilter } = useServicesFilter();
  const { fetchServices } = useServicesActions();

  const dataLoaderProps = {
    objectPath: 'services',
    fetchObjects: (params) => fetchServices({ ...params, populate: ServicesUtil.baseViewPopulate }),
    // defaultSortingKey="clientDescription"
  };

  const dispatch = useDispatch();

  return (
    <BaseDataFilterProvider isDataFilter={isDataFilter} initialTableFilter={initialTableFilter}>
      <BaseDataViewProvider
        objectPath="services"
        getNewObject={ServicesUtil.getEmptyObject}
        useFilter={useServicesFilter}
        dataLoader={usePaginatedList}
        dataLoaderProps={dataLoaderProps}
        deleteObjects={(ids) => dispatch(deleteAllServices(ids))}
      >
        <BaseDataFilteredList
          labelObjects={t('common.entities.service.plural')}
          labelNew={t('service.actions.new')}
          toolbar={
            <>
              <ClientsFilter
                withClientGroups
                withEmptyClientFilter
                emptyClientFilterlabel={t('service.form.showWithoutClient')}
              />
            </>
          }
          Editor={ServiceEditor}
          TableHeader={({ handleSort, sortingKey, sortingOrder }) => (
            <TableHeaderRow>
              <TableHeaderCell
                text={t('common.fields.clientNumber')}
                width="12%"
                // isSorting
                onClick={() => handleSort('clientNumber')}
                sortingOrder={sortingKey === 'clientNumber' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.carrierNumber.long')}
                width="12%"
                // isSorting
                onClick={() => handleSort('carrierNumber')}
                sortingOrder={sortingKey === 'carrierNumber' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.dla')}
                width="7%"
                isSorting
                onClick={() => handleSort('dla')}
                sortingOrder={sortingKey === 'dla' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('service.serviceDescription')}
                width="25%"
                isSorting
                onClick={() => handleSort('description')}
                sortingOrder={sortingKey === 'description' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('service.unit')}
                width="10%"
                isSorting
                onClick={() => handleSort('unit')}
                sortingOrder={sortingKey === 'unit' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.price')}
                width="10%"
                isSorting
                onClick={() => handleSort('price')}
                sortingOrder={sortingKey === 'price' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.updatedAt')}
                width="24%"
                isSorting
                onClick={() => handleSort('updatedAt')}
                sortingOrder={sortingKey === 'updatedAt' ? sortingOrder : ''}
              />
            </TableHeaderRow>
          )}
          TableRow={ServiceListItem}
          newEditorPresets={{
            showAdvice: true,
            isError: true,
            adviceText: t('service.info.invalidFromImport'),
          }}
        />
      </BaseDataViewProvider>
    </BaseDataFilterProvider>
  );
};

export default ViewBaseDataServices;
