import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { NormalizedEntities, requestEntities, RequestEntitiesAction } from '../requestEntities';
import { userSchema } from '../schemas';
import { RootState } from '../store';

import { autoLogin, login } from './authSlice';

export const createUser = (data: UserCreateData): RequestEntitiesAction<UserData> =>
  requestEntities({
    method: 'POST',
    path: '/users',
    data,
    schema: userSchema,
  });

export const fetchUsers = (params?: any, withCount?: boolean): RequestEntitiesAction<UserData[]> =>
  requestEntities({
    method: 'GET',
    path: '/users',
    schema: [userSchema],
    params: {
      // role should always be populated if current user gets loaed, otherwise permission checks fail
      // since the users endpoint is provided by a strapi plugin there are no default populate options
      populate: {
        role: '*',
        costcenters: '*'
      },
      ...params
    },
    withCount,
    type: 'fetchUsers',
  });

export const fetchUser = (userId: number): RequestEntitiesAction<UserData> =>
  requestEntities({
    method: 'GET',
    path: `/users/${userId}`,
    params: {
      populate: 'role',
    },
    schema: userSchema,
  });

export const updateUser = (userId: number, data: UserUpdateData): RequestEntitiesAction<UserData> =>
  requestEntities({
    method: 'PUT',
    path: `/users/${userId}`,
    params: {
      populate: 'role',
    },
    data,
    schema: userSchema,
  });

export const deleteUser = (userId: number): RequestEntitiesAction<UserData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/users/${userId}`,
    schema: userSchema,
    type: 'deleteUser',
  });
};

export const fetchMe = (params?: any): RequestEntitiesAction<UserData> =>
  requestEntities({
    method: 'GET',
    path: '/users/me',
    schema: userSchema,
    params: {
      populate: 'role',
      ...params
    },
  });

export interface UsersState extends EntityState<UserEntity> {
  fetchUsersStatus: RequestStatus;
}

const usersAdapter = createEntityAdapter<UserEntity>({
  selectId: (user) => user.id,
});

const initialState: UsersState = usersAdapter.getInitialState({
  fetchUsersStatus: 'idle',
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchUsers') {
          state.fetchUsersStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.meta.arg.type === 'fetchUsers') {
          state.fetchUsersStatus = 'idle';
        }
        if (action.payload.entities?.users) {
          usersAdapter.upsertMany(state, action.payload.entities.users);
        }
        if (action.meta.arg.type === 'deleteUser') {
          usersAdapter.removeOne(state, action.payload.data.id);
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchUsers') {
          state.fetchUsersStatus = 'failed';
        }
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.user) {
          const normalized = normalize<any, NormalizedEntities>(action.payload.user, userSchema);
          if (normalized.entities.users) {
            usersAdapter.upsertMany(state, normalized.entities.users);
          }
        }
      })
      .addCase(autoLogin.fulfilled, (state, action) => {
        if (action.payload.user) {
          const normalized = normalize<any, NormalizedEntities>(action.payload.user, userSchema);
          if (normalized.entities.users) {
            usersAdapter.upsertMany(state, normalized.entities.users);
          }
        }
      });
  },
});

export const selectUsersState = (state: RootState): UsersState => state.entitiesState.users;
export default usersSlice.reducer;
