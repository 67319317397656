import { useMemo } from 'react';
import { orderBy } from 'lodash';

import { entitySelectors } from '../../app/entitySelectors';

export const useQMReport = (
  reportId: number | undefined
): {
  report: ReportEntity | undefined;
  client: ClientEntity | undefined;
  craft: CraftEntity | undefined;
  contacts: ContactEntity[] | undefined;
  reporter: UserEntity | undefined;
  validRoomCount: number;
  reportrooms: ReportroomEntity[];
  reportroomsFinished: ReportroomEntity[];
  reportroomsPending: ReportroomEntity[];
  countReportroomsFinished: number;
  countReportroomsUnfinished: number;
  countReportroomsPending: number;
  countReportroomsNotStarted: number;
  calculatedScorePercentage: number | undefined;
} => {
  const report = entitySelectors.selectById<ReportEntity>('reports', reportId);
  const client = entitySelectors.selectById<ClientEntity>('clients', report?.clientId);
  const craft = entitySelectors.selectById<CraftEntity>('crafts', report?.craftId);
  const schedule = entitySelectors.selectById<ScheduleEntity>('schedules', (client?.scheduleIds || [])[0]);
  const contacts = entitySelectors.selectByIds<ContactEntity>('contacts', report?.contactIds || []);
  const reporter = entitySelectors.selectById<ClientEntity>('users', report?.reporterId);

  const minRoomCount = report?.roomCount || schedule?.roomCount || 1;

  const reportroomsFilter = useMemo(() => ({ reportId: report?.id }), [report?.id]);
  const reportrooms = entitySelectors.selectByFilter<ReportroomEntity>('reportrooms', reportroomsFilter);

  const reportroomsFinished: ReportroomEntity[] = useMemo(() => {
    const result = reportrooms.filter((reportroom) => reportroom.status === 'finished');
    return orderBy(result, [(reportroom: ReportroomEntity) => reportroom.createdAt], ['asc']);
  }, [reportrooms]);

  const reportroomsPending: ReportroomEntity[] = useMemo(() => {
    const result = reportrooms.filter((reportroom) => reportroom.status !== 'finished');
    return orderBy(result, [(reportroom: ReportroomEntity) => reportroom.createdAt], ['asc']);
  }, [reportrooms]);

  const countReportroomsFinished = reportroomsFinished.length;
  const countReportroomsUnfinished = Math.max(minRoomCount - countReportroomsFinished, 0);
  const countReportroomsPending = reportroomsPending.length;
  const countReportroomsNotStarted = Math.max(minRoomCount - countReportroomsFinished - countReportroomsPending, 0);

  /**
   * TODO dev - can be removed later on
   */
  const calculatedScorePercentage = useMemo(() => {
    if (reportroomsFinished.length === 0) {
      return undefined;
    }
    let sumPercentage = 0;
    reportroomsFinished.forEach((reportroom) => {
      sumPercentage += reportroom.scorePercentage || 0;
    });
    return Math.round(sumPercentage / reportroomsFinished.length) || 0;
  }, [reportroomsFinished]);

  return {
    report,
    client,
    craft,
    contacts,
    reporter,
    validRoomCount: minRoomCount,
    reportrooms,
    reportroomsFinished,
    reportroomsPending,
    countReportroomsFinished,
    countReportroomsUnfinished,
    countReportroomsPending,
    countReportroomsNotStarted,
    calculatedScorePercentage,
  };
};
