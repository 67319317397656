import styled from 'styled-components';

import IconDragHorizontal from '../../../assets/icons-v2/IconDragHorizontal';
import { appTheme } from '../../../theme-v2/appTheme';

const StyledBaseDataQMDragHandle = styled.div({
  width: '22px',
  height: '22px',
  cursor: 'grab',
  svg: {
    width: '22px',
    height: '22px',
    verticalAlign: 'top',
    flexShrink: 0,
    color: appTheme.colors.ciBlue1,
  },
});

const BaseDataQMDragHandle = ({ itemRef }) => (
  <StyledBaseDataQMDragHandle ref={itemRef}>{IconDragHorizontal}</StyledBaseDataQMDragHandle>
);

export default BaseDataQMDragHandle;
