import { useSelector } from 'react-redux';
import DateUtil from '../../utils/DateUtil';

export function useCompanyListItem(companyId) {
    const company = useSelector(({ dataState }) => dataState.companies[companyId]);

    const createdAtFormatted = company?.createdAt ? DateUtil.getDateFormatted(company?.createdAt) : '';
    const updatedAtFormatted = company?.updatedAt ? DateUtil.getDateFormatted(company?.updatedAt) : '';

    return {
        company,
        createdAtFormatted,
        updatedAtFormatted,
    };
}
