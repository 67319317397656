import { UiDiv } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { useCraft } from '../../../app/entityHooks/useCraft';
import {
  createCraft,
  deleteCraft,
  updateCraft,
} from '../../../app/slices/craftsSlice';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import IconRefresh from '../../../assets/icons-v2/IconRefresh';
import Button from '../../atoms-v2/Button';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import TextInput from '../../atoms-v2/TextInput';

interface BaseDataQMCraftFormProps {
  craftId?: number | undefined;
  onSubmit?: React.FormEventHandler<HTMLFormElement | HTMLButtonElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

const BaseDataQMCraftForm: React.FC<BaseDataQMCraftFormProps> = ({
  craftId,
  onSubmit,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formMode = craftId ? 'update' : 'create';

  const { craft } = useCraft(craftId);
  const [craftData, setCraftData] = useState<CraftUpdateData>({});

  useEffect(() => {
    setCraftData({
      name: craft?.name,
      disabled: craft ? craft?.disabled : false,
      canDelete: craft ? craft?.canDelete : false,
    });
  }, [craft]);

  const isFormDataValid = () => {
    const { name } = craftData;
    return !!name;
  };

  const handleChangeValue = (key: string, value: any) => {
    const craftDataUpdated: CraftUpdateData = { ...craftData };
    craftDataUpdated[key] = value;
    setCraftData(craftDataUpdated);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (formMode === 'create') {
      await dispatch(createCraft(craftData));
    } else if (craftId) {
      await dispatch(updateCraft(craftId, craftData));
    }
    if (onSubmit) {
      onSubmit(e);
    }
  };

  const handleOnClickDisable: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (craft) {
      if (window.confirm(t('craft.prompts.delete', { name: craft.name }) || undefined)) {
        if (craft) {
          await dispatch(
            updateCraft(craft.id, { ...craftData, disabled: true })
          );
        }
        if (onSubmit) {
          onSubmit(e);
        }
      }
    }
  };

  const handleOnClickDelete: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (craft) {
      if (window.confirm(t('craft.prompts.deleteFinally', { name: craft.name }) || undefined)) {
        if (craft) {
          await dispatch(deleteCraft(craft.id));
        }
        if (onSubmit) {
          onSubmit(e);
        }
      }
    }
  };

  const handleOnClickRestore: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (craft) {
      await dispatch(updateCraft(craft.id, { ...craftData, disabled: false }));
    }
    if (onSubmit) {
      onSubmit(e);
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <UiDiv>
        <FormElementLabel>{t('common.fields.name')} *</FormElementLabel>
        <TextInput value={craftData.name} onChange={(e) => handleChangeValue('name', e.target.value)} />
      </UiDiv>
      <UiDiv display="flex" columnGap="xs" justifyContent="space-between" paddingTop="s">
        <UiDiv display="flex" columnGap="xs">
          <Button type="submit" icon={IconCheck} disabled={!isFormDataValid()}>
            {formMode === 'create' ? 'Erstellen' : 'Übernehmen'}
          </Button>
          <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
            {t('common.actions.cancel')}
          </Button>
        </UiDiv>
        {formMode === 'update' && (
          <>
            {craftData.disabled ? (
              <UiDiv display="flex" columnGap="xs">
                <Button icon={IconRefresh} onClick={handleOnClickRestore}>
                  {t('common.actions.restore')}
                </Button>
                <Button
                  icon={IconDelete}
                  variant="secondary"
                  onClick={handleOnClickDelete}
                  disabled={!craftData.canDelete}
                >
                  {t('common.actions.deleteFinally')}
                </Button>
              </UiDiv>
            ) : (
              <Button icon={IconDelete} onClick={handleOnClickDisable}>
                {t('common.actions.delete')}
              </Button>
            )}
          </>
        )}
      </UiDiv>
    </form>
  );
};

export default BaseDataQMCraftForm;
