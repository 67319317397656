import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { NormalizedEntities, requestEntities, RequestEntitiesAction } from '../requestEntities';
import { costcenterSchema, userSchema } from '../schemas';
import { autoLogin, login } from './authSlice';

export const fetchCostcenters = (params?: any): RequestEntitiesAction<CostcenterData[]> =>
  requestEntities({
    method: 'GET',
    path: '/costcenters',
    schema: [costcenterSchema],
    params,
  });

export const fetchCostcenter = (costcenterId: number, params?: any): RequestEntitiesAction<CostcenterData> =>
  requestEntities({
    method: 'GET',
    path: `/costcenters/${costcenterId}`,
    schema: costcenterSchema,
    params,
  });

const costcentersAdapter = createEntityAdapter<CostcenterEntity>();

export const costcentersSlice = createSlice({
  name: 'costcenters',
  initialState: costcentersAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.payload.entities?.costcenters) {
          costcentersAdapter.upsertMany(state, action.payload.entities.costcenters);
        }
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.user) {
          const normalized = normalize<any, NormalizedEntities>(action.payload.user, userSchema);
          if (normalized.entities.costcenters) {
            costcentersAdapter.upsertMany(state, normalized.entities.costcenters);
          }
        }
      })
      .addCase(autoLogin.fulfilled, (state, action) => {
        if (action.payload.user) {
          const normalized = normalize<any, NormalizedEntities>(action.payload.user, userSchema);
          if (normalized.entities.costcenters) {
            costcentersAdapter.upsertMany(state, normalized.entities.costcenters);
          }
        }
      });
  },
});

export default costcentersSlice.reducer;
