import { useAppDispatch, useAppSelector } from '../app/appHooks';
import { selectClientFilterState, setClientFilterState } from '../app/slices/clientFilterSlice';
import { ClientFilterState } from '../types/clientFilter';
import { useDynamicClientFilter } from './useDynamicClientFilter';

export const useClientFilterSlice = () => {
  const dispatch = useAppDispatch();
  const currentFilterState = useAppSelector(selectClientFilterState);

  const handleSetFilterState = (filter: ClientFilterState) => {
    dispatch(setClientFilterState(filter));
  };
  return useDynamicClientFilter(currentFilterState, handleSetFilterState);
};
