/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './headline-three.scss';

class HeadlineThree extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, children } = this.props;

        return <h3 className="smg-headline-three">{text || children}</h3>;
    }
}

HeadlineThree.propTypes = {
    text: PropTypes.string,
    children: PropTypes.any
};

HeadlineThree.defaultProps = {
    text: '',
    children: null
};

export default HeadlineThree;
