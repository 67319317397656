import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { fileSchema, jobSchema, offerSchema, publicOffersSchema } from '../schemas';

export const createOffer = (data: OfferCreateData): RequestEntitiesAction<OfferData> =>
  requestEntities({
    method: 'POST',
    path: '/offers',
    data,
    schema: offerSchema,
  });

export const fetchOffer = (offerId: number, params?: any): RequestEntitiesAction<OfferData> =>
  requestEntities({
    method: 'GET',
    path: `/offers/${offerId}`,
    schema: offerSchema,
    params,
  });

export const updateOffer = (offerId: number, data: OfferUpdateData): RequestEntitiesAction<OfferData> =>
  requestEntities({
    method: 'PUT',
    path: `/offers/${offerId}`,
    data,
    schema: offerSchema,
  });

export const createOfferPDF = (offerId: number): RequestEntitiesAction<FileData> => {
  return requestEntities({
    method: 'GET',
    path: `/offers/${offerId}/pdf`,
    schema: fileSchema,
  });
};

export const deleteOfferFile = (offerId: number, fileId: number): RequestEntitiesAction<OfferData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/offers/${offerId}/files/${fileId}`,
    schema: jobSchema,
  });
};

export const uploadOfferFiles = (offerId: number, data: FormData): RequestEntitiesAction<FileData[]> => {
  return requestEntities({
    method: 'POST',
    path: `/offers/${offerId}/files`,
    data,
    schema: jobSchema,
  });
};

export const fetchPublicOffers = (offerId: number, hash: string, params?: any): RequestEntitiesAction =>
  requestEntities({
    method: 'GET',
    path: `/publicoffers/${offerId}/${hash}`,
    schema: publicOffersSchema,
    params,
  });

export const acceptPublicOffers = (
  offerId: number | undefined,
  hash: string | undefined,
  data: FormData
): RequestEntitiesAction =>
  requestEntities({
    method: 'PUT',
    path: `/acceptoffers/${offerId}/${hash || '_'}`,
    schema: publicOffersSchema,
    data,
  });

export const rejectPublicOffers = (
  offerId: number | undefined,
  hash: string | null | undefined,
  data: FormData
): RequestEntitiesAction =>
  requestEntities({
    method: 'PUT',
    path: `/rejectoffers/${offerId}/${hash || '_'}`,
    schema: publicOffersSchema,
    data,
  });

const offersAdapter = createEntityAdapter<OfferEntity>();

export const offersSlice = createSlice({
  name: 'offers',
  initialState: offersAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.offers) {
        offersAdapter.upsertMany(state, action.payload.entities.offers);
      }
    });
  },
});

export default offersSlice.reducer;
