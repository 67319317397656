import hash from 'object-hash';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import AppUtil from './AppUtil';
import ClientsUtil from './ClientsUtil';

class ServicesUtil {
    static filterKeys = [
        'clientId',
        'description',
        'dla',
        'va',
        'validFrom',
        'validTo',
        'price',
        'unit',
        'contractItem',
        'contractNumber',
    ];

    /**
     * Returns data object for a service to be used in API requests
     * @param {Object} service the service Object
     * @param {Boolean} onlyUsedFields the service Object
     * @returns {Object} the data
     */
    static getAPIData(service, onlyUsedFields) {
        const { clientId, description, dla, va, validFrom, validTo, price, unit, contractItem, contractNumber } =
            service;

        const data = {
            description,
            dla,
            va,
            valid_from: AppUtil.toDateString(validFrom, true),
            valid_to: AppUtil.toDateString(validTo, true),
            unit,
            price: parseFloat(price),
            client: clientId,
            contract_item: contractItem,
            contract_number: contractNumber,
        };

        if (onlyUsedFields) {
            // eslint-disable-next-line no-restricted-globals
            return _pickBy(data, (value) => value !== undefined && value !== null && (typeof value === 'object' || !isNaN(value)));
        }

        return data;
    }

    /**
     * Creates a hash over properties that have to be unique for a service
     * @param {Object} service the client
     * @returns {String} the hash
     */
    static getServiceHash(service) {
        return hash({
            contractItem: service.contractItem,
            contractNumber: service.contractNumber,
        });
    }

    /**
     * Returns an empty client, initialized with data provided
     * @param {Object} initialData data to initialize
     * @reutrn {Object} client
     */
    static getEmptyObject(initialData = {}) {
        const strippedInitData = _pick(initialData, ServicesUtil.filterKeys);
        return {
            clientId: null,
            description: '',
            dla: '',
            va: '1',
            taxCode: '1',
            locationCode: '',
            validFrom: null,
            validTo: null,
            price: 0,
            baseQuantity: 1,
            unit: 'Pauschal',
            contractItem: null,
            contractNumber: null,
            ...strippedInitData,
        };
    }

    static parseData(serviceData, options = {}) {
        const { additionalData, oldService } = options;

        const {
            id,
            createdAt,
            updatedAt,
            carrier_number,
            client_number,
            contract_item,
            contract_number,
            description,
            tax_code,
            location_code,
            dla,
            price,
            base_quantity,
            unit,
            va,
            valid_from,
            valid_to,
            client,
        } = serviceData;

        const baseService = {
            id: id || null,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            clientNumber: client_number || null,
            carrierNumber: carrier_number || null,
            contractItem: contract_item || null,
            contractNumber: contract_number || null,
            description: description || null,
            dla: dla || null,
            price: price || 0,
            baseQuantity: base_quantity || 1,
            unit: unit || '',
            va: va || '',
            taxCode: tax_code || null,
            locationCode: location_code || null,
            validFrom: (valid_from && new Date(valid_from)) || null,
            validTo: (valid_to && new Date(valid_to)) || null,
            clientId: AppUtil.getEntryId(client),
        };
        if (client && typeof client !== 'string') {
            baseService.client = ClientsUtil.parseData(client);
        }

        let oldProps = {};
        if (oldService) {
            oldProps = AppUtil.getOldProps(baseService, oldService);
        }

        return {
            ...baseService,
            ...oldProps,
            ...additionalData,
        };
    }

    static baseViewPopulate = {
        client: {
            populate: ['company', 'location', 'costcenter_rel']
        },
    };
}

export default ServicesUtil;
