import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { checkitemgrouptypeSchema } from '../schemas';

export const fetchCheckitemgrouptypes = (): RequestEntitiesAction<CheckitemgrouptypeData[]> =>
  requestEntities({
    method: 'GET',
    path: '/checkitemgrouptypes',
    params: {
      sort: 'name',
    },
    schema: [checkitemgrouptypeSchema],
  });

export const createCheckitemgrouptype = (data: CheckitemgrouptypeCreateData): RequestEntitiesAction<CheckitemgrouptypeData> =>
  requestEntities({
    method: 'POST',
    path: '/checkitemgrouptypes',
    data,
    schema: checkitemgrouptypeSchema,
  });

export const updateCheckitemgrouptype = (
  checkitemgrouptypeId: number,
  data: CheckitemgrouptypeUpdateData
): RequestEntitiesAction<CheckitemgrouptypeData> =>
  requestEntities({
    method: 'PUT',
    path: `/checkitemgrouptypes/${checkitemgrouptypeId}`,
    data,
    schema: checkitemgrouptypeSchema,
  });

export const deleteCheckitemgrouptype = (checkitemgrouptypeId: number): RequestEntitiesAction<CheckitemgrouptypeData> =>
  requestEntities({
    method: 'DELETE',
    path: `/checkitemgrouptypes/${checkitemgrouptypeId}`,
    schema: checkitemgrouptypeSchema,
    type: 'deleteCheckitemgrouptype',
  });

const checkitemgrouptypesAdapter = createEntityAdapter<CheckitemgrouptypeEntity>();

export const checkitemgrouptypesSlice = createSlice({
  name: 'checkitemgrouptypes',
  initialState: checkitemgrouptypesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.checkitemgrouptypes) {
        checkitemgrouptypesAdapter.upsertMany(state, action.payload.entities.checkitemgrouptypes);
      }
      if (action.meta.arg.type === 'deleteCheckitemgrouptype') {
        checkitemgrouptypesAdapter.removeOne(state, action.payload.data.id);
      }
    });
  },
});

export default checkitemgrouptypesSlice.reducer;
