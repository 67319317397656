import axios from 'axios';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';
import { getAPIParams } from '../utils/APIParamsUtil';
import CostcenterUtil from '../utils/CostcenterUtil';

export function fetchCostcenter(id, params, dispatchOptions = {}) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_COSTCENTER,
    });

    return axios({
      method: 'get',
      url: `${RestUtil.getStrapiUrl()}/costcenters/${id}`,
      params,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const costcenter = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_COSTCENTER,
          costcenter,
          ...dispatchOptions,
        });
        return costcenter;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_COSTCENTER_ERROR,
        });
        throw error;
      });
  };
}

export function createCostcenter(data) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    const { paramString } = getAPIParams({ populate: CostcenterUtil.baseViewPopulate });

    dispatch({
      type: REDUX_ACTIONS.REQUEST_CREATE_COSTCENTER,
    });

    return axios({
      method: 'post',
      url: `${RestUtil.getStrapiUrl()}/costcenters${paramString}`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const newCostCenters = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_CREATE_COSTCENTER,
          costcenters: newCostCenters,
        });
        return newCostCenters;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_CREATE_COSTCENTER_ERROR,
        });
        throw error;
      });
  };
}

export function updateCostcenter(costcenterId, data) {
  return async (dispatch, getState) => {
    const {
      dataState: { costcenters },
      authState,
    } = getState();

    const oldCostcenter = costcenters ? costcenters[costcenterId] : {};

    const { paramString } = getAPIParams({ populate: CostcenterUtil.baseViewPopulate });

    dispatch({
      type: REDUX_ACTIONS.REQUEST_UPDATE_COSTCENTER,
    });

    return axios({
      method: 'put',
      url: `${RestUtil.getStrapiUrl()}/costcenters/${costcenterId}${paramString}`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const updatedCostcenters = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_UPDATE_COSTCENTER,
          costcenters: updatedCostcenters,
          oldCostcenter,
        });
        return updatedCostcenters;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_UPDATE_COSTCENTER_ERROR,
        });
        throw error;
      });
  };
}

export function deleteCostcenter(costcenterId) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_COSTCENTER,
    });

    return axios({
      method: 'delete',
      url: `${RestUtil.getStrapiUrl()}/costcenters/${costcenterId}`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const costcenter = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_COSTCENTER,
          costcenterId,
          costcenter,
        });
        return [costcenter];
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_COSTCENTER_ERROR,
        });
        throw error;
      });
  };
}

export function deleteAllCostcenters(costcenterIds) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_COSTCENTER,
    });

    return Promise.all(
      costcenterIds.map((costcenterId) => {
        return axios({
          method: 'delete',
          url: `${RestUtil.getStrapiUrl()}/costcenters/${costcenterId}`,
          headers: {
            Authorization: `Bearer ${authState.jwt}`,
          },
        });
      })
    )
      .then((res) => {
        const costcenters = (res || [{}]).map(({ data }) => data);
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_ALL_COSTCENTERS,
          costcenters,
        });
        return costcenters;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_COSTCENTER_ERROR,
        });
        throw error;
      });
  };
}
