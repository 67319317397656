const IconMapMarker = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M50,0C29.69,0,13.16,16.25,13.16,36.22c0,24.78,33,61.16,34.37,62.7a3.36,3.36,0,0,0,4.94,0c1.4-1.54,34.37-37.92,34.37-62.7C86.84,16.25,70.31,0,50,0Zm0,54.44A18.41,18.41,0,0,1,31.46,36.22a18.54,18.54,0,0,1,37.08,0A18.41,18.41,0,0,1,50,54.44Z"
    />
  </svg>
);

export default IconMapMarker;
