import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@webfox-sc/core';
import { COLORS } from '../../theme/theme';

const theme = {
  root: {
    fontFamily: '"radikal", sans-serif',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
    letterSpacing: '0.5px',
    color: COLORS.CI_RED,
    textTransform: 'uppercase',
    padding: '14px 23px',
    background: 'transparent',
    border: `1px solid ${COLORS.CI_RED}`,
    borderRadius: '5px',
    boxSizing: 'border-box',
    transition: 'background-color 0.1s ease',
    span: {
      marginLeft: '10px',
    },
    svg: {
      width: '14px',
      maxWidth: 'none',
      height: '14px',
    },
    '&[data-with-icon]': {
      paddingLeft: '17px',
    },
    '&[data-with-icon-right]': {
      paddingRight: '17px',
    },
    '&[data-with-icon-only]': {
      padding: '17px',
    },
    '&:hover': {
      background: 'rgba(255,255,255,0.1)',
    },
    '&:active': {
      background: 'rgba(255,255,255,0.2)',
    },
    '&[data-active]': {
      background: COLORS.CI_BLUE_3,
    },
  },
};

const ButtonOutline = (props) => {
  return <Button {...props} theme={theme} />;
};

ButtonOutline.propTypes = {
  ...Button.propTypes,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  icon: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ButtonOutline.defaultProps = {
  ...Button.defaultProps,
  label: undefined,
  ariaLabel: undefined,
  icon: undefined,
  active: false,
  disabled: false,
  onClick: () => {},
};

export default ButtonOutline;
