import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { serviceSchema } from '../schemas';

const servicesAdapter = createEntityAdapter<ServiceEntity>();

export const fetchServices = (params?: any, withCount?: boolean): RequestEntitiesAction<ServiceData[]> =>
  requestEntities({
    method: 'GET',
    path: '/services',
    schema: [serviceSchema],
    params,
    withCount,
    type: 'fetchServices',
  });

export const servicesSlice = createSlice({
  name: 'services',
  initialState: servicesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.services) {
        servicesAdapter.upsertMany(state, action.payload.entities.services);
      }
    });
  },
});

export default servicesSlice.reducer;
