/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './headline-one.scss';

class HeadlineOne extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, children } = this.props;

        return <h1 className="smg-headline-one">{text || children}</h1>;
    }
}

HeadlineOne.propTypes = {
    text: PropTypes.string,
    children: PropTypes.any
};

HeadlineOne.defaultProps = {
    text: '',
    children: null
};

export default HeadlineOne;
