import React, { useEffect, useState } from 'react';
import { UiInput, UiInputProps } from '@webfox-sc/core';
import { CSSObject, useTheme } from 'styled-components';

interface TextInputSpecialNumberProps extends Omit<UiInputProps, 'onChange'> {
  variant?: 'outline';
  error?: boolean;
  maximumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  isNegativeNumberAllowed?: boolean;
  formatOutput?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, draftValue: number | undefined) => void;
}

const TextInputSpecialNumber: React.FC<TextInputSpecialNumberProps> = ({
  variant,
  error,
  maximumIntegerDigits,
  minimumFractionDigits,
  maximumFractionDigits,
  isNegativeNumberAllowed,
  formatOutput,
  width,
  value,
  onChange,
  ...props
}) => {
  const theme = useTheme();
  const [draftValue, setDraftValue] = useState<number | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (value !== draftValue) {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits,
        maximumFractionDigits,
      });
      const valueAsNumber = parseFloat(String(value));
      const inputValueNew = isNaN(valueAsNumber) ? '' : formatter.format(valueAsNumber);

      setDraftValue(valueAsNumber);
      setInputValue(inputValueNew);
    }
  }, [value, draftValue, minimumFractionDigits, maximumFractionDigits]);

  const handleOnKeyPress = (e: any) => {
    const valueNew = e.target.value;

    const reKeyIntegers = '0-9';
    const reKeyComma = (maximumFractionDigits || 0) > 0 && valueNew.indexOf(',') === -1 ? ',' : '';
    const reKeyMinus = isNegativeNumberAllowed && valueNew.indexOf('-') === -1 ? '\\-' : '';
    const reKeyPattern = `[${reKeyIntegers}${reKeyMinus}${reKeyComma}]`;

    const reKey = new RegExp(reKeyPattern);
    if (!reKey.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let valueNew = e.target.value;

    const aInputValue = valueNew.split(',');
    if (!!maximumIntegerDigits && aInputValue[0].length > maximumIntegerDigits) {
      valueNew = inputValue;
    }

    let reValue = new RegExp('^(\\d+)(,)(\\d{' + ((maximumFractionDigits || 0) + 1) + '})$');
    if (valueNew.indexOf('-') !== -1) {
      reValue = new RegExp('^(\\-)(\\d+)(,)(\\d{' + ((maximumFractionDigits || 0) + 1) + '})$');
    }
    if (reValue.test(valueNew)) {
      valueNew = inputValue;
    }

    let draftValueNew: number | undefined = undefined;
    if (valueNew && valueNew !== '-') {
      draftValueNew = Number.parseFloat(valueNew.replace(/\./g, '').replace(',', '.'));
    }

    setDraftValue(draftValueNew);
    setInputValue(valueNew);

    if (onChange) {
      onChange(e, draftValueNew);
    }
  };

  const handleOnBlur = () => {
    if (draftValue !== undefined) {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits,
        maximumFractionDigits,
      });
      setInputValue(formatter.format(draftValue));
    }
  };

  let csso: CSSObject = {
    border: `1px solid ${theme.colors.ciBlue1}`,
    background: 'white',
    padding: '13px 10px',
    height: '50px',
    boxSizing: 'border-box',
    transition: 'background-color 0.1s ease',
    ...theme.textVariants.copy,
    color: theme.colors.ciBlue1,
    '&::placeholder': {
      fontStyle: 'italic',
      color: theme.colors.ciBlue1,
      opacity: 0.5,
    },
    '&:hover': {
      background: theme.colors.grey20,
    },
    '&:focus': {
      outline: 0,
      background: 'white',
    },
    '&:disabled': {
      '&:hover': {
        background: 'white',
      },
    },
  };

  if (variant === 'outline') {
    csso = {
      ...csso,
      borderColor: 'white',
      background: 'transparent',
      color: 'white',
      '&::placeholder': {
        fontStyle: 'italic',
        color: 'white',
        opacity: 0.5,
      },
      '&:hover': {
        background: theme.colors.grey20,
      },
      '&:focus': {
        outline: 0,
        background: theme.colors.grey20,
      },
      '&:disabled': {
        color: 'white',
        '&:hover': {
          background: 'transparent',
        },
      },
    };
  }

  if (error) {
    csso = {
      ...csso,
      borderColor: theme.colors.trafficLightRed,
    };
  }

  return (
    <UiInput
      value={inputValue !== undefined && inputValue !== null ? inputValue : ''}
      csso={csso}
      width={width || '100%'}
      onKeyPress={handleOnKeyPress}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...props}
    />
  );
};

export default TextInputSpecialNumber;
