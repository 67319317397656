import { entitySelectors } from '../entitySelectors';

export const useCheckitemgrouptype = (
  checkitemgrouptypeId: number | undefined
): {
  checkitemgrouptype: CheckitemgrouptypeEntity | undefined;
} => {
  const checkitemgrouptype = entitySelectors.selectById<CheckitemgrouptypeEntity>('checkitemgrouptypes', checkitemgrouptypeId);

  return {
    checkitemgrouptype,
  };
};
