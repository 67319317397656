import { UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTheme } from 'styled-components';
import { useAppDispatch } from '../../app/appHooks';
import { pathToQMReport } from '../../app/paths';
import { createReport } from '../../app/slices/reportsSlice';
import IconQuality from '../../assets/icons-v2/IconQuality';
import DateUtil from '../../utils/DateUtil';
import ScheduleUtil from '../../utils/ScheduleUtil';
import Button from '../atoms-v2/Button';
import { useQMSchedule } from '../qm/useQMSchedule';

interface QMScheduleReportTeaserProps {
  scheduleId: number | undefined;
}

const QMScheduleReportTeaser: React.FC<QMScheduleReportTeaserProps> = ({ scheduleId }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { schedule, client, company, location, pendingReport, isDueDateExpired, craft } =
    useQMSchedule(scheduleId);

  const handleOnClickPerform = async () => {
    if (pendingReport) {
      navigate(pathToQMReport(pendingReport.id, `${pathname}${search}`));
    } else {
      if (!!schedule?.id && client?.id) {
        const response = await dispatch(
          createReport({
            schedule: schedule.id,
            client: client.id,
          })
        );
        const reportId = response?.payload?.data?.id;
        if (reportId) {
          navigate(pathToQMReport(reportId, `${pathname}${search}`));
        }
      }
    }
  };

  const actualRoomCount = pendingReport?.reportroomIds?.length || 0;

  return (
    <UiDiv display="flex" flexDirection="column" rowGap="1px">
      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '200px 3fr 1fr 100px' }}
        rowGap="xs"
        columnGap="s"
        background="white"
        padding="15px"
        minHeight="40px"
      >
        <UiDiv>
          <UiText variant="copySmall"></UiText>
          <UiText variant="copySmall">{company?.name}</UiText>
        </UiDiv>
        <UiDiv>
          <UiText variant="copySmall"></UiText>
          <UiText variant="copySmall">{location?.description}</UiText>
        </UiDiv>
        <UiDiv>
          <UiText variant="copySmall"></UiText>
          <UiText variant="copySmall">{craft?.name || t('craft.form.options.default')}</UiText>
        </UiDiv>
      </UiDiv>
      <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 260px' }} rowGap="1px" columnGap="1px">
        <UiDiv
          display="grid"
          gridTemplateColumns={{ mobile: '1fr', tablet: '200px 1fr 1fr' }}
          rowGap="xs"
          columnGap="s"
          background="ciBlue5"
          padding="5px 15px"
        >
          <UiDiv columnGap="xs">
            <UiText variant="copySmall">
              <strong>{t('report.dueDateTeaser')}</strong>
            </UiText>
            <UiText variant="copySmall" fontWeight={isDueDateExpired ? 'bold' : 'inherit'} color={isDueDateExpired ? colors.trafficLightRed : colors.ciBlue1}>
              {ScheduleUtil.getScheduleDateFormatted(
                schedule?.dueDate ? new Date(schedule.dueDate) : undefined,
                schedule?.interval,
                t
              )}
            </UiText>
          </UiDiv>
          {pendingReport ? (
            <>
              <UiDiv columnGap="xs">
                <UiText variant="copySmall">
                  <strong>{t('report.date')}</strong>
                </UiText>
                <UiText variant="copySmall">{DateUtil.getDateFormatted(pendingReport.date)}</UiText>
              </UiDiv>
              <UiDiv columnGap="xs">
                <UiText variant="copySmall">
                  <strong>{t('report.form.progress')}</strong>
                </UiText>
                <UiText variant="copySmall">
                  {actualRoomCount}/{Math.max(actualRoomCount, pendingReport.roomCount || 0)}{' '}
                  {t('report.textFragments.room.plural')}
                </UiText>
              </UiDiv>
            </>
          ) : (
            <UiDiv columnGap="xs">
              <UiText variant="copySmall">
                <strong>{t('schedule.frequency')}</strong>
              </UiText>
              <UiText variant="copySmall">
                {ScheduleUtil.getFrequencyIntervalFormatted(schedule?.frequency, schedule?.interval, t)}
              </UiText>
            </UiDiv>
          )}
        </UiDiv>
        <UiDiv>
          <Button
            variant={pendingReport ? 'yellow' : isDueDateExpired ? 'red' : 'highlight'}
            icon={IconQuality}
            onClick={handleOnClickPerform}
            minWidth="260px"
          >
            {pendingReport ? t('report.actions.continue') : t('report.actions.start')}
          </Button>
        </UiDiv>
      </UiDiv>
    </UiDiv>
  );
};

export default QMScheduleReportTeaser;
