import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useUsers = (): {
  users: UserEntity[];
  usersSelectOptions: UserSelectOption[];
} => {
  const users = entitySelectors.selectAll<UserEntity>('users');

  const usersSelectOptions: UserSelectOption[] = useMemo(() => {
    return users.map((user) => {
      return {
        value: user.id,
        label: `${user.username} (${user.roleName})`,
      };
    });
  }, [users]);

  return {
    users,
    usersSelectOptions,
  };
};
