import { useMemo } from 'react';
import { USE_GLOBAL_SERVICES } from '../../app.config';

import { useClient } from '../../app/entityHooks/useClient';
import { entitySelectors } from '../../app/entitySelectors';

export const useJobServiceSelect = (
  clientId: number | undefined
): {
  client: ClientEntity | undefined;
  services: ServiceEntity[];
  groupServices: ServiceEntity[];
  activeServices: ServiceEntity[];
} => {
  const { client, clientGroup } = useClient(clientId);

  const services = entitySelectors.selectByIds<ServiceEntity>('services', client?.serviceIds);
  const groupServices = entitySelectors.selectByIds<ServiceEntity>('services', clientGroup?.serviceIds);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const globalServices = USE_GLOBAL_SERVICES
    ? entitySelectors.selectByFilter<ServiceEntity>('services', { clientId: undefined })
    : [];

  const activeServices = useMemo(() => {
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);

    const result: ServiceEntity[] = [];

    [...services, ...groupServices, ...globalServices].forEach((service) => {
      if (
        (!service.validFrom || new Date(service.validFrom) <= now) &&
        (!service.validTo || new Date(service.validTo) >= now)
      ) {
        result.push(service);
      }
    });

    return result;
  }, [services, groupServices, globalServices]);

  return {
    client,
    services,
    groupServices,
    activeServices,
  };
};
