import React from 'react';
import { useContentContext } from '../../components/content/ContentContext';
import SectionView from '../../components/content/SectionView';
import AuthOverlay from '../../components/elements/AuthOverlay';

type ViewMaintenanceProps = {
  maintenanceIndex: number;
};
const ViewMaintenance: React.FC<ViewMaintenanceProps> = ({ maintenanceIndex }) => {
  const { maintenance } = useContentContext();
  const currentMaintenance: ContentSection[] =
    maintenance?.length > maintenanceIndex
      ? maintenance[maintenanceIndex]
      : [
          {
            h3: 'Technische Störung',
            text: ['Es liegt eine technische Störung vor. Die Benutzung  der Applikation ist daher nicht möglich.'],
          },
        ];

  return (
    <AuthOverlay>
      <SectionView content={currentMaintenance} paddingTop="S" paddingBottom="S" />
    </AuthOverlay>
  );
};

export default ViewMaintenance;
