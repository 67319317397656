/* eslint-disable */
import React, { Fragment } from 'react';
import AppUtil from '../../utils/AppUtil';
import UserUtil from '../../utils/UserUtil';
import history from '../../utils/history';
import AuthOverlay from '../../components/elements/AuthOverlay';
import Section from '../../atoms/Section';
import HeadlineThree from '../../components/elements/HeadlineThree';
import CopyTextM from '../../components/elements/CopyTextM';
import CopyTextS from '../../components/elements/CopyTextS';
import BasicInput from '../../components/elements/BasicInput';
import ToggleBox from '../../components/elements/ToggleBox';
import Advice from '../../components/elements/Advice';
import ResetPageLoginButton from './ResetPageLoginButton';
import ButtonSecondary from '../../atoms/ButtonSecondary/ButtonSecondary';

class ResetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        code: '',
        password: '',
        passwordConfirmation: '',
      },
      isSuccess: false,
      isError: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    const { search } = history.location;
    const code = search.substring(6, search.length);
    console.log(code);

    this.setState({
      value: {
        code: code,
      },
    });
  }

  handleFocusInput() {
    this.setState({
      isError: false,
      errorMessage: '',
    });
  }

  handleChange(name, value) {
    this.setState({
      value: {
        ...this.state.value,
        [name]: value,
      },
    });
  }

  async handleSubmit(e) {
    e && e.preventDefault();
    const { value } = this.state;
    const { code, password, passwordConfirmation } = value;

    if (!password || !passwordConfirmation) {
      this.setState({
        isError: true,
        errorMessage: 'Bitte geben Sie ein Passwort ein.',
      });
      return false;
    }

    if (password !== passwordConfirmation) {
      this.setState({
        isError: true,
        errorMessage: 'Die Passwörter stimmen nicht überein.',
      });
      return false;
    }

    if (!AppUtil.isPasswordValid(password)) {
      this.setState({
        isError: true,
        errorMessage: 'Das neue Passwort ist ungültig.<br />Bitte korrigieren Sie die Schreibweise.',
      });
      return false;
    }

    try {
      await UserUtil.sendResetPassword(code, password, passwordConfirmation);
      this.setState({
        isSuccess: true,
      });
    } catch (error) {
      this.setState({
        isError: true,
        errorMessage: 'Leider ist ein unerwarteter Fehler passiert.',
      });
    }
  }

  render() {
    const { isSuccess, isError, errorMessage } = this.state;

    return (
      <AuthOverlay>
        <HeadlineThree text="GSM – Passwort erneuern" />
        {isSuccess && (
          <Fragment>
            <Section paddingTop="S">
              <CopyTextM text="Sie haben erfolgreich Ihr Passwort erneuert." />
            </Section>
            <Section paddingTop="S">
              <ResetPageLoginButton />
            </Section>
          </Fragment>
        )}
        {!isSuccess && (
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <Section paddingTop="S">
              <CopyTextS
                text="Das Passwort muss mindestens 8 Zeichen lang sein und 3 von 4 Kriterien erfüllen:<br />
                                    - mind. 1 Kleinbuchstabe (a–z)<br />
                                    - mind. 1 Großbuchstabe (A–Z)<br />
                                    - mind. 1 Ziffer (0–9)<br />
                                    - mind. 1 der folgenden Sonderzeichen: #?!@$%^&*\-_"
              />
            </Section>
            <Section paddingTop="S">
              <CopyTextM text="Passwort *" />
            </Section>
            <Section paddingTop="XS">
              <BasicInput
                layout="white"
                type="password"
                name="password"
                isError={this.state.isError}
                onFocus={() => this.handleFocusInput()}
                onChange={(value) => this.handleChange('password', value)}
              />
            </Section>
            <Section paddingTop="XS">
              <CopyTextM text="Passwort wiederholen *" />
            </Section>
            <Section paddingTop="XS">
              <BasicInput
                layout="white"
                type="password"
                name="passwordConfirmation"
                isError={this.state.isError}
                onFocus={() => this.handleFocusInput()}
                onChange={(value) => this.handleChange('passwordConfirmation', value)}
              />
            </Section>
            <Section paddingTop="S">
              <ButtonSecondary label="Absenden" type="submit" />
            </Section>
            <ToggleBox show={isError}>
              <Section paddingTop="S">
                <Advice text={errorMessage} isError={true} />
              </Section>
            </ToggleBox>
          </form>
        )}
      </AuthOverlay>
    );
  }
}

export default ResetPage;
