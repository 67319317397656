/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';
import AppUtil from '../utils/AppUtil';

function usersReducer(
    state = {
        userModels: [],
        isLoadingUsers: false,
    },
    action,
) {
    switch (action.type) {
        case REDUX_ACTIONS.UPDATE_USER_MODELS:
            return Object.assign({}, state, {
                userModels:  AppUtil.sortDataObjects(action.userModels, 'username'),
            });

        case REDUX_ACTIONS.UPDATE_IS_LOADING_USERS:
            return Object.assign({}, state, {
                isLoadingUsers: action.isLoadingUsers,
            });

        default:
            return state;
    }
}

export default usersReducer;
