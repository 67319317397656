const IconRatingNone = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M87.4,50c0,20.99-17.01,38-38,38-7.5,0-14.5-2.18-20.39-5.93L78.35,25.38c5.65,6.63,9.06,15.23,9.06,24.62ZM20.03,74.11L69.23,17.58c-5.77-3.54-12.56-5.58-19.83-5.58C28.42,12,11.4,29.01,11.4,50c0,9.15,3.24,17.55,8.63,24.11Zm79.37-24.11c0,27.61-22.39,50-50,50S-.6,77.61-.6,50,21.79,0,49.4,0s50,22.39,50,50Z"
    />
  </svg>
);

export default IconRatingNone;
