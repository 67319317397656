import { useMemo } from 'react';
import { useClient } from '../../app/entityHooks/useClient';
import { useSchedule } from '../../app/entityHooks/useSchedule';
import { entitySelectors } from '../../app/entitySelectors';

export const useQMSchedule = (
  scheduleId: number | undefined
): {
  schedule: ScheduleEntity | undefined;
  client: ClientEntity | undefined;
  craft: CraftEntity | undefined;
  company: CompanyEntity | undefined;
  location: LocationEntity | undefined;
  costcenter: CostcenterEntity | undefined;
  reports: ReportEntity[];
  pendingReport: ReportEntity | undefined;
  isDueDateExpired: boolean;
} => {
  const { schedule, reports } = useSchedule(scheduleId);
  const { client, company, location, costcenter } = useClient(schedule?.clientId);
  const craft = entitySelectors.selectById<CraftEntity>('crafts', schedule?.craftId);

  const pendingReport = useMemo(() => {
    return reports.find((report) => report.status === 'pending');
  }, [reports]);

  const dueDate = schedule?.dueDate ? new Date(schedule.dueDate) : undefined;
  const isDueDateExpired = !!dueDate && dueDate < new Date();

  return {
    schedule,
    client,
    craft,
    company,
    location,
    costcenter,
    reports,
    pendingReport,
    isDueDateExpired,
  };
};
