/* xeslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './table-header-row.scss';

class TableHeaderRow extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { children } = this.props;

        return <div className="smg-table-header-row">{children}</div>;
    }
}

TableHeaderRow.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TableHeaderRow.defaultProps = {
    children: null,
};

export default TableHeaderRow;
