import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, UiDiv, UiText } from '@webfox-sc/core';

import { useAppDispatch, useAppSelector } from '../../../app/appHooks';
import { fetchRoles } from '../../../app/slices/rolesSlice';
import { fetchUsers, selectUsersState } from '../../../app/slices/usersSlice';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconNotFound from '../../../assets/icons-v2/IconNotFound';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import IconSearch from '../../../assets/icons-v2/IconSearch';
import Button from '../../atoms-v2/Button';
import DataTableColumnHeader from '../../atoms-v2/DataTableColumnHeader';
import LoadingWrapper from '../../atoms-v2/LoadingWrapper';
import NoResultsNotice from '../../atoms-v2/NoResultsNotice';
import TextInput from '../../atoms-v2/TextInput';

import BaseDataUser from './BaseDataUser';
import BaseDataUserForm from './BaseDataUserForm';

const limit = 30;

const gridTemplateColumns = '25% 1fr 20% 10% 22px';
const gridColumnGap = 's';

const BaseDataUsers: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchUsersStatus } = useAppSelector(selectUsersState);

  const [fetchStart, setFetchStart] = useState(0);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>('asc');
  const [sortingKey, setSortingKey] = useState('username');
  const [countUsers, setCountUsers] = useState<number>(0);
  const [userIds, setUserIds] = useState<number[]>([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [activeUserId, setActiveUserId] = useState<number | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  useEffect(() => {
    setFetchStart(0);
  }, [searchText]);

  const fetchData = useCallback(async () => {
    const params: AppParams = {
      _start: fetchStart,
      _limit: limit,
      _sort: `${sortingKey}:${sortingOrder}`,
    };

    if (searchText) {
      params.filters = {
        $or: [
          {
            username: {
              $containsi: searchText,
            },
          },
          {
            email: {
              $containsi: searchText,
            },
          },
        ],
      };
    }

    const res = await dispatch(fetchUsers(params));

    setCountUsers(res?.payload?.data?.length || 0);

    const ids = (res?.payload?.data || []).map((entry) => entry.id); // 74
    setUserIds((prevUserIds) => {
      return fetchStart === 0 ? ids : [...prevUserIds, ...ids];
    });
  }, [dispatch, fetchStart, searchText, sortingKey, sortingOrder]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (showCreateForm) {
      setActiveUserId(undefined);
    }
  }, [showCreateForm]);

  useEffect(() => {
    if (activeUserId) {
      setShowCreateForm(false);
    }
  }, [activeUserId]);

  const handleOnSubmitSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
  };

  const handleOnClickColumnHeader = (key: string) => {
    setFetchStart(0);
    if (key === sortingKey) {
      setSortingOrder(sortingOrder === 'desc' ? 'asc' : 'desc');
    } else {
      setSortingKey(key);
      setSortingOrder('asc');
    }
  };

  const handleOnUserCreated = async () => {
    await fetchData();
    setShowCreateForm(false);
  };

  return (
    <>
      <UiDiv>
        <Button icon={IconPlus} onClick={() => setShowCreateForm(!showCreateForm)}>
          {t('user.add.headline')}
        </Button>
        <Collapse show={showCreateForm} fadeInOut keepChildren={false}>
          <UiDiv paddingTop="xs">
            <UiDiv padding="s" background="grey20">
              <BaseDataUserForm onSubmit={handleOnUserCreated} onClickCancel={() => setShowCreateForm(false)} />
            </UiDiv>
          </UiDiv>
        </Collapse>
      </UiDiv>

      <UiDiv marginTop="l">
        <form onSubmit={handleOnSubmitSearch}>
          <UiDiv display="flex" gap="xxs">
            <UiDiv flexGrow={1} display="flex" gap="xxs">
              <UiDiv flexGrow={1}>
                <TextInput
                  variant="outline"
                  endIcon={IconSearch}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                />
              </UiDiv>
              {searchText && (
                <UiDiv>
                  <Button
                    variant="secondaryRed"
                    aria-label="Suche zurücksetzen"
                    icon={IconClose}
                    onClick={() => setSearchText(undefined)}
                  />
                </UiDiv>
              )}
            </UiDiv>
          </UiDiv>
        </form>
      </UiDiv>

      <LoadingWrapper isLoading={fetchUsersStatus === 'loading' && userIds.length === 0} marginTop="m">
        {userIds.length === 0 ? (
          <NoResultsNotice icon={IconNotFound}>{t('user.messages.noFound')}</NoResultsNotice>
        ) : (
          <>
            <UiText variant="copySmall" color="white" textAlign="center">
              {userIds.length} / {countUsers} {t('user.headline.plural')}
            </UiText>
            <UiDiv display="flex" flexDirection="column" rowGap="xs" marginTop="m">
              <UiDiv
                display="grid"
                gridTemplateColumns={gridTemplateColumns}
                columnGap={gridColumnGap}
                padding="0 15px"
              >
                <DataTableColumnHeader
                  sortable
                  sortingOrder={sortingKey === 'username' ? sortingOrder : undefined}
                  onClick={() => handleOnClickColumnHeader('username')}
                >
                  {t('user.username')}
                </DataTableColumnHeader>
                <DataTableColumnHeader
                  sortable
                  sortingOrder={sortingKey === 'email' ? sortingOrder : undefined}
                  onClick={() => handleOnClickColumnHeader('email')}
                >
                  {t('user.email')}
                </DataTableColumnHeader>
                <DataTableColumnHeader>{t('user.role')}</DataTableColumnHeader>
                <DataTableColumnHeader
                  sortable
                  sortingOrder={sortingKey === 'blocked' ? sortingOrder : undefined}
                  onClick={() => handleOnClickColumnHeader('blocked')}
                >
                  {t('user.active')}
                </DataTableColumnHeader>
              </UiDiv>
              {userIds.map((userId) => {
                return (
                  <BaseDataUser
                    key={userId}
                    userId={userId}
                    gridTemplateColumns={gridTemplateColumns}
                    gridColumnGap={gridColumnGap}
                    showContent={userId === activeUserId}
                    onClickToggle={() => setActiveUserId(activeUserId === userId ? undefined : userId)}
                    onSubmit={() => setActiveUserId(undefined)}
                    onClickCancel={() => setActiveUserId(undefined)}
                  />
                );
              })}
              {countUsers > userIds.length && (
                <UiDiv display="flex" justifyContent="center">
                  <Button onClick={() => setFetchStart(fetchStart + limit)}>{t('common.actions.showMore')}</Button>
                </UiDiv>
              )}
            </UiDiv>
          </>
        )}
      </LoadingWrapper>
    </>
  );
};

export default BaseDataUsers;
