import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv } from '@webfox-sc/core';

import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { limitInterval, selectJobListState, setJobListState } from '../../app/slices/jobListSlice';
import IconClose from '../../assets/icons-v2/IconClose';
import IconSearch from '../../assets/icons-v2/IconSearch';
import Button from '../atoms-v2/Button';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import TextInput from '../atoms-v2/TextInput';
import CustomReactSelect from '../CustomReactSelect';

import { useJobsFilters } from './useJobsFilters';

const JobsFilters: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showCostcenterFilter, costcenterOptions, statusOptions } = useJobsFilters();
  const { selectedCostcenterNumber, selectedCompanyName, selectedStatus, searchValue } =
    useAppSelector(selectJobListState);

  const [searchInputValue, setSearchInputValue] = useState('');

  useEffect(() => {
    setSearchInputValue(searchValue || '');
  }, [searchValue]);

  const handleSelectCostcenterNumber = (costcenterNumber: string | undefined) => {
    dispatch(
      setJobListState({
        selectedCostcenterNumber: costcenterNumber || '',
        selectedCompanyName: '',
        searchValue: '',
        limit: limitInterval,
      })
    );
    setSearchInputValue('');
  };

  const handleSelectCompanyName = (companyName: string) => {
    dispatch(
      setJobListState({
        selectedCompanyName: companyName || '',
        selectedCostcenterNumber: '',
        searchValue: '',
        limit: limitInterval,
      })
    );
    setSearchInputValue('');
  };

  const handleSelectStatus = (status: '' | JobStatus | JobOfferStatus) => {
    dispatch(
      setJobListState({
        selectedStatus: status,
        searchValue: '',
        limit: limitInterval,
      })
    );
    setSearchInputValue('');
  };

  const handleOnSubmitSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    dispatch(
      setJobListState({
        searchValue: searchInputValue,
        limit: limitInterval,
      })
    );
  };

  return (
    <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" rowGap="xs" columnGap="xxs">
      {showCostcenterFilter && (
        <UiDiv gridColumn={{ mobile: '1 / 13', tablet: '1 / 7' }}>
          <FormElementLabel color="ciBlue4">{t('common.fields.costcenterNumber.long')}</FormElementLabel>
          <CustomReactSelect
            layout="outline"
            options={costcenterOptions}
            selectedValue={selectedCostcenterNumber}
            isClearable={!!selectedCostcenterNumber}
            onChangeValue={(costcenterNumber) => handleSelectCostcenterNumber(costcenterNumber)}
          />
        </UiDiv>
      )}
      <UiDiv gridColumn={{ mobile: '1 / 13', tablet: '1 / 7' }}>
        <FormElementLabel color="ciBlue4">{t('common.fields.companyName')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          leadingOptions={[{ value: '', label: t('common.form.all') }]}
          selectedOption={{ value: selectedCompanyName, label: selectedCompanyName || t('common.form.all') }}
          asyncApiPath="/companies"
          asyncLimit={500}
          asyncSort="name:asc,number:asc"
          asyncSetParams={(inputValue) =>
            inputValue
              ? {
                  filters: [
                    {
                      name: { $containsi: inputValue },
                    },
                  ],
                }
              : {}
          }
          asyncFormatOption={(entry) => ({
            value: entry?.name,
            label: entry?.name,
          })}
          asyncDefaultOptions
          asyncInputMinLength={3}
          asyncUniqueOptionLabels
          isClearable={!!selectedCompanyName}
          isSearchable
          onChangeValue={handleSelectCompanyName}
        />
      </UiDiv>
      <UiDiv gridColumn={{ mobile: '1 / 13', tablet: '7 / 13' }}>
        <FormElementLabel color="ciBlue4">{t('common.fields.status')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          options={statusOptions}
          selectedValue={selectedStatus || ''}
          isClearable={!!selectedStatus}
          onChangeValue={handleSelectStatus}
        />
      </UiDiv>
      <UiDiv gridColumn="1 / 13">
        <form onSubmit={handleOnSubmitSearch}>
          <FormElementLabel color="ciBlue4">{t('common.form.search')}</FormElementLabel>
          <UiDiv display="flex" gap="xxs">
            <UiDiv flexGrow={1} display="flex" gap="xxs">
              <UiDiv flexGrow={1}>
                <TextInput
                  variant="outline"
                  value={searchInputValue}
                  onChange={(event) => {
                    setSearchInputValue(event.target.value);
                  }}
                />
              </UiDiv>
              {searchInputValue && (
                <UiDiv>
                  <Button
                    variant="secondaryRed"
                    aria-label={t('common.actions.resetSearch') || undefined}
                    icon={IconClose}
                    onClick={() => {
                      dispatch(
                        setJobListState({
                          searchValue: '',
                        })
                      );
                      setSearchInputValue('');
                    }}
                  />
                </UiDiv>
              )}
            </UiDiv>
            <UiDiv display={{ mobile: 'block', tablet: 'none' }}>
              <Button variant="secondaryRed" type="submit" icon={IconSearch} />
            </UiDiv>
            <UiDiv display={{ mobile: 'none', tablet: 'block' }}>
              <Button variant="secondaryRed" type="submit" icon={IconSearch}>
                {t('common.actions.search')}
              </Button>
            </UiDiv>
          </UiDiv>
        </form>
      </UiDiv>
    </UiDiv>
  );
};

export default JobsFilters;
