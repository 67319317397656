const IconArrowLeft = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M19.34,54.35a5.62,5.62,0,0,1,0-8.7L73.54,1.28a5.62,5.62,0,0,1,9.18,4.35V94.37a5.62,5.62,0,0,1-9.18,4.35Z"
    />
  </svg>
);

export default IconArrowLeft;
