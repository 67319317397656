import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BaseDataFilteredList from '../../components/base-data/shared/BaseDataFilteredList';
import { usePaginatedList } from '../../components/base-data/shared/usePaginatedList';
import CompanyEditor from '../../components/CompanyEditor';
import TableHeaderCell from '../../components/elements/TableHeaderCell';
import TableHeaderRow from '../../components/elements/TableHeaderRow';
import IsEmptyFilter from '../../components/IsEmptyFilter';
import SearchFilter from '../../components/SearchFilter';
import { BaseDataFilterProvider } from '../../hooks/baseDataFilter';
import { BaseDataViewProvider } from '../../hooks/baseDataView';
import { useCompaniesFilter } from '../../hooks/useCompaniesFilter';
import { useCompanyActions } from '../../hooks/useCompanyActions';
import { deleteAllCompanies } from '../../redux-actions/actionsCompanies';
import CompaniesUtil from '../../utils/CompaniesUtil';
import CompanyListItem from './CompanyListItem';

const ViewBaseDataCompanies = () => {
  const { t } = useTranslation();
  const { isDataFilter, initialTableFilter } = useCompaniesFilter();
  const { fetchCompaniesLegacy } = useCompanyActions();

  const dataLoaderProps = {
    objectPath: 'companies',
    fetchObjects: (params) => fetchCompaniesLegacy({ ...params, populate: { address: '*' } }),
  };

  const dispatch = useDispatch();

  return (
    <BaseDataFilterProvider isDataFilter={isDataFilter} initialTableFilter={initialTableFilter}>
      <BaseDataViewProvider
        objectPath="companies"
        getNewObject={CompaniesUtil.getEmptyObject}
        useFilter={useCompaniesFilter}
        deleteObjects={(ids) => dispatch(deleteAllCompanies(ids))}
        dataLoader={usePaginatedList}
        dataLoaderProps={dataLoaderProps}
      >
        <BaseDataFilteredList
          labelObjects={t('common.entities.company.plural')}
          labelNew={t('company.actions.new')}
          toolbar={
            <>
              <IsEmptyFilter label={t('company.form.showWithoutClients')} filterKey="clientIds" />
              <SearchFilter />
            </>
          }
          Editor={CompanyEditor}
          TableHeader={({ handleSort, sortingKey, sortingOrder }) => (
            <TableHeaderRow>
              <TableHeaderCell
                text={t('common.fields.number')}
                width="13%"
                isSorting
                onClick={() => handleSort('number')}
                sortingOrder={sortingKey === 'number' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('company.name')}
                width="auto"
                isSorting
                onClick={() => handleSort('name')}
                sortingOrder={sortingKey === 'name' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.createdAt')}
                width="13%"
                isSorting
                onClick={() => handleSort('createdAt')}
                sortingOrder={sortingKey === 'createdAt' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.updatedAt')}
                width="27%"
                isSorting
                onClick={() => handleSort('updatedAt')}
                sortingOrder={sortingKey === 'updatedAt' ? sortingOrder : ''}
              />
            </TableHeaderRow>
          )}
          TableRow={CompanyListItem}
          newEditorPresets={{
            showAdvice: true,
            isError: true,
          }}
        />
      </BaseDataViewProvider>
    </BaseDataFilterProvider>
  );
};

export default ViewBaseDataCompanies;
