class UserModel {
  constructor(data) {
    this._data = data || {};
    this._id = this._data.id || '';
    this._username = this._data.username || '';
    this._email = this._data.email || '';
    this._phone = this._data.tel || '';
    this._costCenter = this._data.costcenter || '';
    this._isConfirmed = this._data.confirmed || false;
    this._isBlocked = this._data.blocked || false;
    this._role = this._data.role || {};
    this._roleId = this._role._id || '';
    this._roleName = this._role.name || '';

    this._costCenters = this.data.costcenters || [];
    this._costCenterNumbers = this._costCenters.map((costCenterObject) => costCenterObject.number);
  }

  get data() {
    return this._data;
  }

  get id() {
    return this._id;
  }

  get username() {
    return this._username;
  }

  get email() {
    return this._email;
  }

  get phone() {
    return this._phone;
  }

  get costCenter() {
    return this._costCenter;
  }

  get costCenters() {
    return this._costCenters;
  }

  set costCenters(_costCenters) {
    this._costCenters = _costCenters;
  }

  get costCenterNumbers() {
    return this._costCenterNumbers;
  }

  addCostCenter(costCenter) {
    // let tempId = 0;

    // const costCenterModel = createCostCenterModel();
    // jobServiceModel.tempId = tempId;
    // costCenterModel.isInCreation = true;
    this._costCenters.push(costCenter);
  }

  removeCostCenter(index) {
    if (this._costCenters && this._costCenters.length) {
      this._costCenters.splice(index, 1);
    }
  }

  get isConfirmed() {
    return this._isConfirmed;
  }

  get isBlocked() {
    return this._isBlocked;
  }

  get role() {
    return this._role;
  }

  get roleId() {
    return this._roleId;
  }

  get roleName() {
    return this._roleName;
  }
}

export default UserModel;
