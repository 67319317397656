import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../../assets/icons-v2/IconArrowUp';
import IconButton from '../../atoms-v2/IconButton';

import { useTheme } from 'styled-components';
import { useCraft } from '../../../app/entityHooks/useCraft';
import BaseDataQMCraftForm from './BaseDataQMCraftForm';

interface BaseDataQMCraftProps {
  craftId: number | undefined;
}

const BaseDataQMCraft: React.FC<BaseDataQMCraftProps> = ({ craftId }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { craft } = useCraft(craftId);

  const [showContent, setShowContent] = useState(false);

  if (!craft) {
    return null;
  }

  return (
    <UiDiv>
      <UiDiv padding="15px" background="white" color="ciBlue1" cursor="pointer" onClick={() => setShowContent(!showContent)}>
        <UiDiv display="grid" gridTemplateColumns="1fr 22px" columnGap="s" alignItems="center">
          <UiText variant="copy" color={craft.disabled ? colors.grey20 : 'default'}>
            <b>
              {craft.name}
              {craft.disabled && ` (${t('common.fields.deleted')})`}
            </b>
          </UiText>
          <IconButton icon={showContent ? IconArrowUp : IconArrowDown} />
        </UiDiv>
      </UiDiv>
      <Collapse show={showContent} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey20">
            <BaseDataQMCraftForm
              craftId={craft.id}
              onSubmit={() => setShowContent(false)}
              onClickCancel={() => setShowContent(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
    </UiDiv>
  );
};

export default BaseDataQMCraft;
