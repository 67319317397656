import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv } from '@webfox-sc/core';

import { useAppDispatch } from '../../../app/appHooks';
import { useCheckitem } from '../../../app/entityHooks/useCheckitem';
import { createCheckitem, deleteCheckitem, updateCheckitem } from '../../../app/slices/checkitemsSlice';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import Button from '../../atoms-v2/Button';
import Checkbox from '../../atoms-v2/Checkbox';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import TextInput from '../../atoms-v2/TextInput';
import CustomReactSelect from '../../CustomReactSelect';
import { useCheckitemtypeOptions } from '../../../app/entityHooks/useCheckitemtypeOptions';

interface BaseDataQMCheckitemFormProps {
  checkitemId?: number | undefined;
  checkitemgroupId?: number | undefined;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

const BaseDataQMCheckitemForm: React.FC<BaseDataQMCheckitemFormProps> = ({
  checkitemId,
  checkitemgroupId,
  onSubmit,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formMode = checkitemId ? 'update' : 'create';

  const { checkitem, checkitemtype } = useCheckitem(checkitemId);
  const { checkitemtypeOptions } = useCheckitemtypeOptions();
  const [checkitemData, setCheckitemData] = useState<CheckitemUpdateData>({});

  useEffect(() => {
    setCheckitemData({
      question: checkitem?.question,
      weight: checkitem?.weight,
      disabled: checkitem ? checkitem?.disabled : false,
      checkitemtype: checkitem?.checkitemtypeId,
    });
  }, [checkitem]);

  const isFormDataValid = () => {
    const { checkitemtype: dataCheckitemtype, weight } = checkitemData;
    return !!dataCheckitemtype && (weight || 0) > 0 && (weight || 0) < 100;
  };

  const handleChangeValue = (key: string, value: any) => {
    const checkitemDataUpdated: CheckitemUpdateData = { ...checkitemData };
    checkitemDataUpdated[key] = value;
    setCheckitemData(checkitemDataUpdated);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (formMode === 'create') {
      if (checkitemgroupId) {
        const createData: CheckitemCreateData = {
          checkitemgroup: checkitemgroupId,
          ...checkitemData,
        };
        await dispatch(createCheckitem(createData));
      }
    } else if (checkitemId) {
      await dispatch(updateCheckitem(checkitemId, checkitemData));
    }
    if (onSubmit) {
      onSubmit(e);
    }
  };

  const handleOnClickDelete: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (checkitem) {
      if (window.confirm(t('checkitem.prompts.delete', { name: checkitemtype?.name }) || undefined)) {
        dispatch(deleteCheckitem(checkitem.id));
      }
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" rowGap="s" columnGap="xs">
        <UiDiv gridColumn="1 / 10">
          <FormElementLabel>{t('common.fields.name')} *</FormElementLabel>
          <CustomReactSelect
            options={checkitemtypeOptions}
            selectedValue={checkitemData?.checkitemtype}
            isSearchable
            onChangeValue={(value) => handleChangeValue('checkitemtype', value)}
            placeholder={t('common.actions.select')}
          />
        </UiDiv>
        <UiDiv gridColumn="10 / 13">
          <FormElementLabel>{t('checkitem.common.weight')} *</FormElementLabel>
          <TextInput
            type="number"
            value={checkitemData.weight}
            onChange={(e) => handleChangeValue('weight', parseInt(e.target.value, 10) || 0)}
          />
        </UiDiv>
        <UiDiv gridColumn="1 / 10">
          <FormElementLabel>{t('checkitem.common.question')}</FormElementLabel>
          <TextInput value={checkitemData.question} onChange={(e) => handleChangeValue('question', e.target.value)} />
        </UiDiv>
        <UiDiv gridColumn="10 / 13">
          <FormElementLabel>{t('common.qm.preSelected')}</FormElementLabel>
          <Checkbox
            checked={!checkitemData.disabled}
            onChange={(e) => handleChangeValue('disabled', !e.target.checked)}
          />
        </UiDiv>
      </UiDiv>
      <UiDiv display="flex" columnGap="xs" justifyContent="space-between" paddingTop="s">
        <UiDiv display="flex" columnGap="xs">
          <Button type="submit" icon={IconCheck} disabled={!isFormDataValid()}>
            {formMode === 'create' ? t('common.actions.create') : t('common.actions.apply')}
          </Button>
          <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
            {t('common.actions.cancel')}
          </Button>
        </UiDiv>
        {formMode === 'update' && (
          <Button icon={IconDelete} onClick={handleOnClickDelete}>
            {t('common.actions.delete')}
          </Button>
        )}
      </UiDiv>
    </form>
  );
};

export default BaseDataQMCheckitemForm;
