import { entitySelectors } from '../entitySelectors';

export const useOffer = (
  offerId: number | undefined
): {
  offer: OfferEntity | undefined;
  job: JobEntity | undefined;
  recipient: RecipientEntity | undefined;
  files: FileEntity[];
  pdfFile: FileEntity | undefined;
} => {
  const offer = entitySelectors.selectById<OfferEntity>('offers', offerId);
  const job = entitySelectors.selectById<JobEntity>('jobs', offer?.jobId);
  const recipient = entitySelectors.selectById<RecipientEntity>('recipients', offer?.recipientId);
  const files = entitySelectors.selectByIds<FileEntity>('files', offer?.fileIds);
  const pdfFile = entitySelectors.selectById<FileEntity>('files', offer?.pdfFileId);

  return {
    offer,
    job,
    recipient,
    files,
    pdfFile,
  };
};
