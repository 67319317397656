import { intersection, map, pick } from 'lodash';
import AppUtil from '../utils/AppUtil';
import ServicesUtil from '../utils/ServicesUtil';

export const useServicesFilter = () => {
  const isDataFilter = ({ companyClientIds, locationClientIds, costcenterClientIds, clientId }) => {
    return companyClientIds || locationClientIds || costcenterClientIds || clientId;
  };

  const filterObjects = (objects, filter) => {
    const objectFilter = pick(filter || {}, ['status']);
    return AppUtil.filterModels(objects, objectFilter);
  };

  const getAPIFilter = (filter) => {
    const reducedFilter = pick(filter || {}, ServicesUtil.filterKeys);
    // get common clientIds
    const idFilters = [];
    let hasClientFilter = false;
    if (filter.companyClientIds) {
      idFilters.push(filter.companyClientIds);
      hasClientFilter = true;
    }
    if (filter.locationClientIds) {
      idFilters.push(filter.locationClientIds);
      hasClientFilter = true;
    }
    if (filter.costcenterClientIds) {
      idFilters.push(filter.costcenterClientIds);
      hasClientFilter = true;
    }
    if (filter.clientId !== undefined) {
      delete reducedFilter.clientId;
      if (filter.clientId?.$empty) {
        reducedFilter.client = {
          id: {
            $null: true,
          },
        };
      } else if (filter.clientId && typeof filter.clientId !== 'object') {
        idFilters.push([filter.clientId]);
        hasClientFilter = true;
      }
    }

    if (hasClientFilter) {
      const clientIds = intersection(...idFilters);

      if (clientIds && clientIds.length > 0) {
        reducedFilter.client = {
          id: {
            $in: clientIds,
          },
        };
      } else {
        // this causes result to be empty instead of including everything
        // TODO stop request instead
        reducedFilter.client = ' ';
      }
    }
    // no search filter yet

    if (Object.keys(reducedFilter).length > 0) {
      return map(reducedFilter, (value, key) => ({
        [key]: value,
      }));
    }
    return null;
  };

  const initialDataFilter = null;

  const initialTableFilter = {
    status: ['new', 'editedNotNew', 'invalid', 'original'],
  };

  const uniqueKeys = ['contractItem', 'contractNumber'];

  return {
    uniqueKeys,
    getAPIFilter,
    isDataFilter,
    filterObjects,
    initialDataFilter,
    initialTableFilter,
  };
};
