import { UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import IconEdit from '../../../assets/icons-v2/IconEdit';
import Button from '../../atoms-v2/Button';
import IconButton from '../../atoms-v2/IconButton';
import TextInput from '../../atoms-v2/TextInput';

type QMAdminEditLabelProps = {
  label: string;
  subLabel?: string;
  onSave: (value: string) => void;
  onDelete?: () => void | undefined;
};

const QMAdminConfigEditableLabel: React.FC<QMAdminEditLabelProps> = ({ label, subLabel, onSave, onDelete }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(label);
  const [editModeIsActive, setEditModeIsActive] = useState(false);

  const handleOnSave = () => {
    onSave(value);
    setEditModeIsActive(false);
  };

  useEffect(() => {
    setValue(label);
  }, [label]);

  return (
    <UiDiv display="flex" gap="xxs" alignItems="center">
      {editModeIsActive ? (
        <>
          <TextInput
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
          <UiDiv>
            <Button aria-label={t('common.actions.save') || undefined} onClick={handleOnSave} icon={IconCheck} />
          </UiDiv>
          <Button
            icon={IconClose}
            variant="secondary"
            onClick={() => {
              setEditModeIsActive(!editModeIsActive);
            }}
          />
          {onDelete && (
            <UiDiv>
              <Button aria-label={t('common.actions.delete') || undefined} onClick={onDelete} icon={IconDelete} />
            </UiDiv>
          )}
        </>
      ) : (
        <UiDiv display="flex" columnGap="xs">
          <UiText variant="copy">
            <b>{label}</b> {subLabel}
          </UiText>
          <IconButton
            variant="red"
            icon={IconEdit}
            onClick={() => {
              setEditModeIsActive(!editModeIsActive);
            }}
          />
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default QMAdminConfigEditableLabel;
