import React from 'react';
import { UiDiv } from '@webfox-sc/core';
import Text from '../../atoms-v2/Text';
import IconChecklist from '../../../assets/icons-v2/IconChecklist';
import IconCalendar from '../../../assets/icons-v2/IconCalendar';
import TextInput from '../../atoms-v2/TextInput';
import CustomReactSelect from '../../CustomReactSelect';
import CustomDatePicker from '../../atoms-v2/CustomDatePicker';
import { useTranslation } from 'react-i18next';
import { USE_SCHEDULE_INTERVAL } from '../../../app.config';

interface QMAdminClientScheduleFormProps {
  viewMode: 'create' | 'edit';
  formErrors?: FormErrors;
  scheduleDraft: ScheduleEntity;
  onUpdateScheduleDraft: (scheduleDraft: ScheduleEntity) => void;
}

const QMAdminClientScheduleForm: React.FC<QMAdminClientScheduleFormProps> = ({
  viewMode,
  formErrors,
  scheduleDraft,
  onUpdateScheduleDraft,
}) => {
  const { t } = useTranslation();

  const intervalOptions = ['weekly', 'monthly', 'quarterly', 'semiAnnually', 'annually'].map((interval) => ({
    value: interval,
    label: t(`schedule.form.frequency.${interval}`),
  }));

  return (
    <UiDiv padding="s" background="white">
      <Text>
        <strong>{viewMode === 'create' ? t('schedule.headline.new') : t('schedule.headline.edit')}</strong>
      </Text>

      <UiDiv marginTop="m">
        <Text icon={IconChecklist}>
          <strong>{t('schedule.headline.roomCount')}</strong>
        </Text>
      </UiDiv>

      <UiDiv display="flex" columnGap="xs" marginTop="s">
        <UiDiv flex="0 0 110px" paddingTop="15px">
          <Text>{t('schedule.roomCount')}:</Text>
        </UiDiv>
        <UiDiv flexGrow={1}>
          <UiDiv width="70px">
            <TextInput
              type="number"
              value={scheduleDraft.roomCount}
              min="1"
              max="99"
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                onUpdateScheduleDraft({
                  ...scheduleDraft,
                  roomCount: parseInt(e.target.value, 10) || undefined,
                });
              }}
            />
          </UiDiv>
          {formErrors?.roomCount && (
            <UiDiv marginTop="xxs">
              <Text color="trafficLightRed">{formErrors.roomCount}</Text>
            </UiDiv>
          )}
        </UiDiv>
      </UiDiv>

      <UiDiv marginTop="m">
        <Text icon={IconCalendar}>
          <strong>{t('common.entities.schedule.singular')}</strong>
        </Text>
      </UiDiv>

      <UiDiv display="flex" columnGap="xs" marginTop="s">
        <UiDiv flex="0 0 110px" paddingTop="15px">
          <Text>{t('schedule.frequency')}:</Text>
        </UiDiv>
        <UiDiv flexGrow={1}>
          <UiDiv display="flex" columnGap="xs" alignItems="center">
            <UiDiv flex="0 0 70px">
              <TextInput
                type="number"
                value={scheduleDraft.frequency}
                min="1"
                max="99"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  onUpdateScheduleDraft({
                    ...scheduleDraft,
                    frequency: parseInt(e.target.value, 10) || undefined,
                  });
                }}
              />
            </UiDiv>
            <UiDiv>
              <Text>x</Text>
            </UiDiv>
            <UiDiv flexGrow={1}>
              <CustomReactSelect
                options={intervalOptions}
                selectedValue={scheduleDraft.interval}
                onChangeValue={(value) => {
                  onUpdateScheduleDraft({
                    ...scheduleDraft,
                    interval: value,
                  });
                }}
                placeholder={t('common.actions.select')}
              />
            </UiDiv>
          </UiDiv>
          {formErrors?.frequency && (
            <UiDiv marginTop="xxs">
              <Text color="trafficLightRed">{formErrors.frequency}</Text>
            </UiDiv>
          )}
        </UiDiv>
      </UiDiv>

      <UiDiv display="flex" columnGap="xs" marginTop="xs">
        <UiDiv flex="0 0 110px" paddingTop="15px">
          <Text>{t('schedule.start')}:</Text>
        </UiDiv>
        <UiDiv flexGrow={1}>
          <CustomDatePicker
            popperPlacement="top-start"
            selected={scheduleDraft.start}
            onChange={(date) => {
              onUpdateScheduleDraft({
                ...scheduleDraft,
                start: date as Date,
              });
            }}
            interval={USE_SCHEDULE_INTERVAL ? scheduleDraft.interval : undefined}
            dateFormat="dd.MM.yyyy"
          />
          {formErrors?.start && (
            <UiDiv marginTop="xxs">
              <Text color="trafficLightRed">{formErrors.start}</Text>
            </UiDiv>
          )}
        </UiDiv>
      </UiDiv>
    </UiDiv>
  );
};

export default QMAdminClientScheduleForm;
