import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/atoms-v2/Button';

const ResetPageLoginButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Button variant="secondaryRed" onClick={() => navigate('/')}>
      Zum Login
    </Button>
  );
};

export default ResetPageLoginButton;
