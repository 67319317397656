import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, InlineSVG, UiDiv, UiFlex } from '@webfox-sc/core';

import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../../assets/icons-v2/IconArrowUp';
import Text from '../../atoms-v2/Text';

import BaseDataUserForm from './BaseDataUserForm';
import { useBaseDataUser } from './useBaseDataUser';

interface BaseDataUserProps {
  userId: number | undefined;
  gridTemplateColumns: string;
  gridColumnGap: string;
  showContent?: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onClickToggle?: React.MouseEventHandler<HTMLDivElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

const BaseDataUser: React.FC<BaseDataUserProps> = ({
  userId,
  gridTemplateColumns,
  gridColumnGap,
  showContent,
  onClickToggle,
  onSubmit,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const { user, costcentersSorted } = useBaseDataUser(userId);

  if (!user) {
    return null;
  }

  return (
    <UiDiv>
      <UiDiv
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        columnGap={gridColumnGap}
        padding="15px"
        background={{ _: 'white', ':hover': 'grey20' }}
        transition="background-color 0.3s ease"
        cursor="pointer"
        onClick={onClickToggle}
      >
        <Text variant="small">{user.username}</Text>
        <Text variant="small">{user.email}</Text>
        <Text variant="small">{user.roleName}</Text>
        <Text variant="small">{user.blocked ? 'Nein' : 'Ja'}</Text>
        <UiFlex width="22px" height="22px" justifyContent="center" alignItems="center" color="ciBlue4">
          <InlineSVG width="18px" height="18px">
            {showContent ? IconArrowUp : IconArrowDown}
          </InlineSVG>
        </UiFlex>
      </UiDiv>
      <Collapse show={showContent} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey20">
            <Text variant="label">
              <b>{t('costcenter.headline.plural')}</b>
            </Text>
            <UiDiv paddingTop="xs">
              {costcentersSorted.length === 0 ? (
                <Text variant="small">
                  <em>{t('user.messages.costcenterNotAssigned')}</em>
                </Text>
              ) : (
                <UiDiv display="flex" flexWrap="wrap" gap="xxs">
                  {costcentersSorted.map((costcenter) => {
                    return (
                      <UiDiv key={costcenter.id} background="white" padding="5px 10px" borderRadius="5px">
                        <Text variant="small">{costcenter.number}</Text>
                      </UiDiv>
                    );
                  })}
                </UiDiv>
              )}
            </UiDiv>
          </UiDiv>
        </UiDiv>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey20">
            <BaseDataUserForm userId={userId} onSubmit={onSubmit} onClickCancel={onClickCancel} />
          </UiDiv>
        </UiDiv>
      </Collapse>
    </UiDiv>
  );
};

export default BaseDataUser;
