import { UiDiv } from '@webfox-sc/core';
import { isEmpty } from 'lodash';
import React from 'react';
import JobHistoryDiffField from './JobHistoryDiffField';

interface JobHistoryContactDiffProps extends DiffEntryProps{
  email: string;
}

const JobHistoryContactDiff: React.FC<JobHistoryContactDiffProps> = ({ oldDiff, newDiff, email }) => {
  const emailDiff: DiffFieldProps = {};

  if (!isEmpty(oldDiff)) {
    if (!isEmpty(newDiff)) {
      // old and new -> name has changed
      emailDiff.label = email;
    } else {
      // only old -> removed
      emailDiff.oldValue = email;
    }
  } else {
    // no old -> new
      emailDiff.newValue = email;
  }
  
  return (
    <UiDiv display="flex" flexDirection="row" flexWrap="wrap">
      {email && (
        <JobHistoryDiffField {...emailDiff}/>
      )}
      {(oldDiff?.name || newDiff?.name) && (
        <JobHistoryDiffField oldValue={oldDiff?.name} newValue={newDiff?.name} />
      )}
    </UiDiv>
  );
};

export default JobHistoryContactDiff;
