import React from 'react';
import JobListSearch from '../../container/JobList/JobListSearch';
import { useBaseDataFilterContext } from '../../hooks/baseDataFilter';
import { useBaseDataViewContext } from '../../hooks/baseDataView';

const SearchFilter = () => {
    const { dataFilter, handleChangeDataFilter } = useBaseDataFilterContext();
    const { setShowAdd, setActiveId } = useBaseDataViewContext();

    const onInputChange = (value) => {
        setShowAdd(false);
        setActiveId(false);
        handleChangeDataFilter({
            ...dataFilter,
            searchFilter: value,
        });
    };

    return (
        <div className="smg-view-base-data__toolbar">
            <div className="smg-view-base-data__toolbar-filter">
                <JobListSearch value={(dataFilter && dataFilter.searchFilter) || ''} onSubmit={onInputChange} />
            </div>
        </div>
    );
};

export default SearchFilter;
