import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _, { difference } from 'lodash';
import QMUtil from '../../utils/QMUtil';
import { RequestEntitiesAction, requestEntities } from '../requestEntities';
import { clientSchema, companySchema, roomgroupSchema } from '../schemas';

const populateOptions = {
  checkitemgroups: {
    populate: {
      checkitems: {
        populate: {
          checkitemtype: '*',
        },
      },
      checkitemgrouptype: '*',
      company: {
        fields: ['id'],
      },
      client: {
        fields: ['id'],
      },
    },
  },
  craft: {
    fields: ['id'],
  },
};

export const fetchGlobalRoomgroups = (): RequestEntitiesAction<RoomgroupData[]> =>
  requestEntities({
    method: 'GET',
    path: '/roomgroups',
    params: {
      filters: [
        {
          client: {
            id: {
              $null: true,
            },
          },
        },
        {
          company: {
            id: {
              $null: true,
            },
          },
        },
      ],
      populate: populateOptions,
    },
    schema: [roomgroupSchema],
  });

export const updateRoomgroup = (roomgroupId: number, data: RoomgroupUpdateData): RequestEntitiesAction<RoomgroupData> =>
  requestEntities({
    method: 'PUT',
    path: `/roomgroups/${roomgroupId}`,
    params: {
      populate: populateOptions,
    },
    data,
    schema: roomgroupSchema,
  });

export const createRoomgroup = (data: RoomgroupUpdateData): RequestEntitiesAction<RoomgroupData> =>
  requestEntities({
    method: 'POST',
    path: '/roomgroups',
    params: {
      populate: populateOptions,
    },
    data,
    schema: roomgroupSchema,
  });

export const deleteRoomgroup = (roomgroupId: number): RequestEntitiesAction<RoomgroupData> =>
  requestEntities({
    method: 'DELETE',
    path: `/roomgroups/${roomgroupId}`,
    schema: roomgroupSchema,
    type: 'deleteRoomgroup',
  });

export const updateCompanyRoomgroups = (
  companyId: number,
  craftId: number,
  data: RoomgroupUpdateData[]
): RequestEntitiesAction<CompanyData> =>
  requestEntities({
    method: 'PUT',
    path: `/companies/${companyId}/roomgroups/${craftId}`,
    params: {
      populate: QMUtil.companyPopulateOptions,
    },
    data,
    schema: companySchema,
    type: 'updateCompanyRoomgroups',
  });

export const deleteCompanyRoomgroups = (companyId: number, craftId: number): RequestEntitiesAction<CompanyData> =>
  requestEntities({
    method: 'DELETE',
    path: `/companies/${companyId}/roomgroups/${craftId}`,
    params: {
      populate: QMUtil.companyPopulateOptions,
    },
    schema: companySchema,
    type: 'deleteCompanyRoomgroups',
  });

export const updateClientRoomgroups = (
  clientId: number,
  craftId: number,
  data: RoomgroupUpdateData[]
): RequestEntitiesAction<ClientApiData> =>
  requestEntities({
    method: 'PUT',
    path: `/clients/${clientId}/roomgroups/${craftId}`,
    params: {
      populate: QMUtil.clientPopulateOptions,
    },
    data,
    schema: clientSchema,
    type: 'updateClientRoomgroups',
  });

export const deleteClientRoomgroups = (clientId: number, craftId: number): RequestEntitiesAction<ClientApiData> =>
  requestEntities({
    method: 'DELETE',
    path: `/clients/${clientId}/roomgroups/${craftId}`,
    params: {
      populate: QMUtil.clientPopulateOptions,
    },
    schema: clientSchema,
    type: 'deleteClientRoomgroups',
  });

const roomgroupsAdapter = createEntityAdapter<RoomgroupEntity>();

export const roomgroupsSlice = createSlice({
  name: 'roomgroups',
  initialState: roomgroupsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.roomgroups) {
        roomgroupsAdapter.upsertMany(state, action.payload.entities.roomgroups);
      }
      if (action.meta.arg.type === 'deleteRoomgroup') {
        roomgroupsAdapter.removeOne(state, action.payload.data.id);
      }
      if (action.meta.arg.type === 'deleteCompanyRoomgroups') {
        // we get entire company back, remove roomgroups for company that are not included in result from store
        const roomgroups = _.filter(
          state.entities,
          (roomgroup) => !!roomgroup?.id && roomgroup?.companyId === action.payload.data.id
        );
        const currentRoomgroupIds = roomgroups.map((roomgroup) => roomgroup?.id as number);
        const newRoomgroupIds = (action.payload.data?.roomgroups || [])
          .map((roomgroup) => roomgroup?.id || undefined)
          .filter((id) => !!id) as number[];

        const obsoleteRoomgroupIds = difference(currentRoomgroupIds, newRoomgroupIds);
        roomgroupsAdapter.removeMany(state, obsoleteRoomgroupIds);
      }
      if (action.meta.arg.type === 'deleteClientRoomgroups') {
        // we get entire client back, remove roomgroups for client that are not included in result from store
        const roomgroups = _.filter(
          state.entities,
          (roomgroup) => !!roomgroup?.id && roomgroup?.clientId === action.payload.data.id
        );
        const currentRoomgroupIds = roomgroups.map((roomgroup) => roomgroup?.id as number);
        const newRoomgroupIds = (action.payload.data?.roomgroups || [])
          .map((roomgroup) => roomgroup?.id || undefined)
          .filter((id) => !!id) as number[];

        const obsoleteRoomgroupIds = difference(currentRoomgroupIds, newRoomgroupIds);
        roomgroupsAdapter.removeMany(state, obsoleteRoomgroupIds);
      }
    });
  },
});

export default roomgroupsSlice.reducer;
