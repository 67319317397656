import React, { useCallback } from 'react';
import QMAdminContactsForm from './QMAdminContactsForm';
import { useCompany } from '../../../app/entityHooks/useCompany';
import { useAppDispatch } from '../../../app/appHooks';
import { updateCompanyContacts } from '../../../app/slices/companiesSlice';

interface QMAdminCompanyContactsProps {
  companyId: number | undefined;
}

const QMAdminCompanyContacts: React.FC<QMAdminCompanyContactsProps> = ({ companyId }) => {
  const dispatch = useAppDispatch();
  const { company } = useCompany(companyId);

  const handleOnAsyncSave = useCallback(
    async (contactIds: number[]) => {
      if (companyId) {
        await dispatch(
          updateCompanyContacts(
            companyId,
            contactIds,
            {
              populate: {
                contacts: '*',
              },
            }
          )
        );
      }
    },
    [companyId, dispatch]
  );

  if (!company) {
    return null;
  }

  return <QMAdminContactsForm contactIds={company.contactIds} onAsyncSave={handleOnAsyncSave} />;
};

export default React.memo(QMAdminCompanyContacts);
