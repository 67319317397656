import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import iconArrowSrcLight from '../../assets/svg/icon-arrow-down-white.svg';
import iconArrowSrcDark from '../../assets/svg/icon-arrow-down.svg';
import iconCloseLight from '../../assets/svg/icon-close-white.svg';
import iconCloseDark from '../../assets/svg/icon-close.svg';
import { useApi } from '../../hooks/useApi';
import { COLORS, STYLE_TEXT_M } from '../../theme/theme';

const customStyles = ({ layout = undefined }) => {
  return {
    container: (provided, state) => {
      return {
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        background: layout === 'outline' ? 'transparent' : COLORS.WHITE,
      };
    },
    control: (provided) => {
      return {
        ...provided,
        appearance: 'none',
        background: layout === 'outline' ? 'transparent' : COLORS.WHITE,
        boxSizing: 'border-box',
        width: '100%',
        minHeight: '50px',
        padding: 0,
        border: `1px solid ${layout === 'outline' ? COLORS.WHITE : COLORS.CI_BLUE_1}`,
        borderRadius: 0,
        boxShadow: 'none',
        cursor: 'pointer',
        '&:hover': {
          background: layout === 'outline' ? COLORS.CI_BLUE_2 : 'rgba(239, 239, 239, 0.4)',
        },
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        padding: '13px 10px 13px 10px',
      };
    },
    indicatorSeparator: () => {
      return {
        display: 'none',
      };
    },
    clearIndicator: (provided) => {
      return {
        ...provided,
        padding: 0,
        width: '18px',
        height: '18px',
        backgroundColor: 'transparent',
        backgroundImage: `url("${layout === 'outline' ? iconCloseLight : iconCloseDark}")`,
        backgroundSize: '12px auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        svg: {
          display: 'none',
        },
      };
    },
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        padding: 0,
        width: '42px',
        height: '18px',
        backgroundColor: 'transparent',
        backgroundImage: `url("${layout === 'outline' ? iconArrowSrcLight : iconArrowSrcDark}")`,
        backgroundSize: '12px auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        svg: {
          display: 'none',
        },
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        ...STYLE_TEXT_M,
        color: layout === 'outline' ? COLORS.WHITE : COLORS.CI_BLUE_1,
        padding: 0,
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        ...STYLE_TEXT_M,
        color: layout === 'outline' ? COLORS.WHITE : COLORS.CI_BLUE_1,
        padding: 0,
      };
    },
    input: (provided) => {
      return {
        ...provided,
        ...STYLE_TEXT_M,
        color: layout === 'outline' ? COLORS.WHITE : COLORS.CI_BLUE_1,
        padding: 0,
        input: {
          ...STYLE_TEXT_M,
          color: layout === 'outline' ? COLORS.WHITE : COLORS.CI_BLUE_1,
          padding: 0,
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        marginTop: '4px',
        padding: 0,
        borderRadius: 0,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        padding: '0px',
      };
    },
    group: (provided) => {
      return {
        ...provided,
        padding: 0,
      };
    },
    groupHeading: (provided) => {
      return {
        ...provided,
        padding: '14px 10px 7px 10px',
        ...STYLE_TEXT_M,
        color: COLORS.CI_BLUE_3,
        fontWeight: 700,
        textTransform: 'normal',
        borderBottom: `1px solid ${COLORS.GREY_20}`,
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        padding: '7px 10px',
        ...STYLE_TEXT_M,
        color: state.isSelected ? state.isDisabled ? COLORS.GREY_20 : COLORS.WHITE : state.isDisabled ? COLORS.GREY_60 : COLORS.GREY_80,
        fontStyle: state.isDisabled ? 'italic' : 'inherit',
        backgroundColor: state.isSelected ? COLORS.CI_BLUE_2 : COLORS.WHITE,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        '&:hover': {
          backgroundColor: state.isSelected ? COLORS.CI_BLUE_3 : COLORS.GREY_20,
        },
      };
    },
  };
};

const CustomReactSelect = ({
  layout,
  options,
  leadingOptions,
  selectedOption,
  selectedValue,
  defaultValue,
  placeholder,
  isMulti,
  isClearable,
  isSearchable,
  captureMenuScroll,
  noOptionsMessage,
  loadingMessage,
  asyncApiPath,
  asyncPopulate,
  asyncSort,
  asyncLimit,
  asyncSetParams,
  asyncOptionValueKey,
  asyncOptionLabelKey,
  asyncFormatOptionLabel,
  asyncFormatOption,
  asyncFormatOptions,
  asyncDefaultOptions,
  asyncInputMinLength,
  asyncUniqueOptionLabels,
  onChange,
  onChangeValue,
  isDisabled,
  isOptionDisabled,
}) => {
  const { t } = useTranslation();
  const { apiGet } = useApi();
  const [selectedOptionLocal, setSelectedOptionLocal] = useState(selectedOption);
  const [asyncOptionsPersisted, setAsyncOptionsPersisted] = useState([]);

  useEffect(() => {
    setSelectedOptionLocal(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (options && selectedValue !== undefined) {
      /**
       * search in options / level 1
       */
      options.forEach((option) => {
        if (option.value === selectedValue) {
          setSelectedOptionLocal(option);
        } else if (option.options) {
          /**
           * search in group options / level 2
           */
          option.options.forEach((groupOption) => {
            if (groupOption.value === selectedValue) {
              setSelectedOptionLocal(groupOption);
            }
          });
        }
      });
    }
  }, [options, selectedValue]);

  const asyncLoadOptions = async (inputValue = '') => {
    if (String(inputValue).length > 0 && String(inputValue).length < asyncInputMinLength) {
      return [...leadingOptions, ...asyncOptionsPersisted];
    }

    let params = {
      populate: asyncPopulate,
      _limit: asyncLimit,
      _sort: asyncSort,
    };

    if (typeof asyncSetParams === 'function') {
      params = {
        ...params,
        ...asyncSetParams(inputValue),
      };
    }

    const res = await apiGet({
      path: asyncApiPath,
      params,
    });
    const entries = res?.data || [];

    let asyncOptions = [];
    if (typeof asyncFormatOptions === 'function') {
      asyncOptions = asyncFormatOptions(entries);
    } else if (typeof asyncFormatOption === 'function') {
      asyncOptions = entries.map((entry) => asyncFormatOption(entry));
    } else {
      asyncOptions = entries.map((entry) => {
        let label = asyncOptionLabelKey ? entry?.[asyncOptionLabelKey] : undefined;
        if (!label) {
          label = typeof asyncFormatOptionLabel === 'function' ? asyncFormatOptionLabel(entry) : entry?.id;
        }
        return {
          value: entry?.[asyncOptionValueKey],
          label,
        };
      });
    }

    if (asyncUniqueOptionLabels) {
      const labels = [];
      asyncOptions = asyncOptions
        .map((asyncOption) => {
          if (labels.includes(asyncOption.label)) {
            return null;
          }
          labels.push(asyncOption.label);
          return asyncOption;
        })
        .filter((asyncOption) => asyncOption !== null);
    }

    setAsyncOptionsPersisted(asyncOptions);
    return [...leadingOptions, ...asyncOptions];
  };

  const handleChange = (result, action) => {
    if (typeof onChange === 'function') onChange(result, action);
    if (!isMulti && typeof onChangeValue === 'function') {
      onChangeValue(result?.value, action);
    }
  };

  if (options) {
    return (
      <Select
        styles={customStyles({ layout })}
        value={selectedOptionLocal}
        defaultValue={defaultValue}
        placeholder={placeholder}
        options={[...leadingOptions, ...options]}
        isMulti={isMulti}
        isClearable={isClearable && selectedOptionLocal && selectedOptionLocal.value}
        isSearchable={isSearchable}
        captureMenuScroll={captureMenuScroll}
        onChange={(result, action) => handleChange(result, action)}
        noOptionsMessage={noOptionsMessage || (() => t('common.form.noEntries'))}
        loadingMessage={loadingMessage}
        isDisabled={isDisabled}
        isOptionDisabled={isOptionDisabled}
      />
    );
  }

  return (
    <AsyncSelect
      styles={customStyles({ layout })}
      value={selectedOptionLocal}
      defaultValue={defaultValue}
      placeholder={placeholder}
      defaultOptions={asyncDefaultOptions}
      loadOptions={asyncLoadOptions}
      isMulti={isMulti}
      isClearable={isClearable && (!selectedOptionLocal || selectedOptionLocal.value)}
      isSearchable={isSearchable}
      captureMenuScroll={captureMenuScroll}
      onChange={(result, action) => handleChange(result, action)}
      noOptionsMessage={noOptionsMessage || (() => t('common.form.noResults'))}
      loadingMessage={loadingMessage}
      isDisabled={isDisabled}
    />
  );
};

CustomReactSelect.propTypes = {
  /**
   * layout
   * @description
   * default undefined = suitable for light backgrounds
   * 'outline' = suitable for dark backgrounds
   */
  layout: PropTypes.string,
  /**
   * options
   * @description if prop is set async is not executed
   */
  options: PropTypes.array,
  /**
   * leadingOptions
   * @description options will be be placed on top of list
   */
  leadingOptions: PropTypes.array,
  /**
   * selectedOption
   * @description same es react-select value (option object or array of option objects)
   */
  selectedOption: PropTypes.any,
  /**
   * selectedOption
   * @description string or number, does not work for async select properly !
   */
  selectedValue: PropTypes.any,
  /**
   * react-select props
   */
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  captureMenuScroll: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  loadingMessage: PropTypes.func,
  /**
   * asyncApiPath
   * @example: '/companies'
   */
  asyncApiPath: PropTypes.string,
  /**
   * asyncPopulate
   */
  asyncPopulate: PropTypes.any,
  /**
   * asyncSort
   * @example: 'email:ASC' or 'email:ASC,dateField:DESC'
   */
  asyncSort: PropTypes.string,
  /**
   * asyncLimit
   * default: 100
   * @example: -1 for unlimited entries
   */
  asyncLimit: PropTypes.number,
  /**
   * asyncSetParams
   * @descrition set additional request params
   * @param inputValue: string
   * @returns object
   * @example
   *  asyncSetParams={(inputValue) =>
   *    inputValue
   *      ? {
   *          filters: [
   *            {
   *              number: { $contains: inputValue },
   *            },
   *          ],
   *        }
   *      : {}
   *  }
   */
  asyncSetParams: PropTypes.func,
  /**
   * asyncOptionValueKey
   * @default 'id'
   * @example: 'id'
   */
  asyncOptionValueKey: PropTypes.string,
  /**
   * asyncOptionLabelKey
   * @description if prop is set asyncFormatOptionLabel is not executed
   * @example: 'name' or 'email' etc
   */
  asyncOptionLabelKey: PropTypes.string,
  /**
   * asyncFormatOptionLabel
   * @example: asyncFormatOptionLabel={(entry) => `${entry.number} - ${entry.name}`}
   */
  asyncFormatOptionLabel: PropTypes.func,
  /**
   * asyncFormatOption
   * @description if prop is set asyncOptionValueKey, asyncFormatOptionLabel and asyncFormatOptionLabel are not executed
   * @example:
   * asyncFormatOption={(entry) => ({
   *      value: entry?.id,
   *      label: `${entry.number} - ${entry.name}`,
   * })}
   */
  asyncFormatOption: PropTypes.func,
  /**
   * asyncFormatOptions
   * @example
   * asyncFormatOptions={(entries) =>
   *      entries.map((entry) => ({
   *          value: entry?.name,
   *          label: entry?.id,
   *      }))
   * }
   */
  asyncFormatOptions: PropTypes.func,
  /**
   * asyncDefaultOptions
   * @description
   * same as react-select defaultOptions
   * when set to true, the results will be autoloaded
   */
  asyncDefaultOptions: PropTypes.any,
  /**
   * asyncInputMinLength
   * @default 0
   * @description
   * min length of input value before request is triggered
   */
  asyncInputMinLength: PropTypes.number,
  /**
   * asyncUniqueOptionLabels
   * @description
   * removes options with duplicate labels
   */
  asyncUniqueOptionLabels: PropTypes.bool,
  /**
   * onChange
   * @description
   * same as react-select onChange
   * returns option object or array of option objects (if isMulti is true)
   */
  onChange: PropTypes.func,
  /**
   * onChangeValue
   * @description
   * returns single value (if Multi is false)
   */
  onChangeValue: PropTypes.func,
  /**
   * isDisabled
   */
  isDisabled: PropTypes.bool,
  /**
   * isOptionDisabled
   */
  isOptionDisabled: PropTypes.func,
};

CustomReactSelect.defaultProps = {
  layout: undefined,
  options: undefined,
  leadingOptions: [],
  selectedOption: undefined,
  selectedValue: undefined,
  defaultValue: undefined,
  placeholder: undefined,
  isMulti: false,
  isClearable: false,
  isSearchable: false,
  captureMenuScroll: false,
  noOptionsMessage: undefined,
  loadingMessage: () => 'lädt...',
  asyncApiPath: undefined,
  asyncPopulate: undefined,
  asyncSort: undefined,
  asyncLimit: undefined,
  asyncSetParams: undefined,
  asyncOptionValueKey: 'id',
  asyncOptionLabelKey: undefined,
  asyncFormatOptionLabel: undefined,
  asyncFormatOption: undefined,
  asyncFormatOptions: undefined,
  asyncDefaultOptions: undefined,
  asyncInputMinLength: 0,
  asyncUniqueOptionLabels: false,
  onChange: undefined,
  onChangeValue: undefined,
  isDisabled: false,
  isOptionDisabled: () => false
};

export default CustomReactSelect;
