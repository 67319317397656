import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useJob } from '../../app/entityHooks/useJob';
import DateUtil from '../../utils/DateUtil';
import FormatUtil from '../../utils/FormatUtil';

import JobCard from './JobCard';
import JobCardStatus from './JobCardStatus';

interface JobCardWrapperProps {
  jobId: number | undefined;
  sortingKey?: string;
  onSelect?: (id: number, s: boolean) => void;
  isSelected?: boolean;
}

const JobCardWrapper: React.FC<JobCardWrapperProps> = ({ jobId, sortingKey, onSelect, isSelected }) => {
  const navigate = useNavigate();
  const { job, dlasFormatted } = useJob(jobId);

  const useSelect = !!onSelect;

  const pathToJob = `/job/${job?.id}`;

  const handleOnClick = useCallback(() => {
    if (jobId) {
      if (useSelect) {
        onSelect(jobId, !isSelected);
      } else {
        navigate(pathToJob);
      }
    }
  }, [isSelected, jobId, navigate, onSelect, pathToJob, useSelect]);

  if (!job) {
    return null;
  }

  let listStatusOffer: JobOfferStatus | undefined = undefined;
  if (
    job?.offerStatus === 'A-erstellt' ||
    job?.offerStatus === 'A-ausstehend' ||
    job?.offerStatus === 'A-bestaetigt' ||
    job?.offerStatus === 'A-abgelehnt'
  ) {
    listStatusOffer = job?.offerStatus;
  }

  let listStatusCertificate: JobStatus | undefined = undefined;
  if (
    job?.status === 'LN-erstellt' ||
    job?.status === 'LN-ausstehend' ||
    job?.status === 'LN-bestaetigt' ||
    job?.status === 'LN-abgelehnt'
  ) {
    listStatusCertificate = job?.status;
  }

  let listStatusJob: JobStatus | undefined = undefined;
  if (!listStatusOffer && !listStatusCertificate) {
    listStatusJob = 'Erstellt';
  }

  return (
    <JobCard
      variant={
        sortingKey === 'carrier_number' ? 'byCarrierNumber' : sortingKey === 'totalPrice' ? 'byTotalPrice' : 'byEntryId'
      }
      entryId={job.entryId}
      clientName={job?.clientName}
      dlasFormatted={dlasFormatted}
      carrierNumber={job?.carrierNumber}
      createdAt={DateUtil.getDateFormatted(job.createdAt)}
      totalPrice={`${FormatUtil.getDecimalFormatted(job.totalPrice)} €`}
      href={pathToJob}
      onClick={handleOnClick}
      useSelect={useSelect}
      isSelected={isSelected}
    >
      {listStatusJob && <JobCardStatus type="job" />}
      {listStatusOffer && <JobCardStatus status={job.offerStatus} type="offer" />}
      {listStatusCertificate && <JobCardStatus status={job.status} type="certificate" />}
    </JobCard>
  );
};

export default JobCardWrapper;
