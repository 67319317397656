import React from 'react';
import { inRange } from 'lodash';

import Bubble, { BubbleSize } from './Bubble';

type ResultBubbleProps = {
  children?: React.ReactNode;
  result: number;
  size?: BubbleSize;
};

const ResultBubble: React.FC<ResultBubbleProps> = ({ result, size }) => {
  const variantRangeMap = {
    red: [0, 50],
    yellow: [50, 80],
    green: [80, 101],
  };

  const variant: string = Object.entries(variantRangeMap).reduce((prevColor, [rangeColor, range]) => {
    const resultIsInRange = inRange(result, range[0], range[1]);
    return resultIsInRange ? rangeColor : prevColor;
  }, 'red');

  return (
    <Bubble variant={variant} size={size || 'm'}>
      {result}%
    </Bubble>
  );
};

export default ResultBubble;
