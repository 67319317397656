import { entitySelectors } from '../entitySelectors';

export const useAddress = (
  addressId: number | undefined
): {
  address: AddressEntity | undefined;
} => {
  const address = entitySelectors.selectById<AddressEntity>('addresses', addressId);

  return {
    address,
  };
};
