import { Collapse, UiDiv } from '@webfox-sc/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { fetchCrafts } from '../../../app/slices/craftsSlice';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import Button from '../../atoms-v2/Button';

import BaseDataQMCraft from './BaseDataQMCraft';
import BaseDataQMCraftForm from './BaseDataQMCraftForm';

const BaseDataQMCrafts: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [craftIds, setCraftIds] = useState<number[]>([]);

  const fetchData = useCallback(async () => {
    const res = await dispatch(fetchCrafts());
    const ids = (res?.payload?.data || []).map((craftData) => craftData.id);
    setCraftIds(ids);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {craftIds.length > 0 && (
        <UiDiv display="flex" flexDirection="column" rowGap="xs" marginBottom="xs">
          {craftIds.map((craftId) => {
            return <BaseDataQMCraft key={craftId} craftId={craftId} />;
          })}
        </UiDiv>
      )}
      <Button icon={IconPlus} onClick={() => setShowCreateForm(!showCreateForm)}>
        {t('craft.actions.new')}
      </Button>
      <Collapse show={showCreateForm} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="xs">
          <UiDiv padding="s" background="grey20">
            <BaseDataQMCraftForm
              onSubmit={() => {
                fetchData();
                setShowCreateForm(false);
              }}
              onClickCancel={() => setShowCreateForm(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
    </>
  );
};

export default BaseDataQMCrafts;
