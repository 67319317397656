import PropTypes from 'prop-types';
import { Component } from 'react';
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts';

class Chart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            componentWidth: 400
        };

        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize() {
        if (this.refComponent) {
            this.setState({
                componentWidth: this.refComponent.offsetWidth
            });
        }
    }

    render() {
        const { componentWidth } = this.state;
        const { data } = this.props;

        return (
            <div
                ref={(node) => {
                    this.refComponent = node;
                }}
            >
                <BarChart
                    width={componentWidth}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 10
                    }}
                >
                    <XAxis dataKey="name" interval={0} />
                    <YAxis />
                    <Bar dataKey={'value'} fill="#4c668b">
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Bar>
                </BarChart>
            </div>
        );
    }
}

Chart.propTypes = {
    data: PropTypes.array
};

Chart.defaultProps = {
    data: []
};

export default Chart;
