import { Collapse, UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../assets/icons-v2/IconArrowUp';
import Button from '../atoms-v2/Button';

import JobHistoryList from './JobHistoryList';
import { useJobHistories } from './useJobHistories';

interface JobHistoryProps {
  jobId?: number | undefined;
}

const JobHistory: React.FC<JobHistoryProps> = ({ jobId }) => {
  const { t } = useTranslation();
  const { jobHistoriesSorted } = useJobHistories(jobId);

  const [showHistory, setShowHistory] = useState(false);

  return (
    <>
      {jobHistoriesSorted.length > 0 && (
        <UiDiv display="flex" justifyContent="center" paddingTop="s">
          <Button
            icon={showHistory ? IconArrowUp : IconArrowDown}
            active={showHistory}
            onClick={() => {
              setShowHistory(!showHistory);
            }}
          >
            {t('job.history')}
          </Button>
        </UiDiv>
      )}

      <Collapse show={showHistory} fadeInOut>
        <UiDiv paddingTop="s">
          <UiDiv background="white" padding="m">
            <UiDiv paddingTop="m">
              <JobHistoryList jobId={jobId} type="job" />
            </UiDiv>
          </UiDiv>
        </UiDiv>
      </Collapse>
    </>
  );
};

export default JobHistory;
