import axios from 'axios';
import RestUtil from '../utils/RestUtil';
import { REDUX_ACTIONS } from '../global/constants';

export function fetchLocation(id, params) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_LOCATION,
    });

    return axios({
      method: 'get',
      url: `${RestUtil.getStrapiUrl()}/locations/${id}`,
      params,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const location = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_LOCATION,
          location,
        });
        return location;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_LOCATION_ERROR,
        });
        throw error;
      });
  };
}

export function createLocation(data) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_CREATE_LOCATION,
    });

    return axios({
      method: 'post',
      url: `${RestUtil.getStrapiUrl()}/locations`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const location = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_CREATE_LOCATION,
          location,
        });
        return [location];
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_CREATE_LOCATION_ERROR,
        });
        throw error;
      });
  };
}

export function updateLocation(locationId, data) {
  return async (dispatch, getState) => {
    const {
      dataState: { locations },
      authState,
    } = getState();

    const oldLocation = locations ? locations[locationId] : {};

    dispatch({
      type: REDUX_ACTIONS.REQUEST_UPDATE_LOCATION,
    });

    return axios({
      method: 'put',
      url: `${RestUtil.getStrapiUrl()}/locations/${locationId}`,
      data,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
        'Cache-Control': 'no-store',
        'If-Modified-Since': '0',
      },
    })
      .then((res) => {
        const location = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_UPDATE_LOCATION,
          location,
          oldLocation,
        });
        return location;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_UPDATE_LOCATION_ERROR,
        });
        throw error;
      });
  };
}

export function deleteLocation(locationId) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_LOCATION,
    });

    return axios({
      method: 'delete',
      url: `${RestUtil.getStrapiUrl()}/locations/${locationId}`,
      headers: {
        Authorization: `Bearer ${authState.jwt}`,
      },
    })
      .then((res) => {
        const location = res.data || {};
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_LOCATION,
          locationId,
          location,
        });
        return [location];
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_LOCATION_ERROR,
        });
        throw error;
      });
  };
}

export function deleteAllLocations(locationIds) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_LOCATION,
    });

    return Promise.all(
      locationIds.map((locationId) => {
        return axios({
          method: 'delete',
          url: `${RestUtil.getStrapiUrl()}/locations/${locationId}`,
          headers: {
            Authorization: `Bearer ${authState.jwt}`,
          },
        });
      })
    )
      .then((res) => {
        const locations = (res || [{}]).map(({ data }) => data);
        dispatch({
          type: REDUX_ACTIONS.RECEIVE_DELETE_ALL_LOCATIONS,
          locations,
        });
        return locations;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_LOCATION_ERROR,
        });
        throw error;
      });
  };
}
