export const SHOW_MAINTENANCE_VIEW =
  process.env.REACT_APP_SHOW_MAINTENANCE_VIEW !== 'false'
    ? parseInt(process.env.REACT_APP_SHOW_MAINTENANCE_VIEW)
    : false;
export const ACCEPT_WITH_COMMENT = process.env.REACT_APP_ACCEPT_WITH_COMMENT !== 'false';
export const USE_GLOBAL_SERVICES = process.env.REACT_APP_USE_GLOBAL_SERVICES !== 'false';
export const SHOW_MOVE_JOBS = process.env.REACT_APP_SHOW_MOVE_JOBS !== 'false';
export const IMPORT_SCHEMAS = `${process.env.REACT_APP_IMPORT_SCHEMAS}`;
export const REQUIRE_COMMENT_IF_NEGATIVE = process.env.REACT_APP_REQUIRE_COMMENT_IF_NEGATIVE !== 'false';
export const MULTIPLE_SCHEDULES = process.env.REACT_APP_MULTIPLE_SCHEDULES !== 'false';
export const SHOW_QM_USER_FILTER = process.env.REACT_APP_SHOW_QM_USER_FILTER !== 'false';
export const REQUIRE_COMPANY_FOR_QM_SUMMARY = process.env.REACT_APP_REQUIRE_COMPANY_FOR_QM_SUMMARY !== 'false';
export const USE_REPORTER_FILTER_IN_QM_SUMMARY = process.env.REACT_APP_USE_REPORTER_FILTER_IN_QM_SUMMARY !== 'false';
export const USE_PHONE_NUMBER = process.env.REACT_APP_USE_PHONE_NUMBER !== 'false';
export const ALLOW_EDIT_JOB_CLIENT = process.env.REACT_APP_ALLOW_EDIT_JOB_CLIENT !== 'false';
export const EXPORT_JOB_SERVICES = process.env.REACT_APP_EXPORT_JOB_SERVICES !== 'false';
export const VAT_RATE = parseInt(process.env.REACT_APP_VAT_RATE);
export const USE_SCHEDULE_INTERVAL = process.env.REACT_APP_USE_SCHEDULE_INTERVAL !== 'false';
export const USE_DEBITOR_ADDRESSES_IN_JOBS = process.env.REACT_APP_USE_DEBITOR_ADDRESSES_IN_JOBS !== 'false';
export const REJECT_OFFERS = process.env.REACT_APP_REJECT_OFFERS !== 'false';
export const SHOW_JOB_RECIPIENT_ADDRESS = process.env.REACT_APP_SHOW_JOB_RECIPIENT_ADDRESS !== 'false';
