import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { apiUrl } from '../api';

export interface AuthState {
  status: 'idle' | 'loading' | 'autoLogin' | 'loggedIn' | 'failed';
  jwt?: string;
  username?: string;
  userId?: number;
  userEmail?: string;
  userRoleName?: string;
  userRoleId?: number;
}

const initialState: AuthState = {
  status: 'idle',
  jwt: undefined,
  username: undefined,
  userId: undefined,
  userEmail: undefined,
  userRoleName: undefined,
  userRoleId: undefined,
};

const getUserData = async (jwt) => {
  if (!jwt) {
    return { jwt: undefined, user: undefined, role: undefined };
  }

  const { data: user } = await axios.get(`${apiUrl}/users/me?populate=*`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  if (user?.role?.id) {
    const { data: roleData } = await axios.get(`${apiUrl}/users-permissions/roles/${user.role.id}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return {
      jwt,
      user,
      role: roleData?.role || undefined,
    };
  }
  return {
    jwt,
    user,
    role: undefined,
  };
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ identifier, password }: { identifier: string; password: string }) => {
    const { data: loginData } = await axios.post(`${apiUrl}/auth/local`, {
      identifier,
      password,
    });
    if (loginData) {
      const { jwt } = loginData;
      return getUserData(jwt);
    }
    return loginData;
  }
);

export const autoLogin = createAsyncThunk('auth/autoLogin', async () => {
  const jwt = JSON.parse(localStorage.getItem('jwt') || '');

  return getUserData(jwt);
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem('jwt');
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        const { jwt, user } = action.payload;
        localStorage.setItem('jwt', JSON.stringify(jwt));
        return {
          ...state,
          status: 'loggedIn',
          jwt,
          username: user?.username,
          userId: user?.id,
          userEmail: user?.email,
          userRoleName: user?.role?.name,
          userRoleId: user?.role?.id,
        };
      })
      .addCase(login.rejected, () => {
        return { ...initialState, status: 'failed' };
      })
      .addCase(autoLogin.pending, (state) => {
        state.status = 'autoLogin';
      })
      .addCase(autoLogin.fulfilled, (state, action) => {
        const { jwt, user } = action.payload;
        return {
          ...state,
          status: 'loggedIn',
          jwt,
          username: user?.username,
          userId: user?.id,
          userEmail: user?.email,
          userRoleName: user?.role?.name,
          userRoleId: user?.role?.id,
        };
      })
      .addCase(autoLogin.rejected, () => {
        localStorage.removeItem('jwt');
        return { ...initialState };
      });
  },
});

export const { logout } = authSlice.actions;
export const selectAuthState = (state: RootState): AuthState => state.authState;
export default authSlice.reducer;
