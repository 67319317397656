const IconSearch = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M96.38,78.93l-17-17A42.16,42.16,0,1,0,0,42.15,42.19,42.19,0,0,0,61.94,79.4l17,17A12.34,12.34,0,1,0,96.38,78.93ZM42.15,65.48A23.33,23.33,0,1,1,65.48,42.15,23.34,23.34,0,0,1,42.15,65.48Z"
    />
  </svg>
);

export default IconSearch;
