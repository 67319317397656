import React from 'react';
import FilterToggle from '../FilterToggle';
import './edit-status-filter.scss';
import { useBaseDataViewContext } from '../../../hooks/baseDataView';
import { useBaseDataFilterContext } from '../../../hooks/baseDataFilter';

const EditStatusFilter = () => {
    const { entryCounts: count } = useBaseDataViewContext();
    const { tableFilter, handleChangeTableFilter } = useBaseDataFilterContext();

    let filterEntry;
    if (tableFilter && tableFilter.status && Array.isArray(tableFilter.status)) {
        filterEntry = tableFilter.status;
    } else {
        filterEntry = [];
    }

    const toggle = (active, filterStatus) => {
        if (active) {
            filterEntry.push(filterStatus);
        } else {
            const index = filterEntry.findIndex((element) => element === filterStatus);
            if (index >= 0) {
                filterEntry.splice(index, 1);
            }
        }
        handleChangeTableFilter({
            ...tableFilter,
            status: filterEntry,
        });
    };

    // set filter
    const filterMap = {
        new: false,
        editedNotNew: false,
        invalid: false,
        original: false,
    };
    if (filterEntry) {
        filterEntry.forEach((status) => {
            filterMap[status] = true;
        });
    }

    return (
        <div className="smg-edit-status-filter">
            <FilterToggle
                isActive={filterMap.new}
                label="Neu"
                className="smg-edit-status-filter__toggle--new"
                count={count.new || 0}
                onToggle={(active) => toggle(active, 'new')}
            />
            <FilterToggle
                isActive={filterMap.editedNotNew}
                label="Bearbeitet"
                className="smg-edit-status-filter__toggle--edited"
                count={count.editedNotNew || 0}
                onToggle={(active) => toggle(active, 'editedNotNew')}
            />
            <FilterToggle
                isActive={filterMap.invalid}
                label="Fehlerhaft"
                className="smg-edit-status-filter__toggle--invalid"
                count={count.invalid || 0}
                onToggle={(active) => toggle(active, 'invalid')}
            />
            <FilterToggle
                isActive={filterMap.original}
                label="Nicht bearbeitet"
                className="smg-edit-status-filter__toggle--original"
                count={count.original || 0}
                onToggle={(active) => toggle(active, 'original')}
            />
        </div>
    );
};

export default EditStatusFilter;
