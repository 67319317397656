import { UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface JobsLegendColorItemProps {
  variant: 'confirmed' | 'pending' | 'rejected';
}

const JobsLegendColorItem: React.FC<JobsLegendColorItemProps> = ({ variant }) => {
  const { t } = useTranslation();
  let color: string;
  let text: string;

  switch (variant) {
    case 'confirmed':
      color = 'trafficLightGreen';
      text = t('job.searchForm.legend.confirmed');
      break;
    case 'pending':
      color = 'trafficLightYellow';
      text = t('job.searchForm.legend.pending');
      break;
    case 'rejected':
      color = 'trafficLightRed';
      text = t('job.searchForm.legend.rejected');
      break;
  }

  return (
    <UiDiv display="flex" alignItems="center" columnGap="5px">
      <UiDiv height="10px" width="10px" borderRadius="5px" backgroundColor={color}></UiDiv>
      <UiText variant="copy" color="white">
        = {text}
      </UiText>
    </UiDiv>
  );
};

export default JobsLegendColorItem;
