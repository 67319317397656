import { Block, Collapse, Group, GroupItem, UiDiv } from '@webfox-sc/core';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import _set from 'lodash/set';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import QMUtil from '../../../utils/QMUtil';
import Button from '../../atoms-v2/Button';
import Checkbox from '../../atoms-v2/Checkbox';
import Text from '../../atoms-v2/Text';
import TextButton from '../../atoms-v2/TextButton';

import QMAdminConfigRoomgroup from './QMAdminConfigRoomgroup';
import QMAdminConfigRoomgroupCreateForm from './QMAdminConfigRoomgroupCreateForm';

interface QMAdminConfigRoomgroupsProps {
  roomgroups: RoomgroupNestedEntity[];
  onSave: (roomgroups: RoomgroupNestedEntity[]) => void;
  onDelete: () => void;
  onRemove?: () => void;
  pageScope: CheckSource;
  configScope: CheckSource;
}

const enableParents = (data, path) => {
  if (typeof path[path.length - 1] === 'number') {
    path.pop();
  }
  path.pop();
  if (_get(data, [...path, 'disabled'])) {
    _set(data, [...path, 'disabled'], false);
  }
  if (path.length) {
    enableParents(data, path);
  }
};

// apply to all entries
const applyToChildItems = (data, path, fn) => {
  const items = _get(data, path);
  items.forEach((_item, index) => fn([...path, index]));
};

const QMAdminConfigRoomgroups: React.FC<QMAdminConfigRoomgroupsProps> = ({
  roomgroups,
  onSave,
  onDelete,
  onRemove,
  pageScope,
  configScope,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<RoomgroupNestedEntity[]>([]);
  const [showGlobalConfig, setShowGlobalConfig] = useState(pageScope !== configScope);
  const [showCreateRoomgroupForm, setShowCreateRoomgroupForm] = useState(false);

  useEffect(() => {
    setData(roomgroups);
  }, [roomgroups]);

  useEffect(() => {
    // always show global options if in create mode
    setShowGlobalConfig(pageScope !== configScope);
  }, [pageScope, configScope]);

  const clickHandler = (path) => {
    const newData = _cloneDeep(data);
    const disabledPath = [...path, 'disabled'];
    const newValue = !_get(newData, disabledPath);
    // disable/enable children/parents
    if (path.length === 1) {
      applyToChildItems(newData, [...path, 'checkitemgroups'], (childPath) => {
        _set(newData, [...childPath, 'disabled'], newValue);
        applyToChildItems(newData, [...childPath, 'checkitems'], (p) => _set(newData, [...p, 'disabled'], newValue));
      });
    } else if (path.length === 3) {
      applyToChildItems(newData, [...path, 'checkitems'], (p) => _set(newData, [...p, 'disabled'], newValue));
      if (!newValue) {
        // child gets enabled, enable parents
        _set(newData, [path[0], 'disabled'], newValue);
      }
    } else if (path.length === 5) {
      if (!newValue) {
        // child gets enabled, enable parents
        _set(newData, [...path.slice(0, 3), 'disabled'], newValue);
        _set(newData, [path[0], 'disabled'], newValue);
      }
    }
    _set(newData, disabledPath, newValue);
    setData(newData);
  };

  const updateHandler = (path, value) => {
    const newData = _cloneDeep(data);
    let isset = false;
    if (typeof path[path.length - 1] === 'number') {
      // modify array
      const arrayPath = [...path];
      const index = arrayPath.pop();
      const arr = arrayPath.length === 0 ? newData : _get(newData, arrayPath);
      if (value === null) {
        // remove from array
        if (Array.isArray(arr)) {
          arr.splice(index, 1);
        }
        isset = true;
      } else if (Array.isArray(arr) && index >= arr.length) {
        // add new entry
        arr.push(value);
        isset = true;
        enableParents(newData, arrayPath);
        // enable parents
      }
    }
    if (!isset) {
      // merge objects
      if (typeof value === 'object') {
        const oldValue = _get(newData, path);
        _set(newData, path, _merge(oldValue, value));
      } else {
        // just set value
        _set(newData, path, value);
      }
    }
    setData(newData);
  };

  const handleOnReset = () => {
    setData(roomgroups);
  };

  const handleOnAdd = (name: string) => {
    setData([...data, QMUtil.getEmptyNestedRoomgroup({ source: pageScope, name, path: [data.length] })]);
  };

  const handleOnSave = () => {
    onSave(data);
  };

  // add original index
  const indexedData = data.map(({ path: _rgPath, checkitemgroups, source, ...roomgroup }, rgI) => {
    const showDelete = source === pageScope;
    return {
      ...roomgroup,
      path: [rgI],
      source,
      showDelete,
      checkitemgroups: checkitemgroups.map(({ path: _cigPath, checkitems, ...checkitemgroup }, cigI) => ({
        ...checkitemgroup,
        path: [rgI, 'checkitemgroups', cigI],
        showDelete,
        checkitems: checkitems.map(({ path: _ciPath, ...checkitem }, ciI) => ({
          ...checkitem,
          path: [rgI, 'checkitemgroups', cigI, 'checkitems', ciI],
          showDelete,
        })),
      })),
    };
  });

  const treeData = showGlobalConfig
    ? QMUtil.mergeRoomgroupTree(indexedData)
    : indexedData.filter(({ source }) => source === pageScope);

  const canSave = !_isEqual(data, roomgroups);

  let specificityInfo;
  if (configScope === 'COMPANY') {
    specificityInfo = t('schedule.info.currentSpecificity.company');
  } else {
    specificityInfo = t('schedule.info.currentSpecificity.default');
  }

  return (
    <>
      <Block padding="m" paddingBottom="xs" display="flex" gap="s">
        <Group gap="xs" alignItems="center">
          <GroupItem grow>
            <Checkbox
              // variant="outline"
              checked={showGlobalConfig}
              disabled={configScope !== pageScope}
              onChange={(e) => setShowGlobalConfig(e.target.checked)}
            />
          </GroupItem>
          <GroupItem grow>
            <Text>{t('schedule.actions.showDefault')}</Text>
          </GroupItem>
          {configScope !== pageScope && (
            <GroupItem grow>
              <Text>
                {specificityInfo}
              </Text>
            </GroupItem>
          )}
        </Group>
      </Block>

      <UiDiv display="flex" flexDirection="column" rowGap="xs" marginTop="m">
        {treeData.map((roomgroup, index) => (
          <QMAdminConfigRoomgroup
            key={roomgroup.id || index}
            roomgroup={roomgroup}
            clickHandler={clickHandler}
            updateHandler={updateHandler}
          />
        ))}

        <UiDiv>
          <TextButton
            icon={IconPlus}
            onClick={(event) => {
              event.preventDefault();
              setShowCreateRoomgroupForm(!showCreateRoomgroupForm);
            }}
          >
            {t('schedule.actions.addCustomRoomgroup')}
          </TextButton>
          <Collapse show={showCreateRoomgroupForm} fadeInOut keepChildren={false}>
            <UiDiv paddingTop="xs">
              <UiDiv padding="s" background="grey20">
                <QMAdminConfigRoomgroupCreateForm
                  onClickSave={(name) => {
                    handleOnAdd(name);
                    setShowCreateRoomgroupForm(false);
                  }}
                  onClickCancel={() => setShowCreateRoomgroupForm(false)}
                />
              </UiDiv>
            </UiDiv>
          </Collapse>
        </UiDiv>
      </UiDiv>

      <Block padding="s" display="flex" justify="center" gap="xs">
        {configScope === pageScope && (
          <Button icon={IconDelete} variant="secondary" onClick={onDelete}>
            {configScope === 'CLIENT'
              ? t('schedule.actions.deleteClientConfiguration')
              : t('schedule.actions.deletCompanyConfiguration')}
          </Button>
        )}
        {onRemove && configScope === 'GLOBAL' && pageScope === 'COMPANY' && (
          <Button icon={IconDelete} variant="secondary" onClick={onRemove}>
            {t('schedule.actions.removeCraft')}
          </Button>
        )}
        <Button onClick={handleOnSave} disabled={!canSave} icon={IconCheck}>
          {t('common.actions.save')}
        </Button>
        <Button onClick={handleOnReset} disabled={!canSave} icon={IconClose} variant="secondary">
          {t('common.actions.cancel')}
        </Button>
      </Block>
    </>
  );
};

export default QMAdminConfigRoomgroups;
