import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { selectErrorState, setErrorState } from '../../app/slices/errorSlice';
import Dialog from '../../container/Dialog';

const ErrorDialog: React.FC = () => {
  const { errorHeading, errorText, showError } = useAppSelector(selectErrorState);
  const dispatch = useAppDispatch();

  const hideError = () => {
    dispatch(
      setErrorState({
        errorHeading: null,
        errorText: null,
        showError: false,
      })
    );
  };

  if (!showError) {
    return null;
  }

  return <Dialog heading={errorHeading} text={errorText} showCloseButton onClickCloseButton={hideError} />;
};

export default ErrorDialog;
