import React from 'react';
import PropTypes from 'prop-types';

const FormSelectOptions = ({ options }) => {
    return options.map((option, i) => {
        const isOptionGroup = Boolean(option.options);
        if (isOptionGroup) {
            return (
                <optgroup key={String(i)} label={option.label}>
                    {option.options.map((groupOption, n) => {
                        return (
                            <option key={String(n)} value={groupOption.value} disabled={groupOption.disabled}>
                                {groupOption.label}
                            </option>
                        );
                    })}
                </optgroup>
            );
        }

        return (
            <option key={String(i)} value={option.value} disabled={option.disabled}>
                {option.label}
            </option>
        );
    });
};

FormSelectOptions.propTypes = {
    options: PropTypes.array,
};

FormSelectOptions.defaultProps = {
    options: [],
};

export default FormSelectOptions;
