import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { contactSchema } from '../schemas';

export const createContact = (data: ContactCreateData): RequestEntitiesAction<ContactData> =>
  requestEntities({
    method: 'POST',
    path: '/contacts',
    data,
    schema: contactSchema,
  });

export const fetchContactsByEmail = (email: string): RequestEntitiesAction<ContactData[]> =>
  requestEntities({
    method: 'GET',
    path: '/contacts',
    params: {
      filters: { email }
    },
    schema: [contactSchema],
  });

export const updateContact = (contactId: number, data: ContactUpdateData): RequestEntitiesAction<ContactData> =>
  requestEntities({
    method: 'PUT',
    path: `/contacts/${contactId}`,
    data,
    schema: contactSchema,
  });

export const deleteContact = (contactId: number): RequestEntitiesAction<ContactData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/contacts/${contactId}`,
    schema: contactSchema,
    type: 'deleteContact',
  });
};

const contactsAdapter = createEntityAdapter<ScheduleEntity>();

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: contactsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.contacts) {
        contactsAdapter.upsertMany(state, action.payload.entities.contacts);
      }
      if (action.meta.arg.type === 'deleteContact') {
        contactsAdapter.removeOne(state, action.payload.data.id);
      }
    });
  },
});

export default contactsSlice.reducer;
