import React from 'react';
import { InlineSVG, UiDiv, UiDivProps, UiText } from '@webfox-sc/core';

interface NoResultsNoticeProps extends UiDivProps {
  icon?: React.ReactNode;
}

const NoResultsNotice: React.FC<NoResultsNoticeProps> = ({ icon, children, ...props }) => {
  return (
    <UiDiv {...props}>
      <UiText variant="copy" color="white" textAlign="center">
        {children}
      </UiText>
      {icon && (
        <UiDiv display="flex" justifyContent="center" marginTop="s">
          <InlineSVG width="48px" color="white">
            {icon}
          </InlineSVG>
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default NoResultsNotice;
