import React from 'react';
import styled from 'styled-components';

import IconArrowLeft from '../../assets/icons-v2/IconArrowLeft';

const StyledBackLink = styled.a(({ theme }) => {
  return {
    display: 'inline-flex',
    gap: '8px',
    alignItems: 'center',
    ...theme.textVariants.copySmall,
    fontWeight: 700,
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
      textDecoration: 'underline',
    },
    svg: {
      width: '11px',
      height: '11px',
      verticalAlign: 'top',
      textDecoration: 'none',
    },
  };
});

const BackLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({ children, ...props }) => {
  return (
    <StyledBackLink {...props}>
      {IconArrowLeft}
      {children}
    </StyledBackLink>
  );
};

export default BackLink;
