const IconCopy = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M14.09,100H71.57A10.57,10.57,0,0,0,82,89.34v-4h3.9A10.58,10.58,0,0,0,96.39,74.75V10.66A10.58,10.58,0,0,0,85.91,0H28.43A10.57,10.57,0,0,0,18,10.66v4h-3.9A10.58,10.58,0,0,0,3.61,25.25V89.34A10.58,10.58,0,0,0,14.09,100ZM25.67,54H39.52V39.88a3.29,3.29,0,1,1,6.58,0V54H60a3.35,3.35,0,0,1,0,6.69H46.1V74.75a3.29,3.29,0,1,1-6.58,0V60.66H25.67a3.35,3.35,0,0,1,0-6.69ZM24.53,10.66a4,4,0,0,1,3.9-4H85.91a4,4,0,0,1,3.93,4V74.75a4,4,0,0,1-3.93,4H82V25.25A10.57,10.57,0,0,0,71.57,14.62h-47Z"
    />
  </svg>
);

export default IconCopy;
