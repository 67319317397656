import { uniq } from 'lodash';

import { entitySelectors } from '../entitySelectors';

export const useJob = (
  jobId: number | undefined
): {
  job: JobEntity | undefined;
  recipient: RecipientEntity | undefined;
  offer: OfferEntity | undefined;
  certificate: CertificateEntity | undefined;
  dlasFormatted: string;
} => {
  const job = entitySelectors.selectById<JobEntity>('jobs', jobId);
  const recipient = entitySelectors.selectById<RecipientEntity>('recipients', job?.recipientId);
  const offer = entitySelectors.selectById<OfferEntity>('offers', job?.offerId);
  const certificate = entitySelectors.selectById<CertificateEntity>('certificates', job?.certificateId);

  const dlas = (job?.services || []).map((service) => service.dla);
  const dlasFormatted: string = uniq(dlas).sort().join(', ');

  return {
    job,
    recipient,
    offer,
    certificate,
    dlasFormatted,
  };
};
