import { useState } from 'react';
import { intersection, isEqual, merge, pull } from 'lodash';

import { useAppDispatch } from '../../app/appHooks';
import { fetchClient, fetchClients } from '../../app/slices/clientsSlice';
import ClientsUtil from '../../utils/ClientsUtil';
import { stripObject } from '../../utils/FilterUtil';

const fetchClientParams = {
  populate: {
    costcenter_rel: {
      fields: ['id', 'number'],
      populate: {
        clients: {
          populate: ['location', 'company'],
        },
      },
    },
    location: {
      fields: ['id', 'description', 'number'],
      populate: {
        clients: {
          populate: ['costcenter_rel', 'company'],
        },
      },
    },
    company: {
      fields: ['id', 'name', 'number'],
      populate: {
        clients: {
          populate: ['costcenter_rel', 'location'],
        },
        address: '*',
      },
    },
    client_group: {
      populate: {
        services: '*',
      },
    },
    services: '*',
    address: '*',
  },
};

const initialFilterState = {
  companies: undefined,
  locations: undefined,
  costcenters: undefined,
};

const getClientOptions = (clients: any[]) => {
  const locationsMap = {};
  const companiesMap = {};
  const costcentersMap = {};
  const res: {
    clientIds: number[];
    locations: any;
    companies: any;
    costcenters: any;
  } = {
    clientIds: [],
    locations: [],
    companies: [],
    costcenters: [],
  };

  clients.forEach(({ location, company, costcenter_rel, id }) => {
    res.clientIds.push(id);
    if (location && typeof location === 'object' && !locationsMap[location.id]) {
      locationsMap[location.id] = stripObject(location);
    }
    if (company && typeof company === 'object' && !companiesMap[company.id]) {
      companiesMap[company.id] = stripObject(company);
    }
    if (costcenter_rel && typeof costcenter_rel === 'object' && !costcentersMap[costcenter_rel.id]) {
      costcentersMap[costcenter_rel.id] = stripObject(costcenter_rel);
    }
  });
  const locations = Object.values(locationsMap);
  if (locations.length > 0) {
    res.locations = locations;
  }
  const companies = Object.values(companiesMap);
  if (companies.length > 0) {
    res.companies = companies;
  }
  const costcenters = Object.values(costcentersMap);
  if (costcenters.length > 0) {
    res.costcenters = costcenters;
  }
  return res;
};

export const useJobRecipientEditor = () => {
  const dispatch = useAppDispatch();

  const [client, setClient] = useState<any | undefined>(undefined);
  const [currentFilterState, setFilterState] = useState(initialFilterState);
  const [filterMap, setFilterMap] = useState({});

  const selectClient = async (clientId?: number) => {
    if (!clientId) {
      setClient(undefined);
      return false;
    }

    if (!!clientId && clientId !== client?.id) {
      const resClientRaw = await dispatch(fetchClient(clientId, fetchClientParams));
      const clientRaw = resClientRaw?.payload?.data;

      if (!clientRaw) {
        setClient(undefined);
      }

      const cl = ClientsUtil.parseData(clientRaw);
      setClient(cl);

      const companyClients = typeof clientRaw?.company === 'object' ? clientRaw?.company?.clients || [] : [];
      const locationClients = typeof clientRaw?.location === 'object' ? clientRaw?.location?.clients || [] : [];
      const costcenterClients =
        typeof clientRaw?.costcenter_rel === 'object' ? clientRaw?.costcenter_rel?.clients || [] : [];

      setFilterState({
        companies: { ...stripObject(cl.company), ...getClientOptions(companyClients) },
        locations: {
          ...stripObject(cl.location),
          ...getClientOptions(locationClients),
        },
        costcenters: {
          ...stripObject(cl.costcenter),
          ...getClientOptions(costcenterClients),
        },
      } as any);
    }

    return true;
  };

  const computeFilterMap = (filterState: any, lastUpdate: any) => {
    const keys = ['companies', 'locations', 'costcenters'];

    // compute filter groups
    const filteredObjects = {
      companies: {},
      locations: {},
      costcenters: {},
    };

    const filters = {};
    // create ids object
    keys.forEach((key) => {
      if (filterState[key]) {
        const obj = filterState[key];
        filters[key] = obj.clientIds;
        filteredObjects[key].selected = obj;
      }
    });

    let newFilterState = { ...filterState };

    const filteredClientIds = intersection(...(Object.values(filters) as any));
    if (filteredClientIds.length === 0) {
      // no match, reset
      if (lastUpdate) {
        // check if other selections are in options of the last selected entry
        const otherKeys = [...keys];
        pull(otherKeys, lastUpdate);

        const lastFilterObject = filterState[lastUpdate];
        otherKeys.forEach((key) => {
          const selection = filterState[key];
          if (selection) {
            const options = lastFilterObject[key];
            if (options && !options.find(({ id }) => id === selection.id)) {
              // selection not found in options, delete
              delete newFilterState[key];
            }
          }
        });
      } else {
        // no last update, reset
        newFilterState = { ...initialFilterState };
      }
      if (!isEqual(filterState, newFilterState)) {
        setFilterState(newFilterState);
      }
      if (client) {
        setClient(undefined);
      }
    } else if (filteredClientIds.length === 1) {
      // exactly one matching client, select
      selectClient(filteredClientIds[0] as any);
    } else {
      // more than one match
      selectClient();
    }

    // set options
    keys.forEach((key, i) => {
      const otherKeys = [...keys];
      otherKeys.splice(i, 1);

      filteredObjects[key].group = {};
      otherKeys.forEach((k) => {
        if (newFilterState[k] && newFilterState[k][key] && newFilterState[k][key].length > 0) {
          filteredObjects[key].group[k] = newFilterState[k][key];
        }
      });
    });
    setFilterMap(filteredObjects);
  };

  return {
    client,
    setClientId: selectClient,
    filterMap,
    company: (currentFilterState.companies as any) || undefined,
    setCompany: async (input: any) => {
      const company = input ? { ...input } : undefined;
      if (input.id) {
        const resClients = await dispatch(
          fetchClients({
            company: input.id,
            populate: ['costcenter_rel', 'location'],
            _limit: -1,
          })
        );
        const clients = resClients?.payload?.data || [];

        if (clients) {
          const options = getClientOptions(clients);
          if (options) {
            merge(company, options);
          }
        }
      }
      const newFilterState = { ...currentFilterState, companies: company };
      setFilterState(newFilterState);
      computeFilterMap(newFilterState, 'companies');
      return company;
    },
    location: (currentFilterState.locations as any) || undefined,
    setLocation: async (input: any) => {
      const location = input ? { ...input } : undefined;
      if (input.id) {
        const resClients = await dispatch(
          fetchClients({
            location: input.id,
            populate: ['costcenter_rel', 'company'],
            _limit: -1,
          })
        );
        const clients = resClients?.payload?.data || [];

        if (clients) {
          const options = getClientOptions(clients);
          if (options) {
            merge(location, options);
          }
        }
      }
      const newFilterState = { ...currentFilterState, locations: location };
      setFilterState(newFilterState);
      computeFilterMap(newFilterState, 'locations');
      return location;
    },
    costcenter: (currentFilterState.costcenters as any) || undefined,
    setCostcenter: async (input: any) => {
      const costcenter = input ? { ...input } : undefined;
      if (input.id) {
        const resClients = await dispatch(
          fetchClients({
            costcenter_rel: input.id,
            populate: ['location', 'company'],
            _limit: -1,
          })
        );
        const clients = resClients?.payload?.data || [];

        if (clients) {
          const options = getClientOptions(clients);
          if (options) {
            merge(costcenter, options);
          }
        }
      }
      const newFilterState = { ...currentFilterState, costcenters: costcenter };
      setFilterState(newFilterState);
      computeFilterMap(newFilterState, 'costcenters');
      return costcenter;
    },
  };
};
