import { UiDiv } from '@webfox-sc/core';
import { map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../app/appHooks';
import { useClient } from '../../../app/entityHooks/useClient';
import { entitySelectors } from '../../../app/entitySelectors';
import { pathToQMAdminCompany } from '../../../app/paths';
import { fetchClient } from '../../../app/slices/clientsSlice';
import { fetchReports } from '../../../app/slices/reportsSlice';
import Headline from '../../atoms-v2/Headline';
import PageContent from '../../page/PageContent';
import PageHeading from '../../page/PageHeading';
import QMFinishedReports from '../../qm-shared/QMFinishedReports';
import QMHistoryVisualization from '../../qm/QMHistoryVisualization';

import QMAdminClientInfoBox from './QMAdminClientInfoBox';

const QMAdminClientReports: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId: clientIdString } = useParams();
  const clientId = clientIdString ? parseInt(clientIdString) : 0;
  const { client, company } = useClient(clientId);

  const [finishedReportIds, setReportIds] = useState<number[]>([]);
  const reports = entitySelectors.selectByIds<ReportEntity>('reports', finishedReportIds);

  const fetchReportsData = useCallback(async () => {
    if (clientId) {
      const res = await dispatch(
        fetchReports({
          filters: {
            status: 'finished',
            client: {
              id: clientId,
            },
          },
          populate: {
            reportrooms: {
              populate: {
                roomgroup: '*',
                checkresults: {
                  populate: {
                    checkitem: '*'
                  }
                },
              },
            },
          },
          pagination: {
            limit: -1,
          },
          sort: 'date:ASC',
        })
      );
      setReportIds(map((res?.payload?.data || []), (entry) => entry.id));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    fetchReportsData();
  }, [fetchReportsData]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClient(clientId, { populate: '*' }));
    }
  }, [dispatch, clientId]);

  if (!client || !company) {
    return null;
  }

  return (
    <>
      <PageHeading
        heading={company.number}
        subheading={company.name}
        backlinkLabel={t('common.actions.back') || undefined}
        backlinkPath={pathToQMAdminCompany(company.id)}
      />
      <PageContent marginTop="l">
        <QMAdminClientInfoBox clientId={client.id} />

        <UiDiv paddingTop="l">
          <QMFinishedReports reportIds={finishedReportIds} />
        </UiDiv>

        {finishedReportIds.length > 1 && (
          <>
            <UiDiv paddingTop="l">
              <Headline color="white" textAlign="center">
                {t('report.headline.history')}
              </Headline>
            </UiDiv>
            <UiDiv paddingTop="m">
              <QMHistoryVisualization
                reports={reports}
                // reports={[
                //   { id: '0', scorePercentage: 100, date: '2022-06-06T00:00:00.000Z', status: 'finished' },
                //   { id: '1', scorePercentage: 89, date: '2022-06-07T00:00:00.000Z', status: 'finished' },
                //   { id: '2', scorePercentage: 75, date: '2022-06-08T00:00:00.000Z', status: 'finished' },
                //   { id: '3', scorePercentage: 26, date: '2022-06-09T00:00:00.000Z', status: 'finished' },
                //   { id: '4', scorePercentage: 26, date: '2022-06-10T00:00:00.000Z', status: 'finished' },
                //   { id: '5', scorePercentage: 72, date: '2022-06-11T00:00:00.000Z', status: 'finished' },
                //   { id: '6', scorePercentage: 56, date: '2022-06-12T00:00:00.000Z', status: 'finished' },
                //   { id: '7', scorePercentage: 90, date: '2022-06-13T00:00:00.000Z', status: 'finished' },
                //   { id: '8', scorePercentage: 8, date: '2022-06-14T00:00:00.000Z', status: 'finished' },
                // ]}
              />
            </UiDiv>
          </>
        )}
      </PageContent>
    </>
  );
};

export default QMAdminClientReports;
