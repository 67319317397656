import { UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';

interface JobCardStatusProps {
  type: 'job' | 'offer' | 'certificate';
  status?: JobStatus | JobOfferStatus;
}

const JobCardStatus: React.FC<JobCardStatusProps> = ({ type, status }) => {
  let backgroundColor = 'grey20';
  let textColor = 'ciBlue1';
  let letter: 'J' | 'A' | 'L';

  if (status === 'A-bestaetigt' || status === 'LN-bestaetigt') {
    backgroundColor = 'trafficLightGreen';
    textColor = 'white';
  } else if (status === 'A-ausstehend' || status === 'LN-ausstehend') {
    backgroundColor = 'trafficLightYellow';
    textColor = 'white';
  } else if (status === 'A-abgelehnt' || status === 'LN-abgelehnt') {
    backgroundColor = 'trafficLightRed';
    textColor = 'white';
  }

  switch (type) {
    case 'job':
      letter = 'J';
      break;
    case 'offer':
      letter = 'A';
      break;
    case 'certificate':
      letter = 'L';
      break;
  }

  return (
    <UiDiv
      flex="0 0 32px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="32px"
      height="32px"
      backgroundColor={backgroundColor}
      borderRadius="16px"
    >
      <UiText variant="copySmall" fontWeight="700" color={textColor} textAlign="center">
        {letter}
      </UiText>
    </UiDiv>
  );
};

export default JobCardStatus;
