import React from 'react';
import styled, { CSSObject } from 'styled-components';

interface StyledButtonProps {
  iconSize?: 'large';
  isIconOnly?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>(({ iconSize, isIconOnly, theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    minWidth: '64px',
    minHeight: '63px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    border: 0,
    borderRadius: 0,
    padding: isIconOnly ? 0 : '0 16px',
    margin: 0,
    background: theme.colors.ciBlue3,
    ...(theme.type.button as CSSObject),
    fontSize: '15px',
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    svg: {
      width: iconSize === 'large' ? '24px' : '16px',
      height: iconSize === 'large' ? '24px' : '16px',
      verticalAlign: 'top',
      flexShrink: 0,
    },
    '&:hover': {
      background: theme.colors.ciBlue2,
    },
    '&:active': {
      background: theme.colors.ciBlue1,
    },
    '&[data-active="true"]': {
      background: theme.colors.ciBlue1,
    },
    [theme.mediaQueries.tablet]: {
      minWidth: '84px',
      padding: isIconOnly ? 0 : '0 30px',
      fontSize: '16px',
    },
  };

  return baseStyle;
});

interface MenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  iconSize?: 'large';
  active?: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({ icon, iconSize, active, type, children, ...props }) => {
  const isIconOnly = !!icon && !children;

  const dataAttributes = {
    'data-active': active,
  };

  return (
    <StyledButton iconSize={iconSize} isIconOnly={isIconOnly} type={type || 'button'} {...props} {...dataAttributes}>
      {icon}
      {children}
    </StyledButton>
  );
};

export default MenuButton;
