import { entitySelectors } from '../entitySelectors';

export const useSchedule = (
  scheduleId: number | undefined
): {
  schedule: ScheduleEntity | undefined;
  client: ClientEntity | undefined;
  users: UserEntity[];
  contacts: ContactEntity[];
  reports: ReportEntity[];
} => {
  const schedule = entitySelectors.selectById<ScheduleEntity>('schedules', scheduleId);
  const client = entitySelectors.selectById<ClientEntity>('clients', schedule?.clientId);
  const users = entitySelectors.selectByIds<UserEntity>('users', schedule?.userIds);
  const contacts = entitySelectors.selectByIds<ContactEntity>('contacts', schedule?.contactIds);
  const reports = entitySelectors.selectByIds<ReportEntity>('reports', schedule?.reportIds);

  return {
    schedule,
    client,
    users,
    contacts,
    reports,
  };
};
