import React from 'react';
import { UiText, UiTextProps } from '@webfox-sc/core';

const TextBox: React.FC<UiTextProps> = (props) => {
  return (
    <UiText variant="copy" boxSizing="border-box" minHeight="50px" padding="14px 11px" background="white" {...props} />
  );
};

export default TextBox;
