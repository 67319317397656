import { UiDiv } from '@webfox-sc/core';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../app/appHooks';
import { useJob } from '../../app/entityHooks/useJob';
import { fetchClient } from '../../app/slices/clientsSlice';
import { updateJob } from '../../app/slices/jobsSlice';
import { fetchServices } from '../../app/slices/servicesSlice';
import IconPlus from '../../assets/icons-v2/IconPlus';
import JobUtil from '../../utils/JobUtil';
import Button from '../atoms-v2/Button';

import JobServiceEditor from './JobServiceEditor';

const defaultJobservice: JobServiceData = {
  serviceId: undefined,
  type: '',
  serviceDescription: '',
  va: '',
  dla: '',
  unit: '',
  unitPrice: undefined,
  quantity: undefined,
  comments: '',
  location: '',
  person: '',
};

interface JobServicesProps {
  jobId?: number | undefined;
  clientId?: number;
  isJobCreateMode?: boolean;
  isJobEditMode?: boolean;
  onServicesUpdated: (jobServicesUpdated: JobServiceData[]) => void;
}

const JobServices: React.FC<JobServicesProps> = ({
  jobId,
  clientId,
  isJobCreateMode,
  isJobEditMode,
  onServicesUpdated,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { job: jobDraft, recipient: jobRecipientDraft, certificate: certificateDraft } = useJob(jobId);

  const validClientId = clientId || jobRecipientDraft?.clientId;

  const [serviceEditIndex, setServiceEditIndex] = useState(-1);
  const [isCreateMode, setIsCreateMode] = useState(false);

  const [jobServices, setJobServices] = useState<JobServiceData[]>([]);
  const [newJobService, setNewJobService] = useState<JobServiceData>({ ...defaultJobservice });

  const getPositionOnAdd = useCallback((currentJobServices?: JobServiceData[]) => {
    if (!currentJobServices) {
      return 10;
    }
    let position = 0;
    currentJobServices.forEach((jobService) => {
      if ((jobService.position || 0) > position) {
        position = jobService.position || 0;
      }
    });
    position = Math.floor((position + 10) / 10) * 10;
    return position;
  }, []);

  useEffect(() => {
    if (!isJobEditMode) {
      setIsCreateMode(false);
    }
  }, [isJobEditMode]);

  useEffect(() => {
    if (isJobCreateMode && !jobServices.length) {
      setIsCreateMode(true);
    }
  }, [isJobCreateMode, jobServices]);

  useEffect(() => {
    if (validClientId) {
      dispatch(
        fetchClient(validClientId, {
          populate: {
            services: '*',
            client_group: {
              populate: {
                services: '*',
              },
            },
          },
        })
      );
    }
  }, [dispatch, validClientId]);

  useEffect(() => {
    if (isJobEditMode || isJobCreateMode) {
      dispatch(
        fetchServices({
          client: {
            id: { $null: true },
          },
        })
      );
    }
  }, [isJobEditMode, isJobCreateMode, dispatch]);

  useEffect(() => {
    if (jobDraft) {
      setJobServices(jobDraft.services || []);
    }
  }, [jobDraft]);

  useEffect(() => {
    if (clientId) {
      setNewJobService({ ...defaultJobservice, position: getPositionOnAdd() });
      setJobServices([]);
    }
  }, [clientId, getPositionOnAdd]);

  const handleOnClickAdd = () => {
    setServiceEditIndex(-1);
    setNewJobService({ ...defaultJobservice, position: getPositionOnAdd(jobServices) });
    setIsCreateMode(true);
  };

  const handleOnCreateService = async (jobServiceNew: JobServiceData) => {
    const jobServicesUpdated = [...jobServices];
    jobServicesUpdated.push(jobServiceNew);

    /**
     * job create mode
     */
    if (isJobCreateMode) {
      setJobServices(jobServicesUpdated);
    }

    /**
     * job edit mode
     */
    if (isJobEditMode && !!jobDraft) {
      await dispatch(
        updateJob(jobDraft.id, {
          services: jobServicesUpdated,
          totalPrice: JobUtil.getServicesTotalNetPrice(jobServicesUpdated),
        })
      );
    }

    setIsCreateMode(false);
    onServicesUpdated(jobServicesUpdated);
  };

  const handleOnUpdateService = async (index, jobServiceUpdated) => {
    if (jobServices[index] && !isEqual(jobServices[index], jobServiceUpdated)) {
      const jobServicesUpdated = [...jobServices];
      jobServicesUpdated[index] = jobServiceUpdated;

      /**
       * job create mode
       */
      if (isJobCreateMode) {
        setJobServices(jobServicesUpdated);
      }

      /**
       * job edit mode
       */
      if (isJobEditMode) {
        if (jobDraft) {
          await dispatch(
            updateJob(jobDraft.id, {
              services: jobServicesUpdated,
              totalPrice: JobUtil.getServicesTotalNetPrice(jobServicesUpdated),
            })
          );
        }
      }

      onServicesUpdated(jobServicesUpdated);
    }
    setServiceEditIndex(-1);
  };

  const handleOnDeleteService = async (index) => {
    const jobServicesUpdated = [...jobServices];
    jobServicesUpdated.splice(index, 1);

    /**
     * job create mode
     */
    if (isJobCreateMode) {
      setJobServices(jobServicesUpdated);
      onServicesUpdated(jobServicesUpdated);
    }

    /**
     * job edit mode
     */
    if (isJobEditMode && !!jobDraft) {
      if (window.confirm(t('job.serviceForm.prompts.delete') || undefined)) {
        await dispatch(
          updateJob(jobDraft.id, {
            services: jobServicesUpdated,
            totalPrice: JobUtil.getServicesTotalNetPrice(jobServicesUpdated),
          })
        );
        onServicesUpdated(jobServicesUpdated);
      }
    }
  };

  return (
    <>
      <UiDiv display="flex" flexDirection="column" rowGap="xs">
        {jobServices.map((jobService, index) => {
          return (
            <JobServiceEditor
              key={String(index)}
              jobService={jobService}
              clientId={validClientId}
              countJobServices={jobServices.length}
              isJobCreateMode={isJobCreateMode}
              isJobEditMode={isJobEditMode}
              serviceEditIndex={serviceEditIndex}
              isEditMode={(isJobCreateMode || isJobEditMode) && serviceEditIndex === index}
              certificateEntryId={certificateDraft?.entryId}
              onClickEdit={() => setServiceEditIndex(index)}
              onClickSubmit={(jobServiceUpdated) => handleOnUpdateService(index, jobServiceUpdated)}
              onClickCancel={() => setServiceEditIndex(-1)}
              onClickDelete={() => handleOnDeleteService(index)}
            />
          );
        })}
      </UiDiv>

      {(isJobCreateMode || isJobEditMode) && (
        <UiDiv marginTop="xs">
          {!isCreateMode && (
            <UiDiv display="flex" justifyContent="center">
              <Button icon={IconPlus} onClick={handleOnClickAdd}>
                {t('job.serviceForm.actions.add')}
              </Button>
            </UiDiv>
          )}
          {isCreateMode && (
            <JobServiceEditor
              jobService={newJobService}
              positionDefault={getPositionOnAdd(jobServices)}
              clientId={validClientId}
              countJobServices={jobServices.length}
              isJobCreateMode={isJobCreateMode}
              isJobEditMode={isJobEditMode}
              serviceEditIndex={serviceEditIndex}
              isEditMode={isCreateMode}
              onClickSubmit={(jobServiceNew) => handleOnCreateService(jobServiceNew)}
              onClickCancel={() => setIsCreateMode(false)}
            />
          )}
        </UiDiv>
      )}
    </>
  );
};

export default JobServices;
