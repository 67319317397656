import { UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCheckitemtypeOptions } from '../../../app/entityHooks/useCheckitemtypeOptions';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import Button from '../../atoms-v2/Button';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import TextInput from '../../atoms-v2/TextInput';
import CustomReactSelect from '../../CustomReactSelect';

interface QMAdminConfigCheckitemFormProps {
  checkitem?: CheckitemNestedEntity;
  onSave: (value: any) => void;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
  showDelete?: boolean | undefined;
}

const QMAdminConfigCheckitemForm: React.FC<QMAdminConfigCheckitemFormProps> = ({
  checkitem,
  onSave,
  onClickCancel,
  showDelete,
}) => {
  const { t } = useTranslation();
  const { checkitemtypeId, question, weight } = checkitem || {};
  const { checkitemtypeOptions } = useCheckitemtypeOptions();
  const [checkitemtypeValue, setCheckitemtypeValue] = useState(checkitemtypeId);
  const [questionValue, setQuestionValue] = useState(question);
  const [weightValue, setWeightValue] = useState(weight);

  const isFormDataValid = () => {
    return !!checkitemtypeValue && (weightValue || 0) > 0 && (weightValue || 0) < 100;
  };

  const handleOnSave = () => {
    onSave({ checkitemtypeId: checkitemtypeValue, question: questionValue, weight: weightValue });
  };

  const handleOnDelete = () => {
    onSave(null);
  };

  return (
    <>
      <UiDiv display="flex" gap="xs">
        <UiDiv flexGrow={1}>
          <FormElementLabel>{t('common.fields.name')}</FormElementLabel>
          <CustomReactSelect
            options={checkitemtypeOptions}
            selectedValue={checkitemtypeValue}
            isSearchable
            onChangeValue={(value) => setCheckitemtypeValue(value)}
            placeholder={t('common.actions.select')}
          />
        </UiDiv>
        <UiDiv>
          <FormElementLabel>{t('checkitem.weight')}</FormElementLabel>
          <TextInput
            value={weightValue}
            type="number"
            onChange={(event) => {
              setWeightValue(parseInt(event.target.value));
            }}
          />
        </UiDiv>
      </UiDiv>
      <UiDiv marginTop="xs">
        <FormElementLabel>{t('checkitem.common.question')}</FormElementLabel>
        <TextInput
          value={questionValue}
          onChange={(event) => {
            setQuestionValue(event.target.value);
          }}
        />
      </UiDiv>
      <UiDiv display="flex" marginTop="s" justifyContent="space-between" gap="xs">
        <UiDiv display="flex" columnGap="xs">
          <Button icon={IconCheck} disabled={!isFormDataValid()} onClick={handleOnSave}>
            {t('common.actions.save')}
          </Button>
          <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
            {t('common.actions.cancel')}
          </Button>
        </UiDiv>

        {showDelete && (
          <Button icon={IconDelete} onClick={handleOnDelete} variant="secondary">
            {t('common.actions.delete')}
          </Button>
        )}
      </UiDiv>
    </>
  );
};

export default QMAdminConfigCheckitemForm;
