import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { fileSchema } from '../schemas';

export const uploadFiles = (data: FormData): RequestEntitiesAction<FileData[]> => {
  return requestEntities({
    method: 'POST',
    path: '/upload',
    data,
    schema: [fileSchema],
  });
};

const filesAdapter = createEntityAdapter<FileEntity>();

export const filesSlice = createSlice({
  name: 'files',
  initialState: filesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.files) {
        filesAdapter.upsertMany(state, action.payload.entities.files);
      }
    });
  },
});

export default filesSlice.reducer;
