import { UiDiv, UiText } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import Button from '../atoms-v2/Button';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import TextArea from '../atoms-v2/TextArea';


interface JobOfferReject {
  onClickCancel: () => void;
  onClickReject: (reason: string) => void;
}

const LandingOfferReject: React.FC<JobOfferReject> = ({ onClickCancel, onClickReject }) => {
  const { t } = useTranslation();

  const [reason, setReason] = useState('');

  const handleOnClickReject = () => {
    onClickReject(reason);
  };

  return (
    <UiDiv background="grey20" padding="s">
      <UiText variant="h3" color="ciRed" textAlign="center">
        {t('landing.offer.reject.headline')}
      </UiText>

      <UiDiv marginTop="s">
        <FormElementLabel>{t('landing.certificate.reject.reason')} *</FormElementLabel>
        <TextArea value={reason} onChange={(e) => setReason(e.target.value)} />
      </UiDiv>

      <UiDiv
        display="flex"
        flexDirection={{ mobile: 'column', tablet: 'row' }}
        justifyContent="center"
        gap="xs"
        marginTop="s"
      >
        <Button icon={IconCheck} disabled={!reason} onClick={handleOnClickReject}>
          {t('common.actions.reject')}
        </Button>
        <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
          {t('common.actions.cancel')}
        </Button>
      </UiDiv>
    </UiDiv>
  );
};

export default LandingOfferReject;
