const IconUpload = (
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M73.37,100H26.63c-1.57,0-3.08-.64-4.2-1.77-1.11-1.13-1.74-2.67-1.74-4.27s.63-3.14,1.74-4.27c1.11-1.13,2.62-1.77,4.2-1.77h46.74c1.57,0,3.08,.64,4.2,1.77,1.11,1.13,1.74,2.67,1.74,4.27s-.63,3.14-1.74,4.27c-1.11,1.13-2.62,1.77-4.2,1.77ZM45.74,1.77L22.36,25.59c-1.08,1.14-1.68,2.67-1.67,4.25,.01,1.58,.64,3.1,1.74,4.22,1.1,1.12,2.59,1.76,4.15,1.77,1.56,.01,3.06-.6,4.18-1.7l13.24-13.47v53.43c0,1.6,.63,3.14,1.74,4.27,1.11,1.13,2.62,1.77,4.2,1.77s3.08-.64,4.2-1.77c1.11-1.13,1.74-2.67,1.74-4.27V20.63l13.24,13.47c1.12,1.13,2.63,1.77,4.2,1.77,1.58,0,3.09-.64,4.2-1.78,1.11-1.14,1.74-2.67,1.74-4.28,0-1.6-.63-3.14-1.74-4.28L54.14,1.77C53.03,.64,51.52,0,49.94,0c-1.57,0-3.08,.64-4.2,1.77Z"
    />
  </svg>
);

export default IconUpload;
