import { InlineSVG, UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useTheme } from 'styled-components';

interface IconButtonProps {
  icon?: React.ReactNode;
  color?: string;
}

const TextIcon: React.FC<IconButtonProps> = ({ icon, color }) => {
  const { colors } = useTheme();
  return (
    <UiDiv display="inline-flex" width="16px" height="22px" alignItems="center">
      <InlineSVG width="16px" height="16px" color={color || colors.ciBlue1}>
        {icon}
      </InlineSVG>
    </UiDiv>
  );
};

export default TextIcon;
