import { UiDiv, UiFlex, UiText } from '@webfox-sc/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/appHooks';
import { useCrafts } from '../../../app/entityHooks/useCrafts';
import { pathToQMAdmin, pathToQMAdminCompanyConfig, pathToQMAdminCompanyConfigAdd } from '../../../app/paths';
import { fetchCompany } from '../../../app/slices/companiesSlice';
import { fetchCrafts } from '../../../app/slices/craftsSlice';
import { fetchGlobalRoomgroups } from '../../../app/slices/roomgroupsSlice';
import { fetchUsers } from '../../../app/slices/usersSlice';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import QMUtil from '../../../utils/QMUtil';
import Button from '../../atoms-v2/Button';
import ContentBox from '../../atoms-v2/ContentBox';
import PageContent from '../../page/PageContent';
import PageHeading from '../../page/PageHeading';
import QMAdminConfigCounts from '../qm-admin-config-counts/QMAdminConfigCounts';
import QMAdminConfigSpecificityLabel from '../qm-admin-shared/QMAdminConfigSpecificityLabel';
import { useQMAdminCompany } from '../useQMAdminCompany';
import QMAdminCompanyClient from './QMAdminCompanyClient';
import QMAdminCompanyClients from './QMAdminCompanyClients';
import QMAdminCompanyContacts from './QMAdminCompanyContacts';
import _, { map } from 'lodash';

const QMAdminCompany: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyId: companyIdString } = useParams();
  const companyId = companyIdString ? parseInt(companyIdString) : -1;
  const { company, clientsWithSchedule, specificityByCraft, schedules } = useQMAdminCompany(companyId);
  const { crafIds } = useCrafts();

  useEffect(() => {
    const fetchData = async () => {
      if (companyId) {
        dispatch(fetchGlobalRoomgroups());
        dispatch(fetchCrafts());
        dispatch(fetchUsers({ _limit: -1 }));
        dispatch(
          fetchCompany(companyId, {
            populate: _.merge(QMUtil.companyPopulateOptions, {
              clients: {
                populate: {
                  schedules: {
                    populate: {
                      craft: {
                        fields: ['id', 'name'],
                      },
                    },
                  },
                },
              },
            }),
          })
        );
      }
    };
    fetchData();
  }, [dispatch, companyId]);

  if (!company) {
    return null;
  }

  // add one to account for unspecific list
  const hasCraftsLeft = Object.keys(specificityByCraft).length < crafIds.length + 1;

  return (
    <>
      <PageHeading
        heading={company.number}
        subheading={company.name}
        backlinkLabel={t('common.actions.toOverview') || undefined}
        backlinkPath={pathToQMAdmin()}
      />
      <PageContent marginTop="l">
        <UiFlex flexDirection="column" rowGap="l">
          <UiDiv>
            <UiText variant="h2" color="white" textAlign="center">
              {t('common.entities.report.singular')}
            </UiText>
            <UiDiv marginTop="m" display="flex" flexDirection="column" gap="xs">
              {map(specificityByCraft, (specificityEntry) => (
                <ContentBox
                  variant="white"
                  withLink
                  linkHref={pathToQMAdminCompanyConfig(company.id, specificityEntry.craftId || 0)}
                  onClickLink={() => navigate(pathToQMAdminCompanyConfig(company.id, specificityEntry.craftId || 0))}
                  key={`craft_${specificityEntry.craftId}`}
                >
                  <UiDiv display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} columnGap="xs">
                    <strong>
                      <QMAdminConfigSpecificityLabel {...specificityEntry} />:
                    </strong>
                    <QMAdminConfigCounts {...specificityEntry} />
                  </UiDiv>
                </ContentBox>
              ))}
              {hasCraftsLeft && (
                <UiFlex justifyContent="center" marginTop="s">
                  <Button
                    variant="primary"
                    icon={IconPlus}
                    onClick={() => navigate(pathToQMAdminCompanyConfigAdd(company.id))}
                  >
                    {t('common.actions.add')}
                  </Button>
                </UiFlex>
              )}
            </UiDiv>
          </UiDiv>

          <UiDiv>
            <UiText variant="h2" color="white" textAlign="center" marginBottom="m">
              {t('schedule.contacts')}
            </UiText>
            <QMAdminCompanyContacts companyId={companyId} />
          </UiDiv>

          <UiDiv>
            <QMAdminCompanyClients companyId={companyId} />
          </UiDiv>

          <UiDiv>
            <UiText variant="h2" color="white" textAlign="center">
              {schedules.length}{' '}
              {schedules.length === 1 ? t('common.entities.schedule.singular') : t('common.entities.schedule.plural')}
            </UiText>

            {clientsWithSchedule.length > 0 && (
              <UiDiv display="flex" flexDirection="column" rowGap="xs" marginTop="m">
                {clientsWithSchedule.map((client) => {
                  return <QMAdminCompanyClient key={client.id} clientId={client.id} />;
                })}
              </UiDiv>
            )}
          </UiDiv>
        </UiFlex>
      </PageContent>
    </>
  );
};

export default QMAdminCompany;
