import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { checkitemgroupSchema } from '../schemas';

const populateOptions = {
  checkitems: {
    populate: '*',
  },
  roomgroup: {
    populate: {
      checkitemgroups: {
        populate: '*',
      },
    },
  },
}

export const updateCheckitemgroup = (
  checkitemgroupId: number,
  data: CheckitemgroupUpdateData
): RequestEntitiesAction<CheckitemgroupData> =>
  requestEntities({
    method: 'PUT',
    path: `/checkitemgroups/${checkitemgroupId}`,
    params: {
      populate: populateOptions,
    },
    data,
    schema: checkitemgroupSchema,
  });

export const createCheckitemgroup = (data: CheckitemgroupUpdateData): RequestEntitiesAction<CheckitemgroupData> =>
  requestEntities({
    method: 'POST',
    path: '/checkitemgroups',
    params: {
      populate: populateOptions,
    },
    data,
    schema: checkitemgroupSchema,
  });

export const deleteCheckitemgroup = (checkitemgroupId: number): RequestEntitiesAction<CheckitemgroupData> =>
  requestEntities({
    method: 'DELETE',
    path: `/checkitemgroups/${checkitemgroupId}`,
    schema: checkitemgroupSchema,
    type: 'deleteCheckitemgroup',
  });

const checkitemgroupsAdapter = createEntityAdapter<CheckitemgroupEntity>();

export const checkitemgroupsSlice = createSlice({
  name: 'checkitemgroups',
  initialState: checkitemgroupsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.checkitemgroups) {
        checkitemgroupsAdapter.upsertMany(state, action.payload.entities.checkitemgroups);
      }
      if (action.meta.arg.type === 'deleteCheckitemgroup') {
        checkitemgroupsAdapter.removeOne(state, action.payload.data.id);
      }
    });
  },
});

export default checkitemgroupsSlice.reducer;
