import { entitySelectors } from '../entitySelectors';

export const useRoomgroup = (
  roomgroupId: number | undefined
): {
  roomgroup: RoomgroupEntity | undefined;
  checkitemgroups: CheckitemgroupEntity[];
} => {
  const roomgroup = entitySelectors.selectById<RoomgroupEntity>('roomgroups', roomgroupId);
  const checkitemgroups = entitySelectors.selectByIds<CheckitemgroupEntity>(
    'checkitemgroups',
    roomgroup?.checkitemgroupIds
  );

  return {
    roomgroup,
    checkitemgroups,
  };
};
