/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconDelete from '../../assets/icons-v2/IconDelete';
import ButtonPrimary from '../../atoms/ButtonPrimary';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import Section from '../../atoms/Section';
import { createClientGroup, deleteClientGroup, updateClientGroup } from '../../redux-actions/actionsClients';
import ClientsUtil from '../../utils/ClientsUtil';
import { getEntryLabel, stripObject } from '../../utils/FilterUtil';
import FormPanel from '../atoms-v2/FormPanel';
import CustomReactSelect from '../CustomReactSelect';
import Advice from '../elements/Advice';
import BasicButtonGroup from '../elements/BasicButtonGroup';
import BasicInput from '../elements/BasicInput';
import FormCell from '../elements/FormCell';
import FormInfo from '../elements/FormInfo';
import FormLabel from '../elements/FormLabel';
import FormRow from '../elements/FormRow';
import ToggleBox from '../elements/ToggleBox';
import { useToastContext } from '../toast/ToastProvider';

const ClientGroupEditor = ({
  model = {},
  isError,
  adviceText: initialAdviceText,
  showAdvice: hasAdvice,
  isUpdateMode,
  initModel,
  onCancel,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = useToastContext();

  const [rawModel, setRawModel] = useState(model);
  const [modelState, setModelState] = useState(model);

  const [showAdvice, setShowAdvice] = useState(hasAdvice || model.isModified || model.isNew);

  useEffect(() => {
    if (model !== rawModel) {
      if (model) {
        setModelState(model);
        if (model.isModified || model.isNew) {
          setShowAdvice(true);
        }
        setRawModel(model);
      } else {
        const emptyModel = ClientsUtil.getEmptyClient();
        setModelState(emptyModel);
        setRawModel(emptyModel);
      }
    }
  }, [model]);

  const handleChangeFormValue = (name, value) => {
    setModelState({
      ...modelState,
      [name]: value,
    });
  };

  const handleOnClickCreate = async () => {
    const strapiData = ClientsUtil.getAPIData(modelState);
    const newClient = await dispatch(createClientGroup(strapiData));
    onCreate(newClient);

    const emptyModel = initModel();
    setModelState(emptyModel);
    setRawModel(emptyModel);
  };

  const handleOnClickUpdate = async () => {
    const strapiData = ClientsUtil.getAPIData(modelState);
    await dispatch(updateClientGroup(model.id, strapiData));
    onUpdate();
  };

  const handleOnClickDelete = async () => {
    // eslint-disable-next-line no-alert
    if (
      // eslint-disable-next-line no-alert
      window.confirm(t('clientGroup.prompts.delete'))
    ) {
      const deletedClients = await dispatch(deleteClientGroup(modelState.id));
      onDelete(deletedClients);
      showToast(t('clientGroup.success.deleted'), 'success');
    }
  };

  const handleOnClickAdvice = () => {
    setShowAdvice(false);
  };

  const handleChangeClients = async (values) => {
    setModelState({
      ...modelState,
      clients: [...values],
      clientIds: values.map(({ value: id }) => id),
    });
  };

  const { clients, debitorId, debitorIdOld, isModified, isNew } = modelState;

  let isSuccess = false;
  let isInfo = false;
  let adviceText = initialAdviceText;

  if (isModified) {
    isInfo = true;
    adviceText = t('client.messages.changed');
  }

  if (isNew) {
    isSuccess = true;
    adviceText = t('client.messages.new');
  }

  let currentOptions = null;
  if (clients) {
    currentOptions = clients.map((client) => ({
      value: client.id || client.value,
      label: getEntryLabel('costcenterClient', client),
      company: stripObject(client.company),
      location: stripObject(client.location),
      costcenter: stripObject(client.costcenter),
    }));
  }

  const isDataValid = !!debitorId;

  return (
    <FormPanel>
      <ToggleBox show={showAdvice}>
        <Section paddingBottom="S">
          <Advice
            text={adviceText}
            isSuccess={isSuccess}
            isInfo={isInfo}
            isError={isError}
            onClick={handleOnClickAdvice}
          />
        </Section>
      </ToggleBox>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="6">
            <FormLabel text={t('clientGroup.headline.singular')} />
            <BasicInput
              type="text"
              value={debitorId || ''}
              onChange={(value) => handleChangeFormValue('debitorId', value)}
            />
            {debitorIdOld && <FormInfo text={debitorIdOld} />}
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="S">
        <FormRow>
          <FormCell cols="12">
            <FormLabel text="Objekte" />
            <div className="smg-costcenter-editor__dropdown">
              <CustomReactSelect
                isMulti
                asyncDefaultOptions={currentOptions}
                selectedOption={currentOptions}
                asyncApiPath="/clients"
                asyncLimit={500}
                asyncSort="number:asc"
                asyncSetParams={(inputValue) =>
                  ClientsUtil.getClientGroupSearchParams(inputValue, {
                    populate: ['location', 'company'],
                  })
                }
                asyncFormatOption={(rawClient) => {
                  const cl = ClientsUtil.parseData(rawClient);
                  const entry = {
                    company: stripObject(cl.company),
                    location: stripObject(cl.location),
                  };
                  return {
                    value: cl.id,
                    label: getEntryLabel('costcenterClient', entry),
                    ...entry,
                  };
                }}
                asyncInputMinLength={3}
                isClearable
                isSearchable
                placeholder={t('common.form.search')}
                onChange={handleChangeClients}
              />
            </div>
          </FormCell>
        </FormRow>
      </Section>
      <Section paddingTop="M">
        <FormRow>
          <FormCell cols="8">
            <BasicButtonGroup>
              {!isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.create')}
                  icon={IconCheck}
                  disabled={!isDataValid}
                  onClick={handleOnClickCreate}
                />
              )}
              {isUpdateMode && (
                <ButtonPrimary
                  label={t('common.actions.apply')}
                  icon={IconCheck}
                  disabled={!isDataValid}
                  onClick={handleOnClickUpdate}
                />
              )}
              <ButtonSecondary label={t('common.actions.close')} icon={IconClose} onClick={onCancel} />
            </BasicButtonGroup>
          </FormCell>
          {isUpdateMode && (
            <FormCell cols="4" align="right">
              <ButtonSecondary label={t('clientGroup.actions.delete')} icon={IconDelete} onClick={handleOnClickDelete} />
            </FormCell>
          )}
        </FormRow>
      </Section>
    </FormPanel>
  );
};

ClientGroupEditor.propTypes = {
  model: PropTypes.object,
  isUpdateMode: PropTypes.bool,
  showAdvice: PropTypes.bool,
  adviceText: PropTypes.string,
  isError: PropTypes.bool,
  initModel: PropTypes.func,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

ClientGroupEditor.defaultProps = {
  model: null,
  isUpdateMode: false,
  showAdvice: false,
  adviceText: '',
  isError: false,
  initModel: () => {},
  onCancel: () => false,
  onCreate: () => false,
  onUpdate: () => false,
  onDelete: () => false,
};

export default ClientGroupEditor;
