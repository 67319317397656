import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from './useApi';
import UserModel from '../models/UserModel';
import { updateUserModels, updateIsLoadingUsers } from '../redux-actions/actionsUsers';

export const useUserActions = () => {
  const dispatch = useDispatch();
  const { apiGet } = useApi();

  const fetchUsers = useCallback(async () => {
    const res = await apiGet({
      path: '/users',
      params: {
        _limit: -1,
      },
      onRequest: () => {
        dispatch(updateIsLoadingUsers(true));
      },
      onResponse: (response) => {
        const usersData = response?.data || [];

        /**
         * create and persist user models
         */
        const userModels = [];
        usersData.forEach((data) => {
          userModels.push(new UserModel(data));
        });
        dispatch(updateUserModels(userModels));

        dispatch(updateIsLoadingUsers(false));
      },
      onError: () => {
        dispatch(updateIsLoadingUsers(false));
      },
    });

    return res?.data;
  }, [dispatch, apiGet]);

  return {
    fetchUsers,
  };
};
