/* xeslint-disable */
class FormatUtil {
    static getDecimalFormatted(amount, decimalCount = 2, decimal = ',', thousands = '.') {
        let _amount = amount;
        let _decimalCount = Math.abs(decimalCount);
        _decimalCount = Number.isNaN(_decimalCount) ? 2 : _decimalCount;

        const negativeSign = _amount < 0 ? '-' : '';

        const i = parseInt((_amount = Math.abs(Number(_amount) || 0).toFixed(_decimalCount)), 10).toString();
        const j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
            (_decimalCount
                ? decimal +
                  Math.abs(_amount - i)
                      .toFixed(_decimalCount)
                      .slice(2)
                : '')
        );
    }

    static getBooleanFormatted(value) {
        return value ? 'Ja' : 'Nein';
    }
}

export default FormatUtil;
