import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox } from '@webfox-sc/core';
import TableBodyRow from '../../components/elements/TableBodyRow';
import TableBodyCell from '../../components/elements/TableBodyCell';
import { themeCheckbox } from '../../theme/theme';
import DateUtil from '../../utils/DateUtil';
import { getEntryLabel } from '../../utils/FilterUtil';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';

const ClientGroupListItem = ({ objectId, onClick, isSelected, onSelect }) => {
  const object = useSelector(({ dataState }) => dataState.clients[objectId]);
  if (!object) {
    return null;
  }

  const { debitorId, debitorIdOld, clients, updatedAt, isModified, isNew } = object;

  const clientsString =
    clients && clients.length > 0
      ? clients.map((client) => getEntryLabel('costcenterClient', client)).join(', ')
      : '** Kein Objekt zugeordnet **';

  const updatedAtFormatted = updatedAt ? DateUtil.getDateFormatted(updatedAt) : '';

  return (
    <TableBodyRow isClickable isModified={isModified} isNew={isNew}>
      <TableBodyCell text={debitorId} textOld={debitorIdOld} onClick={onClick} width="15%" />
      <TableBodyCell text={clientsString} onClick={onClick} width="61%" />
      <TableBodyCell text={updatedAtFormatted} onClick={onClick} width="10%" />
      <TableBodyCell width="7%">
        <Checkbox checked={isSelected} onChange={(e) => onSelect(e.target.checked, objectId)} theme={themeCheckbox} />
      </TableBodyCell>
      <TableBodyCell iconSrc={IconArrowDown} />
    </TableBodyRow>
  );
};

ClientGroupListItem.propTypes = {
  objectId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

ClientGroupListItem.defaultProps = {
  onClick: () => {},
  isSelected: PropTypes.bool,
  onSelect: () => false,
};

export default ClientGroupListItem;
