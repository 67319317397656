import { Checkbox, InlineSVG, UiDiv, UiElement, UiFlex, UiSpan, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import { themeCheckbox } from '../../theme/theme';

type JobCardVariant = 'byEntryId' | 'byCarrierNumber' | 'byTotalPrice' | undefined;

interface JobCardProps {
  children: React.ReactNode;
  variant?: JobCardVariant;
  createdAt?: string;
  clientName?: string;
  entryId?: string;
  dlasFormatted?: string;
  carrierNumber?: string;
  href?: string;
  totalPrice?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  useSelect?: boolean;
  isSelected?: boolean;
}

const JobCard: React.FC<JobCardProps> = ({
  variant,
  createdAt,
  clientName,
  entryId,
  dlasFormatted,
  carrierNumber,
  totalPrice,
  href,
  onClick,
  children,
  useSelect,
  isSelected,
}) => {
  const { t } = useTranslation();
  const handleOnClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  };

  let variantString: string;
  let variantValue: any;

  let variantSecondaryString: string;
  let variantSecondaryValue: any;

  switch (variant) {
    case 'byCarrierNumber':
      variantString = t('common.fields.carrierNumber.medium');
      variantValue = carrierNumber;
      variantSecondaryString = t('job.total');
      variantSecondaryValue = totalPrice;
      break;
    case 'byTotalPrice':
      variantString = t('job.total');
      variantValue = totalPrice;
      variantSecondaryString = t('common.fields.dla');
      variantSecondaryValue = dlasFormatted;
      break;
    default:
      variantString = t('common.fields.id');
      variantValue = entryId;
      variantSecondaryString = t('job.total');
      variantSecondaryValue = totalPrice;
      break;
  }

  return (
    <UiElement
      as="a"
      textDecoration="none"
      color="ciBlue1"
      display="grid"
      gridTemplateColumns={{ mobile: '1fr 32px 22px', tablet: '3fr 74px 1fr 22px' }}
      padding="15px"
      background={{ _: 'white', ':hover': 'ciBlue5' }}
      transition="background-color 0.3s ease"
      alignItems="center"
      columnGap="s"
      href={href}
      onClick={handleOnClick}
    >
      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 3fr 1fr' }}
        rowGap="xxs"
        columnGap="s"
        alignItems="center"
      >
        <UiText variant="copySmall">{createdAt}</UiText>
        <UiText variant="copySmall">{clientName}</UiText>
        <UiText variant="copySmall">
          {dlasFormatted && (
            <>
              <UiSpan display={{ mobile: 'inline', tablet: 'none' }}>
                {variantSecondaryString}: {variantSecondaryValue}
              </UiSpan>
              <UiSpan display={{ mobile: 'none', tablet: 'inline' }}>
                {variantSecondaryString}:
                <br />
                {variantSecondaryValue}
              </UiSpan>
            </>
          )}
        </UiText>
        <UiText variant="copySmall" display={{ mobile: 'block', tablet: 'none' }}>
          <b>
            {variantString}: {variantValue}
          </b>
        </UiText>
      </UiDiv>

      <UiDiv display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} rowGap="10px" columnGap="5px">
        {children}
      </UiDiv>

      <UiText variant="copySmall" display={{ mobile: 'none', tablet: 'block' }}>
        <b>
          {variantString}: {variantValue}
        </b>
      </UiText>

      <UiFlex width="22px" height="22px" justifyContent="center" alignItems="center" color="ciBlue3">
        {useSelect ? (
          <Checkbox checked={isSelected} value="" onChange={(e) => e.preventDefault()} theme={themeCheckbox} />
        ) : (
          <InlineSVG width="18px" height="18px">
            {IconArrowRight}
          </InlineSVG>
        )}
      </UiFlex>
    </UiElement>
  );
};

export default JobCard;
