import { UiDiv } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { createCheckitemtype, deleteCheckitemtype, updateCheckitemtype } from '../../../app/slices/checkitemtypesSlice';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconDelete from '../../../assets/icons-v2/IconDelete';
import IconRefresh from '../../../assets/icons-v2/IconRefresh';
import Button from '../../atoms-v2/Button';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import TextInput from '../../atoms-v2/TextInput';
import { useCheckitemtype } from '../../../app/entityHooks/useCheckitemtype';

interface BaseDataQMCheckitemtypeFormProps {
  checkitemtypeId?: number | undefined;
  onSubmit?: React.FormEventHandler<HTMLFormElement | HTMLButtonElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

const BaseDataQMCheckitemtypeForm: React.FC<BaseDataQMCheckitemtypeFormProps> = ({
  checkitemtypeId,
  onSubmit,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formMode = checkitemtypeId ? 'update' : 'create';

  const { checkitemtype } = useCheckitemtype(checkitemtypeId);
  const [checkitemtypeData, setCheckitemtypeData] = useState<CheckitemtypeUpdateData>({});

  useEffect(() => {
    setCheckitemtypeData({
      name: checkitemtype?.name,
      disabled: checkitemtype ? checkitemtype?.disabled : false,
      canDelete: checkitemtype ? checkitemtype?.canDelete : false,
    });
  }, [checkitemtype]);

  const isFormDataValid = () => {
    const { name } = checkitemtypeData;
    return !!name;
  };

  const handleChangeValue = (key: string, value: any) => {
    const checkitemtypeDataUpdated: CheckitemtypeUpdateData = { ...checkitemtypeData };
    checkitemtypeDataUpdated[key] = value;
    setCheckitemtypeData(checkitemtypeDataUpdated);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (formMode === 'create') {
      await dispatch(createCheckitemtype(checkitemtypeData));
    } else if (checkitemtypeId) {
      await dispatch(updateCheckitemtype(checkitemtypeId, checkitemtypeData));
    }
    if (onSubmit) {
      onSubmit(e);
    }
  };

  const handleOnClickDisable: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (checkitemtype) {
      if (window.confirm(t('checkitem.prompts.delete', { name: checkitemtype?.name }) || undefined)) {
        if (checkitemtype) {
          await dispatch(updateCheckitemtype(checkitemtype.id, { ...checkitemtypeData, disabled: true }));
        }
        if (onSubmit) {
          onSubmit(e);
        }
      }
    }
  };

  const handleOnClickDelete: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (checkitemtype) {
      if (window.confirm(t('checkitem.prompts.deleteFinally', { name: checkitemtype?.name }) || undefined)) {
        if (checkitemtype) {
          await dispatch(deleteCheckitemtype(checkitemtype.id));
        }
        if (onSubmit) {
          onSubmit(e);
        }
      }
    }
  };

  const handleOnClickRestore: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (checkitemtype) {
      await dispatch(updateCheckitemtype(checkitemtype.id, { ...checkitemtypeData, disabled: false }));
    }
    if (onSubmit) {
      onSubmit(e);
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <UiDiv>
        <FormElementLabel>{t('common.fields.name')} *</FormElementLabel>
        <TextInput value={checkitemtypeData.name} onChange={(e) => handleChangeValue('name', e.target.value)} />
      </UiDiv>
      <UiDiv display="flex" columnGap="xs" justifyContent="space-between" paddingTop="s">
        <UiDiv display="flex" columnGap="xs">
          <Button type="submit" icon={IconCheck} disabled={!isFormDataValid()}>
            {formMode === 'create' ? 'Erstellen' : 'Übernehmen'}
          </Button>
          <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
            {t('common.actions.cancel')}
          </Button>
        </UiDiv>
        {formMode === 'update' && (
          <>
            {checkitemtypeData.disabled ? (
              <UiDiv display="flex" columnGap="xs">
                <Button icon={IconRefresh} onClick={handleOnClickRestore}>
                  {t('common.actions.restore')}
                </Button>
                <Button
                  icon={IconDelete}
                  variant="secondary"
                  onClick={handleOnClickDelete}
                  disabled={!checkitemtypeData.canDelete}
                >
                  {t('common.actions.deleteFinally')}
                </Button>
              </UiDiv>
            ) : (
              <Button icon={IconDelete} onClick={handleOnClickDisable}>
                {t('common.actions.delete')}
              </Button>
            )}
          </>
        )}
      </UiDiv>
    </form>
  );
};

export default BaseDataQMCheckitemtypeForm;
