import { Block } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { pathToQMReport } from '../../app/paths';
import IconArrowLeft from '../../assets/icons-v2/IconArrowLeft';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconInfo from '../../assets/icons-v2/IconInfo';
import DateUtil from '../../utils/DateUtil';
import IconButton from '../atoms-v2/IconButton';
import ResultBubble from '../atoms-v2/ResultBubble';
import Text from '../atoms-v2/Text';

const DiagonalLine = styled.div<{ direction: '' | 'positive' | 'negative' }>(({ direction }) => {
  const line =
    direction === 'positive'
      ? '<line x1="0" y1="100" x2="100" y2="0" stroke="white" vector-effect="non-scaling-stroke"/>'
      : '<line x1="0" y1="0" x2="100" y2="100" stroke="white" vector-effect="non-scaling-stroke"/>';

  return {
    background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 100 100">${line}</svg>')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '100% 100%, auto',
  };
});

type QMHistoryVisualizationProps = {
  reports: ReportEntity[];
  showOldest: boolean;
};

const QMHistoryVisualizationGraph: React.FC<QMHistoryVisualizationProps> = ({ reports, showOldest }) => {
  const { pathname, search } = useLocation();
  const { colors } = useTheme();
  const numOfVisibleReports = reports.length > 4 ? 4 : reports.length;
  const [visibleReportIndexes, setVisibleReportIndexes] = useState({
    start: reports.length - numOfVisibleReports,
    end: reports.length,
  });
  const visibleReports = reports.slice(visibleReportIndexes.start, visibleReportIndexes.end);

  useEffect(() => {
    setVisibleReportIndexes({
      start: showOldest ? 0 : reports.length - numOfVisibleReports,
      end: showOldest ? numOfVisibleReports : reports.length,
    });
  }, [numOfVisibleReports, showOldest, reports]);

  const getTrend = (reportId: ReportEntity['id']): '' | 'positive' | 'negative' => {
    const curReport = reports.find((report) => report.id === reportId);
    const curReportIndex = reports.findIndex((report) => report.id === reportId);
    const nextReport = reports[curReportIndex + 1];

    if (curReport === undefined || nextReport === undefined) {
      return '';
    }

    return (curReport.scorePercentage || 0) > (nextReport.scorePercentage || 0) ? 'negative' : 'positive';
  };

  const getHeight = (reportId: ReportEntity['id']): string => {
    const curReport = reports.find((report) => report.id === reportId);
    const curReportIndex = reports.findIndex((report) => report.id === reportId);
    const nextReport = reports[curReportIndex + 1];

    if (curReport === undefined || nextReport === undefined) {
      return '';
    }

    const diff = (curReport.scorePercentage || 0) - (nextReport.scorePercentage || 0);

    if (diff === 0) {
      return '1px';
    }

    const positiveDiff = diff < 0 ? diff * -1 : diff;

    return `${positiveDiff}%`;
  };

  return (
    <Block display="flex" alignItems="center" gap="m">
      <Block>
        <IconButton
          icon={IconArrowLeft}
          variant="white"
          disabled={visibleReportIndexes.start === 0}
          onClick={() => {
            setVisibleReportIndexes({
              start: visibleReportIndexes.start - 1,
              end: visibleReportIndexes.end - 1,
            });
          }}
        />
      </Block>
      <Block paddingTop="l" paddingBottom="l" paddingLeft={{ tablet: 'm' }} paddingRight={{ tablet: 'm' }} flexGrow={1}>
        <Block display="flex" justify="space-between" height="150px" style={{ position: 'relative', zIndex: -1 }}>
          {visibleReports.map((visibleReport, index) => {
            if (visibleReport.scorePercentage === undefined) {
              return null;
            }

            return (
              <div key={visibleReport.id}>
                <div
                  style={{
                    position: 'absolute',
                    top: `${100 - visibleReport.scorePercentage}%`,
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                >
                  <ResultBubble result={visibleReport.scorePercentage} />
                </div>
                {index === numOfVisibleReports - 1 ? null : (
                  <DiagonalLine
                    direction={getTrend(visibleReport.id)}
                    style={{
                      position: 'absolute',
                      top: `${100 - visibleReport.scorePercentage}%`,
                      height: getHeight(visibleReport.id),
                      width: `${100 / (numOfVisibleReports - 1)}%`,
                      transform: getTrend(visibleReport.id) === 'positive' ? 'translateY(-100%)' : '',
                    }}
                  />
                )}
                <div
                  style={{
                    position: 'absolute',
                    top: `${100 - visibleReport.scorePercentage}%`,
                    width: '1px',
                    height: `${visibleReport.scorePercentage}%`,
                    background: colors.ciBlue4,
                  }}
                />
              </div>
            );
          })}
        </Block>
        <Block display="flex" justify="space-between">
          {visibleReports.map((visibleReport) => {
            return (
              <div key={visibleReport.id}>
                <Block
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="xs"
                  style={{
                    position: 'absolute',
                    transform: 'translate(-50%, 50%)',
                  }}
                >
                  <Text color="white">{DateUtil.getDateFormattedShort(visibleReport.date)}</Text>
                  <Link to={pathToQMReport(visibleReport.id, `${pathname}${search}`)}>
                    <Block width="2em" color="white">
                      {IconInfo}
                    </Block>
                  </Link>
                </Block>
                <div>&nbsp;</div>
              </div>
            );
          })}
        </Block>
      </Block>
      <Block>
        <IconButton
          icon={IconArrowRight}
          variant="white"
          disabled={visibleReportIndexes.end === reports.length}
          onClick={() => {
            setVisibleReportIndexes({
              start: visibleReportIndexes.start + 1,
              end: visibleReportIndexes.end + 1,
            });
          }}
        />
      </Block>
    </Block>
  );
};

export default QMHistoryVisualizationGraph;
