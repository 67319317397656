const IconClose = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M66.5,50,97.44,19.07a8.77,8.77,0,0,0,0-12.38L93.31,2.57a8.74,8.74,0,0,0-12.37,0L50,33.51,19.07,2.56a8.77,8.77,0,0,0-12.38,0L2.56,6.69a8.76,8.76,0,0,0,0,12.37L33.51,50,2.57,80.94a8.74,8.74,0,0,0,0,12.37l4.12,4.13a8.77,8.77,0,0,0,12.38,0L50,66.5,80.94,97.44a8.76,8.76,0,0,0,12.37,0l4.13-4.13a8.76,8.76,0,0,0,0-12.37Z"
    />
  </svg>
);

export default IconClose;
