import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconCalendar from '../../../assets/icons-v2/IconCalendar';
import IconChecklist from '../../../assets/icons-v2/IconChecklist';
import IconCraft from '../../../assets/icons-v2/IconCraft';
import IconEdit from '../../../assets/icons-v2/IconEdit';
import IconEmail from '../../../assets/icons-v2/IconEmail';
import IconUser from '../../../assets/icons-v2/IconUser';
import ScheduleUtil from '../../../utils/ScheduleUtil';
import IconButton from '../../atoms-v2/IconButton';
import Text from '../../atoms-v2/Text';
import { useQMAdminSchedule } from '../useQMAdminSchedule';

interface QMAdminScheduleViewProps {
  scheduleId: number | undefined;
  onClick?: () => void;
}

const QMAdminCompanyClientSchedule: React.FC<QMAdminScheduleViewProps> = ({ scheduleId, onClick }) => {
  const { t } = useTranslation();
  const { schedule, craft, contacts, users } = useQMAdminSchedule(scheduleId);

  return (
    <>
      <UiDiv padding="0 0 10px 0" display="flex" justifyContent="space-between">
        <Text icon={IconCalendar}>
          <strong>
            {ScheduleUtil.getFrequencyIntervalFormatted(schedule?.frequency, schedule?.interval, t)}{' '}
            {ScheduleUtil.getScheduleDateFormatted(schedule?.start, schedule?.interval, t, 'schedule.form.firstDate')}
          </strong>
        </Text>
        {onClick && <IconButton variant="red" icon={IconEdit} onClick={onClick} />}
      </UiDiv>

      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '100px 1fr' }}
        gap="xs"
        padding="10px 0"
        borderTop="1px solid white"
      >
        <Text icon={IconCraft}>
          {t('common.fields.craft')}:
        </Text>
        <UiDiv paddingLeft={{ mobile: '26px', tablet: 0 }}>
          <Text>{craft?.name || t('craft.form.options.default')}</Text>
        </UiDiv>
      </UiDiv>

      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '100px 1fr' }}
        gap="xs"
        padding="10px 0"
        borderTop="1px solid white"
      >
        <Text icon={IconChecklist}>{t('schedule.headline.roomCount')}:</Text>
        <UiDiv paddingLeft={{ mobile: '26px', tablet: 0 }}>
          <Text>
            {schedule?.roomCount || t('report.textFragments.none.plural')}{' '}
            {schedule?.roomCount === 1
              ? t('report.textFragments.room.singular')
              : t('report.textFragments.room.plural')}
          </Text>
        </UiDiv>
      </UiDiv>

      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '100px 1fr' }}
        gap="xs"
        padding="10px 0"
        borderTop="1px solid white"
      >
        <Text icon={IconUser}>{t('common.fields.reporter')}:</Text>
        <UiDiv paddingLeft={{ mobile: '26px', tablet: 0 }}>
          {users.length > 0 ? (
            <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} gap="xs">
              {users.map((user) => {
                return (
                  <Text key={user.id}>
                    {user.username} ({user.roleName})
                  </Text>
                );
              })}
            </UiDiv>
          ) : (
            <Text>–</Text>
          )}
        </UiDiv>
      </UiDiv>

      <UiDiv
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: '100px 1fr' }}
        gap="xs"
        padding="10px 0 0 0"
        borderTop="1px solid white"
      >
        <Text icon={IconEmail}>{t('common.fields.contacts')}:</Text>
        <UiDiv paddingLeft={{ mobile: '26px', tablet: 0 }}>
          {contacts.length > 0 ? (
            <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} gap="xs">
              {contacts.map((contact) => {
                return (
                  <Text key={contact.id}>
                    {contact.name}
                    <br />
                    {contact.email}
                  </Text>
                );
              })}
            </UiDiv>
          ) : (
            <Text>–</Text>
          )}
        </UiDiv>
      </UiDiv>
    </>
  );
};

export default QMAdminCompanyClientSchedule;
