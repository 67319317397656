import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { NormalizedEntities, requestEntities, RequestEntitiesAction } from '../requestEntities';
import { usersPermissionsRolesSchema, userSchema } from '../schemas';
import { autoLogin, login } from './authSlice';

export const fetchRoles = (params?: any): RequestEntitiesAction<RoleData[]> =>
  requestEntities({
    method: 'GET',
    path: '/users-permissions/roles',
    schema: usersPermissionsRolesSchema,
    params,
  });

const rolesAdapter = createEntityAdapter<RoleEntity>();

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: rolesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.payload.entities?.roles) {
          rolesAdapter.upsertMany(state, action.payload.entities.roles);
        }
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.user) {
          const normalized = normalize<any, NormalizedEntities>(action.payload.user, userSchema);
          if (normalized.entities.roles) {
            rolesAdapter.upsertMany(state, normalized.entities.roles);
          }
        }
      })
      .addCase(autoLogin.fulfilled, (state, action) => {
        if (action.payload.user) {
          const normalized = normalize<any, NormalizedEntities>(action.payload.user, userSchema);
          if (normalized.entities.roles) {
            rolesAdapter.upsertMany(state, normalized.entities.roles);
          }
        }
      });
  },
});

export default rolesSlice.reducer;
