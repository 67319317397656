import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../../assets/icons-v2/IconArrowUp';
import IconButton from '../../atoms-v2/IconButton';

import { useTheme } from 'styled-components';
import { useCheckitemtype } from '../../../app/entityHooks/useCheckitemtype';
import BaseDataQMCheckitemtypeForm from './BaseDataQMCheckitemtypeForm';

interface BaseDataQMCheckitemtypeProps {
  checkitemtypeId: number | undefined;
}

const BaseDataQMCheckitemtype: React.FC<BaseDataQMCheckitemtypeProps> = ({ checkitemtypeId }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { checkitemtype } = useCheckitemtype(checkitemtypeId);

  const [showContent, setShowContent] = useState(false);

  if (!checkitemtype) {
    return null;
  }

  return (
    <UiDiv>
      <UiDiv padding="15px" background="white" color="ciBlue1" cursor="pointer" onClick={() => setShowContent(!showContent)}>
        <UiDiv display="grid" gridTemplateColumns="1fr 22px" columnGap="s" alignItems="center">
          <UiText variant="copy" color={checkitemtype.disabled ? colors.grey20 : 'default'}>
            <b>
              {checkitemtype.name}
              {checkitemtype.disabled && ` (${t('common.fields.deleted')})`}
            </b>
          </UiText>
          <IconButton icon={showContent ? IconArrowUp : IconArrowDown} />
        </UiDiv>
      </UiDiv>
      <Collapse show={showContent} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey20">
            <BaseDataQMCheckitemtypeForm
              checkitemtypeId={checkitemtype.id}
              onSubmit={() => setShowContent(false)}
              onClickCancel={() => setShowContent(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
    </UiDiv>
  );
};

export default BaseDataQMCheckitemtype;
