import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { addressSchema } from '../schemas';

export const fetchAddress = (addressId: number, params?: any): RequestEntitiesAction<AddressData> =>
  requestEntities({
    method: 'GET',
    path: `/addresses/${addressId}`,
    schema: addressSchema,
    params,
  });

const addressesAdapter = createEntityAdapter<AddressEntity>();

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState: addressesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.addresses) {
        addressesAdapter.upsertMany(state, action.payload.entities.addresses);
      }
    });
  },
});

export default addressesSlice.reducer;
