import { entitySelectors } from '../entitySelectors';

export const useClient = (
  clientId: number | undefined
): {
  client: ClientEntity | undefined;
  clientGroup: ClientEntity | undefined;
  company: CompanyEntity | undefined;
  location: LocationEntity | undefined;
  costcenter: CostcenterEntity | undefined;
  address: AddressEntity | undefined;
  contacts: ContactEntity[];
} => {
  const client = entitySelectors.selectById<ClientEntity>('clients', clientId);
  const clientGroup = entitySelectors.selectById<ClientEntity>('clients', client?.clientGroupId);
  const company = entitySelectors.selectById<CompanyEntity>('companies', client?.companyId);
  const location = entitySelectors.selectById<LocationEntity>('locations', client?.locationId);
  const costcenter = entitySelectors.selectById<CostcenterEntity>('costcenters', client?.costcenterId);
  const address = entitySelectors.selectById<AddressEntity>('addresses', client?.addressId);
  const contacts = entitySelectors.selectByIds<ContactEntity>('contacts', client?.contactIds);

  return {
    client,
    clientGroup,
    company,
    location,
    costcenter,
    address,
    contacts,
  };
};
