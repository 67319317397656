import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { selectJobListState, setJobListState } from '../../app/slices/jobListSlice';

export type SortingOption = {
  value: string;
  sortingKey: string;
  sortingOrder: 'asc' | 'desc';
  label: string;
};



export const useJobsSorting = (): {
  sortingOptions: SortingOption[];
  selectedSortingOption: SortingOption;
  setSelectedSortingOption: (option: SortingOption) => void;
} => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sortingValue } = useAppSelector(selectJobListState);

  const sortingOptions: SortingOption[] = useMemo(() => ([
    {
      value: 'createdAt-asc',
      sortingKey: 'createdAt',
      sortingOrder: 'asc',
      label: `${t('common.fields.createdAt')} (${t('common.form.ascending')})`,
    },
    {
      value: 'createdAt-desc',
      sortingKey: 'createdAt',
      sortingOrder: 'desc',
      label: `${t('common.fields.createdAt')} (${t('common.form.descending')})`,
    },
    {
      value: 'entry_id-asc',
      sortingKey: 'createdAt',
      sortingOrder: 'asc',
      label: `${t('common.fields.id')} (${t('common.form.ascending')})`,
    },
    {
      value: 'entry_id-desc',
      sortingKey: 'createdAt',
      sortingOrder: 'desc',
      label: `${t('common.fields.id')} (${t('common.form.descending')})`,
    },
    {
      value: 'client_name-asc',
      sortingKey: 'client_name',
      sortingOrder: 'asc',
      label: `${t('common.fields.clientDescription.short')} (${t('common.form.ascending')})`,
    },
    {
      value: 'client_name-desc',
      sortingKey: 'client_name',
      sortingOrder: 'desc',
      label: `${t('common.fields.clientDescription.short')} (${t('common.form.descending')})`,
    },
    {
      value: 'carrier_number-asc',
      sortingKey: 'carrier_number',
      sortingOrder: 'asc',
      label: `${t('common.fields.carrierNumber.medium')} (${t('common.form.ascending')})`,
    },
    {
      value: 'carrier_number-desc',
      sortingKey: 'carrier_number',
      sortingOrder: 'desc',
      label: `${t('common.fields.carrierNumber.medium')} (${t('common.form.descending')})`,
    },
    {
      value: 'totalPrice-asc',
      sortingKey: 'totalPrice',
      sortingOrder: 'asc',
      label: `${t('job.totalPrice')} (${t('common.form.ascending')})`,
    },
    {
      value: 'totalPrice-desc',
      sortingKey: 'totalPrice',
      sortingOrder: 'desc',
      label: `${t('job.totalPrice')} (${t('common.form.descending')})`,
    },
  ]), [t]);

  const selectedSortingOption = sortingOptions.find((option) => option.value === sortingValue) || sortingOptions[0];

  const setSelectedSortingOption = (option: SortingOption) => {
    dispatch(
      setJobListState({
        sortingValue: option.value,
      })
    );
  };

  return {
    sortingOptions,
    selectedSortingOption,
    setSelectedSortingOption,
  };
};
