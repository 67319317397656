import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconClose from '../../assets/icons-v2/IconClose';
import IconInfo from '../../assets/icons-v2/IconInfo';
import TextButton from '../atoms-v2/TextButton';
import JobsLegendColorItem from './JobsLegendColorItem';

const JobsLegend: React.FC = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const icon = show ? IconClose : IconInfo;
  const textButtonLabel = show ? t('job.searchForm.hideLegend') : t('job.searchForm.showLegend');

  return (
    <>
      <TextButton icon={icon} onClick={() => setShow(!show)} variant="white">
        {textButtonLabel}
      </TextButton>
      <Collapse show={show} fadeInOut>
        <UiDiv paddingTop="s" paddingLeft="22px">
          <UiDiv
            display="flex"
            flexDirection={{ mobile: 'column', tablet: 'row' }}
            flexWrap="wrap"
            rowGap="15px"
            columnGap="s"
          >
            <UiDiv
              display="flex"
              flexDirection={{ mobile: 'column', tablet: 'row' }}
              flexWrap="wrap"
              rowGap="15px"
              columnGap="s"
            >
              <UiText variant="copy" color="white">
                <b>J</b> = {t('job.searchForm.legend.new')}
              </UiText>
              <UiText variant="copy" color="white">
                <b>A</b> = {t('job.searchForm.legend.offer')}
              </UiText>
              <UiText variant="copy" color="white">
                <b>L</b> = {t('job.searchForm.legend.certificate')}
              </UiText>
            </UiDiv>
            <UiDiv
              display="flex"
              flexDirection={{ mobile: 'column', tablet: 'row' }}
              flexWrap="wrap"
              rowGap="15px"
              columnGap="s"
            >
              <JobsLegendColorItem variant="confirmed" />
              <JobsLegendColorItem variant="pending" />
              <JobsLegendColorItem variant="rejected" />
            </UiDiv>
          </UiDiv>
        </UiDiv>
      </Collapse>
    </>
  );
};

export default JobsLegend;
