const IconInfo = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M50,99.15a50.62,50.62,0,0,0,27.78-8.28A49.33,49.33,0,0,0,96.19,68.81,48.33,48.33,0,0,0,99,40.41,49,49,0,0,0,85.36,15.24,50.64,50.64,0,0,0,30.87,4.59,49.8,49.8,0,0,0,8.43,22.69a48.53,48.53,0,0,0,6.21,62.07A50.49,50.49,0,0,0,50,99.15Zm0-79.89a6.72,6.72,0,0,1,3.7,1.11,6.58,6.58,0,0,1,2.46,2.94,6.45,6.45,0,0,1-1.45,7.14,6.76,6.76,0,0,1-7.26,1.42,6.55,6.55,0,0,1-3-2.41,6.44,6.44,0,0,1-1.13-3.64,6.52,6.52,0,0,1,2-4.64A6.71,6.71,0,0,1,50,19.26ZM43.33,50a6.48,6.48,0,0,1,2-4.63,6.74,6.74,0,0,1,9.42,0,6.48,6.48,0,0,1,2,4.63V76.21a6.52,6.52,0,0,1-2,4.64,6.74,6.74,0,0,1-9.42,0,6.52,6.52,0,0,1-2-4.64Z"
    />
  </svg>
);

export default IconInfo;
