import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { addMonths } from 'date-fns/esm';
import min from 'date-fns/min';
import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { limitInterval, selectDashboardState, setDashboardState } from '../../app/slices/dashboardSlice';
import { getEntryLabel } from '../../utils/FilterUtil';
import CustomDatePicker from '../atoms-v2/CustomDatePicker';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import CustomReactSelect from '../CustomReactSelect';
import { useJobsFilters } from '../jobs/useJobsFilters';
import DashboardDownloadButton from './DashboardDownloadButton';
import { usePermissions } from '../auth/usePermissions';

const DashboardFilters: React.FC = () => {
  const { t } = useTranslation();
  const { canEditJobIsInvoiced } = usePermissions();
  const dispatch = useAppDispatch();
  const { statusOptions } = useJobsFilters();
  const {
    selectedCompanyNumber,
    selectedCompanyName,
    selectedLocationNumber,
    selectedLocationName,
    selectedStatus,
    selectedIsInvoiced,
    selectedStartDate,
    selectedEndDate,
  } = useAppSelector(selectDashboardState);

  const handleSelectCompany = (entry: { value: string; label: string } | null) => {
    dispatch(
      setDashboardState({
        selectedCompanyNumber: entry?.value || '',
        selectedCompanyName: entry?.label || '',
        limit: limitInterval,
      })
    );
  };

  const handleSelectLocation = (entry: { value: string; label: string } | null) => {
    dispatch(
      setDashboardState({
        selectedLocationNumber: entry?.value || '',
        selectedLocationName: entry?.label || '',
        limit: limitInterval,
      })
    );
  };

  const handleSelectStatus = (status: '' | JobStatus | JobOfferStatus) => {
    dispatch(
      setDashboardState({
        selectedStatus: status,
        limit: limitInterval,
      })
    );
  };

  const handleSelectIsInvoiced = (isInvoiced: '' | 'true' | 'true') => {
    dispatch(
      setDashboardState({
        selectedIsInvoiced: isInvoiced,
        limit: limitInterval,
      })
    );
  };

  const handleSelectMonths = (months: [Date | null, Date | null]) => {
    dispatch(
      setDashboardState({
        selectedStartDate: months[0] || undefined,
        selectedEndDate: months[1] || undefined,
        limit: limitInterval,
      })
    );
  };

  const isInvoicedOptions = [
    {
      value: '',
      label: t('common.form.all'),
    },
    {
      value: 'true',
      label: t('job.form.isInvoiced.true'),
    },
    {
      value: 'false',
      label: t('job.form.isInvoiced.false'),
    },
  ];

  const maxDates = [new Date()];
  if (selectedStartDate) {
    maxDates.push(addMonths(selectedStartDate, 6));
  }
  const maxDate = min(maxDates);

  return (
    <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} rowGap="xs" columnGap="xxs">
      <UiDiv>
        <FormElementLabel color="ciBlue4">{t('location.headline.singular')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          leadingOptions={[{ value: '', label: t('common.form.all') }]}
          selectedOption={{ value: selectedLocationNumber || '', label: selectedLocationName || t('common.form.all') }}
          asyncApiPath="/locations"
          asyncLimit={500}
          asyncSort="number:asc"
          asyncSetParams={(inputValue) =>
            inputValue
              ? {
                  filters: {
                    $or: [
                      {
                        description: { $containsi: inputValue },
                      },
                      {
                        number: { $contains: inputValue },
                      },
                    ],
                  },
                }
              : {}
          }
          asyncFormatOption={(entry) => ({
            value: entry.number,
            label: getEntryLabel('location', entry),
          })}
          asyncDefaultOptions
          asyncInputMinLength={3}
          selectedValue={selectedLocationNumber}
          isClearable={!!selectedLocationNumber}
          isSearchable
          onChange={handleSelectLocation}
        />
      </UiDiv>
      <UiDiv>
        <FormElementLabel color="ciBlue4">{t('company.headline.singular')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          leadingOptions={[{ value: '', label: t('common.form.all') }]}
          selectedOption={{ value: selectedCompanyName || '', label: selectedCompanyName || t('common.form.all') }}
          asyncApiPath="/companies"
          asyncLimit={500}
          asyncSort="name:asc,number:asc"
          asyncSetParams={(inputValue) =>
            inputValue
              ? {
                  filters: {
                    $or: [
                      {
                        name: { $containsi: inputValue },
                      },
                      {
                        number: { $contains: inputValue },
                      },
                    ],
                  },
                }
              : {}
          }
          asyncFormatOption={(entry) => ({
            value: entry.number,
            label: getEntryLabel('company', entry),
          })}
          asyncDefaultOptions
          asyncInputMinLength={3}
          selectedValue={selectedCompanyNumber}
          isClearable={!!selectedCompanyNumber}
          isSearchable
          onChange={handleSelectCompany}
        />
      </UiDiv>
      {canEditJobIsInvoiced && (
        <UiDiv>
          <FormElementLabel color="ciBlue4">{t('job.isInvoiced')}</FormElementLabel>
          <CustomReactSelect
            layout="outline"
            options={isInvoicedOptions}
            selectedValue={selectedIsInvoiced || ''}
            onChangeValue={handleSelectIsInvoiced}
          />
        </UiDiv>
      )}
      <UiDiv>
        <FormElementLabel color="ciBlue4">{t('common.fields.status')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          options={statusOptions}
          selectedValue={selectedStatus || ''}
          isClearable={!!selectedStatus}
          onChangeValue={handleSelectStatus}
        />
      </UiDiv>
      <UiDiv display="grid" gridTemplateColumns={{ mobile: '2fr 1fr', tablet: '5fr 1fr' }} rowGap="xs" columnGap="xxs">
        <UiDiv>
          <FormElementLabel color="ciBlue4">{t('dashboard.form.months')}</FormElementLabel>
          <CustomDatePicker
            popperPlacement="top-start"
            variant="outline"
            selectsRange={true}
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            onChange={handleSelectMonths}
            maxDate={maxDate}
            isClearable={false}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </UiDiv>
        <UiDiv
          display="flex"
          flexDirection="column"
          justifyContent="end"
        >
          <DashboardDownloadButton />
        </UiDiv>
      </UiDiv>
    </UiDiv>
  );
};

export default DashboardFilters;
