import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { useCheckitemgroup } from '../../../app/entityHooks/useCheckitemgroup';
import { updateCheckitemgroup } from '../../../app/slices/checkitemgroupsSlice';
import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../../assets/icons-v2/IconArrowUp';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconEdit from '../../../assets/icons-v2/IconEdit';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import { moveArrayElement } from '../../../utils/arrayUtils';
import IconButton from '../../atoms-v2/IconButton';
import TextButton from '../../atoms-v2/TextButton';
import TextIcon from '../../atoms-v2/TextIcon';

import BaseDataQMCheckitem from './BaseDataQMCheckitem';
import BaseDataQMCheckitemForm from './BaseDataQMCheckitemForm';
import BaseDataQMCheckitemgroupForm from './BaseDataQMCheckitemgroupForm';
import BaseDataQMDragHandle from './BaseDataQMDragHandle';
import BaseDataQMDropTarget from './BaseDataQMDropTarget';
import { useTheme } from 'styled-components';

interface BaseDataQMCheckitemgroupProps {
  checkitemgroupId: number | undefined;
  roomgroupId: number | undefined;
  index: number;
}

const BaseDataQMCheckitemgroup: React.FC<BaseDataQMCheckitemgroupProps> = ({
  checkitemgroupId,
  roomgroupId,
  index,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const { checkitemgroup, checkitemgrouptype } = useCheckitemgroup(checkitemgroupId);
  const [checkitemIds, setCheckitemIds] = useState(checkitemgroup?.checkitemIds || []);

  useEffect(() => {
    setCheckitemIds(checkitemgroup?.checkitemIds || []);
  }, [checkitemgroup?.checkitemIds]);

  const [showContent, setShowContent] = useState(true);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showCreateCheckitemForm, setShowCreateCheckitemForm] = useState(false);

  const dragItem: DragItem = { index, id: checkitemgroupId || 0, parentId: roomgroupId };

  const [{ isDragging }, dragHandle, dragPreview] = useDrag(
    () => ({
      type: 'checkitemgroup',
      item: dragItem,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [dragItem, index, checkitemgroup]
  );

  if (!checkitemgroup) {
    return null;
  }

  const handleOnDropCheckitem = (item: DragItem, dropTargetIndex: number) => {
    if (item.parentId === checkitemgroup.id) {
      const checkitemIdsNew = moveArrayElement(
        checkitemIds,
        item.index,
        dropTargetIndex < item.index ? dropTargetIndex : dropTargetIndex - 1
      );

      setCheckitemIds(checkitemIdsNew);

      dispatch(
        updateCheckitemgroup(checkitemgroup.id, {
          checkitems: checkitemIdsNew,
        })
      );
    }
  };

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0 : 1 }}>
      <UiDiv padding="15px" background="white">
        <UiDiv display="grid" gridTemplateColumns="auto 1fr 20% 22px 22px" columnGap="s" alignItems="center">
          <UiDiv>
            <BaseDataQMDragHandle itemRef={dragHandle} />
          </UiDiv>
          <UiText variant="copy">
            <b>{checkitemgrouptype?.name}</b>
          </UiText>
          <UiDiv display="flex" gap="xs" justifyContent="flex-end" alignItems="center">
            {!checkitemgroup?.disabled && (
              <>
                <TextIcon icon={IconCheck} color={colors.trafficLightGreen} />
                <UiText variant="copySmall">{t('common.qm.preSelected')}</UiText>
              </>
            )}
          </UiDiv>
          <IconButton icon={IconEdit} onClick={() => setShowEditForm(!showEditForm)} />
          <IconButton icon={showContent ? IconArrowUp : IconArrowDown} onClick={() => setShowContent(!showContent)} />
        </UiDiv>
      </UiDiv>
      <Collapse show={showEditForm} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey40">
            <BaseDataQMCheckitemgroupForm
              checkitemgroupId={checkitemgroup?.id}
              onSubmit={() => setShowEditForm(false)}
              onClickCancel={() => setShowEditForm(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
      <Collapse show={showContent} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey40">
            <UiText variant="copy">{t('checkitem.headline.plural')}</UiText>
            {checkitemIds.length > 0 && (
              <>
                <UiDiv display="flex" flexDirection="column" marginTop="xs">
                  {checkitemIds.map((checkitemId, checkitemIndex) => {
                    return (
                      <Fragment key={checkitemId}>
                        <BaseDataQMDropTarget
                          parentId={checkitemgroup.id}
                          dropTargetIndex={checkitemIndex}
                          accept="checkitem"
                          onDrop={handleOnDropCheckitem}
                        />
                        <BaseDataQMCheckitem
                          key={checkitemId}
                          checkitemId={checkitemId}
                          checkitemgroupId={checkitemgroup.id}
                          index={checkitemIndex}
                        />
                      </Fragment>
                    );
                  })}
                </UiDiv>
                <BaseDataQMDropTarget
                  parentId={checkitemgroup.id}
                  dropTargetIndex={checkitemIds.length}
                  accept="checkitem"
                  onDrop={handleOnDropCheckitem}
                />
              </>
            )}
            <UiDiv marginTop="xs">
              <TextButton icon={IconPlus} onClick={() => setShowCreateCheckitemForm(!showCreateCheckitemForm)}>
                {t('checkitem.actions.create')}
              </TextButton>
            </UiDiv>
            <Collapse show={showCreateCheckitemForm} fadeInOut keepChildren={false}>
              <UiDiv paddingTop="xs">
                <UiDiv padding="s" background="grey20">
                  <BaseDataQMCheckitemForm
                    checkitemgroupId={checkitemgroup?.id}
                    onSubmit={() => setShowCreateCheckitemForm(false)}
                    onClickCancel={() => setShowCreateCheckitemForm(false)}
                  />
                </UiDiv>
              </UiDiv>
            </Collapse>
          </UiDiv>
        </UiDiv>
      </Collapse>
    </div>
  );
};

export default BaseDataQMCheckitemgroup;
