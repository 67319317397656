import { pick } from 'lodash';
import AppUtil from '../utils/AppUtil';

export const useClientGroupsFilter = () => {
  const isDataFilter = ({ searchFilter }) => {
    return !!searchFilter;
  };

  const filterObjects = (objects, filter) => {
    const objectFilter = pick(filter || {}, ['status']);
    return AppUtil.filterModels(objects, objectFilter);
  };

  const getAPIFilter = (filter) => {
    if (filter.debitorId) {
      return [{ debitor_id: filter.debitorId }];
    }
    // set text search filter
    if (filter.searchFilter) {
      return [
        {
          debitor_id: { $contains: filter.searchFilter },
        },
      ];
    }
    return null;
  };

  const initialDataFilter = null;

  const initialTableFilter = {
    status: ['new', 'editedNotNew', 'invalid', 'original'],
  };

  const uniqueKeys = [];

  return {
    uniqueKeys,
    getAPIFilter,
    isDataFilter,
    filterObjects,
    initialDataFilter,
    initialTableFilter,
  };
};
