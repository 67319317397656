import styled, { CSSObject } from 'styled-components';

type HeadlineVariant = 'h1' | 'h2' | 'h3';

interface HeadlineProps {
  variant?: HeadlineVariant;
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
}

const Headline = styled.div<HeadlineProps>(({ variant, color, textAlign, theme }) => {
  const baseStyle: CSSObject = {
    color: theme.colors[color || ''] || color,
    textAlign,
    margin: 0,
  };

  switch (variant) {
    case 'h1':
      return {
        ...baseStyle,
        ...theme.type.h1Mobile,
        [theme.mediaQueries.tablet]: {
          ...theme.type.h1,
        },
      };

    case 'h2':
      return {
        ...baseStyle,
        ...theme.type.h2,
      };

    case 'h3':
      return {
        ...baseStyle,
        ...theme.type.h3,
      };

    /**
     * return h2 as default
     */
    default:
      return {
        ...baseStyle,
        ...theme.type.h2,
      };
  }
});

export default Headline;
