/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './filter-toggle.scss';

const cssClassInactive = 'smg-filter-toggle smg-filter-toggle--inactive';
const cssClassActive = 'smg-filter-toggle';

class FilterToggle extends Component {
    handleToggle() {
        const { isActive, onToggle } = this.props;
        onToggle(!isActive);
    }

    render() {
        const { className, count, isActive, label } = this.props;
        const cssClass = (isActive ? cssClassActive : cssClassInactive) + ' ' + className;
        return (
            <button className={cssClass} onClick={() => this.handleToggle()}>
                <span className="smg-filter-toggle__label">
                    {label} {count}
                </span>
            </button>
        );
    }
}

FilterToggle.propTypes = {
    className: PropTypes.string,
    count: PropTypes.number,
    isActive: PropTypes.bool,
    label: PropTypes.string,
    onToggle: PropTypes.func
};

FilterToggle.defaultProps = {
    className: '',
    count: 0,
    isActive: false,
    label: '',
    onToggle: () => false
};

export default FilterToggle;
