import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/appHooks';
import { updateUser } from '../../app/slices/usersSlice';
import IconCheck from '../../assets/icons-v2/IconCheck';
import AppUtil from '../../utils/AppUtil';
import Button from '../atoms-v2/Button';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import FormPanel from '../atoms-v2/FormPanel';
import { useAuth } from '../auth/useAuth';
import Advice from '../elements/Advice';
import BasicInput from '../elements/BasicInput';

const AccountPassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [showAdvice, setShowAdvice] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [adviceText, setAdviceText] = useState('');

  if (!currentUser) {
    return null;
  }

  const isFormDataValid = () => {
    if (!newPassword || !newPasswordConfirm) {
      return false;
    }
    return true;
  };

  const showError = (text) => {
    setShowAdvice(true);
    setIsSuccess(false);
    setIsError(true);
    setAdviceText(text);
  };

  const handleOnClickUpdate = async () => {
    if (!isFormDataValid) {
      return false;
    }

    if (newPassword !== newPasswordConfirm) {
      showError(t('account.changePassword.errors.notEqual'));
      return false;
    }

    if (!AppUtil.isPasswordValid(newPassword)) {
      showError(t('account.changePassword.errors.invalid'));
      return false;
    }

    await dispatch(
      updateUser(currentUser.id, {
        password: newPassword,
      })
    );

    setNewPassword('');
    setNewPasswordConfirm('');
    setShowAdvice(true);
    setIsSuccess(true);
    setIsError(false);
    setAdviceText(t('account.changePassword.success') || '');

    return true;
  };

  return (
    <FormPanel>
      <Collapse show={showAdvice}>
        <UiDiv paddingBottom="s">
          <Advice text={adviceText} isSuccess={isSuccess} isError={isError} onClick={() => setShowAdvice(false)} />
        </UiDiv>
      </Collapse>
      <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} columnGap="xs" rowGap="15px">
        <UiDiv>
          <FormElementLabel>{t('account.changePassword.newPassword')} *</FormElementLabel>
          <BasicInput
            type="password"
            isPasswordToggle
            value={newPassword}
            onChange={(value) => setNewPassword(value)}
          />
        </UiDiv>
        <UiDiv>
          <FormElementLabel>{t('account.changePassword.newPasswordRepeat')} *</FormElementLabel>
          <BasicInput
            type="password"
            isPasswordToggle
            value={newPasswordConfirm}
            onChange={(value) => setNewPasswordConfirm(value)}
          />
        </UiDiv>
      </UiDiv>
      <UiDiv paddingTop="m">
        <UiText variant="copySmall">
          {t('account.changePassword.criteria.headline')}
          <br />
          - {t('account.changePassword.criteria.lowerCase')}
          <br />
          - {t('account.changePassword.criteria.upperCase')}
          <br />
          - {t('account.changePassword.criteria.digit')}
          <br />- {t('account.changePassword.criteria.special')}
        </UiText>
      </UiDiv>
      <UiDiv paddingTop="m">
        <Button icon={IconCheck} disabled={!isFormDataValid()} onClick={handleOnClickUpdate}>
          {t('common.actions.refresh')}
        </Button>
      </UiDiv>
    </FormPanel>
  );
};

export default AccountPassword;
