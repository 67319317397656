import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Collapse, Group } from '@webfox-sc/core';
import Headline from '../../components/atoms-v2/Headline';
import CopyText from '../../atoms/CopyText';
import TextButton from '../../atoms/TextButton';
import { COLORS, SPACINGS } from '../../theme/theme';
import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import { login } from '../../app/slices/authSlice';
import TextInput from '../../components/atoms-v2/TextInput';
import ButtonSecondary from '../../atoms/ButtonSecondary/ButtonSecondary';

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status: authStatus } = useAppSelector(({ authState }) => authState);

  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    dispatch(login({ identifier, password }));
  };

  return (
    <Block width="100%" maxWidth="400px" padding="30px" border="1px solid white">
      <Block>
        <Headline variant="h3" color="white">
          Login
        </Headline>
      </Block>
      <Block paddingTop={SPACINGS.M}>
        <form onSubmit={handleOnSubmit}>
          <Group vertical spacing="15px">
            <Block>
              <CopyText color="white">Benutzername</CopyText>
              <Block paddingTop="7px">
                <TextInput
                  variant="outline"
                  name="identifier"
                  autoComplete="current-identifier"
                  value={identifier}
                  width="100%"
                  error={authStatus === 'failed'}
                  onChange={(e) => setIdentifier(e.target.value)}
                />
              </Block>
            </Block>
            <Block>
              <CopyText color="white">Passwort</CopyText>
              <Block paddingTop="7px">
                <TextInput
                  variant="outline"
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  value={password}
                  width="100%"
                  error={authStatus === 'failed'}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Block>
            </Block>
          </Group>
          <Block paddingTop={SPACINGS.M}>
            <ButtonSecondary label="Anmelden" type="submit" />
          </Block>
          <Collapse show={authStatus === 'failed'}>
            <Block paddingTop="40px">
              <Block padding="10px 12px" background={COLORS.TRAFFIC_LIGHT_RED}>
                <CopyText color="white" textAlign="center">
                  Der Benutzername oder das Passwort ist ungültig!
                </CopyText>
              </Block>
            </Block>
          </Collapse>
          <Block paddingTop={SPACINGS.M}>
            <TextButton
              label="Passwort vergessen"
              href="/password/new"
              onClick={(e) => {
                e.preventDefault();
                navigate('/password/new');
              }}
            />
          </Block>
        </form>
      </Block>
    </Block>
  );
};

export default LoginForm;
