const IconFolder = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M2.93,18.3H48.4L43.48,8.76a3,3,0,0,0-2.61-1.58H7.29a2.92,2.92,0,0,0-2.78,2L1.43,18.43A9.2,9.2,0,0,1,2.93,18.3ZM97.07,92.82H2.93A2.93,2.93,0,0,1,0,89.89V27.09a2.93,2.93,0,0,1,2.93-2.93H97.07A2.93,2.93,0,0,1,100,27.09v62.8A2.93,2.93,0,0,1,97.07,92.82Z"
    />
  </svg>
);

export default IconFolder;
