/* xeslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

export function updateUserModels(userModels) {
    return {
        type: REDUX_ACTIONS.UPDATE_USER_MODELS,
        userModels,
    };
}

export function updateIsLoadingUsers(isLoadingUsers) {
    return {
        type: REDUX_ACTIONS.UPDATE_IS_LOADING_USERS,
        isLoadingUsers,
    };
}
