import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientFilterState } from '../../types/clientFilter';
import { RootState } from '../store';


export const initialState: ClientFilterState = { companies: undefined, locations: undefined, costcenters: undefined };

export const clientFilterSlice = createSlice({
  name: 'clientFilter',
  initialState,
  reducers: {
    setClientFilterState: (state: ClientFilterState, action: PayloadAction<ClientFilterState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setClientFilterState } = clientFilterSlice.actions;
export const selectClientFilterState = (state: RootState): ClientFilterState => state.clientFilterState;
export default clientFilterSlice.reducer;
