import { Collapse } from '@webfox-sc/core';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import ButtonSecondary from '../../../atoms/ButtonSecondary';
import Section from '../../../atoms/Section';
import DataEditor from '../../../container/DataEditor';
import { useBaseDataViewContext } from '../../../hooks/baseDataView';
import BasicButtonGroup from '../../elements/BasicButtonGroup';
import EditStatusFilter from '../../elements/EditStatusFilter';
import Preloader from '../../elements/Preloader';
import './base-data-filtered-list.scss';

const BaseDataFilteredList = ({
  labelObjects,
  labelNew,
  Editor,
  EditEditor,
  TableHeader,
  TableRow,
  newEditorPresets,
  toolbar,
  hideDeleteAll,
  hideCreate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    countTotal,
    countDisplayed,
    displayShowMore,
    handleOnAdd,
    handleShowMore,
    showAdd,
    newObject,
    handleOnCancel,
    showStatusFilter,
    showNew,
    newObjects,
    newObjectsKeys,
    handleOnClickSorting,
    sortingKey,
    sortingOrder,
    objectIds,
    activeId,
    objectPath,
    handleBulkItemCancel,
    handleOnClickTableBodyRow,
    handleCheckCanUpdate,
    handleOnCreate,
    handleOnUpdate,
    handleOnDelete,
    handleOnDeleteAll,
    selection,
    handleOnSelect,
    isLoading,
  } = useBaseDataViewContext();

  return (
    <>
      <Section align="center">
        <span className="smg-view-base-data__text">
          {countDisplayed} / {countTotal} {labelObjects}
        </span>
      </Section>
      <Section paddingTop="L">
        {!hideCreate && (
          <div className="smg-view-base-data__toolbar">
            <BasicButtonGroup>
              <ButtonSecondary label={labelNew} icon={IconPlus} onClick={handleOnAdd} active={showAdd} />
            </BasicButtonGroup>
          </div>
        )}
        {toolbar}
      </Section>
      <Collapse show={showAdd}>
        <Section paddingTop="XS">
          {newObject && (
            <Editor
              model={newObject}
              isActive={showAdd}
              initModel={handleOnAdd}
              onCancel={handleOnCancel}
              dispatch={dispatch}
              onCreate={handleOnCreate}
              checkCanUpdate={handleCheckCanUpdate}
            />
          )}
        </Section>
      </Collapse>
      {showStatusFilter && (
        <Section paddingTop="M" align="center">
          <EditStatusFilter />
        </Section>
      )}
      {newObjects &&
        showNew &&
        newObjectsKeys.map((uuid) => (
          <Section paddingTop="XS" key={uuid}>
            <Editor
              model={newObjects[uuid]}
              isActive
              isUpdateMode={newObjects[uuid].id !== ''}
              onCancel={() => handleBulkItemCancel(uuid)}
              dispatch={dispatch}
              initModel={handleOnAdd}
              onUpdate={handleOnUpdate}
              onDelete={handleOnDelete}
              checkCanUpdate={handleCheckCanUpdate}
              {...newEditorPresets}
            />
          </Section>
        ))}
      {isLoading ? (
        <Section paddingTop="L" align="center">
          <Preloader />
        </Section>
      ) : (
        <>
          <Section paddingTop="L">
            <TableHeader handleSort={handleOnClickSorting} sortingKey={sortingKey} sortingOrder={sortingOrder} />
            {objectIds.map((objectId) => {
              return (
                <Fragment key={objectId}>
                  <Section paddingTop="XS">
                    <Collapse show={objectId !== activeId} keepChildren={false}>
                      <TableRow
                        objectId={objectId}
                        onClick={() => handleOnClickTableBodyRow(objectId)}
                        isSelected={!!selection[objectId]}
                        onSelect={handleOnSelect}
                      />
                    </Collapse>
                    <Collapse show={objectId === activeId} keepChildren={false}>
                      <EditEditor
                        objectId={objectId}
                        objectPath={objectPath}
                        component={Editor}
                        isActive={objectId === activeId}
                        isUpdateMode
                        initModel={handleOnAdd}
                        onCancel={handleOnCancel}
                        onUpdate={handleOnUpdate}
                        onDelete={handleOnDelete}
                        checkCanUpdate={handleCheckCanUpdate}
                        dispatch={dispatch}
                      />
                    </Collapse>
                  </Section>
                </Fragment>
              );
            })}
          </Section>
          {displayShowMore && (
            <Section paddingTop="XS" align="center">
              <ButtonSecondary label={t('common.actions.showMore')} icon={IconPlus} onClick={handleShowMore} />
            </Section>
          )}
        </>
      )}
      {!hideDeleteAll && (
        <Section paddingTop="XS" align="right">
          <ButtonSecondary
            label={t('common.actions.deleteSelected')}
            icon={IconClose}
            onClick={handleOnDeleteAll}
            disabled={!Object.keys(selection).length}
          />
        </Section>
      )}
    </>
  );
};

BaseDataFilteredList.propTypes = {
  newObjects: PropTypes.object,
  labelObjects: PropTypes.string,
  labelNew: PropTypes.string,
  Editor: PropTypes.any,
  EditEditor: PropTypes.any,
  TableHeader: PropTypes.any,
  TableRow: PropTypes.any,
  newEditorPresets: PropTypes.object,
  hideDeleteAll: PropTypes.bool,
  showNoServicesFilter: PropTypes.bool,
  toolbar: PropTypes.object,
};

BaseDataFilteredList.defaultProps = {
  newObjects: null,
  labelObjects: '',
  labelNew: '',
  Editor: <></>,
  EditEditor: DataEditor,
  TableHeader: <></>,
  TableRow: <></>,
  newEditorPresets: {},
  showNoServicesFilter: false,
  hideDeleteAll: false,
  toolbar: null,
};

export default BaseDataFilteredList;
