import { UiDiv, UiFlex, UiText } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/appHooks';
import { pathToQMAdminClientConfig, pathToQMAdminClientReports, pathToQMAdminCompany } from '../../../app/paths';
import { fetchClient } from '../../../app/slices/clientsSlice';
import { fetchReports } from '../../../app/slices/reportsSlice';
import { fetchSchedules } from '../../../app/slices/schedulesSlice';
import IconCheckCircle from '../../../assets/icons-v2/IconCheckCircle';
import DateUtil from '../../../utils/DateUtil';
import QMUtil from '../../../utils/QMUtil';
import ContentBox from '../../atoms-v2/ContentBox';
import Text from '../../atoms-v2/Text';
import TextIcon from '../../atoms-v2/TextIcon';
import PageContent from '../../page/PageContent';
import PageHeading from '../../page/PageHeading';
import QMAdminConfigCounts from '../qm-admin-config-counts/QMAdminConfigCounts';
import QMAdminConfigSpecificityLabel from '../qm-admin-shared/QMAdminConfigSpecificityLabel';
import { useQMAdminClient } from '../useQMAdminClient';
import QMAdminClientContacts from './QMAdminClientContacts';
import QMAdminClientInfoBox from './QMAdminClientInfoBox';
import QMAdminClientSchedules from './QMAdminClientSchedules';
import { map } from 'lodash';

const QMAdminClientEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { clientId: clientIdString } = useParams();
  const clientId = clientIdString ? parseInt(clientIdString) : 0;
  const { client, company, specificityByCraft } = useQMAdminClient(clientId);

  const [countFinishedReports, setCountFinishedReports] = useState(0);
  const [firstReportDate, setFirstReportDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (clientId) {
        dispatch(fetchClient(clientId, { populate: QMUtil.clientPopulateOptions }));
        dispatch(fetchSchedules({ filters: { client: clientId }, ...QMUtil.schedulesQueryOptions }));
        const res = await dispatch(
          fetchReports(
            {
              filters: {
                status: 'finished',
                client: {
                  id: clientId,
                },
              },
              pagination: {
                limit: 1,
              },
              fields: ['date'],
              sort: 'date:ASC',
            },
            true
          )
        );
        setCountFinishedReports(res?.payload?.count || 0);
        setFirstReportDate((res?.payload?.data || [])[0]?.date);
      }
    };
    fetchData();
  }, [dispatch, clientId]);

  if (!company || !client) {
    return null;
  }

  return (
    <>
      <PageHeading
        heading={company.number}
        subheading={company.name}
        backlinkLabel={t('common.actions.back') || undefined}
        backlinkPath={pathToQMAdminCompany(company.id)}
      />
      <PageContent marginTop="l">
        <UiFlex flexDirection="column" rowGap="l">
          <QMAdminClientInfoBox clientId={client.id} />
            <UiDiv>
              <UiText variant="h2" color="white" textAlign="center">
                {t('common.entities.report.singular')}
              </UiText>
              <UiDiv marginTop="m" display="flex" flexDirection="column" gap="xs">
                {map(specificityByCraft, (specificityEntry) => (
                  <ContentBox
                    variant="white"
                    withLink
                    linkHref={pathToQMAdminClientConfig(client.id, specificityEntry.craftId || 0)}
                    onClickLink={() => navigate(pathToQMAdminClientConfig(client.id, specificityEntry.craftId || 0))}
                    key={`craft_${specificityEntry.craftId}`}
                  >
                    <UiDiv display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} columnGap="xs">
                      <strong><QMAdminConfigSpecificityLabel {...specificityEntry} />:</strong>
                      <QMAdminConfigCounts {...specificityEntry} />
                    </UiDiv>
                  </ContentBox>
                ))}
              </UiDiv>
            </UiDiv>
          <UiDiv>
            <UiText variant="h2" color="white" textAlign="center" marginBottom="m">
              {t('schedule.contacts')}
            </UiText>
            <QMAdminClientContacts clientId={clientId} />
          </UiDiv>

          <QMAdminClientSchedules clientId={clientId} />

          <UiDiv>
            <UiText variant="h2" color="white" textAlign="center">
            {t('schedule.headline.previous.plural')}
            </UiText>
            <ContentBox
              variant="white"
              withLink={countFinishedReports > 0}
              linkHref={pathToQMAdminClientReports(client.id)}
              onClickLink={() => navigate(pathToQMAdminClientReports(client.id))}
              marginTop="m"
            >
              <UiDiv display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} columnGap="xs"><TextIcon icon={IconCheckCircle} />
              <UiDiv flexGrow={1}>
                {countFinishedReports === 0 ? (
                  <Text>
                    <em>{t('schedule.info.noReports')}</em>
                  </Text>
                ) : (
                  <Text>
                    {countFinishedReports}{' '}
                    {countFinishedReports === 1
                      ? t('common.entities.report.singular')
                      : t('common.entities.report.plural')}{' '}
                    {firstReportDate
                      ? `${
                          countFinishedReports === 1 ? t('common.form.on') : t('common.form.from')
                        } ${DateUtil.getDateFormatted(firstReportDate)}`
                      : ''}
                  </Text>
                )}
              </UiDiv>
              </UiDiv>
            </ContentBox>
          </UiDiv>
        </UiFlex>
      </PageContent>
    </>
  );
};

export default QMAdminClientEdit;
