import { UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../atoms-v2/Text';

import QMFinishedReportTeaser from './QMFinishedReportTeaser';

interface QMByClientFinishedReportsProps {
  reportIds: number[];
}

const QMFinishedReports: React.FC<QMByClientFinishedReportsProps> = ({ reportIds }) => {
  const { t } = useTranslation();
  return (
    <UiDiv>
      <UiText variant="h2" color="white" textAlign="center">
        {t('report.headline.finished')}
      </UiText>
      <UiDiv paddingTop="m">
        {reportIds.length === 0 ? (
          <Text color="white" textAlign="center">
            {t('report.info.noFinishedFound')}
          </Text>
        ) : (
          <UiDiv display="flex" flexDirection="column" rowGap="xs">
            {reportIds.map((reportId) => {
              return <QMFinishedReportTeaser key={reportId} reportId={reportId} />;
            })}
          </UiDiv>
        )}
      </UiDiv>
    </UiDiv>
  );
};

export default QMFinishedReports;
