/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, AsyncThunkAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { normalize, Schema } from 'normalizr';
import { getAPIParams } from '../utils/APIParamsUtil';
import { apiUrl } from './api';
import { RootState } from './store';

export type NormalizedEntities = {
  users?: { [id: string]: UserEntity };
  permissions?: { [id: string]: PermissionEntity };
  roles?: { [id: string]: RoleEntity };
  companies?: { [id: string]: CompanyEntity };
  clients?: { [id: string]: ClientEntity };
  services?: { [id: string]: ServiceEntity };
  costcenters?: { [id: string]: CostcenterEntity };
  checkitemtypes?: { [id: string]: CheckitemtypeEntity };
  checkitems?: { [id: string]: CheckitemEntity };
  checkitemgroups?: { [id: string]: CheckitemgroupEntity };
  checkitemgrouptypes?: { [id: string]: CheckitemgrouptypeEntity };
  crafts?: { [id: string]: CraftEntity };
  roomgroups?: { [id: string]: RoomgroupEntity };
  schedules?: { [id: string]: ScheduleEntity };
  locations?: { [id: string]: LocationEntity };
  contacts?: { [id: string]: ContactEntity };
  reports?: { [id: string]: ReportEntity };
  reportrooms?: { [id: string]: ReportroomEntity };
  checkresults?: { [id: string]: CheckresultEntity };
  pictures?: { [id: string]: PictureEntity };
  jobs?: { [id: string]: JobEntity };
  recipients?: { [id: string]: RecipientEntity };
  addresses?: { [id: string]: AddressEntity };
  offers?: { [id: string]: OfferEntity };
  certificates?: { [id: string]: CertificateEntity };
  files?: { [id: string]: FileEntity };
  histories?: { [id: string]: HistoryEntity };
};

type RequestEntitiesError = {
  statusCode?: number;
  message?: string;
  code?: string;
};

export type RequestEntitiesReturned<D = any> = {
  data?: D;
  entities?: NormalizedEntities;
  count?: number;
  error?: RequestEntitiesError;
};

export type RequestEntitiesThunkArg = {
  method: Method;
  path: string;
  data?: any;
  params?: any;
  schema: Schema;
  type?: string;
  withCount?: boolean;
};

export type RequestEntitiesThunkApiConfig<D = any> = {
  dispatch: ThunkDispatch<RootState, null | undefined, AnyAction>;
  state: RootState;
  rejectValue: RequestEntitiesReturned<D>;
};

export type RequestEntitiesAction<D = any> = AsyncThunkAction<
  RequestEntitiesReturned<D>,
  RequestEntitiesThunkArg,
  RequestEntitiesThunkApiConfig<D>
>;

const getErrorPayloadFromAxiosError = (error) => {
  const requestEntitiesError: RequestEntitiesError = {};
  if (error.response.data.error.message) {
    requestEntitiesError.message = error.response.data.error.message;
  } else if (error.response.data.message) {
    requestEntitiesError.message = error.response.data.message;
  } else {
    requestEntitiesError.message = error.message;
  }

  if (error.response.data.code) {
    requestEntitiesError.code = error.response.data.code;
  }

  requestEntitiesError.statusCode = error.response.status;

  return { error: requestEntitiesError };
};

export const requestEntities = createAsyncThunk<
  RequestEntitiesReturned,
  RequestEntitiesThunkArg,
  RequestEntitiesThunkApiConfig
>('requestEntities', async (arg, thunkAPI) => {
  const { authState } = thunkAPI.getState();

  const { params, paramString } = getAPIParams(arg.params, arg.withCount);

  const axiosConfig: AxiosRequestConfig = {
    method: arg.method,
    url: `${apiUrl}${arg.path}${paramString}`,
    data: arg.data,
  };

  if (authState?.jwt) {
    axiosConfig.headers = {
      Authorization: `Bearer ${authState?.jwt}`,
    };
  }

  let data: any;
  // let countResult: number | undefined = undefined;
  let entities: NormalizedEntities = {};

  try {
    ({ data } = await axios(axiosConfig));
  } catch (error) {
    return thunkAPI.rejectWithValue(getErrorPayloadFromAxiosError(error));
  }

  let entitiesData;
  if (data) {
    try {
      // if params.count=true, data is wrapped
      if (params.count) {
        entitiesData = data.data;
      } else {
        entitiesData = data;
      }
      const normalized = normalize<any, NormalizedEntities>(entitiesData, arg.schema);
      entities = normalized.entities;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }

  const returnData: RequestEntitiesReturned = {
    data: entitiesData,
    entities,
  };

  if (params.count) {
    returnData.count = data?.pagination?.total;
  }

  return returnData;
});
