import { UiDiv } from '@webfox-sc/core';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../app/entityHooks/useUser';
import { QMFilterState } from '../../app/slices/qmOverviewSlice';
import { DynamicClientFilter } from '../../types/clientFilter';
import { createClientDropdowns, getEntries, getGroupLabel } from '../../utils/FilterUtil';
import CustomReactSelect from '../CustomReactSelect';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import { useAuth } from '../auth/useAuth';
import { usePermissions } from '../auth/usePermissions';
import QMCraftsFilter from './QMCraftsFilter';

interface QMClientsFilterProps {
  requireFields?: boolean;
  useCrafts?: boolean;
  layout?: string;
  onChangeFilter?: (filter: QMFilterState) => void;
  dynamicClientFilter: DynamicClientFilter;
}

const QMClientsFilter: React.FC<QMClientsFilterProps> = ({
  onChangeFilter,
  requireFields,
  useCrafts,
  layout,
  dynamicClientFilter,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { costcenters: ownCostcenters } = useUser(currentUser?.id);
  const { canViewAllCostCenters } = usePermissions();

  const { clientId, company, setCompany, location, setLocation, costcenter, setCostcenter, filterMap } =
    dynamicClientFilter;

  const costcenterId = costcenter && costcenter.id;
  const companyId = company && company.id;
  const locationId = location && location.id;

  const assignedCostcenters = useMemo(
    () =>
      canViewAllCostCenters
        ? []
        : [
            {
              label: getGroupLabel('own'),
              options: getEntries('costcenter', ownCostcenters),
            },
          ],
    [ownCostcenters, canViewAllCostCenters]
  );

  const clientDropdowns = useMemo(() => {
    return createClientDropdowns(filterMap);
  }, [filterMap]);

  useEffect(() => {
    if (onChangeFilter) {
      onChangeFilter({
        clientId,

        costcenterId: costcenter?.id || null,
        costcenterClientIds: costcenter?.clientIds || null,
        costcenterNumber: costcenter?.number || '',

        companyId: company?.id,
        companyClientIds: company?.clientIds || null,
        companyNumber: company?.number || '',

        locationId: location?.id,
        locationClientIds: location?.clientIds || null,
        locationNumber: location?.number || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, costcenterId, locationId, onChangeFilter]);

  const onChangeCostcenter = async (option) => {
    const id = (option && option.value) || null;
    const number = (option && option.number) || null;
    await setCostcenter({
      id,
      number,
    });
  };

  const onChangeCompany = async (option) => {
    const id = (option && option.value) || null;
    const number = (option && option.number) || null;
    await setCompany({
      id,
      number,
      name: (option && option.name) || null,
    });
  };

  const onChangeLocation = async (option) => {
    const id = (option && option.value) || null;
    const number = (option && option.number) || null;
    await setLocation({
      id,
      number,
      description: (option && option.description) || null,
    });
  };

  const labelColor = layout === 'outline' ? 'white' : 'ciBlue1';

  return (
    <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} gap="xs">
      <UiDiv>
        <FormElementLabel color={labelColor}>
          {t('common.entities.costcenter.singular')}
          {requireFields ? ' *' : ''}
        </FormElementLabel>
        <CustomReactSelect
          layout={layout}
          asyncDefaultOptions={[
            { value: '', label: t('common.form.showAllCostcenters') },
            ...(clientDropdowns?.costcenter?.length > 0 ? clientDropdowns?.costcenter : assignedCostcenters),
          ]}
          // leadingOptions={[{ value: '', label: 'Alle Arbeitsplätze anzeigen' }]}
          // asyncDefaultOptions={
          //   clientDropdowns?.costcenter?.length > 0
          //     ? [{ value: '', label: 'Alle Arbeitsplätze anzeigen' }, ...clientDropdowns?.costcenter]
          //     : true
          // }
          selectedOption={{
            value: costcenterId || '',
            label: (costcenter && costcenter.number) || t('common.form.showAllCostcenters'),
          }}
          asyncApiPath="/costcenters"
          asyncLimit={500}
          asyncSort="number:asc"
          asyncSetParams={(inputValue) =>
            inputValue
              ? {
                  filters: [
                    {
                      number: { $contains: inputValue },
                    },
                  ],
                }
              : {}
          }
          asyncFormatOptions={(entries) => [
            {
              label: getGroupLabel('none'),
              options: getEntries('costcenter', entries),
            },
          ]}
          asyncInputMinLength={3}
          isClearable
          isSearchable
          onChange={onChangeCostcenter}
        />
      </UiDiv>
      {useCrafts ? <QMCraftsFilter onChangeFilter={onChangeFilter} labelColor={labelColor} /> : <UiDiv />}
      <UiDiv>
        <FormElementLabel color={labelColor}>Debitor{requireFields ? ' *' : ''}</FormElementLabel>
        <CustomReactSelect
          layout={layout}
          asyncDefaultOptions={[
            { value: '', label: t('common.form.showAllDebitorNumbers') },
            ...clientDropdowns?.company,
          ]}
          selectedOption={{
            value: companyId || '',
            label: (company && company.number) || t('common.form.showAllDebitorNumbers'),
          }}
          asyncApiPath="/companies"
          asyncLimit={500}
          asyncSort="number:asc"
          asyncSetParams={(inputValue) =>
            inputValue
              ? {
                  filters: {
                    $or: [
                      {
                        name: { $containsi: inputValue },
                      },
                      {
                        number: { $contains: inputValue },
                      },
                    ],
                  },
                }
              : {}
          }
          asyncFormatOptions={(entries) => [
            {
              label: getGroupLabel('none'),
              options: getEntries('company', entries),
            },
          ]}
          asyncInputMinLength={3}
          isClearable
          isSearchable
          onChange={onChangeCompany}
        />
      </UiDiv>
      <UiDiv>
        <FormElementLabel color={labelColor}>
          {t('common.entities.location.singular')}
          {requireFields ? ' *' : ''}
        </FormElementLabel>
        <CustomReactSelect
          layout={layout}
          asyncDefaultOptions={[{ value: '', label: t('common.form.showAllLocations') }, ...clientDropdowns.location]}
          selectedOption={{
            value: locationId || '',
            label: (location && location.description) || t('common.form.showAllLocations'),
          }}
          asyncApiPath="/locations"
          asyncLimit={500}
          asyncSort="number:asc"
          asyncSetParams={(inputValue) =>
            inputValue
              ? {
                  filters: {
                    $or: [
                      {
                        description: { $containsi: inputValue },
                      },
                      {
                        number: { $contains: inputValue },
                      },
                    ],
                  },
                }
              : {}
          }
          asyncFormatOptions={(entries) => [
            {
              label: getGroupLabel('none'),
              options: getEntries('location', entries),
            },
          ]}
          asyncInputMinLength={3}
          isClearable
          isSearchable
          onChange={onChangeLocation}
        />
      </UiDiv>
    </UiDiv>
  );
};

export default QMClientsFilter;
