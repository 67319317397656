import { map, pick } from 'lodash';
import AppUtil from '../utils/AppUtil';
import ClientsUtil from '../utils/ClientsUtil';

export const useClientsFilter = () => {
  const isDataFilter = ({ companyClientIds, locationClientIds, costcenterClientIds, serviceIds }) => {
    return companyClientIds || locationClientIds || costcenterClientIds || (serviceIds && serviceIds.$empty);
  };

  const filterObjects = (objects, filter) => {
    const objectFilter = pick(filter || {}, ['status']);
    return AppUtil.filterModels(objects, objectFilter);
  };

  const getAPIFilter = (filter) => {
    const reducedFilter = pick(filter || {}, ClientsUtil.filterKeys);

    const { companyId, locationId, costcenterId, ...filterObject } = reducedFilter;
    const apiFilter = map(filterObject, (value, key) => ({
      [key]: value,
    }));

    // setNoServices filter
    if (filter.serviceIds && filter.serviceIds.$empty) {
      apiFilter.push({
        services: {
          id: {
            $null: true,
          }
        },
      });
    }

    // map to API params
    if (companyId) {
      apiFilter.push({
        company: { id: companyId },
      });
    }
    if (locationId) {
      apiFilter.push({
        location: { id: locationId },
      });
    }
    if (costcenterId) {
      apiFilter.push({
        costcenter_rel: { id: costcenterId },
      });
    }

    if (apiFilter.length > 0) {
      return apiFilter;
    }
    return null;
  };

  const initialDataFilter = null;

  const initialTableFilter = {
    status: ['new', 'editedNotNew', 'invalid', 'original'],
  };

  const uniqueKeys = ['number'];

  return {
    uniqueKeys,
    getAPIFilter,
    isDataFilter,
    filterObjects,
    initialDataFilter,
    initialTableFilter,
  };
};
