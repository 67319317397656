import { UiDiv } from '@webfox-sc/core';
import { isEmpty, map, set } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import JobHistoryContactDiff from './JobHistoryContactDiff';
import JobHistoryDiffField from './JobHistoryDiffField';


const JobHistoryRecipientDiff: React.FC<DiffEntryProps> = ({ oldDiff, newDiff }) => {
  const { t } = useTranslation();

  const contactsMap: DiffMap = {};
  map(oldDiff?.contacts || {}, (contact, email) => {
    set(contactsMap, [email, 'old'], contact);
  });
  map(newDiff?.contacts || {}, (contact, email) => {
    set(contactsMap, [email, 'new'], contact);
  });

  return (
    <>
      <UiDiv display="flex" flexDirection="row" flexWrap="wrap">
        {(oldDiff?.costcenter || newDiff?.costcenter) && (
          <JobHistoryDiffField
            label={t('common.fields.costcenterNumber.long') || undefined}
            oldValue={oldDiff?.costcenter}
            newValue={newDiff?.costcenter}
          />
        )}
        {(oldDiff?.client_number || newDiff?.client_number) && (
          <JobHistoryDiffField
            label={t('common.fields.clientDescription.short') || undefined}
            oldValue={oldDiff?.client_number ? `${oldDiff?.client_number} ${oldDiff?.client_description}` : ''}
            newValue={newDiff?.client_number ? `${newDiff?.client_number} ${newDiff?.client_description}` : ''}
          />
        )}
        {(oldDiff?.carrier_number || newDiff?.carrier_number) && (
          <JobHistoryDiffField
            label={t('common.fields.carrierNumber.short') || undefined}
            oldValue={oldDiff?.carrier_number ? `${oldDiff?.carrier_number} ${oldDiff?.carrier_description}` : ''}
            newValue={newDiff?.carrier_number ? `${newDiff?.carrier_number} ${newDiff?.carrier_description}` : ''}
          />
        )}
        {/* contacts */}
        {!isEmpty(contactsMap) && (
          <UiDiv>
            {map(contactsMap, (contact, email) => (
              <JobHistoryContactDiff
                key={`s_${email.replace(/[^\w]+/, '')}`}
                oldDiff={contact?.old}
                newDiff={contact?.new}
                email={email}
              />
            ))}
          </UiDiv>
        )}
      </UiDiv>
    </>
  );
};

export default JobHistoryRecipientDiff;
