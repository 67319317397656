import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { craftSchema } from '../schemas';

export const fetchCrafts = (): RequestEntitiesAction<CraftData[]> =>
  requestEntities({
    method: 'GET',
    path: '/crafts',
    params: {
      sort: 'name',
    },
    schema: [craftSchema],
  });

export const createCraft = (data: CraftCreateData): RequestEntitiesAction<CraftData> =>
  requestEntities({
    method: 'POST',
    path: '/crafts',
    data,
    schema: craftSchema,
  });

export const updateCraft = (
  craftId: number,
  data: CraftUpdateData
): RequestEntitiesAction<CraftData> =>
  requestEntities({
    method: 'PUT',
    path: `/crafts/${craftId}`,
    data,
    schema: craftSchema,
  });

export const deleteCraft = (craftId: number): RequestEntitiesAction<CraftData> =>
  requestEntities({
    method: 'DELETE',
    path: `/crafts/${craftId}`,
    schema: craftSchema,
    type: 'deleteCraft',
  });

const craftAdapter = createEntityAdapter<CraftEntity>();

export const craftsSlice = createSlice({
  name: 'crafts',
  initialState: craftAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.crafts) {
        craftAdapter.upsertMany(state, action.payload.entities.crafts);
      }
      if (action.meta.arg.type === 'deleteCraft') {
        craftAdapter.removeOne(state, action.payload.data.id);
      }
    });
  },
});

export default craftsSlice.reducer;
