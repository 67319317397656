import React from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv } from '@webfox-sc/core';

import { useJob } from '../../app/entityHooks/useJob';
import { useOffer } from '../../app/entityHooks/useOffer';
import DateUtil from '../../utils/DateUtil';
import { formatPrice } from '../../utils/format';
import JobUtil from '../../utils/JobUtil';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import TextBox from '../atoms-v2/TextBox';
import TextInput from '../atoms-v2/TextInput';
import JobServiceItem from '../job-services/JobServiceItem';
import JobServicesSummary from '../job-services/JobServicesSummary';

interface JobOfferEditorProps {
  jobId: number | undefined;
  isCreateMode?: boolean;
  orderNumber?: number | string;
  location?: string;
  comments?: string;
  onChangeInput?: (key: string, value: any) => void;
}

const JobOfferEditor: React.FC<JobOfferEditorProps> = ({
  jobId,
  isCreateMode,
  orderNumber,
  location,
  comments,
  onChangeInput,
}) => {
  const { t } = useTranslation();
  const { job, recipient: jobRecipient } = useJob(jobId);
  const { offer: offer, recipient: offerRecipient } = useOffer(job?.offerId);

  const clientNumber = isCreateMode ? jobRecipient?.clientNumber : offerRecipient?.clientNumber;
  const costcenterNumber = isCreateMode ? jobRecipient?.costcenterNumber : offerRecipient?.costcenterNumber;
  const carrierNumber = isCreateMode ? jobRecipient?.carrierNumber : offerRecipient?.carrierNumber;
  const jobServices = isCreateMode ? job?.services : offer?.services;
  const createdAtFormatted = isCreateMode
    ? DateUtil.getDateFormatted(new Date())
    : DateUtil.getDateFormatted(offer?.createdAt);

  return (
    <>
      <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr 1fr 1fr' }} columnGap="xxs">
        <FormElementLabel order={{ tablet: 1 }}>{t('common.fields.clientNumber')}</FormElementLabel>
        <TextBox order={{ tablet: 5 }} background="grey20" color="ciBlue1">
          {clientNumber}
        </TextBox>
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 2 }}>
          {t('common.fields.costcenterNumber.long')}
        </FormElementLabel>
        <TextBox order={{ tablet: 6 }} background="grey20" color="ciBlue1">
          {costcenterNumber}
        </TextBox>
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 3 }}>
          {t('common.fields.carrierNumber.medium')}
        </FormElementLabel>
        <TextBox order={{ tablet: 7 }} background="grey20" color="ciBlue1">
          {carrierNumber}
        </TextBox>
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 4 }}>
          {t('common.fields.orderNumber.medium')}
        </FormElementLabel>
        {isCreateMode ? (
          <UiDiv order={{ tablet: 8 }}>
            <TextInput
              value={orderNumber}
              maxLength={128}
              onChange={(e) => {
                if (onChangeInput) {
                  onChangeInput('orderNumber', e.target.value);
                }
              }}
            />
          </UiDiv>
        ) : (
          <TextBox order={{ tablet: 8 }} background="grey20" color="ciBlue1">
            {orderNumber}
          </TextBox>
        )}
      </UiDiv>
      <UiDiv marginTop="xs" display="grid" gridTemplateColumns="1fr" columnGap="xxs">
        <FormElementLabel>{t('common.fields.location')}</FormElementLabel>
        {isCreateMode ? (
          <TextInput
            value={location}
            maxLength={512}
            onChange={(e) => {
              if (onChangeInput) {
                onChangeInput('location', e.target.value);
              }
            }}
          />
        ) : (
          <TextBox order={{ tablet: 8 }} background="grey20" color="ciBlue1">
            {location}
          </TextBox>
        )}
      </UiDiv>

      {!!jobServices && Array.isArray(jobServices) && jobServices.length > 0 && (
        <UiDiv display="flex" flexDirection="column" rowGap="xs" marginTop="s">
          {(jobServices || []).map((jobService, i) => {
            return <JobServiceItem key={String(i)} jobService={jobService} showPrices />;
          })}
        </UiDiv>
      )}

      <UiDiv marginTop="xs">
        <JobServicesSummary netPriceFormatted={formatPrice(JobUtil.getServicesTotalNetPrice(jobServices || []))} />
      </UiDiv>

      <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 3fr' }} columnGap="xxs">
        <FormElementLabel order={{ tablet: 1 }}>{t('common.fields.date')}</FormElementLabel>
        <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
          {createdAtFormatted}
        </TextBox>
        <FormElementLabel marginTop={{ mobile: 'xs', tablet: 0 }} order={{ tablet: 2 }}>
          {t('common.fields.comments')}
        </FormElementLabel>
        {isCreateMode ? (
          <UiDiv order={{ tablet: 4 }}>
            <TextInput
              value={comments}
              maxLength={512}
              onChange={(e) => {
                if (onChangeInput) {
                  onChangeInput('comments', e.target.value);
                }
              }}
            />
          </UiDiv>
        ) : (
          <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
            {comments}
          </TextBox>
        )}
      </UiDiv>
    </>
  );
};

export default JobOfferEditor;
