import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../auth/useAuth';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';

import { USE_PHONE_NUMBER } from '../../app.config';
import PageContentSubHeading from '../page/PageContentSubHeading';
import AccountPassword from './AccountPassword';
import AccountPhoneNumber from './AccountPhoneNumber';

const Account: React.FC = () => {
  const { t } = useTranslation();
  const { loggedIn } = useAuth();

  if (!loggedIn) {
    return null;
  }

  return (
    <>
      <PageHeading heading="Account" />
      <PageContent marginTop="l">
        <PageContentSubHeading leftSideText={`* ${t('common.form.requiredFields')}`}>
          {t('account.changePassword.headline')}
        </PageContentSubHeading>

        <UiDiv marginTop="s">
          <AccountPassword />
        </UiDiv>
        {USE_PHONE_NUMBER && (
          <>
            <PageContentSubHeading marginTop="l">{t('account.changePhoneNumber.headline')}</PageContentSubHeading>

            <UiDiv marginTop="s">
              <AccountPhoneNumber />
            </UiDiv>
          </>
        )}
      </PageContent>
    </>
  );
};

export default Account;
