import { pick } from 'lodash';
import AppUtil from './AppUtil';

class LocationsUtil {
    static filterKeys = ['id', 'createdAt', 'updatedAt', 'description', 'number'];

    /**
     * Returns an empty location, initialized with data provided
     * @param {Object} initialData data to initialize
     * @reutrn {Object} location
     */
    static getEmptyObject(initialData = {}) {
        const strippedInitData = pick(initialData, LocationsUtil.filterKeys);
        return {
            description: '',
            number: '',
            ...strippedInitData,
        };
    }

    static parseData(locationData = {}, options = {}) {
        const { additionalData, oldLocation } = options || {};

        const { id, createdAt, updatedAt, description, number, client_ids } = locationData;

        const baseLocation = {
            id: id || null,
            createdAt: createdAt || null,
            updatedAt: updatedAt || null,
            number: number || null,
            description: description || null,
            clientIds: client_ids || [],
        };

        let oldProps = {};
        if (oldLocation) {
            oldProps = AppUtil.getOldProps(baseLocation, oldLocation);
        }

        return {
            ...baseLocation,
            ...oldProps,
            ...additionalData,
        };
    }

    /**
     * Returns data object for a location to be used in API requests
     * @param {Object} location the location Object
     * @returns {Object} the data
     */
    static getAPIData(location) {
        const { description, number } = location;

        const data = {
            description,
            number,
        };

        return data;
    }
}

export default LocationsUtil;
