const IconRatingGood = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M50.4,0C22.79,0,.4,22.39,.4,50s22.39,50,50,50,50-22.39,50-50S78.02,0,50.4,0ZM21.27,39.86c-1.16-1.16-1.16-3.05,0-4.21,4.87-4.87,12.8-4.87,17.68,0,1.16,1.16,1.16,3.05,0,4.21-.58,.58-1.34,.87-2.1,.87s-1.52-.29-2.1-.87c-2.55-2.55-6.71-2.55-9.26,0-1.16,1.16-3.05,1.16-4.21,0Zm28.49,38.99c-12.13,0-22-9.78-22-21.79,0-1.22,1-2.21,2.23-2.21h39.54c1.23,0,2.23,.99,2.23,2.21,0,12.02-9.87,21.79-22,21.79Zm28.49-38.99c-.58,.58-1.34,.87-2.1,.87s-1.52-.29-2.1-.87c-2.55-2.55-6.71-2.55-9.26,0-1.16,1.16-3.05,1.16-4.21,0-1.16-1.16-1.16-3.05,0-4.21,4.87-4.87,12.8-4.87,17.68,0,1.16,1.16,1.16,3.05,0,4.21Z"
    />
  </svg>
);

export default IconRatingGood;
