import { UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { useAppDispatch } from '../../app/appHooks';
import { pathToDefaultBaseData, pathToQM, pathToQMAdmin } from '../../app/paths';
import { logout } from '../../app/slices/authSlice';
import IconArchive from '../../assets/icons-v2/IconArchive';
import IconCockpit from '../../assets/icons-v2/IconCockpit';
import IconFolder from '../../assets/icons-v2/IconFolder';
import IconList from '../../assets/icons-v2/IconList';
import IconLogout from '../../assets/icons-v2/IconLogout';
import IconMenu from '../../assets/icons-v2/IconMenu';
import IconMenuClose from '../../assets/icons-v2/IconMenuClose';
import IconQuality from '../../assets/icons-v2/IconQuality';
import IconUser from '../../assets/icons-v2/IconUser';
import MenuButton from '../atoms-v2/MenuButton';
import MenuOverlayButton from '../atoms-v2/MenuOverlayButton';
import { usePermissions } from '../auth/usePermissions';

const StyledMenuOverlayContent = styled.div(({ theme }) => {
  return {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: theme.page.contentMaxWidth,
    maxHeight: '100%',
    margin: '0 auto',
    padding: '133px 64px 50px 84px',
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    [theme.mediaQueries.desktop]: {
      padding: '133px 84px 50px 84px',
    },
  };
});

const PageHeader: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showMenuOverlay, setShowMenuOverlay] = useState(false);
  const permissions = usePermissions();

  const handleOnClickMenuItem = (path: string) => {
    setShowMenuOverlay(false);
    navigate(path);
  };

  return (
    <UiDiv position="relative" height="63px">
      <UiDiv position="fixed" zIndex={1001} width="100%" background="ciBlue3">
        <UiDiv maxWidth={theme.page.contentMaxWidth} margin="0 auto">
          <UiDiv display="flex" justifyContent="space-between">
            <UiDiv display="flex">
              {permissions.canViewJobs && (
                <MenuButton
                  icon={IconList}
                  active={pathname === '/jobs'}
                  onClick={() => handleOnClickMenuItem('/jobs')}
                >
                  {t('navigation.jobs')}
                </MenuButton>
              )}
              {permissions.canViewQM && (
                <MenuButton
                  icon={IconQuality}
                  active={pathname === pathToQM() || pathname.startsWith(`${pathToQM()}/`)}
                  onClick={() => handleOnClickMenuItem(pathToQM())}
                >
                  {t('navigation.qm')}
                </MenuButton>
              )}
            </UiDiv>
            <MenuButton
              icon={showMenuOverlay ? IconMenuClose : IconMenu}
              iconSize="large"
              aria-label="Menu"
              active={showMenuOverlay}
              onClick={() => setShowMenuOverlay(!showMenuOverlay)}
            />
          </UiDiv>
        </UiDiv>
      </UiDiv>
      {showMenuOverlay && (
        <UiDiv background="ciBlue1" position="fixed" zIndex={1000} top={0} left={0} right={0} bottom={0}>
          <StyledMenuOverlayContent>
            <UiDiv display="flex" flexDirection="column" gap="40px" alignItems="flex-end">
              {permissions.canViewQMAdmin && (
                <MenuOverlayButton
                  icon={IconQuality}
                  active={pathname === pathToQMAdmin() || pathname.startsWith(`${pathToQMAdmin()}/`)}
                  onClick={() => handleOnClickMenuItem(pathToQMAdmin())}
                >
                  {t('navigation.qmAdmin')}
                </MenuOverlayButton>
              )}
              {permissions.canViewBaseData && (
                <MenuOverlayButton
                  icon={IconFolder}
                  active={pathname === '/data' || pathname.includes('/data/')}
                  onClick={() => handleOnClickMenuItem(pathToDefaultBaseData(permissions))}
                >
                  {t('navigation.baseData')}
                </MenuOverlayButton>
              )}
              {permissions.canViewDashboard && (
                <MenuOverlayButton
                  icon={IconCockpit}
                  active={pathname === '/dashboard'}
                  onClick={() => handleOnClickMenuItem('/dashboard')}
                >
                  {t('navigation.dashboard')}
                </MenuOverlayButton>
              )}
              <MenuOverlayButton
                icon={IconUser}
                active={pathname === '/account'}
                onClick={() => handleOnClickMenuItem('/account')}
              >
                {t('navigation.account')}
              </MenuOverlayButton>
              {permissions.canViewJobs && (
                <MenuOverlayButton
                  icon={IconArchive}
                  active={pathname === '/archive'}
                  onClick={() => handleOnClickMenuItem('/archive')}
                >
                  {t('navigation.jobArchive')}
                </MenuOverlayButton>
              )}
              <MenuOverlayButton
                icon={IconLogout}
                onClick={() => {
                  setShowMenuOverlay(false);
                  dispatch(logout());
                  navigate('/');
                }}
              >
                {t('navigation.signOut')}
              </MenuOverlayButton>
            </UiDiv>
          </StyledMenuOverlayContent>
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default PageHeader;
