const IconResize = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M46.19,57.75,42.42,54a4,4,0,0,0-5.64,0L13.46,77.34V57.51a4,4,0,0,0-4-4H4.16a4,4,0,0,0-4,4V96a3.83,3.83,0,0,0,1.18,2.8A4,4,0,0,0,4.16,100H42.68a4,4,0,0,0,4-4V90.71a4,4,0,0,0-4-4H22.85L46.19,63.39A4,4,0,0,0,46.19,57.75ZM98.64,1.18A4,4,0,0,0,95.84,0H57.32a4,4,0,0,0-4,4V9.29a4,4,0,0,0,4,4H77.15L53.78,36.6a4,4,0,0,0,0,5.64L57.55,46a4,4,0,0,0,5.64,0L86.54,22.66V42.49a4,4,0,0,0,4,4h5.32a4,4,0,0,0,4-4V4A4,4,0,0,0,98.64,1.18Z"
    />
  </svg>
);

export default IconResize;
