import { Collapse, InlineSVG, UiDiv, UiFlex, UiText } from '@webfox-sc/core';
import _, { pick } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { REQUIRE_COMPANY_FOR_QM_SUMMARY } from '../../app.config';
import { useAppDispatch, useAppSelector } from '../../app/appHooks';
import {
  QMFilterState,
  selectQMOverviewState,
  setQMFilterState,
  setQMViewState,
} from '../../app/slices/qmOverviewSlice';
import { getReportPDFSummry } from '../../app/slices/reportsSlice';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../assets/icons-v2/IconArrowUp';
import { useClientFilterSlice } from '../../hooks/useClientFilterSlice';
import AppUtil from '../../utils/AppUtil';
import QMUtil from '../../utils/QMUtil';
import { usePermissions } from '../auth/usePermissions';
import PageContent from '../page/PageContent';
import QMClientsFilter from './QMClientsFilter';
import QMFilterSubmit from './QMFilterSubmit';
import QMStatusFilter from './QMStatusFilter';

interface QMFilterProps {
  onSubmit: () => void;
}

const QMFilter: React.FC<QMFilterProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { canCreateQMSummary } = usePermissions();

  const filter = useAppSelector(selectQMOverviewState);
  const handleChangeDataFilter = useCallback(
    (newFilter: QMFilterState) => {
      // not sure if omit is needed
      dispatch(setQMFilterState(_.omit(newFilter, ['clientID'])));
    },
    [dispatch]
  );

  const dynamicClientFilter = useClientFilterSlice();
  const setShowFilter = (show: boolean) => {
    dispatch(setQMViewState({ showFilter: show }));
  };

  const { showFilter } = filter;

  // minimum filters for report summary
  const hasParamsForSummary =
    !!filter.startDate &&
    !!filter.endDate &&
    (!!filter.craftId || filter.craftId === 0) &&
    (!REQUIRE_COMPANY_FOR_QM_SUMMARY || !!filter.companyNumber);

  const onCreateSummary = async () => {
    const res = await dispatch(
      getReportPDFSummry(
        QMUtil.getAPIFilter(
          {
            ...pick(filter, QMUtil.SUMMARY_FILTER_KEYS),
            status: QMUtil.QM_STAUS_OPTONS.FINISHED,
          },
          'reports'
        )
      )
    );
    AppUtil.openDownload(res.payload?.data);
  };

  return (
    <UiDiv background="ciBlue2" marginTop="m" paddingTop="s" paddingBottom="s">
      <PageContent>
        <UiDiv
          display="flex"
          justifyContent="space-between"
          cursor="pointer"
          onClick={() => setShowFilter(!showFilter)}
        >
          <UiText variant="h2" color="white">
            {t('report.headline.filterReports')}
          </UiText>
          <UiFlex width="22px" height="22px" justifyContent="center" alignItems="center">
            <InlineSVG width="30px" height="30px" color="white">
              {showFilter ? IconArrowUp : IconArrowDown}
            </InlineSVG>
          </UiFlex>
        </UiDiv>
        <Collapse show={showFilter} fadeInOut keepChildren={false}>
          <UiDiv marginTop="m" paddingTop="s" paddingBottom="s">
            <QMClientsFilter
              onChangeFilter={handleChangeDataFilter}
              dynamicClientFilter={dynamicClientFilter}
              layout="outline"
              useCrafts={true}
            />
            <QMStatusFilter filter={filter} onChangeFilter={handleChangeDataFilter} />
            <QMFilterSubmit
              searchDisabled={!filter?.isDirty}
              summaryDisabled={!hasParamsForSummary}
              onCreateSummary={canCreateQMSummary ? onCreateSummary : undefined}
              onSubmit={onSubmit}
            />
          </UiDiv>
        </Collapse>
      </PageContent>
    </UiDiv>
  );
};

export default QMFilter;
