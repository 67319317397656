const IconArrowDown = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M54.35,80.66a5.62,5.62,0,0,1-8.7,0L1.28,26.46a5.62,5.62,0,0,1,4.35-9.18H94.37a5.62,5.62,0,0,1,4.35,9.18Z"
    />
  </svg>
);

export default IconArrowDown;
