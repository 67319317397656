/* eslint-disable */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import UserUtil from '../../utils/UserUtil';
import AuthOverlay from '../../components/elements/AuthOverlay';
import Section from '../../atoms/Section';
import HeadlineThree from '../../components/elements/HeadlineThree';
import CopyTextM from '../../components/elements/CopyTextM';
import BasicInput from '../../components/elements/BasicInput';
import ToggleBox from '../../components/elements/ToggleBox';
import Advice from '../../components/elements/Advice';
import ButtonSecondary from '../../atoms/ButtonSecondary';

class ForgotPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSuccess: false,
      isError: false,
      errorMessage: '',
    };
  }

  handleFocusEmail() {
    this.setState({
      isError: false,
      errorMessage: '',
    });
  }

  handleChangeEmail(value) {
    this.setState({
      email: value,
    });
  }

  async handleSubmit(e) {
    e && e.preventDefault();
    const { email } = this.state;

    try {
      await UserUtil.sendForgotPassword(email.toLowerCase());
      this.setState({
        isSuccess: true,
      });
    } catch (error) {
      this.setState({
        isError: true,
        errorMessage: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      });
    }
  }

  render() {
    const { email, isSuccess, isError, errorMessage } = this.state;

    return (
      <AuthOverlay>
        <HeadlineThree text="Passwort ändern" />
        {isSuccess ? (
          <Section paddingTop="S">
            <CopyTextM
              text="Sie haben eine E-Mail erhalten.<br />
                                    Bitte folgen Sie den Anweisungen in der E-Mail."
            />
          </Section>
        ) : (
          <Fragment>
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <Section paddingTop="S">
                <CopyTextM text="Bitte geben Sie zunächst Ihre E-Mail-Adresse ein:" />
              </Section>
              <Section paddingTop="XS">
                <BasicInput
                  layout="white"
                  type="text"
                  value={email}
                  name="email"
                  isError={isError}
                  onFocus={() => this.handleFocusEmail()}
                  onChange={(value) => this.handleChangeEmail(value)}
                />
              </Section>
              <Section paddingTop="S">
                <ButtonSecondary layout="white" label="Absenden" type="submit" />
              </Section>
              <ToggleBox show={isError}>
                <Section paddingTop="S">
                  <Advice text={errorMessage} isError={true} />
                </Section>
              </ToggleBox>
            </form>
          </Fragment>
        )}
        <Section paddingTop="M">
          <Link to="/">
            <span>Zum Login</span>
          </Link>
        </Section>
      </AuthOverlay>
    );
  }
}

export default ForgotPage;
