const IconLogout = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M99.68,44.34a4.06,4.06,0,0,0-.9-1.34L86.28,30.72a4.21,4.21,0,0,0-5.89,0,4,4,0,0,0,0,5.79l5.39,5.3H62.5a4.1,4.1,0,1,0,0,8.19H85.78l-5.39,5.3a4,4,0,0,0,0,5.79,4.21,4.21,0,0,0,5.89,0L98.78,48.8a3.91,3.91,0,0,0,.9-1.33A4,4,0,0,0,99.68,44.34ZM70.84,58.19a4.13,4.13,0,0,0-4.17,4.1V82.77H50V17.23a4.1,4.1,0,0,0-3-3.92L32.56,9H66.67V29.52a4.17,4.17,0,0,0,8.33,0V4.94A4.13,4.13,0,0,0,70.84.84H4.17c-.15,0-.29.07-.43.08a4.65,4.65,0,0,0-.56.1A4.2,4.2,0,0,0,2,1.53c-.09.06-.2.06-.29.13s-.05.07-.08.09A4.18,4.18,0,0,0,.49,3a2.35,2.35,0,0,0-.11.35,3.52,3.52,0,0,0-.33,1,2,2,0,0,0,0,.35c0,.09-.06.16-.06.24V86.87a4.11,4.11,0,0,0,3.35,4L45,99.07a3.75,3.75,0,0,0,.82.09,4.25,4.25,0,0,0,2.64-.93A4.08,4.08,0,0,0,50,95.06V91H70.84A4.12,4.12,0,0,0,75,86.87V62.29A4.13,4.13,0,0,0,70.84,58.19Z"
    />
  </svg>
);

export default IconLogout;
