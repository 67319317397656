import React from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { UiDiv, UiSpan, UiText } from '@webfox-sc/core';

import BackLink from '../atoms-v2/BackLink';
import Button from '../atoms-v2/Button';

import PageContent from './PageContent';
import PageHeadingButton from './PageHeadingButton';

interface PageHeadingProps {
  heading?: string | number;
  subheading?: string;
  backlinkLabel?: string;
  backlinkPath?: string;
  preferHistoryBack?: boolean;
  buttonLabel?: string;
  buttonLabelMobile?: string;
  buttonIcon?: React.ReactNode;
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
  wrapMobileButton?: boolean;
}

const PageHeading: React.FC<PageHeadingProps> = ({
  heading,
  subheading,
  backlinkLabel,
  backlinkPath,
  preferHistoryBack,
  buttonLabel,
  buttonLabelMobile,
  buttonIcon,
  onClickButton,
  wrapMobileButton,
}) => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const showBacklink = !!backlinkLabel && !!backlinkPath;

  const handleOnClickBacklink: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    if ((!preferHistoryBack || navigationType === 'POP') && !!backlinkPath) {
      /**
       * use backlinkPath
       */
      navigate(backlinkPath);
    } else {
      /**
       * use browser history back
       */
      navigate(-1);
    }
  };

  const showButton = !!buttonLabel && !!onClickButton;

  const backlinkComponent = (
    <BackLink href={backlinkPath} onClick={handleOnClickBacklink}>
      {backlinkLabel}
    </BackLink>
  );

  return (
    <UiDiv paddingTop={{ mobile: showBacklink ? '40px' : 'xl', tablet: 'xl' }} background="ciBlue1">
      <PageContent>
        {showBacklink && (
          <UiDiv display={{ mobile: 'block', tablet: 'none' }} marginBottom={{ mobile: '30px', tablet: undefined }}>
            {backlinkComponent}
          </UiDiv>
        )}
        <UiDiv display="grid" gridTemplateColumns="1fr auto 1fr" alignItems="center">
          <UiDiv>
            {showBacklink && <UiDiv display={{ mobile: 'none', tablet: 'block' }}>{backlinkComponent}</UiDiv>}
          </UiDiv>
          <UiDiv>
            <UiText variant="h1" color="white" textAlign="center">
              {heading}
            </UiText>
          </UiDiv>
          <UiDiv>
            {showButton && (
              <UiDiv
                justifyContent="flex-end"
                display={{ mobile: wrapMobileButton ? 'none' : 'flex', desktop: 'flex' }}
              >
                <PageHeadingButton icon={buttonIcon} onClick={onClickButton}>
                  <UiSpan display={{ mobile: 'none', tablet: 'inline' }}>{buttonLabel}</UiSpan>
                  <UiSpan display={{ mobile: 'inline', tablet: 'none' }}>{buttonLabelMobile || buttonLabel}</UiSpan>
                </PageHeadingButton>
              </UiDiv>
            )}
          </UiDiv>
        </UiDiv>
        {subheading && (
          <UiDiv marginTop={{ mobile: 0, tablet: 'xs' }}>
            <UiText variant="h1" color="white" textAlign="center">
              {subheading}
            </UiText>
          </UiDiv>
        )}
        {showButton && wrapMobileButton && (
          <UiDiv marginTop="s" display={{ mobile: 'flex', desktop: 'none' }} justifyContent="center">
            <Button icon={buttonIcon} onClick={onClickButton}>
              <UiSpan>{buttonLabelMobile || buttonLabel}</UiSpan>
            </Button>
          </UiDiv>
        )}
      </PageContent>
    </UiDiv>
  );
};

export default PageHeading;
