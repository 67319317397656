import { useMemo } from 'react';
import { entitySelectors } from '../entitySelectors';

export const useCrafts = (): {
  crafIds: NullableId[];
  crafts: CraftEntity[];
} => {
  const crafts = entitySelectors.selectAll<CraftEntity>('crafts');
  const crafIds = useMemo(() => crafts.map(({ id }) => id), [crafts]);

  return {
    crafIds,
    crafts,
  };
};
