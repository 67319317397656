import { REDUX_ACTIONS } from '../global/constants';

function costcentersReducer(
    state = {
        isCostcentersRequest: false,
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_COSTCENTERS:
            return { ...state, isCostcentersRequest: true };

        case REDUX_ACTIONS.REQUEST_COSTCENTERS_ERROR:
            return { ...state, isCostcentersRequest: false };

        case REDUX_ACTIONS.RECEIVE_COSTCENTERS:
            return { ...state, isCostcentersRequest: false };

        case REDUX_ACTIONS.UPDATE_COSTCENTER_MODELS:
            return { ...state, costcenterModels: action.costcenterModels };

        default:
            return state;
    }
}

export default costcentersReducer;
