import { InlineSVG, UiDiv, UiText } from '@webfox-sc/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import IconInfo from '../../assets/icons-v2/IconInfo';
import { useContentContext } from '../content/ContentContext';

const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

const JobServiceDlaInfoIcon: React.FC = () => {
  const { dlaInfo } = useContentContext();
  const { colors } = useTheme();

  const ref = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  useOnClickOutside(ref, () => setIsPopupOpen(false));

  return (
    <UiDiv position="relative" boxSizing="border-box" width="20px" height="20px" padding="3px">
      <UiDiv cursor="pointer" onClick={() => setIsPopupOpen(!isPopupOpen)}>
        <InlineSVG width="14px" height="14px" color={colors.ciBlue3}>
          {IconInfo}
        </InlineSVG>
      </UiDiv>
      {isPopupOpen && (
        <UiDiv position="absolute" top={0} left={0}>
          <UiText
            innerRef={ref}
            variant="copy"
            position="absolute"
            bottom={0}
            left={0}
            padding="xs"
            background="white"
            border={`2px solid ${colors.ciBlue3}`}
            whiteSpace="nowrap"
          >
            {dlaInfo.map((entry, index) => (
              <Fragment key={`c_${index}`}>
                {entry}
                <br />
              </Fragment>
            ))}
          </UiText>
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default JobServiceDlaInfoIcon;
