import { entitySelectors } from '../entitySelectors';

export const useCheckitem = (
  checkitemId: number | undefined
): {
  checkitem: CheckitemEntity | undefined;
  checkitemtype: CheckitemtypeEntity | undefined;
} => {
  const checkitem = entitySelectors.selectById<CheckitemEntity>('checkitems', checkitemId);
  const checkitemtype = entitySelectors.selectById<CheckitemtypeEntity>('checkitemtypes', checkitem?.checkitemtypeId);

  return {
    checkitem,
    checkitemtype,
  };
};
