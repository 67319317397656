import { Block, UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../app/appHooks';
import { pathToQMAdminCompany, pathToQMAdminCompanyConfig } from '../../../app/paths';
import { fetchCompany, updateCompany } from '../../../app/slices/companiesSlice';
import {
  fetchGlobalRoomgroups
} from '../../../app/slices/roomgroupsSlice';
import QMUtil from '../../../utils/QMUtil';
import PageContent from '../../page/PageContent';
import PageHeading from '../../page/PageHeading';
import { useQMAdminCompany } from '../useQMAdminCompany';

import { useCraftOptions } from '../../../app/entityHooks/useCraftOptions';
import { fetchCheckitemtypes } from '../../../app/slices/checkitemtypesSlice';
import { fetchCrafts } from '../../../app/slices/craftsSlice';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import CustomReactSelect from '../../CustomReactSelect/CustomReactSelect';
import Button from '../../atoms-v2/Button';
import FormElementLabel from '../../atoms-v2/FormElementLabel';

const QMAdminCompanyConfigAdd: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companyId: companyIdString } = useParams();
  const companyId = companyIdString ? parseInt(companyIdString) : -1;

  const { company } = useQMAdminCompany(companyId);
  const [craft, setCraft] = useState<number | null>(null);
  const { craftOptions } = useCraftOptions();

  // TODO check if we need to handle case that roomgroups exist for company and craft, but craft is not enabled
  const companyCrafts = company?.craftIds || [];
  const filteredCraftOptions = craftOptions.filter(
    ({ value }) => typeof value !== 'string' && !companyCrafts.find((craftId) => craftId === value)
  );

  useEffect(() => {
    const fetchData = async () => {
      if (companyId) {
        dispatch(fetchCheckitemtypes());
        dispatch(fetchGlobalRoomgroups());
        dispatch(fetchCrafts());
        dispatch(fetchCompany(companyId, { populate: QMUtil.companyPopulateOptions }));
      }
    };
    fetchData();
  }, [dispatch, companyId]);

  if (!companyId || !company) {
    return null;
  }

  const handleOnAdd = () => {
    if (craft !== null) {
      dispatch(updateCompany(companyId, { crafts: [...companyCrafts, craft] }, { populate: QMUtil.companyPopulateOptions })).then(() =>
        navigate(pathToQMAdminCompanyConfig(companyId, craft))
      );
    }
  };

  // return to company page
  const handleOnCancel = () => {
    navigate(pathToQMAdminCompany(companyId));
  };

  // change if we need to add default craft
  const canAdd = !!craft;

  return (
    <>
      <PageHeading
        heading={company.number}
        subheading={company.name}
        backlinkLabel="Zurück"
        backlinkPath={pathToQMAdminCompany(companyId)}
      />
      <PageContent paddingTop="l">
        <UiText variant="h2" color="white" textAlign="center">
          {t('report.headline.addConfig')}
        </UiText>
        <UiDiv padding="m" background="white" marginTop="m">
          <UiText variant="copy">{t('schedule.info.addCraft')}</UiText>
          <Block paddingTop="m" paddingBottom="xs" display="flex" gap="s">
            <UiDiv flexGrow={1}>
              <FormElementLabel>{t('common.fields.craft')}</FormElementLabel>
              <CustomReactSelect
                options={filteredCraftOptions}
                selectedValue={craft}
                isSearchable
                isClearable
                onChangeValue={(value) => setCraft(value || null)}
                placeholder={t('common.actions.select')}
              />
            </UiDiv>
          </Block>
          <Block paddingTop="s" display="flex" justify="center" gap="xs">
            <Button onClick={handleOnAdd} disabled={!canAdd} icon={IconPlus}>
              {t('common.actions.add')}
            </Button>
            <Button onClick={handleOnCancel} icon={IconClose} variant="secondary">
              {t('common.actions.cancel')}
            </Button>
          </Block>
        </UiDiv>
      </PageContent>
    </>
  );
};

export default QMAdminCompanyConfigAdd;
