import { Block } from '@webfox-sc/core';
import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components';

import { SHOW_MAINTENANCE_VIEW, SHOW_MOVE_JOBS } from './app.config';
import { useAppDispatch } from './app/appHooks';
import { pathSchemas } from './app/paths';
import { autoLogin } from './app/slices/authSlice';
import { fetchCostcenters } from './app/slices/costcentersSlice';
import { setQMFilterState } from './app/slices/qmOverviewSlice';
import Account from './components/account/Account';
import { useAuth } from './components/auth/useAuth';
import { usePermissions } from './components/auth/usePermissions';
import BaseData from './components/base-data/BaseData';
import MoveJobs from './components/base-data/move-jobs/MoveJobs';
import BaseDataQMCheckitemgrouptypes from './components/base-data/qm-checkitemgrouptypes/BaseDataQMCheckitemgrouptypes';
import BaseDataQMCheckitemtypes from './components/base-data/qm-checkitemtypes/BaseDataQMCheckitemtypes';
import BaseDataQMCrafts from './components/base-data/qm-craft/BaseDataQMCrafts';
import BaseDataQM from './components/base-data/qm/BaseDataQM';
import BaseDataUsers from './components/base-data/users/BaseDataUsers';
import ContentProvider from './components/content/ContentContext';
import ContentView from './components/content/ContentView';
import Dashboard from './components/dashboard/Dashboard';
import ErrorDialog from './components/error/ErrorDialog';
import Job from './components/job/Job';
import JobCreate from './components/job/JobCreate';
import Jobs from './components/jobs/Jobs';
import Landing from './components/landing/Landing';
import PageFooter from './components/page/PageFooter';
import PageHeader from './components/page/PageHeader';
import PageScrollHandler from './components/page/PageScrollHandler';
import QMAdminClientEdit from './components/qm-admin/qm-admin-client/QMAdminClientEdit';
import QMAdminClientReports from './components/qm-admin/qm-admin-client/QMAdminClientReports';
import QMAdminCompany from './components/qm-admin/qm-admin-company/QMAdminCompany';
import QMAdminClientConfig from './components/qm-admin/qm-admin-client/QMAdminClientConfig';
import QMAdminCompanyConfig from './components/qm-admin/qm-admin-company/QMAdminCompanyConfig';
import QMAdminStart from './components/qm-admin/qm-admin-start/QMAdminStart';
import QMAddReport from './components/qm/QMAddReport';
import QMReport from './components/qm/QMReport';
import QMReportroom from './components/qm/QMReportroom';
import QMStart from './components/qm/QMStart';
import Toast from './components/toast/Toast';
import ToastProvider from './components/toast/ToastProvider';
import AuthPage from './container/AuthPage';
import ForgotPage from './container/ForgotPage';
import ResetPage from './container/ResetPage';
import ViewBaseDataClientGroups from './container/ViewBaseDataClientGroups';
import ViewBaseDataClients from './container/ViewBaseDataClients';
import ViewBaseDataCompanies from './container/ViewBaseDataCompanies';
import ViewBaseDataCostcenters from './container/ViewBaseDataCostcenters';
import ViewBaseDataImport from './container/ViewBaseDataImport';
import ViewBaseDataLocations from './container/ViewBaseDataLocations';
import ViewBaseDataServices from './container/ViewBaseDataServices';
import ViewMaintenance from './container/ViewMaintenance';
import { appTheme } from './theme-v2/appTheme';
import './utils/i18n';
import QMAdminCompanyConfigAdd from './components/qm-admin/qm-admin-company/QMAdminCompanyConfigAdd';

interface GlobalStyleProps {
  backgroundColor?: string;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>(({ backgroundColor, theme }) => {
  return {
    html: {
      fontSize: '100%',
      WebkitTapHighlightColor: 'transparent',
    },
    body: {
      padding: 0,
      margin: 0,
      ...theme.textVariants.copy,
      color: theme.colors.white,
      background: backgroundColor || theme.colors.ciBlue1,
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    a: {
      color: theme.colors.ciRed,
    },
  };
});

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { currentUser, status: authStatus } = useAuth();
  const { userHasRole, ...permissions } = usePermissions();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const handleOnMount = async () => {
      await dispatch(autoLogin());
      setIsMounted(true);
    };

    if (!isMounted) {
      handleOnMount();
    }
  }, [dispatch, isMounted]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(
        fetchCostcenters({
          populate: {
            clients: {
              populate: {
                company: {
                  fields: ['name', 'number'],
                },
                location: {
                  fields: ['description', 'number'],
                },
              },
              fields: ['is_group', 'debitor_id'],
            },
          },
          fields: ['number'],
          pagination: {
            limit: -1,
          },
        })
      );
    }
  }, [dispatch, currentUser?.id]);

  useEffect(() => {
    // default filter to show own qm schedules and reports only
    if (permissions.canViewQM && currentUser?.id) {
      dispatch(setQMFilterState({ userId: currentUser.id || null }));
    }
  }, [currentUser?.id, dispatch, permissions.canViewQM]);

  if (!isMounted || authStatus === 'autoLogin') {
    return null;
  }

  return (
    <Suspense fallback={<></>}>
      <StyledThemeProvider theme={appTheme}>
        <ContentProvider>
          {SHOW_MAINTENANCE_VIEW ? (
            <>
              <ViewMaintenance maintenanceIndex={SHOW_MAINTENANCE_VIEW - 1} />
              <GlobalStyle />
            </>
          ) : (
            <ToastProvider>
              <PageScrollHandler>
                <Block display="flex" flexDirection="column" minHeight="100vh">
                  <Block flexGrow={1} width="100%">
                    {authStatus === 'loggedIn' && <PageHeader />}
                    <Routes>
                      {authStatus === 'loggedIn' && (
                        <>
                          <Route
                            path="/"
                            element={
                              userHasRole(['qm-prüfer']) ? (
                                <Navigate replace to="/qm" />
                              ) : userHasRole(['qm-admin']) ? (
                                <Navigate replace to="/qm-admin" />
                              ) : (
                                <Navigate replace to="/jobs" />
                              )
                            }
                          />
                          <Route path="/jobs" element={<Jobs />} />
                          <Route path="/archive" element={<Jobs viewMode="archive" />} />
                          <Route path="/job/:jobId" element={<Job />} />
                          <Route path="/addjob" element={<JobCreate />} />
                          {permissions.canViewQMAdmin && (
                            <>
                              <Route path={pathSchemas.qmAdmin} element={<QMAdminStart />} />
                              <Route path={pathSchemas.qmAdminCompany} element={<QMAdminCompany />} />
                              <Route path={pathSchemas.qmAdminCompanyConfig} element={<QMAdminCompanyConfig />} />
                              <Route path={pathSchemas.qmAdminCompanyConfigAdd} element={<QMAdminCompanyConfigAdd />} />
                              <Route path={pathSchemas.qmAdminClient} element={<QMAdminClientEdit />} />
                              <Route path={pathSchemas.qmAdminClientConfig} element={<QMAdminClientConfig />} />
                              <Route path={pathSchemas.qmAdminClientReports} element={<QMAdminClientReports />} />
                            </>
                          )}
                          {permissions.canViewQM && (
                            <>
                              <Route path={pathSchemas.qm} element={<QMStart />} />
                              <Route path={pathSchemas.qmAdd} element={<QMAddReport />} />
                              <Route path={pathSchemas.qmReportroomEdit} element={<QMReportroom editMode />} />
                            </>
                          )}
                          {(permissions.canViewQMAdmin || permissions.canViewQM) && (
                            <>
                              <Route path={pathSchemas.qmReport} element={<QMReport />} />
                              <Route path={pathSchemas.qmReportroom} element={<QMReportroom />} />
                            </>
                          )}
                          {permissions.canViewBaseData && (
                            <Route path="/data" element={<BaseData />}>
                              {permissions.canViewAllUsers && (
                                <Route path="users" element={<BaseDataUsers />} />
                              )}
                              {permissions.canViewGeneralBaseData && (
                                <>
                                  <Route path="companies" element={<ViewBaseDataCompanies />} />
                                  <Route path="locations" element={<ViewBaseDataLocations />} />
                                  <Route path="clientgroups" element={<ViewBaseDataClientGroups />} />
                                  <Route path="objects" element={<ViewBaseDataClients />} />
                                  <Route path="services" element={<ViewBaseDataServices />} />
                                  <Route path="import" element={<ViewBaseDataImport />} />
                                </>
                              )}
                              {permissions.canViewCostcenterBaseData && (
                                <Route path="costcenter" element={<ViewBaseDataCostcenters />} />
                              )}
                              {permissions.canViewQMAdmin && (
                                <>
                                  <Route path="checkitems" element={<BaseDataQMCheckitemtypes />} />
                                  <Route path="checkitemgroups" element={<BaseDataQMCheckitemgrouptypes />} />
                                  <Route path="crafts" element={<BaseDataQMCrafts />} />
                                  <Route path="qm" element={<BaseDataQM />} />
                                </>
                              )}
                              {SHOW_MOVE_JOBS && <Route path="copy/:clientId" element={<MoveJobs />} />}
                            </Route>
                          )}
                          {permissions.canViewDashboard && (
                            <>
                              <Route path="/dashboard" element={<Dashboard />} />
                            </>
                          )}
                          <Route path="/account" element={<Account />} />
                          <Route path="*" element={<div>404</div>} />
                        </>
                      )}
                      <Route path="/offer/:id/:hash" element={<Landing defaultViewType="offer" />} />
                      <Route path="/certificate/:id/:hash" element={<Landing defaultViewType="certificate" />} />
                      <Route path="/password/new" element={<ForgotPage />} />
                      <Route path="/password/set" element={<ResetPage />} />
                      <Route path="/privacy" element={<ContentView selector="privacy" />} />
                      <Route path="/imprint" element={<ContentView selector="imprint" />} />
                      <Route path="*" element={<AuthPage />} />
                    </Routes>
                  </Block>
                  <Block flexGrow={0} width="100%" padding="50px 0 30px 0">
                    <PageFooter />
                  </Block>
                </Block>
              </PageScrollHandler>
              <Toast />
              <ErrorDialog />
              <GlobalStyle
                backgroundColor={
                  // pathname === '/qm' ||
                  pathname.startsWith('/offer/') || pathname.startsWith('/certificate/')
                    ? appTheme.colors.ciBlue3
                    : undefined
                }
              />
            </ToastProvider>
          )}
        </ContentProvider>
      </StyledThemeProvider>
    </Suspense>
  );
};

export default App;
