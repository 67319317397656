import { Collapse, UiDiv, UiText } from '@webfox-sc/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { updateRoomgroup } from '../../../app/slices/roomgroupsSlice';
import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconArrowUp from '../../../assets/icons-v2/IconArrowUp';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconEdit from '../../../assets/icons-v2/IconEdit';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import { moveArrayElement } from '../../../utils/arrayUtils';
import IconButton from '../../atoms-v2/IconButton';
import TextButton from '../../atoms-v2/TextButton';
import TextIcon from '../../atoms-v2/TextIcon';

import { useTheme } from 'styled-components';
import BaseDataQMCheckitemgroup from './BaseDataQMCheckitemgroup';
import BaseDataQMCheckitemgroupForm from './BaseDataQMCheckitemgroupForm';
import BaseDataQMDropTarget from './BaseDataQMDropTarget';
import BaseDataQMRoomgroupForm from './BaseDataQMRoomgroupForm';
import { useBaseDataQMRoomgroup } from './useBaseDataQMRoomgroup';

interface BaseDataQMRoomgroupProps {
  roomgroupId: number | undefined;
}

const BaseDataQMRoomgroup: React.FC<BaseDataQMRoomgroupProps> = ({ roomgroupId }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const { roomgroup } = useBaseDataQMRoomgroup(roomgroupId);
  const [checkitemgroupIds, setCheckitemgroupIds] = useState(roomgroup?.checkitemgroupIds || []);

  useEffect(() => {
    setCheckitemgroupIds(roomgroup?.checkitemgroupIds || []);
  }, [roomgroup?.checkitemgroupIds]);

  const [showContent, setShowContent] = useState(true);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showCreateCheckitemgroupForm, setShowCreateCheckitemgroupForm] = useState(false);

  if (!roomgroup) {
    return null;
  }

  const handleOnDropCheckitemgroup = (item: DragItem, dropTargetIndex: number) => {
    if (item.parentId === roomgroupId) {
      const checkitemgroupIdsNew = moveArrayElement(
        checkitemgroupIds,
        item.index,
        dropTargetIndex < item.index ? dropTargetIndex : dropTargetIndex - 1
      );

      setCheckitemgroupIds(checkitemgroupIdsNew);

      dispatch(
        updateRoomgroup(roomgroup.id, {
          checkitemgroups: checkitemgroupIdsNew,
        })
      );
    }
  };

  return (
    <UiDiv>
      <UiDiv padding="15px" background="white">
        <UiDiv display="grid" gridTemplateColumns="1fr 20% 22px 22px" columnGap="s" alignItems="center">
          <UiText variant="copy">
            <b>{roomgroup.name}</b>
          </UiText>
          <UiDiv display="flex" gap="xs" justifyContent="flex-end" alignItems="center">
            {!roomgroup.disabled && (
              <>
                <TextIcon icon={IconCheck} color={colors.trafficLightGreen} />
                <UiText variant="copySmall">{t('common.qm.preSelected')}</UiText>
              </>
            )}
          </UiDiv>
          <IconButton icon={IconEdit} onClick={() => setShowEditForm(!showEditForm)} />
          <IconButton icon={showContent ? IconArrowUp : IconArrowDown} onClick={() => setShowContent(!showContent)} />
        </UiDiv>
      </UiDiv>
      <Collapse show={showEditForm} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey20">
            <BaseDataQMRoomgroupForm
              roomgroupId={roomgroup.id}
              onSubmit={() => setShowEditForm(false)}
              onClickCancel={() => setShowEditForm(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
      <Collapse show={showContent} fadeInOut>
        <UiDiv paddingTop="1px">
          <UiDiv padding="s" background="grey20">
            <UiText variant="copy">{t('checkitemGroup.headline.plural')}</UiText>
            {checkitemgroupIds.length > 0 && (
              <>
                <UiDiv display="flex" flexDirection="column" marginTop="xs">
                  {checkitemgroupIds.map((checkitemgroupId, index) => {
                    return (
                      <Fragment key={checkitemgroupId}>
                        <BaseDataQMDropTarget
                          parentId={roomgroup.id}
                          dropTargetIndex={index}
                          accept="checkitemgroup"
                          onDrop={handleOnDropCheckitemgroup}
                        />
                        <BaseDataQMCheckitemgroup
                          checkitemgroupId={checkitemgroupId}
                          roomgroupId={roomgroup.id}
                          index={index}
                        />
                      </Fragment>
                    );
                  })}
                </UiDiv>
                <BaseDataQMDropTarget
                  parentId={roomgroup.id}
                  dropTargetIndex={checkitemgroupIds.length}
                  accept="checkitemgroup"
                  onDrop={handleOnDropCheckitemgroup}
                />
              </>
            )}
            <UiDiv marginTop="xs">
              <TextButton
                icon={IconPlus}
                onClick={() => setShowCreateCheckitemgroupForm(!showCreateCheckitemgroupForm)}
              >
                {t('checkitemGroup.actions.create')}
              </TextButton>
            </UiDiv>
            <Collapse show={showCreateCheckitemgroupForm} fadeInOut keepChildren={false}>
              <UiDiv paddingTop="xs">
                <UiDiv padding="s" background="grey20">
                  <BaseDataQMCheckitemgroupForm
                    roomgroupId={roomgroup.id}
                    onSubmit={() => setShowCreateCheckitemgroupForm(false)}
                    onClickCancel={() => setShowCreateCheckitemgroupForm(false)}
                  />
                </UiDiv>
              </UiDiv>
            </Collapse>
          </UiDiv>
        </UiDiv>
      </Collapse>
    </UiDiv>
  );
};

export default BaseDataQMRoomgroup;
