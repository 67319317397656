import { entitySelectors } from '../entitySelectors';

export const useCheckresult = (
  checkresultId: number | undefined
): {
  checkresult: CheckresultEntity | undefined;
  pictures: PictureEntity[];
} => {
  const checkresult = entitySelectors.selectById<CheckresultEntity>('checkresults', checkresultId);
  const pictures = entitySelectors.selectByIds<PictureEntity>('pictures', checkresult?.pictureIds);

  return {
    checkresult,
    pictures,
  };
};
