/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './copy-text-s.scss';

class CopyTextS extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return (
            <span className="smg-copy-text-s" dangerouslySetInnerHTML={{__html: text}} />
        );
    }
}

CopyTextS.propTypes = {
    text: PropTypes.string,
};

CopyTextS.defaultProps = {
    text: '',
};

export default CopyTextS;
