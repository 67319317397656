const monthNamesDe = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

class DateUtil {
  /**
   * @return string 'DD.MM.YYYY'
   */
  static getDateFormatted(time) {
    if (!time) {
      return '';
    }
    const date = time ? new Date(time) : new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const outputDay = day < 10 ? `0${String(day)}` : String(day);
    const outputMonth = month < 10 ? `0${String(month)}` : String(month);
    return `${outputDay}.${outputMonth}.${String(date.getFullYear())}`;
  }

  /**
   * @return string 'DD. [Monat] YYYY'
   */
  static getDateFormattedLong(time) {
    const date = time ? new Date(time) : new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const outputDay = day < 10 ? `0${String(day)}` : String(day);
    const outputMonth = monthNamesDe[month];
    return `${outputDay}. ${outputMonth} ${String(date.getFullYear())}`;
  }

  /**
   * @return string 'DD. [Monat] YYYY'
   */
  static getDateFormattedShort(time) {
    const date = time ? new Date(time) : new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const outputDay = day < 10 ? `0${String(day)}` : String(day);
    const outputMonth = month < 10 ? `0${String(month)}` : String(month);
    const year = String(date.getFullYear()).substring(2, 4);
    return `${outputDay}.${outputMonth}.${year}`;
  }

  /**
   * @return string 'HH:mm'
   */
  static getTimeFormatted(time) {
    const date = time ? new Date(time) : new Date();
    const outputHours = date.getHours() < 10 ? `0${String(date.getHours())}` : String(date.getHours());
    const outputMinutes = date.getMinutes() < 10 ? `0${String(date.getMinutes())}` : String(date.getMinutes());
    return `${outputHours}:${outputMinutes}`;
  }

  /**
   * @return string 'HH:mm:ss'
   */
  static getFullTimeFormatted(time) {
    const date = time ? new Date(time) : new Date();
    const outputSeconds = date.getSeconds() < 10 ? `0${String(date.getSeconds())}` : String(date.getSeconds());
    return `${DateUtil.getTimeFormatted(time)}:${outputSeconds}`;
  }

  /**
   * @return string 'DD.MM.YYYY HH:mm'
   */
  static getDateTimeFormatted(time = new Date()) {
    return `${DateUtil.getDateFormatted(time)} ${DateUtil.getTimeFormatted(time)}`;
  }

  /**
   * @return string 'DD.MM.YYYY, HH:mm Uhr'
   */
  static getDateTimeFormattedExtended(time) {
    if (!time) {
      return '';
    }
    return `${DateUtil.getDateFormatted(time)}, ${DateUtil.getTimeFormatted(time)} Uhr`;
  }

  /**
   * @return string 'DD.MM.YYYY HH:mm:ss'
   */
  static getDateFullTimeFormatted(time) {
    if (!time) {
      return '';
    }
    return `${DateUtil.getDateFormatted(time)} ${DateUtil.getFullTimeFormatted(time)}`;
  }

  /**
   * @return string
   */
  static getMonthName(time) {
    if (!time) {
      return '';
    }
    const date = time ? new Date(time) : new Date();
    return date.toLocaleDateString(undefined,{ month: 'long' });
  }
}

export default DateUtil;
