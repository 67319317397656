import React from 'react';
import { UploadButton } from '@webfox-sc/core';
import { DefaultTheme, useTheme } from 'styled-components';

type TextUploadButtonVariant = 'white';

const buttonTheme = ({ variant, theme }: { variant?: TextUploadButtonVariant; theme: DefaultTheme }) => {
  return {
    button: {
      appearance: 'none',
      padding: 0,
      margin: 0,
      background: 'transparent',
      border: 'none',
      display: 'inline-flex',
      gap: '10px',
      alignItems: 'center',
      ...theme.type.copy,
      color: variant === 'white' ? 'white' : theme.colors.ciRed,
      textDecoration: variant === 'white' ? 'underline' : 'none',
      cursor: 'pointer',
      '&:focus': {
        outline: 0,
      },
      '&:hover': {
        color: variant === 'white' ? 'white' : theme.colors.ciRed,
        textDecoration: variant === 'white' ? 'none !important' : 'underline !important',
      },
      '&:active': {
        color: theme.colors.ciBlue1,
        textDecoration: variant === 'white' ? 'underline !important' : 'none !important',
      },
      svg: {
        width: '12px',
        height: '12px',
        verticalAlign: 'top',
        textDecoration: 'none',
      },
    },
  };
};

interface TextUploadButtonProps {
  children: React.ReactNode;
  variant?: TextUploadButtonVariant;
  accept?: string;
  multiple?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactNode;
}

const TextUploadButton: React.FC<TextUploadButtonProps> = ({ variant, accept, multiple, icon, onChange, children }) => {
  const theme = useTheme();
  return (
    <UploadButton
      accept={accept}
      multiple={multiple}
      icon={icon as any}
      onChange={onChange}
      label={children as string}
      theme={buttonTheme({ variant, theme })}
    />
  );
};

export default TextUploadButton;
