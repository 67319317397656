import { InlineSVG, UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useReportroom } from '../../app/entityHooks/useReportroom';
import { pathToQMReportroom, pathToQMReportroomEdit } from '../../app/paths';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconComment from '../../assets/icons-v2/IconComment';
import IconInfo from '../../assets/icons-v2/IconInfo';
import IconPicture from '../../assets/icons-v2/IconPicture';
import DateUtil from '../../utils/DateUtil';
import Bubble from '../atoms-v2/Bubble';
import Button from '../atoms-v2/Button';
import IconButton from '../atoms-v2/IconButton';
import IndicatorBubble from '../atoms-v2/IndicatorBubble';
import ResultBubble from '../atoms-v2/ResultBubble';
import Text from '../atoms-v2/Text';

interface QMReportReportroomTeaserProps {
  reportroomId: number | undefined;
}

const QMReportReportroomTeaser: React.FC<QMReportReportroomTeaserProps> = ({ reportroomId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { reportroom, roomgroup } = useReportroom(reportroomId);

  if (!reportroom) {
    return null;
  }

  const handleOnClickLink = () => {
    if (reportroom.status === 'finished') {
      navigate(pathToQMReportroom(reportroom.id, `${pathname}${search}`));
    } else {
      navigate(pathToQMReportroomEdit(reportroom.id, `${pathname}${search}`));
    }
  };

  return (
    <UiDiv display="flex" flexDirection="column" rowGap="xxs">
      <UiDiv display="flex" flexDirection={{ mobile: 'column', laptop: 'row' }} gap="1px">
        <UiDiv flex="0 0 50%" display="flex" alignItems="center" background="white" color="ciBlue1" padding="15px">
          {/*
          Meta infos
          */}
          <UiDiv
            display="grid"
            width="100%"
            gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(11, 1fr) 90px' }}
            gap="xs"
          >
            <UiText gridColumn={{ mobile: '1/13', tablet: '1/7' }}>
              <b>{t('common.entities.roomgroup.singular')}</b>
              <br />
              {roomgroup?.name || '–'}
            </UiText>
            <UiText gridColumn={{ mobile: '1/12', tablet: '7/12' }}>
              <b>{t('roomgroup.number')}</b>
              <br />
              {reportroom.number || '–'}
            </UiText>
            <UiText gridColumn="12/13">
              <b>{t('common.fields.date')}</b>
              <br />
              {DateUtil.getDateFormatted(reportroom.createdAt)}
            </UiText>
          </UiDiv>
        </UiDiv>
        <UiDiv display="flex" background="grey20" color="ciBlue1" flexGrow={1} padding="15px" alignItems="center">
          {reportroom.status === 'finished' ? (
            <UiDiv display={{ tablet: 'flex' }} width="100%" gap="m" alignItems="center">
              {/*
              Scores
              */}
              <UiDiv flexGrow={1} display="flex" gap="s" justifyContent="space-between" alignItems="center">
                <UiDiv width="100px">
                  {reportroom.score}/{reportroom.maxScore} {t('common.fields.points')}
                </UiDiv>
                <UiDiv display="flex" gap="s" alignItems="center">
                  <UiDiv display="flex" gap="xxs">
                    {!!reportroom.scores?.['0'] && <Bubble variant="red">{reportroom.scores?.['0'] || 0}</Bubble>}
                    {!!reportroom.scores?.['1'] && <Bubble variant="yellow">{reportroom.scores?.['1'] || 0}</Bubble>}
                    {!!reportroom.scores?.['2'] && <Bubble variant="green">{reportroom.scores?.['2'] || 0}</Bubble>}
                  </UiDiv>
                  {!!reportroom.maxScore && (
                    <ResultBubble result={reportroom.scorePercentage || 0}>
                      {reportroom.scorePercentage || 0}
                    </ResultBubble>
                  )}
                </UiDiv>
              </UiDiv>

              {/*
              Pictures and comments (Mobile)
              */}
              {(!!reportroom.pictureCount || reportroom.hasComments) && (
                <UiDiv display={{ mobile: 'flex', tablet: 'none' }} columnGap="m" marginTop="xs">
                  {!!reportroom.pictureCount && (
                    <Text icon={IconPicture}>
                      {reportroom.pictureCount} {reportroom.pictureCount === 1 ? t('common.form.picture.singular') : t('common.form.picture.plural')}
                    </Text>
                  )}
                  {reportroom.hasComments && <Text icon={IconComment}>{t('reportroom.hasComments')}</Text>}
                </UiDiv>
              )}

              {/*
              Pictures and comments (Tablet)
              */}
              <UiDiv display={{ mobile: 'none', tablet: 'flex' }} flexDirection="column" rowGap="14px">
                <UiDiv position="relative" width="15px" height="15px" opacity={!!reportroom.pictureCount ? 1 : 0}>
                  <InlineSVG width="15px" height="15px">
                    {IconPicture}
                  </InlineSVG>
                  <UiDiv position="absolute" top="-9px" right="-9px">
                    {!!reportroom.pictureCount && <IndicatorBubble number={reportroom.pictureCount} check={false} />}
                  </UiDiv>
                </UiDiv>

                <UiDiv position="relative" width="15px" height="15px" opacity={reportroom.hasComments ? 1 : 0}>
                  <InlineSVG width="15px" height="15px">
                    {IconComment}
                  </InlineSVG>
                  <UiDiv position="absolute" top="-9px" right="-9px">
                    {reportroom.hasComments && <IndicatorBubble check={reportroom.hasComments} />}
                  </UiDiv>
                </UiDiv>
              </UiDiv>

              {/*
              Icon Link (Tablet)
              */}
              <UiDiv display={{ mobile: 'none', tablet: 'block' }}>
                <IconButton variant="red" icon={IconArrowRight} onClick={handleOnClickLink} />
              </UiDiv>
            </UiDiv>
          ) : (
            <UiDiv display="flex" width="100%" justifyContent="flex-end">
              <Button endIcon={IconArrowRight} onClick={handleOnClickLink}>
                {t('common.actions.continue')}
              </Button>
            </UiDiv>
          )}
        </UiDiv>
      </UiDiv>

      {/*
      Mobile detail button
      */}
      {reportroom.status === 'finished' && (
        <UiDiv display={{ mobile: 'block', tablet: 'none' }}>
          <Button icon={IconInfo} onClick={handleOnClickLink}>
            {t('common.actions.showDetails')}
          </Button>
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default QMReportReportroomTeaser;
