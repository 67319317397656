/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function jobDetailsReducer(
    state = {
        isLoadingJob: false
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.REQUEST_JOB:
            return Object.assign({}, state, {
                isLoadingJob: true
            });

        case REDUX_ACTIONS.RECIEVE_JOB:
        case REDUX_ACTIONS.REQUEST_JOB_ERROR:
            return Object.assign({}, state, {
                isLoadingJob: false
            });

        default:
            return state;
    }
}

export default jobDetailsReducer;
