import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useQMAdminNestedRoomgroups = (
  roomgroupIds: number[],
  craftId: number | null,
  useGlobal: boolean | undefined
): {
  roomgroups: RoomgroupNestedEntity[] | undefined;
  roomgroupsCount: number;
  checkitemCount: number;
} => {
  const specificRoomgroups = entitySelectors
    .selectByIds<RoomgroupEntity>('roomgroups', roomgroupIds || [])
    .filter((roomgroup) => (craftId ? craftId === roomgroup.craftId : !roomgroup.craftId));
  const globalRoomgroups = entitySelectors.selectByFilter<RoomgroupEntity>(
    'roomgroups',
    (roomgroup) => !roomgroup.companyId && !roomgroup.clientId && (craftId ? craftId === roomgroup.craftId : !roomgroup.craftId)
  );
  const allCheckitemgroups = entitySelectors.selectAll<CheckitemgroupEntity>('checkitemgroups');
  const allCheckitems = entitySelectors.selectAll<CheckitemEntity>('checkitems');

  const entities = useMemo(() => {
    const filtered = _orderBy(
      [...specificRoomgroups, ...(useGlobal ? globalRoomgroups : [])],
      ['clienId', 'companyId'],
      ['asc', 'asc']
    );
    const nestedRoomgroups = filtered.map(({ checkitemgroupIds, ...roomgroup }) => {
      // TODO not beautiful, but we can't use entitySelectors.selectById here
      const checkitemgroups = allCheckitemgroups.filter(({ id }) => id && checkitemgroupIds.includes(id));
      return {
        path: [],
        showDelete: false,
        ...roomgroup,
        checkitemgroups: checkitemgroups.map(({ checkitemIds, ...checkitemgroup }) => {
          // TODO not beautiful, but we can't use entitySelectors.selectById here
          const checkitems = allCheckitems.filter(({ id }) => id && checkitemIds.includes(id));
          return {
            path: [],
            showDelete: false,
            ...checkitemgroup,
            roomgroupId: checkitemgroup.roomgroupId || roomgroup.id,
            checkitems: checkitems.map((checkitem) => ({
              path: [],
              showDelete: false,
              ...checkitem,
              checkitemgroupId: checkitem.checkitemgroupId || checkitemgroup.id,
            })),
          };
        }),
      };
    });
    const enabled = _filter(filtered, ({ disabled }) => !disabled);
    return {
      roomgroups: nestedRoomgroups,
      roomgroupsCount: enabled.length,
      checkitemCount: enabled.reduce((cnt, roomgroup) => cnt + (roomgroup?.checkitemCount || 0), 0),
    };
  }, [specificRoomgroups, useGlobal, globalRoomgroups, allCheckitemgroups, allCheckitems]);

  return entities;
};
