import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@webfox-sc/core';
import TableBodyRow from '../../components/elements/TableBodyRow';
import TableBodyCell from '../../components/elements/TableBodyCell';
import { themeCheckbox } from '../../theme/theme';
import { useCompanyListItem } from './CompanyListItem.hooks';
import IconArrowDown from '../../assets/icons-v2/IconArrowDown';

const CompanyListItem = ({ objectId, onClick, isSelected, onSelect }) => {
  const { company, createdAtFormatted, updatedAtFormatted } = useCompanyListItem(objectId);

  if (!company) {
    return null;
  }

  return (
    <TableBodyRow isClickable isModified={company.isModified} isNew={company.isNew}>
      <TableBodyCell layout="wide" text={company.number} onClick={onClick} width="13%" />
      <TableBodyCell layout="wide" text={company.name} onClick={onClick} width="auto" />
      <TableBodyCell layout="wide" text={createdAtFormatted} onClick={onClick} width="13%" />
      <TableBodyCell layout="wide" text={updatedAtFormatted} onClick={onClick} width="13%" />
      <TableBodyCell layout="wide" width="7%">
        <Checkbox checked={isSelected} onChange={(e) => onSelect(e.target.checked, objectId)} theme={themeCheckbox} />
      </TableBodyCell>
      <TableBodyCell layout="wide" iconSrc={IconArrowDown} onClick={onClick} width="7%" />
    </TableBodyRow>
  );
};

CompanyListItem.propTypes = {
  objectId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

CompanyListItem.defaultProps = {
  onClick: () => false,
  isSelected: PropTypes.bool,
  onSelect: () => false,
};

export default CompanyListItem;
