const IconUser = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M50,0A26,26,0,1,0,76,26,26,26,0,0,0,50,0ZM94.56,72.68A24.15,24.15,0,0,0,92,67.91,32.3,32.3,0,0,0,69.62,53.85a4.82,4.82,0,0,0-3.29.8,27.67,27.67,0,0,1-32.65,0,4.29,4.29,0,0,0-3.29-.8A32,32,0,0,0,8.06,67.91a28.13,28.13,0,0,0-2.61,4.77,2.4,2.4,0,0,0,.11,2.15,46.68,46.68,0,0,0,3.06,4.54,43.11,43.11,0,0,0,5.22,5.89c1.59,1.59,3.4,3.06,5.21,4.54a51.72,51.72,0,0,0,61.68,0A49.23,49.23,0,0,0,86,85.26a51.92,51.92,0,0,0,5.21-5.89,38.4,38.4,0,0,0,3.06-4.54A1.92,1.92,0,0,0,94.56,72.68Z"
    />
  </svg>
);

export default IconUser;
