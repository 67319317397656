import { Collapse, UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../app/appHooks';
import { updateUser } from '../../app/slices/usersSlice';
import IconCheck from '../../assets/icons-v2/IconCheck';
import Button from '../atoms-v2/Button';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import FormPanel from '../atoms-v2/FormPanel';
import TextInput from '../atoms-v2/TextInput';
import { useAuth } from '../auth/useAuth';
import Advice from '../elements/Advice';

const AccountPhoneNumber: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();
  const [phoneNumberDraft, setPhoneNumberDraft] = useState(currentUser?.tel);
  const [showAdvice, setShowAdvice] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [adviceText, setAdviceText] = useState('');

  if (!currentUser) {
    return null;
  }

  const handleOnClickUpdate = async () => {
    await dispatch(
      updateUser(currentUser.id, {
        tel: phoneNumberDraft,
      })
    );
    setShowAdvice(true);
    setIsSuccess(true);
    setAdviceText(t('account.changePhoneNumber.success') || '');
  };

  return (
    <FormPanel>
      <Collapse show={showAdvice}>
        <UiDiv paddingBottom="s">
          <Advice text={adviceText} isSuccess={isSuccess} onClick={() => setShowAdvice(false)} />
        </UiDiv>
      </Collapse>
      <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} columnGap="xs" rowGap="15px">
        <UiDiv>
          <FormElementLabel>{t('user.phoneNumber')}</FormElementLabel>
          <TextInput
            value={phoneNumberDraft}
            onChange={(e) => setPhoneNumberDraft(e.target.value)}
            onFocus={() => setShowAdvice(false)}
          />
        </UiDiv>
      </UiDiv>
      <UiDiv marginTop="m">
        <Button icon={IconCheck} onClick={handleOnClickUpdate}>
          Aktualisieren
        </Button>
      </UiDiv>
    </FormPanel>
  );
};

export default AccountPhoneNumber;
