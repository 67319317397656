import { UiDiv, UiText } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { pathToQMAdminCompany } from '../../../app/paths';
import ContentBox from '../../atoms-v2/ContentBox';
import { useQMAdminCompany } from '../useQMAdminCompany';
import QMAdminStartCompanyClient from './QMAdminStartCompanyClient';

interface QMAdminStartCompanyProps {
  companyId: number | undefined;
}

const QMAdminStartCompany: React.FC<QMAdminStartCompanyProps> = ({ companyId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { company, clients, clientsWithSchedule } = useQMAdminCompany(companyId);

  if (!company) {
    return null;
  }

  const isCheckSpecific = !!(company.roomgroupIds && company.roomgroupIds.length > 0);

  return (
    <UiDiv display="flex" flexDirection="column" gap="1px">
      <ContentBox
        variant="white"
        withLink
        linkHref={pathToQMAdminCompany(company.id)}
        onClickLink={() => navigate(pathToQMAdminCompany(company.id))}
      >
        <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1.5fr 1fr' }} gap="xs">
          <UiText variant="copy">
            {company.number} – {company.name}
          </UiText>
          {clients.length > 0 ? (
            <UiText variant="copy">
              <b>{clients.length} {t('schedule.client')}</b>
            </UiText>
          ) : (
            <UiText variant="copy">
              <em>{t('schedule.info.noClients')}</em>
            </UiText>
          )}
        </UiDiv>
      </ContentBox>

      {clientsWithSchedule.length > 0 ? (
        <>
          {clientsWithSchedule.map((client) => {
            return (
              <QMAdminStartCompanyClient key={client.id} clientId={client.id} companyHasRoomgroups={isCheckSpecific} />
            );
          })}
        </>
      ) : (
        <ContentBox>
          <em>{t('schedule.info.noReportsShort')}</em>
        </ContentBox>
      )}
    </UiDiv>
  );
};

export default QMAdminStartCompany;
