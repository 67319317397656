import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '@webfox-sc/core';
import { SPACINGS } from '../../theme/theme';

const Section = ({ paddingTop, paddingBottom, align, children }) => {
    let justify;
    if (align === 'center') {
        justify = 'center';
    } else if (align === 'right') {
        justify = 'flex-end';
    }

    return (
        <Block
            paddingTop={paddingTop && SPACINGS[paddingTop]}
            paddingBottom={paddingTop && SPACINGS[paddingBottom]}
            justify={justify}
        >
            {children}
        </Block>
    );
};

Section.propTypes = {
    paddingTop: PropTypes.oneOf(['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '', null, undefined]),
    paddingBottom: PropTypes.oneOf(['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '', null, undefined]),
    align: PropTypes.oneOf(['center', 'right', '', null, undefined]),
    children: PropTypes.any,
};

Section.defaultProps = {
    paddingTop: undefined,
    paddingBottom: undefined,
    align: undefined,
    children: null,
};

export default Section;
