import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';
import { entitySelectors } from '../entitySelectors';

export const useCheckitemtypeOptions = (): {
  checkitemtypeOptions: SelectOption[];
} => {
  const checkitemtypes = entitySelectors.selectAll<CheckitemtypeEntity>('checkitemtypes');

  const checkitemtypeOptions = useMemo(() => {
    const options: SelectOption[] = [];
    checkitemtypes.forEach((checkitemtype) => {
      if (!checkitemtype.disabled) {
        options.push({
          value: checkitemtype.id,
          label: checkitemtype.name,
        });
      }
    });
    return _orderBy(options, [(option: SelectOption) => option.label], ['asc']);
  }, [checkitemtypes]);

  return {
    checkitemtypeOptions,
  };
};
