import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import BaseDataFilteredList from '../../components/base-data/shared/BaseDataFilteredList';
import { usePaginatedList } from '../../components/base-data/shared/usePaginatedList';
import ClientEditor from '../../components/ClientEditor';
import ClientsFilter from '../../components/ClientsFilter';
import TableHeaderCell from '../../components/elements/TableHeaderCell';
import TableHeaderRow from '../../components/elements/TableHeaderRow';
import IsEmptyFilter from '../../components/IsEmptyFilter';
import { BaseDataFilterProvider } from '../../hooks/baseDataFilter';
import { BaseDataViewProvider } from '../../hooks/baseDataView';
import { useClientsActions } from '../../hooks/useClientsActions';
import { useClientsFilter } from '../../hooks/useClientsFilter';
import { deleteAllClients } from '../../redux-actions/actionsClients';
import ClientsUtil from '../../utils/ClientsUtil';
import ClientListItem from './ClientListItem';

const ViewBaseDataClients = () => {
  const { t } = useTranslation();
  const { isDataFilter, initialTableFilter } = useClientsFilter();
  const { fetchClientsLegacy } = useClientsActions();

  const dataLoaderProps = {
    objectPath: 'clients',
    fetchObjects: (params) => fetchClientsLegacy({ ...params, populate: ClientsUtil.baseViewClientsPopulate }),
  };

  const dispatch = useDispatch();

  return (
    <BaseDataFilterProvider isDataFilter={isDataFilter} initialTableFilter={initialTableFilter}>
      <BaseDataViewProvider
        objectPath="clients"
        getNewObject={ClientsUtil.getEmptyClient}
        useFilter={useClientsFilter}
        deleteObjects={(ids) => dispatch(deleteAllClients(ids))}
        dataLoader={usePaginatedList}
        dataLoaderProps={dataLoaderProps}
      >
        <BaseDataFilteredList
          labelObjects={t('client.navItem')}
          labelNew={t('client.actions.new')}
          toolbar={
            <>
              <IsEmptyFilter label={t('client.form.showWithoutServices')} filterKey="serviceIds" />
              <ClientsFilter />
            </>
          }
          Editor={ClientEditor}
          TableHeader={({ handleSort, sortingKey, sortingOrder }) => (
            <TableHeaderRow>
              <TableHeaderCell
                text={t('common.fields.clientNumber')}
                width="15%"
                // isSorting
                onClick={() => handleSort('clientNumber')}
                sortingOrder={sortingKey === 'clientNumber' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.company')}
                width="41%"
                // isSorting
                onClick={() => handleSort('clientDescription')}
                sortingOrder={sortingKey === 'clientDescription' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.carrierNumber.long')}
                width="10%"
                // isSorting
                onClick={() => handleSort('carrierNumber')}
                sortingOrder={sortingKey === 'carrierNumber' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.costcenterNumber.long')}
                width="10%"
                // isSorting
                onClick={() => handleSort('costCenterNumber')}
                sortingOrder={sortingKey === 'costCenterNumber' ? sortingOrder : ''}
              />
              <TableHeaderCell
                text={t('common.fields.updatedAt')}
                width="24%"
                isSorting
                onClick={() => handleSort('updatedAt')}
                sortingOrder={sortingKey === 'updatedAt' ? sortingOrder : ''}
              />
            </TableHeaderRow>
          )}
          TableRow={ClientListItem}
          newEditorPresets={{
            showAdvice: true,
            isError: true,
            adviceText: t('common.info.invalidFromImport'),
          }}
        />
      </BaseDataViewProvider>
    </BaseDataFilterProvider>
  );
};

export default ViewBaseDataClients;
