import { UiDiv } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { pathToQM } from '../../app/paths';
import { useAuth } from '../auth/useAuth';
import { usePermissions } from '../auth/usePermissions';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';

import { useAppDispatch } from '../../app/appHooks';
import PageContentSubHeading from '../page/PageContentSubHeading';

import { pathToQMReport } from '../../app/paths';
import { createReport } from '../../app/slices/reportsSlice';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import Button from '../atoms-v2/Button';
import FormPanel from '../atoms-v2/FormPanel';
import FormRowGroup from '../atoms-v2/FormRowGroup';

import { useCrafts } from '../../app/entityHooks/useCrafts';
import { initialState } from '../../app/slices/clientFilterSlice';
import { fetchClient } from '../../app/slices/clientsSlice';
import { useDynamicClientFilter } from '../../hooks/useDynamicClientFilter';
import CustomReactSelect from '../CustomReactSelect/CustomReactSelect';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import PageNoticeBox from '../page/PageNoticeBox';
import { useQMAdminClient } from '../qm-admin/useQMAdminClient';
import QMClientsFilter from './QMClientsFilter';
import { map } from 'lodash';

const fetchClientParams = {
  populate: {
    roomgroups: {
      populate: {
        craft: {
          fields: ['id', 'name'],
        },
      },
    },
    company: {
      populate: {
        roomgroups: {
          populate: {
            craft: {
              fields: ['id', 'name'],
            },
          },
        },
        clients: {
          // needed, to identify parant of roomgroup as company and set relations
          fields: ['id'],
        },
        crafts: {
          // needed, to identify parant of roomgroup as company and set relations
          fields: ['id', 'name'],
        },
      },
    },
    costcenter_rel: {
      // needed, to identify parant of roomgroup as client and set relations
      fields: ['id'],
    },
  },
};

const QMAddReport: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { canViewQM } = usePermissions();
  const { crafts } = useCrafts();
  const [craftId, setCraftId] = useState<number | null>(null);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [currentFilterState, handleSetFilterState] = useState(initialState);
  const { clientId } = currentFilterState;

  const dynamicClientFilter = useDynamicClientFilter(currentFilterState, handleSetFilterState);

  const handleOnClickCreateAdHocReport = async () => {
    if (clientId) {
      const response = await dispatch(
        createReport({
          client: clientId,
          craft: craftId || undefined
        })
      );
      const reportId = response?.payload?.data?.id;
      if (reportId) {
        navigate(pathToQMReport(reportId));
      }
    }
  };

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClient(clientId, fetchClientParams));
    }
  }, [clientId, dispatch]);

  const { specificityByCraft } = useQMAdminClient(clientId || undefined);

  const filteredCraftOptions = map(specificityByCraft, ({ craftId: cId }) => {
    if (cId) {
      const craft = crafts.find((c) => c.id === cId);
      return {
        value: cId,
        label: craft?.name || '?',
      } as SelectOption;
    }
    return {
      value: null,
      label: t('craft.form.options.default'),
    } as SelectOption;
  });

  return (
    <>
      <PageHeading
        heading={t('report.headline.createAdHoc') || undefined}
        backlinkLabel={t('common.actions.back') || undefined}
        backlinkPath={pathToQM()}
        preferHistoryBack
      />
      <PageContent paddingTop="l">
        <PageContentSubHeading leftSideText={`* ${t('common.form.requiredFields')}`}>
          {t('job.recipient')}
        </PageContentSubHeading>

        <UiDiv marginTop="s">
          {!canViewQM ? (
            <PageNoticeBox>
              {t('report.info.noAccess')}
              <br />
              <br />
              {t('common.info.currentRole', { roleName: currentUser?.roleName })}
            </PageNoticeBox>
          ) : (
            <>
              <FormPanel>
                <FormRowGroup>
                  <QMClientsFilter dynamicClientFilter={dynamicClientFilter} requireFields />
                </FormRowGroup>
                {filteredCraftOptions.length > 1 && (
                  <UiDiv marginTop="xs">
                    <FormElementLabel>{t('common.fields.craft')} *</FormElementLabel>
                    <CustomReactSelect
                      options={filteredCraftOptions}
                      selectedValue={craftId}
                      isSearchable
                      isClearable
                      onChangeValue={(value) => setCraftId(value)}
                      placeholder={t('common.actions.select')}
                    />
                  </UiDiv>
                )}
              </FormPanel>

              <UiDiv display="flex" justifyContent="center" paddingTop="m">
                <Button icon={IconArrowRight} disabled={!clientId} onClick={handleOnClickCreateAdHocReport}>
                  {t('report.actions.start')}
                </Button>
              </UiDiv>
            </>
          )}
        </UiDiv>
      </PageContent>
    </>
  );
};

export default QMAddReport;
