const IconRatingBad = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M50.4,0C22.79,0,.4,22.39,.4,50s22.39,50,50,50,50-22.39,50-50S78.02,0,50.4,0ZM21.27,47.08c-1.16-1.16-1.16-3.05,0-4.21,.58-.58,1.34-.87,2.1-.87s1.52,.29,2.1,.87c2.55,2.55,6.71,2.55,9.26,0,1.16-1.16,3.05-1.16,4.21,0,1.16,1.16,1.16,3.05,0,4.21-4.87,4.87-12.8,4.87-17.68,0Zm42.43,26.49c-1.5,.71-3.29,.07-4-1.42-1.77-3.72-5.56-6.29-9.94-6.29s-8.17,2.56-9.94,6.29c-.71,1.5-2.5,2.13-4,1.42-1.5-.71-2.13-2.5-1.42-4,2.73-5.74,8.58-9.71,15.36-9.71s12.64,3.98,15.36,9.71c.71,1.5,.07,3.29-1.42,4Zm14.55-26.49c-4.87,4.87-12.8,4.87-17.68,0-1.16-1.16-1.16-3.05,0-4.21,.58-.58,1.34-.87,2.1-.87s1.52,.29,2.1,.87c2.55,2.55,6.71,2.55,9.26,0,1.16-1.16,3.05-1.16,4.21,0,1.16,1.16,1.16,3.05,0,4.21Z"
    />
  </svg>
);

export default IconRatingBad;
