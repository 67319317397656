import { UiDiv, UiDivProps } from '@webfox-sc/core';
import React from 'react';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconLink from './IconLink';

interface ContentBoxProps extends UiDivProps {
  variant?: 'white';
  withLink?: boolean;
  linkHref?: string;
  onClickLink?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

const ContentBox: React.FC<ContentBoxProps> = ({ variant, withLink, linkHref, onClickLink, children, ...props }) => {
  const handleOnClickLink: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    if (onClickLink) {
      onClickLink(e);
    }
  };

  const customProps: UiDivProps = {
    padding: '20px 15px',
    color: 'ciBlue1',
    background: variant === 'white' ? 'white' : 'grey20',
  };

  if (withLink) {
    return (
      <UiDiv display="grid" gridTemplateColumns="1fr 22px" gap="xs" alignItems="center" {...customProps} {...props}>
        <UiDiv>{children}</UiDiv>
        <IconLink icon={IconArrowRight} href={linkHref} onClick={handleOnClickLink} />
      </UiDiv>
    );
  }

  return (
    <UiDiv {...customProps} {...props}>
      {children}
    </UiDiv>
  );
};

export default ContentBox;
