import { Block } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Text from '../atoms-v2/Text';
import PageContent from './PageContent';

const PageFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageContent>
      <Block display="flex" gap="30px" justifyContent="flex-end">
        <Text variant="small" color="white">
          <Link to="privacy">{t('navigation.privacy')}</Link>
        </Text>
        <Text variant="small" color="white">
          <Link to="imprint">{t('navigation.imprint')}</Link>
        </Text>
      </Block>
    </PageContent>
  );
};

export default PageFooter;
