import { UiDiv } from '@webfox-sc/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { useCraftOptions } from '../../../app/entityHooks/useCraftOptions';
import { fetchCheckitemtypes } from '../../../app/slices/checkitemtypesSlice';
import { fetchCrafts } from '../../../app/slices/craftsSlice';
import { fetchGlobalRoomgroups } from '../../../app/slices/roomgroupsSlice';
import CustomReactSelect from '../../CustomReactSelect/CustomReactSelect';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import BaseDataQMRoomgroupList from './BaseDataQMRoomgroupList';

const BaseDataQM: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [craftId, setCraftId] = useState<number | null | undefined>(undefined);

  const { craftOptions } = useCraftOptions();

  const allCraftOptions = useMemo(
    () => [
      {
        value: null,
        label: t('craft.form.options.default'),
      } as SelectOption,
      ...craftOptions,
    ],
    [craftOptions, t]
  );

  const fetchData = useCallback(async () => {
    dispatch(fetchCheckitemtypes());
    dispatch(fetchCrafts());
    dispatch(fetchGlobalRoomgroups());
    // const ids = (res?.payload?.data || []).map((roomgroupData) => roomgroupData.id);
    // setRoomgroupIds(ids);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // disable and set default craft if there is no other
    if (craftOptions && !craftOptions.length) {
      setCraftId(null);
    }
  }, [craftOptions]);

  return (
    <>
      <UiDiv>
        <FormElementLabel color="white">{t('common.fields.craft')}</FormElementLabel>
        <CustomReactSelect
          layout="outline"
          options={allCraftOptions}
          isDisabled={!craftOptions.length}
          selectedValue={craftId}
          onChangeValue={setCraftId}
          placeholder={t('common.actions.select')}
        />
      </UiDiv>
      {craftId !== undefined ? (
        <UiDiv marginTop="l">
          <BaseDataQMRoomgroupList onSubmit={fetchData} craftId={craftId || undefined} />
        </UiDiv>
      ): null}
    </>
  );
};

export default BaseDataQM;
