/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function servicesReducer(
    state = {
        serviceModels: [],
        newServices: {},
        isLoadingServices: false
    },
    action
) {
    let serviceModels;
    switch (action.type) {
        case REDUX_ACTIONS.UPDATE_SERVICE_MODEL:
            const modifiedModelIndex = state.serviceModels.findIndex((model) => model.id === action.serviceModel.id);
            serviceModels = state.serviceModels.slice(0);
            if (modifiedModelIndex > -1) {
                serviceModels.splice(modifiedModelIndex, 1, action.serviceModel);
            }
            return Object.assign({}, state, {
                serviceModels
            });

        case REDUX_ACTIONS.UPDATE_SERVICE_MODELS:
            return Object.assign({}, state, {
                serviceModels: action.serviceModels
            });

        case REDUX_ACTIONS.UPDATE_IS_LOADING_SERVICES:
            return Object.assign({}, state, {
                isLoadingServices: action.isLoadingServices
            });

        default:
            return state;
    }
}

export default servicesReducer;
