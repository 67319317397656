const IconQuality = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M95,29.46,85,23.35l-2.26-9.52a8.64,8.64,0,0,0-9.07-6.7L62.18,8,55.6,2.11a8.49,8.49,0,0,0-11.2,0L37.82,8l-11.48-.9a8.6,8.6,0,0,0-9,6.35c-.18.59-3.53,11.2-3.32,10.51L5,29.46A8.83,8.83,0,0,0,1.51,40.3L5.43,50,1.51,59.62A8.83,8.83,0,0,0,5,70.46l10,6.06,2.27,9.57a8.65,8.65,0,0,0,9.07,6.7l11.36-.9,6.7,6a8.49,8.49,0,0,0,11.2,0l6.7-6,11.36.9a8.59,8.59,0,0,0,9.07-6.7L85,76.56l10-6.1a8.84,8.84,0,0,0,3.46-10.84L94.57,50l3.92-9.66A8.83,8.83,0,0,0,95,29.46ZM50,82.23C32.53,82.23,18.32,67.77,18.32,50S32.53,17.77,50,17.77,81.68,32.23,81.68,50,67.47,82.23,50,82.23Zm0-58.6a26.37,26.37,0,0,0,0,52.74,26.37,26.37,0,0,0,0-52.74ZM66.44,43.28,49.16,60.86a2.85,2.85,0,0,1-4.08,0L33.56,49.14a3,3,0,0,1,0-4.14,2.84,2.84,0,0,1,4.08,0l9.48,9.65L62.36,39.14a2.86,2.86,0,0,1,4.08,0A3,3,0,0,1,66.44,43.28Z"
    />
  </svg>
);

export default IconQuality;
