/* eslint-disable */
export function formatDecimal(n, minimumFractionDigits = 2, maximumFractionDigits = 2) {
  if (n === null || n === undefined) {
    return '';
  }

  return n.toLocaleString('de-DE', {
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export function formatPrice(amount, minimumFractionDigits = 2, maximumFractionDigits = 2) {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  });
  return formatter.format(amount);
}
