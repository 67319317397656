import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { pathToQMAdminClientConfig } from '../../../app/paths';
import IconChecklist from '../../../assets/icons-v2/IconChecklist';
import IconEdit from '../../../assets/icons-v2/IconEdit';
import ContentBox from '../../atoms-v2/ContentBox';
import IconButton from '../../atoms-v2/IconButton';
import Text from '../../atoms-v2/Text';
import QMAdminConfigCounts from '../qm-admin-config-counts/QMAdminConfigCounts';
import { useQMAdminClient } from '../useQMAdminClient';
import QMAdminConfigSpecificityLabel from './QMAdminConfigSpecificityLabel';
import { map } from 'lodash';

interface QMAdminRoomgroupsTeaserProps {
  clientId: number | undefined;
}

const QMAdminRoomgroupsTeaser: React.FC<QMAdminRoomgroupsTeaserProps> = ({ clientId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { client, specificityByCraft } = useQMAdminClient(clientId);

  if (!client) {
    return null;
  }

  return (
    <ContentBox display="flex" flexDirection="column" gap="xs">
      {map(specificityByCraft,(specificityEntry) => (
        <UiDiv display="flex" gap="xs" key={`craft_${specificityEntry.craftId}`}>
          <UiDiv display="flex" flexGrow={1} flexWrap="wrap" gap="xs">
            <UiDiv
              flexGrow={1}
              display="grid"
              gridTemplateColumns={{ mobile: '1fr', tablet: 'auto 1fr' }}
              columnGap="xs"
            >
              <Text icon={IconChecklist}>
                <strong>{t('common.entities.report.singular')}</strong>
              </Text>
              <UiDiv paddingLeft={{ mobile: '26px', tablet: 0 }}>
                <Text>
                  <QMAdminConfigCounts {...specificityEntry} />
                </Text>
              </UiDiv>
            </UiDiv>
            <UiDiv paddingLeft="26px">
              <Text>
                <strong>
                  <QMAdminConfigSpecificityLabel {...specificityEntry} />:
                </strong>
              </Text>
            </UiDiv>
          </UiDiv>
          <IconButton
            icon={IconEdit}
            variant="red"
            onClick={() => navigate(pathToQMAdminClientConfig(client.id, specificityEntry.craftId || 0))}
          />
        </UiDiv>
      ))}
    </ContentBox>
  );
};

export default QMAdminRoomgroupsTeaser;
