import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import IconCalendar from '../../../assets/icons-v2/IconCalendar';
import IconSettings from '../../../assets/icons-v2/IconSettings';
import { useTranslation } from 'react-i18next';
import ContentBox from '../../atoms-v2/ContentBox';
import Text from '../../atoms-v2/Text';
import { useQMAdminClient } from '../useQMAdminClient';
import ScheduleUtil from '../../../utils/ScheduleUtil';

interface QMAdminStartCompanyClientProps {
  clientId: number | undefined;
  companyHasRoomgroups: boolean | undefined;
}

const QMAdminStartCompanyClient: React.FC<QMAdminStartCompanyClientProps> = ({ clientId, companyHasRoomgroups }) => {
  const { t } = useTranslation();
  const { client, location, schedules } = useQMAdminClient(clientId);

  if (!client) {
    return null;
  }

  let qmCheckType: string;
  if (client?.roomgroupIds && client.roomgroupIds.length > 0) {
    qmCheckType = t('report.form.specificity.client.long');
  } else if (companyHasRoomgroups) {
    qmCheckType = t('report.form.specificity.company.long');
  } else {
    qmCheckType = t('report.form.specificity.default.long');
  }

  return (
    <ContentBox>
      <UiDiv display="grid" gridTemplateColumns="1fr 22px" gap="xs">
        <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1.5fr 1fr' }} gap="xs">
          <UiDiv>
            <Text>
              <strong>{t('common.entities.location.singular')}</strong>
            </Text>
            <UiDiv paddingTop="xxs">
              <Text>{location?.description || '?'}</Text>
            </UiDiv>
          </UiDiv>
          <UiDiv display="flex" flexDirection="column" gap="xxs">
            {(schedules || []).map((schedule, index) => (
              <Text key={`s_${index}`} icon={IconCalendar}>
                {ScheduleUtil.getFrequencyIntervalFormatted(schedule?.frequency, schedule?.interval, t)}{' '}
                {ScheduleUtil.getScheduleDateFormatted(schedule?.start, schedule?.interval, t, 'schedule.form.firstDate')}
              </Text>
            ))}
            <Text icon={IconSettings}>{qmCheckType}</Text>
          </UiDiv>
        </UiDiv>
      </UiDiv>
    </ContentBox>
  );
};

export default QMAdminStartCompanyClient;
