import { Collapse, UiDiv } from '@webfox-sc/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/appHooks';
import { fetchCheckitemgrouptypes } from '../../../app/slices/checkitemgrouptypesSlice';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import Button from '../../atoms-v2/Button';

import BaseDataQMCheckitemgrouptype from './BaseDataQMCheckitemgrouptype';
import BaseDataQMCheckitemgrouptypeForm from './BaseDataQMCheckitemgrouptypeForm';

const BaseDataQMCheckitemgrouptypes: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [checkitemgrouptypeIds, setCheckitemgrouptypeIds] = useState<number[]>([]);

  const fetchData = useCallback(async () => {
    const res = await dispatch(fetchCheckitemgrouptypes());
    const ids = (res?.payload?.data || []).map((checkitemgrouptypeData) => checkitemgrouptypeData.id);
    setCheckitemgrouptypeIds(ids);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {checkitemgrouptypeIds.length > 0 && (
        <UiDiv display="flex" flexDirection="column" rowGap="xs" marginBottom="xs">
          {checkitemgrouptypeIds.map((checkitemtypeId) => {
            return <BaseDataQMCheckitemgrouptype key={checkitemtypeId} checkitemgrouptypeId={checkitemtypeId} />;
          })}
        </UiDiv>
      )}
      <Button icon={IconPlus} onClick={() => setShowCreateForm(!showCreateForm)}>
        {t('checkitemGroup.actions.new')}
      </Button>
      <Collapse show={showCreateForm} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="xs">
          <UiDiv padding="s" background="grey20">
            <BaseDataQMCheckitemgrouptypeForm
              onSubmit={() => {
                fetchData();
                setShowCreateForm(false);
              }}
              onClickCancel={() => setShowCreateForm(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
    </>
  );
};

export default BaseDataQMCheckitemgrouptypes;
