const IconArchive = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M5,33.06v60.7a3.72,3.72,0,0,0,3.7,3.7H91.27a3.73,3.73,0,0,0,3.7-3.7V33.06ZM68.73,56.3A3.73,3.73,0,0,1,65,60H35a3.72,3.72,0,0,1-3.7-3.7V48.79a3.73,3.73,0,0,1,3.7-3.7H65a3.72,3.72,0,0,1,3.7,3.7ZM96.18,2.54H3.7A3.73,3.73,0,0,0,0,6.24V28.09H100V6.36A3.93,3.93,0,0,0,96.18,2.54Z"
    />
  </svg>
);

export default IconArchive;
