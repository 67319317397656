import { useMemo } from 'react';
import { useReportroom } from '../../app/entityHooks/useReportroom';
import { entitySelectors } from '../../app/entitySelectors';

export const useQMReportroom = (
  reportroomId: number | undefined
): {
  reportroom: ReportroomEntity | undefined;
  report: ReportEntity | undefined;
  roomgroup: RoomgroupEntity | undefined;
  roomgroupOptions: SelectOption[];
} => {
  const { reportroom, report, roomgroup } = useReportroom(reportroomId);
  const reportRoomgroups = entitySelectors.selectByIds<RoomgroupEntity>('roomgroups', report?.roomgroupIds);

  const roomgroupOptions: SelectOption[] = useMemo(() => {
    return reportRoomgroups.map((reportRoomgroup) => ({
      value: reportRoomgroup.id,
      label: reportRoomgroup.name,
    }));
  }, [reportRoomgroups]);

  return {
    reportroom,
    report,
    roomgroup,
    roomgroupOptions,
  };
};
