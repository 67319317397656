import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { fetchClient } from '../../app/slices/clientsSlice';
import CustomReactSelect from '../CustomReactSelect';

import { useJobServiceSelect } from './useJobServiceSelect';

const avlService = {
  type: 'avl',
  id: '',
  description: '',
  va: '',
  dla: '',
  unit: '',
  price: null,
};

interface JobServiceSelectProps {
  clientId: number | undefined;
  onChange?: (service: any) => void;
  value?: any;
}

const JobServiceSelect: React.FC<JobServiceSelectProps> = ({ clientId, onChange, value }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeServices } = useJobServiceSelect(clientId);

  useEffect(() => {
    if (clientId) {
      dispatch(
        fetchClient(clientId, {
          populate: {
            services: '*',
            client_group: {
              populate: {
                services: '*',
              },
            },
          },
        })
      );
    }
  }, [dispatch, clientId]);

  const handleChange = useCallback(
    (selectedValue) => {
      if (onChange) {
        if (selectedValue === 'avl') {
          onChange(avlService);
        } else {
          const service = activeServices.find(({ id }) => id === selectedValue);
          onChange({
            ...service,
            type: 'vl',
          });
        }
      }
    },
    [onChange, activeServices]
  );

  const serviceOptions = activeServices.map((service) => {
    return {
      value: service.id,
      label: service.description,
    };
  });

  return (
    <CustomReactSelect
      placeholder={t('common.actions.select')}
      options={[
        {
          label: t('service.types.avl.plural'),
          options: [{ value: 'avl', label: t('service.types.avl.entry') }],
        },
        {
          label: t('service.types.vl.plural'),
          options: serviceOptions,
        },
      ]}
      selectedValue={value}
      onChangeValue={handleChange}
    />
  );
};

export default JobServiceSelect;
