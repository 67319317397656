import { UiDiv, UiDivProps, UiText } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../app/appHooks';
import { entitySelectors } from '../../../app/entitySelectors';
import { createContact, deleteContact, updateContact } from '../../../app/slices/contactsSlice';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import IconEdit from '../../../assets/icons-v2/IconEdit';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import AppUtil from '../../../utils/AppUtil';
import Button from '../../atoms-v2/Button';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import IconButton from '../../atoms-v2/IconButton';
import TextBox from '../../atoms-v2/TextBox';
import TextInput from '../../atoms-v2/TextInput';
import TextLink from '../../atoms-v2/TextLink';

interface QMAdminContactsFormProps extends UiDivProps {
  contactIds?: number[];
  onAsyncSave?: (contactIdsNew: number[]) => Promise<void>;
}

const QMAdminContactsForm: React.FC<QMAdminContactsFormProps> = ({ contactIds, onAsyncSave, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [contactIdsDraft, setContactIdsDraft] = useState<number[]>(contactIds || []);
  const contacts = entitySelectors.selectByIds<ContactEntity>('contacts', contactIdsDraft);
  const [contactsDraft, setContactsDraft] = useState<ContactEntity[]>(contacts);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    setContactIdsDraft(contactIds || []);
  }, [contactIds]);

  useEffect(() => {
    setContactsDraft(contacts);
  }, [contacts]);

  const handleOnClickAdd = () => {
    const contactsDraftNew = [
      ...contactsDraft,
      {
        id: -Date.now(),
        name: '',
        email: '',
        editState: 'new',
      } as ContactEntity,
    ];
    setContactsDraft(contactsDraftNew);
  };

  const handleOnChange = (contactId: number, key: string, value: string) => {
    const contactsDraftNew = [...contactsDraft];
    const index = contactsDraftNew.findIndex((contact) => contact.id === contactId);

    if (index !== -1) {
      contactsDraftNew[index] = {
        ...contactsDraftNew[index],
      };
      contactsDraftNew[index][key] = value;
      if (!contactsDraftNew[index].editState) {
        contactsDraftNew[index].editState = 'updated';
      }
    }

    setContactsDraft(contactsDraftNew);
  };

  const handleOnClickRemove = (contactId: number) => {
    const contactsDraftNew = [...contactsDraft];
    const index = contactsDraftNew.findIndex((contact) => contact.id === contactId);

    if (index !== -1) {
      if (contactsDraftNew[index].editState === 'new') {
        //
        contactsDraftNew.splice(index, 1);
      } else {
        contactsDraftNew[index] = {
          ...contactsDraftNew[index],
          editState: 'delete',
        };
      }
    }

    setContactsDraft(contactsDraftNew);
  };

  const handleOnClickSave = async () => {
    const contactIdsNew: number[] = [];

    for (const contact of contactsDraft) {
      // -- new
      if (contact.editState === 'new') {
        if (!!contact.name && !!contact.email && AppUtil.isEmailValid(contact.email)) {
          const result = await dispatch(
            createContact({
              name: contact.name,
              email: contact.email,
            })
          );
          if (result.payload?.data?.id) {
            contactIdsNew.push(result.payload.data.id);
          }
        }
      }
      // -- updated
      else if (contact.editState === 'updated') {
        if (!!contact.name && !!contact.email && AppUtil.isEmailValid(contact.email)) {
          await dispatch(
            updateContact(contact.id, {
              name: contact.name,
              email: contact.email,
            })
          );
        }
        contactIdsNew.push(contact.id);
      }
      // -- delete
      else if (contact.editState === 'delete') {
        await dispatch(deleteContact(contact.id));
      }
      // -- nothing changed
      else {
        contactIdsNew.push(contact.id);
      }
    }

    setContactIdsDraft(contactIdsNew);
    setIsEditMode(false);

    if (onAsyncSave) {
      await onAsyncSave(contactIdsNew);
    }
  };

  const handleOnClickCancel = () => {
    setContactsDraft(contacts || []);
    setIsEditMode(false);
  };

  return (
    <UiDiv padding="s" background="white" {...props}>
      {!isEditMode && contactsDraft.length === 0 && (
        <UiText variant="copy" textAlign="center">
          {t('schedule.info.noContacts')}
        </UiText>
      )}
      <UiDiv display="flex" flexDirection="column" rowGap={{ mobile: 'm', tablet: 'xs' }}>
        {contactsDraft.map((contact) => {
          if (contact.editState === 'delete') {
            return null;
          }
          if (!isEditMode && (!contact.name || !contact.email)) {
            return null;
          }
          return (
            <UiDiv
              key={contact.id}
              display="grid"
              gridTemplateColumns={{ mobile: '1fr', tablet: isEditMode ? '40% 1fr 22px' : '40% 1fr' }}
              rowGap="s"
              columnGap="xs"
            >
              {isEditMode ? (
                <>
                  <UiDiv>
                    <FormElementLabel>{t('common.fields.name')}</FormElementLabel>
                    <TextInput
                      autoComplete="name"
                      value={contact.name}
                      maxLength={256}
                      onChange={(e) => handleOnChange(contact.id, 'name', e.target.value)}
                    />
                  </UiDiv>
                  <UiDiv>
                    <FormElementLabel>{t('user.email')}</FormElementLabel>
                    <TextInput
                      autoComplete="email"
                      value={contact.email}
                      maxLength={256}
                      onChange={(e) => handleOnChange(contact.id, 'email', e.target.value)}
                    />
                  </UiDiv>
                  <UiDiv display={{ mobile: 'block', tablet: 'none' }} textAlign="right">
                    <TextLink
                      icon={IconClose}
                      onClick={(e) => {
                        e.preventDefault();
                        handleOnClickRemove(contact.id);
                      }}
                    >
                      {t('schedule.actions.removeContact')}
                    </TextLink>
                  </UiDiv>
                  <UiDiv paddingTop="34px" display={{ mobile: 'none', tablet: 'block' }}>
                    <IconButton icon={IconClose} onClick={() => handleOnClickRemove(contact.id)} />
                  </UiDiv>
                </>
              ) : (
                <>
                  <UiDiv>
                    <FormElementLabel>{t('common.fields.name')}</FormElementLabel>
                    <TextBox background="grey20">{contact.name}</TextBox>
                  </UiDiv>
                  <UiDiv>
                    <FormElementLabel>{t('user.email')}</FormElementLabel>
                    <TextBox background="grey20" wordBreak="break-all">
                      {contact.email}
                    </TextBox>
                  </UiDiv>
                </>
              )}
            </UiDiv>
          );
        })}

        {isEditMode && (
          <UiDiv>
            <TextLink
              icon={IconPlus}
              onClick={(e) => {
                e.preventDefault();
                handleOnClickAdd();
              }}
            >
              {t('schedule.actions.addContact')}
            </TextLink>
          </UiDiv>
        )}
      </UiDiv>

      <UiDiv display="flex" flexWrap="wrap" gap="xs" justifyContent="center" marginTop="s">
        {isEditMode ? (
          <>
            <Button icon={IconCheck} onClick={handleOnClickSave}>
              {t('common.actions.save')}
            </Button>
            <Button variant="secondary" icon={IconClose} onClick={handleOnClickCancel}>
              {t('common.actions.cancel')}
            </Button>
          </>
        ) : (
          <Button variant="secondary" icon={IconEdit} onClick={() => setIsEditMode(true)}>
            {t('common.actions.edit')}
          </Button>
        )}
      </UiDiv>
    </UiDiv>
  );
};

export default QMAdminContactsForm;
