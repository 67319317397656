import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Block, UiDiv } from '@webfox-sc/core';

import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconClose from '../../../assets/icons-v2/IconClose';
import Button from '../../atoms-v2/Button';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import TextInput from '../../atoms-v2/TextInput';

type QMAdminConfigRoomgroupCreateFormProps = {
  onClickSave?: (name: string) => void;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
};

const QMAdminConfigRoomgroupCreateForm: React.FC<QMAdminConfigRoomgroupCreateFormProps> = ({
  onClickSave,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');

  const handleOnClickSave = () => {
    if (onClickSave) {
      onClickSave(name);
    }
    setName('');
  };

  return (
    <Block>
      <FormElementLabel>{t('common.fields.name')} *</FormElementLabel>
      <UiDiv>
        <TextInput
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </UiDiv>
      <UiDiv display="flex" columnGap="xs" justifyContent="space-between" paddingTop="xs">
        <UiDiv display="flex" columnGap="xs">
          <Button type="submit" icon={IconCheck} disabled={!name} onClick={handleOnClickSave}>
            {t('common.actions.save')}
          </Button>
          <Button variant="secondary" icon={IconClose} onClick={onClickCancel}>
            {t('common.actions.cancel')}
          </Button>
        </UiDiv>
      </UiDiv>
    </Block>
  );
};

export default QMAdminConfigRoomgroupCreateForm;
