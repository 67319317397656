import React from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv, UiText } from '@webfox-sc/core';

import { useCheckresult } from '../../app/entityHooks/useCheckresult';
import IconPicture from '../../assets/icons-v2/IconPicture';
import TextUploadButton from '../atoms-v2/TextUploadButton';

import QMReportroomCheckitemPicture from './QMReportroomCheckitemPicture';

interface QMReportroomCheckitemPicturesProps {
  editMode?: boolean;
  checkresultId: number | undefined;
  onSelectPictures: (fileList: FileList | null) => void;
  onDeletePicture?: (pictureId: number) => void;
}

const QMReportroomCheckitemPictures: React.FC<QMReportroomCheckitemPicturesProps> = ({
  editMode,
  checkresultId,
  onSelectPictures,
  onDeletePicture,
}) => {
  const { t } = useTranslation();
  const { pictures } = useCheckresult(checkresultId);

  if (!editMode && pictures.length === 0) {
    return null;
  }

  return (
    <UiDiv>
      {pictures.length > 0 && (
        <>
          <UiText variant="copy">
            <b>{t('checkitem.pictures')}</b>
          </UiText>
          <UiDiv display="flex" flexWrap="wrap" gap="xs" marginTop="xs">
            {pictures.map((picture) => {
              return (
                <QMReportroomCheckitemPicture
                  key={picture.id}
                  pictureId={picture.id}
                  editMode={editMode}
                  onClickDelete={() => {
                    if (onDeletePicture) {
                      onDeletePicture(picture.id);
                    }
                  }}
                />
              );
            })}
          </UiDiv>
        </>
      )}
      {editMode && (
        <UiDiv marginTop={pictures.length > 0 ? 's' : undefined}>
          <TextUploadButton
            icon={IconPicture}
            multiple
            accept=".jpg, .jpeg, .png"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelectPictures(e.target.files)}
          >
            {t('checkitem.actions.addPictures')}
          </TextUploadButton>
        </UiDiv>
      )}
    </UiDiv>
  );
};

export default QMReportroomCheckitemPictures;
