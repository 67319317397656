import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useQMAdminClient = (
  clientId: number | undefined
): {
  client: ClientEntity | undefined;
  company: CompanyEntity | undefined;
  location: LocationEntity | undefined;
  costcenter: CostcenterEntity | undefined;
  schedules: ScheduleEntity[] | undefined;
  specificityByCraft: Record<string, SpecificityEntry>;
} => {
  const client = entitySelectors.selectById<ClientEntity>('clients', clientId);
  const company = entitySelectors.selectById<CompanyEntity>('companies', client?.companyId);
  const roomgroups = entitySelectors.selectByFilter<RoomgroupEntity>(
    'roomgroups',
    (roomgroup) => client && (roomgroup.companyId === client?.companyId || roomgroup.clientId === clientId)
  );
  const location = entitySelectors.selectById<LocationEntity>('locations', client?.locationId);
  const costcenter = entitySelectors.selectById<CostcenterEntity>('costcenters', client?.costcenterId);
  const schedules = entitySelectors.selectByFilter<ScheduleEntity>(
    'schedules',
    (entity) => entity.clientId === clientId
  );

  const specificityByCraft: Record<string, SpecificityEntry> = useMemo(() => {
    const craftMap = {};
    // TODO implement
    if (roomgroups?.length > 0) {
      roomgroups.forEach(({ craftId, clientId: craftClientId, companyId }) => {
        const key = `${craftId || 0}`;
        if (!craftMap[key] || (clientId && !craftMap[key].clientId)) {
          // add to map, override with more specific entries
          craftMap[key] = {
            craftId: craftId || 0,
            clientId: craftClientId,
            companyId,
          };
        }
      });
    } 
    // add all entries for default and all enabled crafts if not existing
    [0, ...(company?.craftIds || [])].forEach((craftId) => {
      const key = `${craftId || 0}`;
      if (!craftMap[key]) {
        craftMap[key] = {
          craftId: craftId || 0,
          clientId: undefined,
          companyId: undefined,
        };
      }
    });
    return craftMap;
    // return Object.values(craftMap);
  }, [clientId, company, roomgroups]);

  return {
    client,
    company,
    location,
    costcenter,
    schedules,
    specificityByCraft,
  };
};
