import { IMPORT_SCHEMAS } from '../app.config';

export const schemaOptions =
  IMPORT_SCHEMAS === 'sap'
    ? [
        {
          value: '',
          label: 'Bitte wählen',
        },
        {
          value: 'companies',
          label: 'Debitoren',
        },
        {
          value: 'locations',
          label: 'Technische Plätze',
        },
        {
          value: 'clientGroups',
          label: 'TP - Gruppen',
        },
        {
          value: 'services',
          label: 'Leistungen',
        },
      ]
    : [
        {
          value: 'ibsCompanies',
          label: 'IBSuite - Kunden',
        },
        {
          value: 'ibsServices',
          label: 'IBSuite - Leistungen',
        },
      ];

export const encodingOptions = [
  {
    value: 'utf8',
    label: 'UTF-8',
  },
  {
    value: 'iso-8859-1',
    label: 'ISO 8859-1 (Excel 2010)',
  },
];

export const defaultEncoding = 'utf8';

export const getEncodingLabel = (val) => {
  const encoding = encodingOptions.find((enc) => enc.value === val);
  if (encoding) {
    return encoding.label;
  }
  return '';
};

export const getSchemaLabel = (val) => {
  const schema = schemaOptions.find((enc) => enc.value === val);
  if (schema) {
    return schema.label;
  }
  return 'Bitte wählen';
};
