import RestUtil from '../../utils/RestUtil';
import { entitySelectors } from '../entitySelectors';

export const usePicture = (
  pictureId: number | undefined
): {
  picture: PictureEntity | undefined;
  pictureTeaserUrl: string | undefined;
  pictureUrl: string | undefined;
} => {
  const picture = entitySelectors.selectById<PictureEntity>('pictures', pictureId);

  const pictureTeaserUrl = RestUtil.getDownloadUrl(
    picture?.formats?.small?.url || picture?.formats?.medium?.url || picture?.formats?.large?.url || picture?.url
  );

  const pictureUrl = RestUtil.getDownloadUrl(picture?.url);

  return {
    picture,
    pictureTeaserUrl,
    pictureUrl,
  };
};
