import React, { Fragment } from 'react';
import Section from '../../atoms/Section';
import CopyTextM from '../elements/CopyTextM';
import HeadlineOne from '../elements/HeadlineOne';
import HeadlineThree from '../elements/HeadlineThree';
import HeadlineTwo from '../elements/HeadlineTwo';

interface SectionViewProps {
  content: ContentSection[];
  paddingTop?: string;
  paddingBottom?: string;
}

const SectionView: React.FC<SectionViewProps> = ({ content, paddingTop, paddingBottom }) => {
  return (
    <>
      {content.map((entry, index) => {
        const sectionStyles: { paddingTop?: string; paddingBottom?: string } = {};
        if (paddingTop) {
          sectionStyles.paddingTop = paddingTop;
        } else if (!entry.h1) {
          sectionStyles.paddingTop = 'L';
        }
        if (paddingBottom) {
          sectionStyles.paddingBottom = paddingBottom;
        }
        return (
          <Section key={`s_${index}`} {...sectionStyles}>
            {entry.h1 && <HeadlineOne text={entry.h1} />}
            {entry.h2 && <HeadlineTwo text={entry.h2} />}
            {entry.h3 && <HeadlineThree text={entry.h3} />}
            {entry.text
              ? entry.text.map((text, textIndex) => (
                  <Fragment key={`t_${textIndex}`}>
                    <br />
                    <CopyTextM text={text} />
                  </Fragment>
                ))
              : ''}
          </Section>
        );
      })}
    </>
  );
};

export default SectionView;
