import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, UiDiv } from '@webfox-sc/core';

import { useAppDispatch } from '../../../app/appHooks';
import { fetchCheckitemtypes } from '../../../app/slices/checkitemtypesSlice';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import Button from '../../atoms-v2/Button';

import BaseDataQMCheckitemtype from './BaseDataQMCheckitemtype';
import BaseDataQMCheckitemtypeForm from './BaseDataQMCheckitemtypeForm';

const BaseDataQMCheckitemtypes: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [checkitemtypeIds, setCheckitemtypeIds] = useState<number[]>([]);

  const fetchData = useCallback(async () => {
    const res = await dispatch(fetchCheckitemtypes());
    const ids = (res?.payload?.data || []).map((checkitemtypeData) => checkitemtypeData.id);
    setCheckitemtypeIds(ids);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {checkitemtypeIds.length > 0 && (
        <UiDiv display="flex" flexDirection="column" rowGap="xs" marginBottom="xs">
          {checkitemtypeIds.map((checkitemtypeId) => {
            return <BaseDataQMCheckitemtype key={checkitemtypeId} checkitemtypeId={checkitemtypeId} />;
          })}
        </UiDiv>
      )}
      <Button icon={IconPlus} onClick={() => setShowCreateForm(!showCreateForm)}>
        {t('checkitem.actions.new')}
      </Button>
      <Collapse show={showCreateForm} fadeInOut keepChildren={false}>
        <UiDiv paddingTop="xs">
          <UiDiv padding="s" background="grey20">
            <BaseDataQMCheckitemtypeForm
              onSubmit={() => {
                fetchData();
                setShowCreateForm(false);
              }}
              onClickCancel={() => setShowCreateForm(false)}
            />
          </UiDiv>
        </UiDiv>
      </Collapse>
    </>
  );
};

export default BaseDataQMCheckitemtypes;
