import { UiDiv, UiFlex } from '@webfox-sc/core';
import { find, reduce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/appHooks';
import { moveJobs } from '../../../app/slices/clientsSlice';
import { fetchJobs, selectJobsState } from '../../../app/slices/jobsSlice';
import IconArrowDown from '../../../assets/icons-v2/IconArrowDown';
import IconCheck from '../../../assets/icons-v2/IconCheck';
import IconNotFound from '../../../assets/icons-v2/IconNotFound';
import ButtonPrimary from '../../../atoms/ButtonPrimary';
import ClientsUtil from '../../../utils/ClientsUtil';
import { getEntryLabel, stripObject } from '../../../utils/FilterUtil';
import Button from '../../atoms-v2/Button';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import LoadingWrapper from '../../atoms-v2/LoadingWrapper';
import NoResultsNotice from '../../atoms-v2/NoResultsNotice';
import CustomReactSelect from '../../CustomReactSelect';
import JobCardWrapper from '../../jobs/JobCardWrapper';
import PageContent from '../../page/PageContent';
import PageHeading from '../../page/PageHeading';

interface MoveJobListState {
  jobIds?: number[];
  jobsCount?: number;
}

const limitInterval = 20;

const MoveJobs: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clientId: clientIdString } = useParams();
  const clientId = clientIdString ? parseInt(clientIdString) : 0;

  const { fetchJobsStatus } = useAppSelector(selectJobsState);

  const emptyOption = {
    value: '',
    label: t('common.actions.select'),
  };
  const asyncDefaultOptions = [emptyOption];

  const [jobListState, setJobListState] = useState<MoveJobListState>({});
  const [sourceClientId, setSourceClientId] = useState<number | null>(null);
  const [limit, setLimit] = useState<number | null>(null);
  const [jobSelection, setJobSelection] = useState<Record<string, boolean>>({});

  const onChangeClient = (option) => {
    const id = (option && option.value) || null;
    setSourceClientId(id);
  };

  const fetchJobsData = useCallback(async () => {
    const params: {
      [key: string]: any;
    } = {
      client_id: sourceClientId || undefined,
      _limit: limit,
      _start: 0,
    };

    const res = await dispatch(fetchJobs(params, true));
    const receivedJobIds = (res.payload?.data || []).map((jobData) => jobData.id);

    setJobListState({
      jobIds: receivedJobIds,
      jobsCount: res.payload?.count || 0,
    });
    setJobSelection({});
  }, [dispatch, limit, sourceClientId]);

  const handleOnSelect = (selectedJobId: number, isSelected: boolean) => {
    setJobSelection({
      ...jobSelection,
      [`${selectedJobId}`]: isSelected,
    });
  };

  const handleOnCopy = async () => {
    const jobIds = reduce(
      jobSelection,
      (acc, isSelected, id) => {
        if (isSelected) {
          acc.push(parseInt(`${id}`));
        }
        return acc;
      },
      [] as number[]
    );
    await dispatch(moveJobs(clientId, { jobs: jobIds }));
    // refresh
    fetchJobsData();
  };

  useEffect(() => {
    fetchJobsData();
  }, [fetchJobsData]);

  const { jobIds, jobsCount } = jobListState;

  const hasSelectedJob = !!find(jobSelection, (isSelected) => isSelected);

  return (
    <>
      <PageHeading
        heading={t('client.headline.movejobsServices') || undefined}
        onClickButton={() => navigate('/data/objects')}
      />

      <PageContent marginTop="m">
        <UiDiv>
          <FormElementLabel color="ciBlue4">{t('company.headline.singular')}</FormElementLabel>
          <CustomReactSelect
            layout="outline"
            asyncDefaultOptions={asyncDefaultOptions}
            placeholder={t('common.actions.select')}
            asyncApiPath="/clients"
            asyncLimit={500}
            asyncSort="number:asc"
            asyncSetParams={(inputValue) => ClientsUtil.getClientGroupSearchParams(inputValue)}
            asyncFormatOption={(rawClient) => {
              const cl = ClientsUtil.parseData(rawClient);
              const entry = {
                company: stripObject(cl.company),
                location: stripObject(cl.location),
                costcenter: stripObject(cl.costcenter),
                isGroup: cl.isGroup,
                debitorId: cl.debitorId,
              };
              return {
                value: cl.id,
                label: getEntryLabel('client', entry),
              };
            }}
            asyncInputMinLength={3}
            isClearable
            isSearchable
            onChange={onChangeClient}
          />
        </UiDiv>

        {/* job list */}
        <LoadingWrapper isLoading={fetchJobsStatus === 'loading' && (jobIds || []).length === 0} marginTop="m">
          {(jobIds || []).length === 0 ? (
            <NoResultsNotice icon={IconNotFound}>{t('common.messages.noJobsFound')}</NoResultsNotice>
          ) : (
            <>
              <UiFlex flexDirection="column" rowGap="xs" marginTop="m">
                {(jobIds || []).map((jobId) => {
                  return (
                    <JobCardWrapper
                      key={jobId}
                      jobId={jobId}
                      sortingKey="createdAt"
                      onSelect={handleOnSelect}
                      isSelected={!!jobSelection[`${jobId}`]}
                    />
                  );
                })}
              </UiFlex>

              {(jobsCount || 0) > (jobIds || []).length && (
                <UiFlex justifyContent="center" marginTop="m">
                  <Button icon={IconArrowDown} onClick={() => setLimit((limit || 0) + limitInterval)}>
                    {t('common.actions.showMoreEntries')}
                  </Button>
                </UiFlex>
              )}
              <UiFlex justifyContent="center" marginTop="m">
                <ButtonPrimary
                  label={t('common.actions.apply')}
                  icon={IconCheck}
                  disabled={!hasSelectedJob}
                  onClick={handleOnCopy}
                />
              </UiFlex>
            </>
          )}
        </LoadingWrapper>
      </PageContent>
    </>
  );
};

export default MoveJobs;
