import { UiDiv, UiText } from '@webfox-sc/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../../app/appHooks';
import { useRoomgroup } from '../../app/entityHooks/useRoomgroup';
import { pathToQMReport, pathToQMReportroomEdit } from '../../app/paths';
import {
  createReportroom,
  deleteReportroom,
  fetchReportroom,
  updateReportroom,
} from '../../app/slices/reportroomsSlice';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import DateUtil from '../../utils/DateUtil';
import Button from '../atoms-v2/Button';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import TextBox from '../atoms-v2/TextBox';
import TextInput from '../atoms-v2/TextInput';
import CustomReactSelect from '../CustomReactSelect';
import PageContent from '../page/PageContent';
import PageHeading from '../page/PageHeading';

import IconDelete from '../../assets/icons-v2/IconDelete';
import QMReportComment from './QMReportComment';
import QMReportMetaInfo from './QMReportMetaInfo';
import QMReportroomAllCheckitemsControls from './QMReportroomAllCheckitemsControls';
import QMReportroomCheckitem from './QMReportroomCheckitem';
import { useQMReport } from './useQMReport';
import { useQMReportroom } from './useQMReportroom';

interface QMReportroomProps {
  editMode?: boolean;
}

const QMReportroom: React.FC<QMReportroomProps> = ({ editMode }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { reportroomId: reportroomIdString } = useParams();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('ref');
  const reportroomId = reportroomIdString ? parseInt(reportroomIdString) : -1;
  const { reportroom, roomgroup, roomgroupOptions } = useQMReportroom(reportroomId);
  const { report, reporter, contacts, validRoomCount, countReportroomsFinished, countReportroomsNotStarted, craft } =
    useQMReport(reportroom?.reportId);

  const [selectedRoomgroupId, setSelectedRoomgroupId] = useState<number | undefined>(undefined);
  const [number, setNumber] = useState<string | undefined>(reportroom?.number);

  useEffect(() => {
    if (reportroomId) {
      dispatch(
        fetchReportroom(reportroomId, {
          populate: {
            report: {
              populate: {
                client: {
                  populate: ['schedules'],
                },
                craft: {
                  fields: ['id', 'name'],
                },
                contacts: '*',
                reportrooms: '*',
                reporter: '*',
              },
            },
            roomgroup: '*',
            checkresults: {
              populate: {
                checkitem: {
                  fields: ['id'],
                },
                checkitemType: '*',
                checkitemgroupType: '*',
                pictures: '*',
                reportroom: {
                  fields: ['id'],
                },
              },
            },
          },
        })
      );
    }
  }, [dispatch, reportroomId]);

  useEffect(() => {
    setNumber(reportroom?.number);
  }, [reportroom?.number]);

  useEffect(() => {
    // disable and set default craft if there is no other
    if (roomgroupOptions && roomgroupOptions.length === 1) {
      setSelectedRoomgroupId(
        typeof roomgroupOptions[0].value === 'number'
          ? roomgroupOptions[0].value
          : (roomgroupOptions[0].value && parseInt(roomgroupOptions[0].value)) || undefined
      );
    }
  }, [roomgroupOptions]);

  const backPath = useMemo(() => {
    if (referrer) {
      return referrer;
    }
    if (report) {
      return pathToQMReport(report?.id);
    }
    return '';
  }, [referrer, report]);

  const { checkitemgroups } = useRoomgroup(roomgroup?.id);
  const checkitemIds = checkitemgroups.flatMap((checkitemgroup) => {
    return checkitemgroup.checkitemIds;
  });

  if (!reportroom || !report) {
    return null;
  }

  const handleOnClickStart = () => {
    if (!!selectedRoomgroupId && !!number) {
      dispatch(
        updateReportroom(reportroom.id, {
          roomgroup: selectedRoomgroupId,
          roomgroup_id: selectedRoomgroupId,
          number,
        })
      );
    }
  };

  const handleOnSaveComment = (comment?: string) => {
    dispatch(
      updateReportroom(reportroom.id, {
        comment,
      })
    );
  };

  const handleOnClickDelete = async () => {
    await dispatch(deleteReportroom(reportroom.id));
    navigate(backPath);
  };

  const handleOnClickSave = async () => {
    if (number !== undefined) {
      await dispatch(
        updateReportroom(reportroom.id, {
          number,
          status: 'finished',
        })
      );
      navigate(backPath);
    }
  };

  const handleOnClickSaveAndNext = async () => {
    if (number !== undefined) {
      await dispatch(
        updateReportroom(reportroom.id, {
          number,
          status: 'finished',
        })
      );
      const response = await dispatch(
        createReportroom({
          report: report.id,
        })
      );
      const reportroomIdNew = response?.payload?.data?.id;
      if (reportroomIdNew) {
        navigate(pathToQMReportroomEdit(reportroomIdNew, `${search}`, true));
      }
    }
  };

  const reportTypeFormatted = report.isAdHoc ? t('report.form.adHoc.true') : t('report.form.adHoc.false');
  const maxRoomCount = Math.max(validRoomCount, countReportroomsFinished + 1);
  const isComplete = (reportroom?.checkresultIds?.length || 0) >= (roomgroup?.checkitemCount || 0);

  return (
    <>
      <PageHeading
        heading={
          report.status === 'finished'
            ? reportTypeFormatted
            : t('report.headline.execute', { type: reportTypeFormatted }) || ''
        }
        backlinkLabel={t('common.actions.back') || ''}
        backlinkPath={backPath}
      />
      <PageContent marginTop="l">
        <QMReportMetaInfo
          companyName={report.companyName}
          locationDescription={report.locationDescription}
          costcenterNumber={report.costcenterNumber}
          contacts={contacts}
          reporter={reporter}
          dateFormatted={report.status === 'finished' ? DateUtil.getDateFormatted(report.date) : undefined}
          dueDateFormatted={report.status !== 'finished' ? DateUtil.getDateFormatted(report.dueDate) : undefined}
          craftName={craft?.name || t('craft.form.options.default') || undefined}
        />
        <UiDiv marginTop="l">
          {reportroom.status === 'finished' ? (
            <UiText variant="h2" color="white" textAlign="center">
              {t('reportroom.form.status.finished')}
            </UiText>
          ) : (
            <UiText variant="h2" color="white" textAlign="center">
              {t('common.entities.reportroom')} {countReportroomsFinished + 1}/{maxRoomCount}
            </UiText>
          )}
        </UiDiv>
        <UiDiv background="grey20" padding={{ mobile: 's', tablet: 'm' }} marginTop="m">
          <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="xs">
            <UiDiv gridColumn={{ mobile: '1/13', laptop: '1/8' }}>
              <FormElementLabel>{t('common.entities.roomgroup.singular')}</FormElementLabel>
              {roomgroup ? (
                <TextBox>{roomgroup.name}</TextBox>
              ) : (
                <CustomReactSelect
                  isDisabled={roomgroupOptions.length === 1}
                  options={roomgroupOptions}
                  selectedValue={selectedRoomgroupId}
                  onChangeValue={setSelectedRoomgroupId}
                  placeholder="Auswählen ..."
                />
              )}
            </UiDiv>
            <UiDiv gridColumn={{ mobile: '1/8', laptop: '8/11' }}>
              {editMode ? (
                <>
                  <FormElementLabel>{t('roomgroup.number')}*</FormElementLabel>
                  <TextInput value={number} maxLength={100} onChange={(e) => setNumber(e.target.value)} />
                </>
              ) : (
                <>
                  <FormElementLabel>{t('roomgroup.number')}</FormElementLabel>
                  <TextBox>{number}</TextBox>
                </>
              )}
            </UiDiv>
            <UiDiv gridColumn={{ mobile: '8/13', laptop: '11/13' }}>
              <FormElementLabel>{t('common.fields.date')}</FormElementLabel>
              <TextBox>{DateUtil.getDateFormatted(reportroom.createdAt)}</TextBox>
            </UiDiv>
          </UiDiv>

          {editMode && !roomgroup && (
            <UiDiv display="flex" justifyContent="center" marginTop="m">
              <Button endIcon={IconArrowRight} disabled={!selectedRoomgroupId || !number} onClick={handleOnClickStart}>
                {t('reportroom.actions.start')}
              </Button>
            </UiDiv>
          )}

          {roomgroup && (
            <>
              {editMode && <QMReportroomAllCheckitemsControls reportroomId={reportroomId} />}
              {checkitemIds.length > 0 && (
                <UiDiv display="flex" flexDirection="column" rowGap="s" marginTop="m">
                  {checkitemIds.map((checkitemId) => {
                    return (
                      <QMReportroomCheckitem
                        key={checkitemId}
                        checkitemId={checkitemId}
                        reportroomId={reportroomId}
                        editMode={editMode}
                      />
                    );
                  })}
                </UiDiv>
              )}
              {editMode && <QMReportroomAllCheckitemsControls reportroomId={reportroomId} />}

              {(editMode || !!reportroom.comment) && (
                <UiDiv maxWidth="640px" margin="0 auto" marginTop={{ mobile: 'm', tablet: '40px' }}>
                  <QMReportComment
                    variant="box"
                    editMode={editMode}
                    comment={reportroom.comment}
                    onSave={(comment) => handleOnSaveComment(comment)}
                  />
                </UiDiv>
              )}

              {editMode && reportroom.status !== 'finished' && !isComplete && (
                <UiText variant="copy" textAlign="center" marginTop={{ mobile: 'm' }}>
                  {t('reportroom.info.answerAll')}
                </UiText>
              )}

              {editMode && (
                <UiDiv
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  gap="xs"
                  marginTop={{ mobile: 'm', tablet: '40px' }}
                >
                  {editMode && reportroom.status !== 'finished' && (
                    <Button icon={IconDelete} onClick={handleOnClickDelete}>
                      {t('common.actions.delete')}
                    </Button>
                  )}
                  {reportroom.status !== 'finished' && (
                    <Button icon={IconCheck} onClick={handleOnClickSaveAndNext} disabled={!isComplete || !number}>
                      {t('reportroom.actions.saveAndContinue')}
                    </Button>
                  )}
                  {reportroom.status !== 'finished' && countReportroomsNotStarted === 0 && (
                    <Button icon={IconCheck} onClick={handleOnClickSave} disabled={!isComplete || !number}>
                      {t('common.actions.save')}
                    </Button>
                  )}
                  {reportroom.status === 'finished' && (
                    <Button icon={IconCheck} onClick={handleOnClickSave} disabled={!number}>
                      {t('common.actions.refresh')}
                    </Button>
                  )}
                  <Button icon={IconClose} variant="secondary" onClick={() => navigate(backPath)}>
                    {t('common.actions.cancel')}
                  </Button>
                </UiDiv>
              )}
            </>
          )}
        </UiDiv>
      </PageContent>
    </>
  );
};

export default QMReportroom;
