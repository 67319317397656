import { Block, InlineSVG, UiDiv, UiInput, UiText } from '@webfox-sc/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useService } from '../../app/entityHooks/useService';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconDelete from '../../assets/icons-v2/IconDelete';
import IconEdit from '../../assets/icons-v2/IconEdit';
import IconInfo from '../../assets/icons-v2/IconInfo';
import AppUtil from '../../utils/AppUtil';
import DateUtil from '../../utils/DateUtil';
import { formatDecimal, formatPrice } from '../../utils/format';
import JobUtil from '../../utils/JobUtil';
import Button from '../atoms-v2/Button';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import FormPanel from '../atoms-v2/FormPanel';
import FormRowGroup from '../atoms-v2/FormRowGroup';
import TextArea from '../atoms-v2/TextArea';
import TextBox from '../atoms-v2/TextBox';
import TextInput from '../atoms-v2/TextInput';
import TextInputSpecialNumber from '../atoms-v2/TextInputSpecialNumber';
import CustomReactSelect from '../CustomReactSelect';
import JobServiceDlaInfoIcon from '../job-services/JobServiceDlaInfoIcon';

import JobServiceSelect from './JobServiceSelect';

const now = new Date();
now.setUTCHours(0, 0, 0, 0);

interface JobServiceEditorProps {
  jobService?: JobServiceData;
  positionDefault?: number;
  clientId?: number;
  countJobServices?: number;
  serviceEditIndex?: number;
  isJobCreateMode?: boolean;
  isJobEditMode?: boolean;
  isEditMode?: boolean;
  certificateEntryId?: string;
  onClickEdit?: React.MouseEventHandler<HTMLButtonElement>;
  onClickSubmit?: (jobServiceUpdated: any) => void;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onClickDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

const JobServiceEditor: React.FC<JobServiceEditorProps> = ({
  jobService,
  positionDefault,
  clientId,
  countJobServices,
  serviceEditIndex,
  isJobCreateMode,
  isJobEditMode,
  isEditMode,
  certificateEntryId,
  onClickEdit,
  onClickCancel,
  onClickDelete,
  onClickSubmit,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { service: clientService } = useService(jobService?.serviceId);

  const [serviceId, setServiceId] = useState<number | undefined>(undefined);
  const [locationCode, setLocationCode] = useState<string | undefined>(undefined);
  const [position, setPosition] = useState<number | undefined>(positionDefault);
  const [type, setType] = useState<string | undefined>('');
  const [serviceDescription, setServiceDescription] = useState<string | undefined>('');
  const [va, setVa] = useState<string | undefined>('');
  const [dla, setDla] = useState<string | undefined>('');
  const [contractNumber, setContractNumber] = useState<string | undefined>('');
  const [contractItem, setContractItem] = useState<string | undefined>('');
  const [unit, setUnit] = useState<string | undefined>('');
  const [unitPrice, setUnitPrice] = useState<number | undefined>(undefined);
  const [quantity, setQuantity] = useState<number | undefined>(undefined);
  const [comments, setComments] = useState<string | undefined>('');
  const [location, setLocation] = useState<string | undefined>('');
  const [person, setPerson] = useState<string | undefined>('');

  const reset = useCallback(() => {
    if (jobService) {
      setServiceId(jobService.serviceId);
      setLocationCode(jobService.locationCode);
      setPosition(jobService.position);
      setType(jobService.type);
      setServiceDescription(jobService.serviceDescription);
      setVa(jobService.va);
      setDla(jobService.dla);
      setContractNumber(jobService.contractNumber);
      setContractItem(jobService.contractItem);
      setUnit(jobService.unit);
      setUnitPrice(jobService.unitPrice);
      setQuantity(jobService.quantity);
      setComments(jobService.comments);
      setLocation(jobService.location);
      setPerson(jobService.person);
    }
  }, [jobService]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (!isEditMode) {
      reset();
    }
  }, [isEditMode, reset]);

  const handleChangeServiceDropdown = (service) => {
    setServiceId(service.id);
    setLocationCode(service.locationCode);
    setType(service.type);
    setServiceDescription(service.description);
    setVa(service.va || '');
    setDla(service.dla);
    setContractNumber(service.contractNumber || '');
    setContractItem(service.contractItem || '');
    setUnit(service.unit);
    setUnitPrice(service.price);
    setQuantity(service.baseQuantity || 1);
    setComments('');
    setLocation('');
    setPerson('');
  };

  const isDataValid = () => {
    return !!position && !!serviceDescription && !!va && !!dla && !!unit && !!unitPrice && !!quantity;
  };

  const handleClickUpdate: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (onClickSubmit) {
      onClickSubmit({
        serviceId,
        locationCode,
        position,
        type,
        serviceDescription,
        va,
        dla,
        contractNumber,
        contractItem,
        unit,
        unitPrice,
        quantity,
        comments,
        location,
        person,
      });
    }
  };

  /**
   * service date notification
   */
  let notificationText = '';
  let notificationColor = '';
  if (clientService) {
    const { validFrom, validTo } = clientService;
    if (validFrom) {
      const dateValidFrom = new Date(validFrom);
      if (dateValidFrom > now) {
        notificationText = t('job.serviceForm.messages.willBeValid', {
          date: DateUtil.getDateFormatted(dateValidFrom.getTime()),
        });
        notificationColor = colors.trafficLightYellow;
      }
    }
    if (validTo) {
      const dateValidTo = new Date(validTo);
      const twoWeeksAsMs = 1000 * 60 * 60 * 24 * 14;

      if (dateValidTo < now) {
        notificationText = t('job.serviceForm.messages.isInvalid', {
          date: DateUtil.getDateFormatted(dateValidTo.getTime() + 1000 * 60 * 60 * 24),
        });
        notificationColor = colors.trafficLightRed;
      } else if (dateValidTo.getTime() < now.getTime() + twoWeeksAsMs) {
        notificationText = t('job.serviceForm.messages.willBeInvalid', {
          date: DateUtil.getDateFormatted(dateValidTo.getTime() + 1000 * 60 * 60 * 24),
        });
        notificationColor = colors.trafficLightRed;
      }
    }
  }

  /**
   * select options
   */
  const vaOptions = AppUtil.getSelectOptionsVA();
  const unitOptions = AppUtil.getSelectOptionsServiceUnit();
  let grid1: string;
  if (isEditMode) {
    grid1 = '1fr 1fr';
  } else {
    grid1 = '1fr';
  }

  return (
    <FormPanel>
      {notificationText && (
        <UiDiv paddingBottom="m">
          <UiText
            display="flex"
            justifyContent="center"
            padding="13px 9px"
            background="grey20"
            color="ciBlue1"
            border={`2px solid ${notificationColor}`}
            variant="copy"
          >
            <em>{notificationText}</em>
          </UiText>
        </UiDiv>
      )}

      <FormRowGroup>
        <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 9fr' }} columnGap="xxs">
          <FormElementLabel order={{ tablet: 1 }}>{t('service.position.short')}</FormElementLabel>
          {isEditMode ? (
            <UiDiv order={{ tablet: 3 }}>
              <TextInputSpecialNumber
                value={position}
                maximumIntegerDigits={4}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
                onChange={(_, value) => setPosition(value)}
              />
            </UiDiv>
          ) : (
            <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
              {position}
            </TextBox>
          )}
          <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 2 }}>
            {t('service.serviceDescription')}
            {type === 'avl' ? ' *' : ''}
          </FormElementLabel>
          <UiDiv
            order={{ tablet: 4 }}
            display="grid"
            gridTemplateColumns={{ mobile: '1fr', tablet: grid1 }}
            columnGap="xxs"
          >
            <UiDiv>
              {isEditMode && type === 'avl' ? (
                <UiDiv>
                  <TextArea value={serviceDescription} onChange={(e) => setServiceDescription(e.target.value)} />
                </UiDiv>
              ) : (
                <TextBox background="grey20" color="ciBlue1">
                  <div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: (serviceDescription || '').replace(/\n/g, '<br />'),
                      }}
                    />
                  </div>
                  {!isEditMode && (
                    <ul>
                      {type === 'vl' && <li>{`${t('service.types.vl.singular')} (${va})`}</li>}
                      {type === 'avl' && <li>{`${t('service.types.avl.singular')} (${va})`}</li>}
                      {comments && (
                        <li>
                          {t('common.fields.comments')}: {comments}
                        </li>
                      )}
                      {location && (
                        <li>
                          {t('service.location.short')}: {location}
                        </li>
                      )}
                      {person && (
                        <li>
                          {t('service.person.short')}: {person}
                        </li>
                      )}
                    </ul>
                  )}
                </TextBox>
              )}
            </UiDiv>
            {isEditMode && (
              <UiDiv marginTop={{ mobile: 'xs', tablet: 0 }}>
                <JobServiceSelect
                  clientId={clientId}
                  onChange={(service) => handleChangeServiceDropdown(service)}
                  value={type === 'avl' ? 'avl' : serviceId || ''}
                />
              </UiDiv>
            )}
          </UiDiv>
        </UiDiv>

        {isEditMode && (
          <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '3fr 1fr' }} columnGap="xxs">
            <FormElementLabel order={{ tablet: 1 }}>{t('service.type')}</FormElementLabel>
            <UiDiv order={{ tablet: 3 }} display="flex" columnGap="m">
              <UiDiv display="flex" height="50px" columnGap="7px" alignItems="center">
                <UiInput type="radio" value="vl" name="category" checked={type === 'vl'} disabled />
                <UiText variant="copy">{t('service.types.vl.singular')}</UiText>
              </UiDiv>
              <UiDiv display="flex" height="50px" columnGap="7px" alignItems="center">
                <UiInput type="radio" value="avl" name="category" checked={type === 'avl'} disabled />
                <UiText variant="copy">{t('service.types.avl.singular')}</UiText>
              </UiDiv>
            </UiDiv>
            <FormElementLabel order={{ tablet: 2 }}>
              {t('common.fields.va')}
              {type === 'avl' ? ' *' : ''}
            </FormElementLabel>
            {isEditMode && type === 'avl' ? (
              <UiDiv order={{ tablet: 4 }}>
                <CustomReactSelect
                  placeholder={t('common.actions.select')}
                  options={vaOptions}
                  selectedValue={va}
                  onChangeValue={setVa}
                />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1">
                {va}
              </TextBox>
            )}
          </UiDiv>
        )}

        <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 2fr 1fr 2fr 2fr' }} columnGap="xxs">
          <UiDiv display="flex" columnGap="xxs" order={{ tablet: 1 }}>
            <FormElementLabel>{t('common.fields.dla')}{type === 'avl' ? ' *' : ''}</FormElementLabel>
            <JobServiceDlaInfoIcon />
          </UiDiv>
          {isEditMode && type === 'avl' ? (
            <UiDiv order={{ tablet: 6 }}>
              <TextInput value={dla} onChange={(e) => setDla(e.target.value)} maxLength={8} />
            </UiDiv>
          ) : (
            <TextBox order={{ tablet: 6 }} background="grey20" color="ciBlue1">
              {dla}
            </TextBox>
          )}
          <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 2 }}>
            {t('service.unit')}
            {type === 'avl' ? ' *' : ''}
          </FormElementLabel>
          {isEditMode && type === 'avl' ? (
            <UiDiv order={{ tablet: 7 }}>
              <CustomReactSelect
                placeholder={t('common.actions.select')}
                options={unitOptions}
                selectedValue={unit}
                onChangeValue={setUnit}
              />
            </UiDiv>
          ) : (
            <TextBox order={{ tablet: 7 }} background="grey20" color="ciBlue1">
              {unit}
            </TextBox>
          )}
          <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 3 }}>
            {t('service.quantity')} *
          </FormElementLabel>
          {isEditMode && (type === 'avl' || type === 'vl') ? (
            <UiDiv order={{ tablet: 8 }}>
              <TextInputSpecialNumber
                value={quantity}
                maximumIntegerDigits={5}
                minimumFractionDigits={0}
                maximumFractionDigits={2}
                onChange={(_, value) => setQuantity(value)}
              />
            </UiDiv>
          ) : (
            <TextBox order={{ tablet: 8 }} background="grey20" color="ciBlue1">
              {formatDecimal(quantity, 0, 2)}
            </TextBox>
          )}
          <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 4 }}>
            {t('service.unitPrice')}
            {type === 'avl' ? ' *' : ''}
          </FormElementLabel>
          {isEditMode && type === 'avl' ? (
            <UiDiv order={{ tablet: 9 }}>
              <TextInputSpecialNumber
                value={unitPrice}
                maximumIntegerDigits={5}
                minimumFractionDigits={2}
                maximumFractionDigits={4}
                isNegativeNumberAllowed
                onChange={(_, value) => setUnitPrice(value)}
              />
            </UiDiv>
          ) : (
            <TextBox order={{ tablet: 9 }} background="grey20" color="ciBlue1">
              {formatPrice(unitPrice, 2, 4)}
            </TextBox>
          )}
          <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 5 }}>
            {t('service.netPrice')}
          </FormElementLabel>
          <TextBox order={{ tablet: 10 }} background="grey20" color="ciBlue1">
            {formatPrice(JobUtil.getServiceNetPrice(unitPrice, quantity))}
          </TextBox>
        </UiDiv>

        {isEditMode && (
          <>
            <UiDiv display="grid" gridTemplateColumns="1fr">
              <FormElementLabel>{t('common.fields.comments')}</FormElementLabel>
              {type === 'avl' || type === 'vl' ? (
                <>
                  <TextInput value={comments} onChange={(e) => setComments(e.target.value)} />
                </>
              ) : (
                <TextBox order={{ tablet: 10 }} background="grey20" color="ciBlue1">
                  {comments}
                </TextBox>
              )}
            </UiDiv>
            <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} columnGap="xxs">
              <FormElementLabel order={{ tablet: 1 }}>{t('service.location.long')}</FormElementLabel>
              {type === 'avl' || type === 'vl' ? (
                <UiDiv order={{ tablet: 3 }}>
                  <TextInput value={location} onChange={(e) => setLocation(e.target.value)} />
                </UiDiv>
              ) : (
                <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
                  {location}
                </TextBox>
              )}
              <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 2 }}>
                {t('service.person.long')}
              </FormElementLabel>
              {type === 'avl' || type === 'vl' ? (
                <UiDiv order={{ tablet: 4 }}>
                  <TextInput value={person} onChange={(e) => setPerson(e.target.value)} />
                </UiDiv>
              ) : (
                <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1">
                  {person}
                </TextBox>
              )}
            </UiDiv>
          </>
        )}
      </FormRowGroup>

      {isEditMode && (
        <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="m">
          <Button icon={IconCheck} disabled={!isDataValid()} onClick={handleClickUpdate}>
            {t('common.actions.apply')}
          </Button>
          <Button variant="secondary" icon={IconClose} disabled={countJobServices === 0} onClick={onClickCancel}>
            {t('common.actions.cancel')}
          </Button>
        </UiDiv>
      )}

      {(isJobCreateMode || isJobEditMode) && !isEditMode && serviceEditIndex === -1 && (
        <Block display="flex" justifyContent="flex-end" gap="8px" paddingTop="20px">
          <Button icon={IconEdit} aria-label="Edit" onClick={onClickEdit} />
          {(countJobServices || 0) > 1 && <Button icon={IconDelete} aria-label="Delete" onClick={onClickDelete} />}
        </Block>
      )}

      {certificateEntryId && (
        <UiDiv display="flex" alignItems="center" columnGap="xs" marginTop="m">
          <UiDiv height="18px">
            <InlineSVG width="18px" color={colors.acGreenDark}>
              {IconInfo}
            </InlineSVG>
          </UiDiv>
          <UiText variant="copy" color="acGreenDark">
            {t('job.serviceForm.messages.includedInCertificate', { certificateEntryId })}
          </UiText>
        </UiDiv>
      )}
    </FormPanel>
  );
};

export default JobServiceEditor;
