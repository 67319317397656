import { entitySelectors } from '../entitySelectors';

export const useCostcenter = (
  costcenterId: number | undefined
): {
  costcenter: CostcenterEntity | undefined;
  users: UserEntity[];
} => {
  const costcenter = entitySelectors.selectById<CostcenterEntity>('costcenters', costcenterId);
  const users = entitySelectors.selectByIds<UserEntity>('users', costcenter?.userIds);

  return {
    costcenter,
    users,
  };
};
