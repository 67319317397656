const IconEmail = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      d="M96.43,14.94H3.57L50,52.48,97,15A3,3,0,0,0,96.43,14.94ZM52.25,59.73a3.61,3.61,0,0,1-4.52,0L0,21.13V81.56a3.54,3.54,0,0,0,3.57,3.5H96.43a3.54,3.54,0,0,0,3.57-3.5V21.65Z"
    />
  </svg>
);

export default IconEmail;
