import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconClose from '../../assets/icons-v2/IconClose';
import IconUpload from '../../assets/icons-v2/IconUpload';
import UploadButtonSecondary from '../../atoms/UploadButtonSecondary';
import RestUtil from '../../utils/RestUtil';
import Button from '../atoms-v2/Button';
import TextBox from '../atoms-v2/TextBox';
import FormLabel from '../elements/FormLabel';

interface JobFilesProps {
  files?: FileEntity[];
  isEditable?: boolean;
  onAddFiles?: (fileList: FileList) => void;
  onDeleteFile?: (fileId: any) => void;
}

const JobFiles: React.FC<JobFilesProps> = ({ files, isEditable, onAddFiles, onDeleteFile }) => {
  const { t } = useTranslation();
  const handleChangeFileUpload = (e) => {
    const { files: inputFiles } = e.target;
    if (!!onAddFiles && inputFiles.length > 0) {
      onAddFiles(inputFiles);
    }
    e.target.value = null;
  };

  return (
    <>
      {!!files && files.length > 0 && (
        <>
          {isEditable && <FormLabel text={t('common.fields.files')} />}
          <UiDiv marginBottom="xxs">
            <UiDiv display="flex" flexDirection="column" rowGap="xxs">
              {files.map((file) => (
                <UiDiv key={file.id} display="flex" columnGap="xxs">
                  <UiDiv flexGrow={1}>
                    <TextBox variant="copy" key={file.id} background="grey20" wordBreak="break-all">
                      <a href={RestUtil.getDownloadUrl(file.url)} target="_blank">
                        {file.name}
                      </a>
                    </TextBox>
                  </UiDiv>
                  {isEditable && (
                    <UiDiv>
                      <Button
                        aria-label={t('common.actions.delete') || undefined}
                        icon={IconClose}
                        onClick={() => {
                          if (onDeleteFile) {
                            onDeleteFile(file.id);
                          }
                        }}
                      />
                    </UiDiv>
                  )}
                </UiDiv>
              ))}
            </UiDiv>
          </UiDiv>
        </>
      )}

      {isEditable && (
        <UiDiv>
          <UploadButtonSecondary
            label={t('common.actions.addFiles')}
            icon={IconUpload}
            accept=".pdf, application/pdf"
            multiple
            onChange={handleChangeFileUpload}
          />
        </UiDiv>
      )}
    </>
  );
};

export default JobFiles;
