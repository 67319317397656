import React from 'react';
import styled, { CSSObject } from 'styled-components';
import IconRatingBad from '../../assets/icons-v2/IconRatingBad';
import IconRatingGood from '../../assets/icons-v2/IconRatingGood';
import IconRatingNeutral from '../../assets/icons-v2/IconRatingNeutral';
import IconRatingNone from '../../assets/icons-v2/IconRatingNone';

type RatingButtonVariant = 'good' | 'neutral' | 'bad' | 'bad-pending' | 'none';

interface StyledRatingButtonProps {
  variant: RatingButtonVariant;
}

const StyledRatingButton = styled.button<StyledRatingButtonProps>(({ variant, theme }) => {
  const baseStyle: CSSObject = {
    appearance: 'none',
    width: '50px',
    height: '50px',
    border: 0,
    margin: 0,
    padding: 0,
    background: 'transparent',
    color: theme.colors.grey20,
    cursor: 'pointer',
    transition: 'color 0.1s ease, opacity 0.1s ease',
    svg: {
      width: '50px',
      maxWidth: 'none',
      height: '50px',
      verticalAlign: 'top',
    },
    '&:hover': {
      color: theme.colors.grey80,
    },
    '&:active': {
      opacity: 0.8,
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
      '&:hover': {
        opacity: 0.5,
      },
    },
    '&[data-active="true"]': {
      color: theme.colors.grey80,
      opacity: 1,
    },
  };

  switch (variant) {
    case 'good':
      return {
        ...baseStyle,
        '&:hover': {
          color: theme.colors.trafficLightGreen,
        },
        '&[data-active="true"]': {
          color: theme.colors.trafficLightGreen,
          opacity: 1,
        },
      };

    case 'neutral':
      return {
        ...baseStyle,
        '&:hover': {
          color: theme.colors.trafficLightYellow,
        },
        '&[data-active="true"]': {
          color: theme.colors.trafficLightYellow,
          opacity: 1,
        },
      };

    case 'bad':
      return {
        ...baseStyle,
        '&:hover': {
          color: theme.colors.trafficLightRed,
        },
        '&[data-active="true"]': {
          color: theme.colors.trafficLightRed,
          opacity: 1,
        },
      };

    case 'bad-pending':
      return {
        ...baseStyle,
        '&:hover': {
          color: theme.colors.trafficLightRed,
        },
        '&[data-active="true"]': {
          color: theme.colors.trafficLightRed,
          opacity: 0.7,
        },
      };

    default:
      return {
        ...baseStyle,
      };
  }
});

interface RatingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: RatingButtonVariant;
  active?: boolean;
}

const RatingButton: React.FC<RatingButtonProps> = ({ variant, active, type, ...props }) => {
  const dataAttributes = {
    'data-active': active,
  };

  return (
    <StyledRatingButton variant={variant} type={type || 'button'} {...props} {...dataAttributes}>
      {variant === 'good'
        ? IconRatingGood
        : variant === 'neutral'
        ? IconRatingNeutral
        : ['bad', 'bad-pending'].includes(variant) 
        ? IconRatingBad
        : IconRatingNone}
    </StyledRatingButton>
  );
};

export default RatingButton;
