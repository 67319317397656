import React from 'react';
import { useTranslation } from 'react-i18next';
import { UiDiv, UiElement } from '@webfox-sc/core';

import { formatDecimal, formatPrice } from '../../utils/format';
import JobUtil from '../../utils/JobUtil';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import TextBox from '../atoms-v2/TextBox';

interface JobServiceItemProps {
  jobService: JobServiceData | undefined;
  showPrices?: boolean;
}

const JobServiceItem: React.FC<JobServiceItemProps> = ({ jobService, showPrices }) => {
  const { t } = useTranslation();
  if (!jobService) {
    return null;
  }

  return (
    <>
      <UiDiv background="grey20" padding="s">
        <UiDiv display="grid" gridTemplateColumns="repeat(24, 1fr)" columnGap="xxs">
          <FormElementLabel gridColumn={{ mobile: '1 / 25', tablet: '1 / 4' }} order={{ tablet: 1 }}>
            {t('service.position.short')}
          </FormElementLabel>
          <TextBox gridColumn={{ mobile: '1 / 25', tablet: '1 / 4' }} order={{ tablet: 3 }}>
            {jobService.position}
          </TextBox>
          <FormElementLabel
            gridColumn={{ mobile: '1 / 25', tablet: '4 / 25' }}
            order={{ tablet: 2 }}
            marginTop={{ mobile: 'xs', tablet: 0 }}
          >
            {t('service.serviceDescription')}
          </FormElementLabel>
          <TextBox gridColumn={{ mobile: '1 / 25', tablet: '4 / 25' }} order={{ tablet: 4 }}>
            <UiDiv
              dangerouslySetInnerHTML={{ __html: (jobService.serviceDescription || '').replace(/\n/g, '<br />') }}
            />
            <UiElement as="ul" margin={0} marginTop="xs">
              {jobService.type === 'vl' && <li>{`${t('service.types.vl.singular')} (${jobService.va})`}</li>}
              {jobService.type === 'avl' && <li>{`${t('service.types.avl.singular')} (${jobService.va})`}</li>}
              {jobService.comments && <li>{t('common.fields.comments')}: {jobService.comments}</li>}
              {jobService.location && <li>{t('service.location.short')}: {jobService.location}</li>}
              {jobService.person && <li>{t('service.person.short')}: {jobService.person}</li>}
            </UiElement>
          </TextBox>
        </UiDiv>

        <UiDiv display="grid" gridTemplateColumns="repeat(24, 1fr)" rowGap="xs" columnGap="xxs" marginTop="xs">
          <UiDiv gridColumn={{ mobile: '1 / 25', tablet: '1 / 4' }}>
            <FormElementLabel>{t('common.fields.dla')}</FormElementLabel>
            <TextBox>{jobService.dla}</TextBox>
          </UiDiv>
          <UiDiv gridColumn={{ mobile: '1 / 17', tablet: '4 / 10' }}>
            <FormElementLabel>{t('service.unit')}</FormElementLabel>
            <TextBox>{jobService.unit}</TextBox>
          </UiDiv>
          <UiDiv gridColumn={{ mobile: '17 / 25', tablet: '10 / 13' }}>
            <FormElementLabel>{t('service.quantity')}</FormElementLabel>
            <TextBox>{formatDecimal(jobService.quantity, 0, 2)}</TextBox>
          </UiDiv>
          {showPrices && (
            <>
              <UiDiv gridColumn={{ mobile: '1 / 25', tablet: '13 / 19' }}>
                <FormElementLabel>{t('service.unitPrice')}</FormElementLabel>
                <TextBox>{formatPrice(jobService.unitPrice, 2, 4)}</TextBox>
              </UiDiv>
              <UiDiv gridColumn={{ mobile: '1 / 25', tablet: '19 / 25' }}>
                <FormElementLabel textAlign={{ tablet: 'right' }}>{t('service.netPrice')}</FormElementLabel>
                <TextBox textAlign={{ tablet: 'right' }}>
                  {formatPrice(JobUtil.getServiceNetPrice(jobService.unitPrice, jobService.quantity))}
                </TextBox>
              </UiDiv>
            </>
          )}
        </UiDiv>
      </UiDiv>
    </>
  );
};

export default JobServiceItem;
