import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const limitInterval = 20;

const firstDayOfMonth = new Date();
firstDayOfMonth.setDate(1);
firstDayOfMonth.setHours(0);
firstDayOfMonth.setMinutes(0);
firstDayOfMonth.setSeconds(0);

export interface DashboardState {
  selectedLocationNumber?: string;
  selectedLocationName?: string;
  selectedCompanyNumber?: string;
  selectedCompanyName?: string;
  selectedStatus?: '' | JobStatus | JobOfferStatus;
  selectedStartDate?: Date;
  selectedEndDate?: Date;
  selectedIsInvoiced?: string; 
  limit?: number;
  dashboardLimit?: number;
  jobIds?: number[];
  jobsCount?: number;
  metrics?: MetricsData,
  csvData?: any
}

const initialState: DashboardState = {
  selectedLocationNumber: '',
  selectedLocationName: '',
  selectedCompanyNumber: '',
  selectedCompanyName: '',
  selectedStatus: '',
  selectedStartDate: firstDayOfMonth,
  selectedEndDate: firstDayOfMonth,
  selectedIsInvoiced: '',
  limit: limitInterval,
  dashboardLimit: limitInterval,
  jobIds: [],
  jobsCount: 0,
  metrics: { offers: {}, certificates: {}},
  csvData: null
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardState: (state: DashboardState, action: PayloadAction<DashboardState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setDashboardState } = dashboardSlice.actions;
export const selectDashboardState = (state: RootState): DashboardState => state.dashboardState;
export default dashboardSlice.reducer;
