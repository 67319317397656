/* eslint-disable react-hooks/exhaustive-deps */
import { Group, GroupItem, UiDiv } from '@webfox-sc/core';
import { pull } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../app/appHooks';
import { useAddress } from '../../app/entityHooks/useAddress';
import { useJob } from '../../app/entityHooks/useJob';
import { useRecipient } from '../../app/entityHooks/useRecipient';
import { useUser } from '../../app/entityHooks/useUser';
import { fetchAddress } from '../../app/slices/addressesSlice';
import IconArrowRight from '../../assets/icons-v2/IconArrowRight';
import IconCheck from '../../assets/icons-v2/IconCheck';
import IconClose from '../../assets/icons-v2/IconClose';
import IconEdit from '../../assets/icons-v2/IconEdit';
import IconPlus from '../../assets/icons-v2/IconPlus';
import ButtonSecondary from '../../atoms/ButtonSecondary';
import { getEntries, getGroupLabel, GROUP_KEYS } from '../../utils/FilterUtil';
import Button from '../atoms-v2/Button';
import FormElementLabel from '../atoms-v2/FormElementLabel';
import FormPanel from '../atoms-v2/FormPanel';
import FormRowGroup from '../atoms-v2/FormRowGroup';
import TextBox from '../atoms-v2/TextBox';
import TextInput from '../atoms-v2/TextInput';
import { useAuth } from '../auth/useAuth';
import CustomReactSelect from '../CustomReactSelect';

import { SHOW_JOB_RECIPIENT_ADDRESS, USE_DEBITOR_ADDRESSES_IN_JOBS } from '../../app.config';
import { usePermissions } from '../auth/usePermissions';
import JobRecipientAddressIcon from './JobRecipientAddressIcon';
import { useJobRecipientEditor } from './useJobRecipientEditor';
import { fetchCompany } from '../../app/slices/companiesSlice';
import { useCompany } from '../../app/entityHooks/useCompany';

const DROPDOWN_PARAMS = [
  { identifier: 'costCenterNumber', dataKey: 'costcenters' },
  { identifier: 'carrierNumber', dataKey: 'locations' },
  { identifier: 'carrierDescription', dataKey: 'locations' },
  { identifier: 'clientNumber', dataKey: 'companies' },
  { identifier: 'clientDescription', dataKey: 'companies' },
];

const createClientDropdowns = (filterMap: any) => {
  const dropdowns = {};
  Object.values(DROPDOWN_PARAMS).forEach(({ identifier, dataKey }) => {
    dropdowns[identifier] = [];
    if (filterMap && filterMap[dataKey] && filterMap[dataKey].group) {
      // check other filter objects for grouped options
      const otherKeys = [...GROUP_KEYS];
      pull(otherKeys, dataKey);
      otherKeys.forEach((key) => {
        if (filterMap[dataKey].group[key]) {
          dropdowns[identifier].push({
            label: getGroupLabel(key, filterMap[key].selected),
            options: getEntries(identifier, filterMap[dataKey].group[key]),
          });
        }
      });
    }
  });
  return dropdowns;
};

const emptyContact: ContactPostOrPutData = {
  name: '',
  email: '',
};

interface JobRecipientEditorProps {
  jobId?: number | undefined;
  isJobEditMode?: boolean;
  isEditMode?: boolean;
  showButtonNext?: boolean;
  onUpdate?: (updateData: any) => void;
}

const JobRecipientEditor: React.FC<JobRecipientEditorProps> = ({
  jobId,
  isJobEditMode,
  isEditMode,
  onUpdate,
  showButtonNext,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();
  const { costcenters } = useUser(currentUser?.id);
  const { canViewAllCostCenters } = usePermissions();
  const { job: jobDraft } = useJob(jobId);
  const { recipient: jobRecipientDraft, contacts: contactsDraft } = useRecipient(jobDraft?.recipientId);

  const [contacts, setContacts] = useState<ContactPostOrPutData[]>([{ ...emptyContact }]);

  const [addressId, setAddressId] = useState<number | undefined>(undefined);
  const { address } = useAddress(addressId);

  const [isCostcenterInitiallySet, setIsCostcenterInitiallySet] = useState(false);
  const [currentIsEditMode, setCurrentIsEditMode] = useState(isEditMode);
  const [currentIsEditModeAllowed, setCurrentIsEditModeAllowed] = useState(isJobEditMode);
  const [currentShowButtonNext, setCurrentShowButtonNext] = useState(showButtonNext);

  const { client, setClientId, company, setCompany, location, setLocation, costcenter, setCostcenter, filterMap } =
    useJobRecipientEditor();

  const { company: companyEntity } = useCompany(company?.id);
  const companyAddressId = companyEntity?.addressId;

  const clientDropdowns: any = useMemo(() => {
    return createClientDropdowns(filterMap);
  }, [filterMap]);

  const resetData = () => {
    const newContacts: ContactPostOrPutData[] = JSON.parse(JSON.stringify(contactsDraft));
    if (!newContacts.length) {
      setContacts([{ ...emptyContact }]);
      contacts.push({
        name: '',
        email: '',
      });
    } else {
      setContacts(newContacts);
    }

    setClientId(jobRecipientDraft?.clientId);
  };

  useEffect(() => {
    if (jobRecipientDraft) {
      resetData();
    }
  }, [jobRecipientDraft]);

  useEffect(() => {
    if (isJobEditMode !== currentIsEditModeAllowed) {
      setCurrentIsEditModeAllowed(isJobEditMode);
      if (!isJobEditMode) {
        resetData();
        setCurrentIsEditMode(false);
      }
    }
  }, [isJobEditMode]);

  useEffect(() => {
    const fetchAddressData = async () => {
      if (client?.company) {
        const newAddressId = USE_DEBITOR_ADDRESSES_IN_JOBS
          ? client.company.addressId
          : client?.addressId || client.company.addressId;
        if (newAddressId !== addressId) {
          if (newAddressId) {
            await dispatch(fetchAddress(newAddressId));
            setAddressId(newAddressId);
          } else {
            setAddressId(undefined);
          }
        }
      }
    };
    fetchAddressData();
  }, [dispatch, client, addressId, client?.addressId, client?.company]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (company) {
        await dispatch(fetchCompany(company.id, { populate: { address: '*' }}));
      }
    };
    fetchCompanyData();
  }, [dispatch, company]);

  useEffect(() => {
    if (costcenters.length === 1 && !isCostcenterInitiallySet) {
      setCostcenter({
        id: costcenters[0].id,
        number: costcenters[0].number,
      });
      setIsCostcenterInitiallySet(true);
    }
  }, [costcenters, isCostcenterInitiallySet]);

  const handleAddContactPerson = () => {
    setContacts([...contacts, { ...emptyContact }]);
  };

  const handleChangeContactPerson = (index: number, key: string, value: any) => {
    const newContacts = [...contacts];
    const contact = contacts[index];
    contact[key] = value;
    setContacts(newContacts);
  };

  const handleRemoveContactPerson = (index: number) => {
    contacts.splice(index, 1);
    setContacts([...contacts]);
  };

  const handleOnClickUpdate = async () => {
    if (onUpdate) {
      const updateData = {
        clientId: client?.id || '',
        costcenter: costcenter?.number || '',
        carrierNumber: location?.number || '',
        carrierDescription: location?.description || '',
        clientNumber: company?.number || '',
        clientDescription: company?.name || '',
        noTax: client?.noTax || false,
        debitorId: client?.debitorId,
        contacts,
        address,
      };
      onUpdate(updateData);
    }

    setCurrentIsEditMode(false);
    setCurrentShowButtonNext(false);
  };

  const handleClickEdit = () => {
    setCurrentIsEditMode(true);
  };

  const handleClickCancel = () => {
    resetData();
    setCurrentIsEditMode(false);
  };

  const isClientDataValid = (): boolean => {
    const reContactEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let areContactsValid = true;
    contacts.forEach((contact) => {
      if (!contact.name) areContactsValid = false;
      if (!reContactEmail.test(String(contact.email).toLowerCase())) areContactsValid = false;
    });

    return (
      !!client?.id &&
      !!costcenter?.number &&
      !!location?.number &&
      !!location?.description &&
      !!company?.number &&
      !!company?.name &&
      areContactsValid
    );
  };

  let canEditClient = true;
  if (jobDraft) {
    canEditClient = (!jobDraft.status || jobDraft.status === 'Erstellt') && !jobDraft.offerStatus;
  }

  return (
    <>
      <FormPanel>
        <FormRowGroup>
          {(costcenters.length > 1 || canViewAllCostCenters) && (
            <UiDiv display="grid" gridTemplateColumns="repeat(12, 1fr)" columnGap="xxs">
              <FormElementLabel gridColumn={{ mobile: '1 / 13', tablet: '1 / 7' }}>
                {t('common.fields.costcenterNumber.long')} *
              </FormElementLabel>
              {currentIsEditMode && canEditClient ? (
                <UiDiv gridColumn={{ mobile: '1 / 13', tablet: '1 / 7' }}>
                  <CustomReactSelect
                    leadingOptions={[{ value: '', label: t('common.form.selectSearch') }]}
                    asyncDefaultOptions
                    selectedOption={{
                      value: costcenter?.id || '',
                      label: costcenter?.number || t('common.form.selectSearch'),
                    }}
                    asyncApiPath="/costcenters"
                    asyncLimit={500}
                    asyncSort="number:asc"
                    asyncSetParams={(inputValue) =>
                      inputValue
                        ? {
                            filters: [
                              {
                                number: { $contains: inputValue },
                              },
                            ],
                          }
                        : {}
                    }
                    asyncFormatOptions={(entries) => [
                      {
                        label: getGroupLabel('none'),
                        options: getEntries('costCenterNumber', entries),
                      },
                    ]}
                    asyncInputMinLength={3}
                    isClearable
                    isSearchable
                    onChange={(opt) =>
                      setCostcenter({
                        id: (opt && opt.value) || null,
                        number: (opt && opt.number) || null,
                      })
                    }
                  />
                </UiDiv>
              ) : (
                <TextBox gridColumn={{ mobile: '1 / 13', tablet: '1 / 7' }} background="grey20" color="ciBlue1">
                  {costcenter?.number || ''}
                </TextBox>
              )}
            </UiDiv>
          )}

          <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} columnGap="xxs">
            <FormElementLabel order={{ tablet: 1 }}>{t('common.fields.carrierNumber.long')} *</FormElementLabel>
            {currentIsEditMode && canEditClient ? (
              <UiDiv order={{ tablet: 3 }}>
                <CustomReactSelect
                  asyncDefaultOptions={[
                    { value: '', label: t('common.form.selectSearch') },
                    ...clientDropdowns?.carrierNumber,
                  ]}
                  selectedOption={{
                    value: location?.id || '',
                    label: location?.number || t('common.form.selectSearch'),
                  }}
                  asyncApiPath="/locations"
                  asyncLimit={500}
                  asyncSort="number:asc"
                  asyncSetParams={(inputValue) =>
                    inputValue
                      ? {
                          filters: [
                            {
                              number: { $contains: inputValue },
                            },
                          ],
                        }
                      : {}
                  }
                  asyncFormatOptions={(entries) => [
                    {
                      label: getGroupLabel('none'),
                      options: getEntries('carrierNumber', entries),
                    },
                  ]}
                  asyncInputMinLength={3}
                  isClearable
                  isSearchable
                  onChange={(opt) =>
                    setLocation({
                      id: (opt && opt.value) || null,
                      number: (opt && opt.number) || null,
                      description: (opt && opt.description) || null,
                    })
                  }
                />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
                {location?.number || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} display="flex" order={{ tablet: 2 }}>
              {t('common.fields.carrierDescription.long')} *
              {SHOW_JOB_RECIPIENT_ADDRESS && (
                <JobRecipientAddressIcon isDisabled={!client} addressId={client?.addressId} />
              )}
            </FormElementLabel>
            {currentIsEditMode && canEditClient ? (
              <UiDiv order={{ tablet: 4 }}>
                <CustomReactSelect
                  asyncDefaultOptions={[
                    { value: '', label: t('common.form.selectSearch') },
                    ...clientDropdowns.carrierDescription,
                  ]}
                  selectedOption={{
                    value: location?.id || '',
                    label: location?.description || t('common.form.selectSearch'),
                  }}
                  asyncApiPath="/locations"
                  asyncLimit={500}
                  asyncSort="description:asc"
                  asyncSetParams={(inputValue) =>
                    inputValue
                      ? {
                          filters: [
                            {
                              description: { $containsi: inputValue },
                            },
                          ],
                        }
                      : {}
                  }
                  asyncFormatOptions={(entries) => [
                    {
                      label: getGroupLabel('none'),
                      options: getEntries('carrierDescription', entries),
                    },
                  ]}
                  asyncInputMinLength={3}
                  isClearable
                  isSearchable
                  onChange={(opt) =>
                    setLocation({
                      id: (opt && opt.value) || null,
                      number: (opt && opt.number) || null,
                      description: (opt && opt.description) || null,
                    })
                  }
                />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1">
                {location?.description || ''}
              </TextBox>
            )}
          </UiDiv>

          <UiDiv display="grid" gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }} columnGap="xxs">
            <FormElementLabel order={{ tablet: 1 }}>{t('common.fields.clientNumber')} *</FormElementLabel>
            {currentIsEditMode && canEditClient ? (
              <UiDiv order={{ tablet: 3 }}>
                <CustomReactSelect
                  asyncDefaultOptions={[
                    { value: '', label: t('common.form.selectSearch') },
                    ...clientDropdowns?.clientNumber,
                  ]}
                  selectedOption={{
                    value: company?.id || '',
                    label: company?.number || t('common.form.selectSearch'),
                  }}
                  asyncApiPath="/companies"
                  asyncLimit={500}
                  asyncSort="number:asc"
                  asyncSetParams={(inputValue) =>
                    inputValue
                      ? {
                          filters: [
                            {
                              number: { $contains: inputValue },
                            },
                          ],
                        }
                      : {}
                  }
                  asyncFormatOptions={(entries) => [
                    {
                      label: getGroupLabel('none'),
                      options: getEntries('clientNumber', entries),
                    },
                  ]}
                  asyncInputMinLength={3}
                  isClearable
                  isSearchable
                  onChange={(opt) =>
                    setCompany({
                      id: (opt && opt.value) || null,
                      number: (opt && opt.number) || null,
                      name: (opt && opt.name) || null,
                    })
                  }
                />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
                {company?.number || ''}
              </TextBox>
            )}
            <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} display="flex" order={{ tablet: 2 }}>
              {t('common.fields.clientDescription.long')} *
              {SHOW_JOB_RECIPIENT_ADDRESS && <JobRecipientAddressIcon isDisabled={!companyAddressId} addressId={companyAddressId} />}
            </FormElementLabel>
            {currentIsEditMode && canEditClient ? (
              <UiDiv order={{ tablet: 4 }}>
                <CustomReactSelect
                  asyncDefaultOptions={[
                    { value: '', label: t('common.form.selectSearch') },
                    ...clientDropdowns?.clientDescription,
                  ]}
                  selectedOption={{
                    value: company?.id || '',
                    label: company?.name || t('common.form.selectSearch'),
                  }}
                  asyncApiPath="/companies"
                  asyncLimit={500}
                  asyncSort="name:asc"
                  asyncSetParams={(inputValue) =>
                    inputValue
                      ? {
                          filters: [
                            {
                              name: { $containsi: inputValue },
                            },
                          ],
                        }
                      : {}
                  }
                  asyncFormatOptions={(entries) => [
                    {
                      label: getGroupLabel('none'),
                      options: getEntries('clientDescription', entries),
                    },
                  ]}
                  asyncInputMinLength={3}
                  isClearable
                  isSearchable
                  onChange={(opt) =>
                    setCompany({
                      id: (opt && opt.value) || null,
                      number: (opt && opt.number) || null,
                      name: (opt && opt.name) || null,
                    })
                  }
                />
              </UiDiv>
            ) : (
              <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1">
                {company?.name || ''}
              </TextBox>
            )}
          </UiDiv>

          {contacts.map((contact, i) => {
            return (
              <UiDiv
                key={String(i)}
                display="grid"
                gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }}
                columnGap="xxs"
              >
                <FormElementLabel order={{ tablet: 1 }}>
                  {i === 0 ? t('recipient.mainContact') : t('recipient.otherContact')} *
                </FormElementLabel>
                {currentIsEditMode ? (
                  <UiDiv order={{ tablet: 3 }}>
                    <TextInput
                      value={contact.name}
                      onChange={(e) => handleChangeContactPerson(i, 'name', e.target.value)}
                    />
                  </UiDiv>
                ) : (
                  <TextBox order={{ tablet: 3 }} background="grey20" color="ciBlue1">
                    {contact.name}
                  </TextBox>
                )}
                <FormElementLabel marginTop={{ mobile: '15px', tablet: 0 }} order={{ tablet: 2 }}>
                  {t('common.fields.email')} *
                </FormElementLabel>
                {currentIsEditMode ? (
                  <UiDiv order={{ tablet: 4 }}>
                    <Group nowrap spacing="5px">
                      <GroupItem grow>
                        <TextInput
                          name={`persons[${i}][email]`}
                          value={contact.email}
                          onChange={(e) => handleChangeContactPerson(i, 'email', e.target.value)}
                        />
                      </GroupItem>
                      <GroupItem>
                        {currentIsEditMode && contacts.length > 1 && (
                          <ButtonSecondary
                            ariaLabel="Entfernen"
                            icon={IconClose}
                            onClick={() => handleRemoveContactPerson(i)}
                          />
                        )}
                      </GroupItem>
                    </Group>
                  </UiDiv>
                ) : (
                  <TextBox order={{ tablet: 4 }} background="grey20" color="ciBlue1" wordBreak="break-all">
                    {contact.email}
                  </TextBox>
                )}
              </UiDiv>
            );
          })}

          {currentIsEditMode && (
            <UiDiv>
              <Button variant="secondary" icon={IconPlus} onClick={handleAddContactPerson}>
                {t('contact.actions.add')}
              </Button>
            </UiDiv>
          )}
        </FormRowGroup>

        {currentIsEditMode && !currentShowButtonNext && (
          <UiDiv display="flex" flexWrap="wrap" justifyContent="center" gap="xs" marginTop="m">
            <Button icon={IconCheck} disabled={!isClientDataValid()} onClick={handleOnClickUpdate}>
              {t('common.actions.apply')}
            </Button>
            {jobRecipientDraft && (
              <Button variant="secondary" icon={IconClose} onClick={handleClickCancel}>
                {t('common.actions.cancel')}
              </Button>
            )}
          </UiDiv>
        )}

        {currentIsEditModeAllowed && !currentIsEditMode && (
          <UiDiv display="flex" justifyContent="flex-end" paddingTop="s">
            <ButtonSecondary icon={IconEdit} ariaLabel="Edit" onClick={handleClickEdit} />
          </UiDiv>
        )}
      </FormPanel>

      {currentShowButtonNext && (
        <UiDiv display="flex" justifyContent="center" paddingTop="m">
          <Button icon={IconArrowRight} disabled={!isClientDataValid()} onClick={handleOnClickUpdate}>
            {t('common.actions.forward')}
          </Button>
        </UiDiv>
      )}
    </>
  );
};

export default JobRecipientEditor;
