/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconSortSrc from '../../../assets/images/no-filter.png';
import IconSortAscSrc from '../../../assets/images/filter-up.png';
import IconSortDescSrc from '../../../assets/images/filter-down.png';
import './table-header-cell.scss';

class TableHeaderCell extends Component {
  constructor(props) {
    super(props);
  }

  handleOnClickLabel() {
    const { isSorting, onClick } = this.props;
    if (isSorting) {
      onClick();
    }
  }

  render() {
    const { text, width, isDark, isSorting, sortingOrder } = this.props;

    const cellStyle = {
      width: width,
      flexGrow: width === 'auto' ? 1 : 0,
    };

    let labelClassName = 'smg-table-header-cell__label';
    if (isSorting) {
      labelClassName += ' smg-table-header-cell__label--clickable';
    }
    if (isDark) {
      labelClassName += ' smg-table-header-cell__label--dark';
    }

    let iconSrc = IconSortSrc;
    if (sortingOrder === 'asc') {
      iconSrc = IconSortAscSrc;
    } else if (sortingOrder === 'desc') {
      iconSrc = IconSortDescSrc;
    }

    return (
      <div className="smg-table-header-cell" style={cellStyle}>
        <div className="smg-table-header-cell__inner">
          <div className={labelClassName} onClick={() => this.handleOnClickLabel()}>
            <span>{text}</span>
            {isSorting && (
              <div className="smg-table-header-cell__icon-sorting">
                <img src={iconSrc} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

TableHeaderCell.propTypes = {
  text: PropTypes.string,
  width: PropTypes.string,
  isDark: PropTypes.bool,
  isSorting: PropTypes.bool,
  sortingOrder: PropTypes.string,
  onClick: PropTypes.func,
};

TableHeaderCell.defaultProps = {
  text: '',
  width: 'auto',
  isDark: false,
  isSorting: false,
  sortingOrder: '',
  onClick: () => false,
};

export default TableHeaderCell;
