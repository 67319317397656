import { UiDiv } from '@webfox-sc/core';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/appHooks';
import { useCraftOptions } from '../../app/entityHooks/useCraftOptions';
import { QMFilterState, selectQMOverviewState } from '../../app/slices/qmOverviewSlice';
import CustomReactSelect from '../CustomReactSelect';
import FormElementLabel from '../atoms-v2/FormElementLabel';

interface QMCraftsFilterProps {
  onChangeFilter?: (filter: QMFilterState) => void;
  labelColor: string;
}

const QMCraftsFilter: React.FC<QMCraftsFilterProps> = ({ onChangeFilter, labelColor }) => {
  const { t } = useTranslation();
  const filter = useAppSelector(selectQMOverviewState);
  const { craftOptions } = useCraftOptions();

  const allCraftOptions = useMemo(
    () => [
      {
        value: null,
        label: t('craft.form.options.showAll'),
      } as SelectOption,
      {
        value: 0,
        label: t('craft.form.options.default'),
      } as SelectOption,
      ...craftOptions,
    ],
    [craftOptions, t]
  );

  const onChangeCraftValue = async (value) => {
    if (onChangeFilter) {
      onChangeFilter({
        craftId: value,
      });
    }
  };

  useEffect(() => {
    // disable and set default craft if there is no other
    if (craftOptions && !craftOptions.length && onChangeFilter) {
      onChangeFilter({
        craftId: 0,
      });
    }
  }, [craftOptions, onChangeFilter]);

  return (
    <UiDiv>
      <FormElementLabel color={labelColor}>
        {t('common.fields.craft')}
      </FormElementLabel>
      <CustomReactSelect
        isDisabled={!craftOptions.length}
        layout="outline"
        options={allCraftOptions}
        selectedValue={filter?.craftId}
        onChangeValue={onChangeCraftValue}
      />
    </UiDiv>
  );
};

export default QMCraftsFilter;
