import { UiDiv } from '@webfox-sc/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconClose from '../../../assets/icons-v2/IconClose';
import IconPlus from '../../../assets/icons-v2/IconPlus';
import FormElementLabel from '../../atoms-v2/FormElementLabel';
import IconButton from '../../atoms-v2/IconButton';
import TextInput from '../../atoms-v2/TextInput';
import TextLink from '../../atoms-v2/TextLink';
import { useQMAdminSchedule } from '../useQMAdminSchedule';

interface QMAdminClientScheduleContactsFormProps {
  scheduleId?: number;
  onUpdate?: (contactsDraft: ContactEntity[]) => void;
}

const QMAdminClientScheduleContactsForm: React.FC<QMAdminClientScheduleContactsFormProps> = ({
  scheduleId,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const { contacts } = useQMAdminSchedule(scheduleId);
  const [contactsDraft, setContactsDraft] = useState<ContactEntity[]>(contacts || []);

  const updateContactsDraft = (contactsDraftNew: ContactEntity[]) => {
    setContactsDraft(contactsDraftNew);
    if (onUpdate) {
      onUpdate(contactsDraftNew);
    }
  };

  const handleOnClickAdd = () => {
    const contactsDraftNew = [
      ...contactsDraft,
      {
        id: -Date.now(),
        name: '',
        email: '',
        editState: 'new',
      } as ContactEntity,
    ];
    updateContactsDraft(contactsDraftNew);
  };

  const handleOnChange = (contactId: number, key: string, value: string) => {
    const contactsDraftNew = [...contactsDraft];
    const index = contactsDraftNew.findIndex((contact) => contact.id === contactId);

    if (index !== -1) {
      contactsDraftNew[index] = {
        ...contactsDraftNew[index],
      };
      contactsDraftNew[index][key] = value;
      if (!contactsDraftNew[index].editState) {
        contactsDraftNew[index].editState = 'updated';
      }
    }

    updateContactsDraft(contactsDraftNew);
  };

  const handleOnClickRemove = (contactId: number) => {
    const contactsDraftNew = [...contactsDraft];
    const index = contactsDraftNew.findIndex((contact) => contact.id === contactId);

    if (index !== -1) {
      if (contactsDraftNew[index].editState === 'new') {
        //
        contactsDraftNew.splice(index, 1);
      } else {
        contactsDraftNew[index] = {
          ...contactsDraftNew[index],
          editState: 'delete',
        };
      }
    }

    updateContactsDraft(contactsDraftNew);
  };

  return (
    <UiDiv padding="s" background="white">
      <UiDiv display="flex" flexDirection="column" rowGap={{ mobile: 'm', tablet: 'xs' }}>
        {contactsDraft.map((contact) => {
          if (contact.editState === 'delete') {
            return null;
          }
          return (
            <UiDiv
              key={contact.id}
              display="grid"
              gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1.5fr 22px' }}
              rowGap="s"
              columnGap="xs"
            >
              <UiDiv>
                <FormElementLabel>{t('common.fields.name')}</FormElementLabel>
                <TextInput
                  autoComplete="name"
                  value={contact.name}
                  maxLength={256}
                  onChange={(e) => handleOnChange(contact.id, 'name', e.target.value)}
                />
              </UiDiv>
              <UiDiv>
                <FormElementLabel>{t('user.email')}</FormElementLabel>
                <TextInput
                  autoComplete="email"
                  value={contact.email}
                  maxLength={256}
                  onChange={(e) => handleOnChange(contact.id, 'email', e.target.value)}
                />
              </UiDiv>
              <UiDiv display={{ mobile: 'block', tablet: 'none' }} textAlign="right">
                <TextLink
                  icon={IconClose}
                  onClick={(e) => {
                    e.preventDefault();
                    handleOnClickRemove(contact.id);
                  }}
                >
                  {t('schedule.actions.removeContact')}
                </TextLink>
              </UiDiv>
              <UiDiv paddingTop="34px" display={{ mobile: 'none', tablet: 'block' }}>
                <IconButton icon={IconClose} onClick={() => handleOnClickRemove(contact.id)} />
              </UiDiv>
            </UiDiv>
          );
        })}
        <UiDiv>
          <TextLink
            icon={IconPlus}
            onClick={(e) => {
              e.preventDefault();
              handleOnClickAdd();
            }}
          >
            {t('schedule.actions.addContact')}
          </TextLink>
        </UiDiv>
      </UiDiv>
    </UiDiv>
  );
};

export default QMAdminClientScheduleContactsForm;
