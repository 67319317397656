/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './form-info.scss';

class FormInfo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        let className = 'smg-form-info';

        return <div className={className}>{text}</div>;
    }
}

FormInfo.propTypes = {
    /**
     * @type string
     */
    text: PropTypes.string
};

FormInfo.defaultProps = {
    text: ''
};

export default FormInfo;
